import React from 'react';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import {
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormikField from '../components/Formik/FormikField';
import { Formik, Form } from 'formik';
import iconU from '../images/icon-U.svg';
import { ViolationDetail } from 'aws-sdk/clients/fms';
import { number } from '@hapi/joi';
import { NEInputLight as NEInput } from '../components/NEInput';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#000',
    },
    campaignBlk:{
      display:'flex', 
      marginBottom:'10px',
      '& .MuiFormControl-root':{
        width:'initial',
        marginBottom:'0',
        minHeight:'0',
        '& p':{
          position:'absolute',
          bottom:'-50%',
          left:'10px',
          width:'100%',
        }
      }
    }
  });

const initialFormValues ={
  permaLink: '/classes/',
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  onSubmitValue: () => void;
  handleIDInput: () => void;
  campaignId: any;
  isInteractive : boolean;
  error : any;
}

interface State {
  Id: string;
}
export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, onSubmitValue, handleIDInput, campaignId, error, isInteractive = false, ...other } = props;
  const [values, setValues] = React.useState({
    Id: '',
  });

  const handleChange = (prop: keyof State) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValues({ ...values, [prop]: event.target.value });
    handleIDInput(event);
  };

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <img src={iconU} className={classes.iconU} />
      <Typography variant="h6">{children}</Typography>
      {isInteractive &&
      <div className={classes.campaignBlk}>
        <Button onClick={(e) =>{onSubmitValue()}} color="secondary" type="submit">Send Email</Button>
        <NEInput
          label="Campaign ID"
          variant="outlined"
          id="CampaignID"
          // fullWidth={true}
          defaultValue={campaignId}
          required
          name="CampaignID"
          value={campaignId}
          onChange={handleChange('CampaignID')}
          error={!!error.campaignId || ''}
          helperText={error.campaignId || ''}
          // helperText={error.classTitle || ''}
          // onBlur={generatePermalink}
        />
      </div>}
      <div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dividers: {
    borderTop: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(MuiDialogActions);
