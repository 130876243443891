/*
 *
 * Members actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
// reset password
export const passwordRestAction = (email: string) => {
  return action(ActionTypes.PASSWORD_RESET, email);
};
// switch email login
export const switchEmailLogin = data => {
  return action(ActionTypes.SWITCH_EMAILLOGIN, data);
};
// revoke
export const revokeAction = data => {
  return action(ActionTypes.REVOKE_ACCESS, data);
};
// loader
export const memberLoader = (isLoading: boolean) => {
  return action(ActionTypes.MEMBER_LOADER, isLoading);
};
// action faild
export const actionFaild = () => {
  return action(ActionTypes.ACTION_FAILED);
};
// get subscription
export const getSubscriptions = () => {
  return action(ActionTypes.SUBSCRIPTION_PLANS);
};
