import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { colors } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SvgClose } from './NeIcons';

const focusedColor = '#000';
const defaultColor = '#909599';
const defaultLabelColor = '#060B0F';
const hoverLabelColor = '#060B0F';
const focusedLabelColor = '#060B0F';
const inputHoverColor = '#E3E3E4';
const inputFocusedColor = '#060B0F';
const errorColor = '#FF1C58';
const borderColor = '#E3E3E4';

export const AutocompleteInput = withStyles({
  root: {
    marginBottom: 16,
    width: '100%',
    '& label.MuiInputLabel-outlined': {
      color: defaultColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 16px) scale(1)',
      color: defaultColor,
      fontSize: '16px',
      fontFamily: 'Proxima-Nova-Regular',
      fontWeight: '500',
      letterSpacing: '0',
      lineHeight: '20px',
    },

    '& label.Mui-focused': {
      color: focusedLabelColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.Mui-focused.Mui-error': {
      color: errorColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: borderColor,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      padding: '9px 14px',
      '& fieldset': {
        borderColor,
      },
      '&:hover fieldset': {
        borderColor: inputHoverColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${inputFocusedColor}`,
      },
    },
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        color: focusedColor,
        padding: '5.5px 14px',
        fontFamily: 'Proxima-Nova-Regular',
        fontStyle: 'italic',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      borderRadius: '6px',
      '& fieldset': {
        borderColor: errorColor,
      },
      '&:hover fieldset': {
        color: defaultColor,
        borderColor: errorColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${errorColor}`,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '4px',
      '&.Mui-error': {
        color: errorColor,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
    tag: {
      background: '#3C4042',
      color: '#fff',
      height: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    deleteIcon: {
      color: '#fff',
      width: '18px',
      opacity: 1,
      '&:hover': {
        color: '#fff',
        opacity: '0.5',
      },
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    autocompleteOptions: {
      fontFamily: 'Proxima-Nova-Regular',
    },
    autocompleteInputRoot: {},
    autocompleteRoot: {},
  }),
);

export default function LimitTags(props) {
  const classes = useStyles();
  const {
    options = [],
    placeholder = 'Add',
    label = 'Categories',
    name,
    defaultValue,
  } = props;

  const [value, setValue] = useState(true);

  return (
    <div className={classes.root}>
      <Autocomplete
        classes={{
          root: classes.autocompleteRoot,
          tag: classes.tag,
          option: classes.autocompleteOptions,
          inputRoot: classes.autocompleteInputRoot,
        }}
        id="multiple-limit-tags"
        getOptionLabel={option => option.title}
        // defaultValue={defaultValue}
        ChipProps={{
          //   deleteIcon: <SvgClose />,
          classes: {
            deleteIcon: classes.deleteIcon,
            label: classes.chipLabel,
          },
        }}
        renderInput={params => (
          <AutocompleteInput
            {...params}
            variant="outlined"
            label={label}
            name="genres"
            placeholder={placeholder}
          />
        )}
        {...props}
      />
    </div>
  );
}
