import { Theme, makeStyles, createStyles } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      '& svg': {
        [theme.breakpoints.up('lg')]: {
          width: '64px',
          height: '64px',
        },
      },
      '& img': {
        backgroundColor: '#080b0b',
      },
      width: theme.spacing(9),
      height: theme.spacing(9),
      backgroundColor: '#35414a',
      fontFamily: 'ProximaNova-Light',
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        fontSize: '40px',
        lineHeight: '48px',
      },
      [theme.breakpoints.up('xl')]: {
        width: theme.spacing(12),
        height: theme.spacing(12),
      },
    },
  }),
);
