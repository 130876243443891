/*
 *
 * COLLECTION constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/Video/DEFAULT_ACTION',

  REQUEST_COLLECTION_LIST = 'REQUEST_COLLECTION_LIST',
  COLLECTION_LIST_SUCCESS = 'COLLECTION_LIST_SUCCESS',
  COLLECTION_LIST_FAILED = 'COLLECTION_LIST_FAILED',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  GET_SEARCH_RESULT = 'app/collections/GET_SEARCH_RESULT',
  FETCH_COLLECTION_BY_ID = 'FETCH_COLLECTION_BY_ID',
  STORE_COLLECTION_DETAIL = 'STORE_COLLECTION_DETAIL',

  SET_COLLECTION_LOADER = 'SET_COLLECTION_LOADER',
  SET_COLLECTION_PAGINATION_LOADER = 'SET_CONCEPT_PAGINATION_LOADER',

  // This Empty Details getting
  ADD_COLLECTION = 'ADD_COLLECTION',
  //
  ADD_COLLECTION_DETAIL_INFO = 'ADD_COLLECTION_DETAIL_INFO',
  ADD_TO_COLLECTION_DETAIL_INFO_SUCCESS = 'ADD_TO_COLLECTION_DETAIL_INFO_SUCCESS',
  ADD_TO_COLLECTION_DETAIL_INFO_FAILED = 'ADD_TO_COLLECTION_DETAIL_INFO_FAILED',

  EDIT_COLLECTION_DETAIL_INFO = 'EDIT_COLLECTION_DETAIL_INFO',
  EDIT_TO_COLLECTION_DETAIL_INFO_SUCCESS = 'EDIT_TO_COLLECTION_DETAIL_INFO_SUCCESS',
  EDIT_TO_COLLECTION_DETAIL_INFO_FAILED = 'EDIT_TO_COLLECTION_DETAIL_INFO_FAILED',

  SEARCH_VIDEO_BY_TITLE_OR_ID = 'SEARCH_VIDEO_BY_TITLE_OR_ID',
  SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS = 'SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS',
  SEARCH_VIDEO_BY_TITLE_OR_ID_FAILED = 'SEARCH_VIDEO_BY_TITLE_OR_ID_FAILED',

  COLLECTION_LIST_TOTAL = 'COLLECTION_LIST_TOTAL',
  REQUEST_COLLECTION_LIST_PAGINATION = 'REQUEST_COLLECTION_LIST_PAGINATION',
  COLLECTION_LIST_PAGINATION_SUCCESS = 'COLLECTION_LIST_PAGINATION_SUCCESS',
  PUT_COLLECTION_LIST_PAGINATION_SUCCESS = 'PUT_COLLECTION_LIST_PAGINATION_SUCCESS',
  REQUEST_COLLECTION_TYPE = 'REQUEST_COLLECTION_TYPE',
  SET_SORT_BY_TYPE = 'SET_SORT_BY_TYPE',

  RESET_COLLECTION = 'RESET_COLLECTION',
}

export default ActionTypes;
