import * as React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import clsx from 'clsx';

const selectBtnStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: '17px',
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '14px',
      lineHeight: '17px',
      padding: '0px',
      marginLeft: '-10px',
      [theme.breakpoints.only('sm')]: {
        marginLeft: '8px',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      // '& .MuiIconButton-root': {
      //   paddingLeft: '17px',
      // },
    },
    icon: {
      borderRadius: 4,
      width: 16,
      height: 16,
      backgroundColor: '#12161A',
    },
    checkedIcon: {
      backgroundColor: '#12161A',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
  }),
);

export default function FilterCheckBox(props) {
  const classes = selectBtnStyle();

  const handleChange = event => {
    props.onChange(event.target.checked);
  };
  return (
    <div className={classes.root}>
      <Checkbox
        disableRipple
        color="default"
        onChange={value => handleChange(value)}
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        checked={props.checked}
      />
      <div>No Explicit Language</div>
    </div>
  );
}
