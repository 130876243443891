import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import { storeClients } from './actions';

// Individual exports for testing
export default function* myClassDetailSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_MY_CLIENT, getMyClassClients);
}

// get
export function* getMyClassClients(action: AnyAction) {
  try {
    const videoId = action.payload;

    if (videoId) {
      let { result } = yield call(() =>
        request({
          url: `/neouadmin/v1/get-booked-user-list?videoId=${videoId}`,
          method: 'GET',
        }),
      );
      yield put(storeClients(result));
    }
  } catch (error) {
    if (error) {
    }
  }
}
