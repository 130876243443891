import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../../Root/actions';
import { Snackbar } from '@material-ui/core';

const options = ['Copy Link'];

export default function ShareURL(props) {
  const { classURL } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const [copyIdBtnText, setCopyIdBtnText] = React.useState('Copy');
  const [copyAlert, setCopyAlert] = React.useState(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleCloseToggle = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const getTextAreaContent = () => {
    setCopyAlert(false);
    let copyTextarea = classURL;
    navigator.clipboard.writeText(copyTextarea).then(
      function() {
        setCopyIdBtnText('Copied');
        setOpen(false);
        setTimeout(() => {
          setCopyAlert(true);
        }, 2000);
      },
      function(err) {
        setCopyIdBtnText('Copy');
        dispatch(openSnackBar('Could not copy concept Id!'));
      },
    );
  };

  return (
    <>
      <Grid item xs={3} style={{ padding: '8px' }}>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            style={{ background: '#D4D8DC' }}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            Share
          </Button>
        </ButtonGroup>
        <Popper
          placement={'right'}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'right' ? 'center right' : 'center right',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseToggle}>
                  <MenuList id="split-button-menu">
                    {options.map(option => (
                      <MenuItem
                        key={option}
                        selected={option === 'Copy Link'}
                        onClick={() => getTextAreaContent()}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>

      {copyIdBtnText === 'Copied' && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={!copyAlert}
          onClose={copyAlert}
          message="Copied to Clipboard"
        />
      )}
    </>
  );
}
