import React from 'react';
import {
  Dialog,
  withStyles,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { dialogStyles } from './LiveVideoFormStyles';
import { dynamicSort } from '../../../utils/commonFunctions';
import { useDispatch } from 'react-redux';
import request from '../../../shared/lib/request';
import { storeLiveClassInfo } from '../actions';
import { openSnackBar } from '../../Root/actions';
import { SvgLoaderGif } from '../../../components/NeIcons';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    classRoot: {
      width: '100%',
      boxShadow: 'none',
      '& .MuiTableRow-root': {
        'td.MuiTableCell-root.MuiTableCell-body:nth-child(1)': {
          flex: '1',
          width: '34%',
        },
        '& td.MuiTableCell-root.MuiTableCell-body:nth-child(2)': {
          width: '30%',
          minWidth: '224px',
        },
        '& td.MuiTableCell-root.MuiTableCell-body:nth-child(3)': {
          minWidth: '200px',
        },
        '& td.MuiTableCell-root.MuiTableCell-body:nth-child(4)': {
          width: '30%',
        },
      },
    },
    clientLists: {
      width: '1060px',
      height: '683px',
    },
    message: {
      fontSize: '16px',
      fontStyle: 'italic',
      color: '#A1A6A9',
      textAlign: 'center',
      background: '#F8F9FA',
      padding: '25px 0px',
      width: '100%',
      marginTop: '-24px',
    },
    genderText: {
      textTransform: 'capitalize',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      paddingLeft: '15px',
    },
    conceptTitle: {
      color: '#12161A',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '17px',
      letterSpacing: '0',
      lineHeight: '24px',
    },
    container: {
      maxHeight: '100%',
    },
    firstColumn: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'inherit',
    },
    firstCoulmContent: {
      textAlign: 'start',
      paddingLeft: '16px',
    },
    text: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
    },
  }),
);

const LiveVideoFormDialog = ({ id, isOpen, classes, clients, handleClose }) => {
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [isParticipantListLoading, setParticipantListLoading] = React.useState(
    false,
  );
  let sortedClientsList = clients.sort(dynamicSort('firstName'));
  const styles = useStyles();
  const dispatch = useDispatch();
  let [participantsList, setParticipantsList] = React.useState(
    sortedClientsList,
  );
  const _init = async () => {
    try {
      setParticipantListLoading(true);
      let { result } = await request({
        url: `neouadmin/v1/liveclass-participants/`,
        method: 'GET',
      });
      let clientList =
        result &&
        result.users &&
        result.users.length > 0 &&
        result.users.sort(dynamicSort('firstName'));
      setParticipantsList(clientList);
      dispatch(storeLiveClassInfo(result));
      setParticipantListLoading(false);
    } catch (error) {
      setParticipantListLoading(false);
      dispatch(storeLiveClassInfo({}));
      dispatch(openSnackBar('Error in Fetching data'));
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      _init();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick={true}
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
        >
          {`LIVE CLASS PARTICIPANT LIST (${
            participantsList.length > 0 ? participantsList.length : 0
          })`}
        </DialogTitle>
        <DialogContent
          dividers
          classes={{ root: classes.dialogContent }}
          style={{ height: '683px' }}
        >
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the Videos."
            onAgree={() => handleClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <div className={styles.message}>
            Participant list will refresh for every 30 seconds
          </div>
          <Paper className={styles.classRoot}>
            <TableContainer className={styles.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableBody>
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell align="left">
                      <div className={styles.firstColumn}>
                        <div className={styles.firstCoulmContent}>
                          <div className={styles.conceptTitle}>First Name</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={styles.conceptTitle}>Display Name</div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={styles.conceptTitle}>Gender</div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={styles.conceptTitle}>Email</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Paper className={styles.classRoot}>
            <TableContainer className={styles.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableBody>
                  {participantsList &&
                    participantsList.length > 0 &&
                    participantsList.map(list => {
                      return (
                        <TableRow
                          key={list && list.id}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <TableCell align="left">
                            <div className={styles.firstColumn}>
                              <div className={styles.firstCoulmContent}>
                                <div className={styles.text}>
                                  {list.firstName ? list.firstName : 'Stranger'}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className={styles.genderText}>
                              {list.displayName ? list.displayName : 'N/A'}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className={styles.genderText}>
                              {list.gender ? list.gender : 'N/A'}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className={styles.text}>{list.email}</div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {isParticipantListLoading && (
            <div style={{ padding: '10px', width: '100%' }}>
              <SvgLoaderGif />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(dialogStyles)(LiveVideoFormDialog);
