import * as Yup from 'yup';
const { object, string, number, date, boolean, array } = Yup;

/**
 * https://runkit.com/bohs100/5e8b7edad6356000131bbe1c
 */

const InteractiveConceptSchema = object({
  interactive: object({
    title: string().required('Enter a concept title'),
    description: string().required('Enter a description'),
    permaLink: string().required('Enter a valid permalink'),
    seriesID: string()
      .required('Enter a series ID')
      .min(3)
      .matches(/[A-Z]{3,}/, 'Enter only uppercase char')
      .max(3),
    instructors: array()
      .min(1)
      .required('Enter instructors'),
    genres: string().required('Enter a concept genres'),
    categories: array().required('Enter categories'),

    gallery: array().required('Enter atleast one gallery image'),
    graphics: object().shape({
      '16x9': string().url(),
      '32x9': string().url(),
      '1x1': string().url(),
      '9x16': string().url(),
      '16x9_trans': string().url(),
    }),
    // videos: object().shape({
    //   videoIds: array()
    //     .min(1, 'Enter atleast one video item')
    //     .required('Video list is madantory'),
    // }),
  }),
});

export { InteractiveConceptSchema };
