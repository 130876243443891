import React, { useState } from 'react';
import CustomIcons from './CustomIcon';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { PageTitle } from '../styles/Typography';
import { SvgFavoriteSelected, SvgFavorite, SvgShare, SvgBack } from './NeIcons';
import { Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
// import {
//   openUnFavoriteModal,
//   openShareModal,
// } from '../containers/Root/actions';

export interface IHeaderProps {
  history: any;
  headerTitle: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: {
      [theme.breakpoints.between('xs', 'md')]: {
        // marginTop: '56px',
      },
      [theme.breakpoints.only('md')]: {
        padding: '0px 26px',
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '0px 16px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '0px 16px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '16px 16px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '26px 212px',
      },
    },
    containerStyle: {
      maxWidth: '1200px',
      margin: 'auto',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    goBackIcon: {
      padding: '0px',
      '& svg': {
        color: 'white !important',
      },
    },
    favIcon: {
      '& .MuiButtonBase-root': {
        cursor: 'default !important',
      },
      '& svg': {
        color: 'white !important',
        cursor: 'pointer',
      },
      padding: '0px',
    },
    shareIcon: {
      cursor: 'default !important',
      padding: '0px 0px 0px 20px',
      '& svg': {
        cursor: 'pointer',
        width: '17px',
        height: '17px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '0px 0px 0px 30px',
      },
      [theme.breakpoints.up('xl')]: {
        padding: '0px 0px 0px 36px',
      },
    },
    title: {
      textAlign: 'center',
      cursor: 'pointer',
    },
    iconWrapper: {
      textAlign: 'end',
    },
  }),
);

const Favorites: React.FunctionComponent<IHeaderProps> = ({
  history,
  title,
  id,
  permaLink,
}) => {
  const [isSelected, setSelected] = React.useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();
  const favSelected = (id, favType, title) => {
    const favData = { id, favType, title };
    if (isSelected) {
      // dispatch(openUnFavoriteModal(favData));
    } else {
      dispatch(setFavorites(favData));
      setSelected(true);
    }
  };

  const shareSelected = (id, page, title) => {
    const shareData = { id, page, title };
    dispatch(openShareModal(shareData));
  };

  const goToLandingPage = () => {
    if (page === 'class') {
      history.push(`/neou/concepts${permaLink}`, {
        id: id,
      });
    }
  };

  return (
    <div>
      <Grid container alignItems={'center'} className={classes.containerStyle}>
        <Grid item xs={3} md={2}>
          <IconButton className={classes.goBackIcon} onClick={history.goBack}>
            <SvgBack />
          </IconButton>
        </Grid>

        <Grid
          className={classes.title}
          item
          xs={6}
          md={8}
          onClick={() => goToLandingPage()}
        >
          {page === 'instructor' ? (
            <PageTitle style={{ textTransform: 'capitalize' }}>
              {title}
            </PageTitle>
          ) : (
            <PageTitle>{title}</PageTitle>
          )}
        </Grid>

        <Grid item className={classes.iconWrapper} xs={3} md={2}>
          {page === 'concept' ? (
            <IconButton
              className={classes.favIcon}
              onClick={() => {
                favSelected(id, page, title);
              }}
            >
              {isSelected ? <SvgFavoriteSelected /> : <SvgFavorite />}
            </IconButton>
          ) : (
            ''
          )}
          {page === 'instructor' ? (
            <IconButton
              className={classes.favIcon}
              onClick={() => {
                favSelected(id, page, title);
              }}
            >
              {isSelected ? <SvgFavoriteSelected /> : <SvgFavorite />}
            </IconButton>
          ) : (
            ''
          )}
          {page !== 'favorites' ? (
            <IconButton className={classes.shareIcon}>
              <div
                onClick={() => {
                  shareSelected(id, page, title);
                }}
              >
                <SvgShare />
              </div>
            </IconButton>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const Video: React.FunctionComponent<IHeaderProps> = ({
  history,
  title,
  page,
  id,
  permaLink,
}) => {
  const [isSelected, setSelected] = React.useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();
  const favSelected = (id, favType, title) => {
    const favData = { id, favType, title };
    if (isSelected) {
      // dispatch(openUnFavoriteModal(favData));
    } else {
      dispatch(setFavorites(favData));
      setSelected(true);
    }
  };

  const shareSelected = (id, page, title) => {
    const shareData = { id, page, title };
    dispatch(openShareModal(shareData));
  };

  const goToLandingPage = () => {
    if (page === 'class') {
      history.push(`/neou/concepts${permaLink}`, {
        id: id,
      });
    }
  };

  return (
    <div>
      <Grid container alignItems={'center'} className={classes.containerStyle}>
        <Grid item xs={3} md={2}>
          <IconButton className={classes.goBackIcon} onClick={history.goBack}>
            <SvgBack />
          </IconButton>
        </Grid>

        <Grid
          className={classes.title}
          item
          xs={6}
          md={8}
          onClick={() => goToLandingPage()}
        >
          {page === 'instructor' ? (
            <PageTitle style={{ textTransform: 'capitalize' }}>
              {title}
            </PageTitle>
          ) : (
            <PageTitle>{title}</PageTitle>
          )}
        </Grid>

        <Grid item className={classes.iconWrapper} xs={3} md={2}>
          {page === 'concept' ? (
            <IconButton
              className={classes.favIcon}
              onClick={() => {
                favSelected(id, page, title);
              }}
            >
              {isSelected ? <SvgFavoriteSelected /> : <SvgFavorite />}
            </IconButton>
          ) : (
            ''
          )}
          {page === 'instructor' ? (
            <IconButton
              className={classes.favIcon}
              onClick={() => {
                favSelected(id, page, title);
              }}
            >
              {isSelected ? <SvgFavoriteSelected /> : <SvgFavorite />}
            </IconButton>
          ) : (
            ''
          )}
          {page !== 'favorites' ? (
            <IconButton className={classes.shareIcon}>
              <div
                onClick={() => {
                  shareSelected(id, page, title);
                }}
              >
                <SvgShare />
              </div>
            </IconButton>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const Header: React.FunctionComponent<IHeaderProps> = ({
  history,
  title,
  page,
  id,
  permaLink,
}) => {
  const [isSelected, setSelected] = React.useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();
  const favSelected = (id, favType, title) => {
    const favData = { id, favType, title };
    if (isSelected) {
      // dispatch(openUnFavoriteModal(favData));
    } else {
      dispatch(setFavorites(favData));
      setSelected(true);
    }
  };

  const shareSelected = (id, page, title) => {
    const shareData = { id, page, title };
    dispatch(openShareModal(shareData));
  };

  const goToLandingPage = () => {
    if (page === 'class') {
      history.push(`/neou/concepts${permaLink}`, {
        id: id,
      });
    }
  };

  return (
    <div>
      <Grid container alignItems={'center'} className={classes.containerStyle}>
        <Grid item xs={3} md={2}>
          <IconButton className={classes.goBackIcon} onClick={history.goBack}>
            <SvgBack />
          </IconButton>
        </Grid>

        <Grid
          className={classes.title}
          item
          xs={6}
          md={8}
          onClick={() => goToLandingPage()}
        >
          {page === 'instructor' ? (
            <PageTitle style={{ textTransform: 'capitalize' }}>
              {title}
            </PageTitle>
          ) : (
            <PageTitle>{title}</PageTitle>
          )}
        </Grid>

        <Grid item className={classes.iconWrapper} xs={3} md={2}>
          {page === 'concept' ? (
            <IconButton
              className={classes.favIcon}
              onClick={() => {
                favSelected(id, page, title);
              }}
            >
              {isSelected ? <SvgFavoriteSelected /> : <SvgFavorite />}
            </IconButton>
          ) : (
            ''
          )}
          {page === 'instructor' ? (
            <IconButton
              className={classes.favIcon}
              onClick={() => {
                favSelected(id, page, title);
              }}
            >
              {isSelected ? <SvgFavoriteSelected /> : <SvgFavorite />}
            </IconButton>
          ) : (
            ''
          )}
          {page !== 'favorites' ? (
            <IconButton className={classes.shareIcon}>
              <div
                onClick={() => {
                  shareSelected(id, page, title);
                }}
              >
                <SvgShare />
              </div>
            </IconButton>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(Header);

const FavoritesHeader = withRouter(Favorites);
const ClassHeader = withRouter(Video);

export { FavoritesHeader, ClassHeader };
