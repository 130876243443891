/*
 *
 * HomeCurate reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions, HomeCurate } from './types';

export const initialState: ContainerState = {
  topSection: [],
  lowerSection: [],
  segments: [],
  isLoading: false,
};

function homeCurateReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.REQUEST_HOME_PAGE_INFO:
      return { ...state, isLoading: true };
    case ActionTypes.STORE_HOME_PAGE_INFO:
      let { topSection, segments, lowerSection }: HomeCurate = action.payload;
      return { ...state, topSection, segments, lowerSection, isLoading: false };
    case ActionTypes.SET_LOADER:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export default homeCurateReducer;
