import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import { openSnackBar } from '../Root/actions';
import {
  requestAdministrators,
  getAdministratorDetail,
  administratorListSuccess,
  administratorListFailed,
  setAdminLoader,
  storeInstructorName,
  openAdminDrawerAdd,
  setLoader,
} from './actions';
import { AnyAction } from 'redux';

// Individual exports for testing
export default function* attributeSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(
    ActionTypes.REQUEST_ADMINISTRATOR_LIST,
    getAdministratorList,
  );
  yield takeLatest(ActionTypes.REQUEST_INSTRUCTOR, searchContents);
  yield takeLatest(ActionTypes.SAVE_ADMINSTRATOR_DETAIL, editSaveAdministrator);
  yield takeLatest(ActionTypes.PASSWORD_RESET, restPasswordAction);
  yield takeLatest(ActionTypes.REMOVE_ADMINISTRATOR, deleteAdminUser);
}

export function* searchContents() {
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/people?keyword=&interactive=true&isPublished=true&isHidden=false`,
        method: 'GET',
      }),
    );
    yield put(storeInstructorName(result));
  } catch (error) {
    yield put(setLoader(false));
    yield put(openSnackBar(`No interactive instructors found`));
  }
}

// get
export function* getAdministratorList(action: AnyAction) {
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/get-admins`,
        method: 'GET',
      }),
    );
    yield put(administratorListSuccess(result));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(administratorListSuccess([]));
    }
    yield put(administratorListFailed());
  }
}
let isFetching = false;
// add
export function* editSaveAdministrator(action: AnyAction) {
  try {
    if (!isFetching) {
      isFetching = true;
      const { id, instrId, name, email, role, permissions } = action.payload;
      let data;
      if (role === 'instructor') {
        data = {
          interactive: { instrId: instrId },
          name,
          email,
          role,
          permissions,
        };
      } else {
        data = { name, email, role, permissions };
      }
      if (action.payload.isAdd) {
        const { result } = yield call(() =>
          request({
            url: `/neouadmin/v1/addedit-admins`,
            method: 'POST',
            data: data,
          }),
        );
        yield put(setAdminLoader(false));
        yield put(requestAdministrators());
        yield put(openAdminDrawerAdd(false));
        restPasswordAction(email);
        yield put(openSnackBar(`Administrator added successfully`));
      } else {
        if (role === 'instructor') {
          data = {
            id: id,
            interactive: { instrId: instrId },
            name,
            role,
            permissions,
          };
        } else {
          data = { id, name, role, permissions };
        }
        const { result } = yield call(() =>
          request({
            url: `/neouadmin/v1/addedit-admins`,
            method: 'PUT',
            data: data,
          }),
        );
        yield put(setAdminLoader(false));
        yield put(requestAdministrators());
        yield put(openSnackBar('Administrator updated successfully'));
      }
      isFetching = false;
    }
  } catch (error) {
    if (isFetching) {
      isFetching = false;
    }
    yield put(openAdminDrawerAdd(false));
    if (error && error.data && error.data.responseCode === 401) {
      yield put(openSnackBar(error.data.error));
    } else if (error.data.error === 'EMAIL_EXISTS') {
      yield put(
        openSnackBar('[EMAIL_EXIST] Email is associated with other account'),
      );
    } else if (error.data.message === 'User already exist') {
      yield put(openSnackBar('[USER_EXIST] The User has an account'));
    } else if (error.data.message === 'Email ID not valid') {
      yield put(openSnackBar(error.data.message));
    } else {
      yield put(openSnackBar("Couldn't save the details. Try again later!"));
    }
    yield put(setAdminLoader(false));
    yield put(administratorListFailed());
  }
}

// reset
export function* restPasswordAction(action: AnyAction) {
  try {
    const email = action.payload;
    const data = {
      email: email,
    };
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/user/resetPwdLink`,
        method: 'POST',
        data: data,
      }),
    );
    yield put(openSnackBar('Reset password success'));
  } catch (error) {
    if (error && error.data) {
      yield put(openSnackBar(error.data.message || 'Reset password failed'));
    }
    yield put(administratorListFailed());
  }
}

// delete
export function* deleteAdminUser(action) {
  const { id, name, role, permissions, deleteAdmin } = action.payload;
  yield put(setAdminLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/addedit-admins`,
        method: 'PUT',
        data: { id, name, role, permissions, deleteAdmin },
      }),
    );
    yield put(setAdminLoader(false));
    yield put(requestAdministrators());
  } catch (error) {
    if (error && error.data) {
      yield put(openSnackBar(error.data.error));
    }
    yield put(setAdminLoader(false));
  }
}
