/*
 *
 * FitnessGoals
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectFitnessGoals from './selectors';
import reducer from './reducer';
import saga from './saga';
import { useStyles } from '../Attributes/styles';
import AttributeListTable from '../Attributes/AttributeTable/AttributeTable';
import { useEffect } from 'react';
import { getFitness } from './actions';
import Loader from '../../components/Loader';

const stateSelector = createStructuredSelector({
  fitnessGoals: makeSelectFitnessGoals(),
});

interface Props {}

function FitnessGoals(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'fitnessGoals', reducer: reducer });
  useInjectSaga({ key: 'fitnessGoals', saga: saga });

  const { fitnessGoals } = useSelector(stateSelector);
  let { fitnessList, isLoading } = fitnessGoals;

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    // Update the document title using the browser API
    dispatch(getFitness());
  }, []);

  return (
    <div>
      <Helmet>
        <title>Configure Fitness Goals</title>
        <meta name="description" content="Description of FitnessGoals" />
      </Helmet>
      {isLoading && <Loader />}

      <div className={classes.root}>
        <div>
          <div
            className={classes.headWrap}
            style={{ borderBottom: '1px solid #000000' }}
          >
            <div className={classes.head}>Configure: Fitness Goals</div>
          </div>

          {fitnessList && fitnessList.length === 0 ? (
            <div className={classes.noData}>No Data Found</div>
          ) : (
            <AttributeListTable
              list={fitnessList}
              isLoading={isLoading}
              isFitnessGoal={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FitnessGoals;
