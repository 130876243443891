/*
 *
 * InstructorMyClasses actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

// setWeeks(state, action: PayloadAction<any>) {
//     state.weeks = action.payload;
//   },

export const setWeeks = data => {
  return action(ActionTypes.SET_WEEKS, data);
};

export const setLoading = data => {
  return action(ActionTypes.SET_LOADING, data);
};

export const storeClass = (classes, classCount) => {
  return action(ActionTypes.STORE_CLASS, { classes, classCount });
};

export const requestMyClasses = id => {
  return action(ActionTypes.REQUEST_MY_CLASSES, id);
};

export const setActiveWeek = data => {
  return action(ActionTypes.SET_ACTIVE_WEEK, data);
};

export const setActiveWeekInfo = data => {
  return action(ActionTypes.SET_ACTIVE_WEEK_INFO, data);
};

export const setDates = data => {
  return action(ActionTypes.SET_DATES, data);
};

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
