import React, { memo, useState, useEffect } from 'react';
// import styled from 'styles/styled-components';
import { makeStyles, createStyles, Theme, Grid } from '@material-ui/core';
import FilterAccordion from '../CommonFilter/FilterAccordion';
import FilterRangeSlider from '../CommonFilter/FilterRangeSlider';
import FilterPickerButton from '../CommonFilter/FilterpickerButton';
import FilterSearchResultsButton from '../CommonFilter/FilterSearchResultsButton';
import FilterCheckBox from '../CommonFilter/FilterCheckBox';
import FilterDifficultySelector from '../CommonFilter/FilterDifficultySelector';
import FilterSearchLabel from '../CommonFilter/FilterSearchLabel';
import { SvgOutlined } from '../NeIcons';
import {
  updateVideoList,
  reupdateVideoList,
  setInteractiveOnlyAction,
} from '../../containers/Video/actions';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    // margin: '5px',
    margin: 'auto',
    width: '100%',
    paddingTop: '46px',
    paddingBottom: '72px',
  },
  paper: {
    background: '#FFF',
    // overflowY: 'auto',
    fontFamily: 'ProximaNova-Semibold',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1200px',
      margin: 'auto',
    },
  },
  title: {
    // [theme.breakpoints.only('sm')]: {
    //   marginTop: '5px',
    //   fontSize: '40px',
    // },
    // [theme.breakpoints.only('md')]: {
    //   marginTop: '15px',
    //   fontSize: '48px',
    // },
    // [theme.breakpoints.only('lg')]: {
    //   marginTop: '23px',
    //   fontSize: '52px',
    // },
    // [theme.breakpoints.only('xl')]: {
    //   marginTop: '34px',
    //   fontSize: '80px',
    // },

    height: '33px',
    color: '#FFFFFF',
    fontFamily: 'Proxima-Nova-Regular',
    display: 'flex',
    alignContent: 'spaceEvenly',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title__heading: {
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '26px',
  },
  close__icon: {
    alignSelf: 'center',
    cursor: 'pointer',
  },
  footer: {
    bottom: '0px',
    position: 'fixed',
    backgroundColor: '#FFF',
    width: '100%',
    borderTop: '1px solid #222A31',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.only('sm')]: {
      height: '72px',
    },
    [theme.breakpoints.up('md')]: {
      height: '71px',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 150px)',
    },
    [theme.breakpoints.up('xl')]: {
      margin: 'auto',
      width: 'calc(100% - 230px)',
    },
  },
  auto__margin: {
    margin: 'auto',
    // marginTop: '20px',
  },
  expLang: {
    margin: '0px',
    padding: '12px 0px 20px',
    display: 'flex',
  },
  line: {
    margin: '0px',
    borderBottom: '1px solid #E3E3E4',
  },
  chipBoxes: {
    paddingTop: '25px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

export default function CommonFilter(props) {
  const {
    filterData,
    selectedParams,
    selectedAPIParams,
    rangeValue,
    difficultyValue,
    interactiveOnly = false,
  } = props;

  const classes = useStyles();
  /* Duration data set */
  // const [rangeValue, setRangeValue] = useState([5, 10, 15, 20, 30, 45, 60, 90]);

  /* Boolean to show/hide the filter options alone */
  const [showFilterOption, setshowFilterOptions] = useState(true);

  /* Boolean to show/hide the Results from the after the API trigger */
  const [showResultOption, setshowResultOption] = useState(false);

  /* Static data for the difficulty params */

  const [APIRangeValue, setAPIRangeValue] = useState(rangeValue);
  const [APIDifficultyValue, setAPIDifficultyValue] = useState();
  const [difficult] = useState(difficultyValue);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInteractiveOnlyAction(interactiveOnly));
    setselectedFilterParams(selectedParams);
    setSelectedAPIData(selectedAPIParams);
  }, [interactiveOnly]);

  const [selectedFilterParams, setselectedFilterParams] = useState({
    duration: APIRangeValue,
    bodyFocus: [],
    difficulty: difficult,
    equipments: [],
    music: [],
  });

  /* The below state maintains the API filter params and maintained in the local state */

  const [selectedAPIData, setSelectedAPIData] = useState({
    keyword: '',
    explicitLang: false,
    duration: APIRangeValue,
    bodyFocus: [],
    difficulty: APIDifficultyValue,
    equipments: [],
    music: [],
    selectedCount: null,
    showFilterOption: showFilterOption,
    showResultOption: showResultOption,
  });

  const [keyword, setKeyword] = useState('');

  const onSelectedRange = value => {
    selectedAPIData.duration = [];
    selectedFilterParams.duration = [];
    const result = APIRangeValue.filter(
      val => val >= value[0] && val <= value[1],
    );
    selectedAPIData.duration = result;
    selectedFilterParams.duration = value;
    props.onChangedValue(
      filterData,
      selectedFilterParams,
      selectedAPIData,
      rangeValue,
      difficultyValue,
    );
  };

  const onExplicitLang = value => {
    selectedAPIData.explicitLang = value;
    props.onChangedValue(
      filterData,
      selectedFilterParams,
      selectedAPIData,
      rangeValue,
      difficultyValue,
    );
  };

  const onSelected = (id?, mainTitle?, selected?, title?, index?) => {
    if (mainTitle !== 'duration') {
      filterData[mainTitle].values[index].isSelected = !filterData[mainTitle]
        .values[index].isSelected;
      selectedFilterParams[mainTitle] = [];
      selectedAPIData[mainTitle] = [];
      const difficultyResult = filterData[mainTitle].values.filter(itm => {
        if (itm.isSelected) {
          return itm;
        }
      });
      if (mainTitle === 'difficulty') {
        selectedFilterParams[mainTitle] = difficultyResult;
        const APIResults = selectedFilterParams[mainTitle].map(itm => {
          return itm.title;
        });
        selectedAPIData[mainTitle] = APIResults;
      } else {
        selectedFilterParams[mainTitle] = difficultyResult;
        const APIResults = selectedFilterParams[mainTitle].map(itm => {
          return itm.id;
        });
        selectedAPIData[mainTitle] = APIResults;
      }
      props.onChangedValue(
        filterData,
        selectedFilterParams,
        selectedAPIData,
        rangeValue,
        difficultyValue,
      );
    } else {
      // let value = filterDuration;
      selectedAPIData.duration = [];
      selectedFilterParams.duration = [];
      selectedAPIData.duration = APIRangeValue;
      selectedFilterParams.duration = APIRangeValue;
      props.onChangedValue(
        filterData,
        selectedFilterParams,
        selectedAPIData,
        rangeValue,
        difficultyValue,
      );
    }
  };
  const onSelectedKeyword = value => {
    selectedAPIData.keyword = value;
    if (value.length <= 2) {
      if (value.length == 0) {
        selectedAPIData.keyword = '';
      }
      setKeyword(value);
      selectedAPIData.selectedCount = 0;
    } else if (value.length > 2) {
      setKeyword(value);
      props.onChangedValue(
        filterData,
        selectedFilterParams,
        selectedAPIData,
        rangeValue,
        difficultyValue,
      );
    }
  };

  function dispatchSearchAPIcall() {
    selectedAPIData.showFilterOption = false;
    selectedAPIData.showResultOption = true;
    // dispatch(searchResults(selectedAPIData));
    dispatch(updateVideoList());
    // props.closeFilterPanel();
  }
  if (filterData) {
    return (
      <div className={classes.root}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={8}>
            <Grid item lg={12}>
              <div className={classes.header}>
                <div className={classes.title__heading}>Search & Filter</div>
                <div
                  className={classes.close__icon}
                  onClick={() => {
                    props.closeFilterPanel();
                    dispatch(reupdateVideoList());
                  }}
                >
                  <SvgOutlined />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={12} sm={12} lg={11}>
              <div className={classes.paper}>
                {/* <div className={classes.title}>
                 <div>Clear All</div>
                 <div className={classes.title__heading}>Filter</div>
                 <div onClick={() => props.closeFilterPanel(false)}> X</div>
               </div> */}
                <Grid item className={classes.auto__margin}>
                  <div>
                    <FilterSearchLabel
                      predictions={filterData.searchSuggestions}
                      onChange={val => {
                        onSelectedKeyword(val);
                      }}
                      values={selectedAPIData.keyword}
                      onClick={dispatchSearchAPIcall}
                    />
                  </div>
                  {/* <div className={classes.expLang}>
                    <FilterCheckBox
                      onChange={val => {
                        onExplicitLang(val);
                      }}
                    />
                  </div>
                  <div className={classes.line} /> */}
                </Grid>

                <div>
                  <Grid item className={classes.auto__margin}>
                    {filterData &&
                      filterData['duration'] &&
                      filterData['duration'].values &&
                      filterData['duration'].values.length > 0 && (
                        <FilterAccordion
                          title="Duration"
                          // selectedValue={selectedAPIData.duration}
                          selectedValue={selectedFilterParams.duration}
                          value={filterData['duration'].values}
                        >
                          <FilterRangeSlider
                            value={selectedFilterParams.duration}
                            onChange={val => {
                              onSelectedRange(val);
                            }}
                          />
                        </FilterAccordion>
                      )}
                    {filterData['bodyFocus'] &&
                      filterData['bodyFocus'].values &&
                      filterData['bodyFocus'].values.length > 0 && (
                        <FilterAccordion
                          title={
                            filterData['bodyFocus'].title
                              ? filterData['bodyFocus'].title
                              : ''
                          }
                          selectedValue={selectedFilterParams.bodyFocus}
                        >
                          <div style={{ width: '100%' }}>
                            {filterData['bodyFocus'].values.map(
                              (value, index) => {
                                return (
                                  <FilterPickerButton
                                    key={value.id}
                                    title={value.title}
                                    selected={value.isSelected}
                                    onSelect={() => {
                                      onSelected(
                                        value.id,
                                        value.mainTitle,
                                        value.isSelected,
                                        value.title,
                                        index,
                                      );
                                    }}
                                  />
                                );
                              },
                            )}
                          </div>
                        </FilterAccordion>
                      )}
                    {filterData['difficulty'] &&
                      filterData['difficulty'].values &&
                      filterData['difficulty'].values.length && (
                        <FilterAccordion
                          title={
                            filterData['difficulty'].title
                              ? filterData['difficulty'].title
                              : ''
                          }
                          selectedValue={selectedFilterParams.difficulty}
                        >
                          {filterData &&
                            filterData['difficulty'] &&
                            filterData['difficulty'].values && (
                              <FilterDifficultySelector
                                value={filterData['difficulty'].values}
                                onSelect={(
                                  id,
                                  mainTitle,
                                  isSelected,
                                  title,
                                  index,
                                ) => {
                                  onSelected(
                                    id,
                                    mainTitle,
                                    isSelected,
                                    title,
                                    index,
                                  );
                                }}
                              />
                            )}
                        </FilterAccordion>
                      )}

                    {filterData['equipments'] &&
                      filterData['equipments'].values &&
                      filterData['equipments'].values.length > 0 && (
                        <FilterAccordion
                          title={
                            filterData['equipments'].title
                              ? filterData['equipments'].title
                              : ''
                          }
                          selectedValue={selectedFilterParams.equipments}
                        >
                          <div style={{ width: '100%' }}>
                            {filterData['equipments'].values.map(
                              (value, index) => {
                                return (
                                  <FilterPickerButton
                                    key={value.id}
                                    title={value.title}
                                    selected={value.isSelected}
                                    onSelect={() => {
                                      onSelected(
                                        value.id,
                                        value.mainTitle,
                                        value.isSelected,
                                        value.title,
                                        index,
                                      );
                                    }}
                                  />
                                );
                              },
                            )}
                          </div>
                        </FilterAccordion>
                      )}

                    {filterData['music'] &&
                      filterData['music'].values &&
                      filterData['music'].values.length > 0 && (
                        <FilterAccordion
                          title={
                            filterData['music'].title
                              ? filterData['music'].title
                              : ''
                          }
                          selectedValue={selectedFilterParams.music}
                        >
                          <div style={{ width: '100%' }}>
                            {filterData['music'].values.map((value, index) => {
                              return (
                                <FilterPickerButton
                                  key={value.id}
                                  title={value.title}
                                  selected={value.isSelected}
                                  onSelect={() => {
                                    onSelected(
                                      value.id,
                                      value.mainTitle,
                                      value.isSelected,
                                      value.title,
                                      index,
                                    );
                                  }}
                                />
                              );
                            })}
                          </div>
                        </FilterAccordion>
                      )}
                  </Grid>
                </div>
              </div>
              {/*
             {filterState.isSearchResultsFetching &&
               selectedAPIData.showResultOption &&
               (selectedAPIData.selectedCount === 0 ||
                 selectedAPIData.selectedCount === null) && <FilterNoResults />}  */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return null;
  }
}
