import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
    '& .MuiTableRow-root': {
      'td.MuiTableCell-root.MuiTableCell-body:nth-child(1)': {
        flex: '1',
        width: '40%',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(2)': {
        width: '34%',
        minWidth: '224px',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(3)': {
        minWidth: '200px',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(4)': {
        width: '10%',
      },
    },
  },
  resetBlk: {
    // textAlign: 'center',
    margin: '0 20px 20px',
    '& .MuiButton-fullWidth': {
      margin: '0',
    },
    '& section': {
      // margin: '24px 0',
    },
  },
  rightContainer: {
    width: '650px',
  },
  container: {
    maxHeight: '100%',
  },
  btnContainer: {
    padding: '20px',
    position: 'absolute',
    bottom: '0',
    textAlign: 'center',
    width: '100%',
    borderTop: '1px solid #D9D9D9',
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  box: {
    height: '64px',
    width: '64px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '64px',
      height: 'auto',
    },
  },
  firstColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'inherit',
  },
  firstCoulmContent: {
    textAlign: 'start',
    paddingLeft: '16px',
  },
  thirdColumn: {
    height: '20px',
    width: '70px',
    color: '#3E4142',
    fontFamily: 'ProximaNova-Bold',
    fontSize: '8px',
    fontWeight: 'bold',
    letterSpacing: '0.57px',
    lineHeight: '10px',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  draft: {
    boxSizing: 'border-box',
    border: '1px solid #E8EBED',
    backgroundColor: '#E8EBED',
  },
  published: {
    boxSizing: 'border-box',
    border: '1px solid #E8EBED',
  },
  title: {
    padding: '16px 24px',
    color: '#12161A',
    fontFamily: 'ProximaNova-Bold',
    fontSize: '16px',
    lineHeight: '20px',
    float: 'left',
  },
  closeBtn: {
    float: 'right',
    paddingTop: '15px',
  },
  titleDiv: {
    width: '100%',
    float: 'left',
  },
  titleWrapper: {
    width: '100%',
    overflow: 'hidden',
    padding: '20px',
    paddingBottom: '0px',
  },
  secondRow: {
    width: '100%',
    overflow: 'hidden',
    padding: '5px 20px 0 20px',
  },
  perLabel: {
    margin: '0',
    fontFamily: 'ProximaNova-Bold',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0',
  },
  typeInput: {
    width: '100%',
  },
  checkboxBlock: {},
  customStyle: {
    width: '50%',
    marginRight: 0,
  },
  line: {
    width: '100%',
    marginBottom: '20px',
  },
  divider: {
    width: '100%',
    overflow: 'hidden',
  },
  attributeTitle: {
    color: '#12161A',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '17px',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  formControlLabel: {
    fontFamily: 'Proxima-Nova-Regular',
    color: '#909599',
    fontSize: '15px',
    letterSpacing: '0',
    lineHeight: '20px',
  },
  delBtn: {
    backgroundColor: '#D0021B',
    color: '#FFF',
    height: '40px',
    lineHeight: '40px',
    borderRadius: '6px',
    width: '100px',
  },
  resetButton: {
    height: '40px',
    width: '300px',
    color: '#FFF',
    lineHeight: 3,
    borderRadius: '6px',
    backgroundColor: '#12161A',
  },
  saveLegalBtn: {
    height: '40px',
    width: '100px',
    color: '#FFF',
    lineHeight: '40px',
    borderRadius: '6px',
    backgroundColor: '#12161A',
  },
  saveLegalBtn1: {
    height: '40px',
    width: '300px',
    color: '#FFF',
    lineHeight: 3,
    borderRadius: '6px',
    backgroundColor: '#12161A',
    fontSize: '15px',
    fontWeight: 'bold',
    letterSpacing: '1.15px',
  },
  text: {
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '20px',
    color: '#909599',
  },
  imgContainer: {
    backgroundColor: '#000 !important',
    padding: '10px !important',
    margin: '0 auto !important',
    maxWidth: '250px',
    height: '125px',
    backgroundSize: 'contain',
  },
  editIcon: {
    width: '36px',
    height: '36px',
    color: '#000000',
    padding: '6px',
  },
});
