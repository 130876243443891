import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SvgIconEdit, SvgIconAlert } from '../../../components/NeIcons';
import moment from 'moment';
import { useStyles } from './styles';
import { IconButton, Avatar } from '@material-ui/core';
import ChallengeFallbackIcon from '../../../images/challenge_fallback_icon.png';
import ProgramFallbackIcon from '../../../images/program_fallback_icon.png';
import InterestFallbackIcon from '../../../images/interest_fallback_icon.png';
import CollectionFormDialog, {
  FormType,
} from '../components/CollectionFormDialog';

export default function CollectionListTable(props) {
  const classes = useStyles();

  let { list, isLoading } = props;
  const [formDialogInfo, setFormDialogInfo] = React.useState({
    isOpen: false,
    id: '',
  });

  const getDate = list => {
    if (list.isPublished) {
      return (
        <div>
          {`Updated ${moment(list.updatedAt).format(`L`)}`} <br /> Published{' '}
          {`${moment(list.publishedDate).format(`L`)}`}
        </div>
      );
    } else {
      return `Saved ${moment(list.updatedAt).format(`L`)}`;
    }
  };

  const renderCTA = ({
    isPublished = false,
    isHidden = false,
    isError = false,
  }) => {
    if (isHidden) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'HIDDEN'}
        </div>
      );
    } else if (isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'PUBLISHED'}
        </div>
      );
    } else if (!isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.draft}`}>
          {'DRAFT'}
        </div>
      );
    }
  };
  const collectionEdit = id => {
    // dispatch(getCollectionDetail(id));
    setFormDialogInfo({ isOpen: true, id });
  };

  const getFallbackIcon = collectionType => {
    switch (collectionType) {
      case 'program':
        return ProgramFallbackIcon;
      case 'challenge':
        return ChallengeFallbackIcon;
      case 'interest':
        return InterestFallbackIcon;
      default:
        return ProgramFallbackIcon;
    }
  };

  return (
    <>
      {formDialogInfo.isOpen && (
        <CollectionFormDialog
          isOpen={formDialogInfo.isOpen}
          onClose={() => setFormDialogInfo({ isOpen: false, id: '' })}
          title="Edit Collection"
          type={FormType.EDIT}
          id={formDialogInfo.id}
        ></CollectionFormDialog>
      )}
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead></TableHead>
            <TableBody>
              {list &&
                list.map(list => {
                  return (
                    <TableRow key={list.id} hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">
                        <div className={classes.firstColumn}>
                          <div className={classes.box}>
                            <Avatar
                              variant="square"
                              src={list.graphics && list.graphics['1x1']}
                              classes={{
                                root: classes.iconHolder,
                                square: classes.iconSquare,
                                img: classes.iconImg,
                              }}
                            >
                              <img src={getFallbackIcon(list.collectionType)} />
                            </Avatar>
                          </div>
                          <div className={classes.firstCoulmContent}>
                            <div className={classes.conceptTitle}>
                              {list.title}
                            </div>
                            <div className={classes.text}>
                              {!list.kicker ? (
                                <div>{'kicker undefined'}</div>
                              ) : (
                                <span>{list.kicker}</span>
                              )}
                            </div>
                            <div className={classes.text}>
                              {list.classCount + ' videos'}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className={classes.text}>{getDate(list)}</div>
                      </TableCell>
                      <TableCell align="center">{renderCTA(list)}</TableCell>
                      <TableCell align="right">
                        {list.isError ? (
                          <div>
                            <SvgIconAlert />
                          </div>
                        ) : (
                          <IconButton
                            onClick={() => collectionEdit(list.id)}
                            classes={{ root: classes.editIcon }}
                          >
                            <SvgIconEdit />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
