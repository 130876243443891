import {
  Button,
  Divider,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { object, string } from 'yup';
import FormikField from '../../../components/Formik/FormikField';

export const ComponentFormSchema = object({
  invitePeopletoNeoU: string().required(),
  inviteDisplayText: string().required(),
});

export const ComponentEditor = ({
  componentFormInfo,
  handleEditFormSubmit,
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={componentFormInfo && componentFormInfo.item}
      enableReinitialize
      validationSchema={ComponentFormSchema}
      onSubmit={handleEditFormSubmit}
    >
      {({ values, setFieldValue, isValid, errors }) => {
        return (
          <Form noValidate>
            <div className={classes.drawerBody}>
              <Grid
                container
                alignItems="flex-start"
                className={classes.editContainer}
              >
                <FormikField
                  name="invitePeopletoNeoU"
                  label={'Invite People to NeoU *'}
                  placeholder="Enter email invite message"
                  multiline
                  rows={7}
                ></FormikField>
                <FormikField
                  name="inviteDisplayText"
                  label={'Invite Display Text *'}
                  placeholder="Enter email invite message"
                  multiline
                  rows={7}
                ></FormikField>
              </Grid>
            </div>

            <Divider />
            <div className={classes.ctaWrap}>
              <div className={classes.drawerFooter}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    if (isValid) {
                      //setFieldValue('isPublished', true);
                    }
                  }}
                >
                  Save & Publish changes
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerBody: {
      height: 'calc(100vh - 144px)',
      overflow: 'auto',
    },
    editContainer: {
      margin: '24px',
      width: '92%',
      zIndex: '5',
    },
    imgURL: {
      width: '70%',
      margin: '0px auto',
      height: 'auto',
    },
    ctaWrap: {
      display: 'flex',
    },
    drawerFooter: {
      padding: '16px 6px',
      display: 'flex',
      width: '100%',
      '& .MuiButton-root': {
        marginRight: 8,
      },
    },
  }),
);
