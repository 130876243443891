import React, { useState } from 'react';
import SearchVideo from './SearchVideo';
import request from '../shared/lib/request';
import { Divider } from '@material-ui/core';
import { getUniqItems } from '../utils/array';
import DraggableListData from './DraggableInteractiveList';
import { getTimeAfterMinutes, UnivDate } from '../utils/calender';
import moment from 'moment';
import {
  ErrorText,
} from '../components/Typography';

interface VideoID {
  [key: string]: string;
}

export interface video {
  id: string;
  text: string;
  title: string;
  videoID: VideoID;
}

interface getSubTitleFunc {
  (): string;
}

interface getStartDateFunc {
  (): string;
}

interface SearchVideoComponentProps {
  videoList: video[];
  onVideoListChange: (newValue: video[], action: string) => void;
  id?: any;
  subTitle?: string | getSubTitleFunc;
  startDate?: string | getStartDateFunc;
  onChange?: (videoList) => void;
  errorMessage?: any;
}

export const SearchTypeInteractiveComponent: React.FC<
  SearchVideoComponentProps
> = ({
  videoList: defaultValue,
  startDate = () => { },
  subTitle = () => { },
  onChange = () => { },
  errorMessage = () => { },
}) => {
    const [isSearchResult, setSearchVideo] = useState([]);
    const [videos, setVideos] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [inputRef, setInputRef] = useState(null);
    let timeout;

    const handleSearchInputChange = value => {
      timeout = setTimeout(() => {
        setSearchText('');
        if (inputRef) inputRef.value = '';
      }, 1000);
      if (value) {
        setSearchText(
          value.interactive && value.interactive.title
            ? value.interactive.title
            : value.title,
        );
        let uniqueList = getUniqItems([value, ...videos], 'id');
        setVideos(uniqueList);
        onChange([value, ...videos]);
      }
    };

    function searchVideos(data) {
      return request({
        url: `/neouadmin/v1/search`,
        method: 'POST',
        data: {
          isUpcoming: true,
          keyword: data,
          interactive: {
            isPublished: true,
            isHidden: false,
          },
          videoType: ['groupInteractive'],
        },
      });
    }
    const onSearchChange = async value => {
      try {
        let result = await searchVideos(value);
        setSearchVideo(result.result.classes.result);
      } catch (error) {
        setSearchVideo([]);
      }
    };

    React.useEffect(() => {
      setVideos(defaultValue);
      return () => {
        clearTimeout(timeout);
      };
    }, [defaultValue]);

    function getLastUpdateTime() {
      var momentTZ = require('moment-timezone');
      var zone = momentTZ.tz.guess();
      const lastUpdateTime = momentTZ.tz(zone).format('z');
      return lastUpdateTime;
    }

    function getTime(startDate, duration) {
      let time;
      if (startDate && duration) {
        time =
          moment(UnivDate(startDate))
            .utc()
            .format('ddd, MMM D') +
          ' • ' +
          moment(UnivDate(startDate))
            .utc()
            .format('LT') +
          ' ' +
          getTimeAfterMinutes(startDate, duration) +
          ' ' +
          getLastUpdateTime();
      } else {
        time =
          moment(UnivDate(startDate))
            .utc()
            .format('ddd, MMM D') +
          ' • ' +
          moment(UnivDate(startDate))
            .utc()
            .format('LT') +
          ' ' +
          getLastUpdateTime();
      }
      return time;
    }

    return (
      <div style={{ width: '100%' }}>
        <SearchVideo
          options={isSearchResult || []}
          name="searchVideos"
          labelText="Add an Item: Search for Class title"
          inputValue={searchText}
          onChange={(event, value) => handleSearchInputChange(value)}
          limitTags={2}
          onInputChange={(event, value) => onSearchChange(value)}
          // getOptionLabel={option =>
          //   option.interactive && option.interactive.title
          //     ? option.interactive.title
          //     : option.title
          // }
          getOptionLabel={option =>
            `${option.interactive && option.interactive.title
              ? option.interactive.title
              : option.title
            } at ${getTime(option.startDate, option.duration || 0)}`
          }
          onSearchTextChange={e => setSearchText(e)}
          ref={inputRef}
          setInputRef={ref => setInputRef(ref)}
          blurOnSelect
          clearOnBlur
        />
        <ErrorText>
          <h2>{errorMessage()}</h2>
        </ErrorText>
        <Divider style={{ backgroundColor: '#999FA4', marginBottom: 8 }} />
        <DraggableListData
          list={videos}
          onListChange={(list, updateType) => {
            setVideos(list);
            onChange(list);
          }}
          startDate={startDate}
          subTitle={subTitle}
        />
      </div>
    );
  };
