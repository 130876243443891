import * as React from 'react';
import styled from 'styled-components';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Select,
  MenuItem,
  List,
  ListItem,
  IconButton,
} from '@material-ui/core';

import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import saga from '.././saga';
import reducer from '.././reducer';
import makeSelectVideo from './../selectors';
import LabelInput from '../../../components/LabelInput';
import AddButton from '../../../components/AddButton';
import TextField from '@material-ui/core/TextField';
import CancelButton from '../../../components/CancelButton';

import { VideoEvent } from '../../../validators/Authentication';
import { formError, customMessage } from '../../../utils/validator';
import { reEncodeVideo } from '.././actions';
import StyledDialog from '../../../components/StyledDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';

import {
  SvgIconPlayArrow,
  SvgDownload,
  SvgOverflow,
  SvgDelete,
} from '../../../components/NeIcons';

const useStyles = makeStyles(theme => ({
  common: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  item: {
    display: 'flex',
  },
  itemLeft: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    justifyContent: 'flex-start',
  },
  itemRight: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '8px',
    width: '50%',
    justifyContent: 'space-evenly',
    cursor: 'pointer',
  },
  box: {
    height: '64px',
    width: '64px',
    borderRadius: '6px',
    backgroundColor: '#D4D8DC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      width: '18px',
      height: '24px',
    },
  },
  txt: {
    fontSize: '12px',
    color: '#4A4A4A',
    padding: '0px 10px',
    textTransform: 'uppercase',
  },
  txtSec: {
    fontSize: '16px',
    color: '#12161A',
    padding: '10px',
  },

  root: {},
}));

const stateSelector = createStructuredSelector({
  video: makeSelectVideo(),
});

export default function ManageRenditions(props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'video', reducer: reducer });
  useInjectSaga({ key: 'video', saga: saga });

  const { video } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { value, timing, videoId } = props;
  const { videos } = value;
  const [error, setError] = React.useState({
    videoTitle: '',
  });
  const [videoTitle, setvideoTitle] = React.useState('');
  const [isManageRenditonsOpen, openManageRenditons] = React.useState(false);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);

  function handleTitleInput(e) {
    setvideoTitle(e.target.value);
  }

  const validateForm = () => {
    const { error, value } = VideoEvent.validate(
      { videoTitle },
      {
        abortEarly: false,
        messages: customMessage,
      },
    );
    return formError(error);
  };

  const removeSelectedRenditions = index => {
    let newVideos = videos;
    newVideos.splice(index, 1);
    props.onChange(newVideos);
  };

  return (
    <div>
      <StyledDialog
        isOpen={props.value}
        title="MANAGE RENDITIONS"
        handleClose={() => {
          props.onClearEvent();
        }}
      >
        {videos &&
          videos.length > 0 &&
          videos.map((item, index) => {
            return (
              <div className={classes.item}>
                <div className={classes.itemLeft}>
                  <div
                    className={classes.box}
                    onClick={() => window.open(item.url, '_blank')}
                  >
                    <SvgIconPlayArrow />
                  </div>

                  <List>
                    <ListItem className={classes.txt}>
                      {item.codec} - {item.resolution}
                    </ListItem>
                    <ListItem className={classes.txt}>{item.bitrate}</ListItem>
                    <ListItem className={classes.txtSec}>
                      {props.timing}
                    </ListItem>
                  </List>
                </div>
                <div className={classes.itemRight}>
                  <IconButton onClick={() => window.open(item.url, '_blank')}>
                    <SvgDownload />
                  </IconButton>
                  <SvgDelete onClick={() => showAlertToClose(true)} />
                  <SvgOverflow />
                </div>
                {isCloseAlertOpen && (
                  <ConfirmDialog
                    isOpen={isCloseAlertOpen}
                    headerText="Are you sure?"
                    bodyText="You are about to delete the rendition video."
                    onAgree={() => removeSelectedRenditions(index)}
                    handleClose={() => showAlertToClose(false)}
                    onDisagree={() => showAlertToClose(false)}
                  ></ConfirmDialog>
                )}
              </div>
            );
          })}

        <AddButton
          title={'RE-ENCODE ALL'}
          style={{ width: '100%' }}
          onClick={() => dispatch(reEncodeVideo(videoId))}
        />
      </StyledDialog>
    </div>
  );
}
