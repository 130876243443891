import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { ApplicationRootState } from '../../types';

// To parse this data:
//
//   import { Convert, HomeCurate } from "./file";
//
//   const homeCurate = Convert.toHomeCurate(json);
export interface HomeCurate {
  topSection: TopSection[];
  lowerSection: LowerSection[];
  segments: Segment[];
}

export interface LowerSection {
  componentTitle: string;
  componentType: string;
  contentType: string;
  componentOrder: ComponentOrder;
  userSegments: UserSegment[];
  content: LowerSectionContent;
  currentComponentOrder: number;
  updatedAt?: number;
}

export interface ComponentOrder {
  [key: string]: number;
}

export enum ComponentType {
  Tray = 'tray',
  Carousel = 'carousel',
  ProgramCarousel = 'programsCarousel',
}

export interface LowerSectionContent {
  data?: PurpleDatum[];
  mode: string;
}

export interface PurpleDatum {
  id: string;
  order: number;
  title?: string;
}

export enum Mode {
  Auto = 'auto',
  ManuallyCurated = 'manuallyCurated',
}

export enum ContentType {
  Collections = 'collections',
  Concept = 'concept',
  Videos = 'videos',
  Promo = 'promo',
  Instructor = 'instructor',
  PromoTray = 'bannerImg',
}

export enum ComponentSection {
  Programs = 'program',
  Challenges = 'challenge',
  Collections = 'collections',
  Concept = 'concept',
}

export enum UserSegment {
  AllUsers = 'No Fitness Profile',
}

export interface Segment {
  id: string;
  title: string;
  type: string;
}

export interface TopSection {
  componentType: string;
  componentTitle: string;
  contentType: string;
  componentOrder: ComponentOrder;
  content: TopSectionContent;
  userSegments: UserSegment[];
  currentComponentOrder: number;
  updatedAt?: number;
}

export interface TopSectionContent {
  data: FluffyDatum[];
  mode: Mode;
}

export interface FluffyDatum {
  permaLink?: string;
  imgUrl?: string;
  order: number;
  id?: string;
  text?: string;
  title?: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toHomeCurate(json: string): HomeCurate {
    return JSON.parse(json);
  }

  public static homeCurateToJson(value: HomeCurate): string {
    return JSON.stringify(value);
  }
}

/* --- STATE --- */
interface HomeCurateState {
  readonly topSection: TopSection[];
  readonly lowerSection: LowerSection[];
  readonly segments: Segment[];
  readonly isLoading: boolean;
}

/* --- ACTIONS --- */
type HomeCurateActions = ActionType<typeof actions>;

/* --- EXPORTS --- */
type RootState = ApplicationRootState;
type ContainerState = HomeCurateState;
type ContainerActions = HomeCurateActions;

export { RootState, ContainerState, ContainerActions };
