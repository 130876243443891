import React, { useEffect, useState } from 'react';
import { DrawerView } from '../../containers/HomeCurate/components/DrawerView';
import { Form, Formik } from 'formik';
import {
  Grid,
  Drawer,
  Typography,
  Button,
  Divider,
  makeStyles,
  Theme,
  createStyles,
  MenuItem,
  ButtonBase,
} from '@material-ui/core';
import ConfirmDialog from '../ConfirmDialog';
import FormikField from '../Formik/FormikField';

const useStylesLocal = makeStyles((theme: Theme) =>
  createStyles({
    drawerBody: {
      height: 'calc(100vh - 144px)',
      overflow: 'auto',
      overflowX: 'hidden',
    },
    drawerFooter: {
      padding: '16px',
      display: 'flex',
      '& .MuiButton-root': {
        marginRight: 8,
      },
    },

    quantity: {
      '& .MuiInputBase-input': {
        color: '#000 !important',
      },
    },
    viewPackages: {
      textTransform: 'capitalize',
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 10px',
      height: '24px',
      width: '55px',
    },
    packageType: {
      marginTop: '22px',
      marginLeft: '20px',
      marginRight: '20px',
    },
    priceGrp: {
      marginLeft: '12px',
      marginRight: '12px',
    },
    description: {
      marginLeft: '20px',
      marginRight: '20px',
    },
  }),
);
const initialFormValues = {
  nickname: '',
  price: '',
  description: '',
  classes: '',
  id: '',
};

export default function ViewPackage(props) {
  const [componentFormInfo, setComponentFormInfo] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleEditFormSubmit = () => {};
  const handleEditDrawerClose = () => setComponentFormInfo(false);
  const [formValues, setFormValues] = useState(initialFormValues);

  const classes = useStylesLocal();

  return (
    <>
      <ButtonBase
        className={classes.viewPackages}
        onClick={() => {
          setComponentFormInfo(true);
          setFormValues({ ...initialFormValues, ...props });
        }}
      >
        View
      </ButtonBase>
      <Drawer
        anchor={'right'}
        open={componentFormInfo}
        disableBackdropClick={true}
        onClose={() => showAlertToClose(true)}
      >
        <ConfirmDialog
          isOpen={isCloseAlertOpen}
          headerText="Are you sure?"
          bodyText="You are about to close the view package."
          onAgree={() => handleEditDrawerClose()}
          handleClose={() => showAlertToClose(false)}
          onDisagree={() => showAlertToClose(false)}
        ></ConfirmDialog>

        <DrawerView handleClose={showAlertToClose} title={'VIEW PACKAGE'}>
          <Formik
            initialValues={formValues}
            enableReinitialize
            validationSchema={() => {}}
            onSubmit={!isError && handleEditFormSubmit}
          >
            {({
              values,
              setFieldValue,
              submitForm,
              errors,
              isValid,
              setErrors,
              validateForm,
              setTouched,
              handleSubmit,
            }) => {
              return (
                <Form onSubmit={() => {}}>
                  <>
                    <div className={classes.drawerBody}>
                      <Grid container>
                        <Grid item xs={12} className={classes.packageType}>
                          <FormikField
                            name="nickname"
                            label="Package Type"
                            disabled={true}
                          />
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          className={classes.priceGrp}
                        >
                          <Grid item xs={5}>
                            <FormikField
                              name="classes"
                              label="Class Quantity"
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <FormikField
                              name="price"
                              label="Package Price"
                              disabled={true}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.description}>
                          <FormikField
                            name="description"
                            label="Package Description"
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} className={classes.description}>
                          <FormikField
                            name="id"
                            label="Strip Product ID"
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <Divider />
                    <div className={classes.drawerFooter}>
                      <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          showAlertToClose(true);
                        }}
                      >
                        CLOSE
                      </Button>
                    </div>
                  </>
                </Form>
              );
            }}
          </Formik>
        </DrawerView>
      </Drawer>
    </>
  );
}
