import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  SvgIconPlayArrow,
  SvgIconEdit,
  SvgIconAlert,
} from '../../../components/NeIcons';
import moment from 'moment';
import { useStyles } from './styles';
import Loader from '../../../components/Loader';
import { IconButton } from '@material-ui/core';
import EditDialog from './EditDialog';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

export default function AttributeListTable(props) {
  const classes = useStyles();

  let { list, isLoading } = props;

  const getDate = list => {
    if (list.isVisible) {
      return <div>{`Updated ${moment(list.createdAt).format(`L`)}`}</div>;
    } else {
      return `Created ${moment(list.createdAt).format(`L`)}`;
    }
  };
  const renderCTA = ({
    isPublished = false,
    isHidden = false,
    isError = false,
  }) => {
    if (isError) {
      return <div></div>;
    }
    if (isHidden) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'HIDDEN'}
        </div>
      );
    } else if (isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'PUBLISHED'}
        </div>
      );
    } else if (!isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.draft}`}>
          {'DRAFT'}
        </div>
      );
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead></TableHead>
          <TableBody>
            <TableRow key={list.id} tabIndex={-1}>
              <TableCell align="left" style={{ paddingLeft: '30px' }}>
                <div className={classes.attributeTitle}>Name</div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.attributeTitle}>Type</div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.attributeTitle}>Last Updated</div>
              </TableCell>
              <TableCell align="center">
                <div className={classes.attributeTitle}></div>
              </TableCell>
            </TableRow>
            {list &&
              list.length > 0 &&
              list.map(list => {
                return (
                  <TableRow key={list.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell align="left">
                      <div className={classes.firstColumn}>
                        <div className={classes.firstCoulmContent}>
                          <div className={classes.attributeTitle}>
                            {!isEmpty(list.name) ? list.name : '-'}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={classes.text}>{list.role}</div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={classes.text}>{getDate(list)}</div>
                    </TableCell>

                    <TableCell align="right">
                      <EditDialog
                        id={list.id}
                        classes={classes}
                        administratorDetail={list || {}}
                      ></EditDialog>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
