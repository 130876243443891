import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the myAccount state domain
 */

const selectMyAccountDomain = (state: ApplicationRootState) => {
  return state.myAccount || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by MyAccount
 */

const makeSelectMyAccount = () =>
  createSelector(
    selectMyAccountDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectMyAccount;
export { selectMyAccountDomain };
