import * as React from 'react';
import { makeStyles, Theme, Grid } from '@material-ui/core';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useDispatch } from 'react-redux';
import saga from '../saga';
import reducer from '../reducer';
import LabelInput from '../../../components/LabelInput';
import AddButton from '../../../components/AddButton';
import CancelButton from '../../../components/CancelButton';
import { groupEvent } from '../../../validators/Authentication';
import { formError, customMessage } from '../../../utils/validator';
import { addClassEvent, requestInteractiveVideoList } from '../actions';
import moment from 'moment';
import { slugify } from '../../../utils/slugify';
import request from '../../../shared/lib/request';
import { openSnackBar } from '../../Root/actions';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import StartDateTime from '../../../components/Formik/StartDateTime';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },

  common: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  startTime: {
    width: '55%',
  },
  durations: {
    width: '40%',
    '& label.Mui-focused': {
      color: '#909599',
      '&.Mui-error': {
        color: '#FF1C58',
      },
    },
    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#909599',
      },
      '&:hover fieldset': {
        borderColor: '#909599',
      },
      '&.Mui-focused fieldset': {
        border: `1px solid #909599`,
      },
    },
  },

  root: {},
}));

export default function AddClasses(props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'interactiveClass', reducer: reducer });
  useInjectSaga({ key: 'interactiveClass', saga: saga });

  const dispatch = useDispatch();
  const classes = useStyles();

  const [error, setError] = React.useState({
    classTitle: '',
    interactiveURL: '',
  });
  const [classTitle, setClassTitle] = React.useState('');
  const [interactiveURL, setInteractiveURL] = React.useState('/classes/');
  const SLUG_PREFIX = '/classes/';
  const [selectedDate, setSelectedDate] = React.useState(
    moment(new Date()).add(10, 'minutes'),
  );

  function handleTitleInput(e) {
    setClassTitle(e.target.value);
  }
  function handleStreamURLInput(e) {
    setInteractiveURL(e.target.value);
  }

  const validateForm = () => {
    const { error, value } = groupEvent.validate(
      { classTitle, interactiveURL },
      {
        abortEarly: false,
        messages: customMessage,
      },
    );
    return formError(error);
  };

  const onAddEvent = () => {
    const e: any = validateForm();
    setError(e);
    const data = {
      videoType: 'groupInteractive',
      title: classTitle,
      permaLink: interactiveURL,
      interactive: { type: '2-way video', dropInPrice: 0, spots: 50 },
      startDate: moment(selectedDate)
        .utc()
        .valueOf()
        .toString(),
      isPublished: false,
      isHidden: false,
    };
    if (Object.keys(e).length === 0) {
      let currentTime = moment(Date.now());
      let startTime = moment(selectedDate);
      let isStarted = startTime.diff(currentTime) >= 30000;
      if (!isStarted) {
        dispatch(openSnackBar('Interactive class should have future time'));
        return;
      } else {
        addClass(data);
      }
    }
  };

  const addClass = async data => {
    try {
      await request({
        url: `/neouadmin/v1/videos`,
        method: 'POST',
        data: data,
      });
      dispatch(openSnackBar('Class Added to Draft Successfully'));
      dispatch(requestInteractiveVideoList());
      onClearEvent();
    } catch ({ data: error }) {
      if (error.error) {
        dispatch(openSnackBar(`Failed to add class -${error.error}`));
      } else {
        dispatch(openSnackBar('Class Adding Failed'));
      }
    }
  };

  function onClearEvent() {
    props.onClearEvent();
    setClassTitle('');
    setInteractiveURL('/classes/');
    setSelectedDate(moment(new Date()).add(10, 'minutes'));
  }

  const generatePermalink = () => {
    setInteractiveURL(
      `${SLUG_PREFIX}${slugify(`${classTitle}`)}/${Math.floor(
        Math.random() * 10000000000,
      ).toString()}`,
    );
  };

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <NEInput
          label="Title"
          variant="outlined"
          id="classTitle"
          fullWidth={true}
          required
          name="classTitle"
          value={classTitle}
          onChange={e => handleTitleInput(e)}
          error={!!error.classTitle || ''}
          helperText={error.classTitle || ''}
          onBlur={generatePermalink}
        />
        <NEInput
          label="Interactive URL"
          variant="outlined"
          fullWidth={true}
          //className={classes.inputWrap}
          name="permaLink"
          id="permaLink"
          value={interactiveURL}
          onChange={e => handleStreamURLInput(e)}
          required
          error={!!error.interactiveURL || ''}
          helperText={error.interactiveURL || ''}
          onBlur={() => {
            if (!interactiveURL.includes(SLUG_PREFIX)) {
              generatePermalink();
            }
          }}
        />
        <div className={classes.common}>
          <StartDateTime date={selectedDate} selectedValue={setSelectedDate} />
        </div>
        <div className={classes.common}>
          <CancelButton
            key={1}
            title={'CANCEL'}
            clearAll={true}
            onClick={onClearEvent}
          />
          <AddButton title={'ADD CLASS'} onClick={e => onAddEvent()} />
        </div>
      </Grid>
    </div>
  );
}
