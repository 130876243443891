/*
 *
 * attribute constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/Video/DEFAULT_ACTION',

  REQUEST_ATTRIBUTE_LIST = 'REQUEST_ATTRIBUTE_LIST',
  ATTRIBUTE_LIST_SUCCESS = 'ATTRIBUTE_LIST_SUCCESS',
  ATTRIBUTE_LIST_FAILED = 'ATTRIBUTE_LIST_FAILED',
  SET_SORT_BY = 'SET_SORT_BY',
  GET_SEARCH_RESULT = 'GET_SEARCH_RESULT',
  FETCH_ATTRIBUTE_BY_ID = 'FETCH_ATTRIBUTE_BY_ID',
  FETCH_ATTRIBUTE_BY_ID_SUCCESS = 'FETCH_ATTRIBUTE_BY_ID_SUCCESS',
  SET_ATTRIBUTE_LOADER = 'SET_ATTRIBUTE_LOADER',
  SAVE_ATTRIBUTE_DETAIL = 'SAVE_ATTRIBUTE_DETAIL',
  REMOVE_ATTRIBUTE_DETAIL = 'REMOVE_ATTRIBUTE_DETAIL',
}

export default ActionTypes;
