import * as React from 'react';
import {
  Typography,
  Avatar,
  createStyles,
  withStyles,
} from '@material-ui/core';
import GalleryDialog from '../Gallery/Gallery';
import { Field } from 'formik';
import { ErrorText } from '../Typography';

enum GalleryType {
  image = 'image',
  video = 'video',
}

interface ManageGalleryItem {
  thumbnail: string;
  url: string;
  type: GalleryType;
}

interface ManageGalleryProps {
  name: string;
  albumName?: string;
  galleryItems?: ManageGalleryItem[];
  title?: string;
}

const styles = () =>
  createStyles({
    galleryRoot: {
      width: '100%',
      marginBottom: '8px',
    },
    manageGallery: {
      textTransform: 'capitalize',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: '#12161A',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 22px',
      position: 'absolute',
      height: '24px',
      top: 'calc(50% - 12px)',
    },
    galleryDiv: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      marginBottom: '16px',
    },
    galleryHeader: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '14px',
    },
    galleryDrop: {
      height: '142px',
      width: '100%',
      borderRadius: '6px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    errorText: {
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
      color: '#FF1C58',
    },
  });

const ManageGallery: React.FC<ManageGalleryProps> = ({
  galleryItems = [],
  title = 'MANAGE GALLERY',
  classes,
  albumName,
  name = 'gallery',
}) => {
  const [isDialogOpen, openDialog] = React.useState(false);
  const [items, setItems] = React.useState(false);

  const handleClose = () => {
    openDialog(false);
  };
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        let firstImage = galleryItems.filter(i => i.type === 'image').slice(0);
        let firstVideo = galleryItems.filter(i => i.type === 'video').slice(0);
        let galleryCover =
          firstImage && firstImage.length > 0
            ? firstImage[0].url
            : firstVideo && firstVideo.length > 0
            ? firstVideo[0] && firstVideo[0].thumbnail
            : '';

        return (
          <div className={classes.galleryRoot}>
            <Typography classes={{ root: classes.galleryHeader }}>
              Gallery* ({Number(galleryItems && galleryItems.length)} items)
            </Typography>

            <div className={classes.galleryDiv}>
              <Avatar
                alt="img"
                src={galleryCover}
                className={classes.galleryDrop}
              ></Avatar>
              <div
                className={classes.manageGallery}
                onClick={() => openDialog(true)}
              >
                Manage Gallery
              </div>
            </div>
            <ErrorText>{meta.touched && meta.error}</ErrorText>

            {isDialogOpen && (
              <GalleryDialog
                isOpen={isDialogOpen}
                title={title}
                galleryDetails={field.value}
                handleClose={handleClose}
                galleryData={setItems}
                albumName={albumName}
                handleSaveBtn={items => {
                  form.setFieldValue(name, items);
                  form.setFieldTouched(name, true, false);

                  handleClose();
                }}
                type="concept"
              ></GalleryDialog>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default withStyles(styles)(ManageGallery);
