import * as React from 'react';

function SvgIconLibrary(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 20" {...props}>
      <path
        d="M8.99 17.005l-7.37-5.73L0 12.535l9 7 9-7-1.63-1.27-7.38 5.74zm.01-2.54l7.36-5.73L18 7.465l-9-7-9 7 1.63 1.27L9 14.465zm0-11.47l5.74 4.47L9 11.935l-5.74-4.47L9 2.995z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconLibrary;
