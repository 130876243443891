/*
 *
 * LiveClass actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

// Get live video list
export const requestLiveVideoList = () => {
  return action(ActionTypes.REQUEST_LIVE_VIDEO_LIST);
};

export const storeLiveClassInfo = data => {
  return action(ActionTypes.STORE_LIVE_VIDEO_LIST, data);
};

export const setLoader = data => {
  return action(ActionTypes.SET_LOADER, data);
};
