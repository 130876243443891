import * as React from 'react';
import { Button, makeStyles, Theme, createStyles } from '@material-ui/core';

const selectBtnStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: '50%',
      width: '100%',
      height: '40px',
      border: 'none',
      borderRadius: '6px',
      borderColor: 'black',
      textTransform: 'uppercase',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '15px',
      fontWeight: 'bold',
      letterSpacing: '1px',
      lineHeight: '20px',
      padding: '8px',
      cursor: 'pointer',
      background: '#12161A',
      color: '#FFFFFF',
      '&:hover': {
        background: '#505B63',
        color: 'white',
      },
    },
  }),
);

export default function AddButton(props) {
  let data = null;

  const classes = selectBtnStyle();
  const handleChange = () => {
    props.onClick();
  };

  return (
    <Button className={classes.root} onClick={handleChange}>
      {props.title}
    </Button>
  );
}
