import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import NEInput from './NEInput';
import { getTimeWithZone } from '../utils/calender';

function createData(
  date: string,
  plan: string,
  period: string,
  method: string,
  desc: string,
  offers: string,
  discount: string,
  total: string,
) {
  return { date, plan, period, method, desc, offers, discount, total };
}

const currency_symbols = {
  usd: '$', // US Dollar
  inr: '₹', // Indian Rupee
};

const rows = [
  createData(
    '05/02/20',
    'MONTHLY_USD',
    '05/02/20 - 06/01/20',
    'Credit Card',
    'Charge',
    'N/A',
    'N/A',
    '$7.99',
  ),
  createData(
    '05/02/21',
    'MONTHLY_USD',
    '05/02/20 - 06/01/20',
    'Credit Card',
    'Charge',
    'N/A',
    'N/A',
    '$7.99',
  ),
  createData(
    '05/02/22',
    'MONTHLY_USD',
    '05/02/20 - 06/01/20',
    'Credit Card',
    'Charge',
    'N/A',
    'N/A',
    '$7.99',
  ),
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      textAlign: 'center',
      borderCollapse: 'collapse',
      border: '1px solid #D4D8DC',
      width: '100%',
      maxHeight: '100px',
      overflow: 'auto',
    },
    tableHead: {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed',
      '&:nth-child(2n)': {
        background: '#F7F7F7',
      },
    },
    tbody: {
      display: 'block',
      overflow: 'auto',
      tableLayout: 'fixed',
      maxHeight: '250px',
    },
    head: {
      padding: '7px 9px',
      backgroundColor: '#12161A',
      color: '#fff',
      fontSize: '12px',
      fontFamily: 'ProximaNova-Semibold',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '22px',
      textAlign: 'left',
    },
    billing: {
      fontFamily: 'ProximaNova-Semibold',
      fontWeight: 500,
      color: '#000',
      fontSize: '11px',
      lineHeight: '14px',
      padding: '12px 8px',
      textAlign: 'left',
      textTransform: 'capitalize',
    },
    detail: {
      fontFamily: 'ProximaNova-Semibold',
      fontWeight: 500,
      color: '#000',
      fontSize: '11px',
      lineHeight: '14px',
      padding: '12px 8px',
      textAlign: 'left',
    },
  }),
);
export default function TableList({ tHead, label, data, subList }) {
  const classes = useStyles();

  function WatchList(props) {
    const { getData } = props;
    return (
      <>
        {getData &&
          getData.length > 0 &&
          getData.map(el => (
            <tr key={el.id} className={classes.tableHead}>
              <td className={classes.detail}>{el.title}</td>
              <td className={classes.detail}>{el.concept}</td>
            </tr>
          ))}
      </>
    );
  }
  function FavoritList(props) {
    const { getData } = props;
    return (
      <>
        {getData &&
          getData.length > 0 &&
          getData.map(row => (
            <tr key={row.name} className={classes.tableHead}>
              <td className={classes.detail}>{row.name}</td>
            </tr>
          ))}
      </>
    );
  }
  function DownloadList(props) {
    const { getData } = props;
    return (
      <>
        {getData &&
          getData.length > 0 &&
          getData.map(row => (
            <tr key={row.name} className={classes.tableHead}>
              {/* <td className={classes.detail}>{row.name}</td> */}
            </tr>
          ))}
      </>
    );
  }

  function BillingList(props) {
    const { getData, subList } = props;
    const daysOfYear = [];
    const monthofDate = [];
    if (!isEmpty(getData) && !isEmpty(subList)) {
      const orgData = getData[0];
      const subscriptinData = subList.products;
      // Orginal startDate
      const startDate = new Date(orgData.original_purchase_date);
      // endate
      const puchaseDate = new Date(orgData.purchase_date);
      // plan filter
      let getPlans;
      subscriptinData.forEach(function(el) {
        const x = el.plans;
        const get = x.filter(el => el.id === orgData.plan);
        if (get) {
          getPlans = get;
        }
      });
      // interval type
      const intervalType = getPlans[0].interval;
      // interval count
      const intervalCount = getPlans[0].interval_count;
      // amount
      const subscriptionAmount = getPlans[0].amount;
      // currency
      const subscriptionCurrency = getPlans[0].currency;
      const symbol = currency_symbols[subscriptionCurrency];
      // decrement count
      const decrement = intervalCount + 1;

      // getDays
      const getDaysArray = function(start, end) {
        for (let d = start; d < end; d.setDate(d.getDate() + decrement)) {
          const tomorrow = new Date(d);
          daysOfYear.push({
            firstDate: new Date(d),
            endDate: new Date(
              tomorrow.setDate(tomorrow.getDate() + intervalCount),
            ),
            amount: subscriptionAmount,
            currency: subscriptionCurrency,
            plan: orgData.planLabel,
          });
        }
        return daysOfYear;
      };

      // getMonths
      const getMonthsArray = function(start, end) {
        const endMonth = end;
        const monthofDate = [];
        for (
          let month = start;
          month < endMonth;
          month.setMonth(month.getMonth() + decrement)
        ) {
          const tomorrow = new Date(month);
          monthofDate.push({
            firstDate: new Date(month),
            endDate: new Date(
              tomorrow.setMonth(tomorrow.getMonth() + intervalCount),
            ),
            amount: subscriptionAmount,
            currency: subscriptionCurrency,
            plan: orgData.planLabel,
          });
        }
        return monthofDate;
      };

      // getYears
      const getYearsArray = function(start, end) {
        const endMonth = end;
        const monthofYear = [];
        for (
          let month = start;
          month < endMonth;
          month.setFullYear(month.getFullYear() + decrement)
        ) {
          const tomorrow = new Date(month);
          monthofYear.push({
            firstDate: new Date(month),
            endDate: new Date(
              tomorrow.setFullYear(tomorrow.getFullYear() + intervalCount),
            ),
            amount: subscriptionAmount,
            currency: subscriptionCurrency,
            plan: orgData.planLabel,
          });
        }
        return monthofYear;
      };

      // getData
      let newArray;
      if (intervalType === 'day') {
        newArray = getDaysArray(startDate, puchaseDate);
      } else if (intervalType === 'month') {
        newArray = getMonthsArray(startDate, puchaseDate);
      } else if (intervalType === 'year') {
        newArray = getYearsArray(startDate, puchaseDate);
      }
    }
    return (
      <>
        {newArray &&
          newArray.length > 0 &&
          newArray.map(row => (
            <tr key={row.date} className={classes.tableHead}>
              <td className={classes.detail}>
                {moment(row.firstDate).format('MM-DD-YYYY')}
              </td>
              <td className={classes.detail}>{row.plan}</td>
              <td className={classes.detail}>{`${moment(row.firstDate).format(
                'MM-DD-YYYY',
              )} - ${moment(row.endDate).format('MM-DD-YYYY')}`}</td>
              <td className={classes.detail}>
                {symbol} {row.amount}
              </td>
            </tr>
          ))}
      </>
    );
  }
  function HistoryList(props) {
    const { getData } = props;
    return (
      <>
        {getData &&
          getData.length > 0 &&
          getData.map(row => (
            <tr key={row.name} className={classes.tableHead}>
              <td className={classes.detail}>
                {moment(row.watchDate, 'dddd,Do MMM YYYY').format('MM/DD/YYYY')}
              </td>
              <td className={classes.detail}>{row.title}</td>
              <td className={classes.detail}>{row.concept}</td>
            </tr>
          ))}
      </>
    );
  }

  function CreditsList(props) {
    const { getData } = props;
    return (
      <>
        {getData &&
          getData.length > 0 &&
          getData.map(row => (
            <tr key={row.name} className={classes.tableHead}>
              <td className={classes.detail}>
                {moment(row.createdDate).format('MM/DD/YYYY')}
              </td>
              <td className={classes.detail}>{row.numOfCredits}</td>
              <td className={classes.detail}>{row.expiryDate}</td>
              <td className={classes.detail}>
                {row.amount ? `$ ${row.amount}` : 'N/A'}
              </td>
              <td className={classes.detail}>
                {row.redeemed !== row.numOfCredits ? (
                  <>Valid Until {moment(row.expiryDate).format('MM/DD/YYYY')}</>
                ) : (
                  'Redeemed'
                )}
              </td>
            </tr>
          ))}
      </>
    );
  }

  function NeoUsPackages(props) {
    const { getData } = props;
    return (
      <>
        {getData &&
          getData.length > 0 &&
          getData.map(el => (
            <>
              {el.planId !== 'drop-in' && (
                <tr key={el.id} className={classes.tableHead}>
                  <td className={classes.detail}>
                    {el && el.nickname
                      ? el.nickname
                      : el.metadata && el.metadata.nickname
                      ? el.metadata.nickname
                      : el.planId}
                  </td>
                  <td className={classes.billing}>{el && el.classes}</td>
                  <td className={classes.billing}>{el && el.balance}</td>
                </tr>
              )}
            </>
          ))}
      </>
    );
  }

  function NeoUsPurchaseHistory(props) {
    const { getData } = props;
    return (
      <>
        {getData &&
          getData.length > 0 &&
          getData.map(el => (
            <tr key={el.id} className={classes.tableHead}>
              <td className={classes.detail}>
                {' '}
                {moment(el.createdAt).format('MM/DD/YYYY')}
              </td>
              <td className={classes.billing}>
                {el && el.purchaseDescription}
              </td>
              <td className={classes.billing}>
                {el && el.subscriptionStatus ? el.subscriptionStatus : ''}
              </td>
              <td className={classes.detail}>{el && el.paymentIntentId}</td>
              <td className={classes.detail}>
                {el && el.desc ? el.desc : 'Charge'}
              </td>
              <td className={classes.detail}>
                {el.amount
                  ? `$${el &&
                      el.amount
                        .toString()
                        .slice(0, -2)}.${el.amount.toString().substr(-2)}`
                  : '$0.00'}
              </td>
            </tr>
          ))}
      </>
    );
  }

  function BookingList(props) {
    const { getData } = props;
    return (
      <>
        {getData &&
          getData.length > 0 &&
          getData.map(row => (
            <tr key={row.name} className={classes.tableHead}>
              <td className={classes.detail}>
                {' '}
                {moment(row.airedDate).format('MM/DD/YYYY')}
              </td>
              <td className={classes.detail}>
                {row.interactive && row.interactive.isCancelled === true
                  ? 'Cancelled'
                  : 'Booked'}
              </td>
              <td className={classes.detail}>{row.title}</td>
              <td className={classes.detail}>
                {row.instructors &&
                row.instructors.length > 0 &&
                row.instructors[0]
                  ? `${row.instructors[0].firstName} ${row.instructors[0]
                      .lastName && row.instructors[0].lastName}`
                  : `${row.instructors.firstName} ${row.instructors.lastName &&
                      row.instructors.lastName}`}
              </td>
              <td className={classes.detail}>
                {`${moment(new Date(parseInt(row.startDate))).format(
                  'YYYY-MM-DDTH, HH:mm A',
                )} ${getTimeWithZone(parseInt(row.startDate))}`}
              </td>
              <td className={classes.detail} style={{ textAlign: 'center' }}>
                {row.interactive &&
                  row.interactive.dropInPrice &&
                  `$${Number(row.interactive.dropInPrice / 100).toFixed(2)}`}
              </td>
            </tr>
          ))}
      </>
    );
  }

  function AuditLogList(props) {
    const { getData } = props;
    return (
      <>
        {getData &&
          getData.length &&
          getData.map(row => (
            <tr key={row.name} className={classes.tableHead}>
              {}
            </tr>
          ))}
      </>
    );
  }

  return (
    <>
      <table className={classes.table}>
        <thead className={classes.tableHead}>
          <tr>
            {tHead.map(el => (
              <th className={classes.head}>{el}</th>
            ))}
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {label === 'neoUsPackages' && <NeoUsPackages getData={data} />}
          {label === 'neoUsBillingHistory' && (
            <NeoUsPurchaseHistory getData={data} />
          )}
          {label === 'whatchlist' && <WatchList getData={data} />}
          {label === 'Favorites' && <FavoritList getData={data} />}
          {label === 'Downloads' && <DownloadList getData={data} />}
          {label === 'billing' && (
            <BillingList getData={data} subList={subList} />
          )}
          {label === 'Booking' && <BookingList getData={data} />}
          {label === 'Credits' && <CreditsList getData={data} />}
          {label === 'History' && <HistoryList getData={data} />}
          {label === 'Auditlog' && <AuditLogList getData={data} />}
        </tbody>
      </table>
    </>
  );
}
