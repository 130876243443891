import React, { Fragment } from 'react';
import FullCalendar, { EventClickArg, Theme } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  makeStyles,
  createStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import moment from 'moment';
import { UnivDate } from '../../../utils/calender';
import GroupInteractive from '../components/GroupInteractive';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calenderRoot: {
      fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
      width: '100%',
    },
    root: {
      flexGrow: 1,
      padding: '3em',
      '& .fc .fc-daygrid-event-harness-abs': {
        background: 'none',
      },
      '& .fc': {
        maxWidth: '1100px',
        margin: '0 auto',
      },
      '& .fc .fc-toolbar.fc-header-toolbar': {
        marginBottom: '1.5em',
      },
      '& .fc .fc-toolbar': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& .fc-direction-ltr': {
        direction: 'ltr',
        textAlign: 'left',
      },
      '& .fc-direction-ltr .fc-daygrid-event .fc-event-time': {
        marginRight: '1px',
        textTransform: 'uppercase',
      },
      '& .fc-timegrid-event .fc-event-time': {
        paddingRight: '1px',
        textTransform: 'uppercase',
      },
      '& .fc .fc-button-group': {
        position: 'relative',
        display: 'inline-flex',
        verticalAlign: 'middle',
      },
      '& .fc-direction-ltr .fc-toolbar > * > :not(:first-child)': {
        marginLeft: '.75em',
      },
      '& .fc .fc-button-primary:disabled': {
        color: 'var(--fc-button-text-color, #fff)',
        backgroundColor: 'var(--fc-button-bg-color, #2C3E50)',
        borderColor: 'var(--fc-button-border-color, #2C3E50)',
      },
      '& .fc .fc-button:disabled': {
        opacity: 0.65,
      },
      '& .fc .fc-button': {
        display: 'inline-block',
        fontWeight: 400,
        textAlign: 'center',
        verticalAlign: 'middle',
        userSelect: 'none',
        border: '1px solid transparent',
        padding: '0.4em 0.65em',
        fontSize: '1em',
        lineHeight: '1.5',
        borderRadius: '0.25em',
        overflow: 'visible',
        textTransform: 'none',
        margin: 0,
        fontFamily: 'inherit',
        '-webkit-appearance': 'button',
      },
      '& .fc .fc-toolbar-title': {
        fontSize: '1.75em',
        margin: 0,
      },
      '& .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child)': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& .fc .fc-button-group > .fc-button.fc-button-active': {
        zIndex: 1,
      },
      '& .fc .fc-button-primary:not(:disabled).fc-button-active': {
        color: 'var(--fc-button-text-color, #fff)',
        backgroundColor: 'var(--fc-button-active-bg-color, #1a252f)',
        borderColor: 'var(--fc-button-active-border-color, #151e27)',
      },
      '& .fc .fc-button-group > .fc-button': {
        position: 'relative',
        flex: '1 1 auto',
      },
      '& .fc .fc-button:not(:disabled)': {
        cursor: 'pointer',
      },
      '& .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child)': {
        marginLeft: '-1px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '& .fc .fc-button-primary': {
        color: 'var(--fc-button-text-color, #fff)',
        backgroundColor: 'var(--fc-button-bg-color, #2C3E50)',
        borderColor: 'var(--fc-button-border-color, #2C3E50)',
      },
      '& .fc .fc-button .fc-icon': {
        marginBottom: '2px',
        verticalAlign: 'middle',
        fontSize: '1.5em',
      },
      '& .fc-icon': {
        display: 'inline-block',
        width: '1em',
        height: '1em',
        textAlign: 'center',
        userSelect: 'none',
        //fontFamily: `'fcicons' !important`,
        speak: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: 1,
        '-webkit-font-smoothing': 'antialiased',
      },
      '& .fc-icon-chevron-right': {
        '&:before': {
          content: '/e901 !important',
        },
      },
      '& .fc .fc-view-harness': {
        flexGrow: 1,
        position: 'relative',
        maxHeight: '625px',
      },
      '& .fc .fc-view-harness-active > .fc-view': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      '& .fc .fc-scrollgrid-liquid': {
        height: '100%',
        width: '100%',
      },
      '& .fc .fc-scrollgrid:': {
        borderCollapse: 'separate',
        borderRightWidth: 0,
        borderBottomWidth: 0,
        width: '100%',
        tableLayout: 'fixed',
      },
      '& .fc-theme-standard .fc-scrollgrid': {
        border: '1px solid var(--fc-border-color, #ddd)',
      },
      '& .fc table': {
        borderCollapse: 'collapse',
        borderSpacing: 0,
        fontSize: '1em',
        '& colgroup': {
          '& col': {
            width: '50px !important',
          },
        },
      },
      '& .fc, .fc *, .fc *:before, .fc *:after': {
        boxSizing: 'border-box',
      },
      '& .fc .fc-scrollgrid-section': {
        height: '0px' /* better than 0, for firefox */,
      },
      '& .fc .fc-scroller-harness': {
        position: 'relative',
        overflow: 'hidden',
        direction: 'ltr',
      },
      '& .fc .fc-scroller': {
        position: 'relative',
      },
      '& .fc .fc-scrollgrid-section table': {
        height: '1px',
        width: '720px',
      },
      '& .fc .fc-scrollgrid table': {
        borderTopStyle: 'hidden',
        borderLeftStyle: 'hidden',
        borderRightStyle: 'hidden',
      },
      '& .fc .fc-scrollgrid, .fc .fc-scrollgrid table': {
        tableLayout: 'fixed',
      },
      '& .fc .fc-daygrid-body': {
        position: 'relative',
        zIndex: 1,
      },
      '& .fc .fc-scrollgrid-section-body table': {
        width: '720px',
        //height: '511px',
        borderBottomStyle: 'hidden',
      },
      '& .fc-theme-standard td': {
        border: '1px solid var(--fc-border-color, #ddd)',
      },
      '& .fc td': {
        verticalAlign: 'top',
        padding: '0',
      },
      '& .fc .fc-daygrid-day-frame': {
        position: 'relative',
        minHeight: '100%',
      },
      '& .fc-daygrid-day-frame:before, .fc-daygrid-day-events:before, .fc-daygrid-event-harness:before': {
        content: '',
        clear: 'both',
        display: 'table',
      },
      '& .fc .fc-day-other .fc-daygrid-day-top': {
        opacity: '0.3',
      },
      '& .fc .fc-daygrid-day-top': {
        display: 'flex',
        flexDirection: 'row-reverse',
      },
      '& .fc .fc-daygrid-day-number': {
        position: 'relative',
        zIndex: 4,
        padding: '10px',
      },
      '& .fc .fc-daygrid-body-balanced .fc-daygrid-day-events': {
        position: 'absolute',
        left: 0,
        right: 0,
      },
      '& .fc .fc-daygrid-day-events': {
        marginTop: '1px',
      },
      '& .fc .fc-daygrid-day-bottom': {
        fontSize: '.85em',
        padding: '2px 3px 0',
      },
      '& .fc .fc-daygrid-day-bottom:before': {
        content: '',
        clear: 'both',
        display: 'table',
      },
      '& .fc-daygrid-day-frame:after, .fc-daygrid-day-events:after, .fc-daygrid-event-harness:after': {
        content: '',
        clear: 'both',
        display: 'table',
      },
      '& .fc-theme-standard td, .fc-theme-standard th': {
        border: '1px solid var(--fc-border-color, #ddd) !important',
        verticalAlign: 'top',
        padding: 0,
      },
      '& .fc th, .fc td': {
        verticalAlign: 'top',
        padding: 0,
      },
      '& .fc th': {
        textAlign: 'center',
      },
      '& .fc .fc-daygrid-day.fc-day-today': {
        backgroundColor: 'var(--fc-today-bg-color, rgba(255, 220, 40, 0.15))',
      },
      '& .fc .fc-daygrid-event-harness': {
        position: 'relative',
      },
      '& .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start': {
        marginRight: '2px',
        cursor: 'pointer',
      },
      '& .fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end': {
        marginLeft: '2px',
      },
      '& .fc .fc-daygrid-event': {
        zIndex: 6,
        margintop: '1px',
      },
      '& .fc-event[href], .fc-event.fc-event-draggable': {
        cursor: 'pointer',
      },
      '& a.fc-event, a.fc-event:hover': {
        textDecoration: 'none',
      },
      '& .fc-daygrid-event': {
        position: 'relative',
        whiteSpace: 'nowrap',
        borderradius: '3px',
        fontSize: 'var(--fc-small-font-size, .85em)',
      },
      '& .fc-daygrid-event-dot': {
        border: 'none',
        display: 'none',
      },
      '& .fc-h-event': {
        display: 'block',
        //border: '1px solid var(--fc-event-border-color, #3788d8)',
        //backgroundColor: 'var(--fc-event-bg-color, #3788d8)',
      },
      '& .fc-h-event .fc-event-main': {
        //color: 'var(--fc-event-text-color, #fff)',
      },
      '& .fc-event .fc-event-main': {
        position: 'relative',
        zIndex: 2,
      },
      '& .fc-direction-ltr .fc-h-event:not(.fc-event-selected) .fc-event-resizer-end, .fc-direction-rtl .fc-h-event:not(.fc-event-selected) .fc-event-resizer-start': {
        cursor: 'e-resize',
        right: 'calc(var(--fc-event-resizer-thickness, 8px) / -2)',
      },
      '& .fc-h-event:not(.fc-event-selected) .fc-event-resizer': {
        top: 0,
        bottom: 0,
        width: 'var(--fc-event-resizer-thickness, 8px)',
      },
      '& .fc-event .fc-event-resizer': {
        display: 'none',
        position: 'absolute',
        zIndex: 4,
      },
      '& .fc .fc-timegrid-axis-frame': {
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      '& .fc-scrollgrid-sync-inner': {
        width: '100%',
        //textAlign: 'center',
      },
      '& .fc .fc-col-header-cell-cushion': {
        display: 'inline-block',
        padding: '2px 4px',
      },
      '& .fc .fc-timegrid .fc-daygrid-body': {
        zIndex: '2px',
        width: '720px',
      },
      '& .fc .fc-timegrid-axis-cushion': {
        maxWidth: '60px',
        flexShrink: 0,
      },
      '& .fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion': {
        padding: '0 4px',
      },
      '& .fc .fc-daygrid-body-natural .fc-daygrid-day-events': {
        marginbottom: '1em',
      },
      '& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events': {
        position: 'relative',
        minHeight: '2em',
      },
      '& .fc .fc-scrollgrid-section-body table, .fc .fc-scrollgrid-section-footer table': {
        borderBottomStyle: 'hidden',
      },
      '& .fc .fc-scrollgrid-section > td': {
        height: '1px',
      },
      '& .fc .fc-timegrid-divider': {
        padding: '0 0 2px',
      },
      '& .fc .fc-cell-shaded, .fc .fc-day-disabled': {
        background: 'var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3))',
      },
      '& .fc .fc-scrollgrid-section > *': {
        borderTopWidth: 0,
        borderLeftWidth: 0,
      },
      '& .fc .fc-scrollgrid-section-liquid > td': {
        height: '100%',
      },
      '& .fc .fc-scrollgrid > * > tr > *': {
        borderTopWidth: 0,
        borderLeftWidth: 0,
      },

      '& .fc .fc-scrollgrid-section-liquid': {
        height: 'auto',
      },
      '& .fc .fc-scroller-harness-liquid': {
        height: '100%',
      },
      '& .fc-scroller fc-scroller-liquid-absolute': {
        overflow: 'hidden scroll',
      },
      '& .fc .fc-scroller-liquid-absolute': {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      },
      '& .fc .fc-timegrid-body': {
        position: 'relative',
        zIndex: 1,
        minHeight: '100%',
      },
      '& .fc .fc-timegrid-slots': {
        position: 'relative',
        zIndex: 2,
      },
      '& .fc-scroller': {
        overflow: 'hidden scroll',
      },
      '& .fc .fc-timegrid-slot-label': {
        verticalAlign: 'middle',
      },
      '& .fc .fc-timegrid-slot': {
        height: '1.5em',
        borderBottom: 0 /* each cell owns its top border */,
      },
      '& .fc-direction-ltr .fc-timegrid-slot-label-frame': {
        textAlign: 'right',
      },
      '& .fc .fc-timegrid-slot-label-cushion': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
      },
      '& .fc .fc-scrollgrid': {
        borderCollapse: 'separate',
      },
      '& .fc-view-harness fc-view-harness-active': {
        paddingBottom: '74.0741%',
      },
      '& .fc-scrollgrid-section': {
        height: 0,
      },
      '& .fc-col-header': {
        width: '883px',
      },
      '& .fc .fc-timegrid-slot-minor': {
        borderTopStyle: 'dotted',
      },
      '& .fc .fc-day-today': {
        background: 'var(--fc-today-bg-color, rgba(255, 220, 40, 0.15))',
      },
      '& .fc .fc-scrollgrid > tbody table, .fc .fc-scrollgrid > tfoot table': {
        borderBottomStyle: 'hidden',
      },
      '& .fc-media-screen .fc-timegrid-cols': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      '& .fc-media-screen .fc-timegrid-cols > table': {
        height: '100%',
      },
      '& .fc-icon-chevron-left:before': {
        content: '"prev"',
        display: 'inline-block',
        fontSize: '16px',
        width: '32px',
      },
      '& .fc-icon-chevron-right:before': {
        content: '"next"',
        display: 'inline-block',
        fontSize: '16px',
        width: '32px',
      },
      '& .fc-daygrid-dot-event .fc-event-title': {
        //textOverflow: 'ellipsis',
      },
      '& .fc-daygrid-body-natural': {
        display: 'none !important',
      },
      '& .fc-event-main-frame': {
        display: 'flex',
        overflow: 'hidden',
        cursor: 'pointer',
        flexDirection: 'unset',
      },
      '& .fc-daygrid-block-event .fc-event-time': {
        fontWeight: 'unset',
      },
      '& .fc-sticky': {
        fontWeight: 'bold',
      },
    },
  }),
);

export default function CalendarView(props) {
  const classes = useStyles();
  const { upcomingClasses } = props;
  let classEvent = [];
  if (upcomingClasses && upcomingClasses.length > 0) {
    upcomingClasses.map(data => {
      var currentDate = new Date(data.startDate);
      var futureDate = new Date(currentDate.getTime() + data.duration * 60000);
      classEvent.push({
        id: data.id,
        groupId: data.id,
        title: data.title,
        start: moment(UnivDate(data.startDate))
          .utc()
          .format(),
        // end: moment(UnivDate(futureDate))
        //   .utc()
        //   .format(),
      });
    });
  }
  let [calendarWeekends, setCalendarWeekends] = React.useState(true);
  const [editVideoInfo, setEditVideoInfo] = React.useState({
    isOpen: false,
    id: '',
  });

  const calendarComponentRef = React.createRef();

  const handleEventClick = (clickInfo: EventClickArg) => {
    if (clickInfo.event.id) {
      setEditVideoInfo({
        isOpen: true,
        id: clickInfo.event.id,
      });
    }
  };

  const handleToolTip = info => {};

  function eventContent(arg) {
    return (
      <Tooltip
        title={
          <Typography color="inherit">
            {arg && arg.event && arg.event.title}
          </Typography>
        }
        arrow
      >
        {renderInnerContent(arg)}
      </Tooltip>
    );
  }
  function renderInnerContent(innerProps) {
    return (
      <div className="fc-event-main-frame">
        {innerProps && innerProps.timeText && (
          <div className="fc-event-time">{innerProps.timeText}</div>
        )}
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {(innerProps && innerProps.event && innerProps.event.title) || (
              <Fragment>&nbsp;</Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
  

  return (
    <div className={classes.calenderRoot}>
      {editVideoInfo.isOpen && (
        <GroupInteractive
          id={editVideoInfo && editVideoInfo.id}
          title="Edit Group Interactive Class"
          isOpen={editVideoInfo.isOpen}
          handleClose={() => setEditVideoInfo({ isOpen: false, id: '' })}
          isReviewTab={false}
        />
      )}
      <div className={classes.root}>
        <FullCalendar
          //editable={true}
          defaultView="timeGridWeek"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          header={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          ref={calendarComponentRef}
          weekends={calendarWeekends}
          events={classEvent}
          nextDayThreshold="00:00:00"
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'narrow',
          }}
          //eventRender={eventRender}
          eventContent={eventContent}
          //dateClick={handleDateClick}
          eventClick={handleEventClick}
          //eventDidMount={e => handleToolTip(e)}
          // eventDrop={info => {
          //   //<--- see from here
          //   const { start, end } = info.oldEvent._instance.range;
          //   console.log(start, end);
          //   const { start: newStart, end: newEnd } = info.event._instance.range;
          //   console.log(newStart, newEnd);
          //   if (new Date(start).getDate() === new Date(newStart).getDate()) {
          //     info.revert();
          //   }
          // }}
        />
      </div>
    </div>
  );
}
