/*
 *
 * Achievement
 *
 */

import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectAchievement from './selectors';
import reducer from './reducer';
import saga from './saga';
import { Button, InputAdornment } from '@material-ui/core';
import debounce from 'lodash.debounce';
import StyledDialog from '../../components/StyledDialog';
import {
  requestAchievements,
  setTabChange,
  requestAchievementListPagination,
  resetAchievement,
  setSearchKey,
} from './actions';
import AchievementListTable from './AchievementTable/AchievementTable';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';
import { useStyles } from './styles';
import {
  SvgIconSearch,
  SvgIconAdd,
  SvgLoaderGif,
} from '../../components/NeIcons';
import SortSelect from './Select';
import Loader from '../../components/Loader';
import NEInput from '../../components/NEInput';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AchievementFormDialog, {
  FormType,
} from './components/AchievementFormDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Helmet } from 'react-helmet';

const stateSelector = createStructuredSelector({
  achievements: makeSelectAchievement(),
});

interface Props {}

function Achievements(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'achievement', reducer: reducer });
  useInjectSaga({ key: 'achievement', saga: saga });

  const { achievements } = useSelector(stateSelector);
  let {
    achievementList,
    isListFetchingError,
    isLoading,
    programChallenge,
    genres,
    total,
    isAchievementLoading,
  } = achievements;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [isAddSpecialOpen, openAddSpecialDialog] = React.useState(false);
  const [isAddMilestoneOpen, openAddMilestoneDialog] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [activeTab, setActiveTab] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  const [isAddConceptOpen, openAddConceptDialog] = React.useState(false);
  const [achievementDialogData, setAchievementDialogData] = React.useState({
    isOpen: false,
    id: '',
    genres: [],
    programChallenge: [],
  });
  const [badgeType, setBadgeType] = React.useState('');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    if (tabIndex === 0) {
      dispatch(setTabChange('special'));
      dispatch(requestAchievements());
    } else if (tabIndex === 1) {
      dispatch(setTabChange('milestones'));
      dispatch(requestAchievements());
    } else if (tabIndex === 2) {
      dispatch(setTabChange('streaks'));
      dispatch(requestAchievements());
    }
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    if (activeTab === 0) {
      dispatch(setTabChange('special'));
    }
    // Update the document title using the browser API
    dispatch(requestAchievements());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetAchievement());
    };
  }, []);

  const tabConfig = [
    {
      label: 'SPECIAL',
      component: <AchievementListTable />,
    },
    {
      label: 'MILESTONES',
      component: <AchievementListTable />,
    },
    {
      label: 'STREAKS',
      component: <AchievementListTable />,
    },
  ];

  const debouncedGet = useCallback(
    debounce(textValue => {
      onSearchClick(textValue);
    }, 1500),
    [], // will be created only once initially
  );

  const handleInput = e => {
    setSearchText(e.target.value);
    debouncedGet(e.target.value);
  };

  const onSearchClick = (text = '') => {
    dispatch(setSearchKey(text ? text : searchText));
    dispatch(requestAchievements());
  };

  const onAgree = () => {
    openAddConceptDialog(false);
  };

  const onDisAgree = () => {
    openAddConceptDialog(true);
  };

  function loadMore() {
    if (achievementList && achievementList.length < total) {
      dispatch(requestAchievementListPagination(achievementList.length));
    }
  }

  return (
    <>
      <Helmet>
        <title>Configure Achievements</title>
        <meta name="description" content="Description of Achievements" />
      </Helmet>
      {isLoading && <Loader />}

      <div className={classes.root}>
        <div>
          {achievementDialogData.isOpen && (
            <AchievementFormDialog
              type={FormType.ADD}
              isOpen={achievementDialogData.isOpen}
              id={null}
              badgeType={badgeType}
              genres={genres}
              programChallenge={programChallenge}
              title={FormType.ADD ? `ADD ${badgeType} ACHIEVEMENT` : ''}
              onClose={() =>
                setAchievementDialogData({
                  isOpen: false,
                  id: '',
                  genres: [],
                  programChallenge: [],
                })
              }
            />
          )}
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            handleClose={openConfirmDialog}
            onAgree={onAgree}
            onDisagree={onDisAgree}
            agreeButtonText="Yes"
            disagreeBtnText="Cancel"
            headerText="Are you sure?"
            bodyText="You’re about to close this Add Achievement dialog box."
          ></ConfirmDialog>

          <div className={classes.headWrap}>
            <div className={classes.head}>Library: Achievements</div>
            <div className={classes.searchWrap}>
              <NEInput
                label="Search"
                variant="outlined"
                id="custom-css-outlined-input"
                fullWidth={true}
                onChange={e => handleInput(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={() => onSearchClick()}>
                        <SvgIconSearch />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.headWrap}>
            <>
              <Button
                onClick={handleClick}
                variant="contained"
                color="secondary"
                className={classes.addAchievementBtn}
                disableRipple={true}
              >
                <SvgIconAdd />{' '}
                <div className={classes.newAchievement}>BADGE</div>{' '}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.dropDown}
              >
                <MenuItem
                  onClick={() => {
                    setAchievementDialogData({
                      isOpen: true,
                      genres: genres,
                      programChallenge: programChallenge,
                    });
                    setBadgeType('special');
                    handleClose();
                  }}
                >
                  <ListItemText primary="Special" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAchievementDialogData({
                      isOpen: true,
                      genres: genres,
                      programChallenge: programChallenge,
                    });
                    setBadgeType('milestones');
                    handleClose();
                  }}
                >
                  <ListItemText primary="Milestone" />
                </MenuItem>
              </Menu>
            </>
            <SortSelect />
          </div>

          <StyledTabs
            value={activeTab}
            onChange={onTabChange}
            aria-label="My account tabs"
            centered
            className={classes.styledTab}
          >
            {tabConfig.map((t, index) => (
              <StyledTab key={index} label={t.label} />
            ))}
          </StyledTabs>
          {isListFetchingError &&
          (achievementList && achievementList.length === 0) ? (
            <div className={classes.noData}>No Data Found</div>
          ) : (
            <AchievementListTable
              list={achievementList}
              programChallenge={programChallenge}
              genres={genres}
              isLoading={isLoading}
            />
          )}
          {isAchievementLoading && (
            <div style={{ padding: '10px' }}>
              <SvgLoaderGif />
            </div>
          )}
          {!isAchievementLoading &&
            achievementList &&
            total !== achievementList.length && (
              <div className={classes.loadMoreBtn}>
                <Button color="secondary" onClick={() => loadMore()}>
                  Load More Achievements
                </Button>
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default Achievements;
