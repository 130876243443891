import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { ApplicationRootState } from '../../types';

/**
 * Direct selector to the myClassDetail state domain
 */

const selectMyClassDetailDomain = (state: ApplicationRootState) => {
  return state.myClassDetail || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by MyClassDetail
 */

const makeSelectMyClassDetail = () =>
  createSelector(
    selectMyClassDetailDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectMyClassDetail;
export { selectMyClassDetailDomain };
