/*
 *
 * SignOutHome reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  isLoading: false,
  components: null,
};

function signOutHomeReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_SIGN_OUT_HOME:
      return { ...state, isLoading: true };
    case ActionTypes.SET_LOADER:
      return { ...state, isLoading: action.payload };
    case ActionTypes.STORE_SIGN_OUT_INFO:
      return { ...state, components: action.payload };
    default:
      return state;
  }
}

export default signOutHomeReducer;
