/*
 *
 * InstructorAddClasses constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/InstructorAddClasses/DEFAULT_ACTION',
  VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS',
  VIDEO_LIST_TOTAL = 'VIDEO_LIST_TOTAL',
  VIDEO_LIST_FAILED = 'VIDEO_LIST_FAILED',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  REQUEST_INTERACTIVE_VIDEO_LIST = 'REQUEST_INTERACTIVE_VIDEO_LIST',
}

export default ActionTypes;
