/*
 *
 * Video actions
 *
 */

import { action } from 'typesafe-actions';
import { VideoList } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

// Get video list
export const requestLiveVideosList = () => {
  return action(ActionTypes.GET_LIVE_VIDEOS);
};
export const requestVideoListPagination = val => {
  return action(ActionTypes.REQUEST_PAGINATION_LIVE_VIDEO, val);
};

export const VideoListSuccess = (videoList: VideoList) => {
  return action(ActionTypes.VIDEO_LIST_SUCCESS, videoList);
};

export const videoListSuccessPagination = (videoList: VideoList) => {
  return action(ActionTypes.VIDEO_LIST_PAGINATION_SUCCESS, videoList);
};
export const videoListCountSuccess = (total: number) => {
  return action(ActionTypes.VIDEO_LIST_TOTAL, total);
};

export const videoListFailed = () => {
  return action(ActionTypes.VIDEO_LIST_FAILED);
};

export const serSortBy = sortBy => {
  return action(ActionTypes.SET_SORT_BY, sortBy);
};

export const setTabChange = tab => {
  return action(ActionTypes.SET_ACTIVE_TAB, tab);
};

export const requestFilterParamsList = () => {
  return action(ActionTypes.REQUEST_LIVE_FILTER_PARAMS);
};
export const filtersFetchSuccess = data =>
  action(ActionTypes.FILTERS_FETCH_SUCCESS, data);

export const updateFilterParams = data =>
  action(ActionTypes.UPDATE_FILTERS_SUCCESS, data);

export const filtersFetchFailed = () =>
  action(ActionTypes.FILTERS_FETCH_FAILED);

/* Search Suggestions API */

export const searchLiveSuggestions = (searchKeyword: string) =>
  action(ActionTypes.LIVE_SEARCH_SUGGESTIONS, searchKeyword);

/* Search Suggestions success API */

export const searchSuggestionsSuccess = (data: any[]) =>
  action(ActionTypes.SEARCH_SUGGESTION_FETCH_SUCCESS, data);

export const requestLiveVideoListWithFilters = data => {
  return action(ActionTypes.REQUEST_lIVE_VIDEO_LIST_WITH_FILTERS, data);
};

export const setInteractiveOnlyAction = interactiveOnly => {
  return action(ActionTypes.SET_INTERACTIVE_ONLY, interactiveOnly);
};

export const toggleExplicitLangAction = data => {
  return action(ActionTypes.TOGGLE_EXPLICIT_LANG, data);
};

export const VideoListSuccessWithFilters = searchObj => {
  return action(ActionTypes.VIDEO_LIST_SUCCESS_WITH_FILTERS, searchObj);
};
export const searchLiveResultsPagination = searchObj => {
  return action(ActionTypes.VIDEO_LIVE_LIST_WITH_FILTERS_PAGINATION, searchObj);
};
export const searchResultsPaginationSuccess = searchObj => {
  return action(
    ActionTypes.VIDEO_LIST_SUCCESS_WITH_FILTERS_PAGINATION,
    searchObj,
  );
};

export const videoListFailedWithFilters = () => {
  return action(ActionTypes.VIDEO_LIST_FAILED_WITH_FILTERS);
};

export const updateVideoList = () => {
  return action(ActionTypes.UPDATE_VIDEO_LIST);
};

export const updateSearchCount = () => {
  return action(ActionTypes.UPDATE_SEARCH_COUNT);
};

export const liveReupdateVideoList = () => {
  return action(ActionTypes.LIVE_RE_UPDATE_LIST);
};

export const getDurationDetails = () => {
  return action(ActionTypes.GET_DURATION_DETAILS);
};

export const durationSuccess = result => {
  return action(ActionTypes.DURATION_DETAILS_SUCCESS, result);
};

export const durationFailed = () => {
  return action(ActionTypes.DURATION_DETAILS_FAILED);
};

export const addLiveEvent = data => {
  return action(ActionTypes.ADD_LIVE_VIDEO_EVENT, data);
};
export const addVideoEvent = data => {
  return action(ActionTypes.ADD_VIDEO_EVENT, data);
};

export const reEncodeVideo = data => {
  return action(ActionTypes.RE_ENCODE_VIDEO, data);
};

export const videoAddSuccess = data => {
  return action(ActionTypes.CLOSE_ADD_VIDEO_DIALOG, data);
};

export const filterUpdateList = () => {
  return action(ActionTypes.FILTER_UPDATE_VIDEO_LIST);
};

export const addMutipleAudioTracks = data => {
  return action(ActionTypes.ADD_MULTIPLE_AUDIO_TRACKS, data);
};

export const setVideoLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_VIDEO_LOADER, isLoading);
};

export const setLiveSearchVideoLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_LIVE_SEARCH_VIDEO_LOADER, isLoading);
};
