/*
 *
 * Video reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  videoList: [],
  sortBy: 'updatedAt=desc',
  activeTab: '',
  isListFetchingError: false,
  isLoading: false,
  filterData: [],
  searchResults: [],
  searchSuggestion: [],
  searchResultsCount: 0,
  durationDetails: [],
  videoTotalCount: 0,
  addVideoDialog: false,
  interactiveOnly: false,
  isExplicitLang: false,
  isVideoLoading: false,
};

function videoReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_VIDEO_LIST:
      return { ...state };
    case ActionTypes.VIDEO_LIST_SUCCESS:
      return {
        ...state,
        videoList: [...action.payload],
        searchResultsCount: 0,
        isListFetchingError: false,
      };
    case ActionTypes.VIDEO_LIST_TOTAL:
      return {
        ...state,
        videoTotalCount: action.payload,
        searchResultsCount: 0,
      };
    case ActionTypes.VIDEO_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        videoList: [...state.videoList, ...action.payload],
        searchResultsCount: 0,
      };
    case ActionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case ActionTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case ActionTypes.VIDEO_LIST_FAILED:
      return {
        ...state,
        isListFetchingError: true,
      };
    case ActionTypes.FILTERS_FETCH_SUCCESS:
      return {
        ...state,
        filterData: action.payload,
        searchResults: [],
      };

    case ActionTypes.UPDATE_FILTERS_SUCCESS:
      return {
        ...state,
        filterData: action.payload,
        searchResults: [],
      };
    case ActionTypes.SET_INTERACTIVE_ONLY:
      return {
        ...state,
        interactiveOnly: action.payload,
      };
    case ActionTypes.TOGGLE_EXPLICIT_LANG:
      return {
        ...state,
        isExplicitLang: action.payload,
      };
    case ActionTypes.SEARCH_SUGGESTION_FETCH_SUCCESS:
      return {
        ...state,
        searchSuggestion: action.payload,
        searchResults: [],
        // isSearchResultsFetching: true,
      };

    case ActionTypes.VIDEO_LIST_SUCCESS_WITH_FILTERS:
      let { result, total } = action.payload;
      return {
        ...state,
        searchResults: result ? result : [],
        searchResultsCount: total ? total : 0,
        isLoading: false,
        isVideoLoading: false,
      };
    case ActionTypes.VIDEO_LIST_SUCCESS_WITH_FILTERS_PAGINATION:
      let search = action.payload;
      return {
        ...state,
        searchResults: [...state.searchResults, ...search.result],
        videoList: [...state.videoList, ...search.result],
        searchResultsCount: search.total,
      };
    case ActionTypes.FILTER_UPDATE_VIDEO_LIST:
      return {
        ...state,
        videoList: state.searchResults ? [...state.searchResults] : [],
        searchResults: [],
        videoTotalCount: state.searchResultsCount,
        // searchResultsCount: 0,
      };
    case ActionTypes.UPDATE_SEARCH_COUNT:
      return {
        ...state,
        videoList: [],
        searchResultsCount: 0,
      };
    case ActionTypes.SET_VIDEO_LOADER:
      return { ...state, isLoading: action.payload };
    case ActionTypes.SET_SEARCH_VIDEO_LOADER:
      return { ...state, isVideoLoading: action.payload };
    case ActionTypes.DURATION_DETAILS_SUCCESS:
      return {
        ...state,
        durationDetails: action.payload,
      };
    case ActionTypes.DURATION_DETAILS_FAILED:
      return {
        ...state,
      };
    case ActionTypes.CLOSE_ADD_VIDEO_DIALOG:
      return {
        ...state,
        addVideoDialog: action.payload,
      };
    default:
      return state;
  }
}

export default videoReducer;
