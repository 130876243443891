import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import ClassTable from '../../../components/ClassListTable/ClassTable';
import { storeClassInfo } from '../../MyClassDetail/actions';

export default function ClassListTable(props) {
  const dispatch = useDispatch();

  let { list } = props;

  function setEditVideoInfo(editVideoInfo) {
    const data = {
      videoId: editVideoInfo.id,
    };
    const classInfo = {
      classItem: list.find(c => c.id === editVideoInfo.id),
      id: editVideoInfo.id,
    };
    classInfo['isPublished'] = classInfo.classItem.isPublished ? true : false;
    dispatch(storeClassInfo(classInfo));
    dispatch(push(`/neou/instructor-my-class/${editVideoInfo.id}`));
  }

  return (
    <Paper>
      <ClassTable list={list} setEditVideoInfo={setEditVideoInfo} />
    </Paper>
  );
}
