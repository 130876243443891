/*
 *
 * Collection actions
 *
 */

import { action } from 'typesafe-actions';
import { CollectionList, collectionDetail } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

//Get Collection list
export const requestCollectionList = () => {
  return action(ActionTypes.REQUEST_COLLECTION_LIST);
};

export const collectionListSuccess = (collectionList: CollectionList) => {
  return action(ActionTypes.COLLECTION_LIST_SUCCESS, collectionList);
};

export const collectionListFailed = () => {
  return action(ActionTypes.COLLECTION_LIST_FAILED);
};

export const resetCollection = () => {
  return action(ActionTypes.RESET_COLLECTION);
};

export const serSortBy = sortBy => {
  return action(ActionTypes.SET_SORT_BY, sortBy);
};

export const setType = setType => {
  return action(ActionTypes.SET_SORT_BY_TYPE, setType);
};

export const setTabChange = tab => {
  return action(ActionTypes.SET_ACTIVE_TAB, tab);
};

export const setSeachKey = key => {
  return action(ActionTypes.GET_SEARCH_RESULT, key);
};

export const getCollectionDetail = (id: string) => {
  return action(ActionTypes.FETCH_COLLECTION_BY_ID, id);
};

export const storeCollectionDetailInfo = collectionDetailInfo => {
  return action(ActionTypes.STORE_COLLECTION_DETAIL, collectionDetailInfo);
};

export const setSearchKey = key => {
  return action(ActionTypes.GET_SEARCH_RESULT, key);
};

export const setCollectionLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_COLLECTION_LOADER, isLoading);
};
export const setCollectionPaginationLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_COLLECTION_PAGINATION_LOADER, isLoading);
};
export const addCollectionDetailInfo = data => {
  return action(ActionTypes.ADD_COLLECTION_DETAIL_INFO, data);
};
export const addCollectionDetailInfoSuccess = result =>
  action(ActionTypes.ADD_TO_COLLECTION_DETAIL_INFO_SUCCESS, result);
export const addCollectionDetailInfoFailed = error =>
  action(ActionTypes.ADD_TO_COLLECTION_DETAIL_INFO_FAILED, error);

export const editCollectionDetailInfo = data => {
  return action(ActionTypes.EDIT_COLLECTION_DETAIL_INFO, data);
};
export const editCollectionDetailInfoSuccess = result =>
  action(ActionTypes.EDIT_TO_COLLECTION_DETAIL_INFO_SUCCESS, result);
export const editCollectionDetailInfoFailed = error =>
  action(ActionTypes.EDIT_TO_COLLECTION_DETAIL_INFO_FAILED, error);

export const addCollection = data => {
  return action(ActionTypes.ADD_COLLECTION, data);
};

export const searchVideosByTitleOrID = (value: string) => {
  return action(ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID, value);
};

export const searchVideosByTitleOrIDSuccess = videoList => {
  return action(ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS, videoList);
};

export const searchVideosByTitleOrIDFailed = error => {
  return action(ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID_FAILED, error);
};

export const collectionListCountSuccess = (total: number) => {
  return action(ActionTypes.COLLECTION_LIST_TOTAL, total);
};

export const collectionListPaginationSuccess = payload => {
  return action(ActionTypes.COLLECTION_LIST_PAGINATION_SUCCESS, payload);
};
export const requestCollectionListPagination = payload => {
  return action(ActionTypes.REQUEST_COLLECTION_LIST_PAGINATION, payload);
};

export const requestCollectionType = payload => {
  return action(ActionTypes.REQUEST_COLLECTION_TYPE, payload);
};

export const putCollectionPagination = () => {
  return action(ActionTypes.PUT_COLLECTION_LIST_PAGINATION_SUCCESS);
};
