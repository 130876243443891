import { takeLatest, call, take, put, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import Cookies from 'universal-cookie';
import { push } from 'connected-react-router';
import { openSnackBar, closeSnackBar } from '../Root/actions';
import { AnyAction } from 'redux';
import makeSelectAuthentication from './selectors';
import {
  storeUserInfo,
  requestUserInfo,
  requestUserBy,
  isLoginFetching,
} from './action';

const cookies = new Cookies();

// Individual exports for testing
export default function* saga() {
  yield takeLatest(ActionTypes.USER_LOGIN, userLogin);
  yield takeLatest(ActionTypes.USER_RESET, userReset);
  yield takeLatest(ActionTypes.SET_NEW_PASSWORD, setNewPassword);
  yield takeLatest(ActionTypes.REQUEST_USER_INFO, fetchUserInfo);
  yield takeLatest(ActionTypes.REDIRECT_USER, redirectByUser);
}

export function* userLogin(action: AnyAction) {
  const { email, password } = action.payload;
  const data = {
    email: email,
    password: password,
  };
  try {
    const { token } = yield call(() =>
      request({
        url: `/neouadmin/v1/user/login`,
        method: 'POST',
        data: data,
      }),
    );
    // if login successs store the token
    yield put(requestUserBy());
    cookies.set('adminToken', token);
    yield put(requestUserInfo());
  } catch (error) {
    yield put(isLoginFetching(false));
    yield put(openSnackBar('Login failed'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  }
}

// redirect
export function* redirectByUser() {
  try {
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/user/`,
        method: 'GET',
      }),
    );

    if (result) {
      const permisArr = result.permissions;
      const firstArr = permisArr.length > 0 && permisArr[0] ? permisArr[0] : '';
      let urlPage;
      switch (firstArr) {
        case 'configure':
          urlPage = `/neou/attributes`;
          break;
        case 'library':
          urlPage = `/neou/videos`;
          break;
        case 'live-participants-page':
          urlPage = `/neou/live-class`;
          break;
        case 'interactive':
          urlPage = `/neou/interactive-classes`;
          break;
        case 'curate':
          urlPage = `/neou/home-page-tray`;
          break;
        case 'members':
          urlPage = `/neou/members`;
          break;
        case 'plans':
          urlPage = `/neou/subscription`;
          break;
        case 'my-classes':
          urlPage = `/neou/my-classes`;
          break;
        case 'my-account':
          urlPage = `/neou/my-account`;
          break;
        case 'add-classes':
          urlPage = `/neou/instructor-add-classes`;
          break;
      }
      yield put(push(urlPage === undefined ? `/notfound` : urlPage));
      yield put(storeUserInfo(result));
    }
  } catch (error) {
    yield put(isLoginFetching(false));
    yield put(openSnackBar('Login failed'));
  }
}

export function* userReset(action: AnyAction) {
  try {
    const email = action.payload;
    const data = {
      email: email,
    };
    const { link } = yield call(() =>
      request({
        url: `/neouadmin/v1/user/resetPwdLink`,
        method: 'POST',
        data: data,
      }),
    );
    yield put(push(`/new-password?code=${link}`));
  } catch (error) {
    yield put(openSnackBar('Reset password failed'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  }
}

export function* setNewPassword(action: AnyAction) {
  const { code, newPwd } = action.payload;
  const state = yield select(makeSelectAuthentication());
  try {
    const data = {
      code: code,
      newPwd: newPwd,
    };
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/user/resetPwd`,
        method: 'POST',
        data: data,
      }),
    );
    yield put(push(`/neou`));
  } catch (error) {
    yield put(openSnackBar('Setting new password failed'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  }
}

export function* fetchUserInfo() {
  try {
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/user/`,
        method: 'GET',
      }),
    );
    if (result) {
      yield put(storeUserInfo(result));
    }
    yield put(isLoginFetching(false));
  } catch (error) {
    yield put(isLoginFetching(false));
    yield put(
      openSnackBar(
        'Not enough permissions see the data. Request your super admin for more permission',
      ),
    );
  }
}
