import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the homeCurate state domain
 */

const selectHomeCurateDomain = (state: ApplicationRootState) => {
  return state.homeCurate || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by HomeCurate
 */

const makeSelectHomeCurate = () =>
  createSelector(
    selectHomeCurateDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectHomeCurate;
export { selectHomeCurateDomain };
