import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { PromoComponentSchema } from './ComponentFormSchema';
import {
  withStyles,
  Divider,
  Button,
  Grid,
  createStyles,
  Theme,
  IconButton,
  Typography,
} from '@material-ui/core';
import { arrayRemove, arrayMove, List } from 'baseui/dnd-list';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FormikImageUploader from '../../../components/Formik/FormikImageUploader';
import { ErrorText, ItalicInfoText } from '../../../components/Typography';
import { SearchTypeComponent } from '../../../components/searchTypeComponent';
import { ContentType } from '../types';

interface IPromoComponentProps {
  onSubmit: () => void;
  defaultValue: PromoFormValue;
}

interface ContentData {
  id: string;
  title: string;
  imgUrl?: string;
  contentType: string;
}
interface PromoFormValue {
  contentType: ContentType;
  content: {
    data: ContentData[];
  };
}

const PromoComponent: React.FC<IPromoComponentProps> = ({
  defaultValue,
  classes,
  onSubmit,
}) => {
  const [refList, setRefList] = React.useState([]);
  const [isContentDataErr, setContentData] = React.useState(false);
  const [isErrorMsg, setErrorMsg] = React.useState('');

  const ListContent = React.memo(({ $value, other }) => {
    let { $index } = other;
    return (
      <div className={classes.wrapper}>
        <Typography variant="caption">{$value && $value.title}</Typography>
        <FormikImageUploader
          id={'ImageUploader' + $index}
          key={'ImageUploader' + $index}
          name={`content.data[${other.$index}].imgUrl`}
          aspectRatio="32x9"
          title=""
          defaultValue={$value && $value.imgUrl ? $value.imgUrl : $value.graphics && $value.graphics.promoSpotImg || ''}
        />
      </div>
    );
  });

  const saveContent = ({ values, setFieldValue }) => {
    const contentData =
      values && values.content && values.content.data
        ? values.content.data
        : [];
    if (contentData.length === 0 || contentData.length > 30) {
      setContentData(true);
      setErrorMsg(
        contentData.length === 0
          ? 'Enter atleast one content item'
          : 'Enter atmost 30 item',
      );
      return;
    } else {
      setContentData(false);
      let newContentData = contentData.map((c, index) => {
        if (refList[index] && refList[index].querySelector('input'))
          c.title = refList[index].querySelector('input').value;
        c = {
          ...c,
          imgUrl: c && c.imgUrl ? c.imgUrl : c.graphics ? c.graphics.promoSpotImg : '',
        };
        return c;
      });
      setFieldValue('content.data', newContentData);
    }
  };

  function renderContentDnD(
    values: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
    ListContent: ({
      $value,
      other,
    }: {
      $value: any;
      other: any;
    }) => JSX.Element,
  ) {
    return (
      <List
        removable={true}
        items={values && values.content && values.content.data}
        overrides={{
          Label: {
            component: ({ $value, ...other }) => (
              <ListContent
                $value={$value}
                other={{
                  ...other,
                  values,
                  setFieldValue,
                }}
                key={other.$index}
              />
            ),
          },
          Root: {
            style: {
              maxWidth: '100%',
            },
          },
          Item: {
            style: ({ $theme }) => {
              return {
                boxShadow: '0 2px 2px 0 rgba(0,0,0, 0);',
                borderLeftColor: '#E3E3E4!important',
                borderRightColor: ' #E3E3E4!important',
                borderTopColor: ' #E3E3E4!important',
                borderBottomColor: ' #E3E3E4!important',
                borderLeftWidth: '1px!important',
                borderRightWidth: '1px!important',
                borderTopWidth: '1px!important',
                borderBottomWidth: '1px!important',
                borderRadius: '6px',
                marginBottom: '15px',
                zIndex: 140000,
              };
            },
          },
          DragHandle: {
            style: ({ $theme }) => {
              return {
                marginTop: '-90px !important',
              };
            },
          },
          CloseHandle: {
            component: props => (
              <IconButton {...props}>
                <DeleteOutlinedIcon />
              </IconButton>
            ),
          },
        }}
        onChange={({ oldIndex, newIndex }) => {
          let newList =
            newIndex === -1
              ? arrayRemove(values.content.data, oldIndex)
              : arrayMove(values.content.data, oldIndex, newIndex);
          setFieldValue('content.data', newList);
        }}
      />
    );
  }
  return (
    <div className={classes.root}>
      <Formik
        initialValues={defaultValue}
        enableReinitialize
        validationSchema={PromoComponentSchema}
        onSubmit={!isContentDataErr && onSubmit}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form noValidate>
              <Grid
                container
                alignItems="center"
                className={classes.addBtnWrapper}
              >
                <Grid item xs align="right">
                  <ItalicInfoText>
                    Recommending Image in 1300x460
                  </ItalicInfoText>
                </Grid>
              </Grid>
              <Divider />
              <Grid alignItems="flex-start" className={classes.contentWrapper}>
                <FieldArray
                  name="content.data"
                  render={arrayHelpers => (
                    <div style={{ width: '100%' }}>
                      <SearchTypeComponent
                        key={`search_content`}
                        labelText={
                          'Add an Item: Search for Concept/Collection title'
                        }
                        onVideoListChange={list => {
                          let contentData = [list, ...values.content.data];

                          setFieldValue('content.data', contentData);
                        }}
                        contentType={values && values.contentType}
                      />
                      {isContentDataErr && (
                        <div className={classes.errorText}>
                          <ErrorText>{isErrorMsg}</ErrorText>
                        </div>
                      )}
                      {renderContentDnD(values, setFieldValue, ListContent)}
                    </div>
                  )}
                />
              </Grid>
              <Divider />

              <div className={classes.footer}>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  className={classes.publishBtn}
                  onClick={() => saveContent({ values, setFieldValue })}
                  fullWidth
                >
                  SAVE & PUBLISH CHANGES
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
    },
    addBtnWrapper: {
      padding: '8px 16px',
    },
    contentWrapper: {
      padding: '16px',
      overflow: 'auto',
      height: 'calc(100vh - 180px)',
    },
    footer: {
      padding: '8px 16px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#000',
    },
    promoHolder: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    wrapper: {
      flex: 1,
      margin: '0 8px',
    },
  });

export default React.memo(withStyles(styles)(PromoComponent));
