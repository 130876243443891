import React, { useEffect, useState } from 'react';
import { SliderImage } from '../../../components/SliderImage';
import ClassItem from '../../../components/ClassItem';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import InstructorExpansionPanel from '../../../components/InstructorExponentialComponent';
import Grid from '@material-ui/core/Grid';
import { SvgMyClassThin, SvgShare, SvgPlay, SvgFilter } from '../../../components/NeIcons';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import Loader from '../../../components/Loader';
import CollectionSlider from '../../../components/CollectionSlider';
import { FavoriteBtn } from '../../../components/FavoriteBtn';
import { H3, H8, H6, H5 } from '../../../styles/Typography';
import { Box, ButtonBase, Avatar } from '@material-ui/core';
import InstructorHeader from './InstructorHeader';
import { useDispatch, useSelector } from 'react-redux';
import makeSelectPeople from '../selectors';
import { getPeopleClasses, requestPeopleDetail } from '../actions';
import request from '../../../shared/lib/request';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import reducer from '../reducer';
import { DateTime } from 'luxon';
import saga from '../saga';
import { isNull, isEmpty } from 'lodash';
import { getTimeWithZone } from '../../../utils/calender';
import ProgramHeader from '../../ProgramDetail/ProgramHeader';
import ClassCard from '../../../components/ClassCard';
import {
  appApiURL
} from '../../../utils/config';
import { SocialButton } from '../../../components/SocialButton';
import { IconButton } from '@material-ui/core';
import classImg from '../../../images/16_9 Image Placeholder.svg';

export interface IInstructorLandingPageProps { }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slider: {
      [theme.breakpoints.up('md')]: {
        borderBottom: 'none',
      },
      borderBottom: '1px solid #222A31',
      boxSizing: 'border-box',
      padding: '16px 0',
    },
    shareLinkBtn: {
      cursor: 'pointer',
      height: '42px',
      width: '42px',
      borderRadius: '6px',
      backgroundColor: '#222A31',
      border: '0 solid transparent',
      outline: 'none !important',
      '&:hover': {
        background: 'transparent',
      },
    },
    containerStyle: {
      maxWidth: '1200px',
      margin: 'auto',
      width: '100%',
    },
    AboutInstructor: {
      [theme.breakpoints.up('md')]: {
        borderBottom: 'none',
      },
      borderBottom: '1px solid #222A31',
      boxSizing: 'border-box',
    },
    classHeadWrapper: {
      boxSizing: 'border-box',
      padding: '13px 0px',
      display: 'block',
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '13px 16px',
      },
    },
    classesScroll: {
      maxHeight: '888px',
      overflowY: 'auto',
      marginTop: '16px',
      [theme.breakpoints.between('xs', 'sm')]: {
        marginBottom: '8px',
      },
    },
    instructorItem: {
      [theme.breakpoints.up('xl')]: {
        marginTop: '26px',
      },
      padding: '16px',
      maxWidth: '1200px',
      margin: 'auto',
    },
    leftPanel: {
      [theme.breakpoints.up('md')]: {
        padding: '16px 12px 16px 0px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '22px 16px 22px 0px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '28px 20px 28px 0px',
      },
    },
    rightPanel: {
      [theme.breakpoints.up('md')]: {
        padding: '16px 24px 16px 12px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '22px 32px 22px 16px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '28px 20px 28px 20px',
      },
    },
    load: {
      margin: 'auto',
      display: 'flex',
      padding: '16px 0px 8px 0px;',
      [theme.breakpoints.only('xl')]: {
        padding: '32px 0px 24px 0px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '28px 0px 22px 0px',
      },
      '& img': {
        width: '28px',
        height: '28px',
      },
    },
    borderBtm: {
      width: '100%',
      borderBottom: '1px solid #222A31',
    },
    classWrapper: {
      maxHeight: 'calc(100vh - 180px)',
      overflow: 'auto',
    },
    contentSection: {
      padding: '0px 16px',
      maxWidth: '1200px',
      margin: 'auto',
    },
    about: {
      height: '20px',
      color: '#fff',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '18px',
      margin: 0,
      // borderBottom: '3px solid rgba(34,42,49,1)',
    },
    favoriteBtn: {
      color: '#fff',
      backgroundColor: '#222A31',
      borderRadius: '6px',
      cursor: 'pointer',
      zIndex: 4,
      '& svg': {
        color: 'white',
      },
    },
    miniConceptSection: {
      margin: '12px 0',
    },
    conceptTitle: {
      color: 'rgba(255,255,255,1)',
      fontSize: '12px',
      fontFamily: 'ProximaNova-Semibold',
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    miniConcepts: {
      display: 'flex',
    },
    miniConceptImg: {
      width: '104px',
      height: 'auto',
      borderRadius: '5px',
      backgroundColor: 'transparent',
      marginRight: '8px',
      '& img': {
        width: '100%',
      },
    },
    miniConceptTitle: {
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '14px',
      lineHeight: '18px',
      color: 'rgba(255,255,255,1)',
      margin: '6px 0',
    },
    classSet: {
      display: 'initial',
      backgroundColor: 'transparent',
      boxShadow: '0px',
    },
    date: {
      fontSize: '14px',
      fontFamily: 'ProximaNova-Bold',
      color: '#fff',
      textTransform: 'uppercase',
      padding: '11px',
      backgroundColor: '#080B0D',
      borderBottom: '1px solid #35414A',
      borderTop: '1px solid #35414A',
    },
    subHead: {
      color: '#FFFFFF',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '17px',
      lineHeight: '18px',
      borderBottom: '2px solid #222A31',
      paddingBottom: '25px',
    },
    emptyClass: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '240px',

      color: 'rgba(140,148,155,1)',
      '& svg': {
        width: '67px',
        height: '60px',
        marginBottom: '24px',
      },
      '& p': {
        fontStyle: 'italic',
        textAlign: 'center',
        maxWidth: '280px',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Proxima-Nova-Regular',
      },
    },
    readOnly: {
      pointerEvents: 'none',
    },
    classHeader: {
      padding: '13px 0px',
      boxSizing: 'border-box',
      borderBottom: '1px solid #222A31',
    },
    filterWrapper: {
      marginRight: '8px',
      height: '32px',
      width: '89px',
      borderRadius: '6px',
      backgroundColor: '#222A31',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
    },
    filterMargin: {
      marginRight: '8px',
    },
    videoScrollWrap: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '16px',
      maxHeight: '640px',
      overflowY: 'auto',
      marginBottom: '24px',
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '14px 16px',
        marginBottom: '0',
        marginTop: '0',
      },
    },
    videoThumbanil: {
      position: 'relative',
      '& .MuiIconButton-label': {
        height: '100% !important',
      },
      '& .MuiIconButton-root': {
        position: 'absolute',
        top: 'calc(50% - 32px)',
        left: 'calc(50% - 32px)',
        width: '64px',
        height: '64px',
        padding: 0,
        [theme.breakpoints.down('md')]: {
          position: 'absolute',
          top: 'calc(50% - 24px)',
          left: 'calc(50% - 24px)',
          width: '48px',
          height: '48px',
        },
        zIndex: 1,
        cursor: 'pointer',
      },
    },
  }),
);

const stateSelector = createStructuredSelector({
  people: makeSelectPeople(),
});

export default function InstructorLandingPage(props) {
  const classes = useStyles();
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'people', reducer: reducer });
  useInjectSaga({ key: 'people', saga: saga });
  const [instrctorData, setInstructorData] = React.useState(null);
  const [timeStamb, setTimeStamb] = React.useState({});
  const [isPathType, setPathType] = React.useState('');
  const [interactiveData, setClasses] = React.useState(null);

  var time = 0;
  useEffect(() => {
    if (time === 0) {
      var timer = setInterval(() => {
        setTimeStamb(moment());
      }, 30000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [time]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props && props.match && props.location.pathname) {
      let id = props.location.search.replace('?id=', '');
      setPathType(props.match.params.url)
      dispatch(getPeopleClasses(props.match.params.id));
      dispatch(requestPeopleDetail(props.match.params.id));
    }
  }, []);

  const { people } = useSelector(stateSelector);
  let { instructorDetail = {}, isInstructorLoading, relatedClasses } = people;
  let total;
  let videos;
  if (instrctorData) {
    total = instrctorData.total;
    videos = instrctorData.classes;
  }
  let {
    id,
    firstName,
    lastName,
    permaLink,
    gallery,
    website,
    facebook,
    twitter,
    instagram,
    description,
    interactive,
  } = instructorDetail || {};
  useEffect(() => {
    let timezone = DateTime.local().zoneName;
    if(!isNull(instructorDetail)){
        let { isPublished, isHidden } = instructorDetail && instructorDetail;
      let requestURL;
      if (isPublished) {
        requestURL = `${appApiURL}/neou/v1/instructor-detail?permaLink=${instructorDetail.permaLink}&classOffset=0&classLimit=20`;
      }else if (!isPublished && !isHidden) {
        requestURL = `${appApiURL}/neou/v1/instructor-detail?permaLink=${instructorDetail.permaLink}&classOffset=0&classLimit=20&isPublished=false&isHidden=false`
      }else{
        requestURL = `${appApiURL}/neou/v1/instructor-detail?permaLink=${instructorDetail.permaLink}&classOffset=0&classLimit=20&isPublished=false&isHidden=true`
      }
      async function searchVideos() {
        if (permaLink) {
          let { result } = await request({
            url: requestURL,
            method: 'POST',
          });
          setInstructorData(result[0]);
        }
      }
      searchVideos();
    }
  }, [instructorDetail]);

  useEffect(() => {
    let timezone = DateTime.local().zoneName;
    async function getClasses() {
      if (permaLink) {
        let { result } = await request({
          url: `${appApiURL}/neou/v1/get-instructors?interactive=true&permaLink=${permaLink}&timeZone=${timezone}`,
          method: 'POST',
        });
        setClasses(result[0]);
      }
    }
    getClasses();
  }, [permaLink]);

  let kicker = '';
  firstName = instructorDetail && instructorDetail.firstName;
  lastName = (instructorDetail && instructorDetail.lastName) || '';
  if (isEmpty(instrctorData)) return <Loader />;
  return (
    <div>
      <div
        style={{
          backgroundColor: '#12161A',
          minHeight: 'calc(100vh - 10px)',
          maxHeight: 'calc(100vh - 10px)',
          overflow: 'auto',
        }}
      >
        <div
          style={{ borderBottom: '1px solid #35414A' }}
          className={classes.readOnly}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.instructorItem}>
                {isPathType !== 'VOD' ?
                  <InstructorHeader
                    title={`${firstName + ' ' + lastName}`}
                    id={id}
                    permaLink={permaLink}
                    isFavorited={false}
                  /> :
                  <ProgramHeader title={firstName + ' ' + lastName} />}
              </Box>
            </Grid>
            <div className={classes.containerStyle}>
              <Grid container className={classes.contentSection}>
                <Grid item xs={12} md={6} className={classes.leftPanel}>
                  {isPathType !== 'VOD' &&
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid container spacing={7}>
                        <Grid item sm={7} md={8} lg={9}>
                          <p className={classes.about}>About {`${instrctorData.firstName} ${instrctorData.lastName}`}</p>
                          
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <FavoriteBtn classes={classes.favoriteBtn} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <button
                            className={classes.shareLinkBtn}
                          >
                            <SvgShare />
                          </button>
                        </Grid>
                      </Grid>
                    </div>}
                  <div className={classes.slider}>
                  <CollectionSlider>
                    {gallery && gallery.length > 0 ? (
                      gallery.map((item, index) => {
                        if (item.type === 'image') {
                          return <SliderImage imgUrl={item.url} key={`image_${index}`} />;
                        } else {
                          return (
                            <div className={classes.videoThumbanil} key={`video_${index}`}>
                              <SliderImage imgUrl={item.thumbnail} />
                              <IconButton>
                                <SvgPlay />
                              </IconButton>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <SliderImage imgUrl={classImg} />
                    )}
                  </CollectionSlider>
                  </div>
                  <div className={classes.AboutInstructor}>
                    <InstructorExpansionPanel
                      head={isPathType === 'VOD' && `About ${firstName + ' ' + lastName}`}
                      body={description}
                      instructors={[]}
                      socialInsta={instagram}
                      socialFb={facebook}
                      socialTwitter={twitter}
                      socialWeb={website}
                    />
                  </div>
                  <div className={classes.socialBtnWrapper}>
                        {instructorDetail && instructorDetail.instagram && (
                          <SocialButton
                            color="secondary"
                            style={{background: '#35414A', color: '#fff'}}
                            //onClick={() => openLink(instructor?.instagram)}
                          >
                            <i className="fa fa-instagram" aria-hidden="true" />
                          </SocialButton>
                        )}

                        {instructorDetail && instructorDetail.facebook && (
                          <SocialButton
                            color="secondary"
                            style={{background: '#35414A', color: '#fff'}}
                            //onClick={() => openLink(instructor?.facebook)}
                          >
                            <i
                              className="fa fa-facebook pad-icons"
                              aria-hidden="true"
                            />
                          </SocialButton>
                        )}

                        {instructorDetail && instructorDetail.twitter && (
                          <SocialButton
                            color="secondary"
                            style={{background: '#35414A', color: '#fff'}}
                            //onClick={() => openLink(instructor?.twitter)}
                          >
                            <i
                              className="fa fa-twitter pad-icons"
                              aria-hidden="true"
                            />{' '}
                          </SocialButton>
                        )}

                        {instructorDetail && instructorDetail.website && (
                          <SocialButton
                            color="secondary"
                            style={{background: '#35414A', color: '#fff'}}
                            //onClick={() => openLink(instructor?.website)}
                          >
                            <i
                              className="fa fa-globe pad-icons"
                              aria-hidden="true"
                            />
                          </SocialButton>
                        )}
                      </div>
                  {/* {!isNull(instrctorData) &&
                    instrctorData.concepts &&
                    Array.isArray(instrctorData.concepts) &&
                    instrctorData.concepts.length > 0 && (
                      <section className={classes.miniConceptSection}>
                        <p className={classes.conceptTitle}>CONCEPTS</p>
                        <div className={classes.miniConcepts}>
                          {instrctorData.concepts.map((concept, index) => {
                            return (
                              <div key={index}>
                                <a
                                  style={{ textDecoration: 'none' }}
                                  href={`${window.location.origin}/neou-interactive/concepts${concept.permaLink}`}
                                  onClick={e => {
                                    e.preventDefault();
                                    if (concept.permaLink) {
                                      history.push(
                                        `/neou-interactive/concepts${concept.permaLink}`,
                                      );
                                    }
                                  }}
                                >
                                  <ButtonBase>
                                    <Avatar
                                      aria-label="recipe"
                                      classes={{
                                        root: classes.miniConceptImg,
                                      }}
                                      src={
                                        concept.graphics &&
                                        concept.graphics['16x9']
                                      }
                                    >
                                      <img
                                        alt=""
                                        src={require('../../../images/16_9 Image Placeholder.svg')}
                                      />
                                    </Avatar>
                                  </ButtonBase>
                                </a>
                                <p className={classes.miniConceptTitle}>
                                  {concept.title}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </section>
                    )} */}
                </Grid>
                <Grid item xs={12} md={6} className={classes.rightPanel}>
                  {isPathType !== 'VOD' ?
                    <Grid
                      container
                      item
                      justify={'flex-start'}
                      alignItems={'flex-start'}
                      direction="column "
                      className={classes.classHeadWrapper}
                    >
                      <div className={classes.subHead}>
                        Upcoming Group Interactives
                      </div>
                      <div className={classes.classWrapper}>
                  {interactiveData && interactiveData.classes &&
                  Object.keys(interactiveData && interactiveData.classes).length > 0 ? (
                    Object.keys(interactiveData && interactiveData.classes)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .map((dateKey, index) => {
                        return (
                          <Card className={classes.classSet} key={index}>
                            <div style={{ display: 'initial' }}>
                              <div style={{ position: 'sticky', top: 0 }}>
                                <div className={classes.date}>
                                  {moment(dateKey).calendar(null, {
                                    lastDay: '[Yesterday]',
                                    sameDay: '[Today]',
                                    nextDay: '[Tomorrow]',
                                    lastWeek: '[last] dddd',
                                    nextWeek: 'dddd',
                                    sameElse: 'L',
                                  })}{' '}
                                  {moment(dateKey).format('MMM DD')}
                                </div>
                              </div>
                              <div>
                                {interactiveData && interactiveData.classes[dateKey].map(
                                  (classItem, index) => {
                                    let timezone = getTimeWithZone(
                                      +classItem.startDate,
                                    );
                                    return (
                                      <ClassItem
                                        key={index}
                                        classItem={classItem}
                                        instructor={interactiveData}
                                        //onBooking={bookClass}
                                        time_zone={timezone}
                                        time={timeStamb}
                                      />
                                    );
                                  },
                                )}
                              </div>
                            </div>
                          </Card>
                        );
                      })
                  ) : (
                    <div className={classes.emptyClass}>
                      <SvgMyClassThin />
                      <p>
                        Classes coming soon! Favorite this instructor to get
                        updates.
                      </p>
                    </div>
                  )}
                </div>
                    </Grid> :
                    <>
                      <Grid
                        container
                        item
                        // justify={'space-between'}
                        alignItems={'center'}
                        direction="row"
                        className={classes.classHeader}
                      >
                        <H3 style={{ marginRight: '4px' }}>All Classes</H3>
                        <H8>{`(${total && total > 0 ? total : 0})`}</H8>
                        <div
                          className={classes.filterWrapper}
                        >
                          <H6 className={classes.filterMargin}>Filter</H6>
                          {/* <span onClick={InititalCall}> */}
                          <span>{<SvgFilter />}</span>
                        </div>
                      </Grid>
                      <div className={classes.videoScrollWrap}>
                        {videos &&
                          videos.length > 0 ?
                          videos.map(item => {
                            return (
                              <ClassCard
                                key={item.id}
                                item={item}
                              // seriesId={id}
                              // title={title}
                              />
                            );
                          }): (
                          <div className={classes.emptyClass}>
                            <SvgMyClassThin />
                            <p>
                              Classes coming soon! Favorite this instructor to get
                              updates.
                            </p>
                          </div>
                          )}
                      </div>
                    </>
                  }
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
}
