import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Greet } from '../styles/Typography';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#12161A',
      '& .MuiPaper-root': {
        background: '#12161A',
      },
      '& .MuiCardMedia-root': {
        borderRadius: '6px',
        // boxShadow: '0 0 4px 0 #050606',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
          // height: 'calc(16em)',
          objectFit: 'fill',
        },
      },
      '& .MuiCardContent-root': {
        padding: '8px',
        color: '#FFFFFF',
        fontFamily: 'Proxima-Nova-Regular',
      },
      '& .MuiSvgIcon-root': {
        margin: '8px',
      },
    },
    title: {
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '16px',
      lineHeight: '18px',
      textAlign: 'center',
      paddingTop: '12px',
      [theme.breakpoints.up('xl')]: {
        fontSize: '22px',
        lineHeight: '21.6px',
      },
    },
    loading: {
      animation: '$fading 1.5s infinite',
    },

    cardActionRoot: {
      position: 'relative',
      paddingTop: '56.25%',
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '6px',
      '& .MuiCardMedia-root': {
        position: 'absolute',
        top: 0,
        // zIndex: 1,
      },
    },
    lg: {
      paddingTop: '28.125%',
    },
    '@keyframes fading': {
      '0%': {
        opacity: 0.1,
      },
      '50%': {
        opacity: 0.2,
      },
      '100%': {
        opacity: 0.1,
      },
    },
  }),
);

export function SliderImage(props) {
  let { imgUrl, text, size, permaLink, collectionType, history } = props;
  const classes = useStyles();
  let imageUrl = (imgUrl && imgUrl.url) || imgUrl;
  let isLoading = !imageUrl || (imageUrl && imageUrl.length === 0);

  const redirect = () => {
    switch (collectionType) {
      case 'concept':
        return history.push(`/neou/concepts${permaLink}`);
      case 'program':
        return history.push(`/neou/programs${permaLink}`);
      case 'challenge':
        return history.push(`/neou/challenges${permaLink}`);
      case 'interest':
        return history.push(`/neou/interests${permaLink}`);
      default:
        return null;
    }
  };

  return (
    <div className={classes.root} onClick={redirect}>
      <Card>
        <CardActionArea
          className={`${classes.cardActionRoot} ${isLoading &&
            classes.loading}  && ${size === '32x9' && classes.lg}`}
        >
          <CardMedia component="img" alt="" image={imageUrl} title={text} />
        </CardActionArea>
        {text && <div className={classes.title}>{text}</div>}
      </Card>
    </div>
  );
}
