/*
 *
 * MyAccount constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/MyAccount/DEFAULT_ACTION',
  REQUEST_PAST_CLASSES = 'REQUEST_PAST_CLASSES',
  SET_PAST_CLASS_INFO = 'SET_PAST_CLASS_INFO',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
}

export default ActionTypes;
