import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import request from '../../shared/lib/request';
import { openSnackBar, closeSnackBar } from '../Root/actions';
import {
  durationFailed,
  VideoListSuccess,
  videoListFailed,
  VideoListSuccessWithFiltersInteractive,
  filtersFetchSuccess,
  filtersFetchFailed,
  putClassPagination,
  setClassPaginationLoader,
  classListPaginationSuccess,
  classListCountSuccess,
  storeClients,
  UpcomingCalenderListSuccess,
  filterUpdateList,
  storeClientsJoined,
} from './actions';
import ActionTypes from './constants';
import { selectInteractivePublishedClassesDomain } from './selectors';
import { videoListFailedWithFilters } from '../Video/actions';
import { AnyAction } from 'redux';

// Individual exports for testing
export default function* interactivePublishedClassesSaga() {
  yield takeLatest(
    ActionTypes.REQUEST_VIDEO_LIST_WITH_FILTERS_INTERACTIVE,
    searchResultsInteractive,
  );
  yield takeLatest(
    ActionTypes.REQUEST_CLASS_LIST_PAGINATION,
    getClassListPagination,
  );
  yield takeLatest(
    ActionTypes.VIDEO_LIST_WITH_FILTERS_PAGINATION,
    searchResultsPagination,
  );
  yield takeLatest(ActionTypes.REQUEST_FILTER_PARAMS, fetchSearchFilter);
  yield takeLatest(ActionTypes.ADD_CLASS_EVENT, addClassEvent);
  yield takeLatest(
    ActionTypes.REQUEST_INTERACTIVE_CALENDER_LIST,
    getUpcomingCalender,
  );
  yield takeLatest(
    ActionTypes.REQUEST_INTERACTIVE_VIDEO_LIST,
    getInteractiveVideoList,
  );
  yield takeLatest(ActionTypes.REQUEST_MY_CLIENT, getMyClassClients);
  yield takeLatest(ActionTypes.SEND_CAMPAIGN_EMAIL, sendEmailCampaign);
  yield takeLatest(
    ActionTypes.REQUEST_JOINED_CLIENTS_LIST,
    getMyClassClientsJoined,
  );
}

const SLICE_COUNT_OF_CLASSES = 20;

// get
export function* getMyClassClients(action: AnyAction) {
  try {
    const videoId = action.payload;
    if (videoId) {
      let { result } = yield call(() =>
        request({
          url: `/neouadmin/v1/get-booked-user-list?videoId=${videoId}`,
          method: 'GET',
        }),
      );
      yield put(storeClients(result));
    }
  } catch (error) {
    if (error) {
    }
  }
}

// get
export function* getMyClassClientsJoined(action: AnyAction) {
  try {
    const videoId = action.payload;
    if (videoId) {
      let { result } = yield call(() =>
        request({
          url: `/neouadmin/v1/get-joiners-list?videoId=${videoId}`,
          method: 'GET',
        }),
      );
      yield put(storeClientsJoined(result));
    }
  } catch (error) {
    if (error) {
    }
  }
}

// send email campaign
export function* sendEmailCampaign(action: AnyAction) {
  try {
    const bodyLoad = action.payload;
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/send-emailto-participants`,
        method: 'POST',
        data: bodyLoad,
      }),
    );
    yield put(openSnackBar('Campaign email sent successfully.'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  } catch (error) {
    if (error) {
      if (error.data.error) {
        yield put(openSnackBar(`${error.data.error}`));
      } else {
        yield put(openSnackBar(`Please Enter valid campaignId.`));
      }

      setTimeout(() => {
        put(closeSnackBar());
      }, 1000);
    }
  }
}

export function* searchResultsInteractive(action) {
  const values = action.payload;
  const bodyLoad = {};
  const objects = Object.keys(action.payload);
  const { filterData } = yield select(selectInteractivePublishedClassesDomain);
  let durationLength =
    (filterData &&
      filterData.duration &&
      filterData.duration.values &&
      filterData.duration.values.length) ||
    0;
  objects.map(item => {
    if (item === 'keyword') {
      if (values[item].length > 0) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'explicitLang') {
      if (values[item]) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'difficulty') {
      if (values[item].length !== 4 && values[item].length !== 0) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'duration') {
      if (values[item].length !== durationLength && values[item].length !== 0) {
        bodyLoad[item] = values[item];
      }
    } else if (
      item !== 'explicitLang' &&
      item !== 'keyword' &&
      item !== 'difficulty' &&
      item !== 'duration' &&
      values[item] &&
      values[item].length > 0
    ) {
      bodyLoad[item] = values[item];
    }
  });
  bodyLoad['videoType'] = ['groupInteractive'];
  try {
    const { result, total } = yield call(() =>
      request({
        url: `/neouadmin/v1/search?interactive=true`,
        method: 'POST',
        data: bodyLoad,
      }),
    );
    let resultObj = {
      total: result.classes.total,
      result: result.classes.result,
    };
    yield put(classListCountSuccess(total));
    yield put(VideoListSuccessWithFiltersInteractive(resultObj));
    yield delay(1000);
    yield put(filterUpdateList());
  } catch (error) {
    yield put(videoListFailedWithFilters());
  }
}

export function* fetchSearchFilter(action) {
  const key = action.payload;
  try {
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/search?interactive=true`,
        method: 'POST',
      }),
    );
    const newObj = {};
    result.filterParams.map(itm => {
      newObj[itm.name] = itm;
    });
    const filterObject = Object.keys(newObj);
    filterObject.map(item => {
      if (item !== 'explicitLang') {
        newObj[item].values &&
          newObj[item].values.map((itm, index) => {
            if (item == 'difficulty') {
              itm.isSelected = true;
              itm.mainTitle = item;
              itm.index = index;
            } else {
              itm.isSelected = false;
              itm.mainTitle = item;
              itm.index = index;
            }
          });
      }
    });
    yield put(filtersFetchSuccess(newObj));
  } catch (error) {
    yield put(filtersFetchFailed());
  }
}

export function* addClassEvent(action) {
  try {
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/videos`,
        method: 'POST',
        data: action.payload,
      }),
    );
    //yield put(requestVideoList());
    yield put(openSnackBar('Class Added Successfully'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  } catch (error) {
    yield put(durationFailed());
    yield put(
      openSnackBar(
        error && error.data && error.data.error
          ? error.data.error
          : 'Class Adding Failed',
      ),
    );
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  }
}

export function* getUpcomingCalender() {
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/get-videos?interactive=true&isUpcoming=true&isPublished=true&isHidden=false&sortBy=startDate&sortOrder=asc&offset=0&limit=100`,
        method: 'POST',
      }),
    );
    yield delay(1000);
    yield put(UpcomingCalenderListSuccess(result));
  } catch (error) {
    yield put(videoListFailed());
    yield put(VideoListSuccess([]));
  }
}

export function* getInteractiveVideoList() {
  try {
    let { sortBy, activeTab } = yield select(
      selectInteractivePublishedClassesDomain,
    );
    if (sortBy === '') {
      sortBy = 'startDate=desc';
    }

    const splitResult = sortBy.split('=');

    let { result, total } = yield call(() =>
      request({
        url: `/neouadmin/v1/get-videos?interactive=true&${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}`,
        method: 'POST',
      }),
    );
    yield delay(1000);
    yield put(VideoListSuccess(result));
    //yield put(VideoListCountSuccess(total));
    yield put(classListCountSuccess(total));
    // yield put(updateSearchCount());
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(videoListFailed());
    }
    yield put(videoListFailed());
    yield put(VideoListSuccess([]));
  }
}

export function* getClassListPagination(action) {
  yield put(setClassPaginationLoader(true));
  try {
    let { sortBy, activeTab } = yield select(
      selectInteractivePublishedClassesDomain,
    );
    if (sortBy === '') {
      sortBy = 'startDate=asc';
    }
    const splitResult = sortBy.split('=');

    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/get-videos?interactive=true&${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&offset=${action.payload}&limit=${SLICE_COUNT_OF_CLASSES}`,
        method: 'POST',
      }),
    );
    yield put(classListPaginationSuccess(result));
    yield delay(2000);
    yield put(putClassPagination());
    yield put(classListPaginationSuccess([]));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(classListPaginationSuccess([]));
      yield put(setClassPaginationLoader(false));
    }
    yield put(videoListFailed());
    yield put(setClassPaginationLoader(false));
  }
}

export function* searchResultsPagination(action) {
  const { values, count } = action.payload;
  const bodyLoad = {};
  const objects = Object.keys(action.payload.values);
  const { filterData } = yield select(selectInteractivePublishedClassesDomain);
  let durationLength =
    (filterData &&
      filterData.duration &&
      filterData.duration.values &&
      filterData.duration.values.length) ||
    0;
  objects.map(item => {
    if (item === 'keyword') {
      if (values[item].length > 0) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'explicitLang') {
      if (values[item]) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'difficulty') {
      if (values[item].length !== 4 && values[item].length !== 0) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'duration') {
      if (values[item].length !== durationLength && values[item].length !== 0) {
        bodyLoad[item] = values[item];
      }
    } else if (
      item !== 'explicitLang' &&
      item !== 'keyword' &&
      item !== 'difficulty' &&
      item !== 'duration' &&
      values[item] &&
      values[item].length > 0
    ) {
      bodyLoad[item] = values[item];
    }
  });
  bodyLoad['videoType'] = ['groupInteractive'];
  try {
    yield put(setClassPaginationLoader(true));
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/search?interactive=true&offset=${count}&limit=${SLICE_COUNT_OF_CLASSES}`,
        method: 'POST',
        data: bodyLoad,
      }),
    );
    let resultObj = {
      total: result.classes.total,
      result: result.classes.result,
    };
    yield put(classListPaginationSuccess(resultObj && resultObj.result));
    yield delay(2000);
    yield put(putClassPagination());
    yield put(classListPaginationSuccess([]));
    yield put(classListCountSuccess(result.classes.total));
  } catch (error) {
    yield put(videoListFailedWithFilters());
    if (error && error.data && error.data.responseCode === 404) {
      yield put(classListPaginationSuccess([]));
      yield put(setClassPaginationLoader(false));
    }
    yield put(videoListFailed());
    yield put(setClassPaginationLoader(false));
  }
}
