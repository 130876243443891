import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SvgIconPlayArrow } from '../../../components/NeIcons';
import moment from 'moment';
import { useStyles } from './styles';
import { IconButton } from '@material-ui/core';
import LiveVideoFormDialog from './LiveVideoFormDialog';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

export default function LiveVideoListTable(props) {
  const classes = useStyles();
  const { list } = props;
  const [editVideoInfo, setEditVideoInfo] = React.useState({
    isOpen: false,
    id: '',
    clients: {},
  });

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead />
          <TableBody>
            <TableRow key={list.id} hover role="checkbox" tabIndex={-1}>
              <TableCell align="left">
                <div className={classes.firstColumn}>
                  <div className={classes.box}>
                    <SvgIconPlayArrow />
                  </div>
                  <div className={classes.firstCoulmContent}>
                    <div className={classes.conceptTitle}>{list.title}</div>
                    <div className={classes.text}>
                      Start Time:{' '}
                      {moment(+list.startDate).format(`MM/DD/YYYY - HH:mm A`)}
                    </div>
                    <div className={classes.classLive}>LIVE NOW</div>
                  </div>
                </div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.text}>
                  Duration : {list && list.duration ? list.duration : 0} mins
                </div>
                <div className={classes.text}>
                  No.of Users :{' '}
                  {list && list.users && list.users.length > 0
                    ? list.users.length
                    : 0}
                </div>
              </TableCell>
              <TableCell align="center">
                {' '}
                <div className={`${classes.thirdColumn} ${classes.published}`}>
                  {'PUBLISHED'}
                </div>
              </TableCell>
              <TableCell
                align="right"
                className={classes.viewParticipants}
                onClick={() => {
                  setEditVideoInfo({
                    isOpen: true,
                    clients: list,
                    id: list.id,
                  });
                }}
              >
                <IconButton classes={{ root: classes.editIcon }}>
                  <PeopleAltIcon />
                </IconButton>
                <div> View Participants</div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {editVideoInfo.isOpen && (
        <LiveVideoFormDialog
          isOpen={editVideoInfo.isOpen}
          id={editVideoInfo.id}
          clients={(list && list.users) || []}
          handleClose={() =>
            setEditVideoInfo({ isOpen: false, id: '', clients: {} })
          }
        />
      )}
    </Paper>
  );
}
