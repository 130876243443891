/*
 *
 * InstructorMyClasses reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  weeks: [],
  classes: {},
  activeWeek: '',
  activeWeekInfo: {},
  dates: [],
  classCount: 0,
  isLoading: false,
};

function instructorMyClassesReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_MY_CLASSES:
      return { ...state };
    case ActionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case ActionTypes.SET_WEEKS:
      return { ...state, weeks: action.payload };
    case ActionTypes.SET_ACTIVE_WEEK:
      return { ...state, activeWeek: action.payload };
    case ActionTypes.SET_ACTIVE_WEEK_INFO:
      return { ...state, activeWeekInfo: action.payload };
    case ActionTypes.SET_DATES:
      return { ...state, dates: action.payload };
    case ActionTypes.STORE_CLASS:
      let { classes, classCount = 0 } = action.payload;
      return { ...state, classes, classCount };
    default:
      return state;
  }
}

export default instructorMyClassesReducer;
