/*
 *
 * InstructorMyClasses constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/InstructorMyClasses/DEFAULT_ACTION',
  SET_WEEKS = 'SET_WEEKS',
  STORE_CLASS = 'STORE_CLASS',
  REQUEST_MY_CLASSES = 'REQUEST_MY_CLASSES',
  SET_ACTIVE_WEEK = 'SET_ACTIVE_WEEK',
  SET_ACTIVE_WEEK_INFO = 'SET_ACTIVE_WEEK_INFO',
  SET_DATES = 'SET_DATES',
  SET_LOADING = 'SET_LOADING',
}

export default ActionTypes;
