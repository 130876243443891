import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the interactiveCoupon state domain
 */

const selectInteractiveCouponDomain = (state: ApplicationRootState) => {
  return state.interactiveCoupon || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by InteractiveCoupon
 */

const makeSelectInteractiveCoupon = () =>
  createSelector(
    selectInteractiveCouponDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectInteractiveCoupon;
export { selectInteractiveCouponDomain };
