/*
 *
 * BrandVoice
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { requestbrandVoice, updateBrandVoice } from './actions';
import { useStyles } from './styles';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectBrandVoice from './selectors';
import reducer from './reducer';
import saga from './saga';
import HomePageSection from '../../components/VoiceBrandPage/HomePageSection';
import PostWatchSection from '../../components/VoiceBrandPage/PostWatchSection';
import InviteMessage from './components/InviteMessage';

const stateSelector = createStructuredSelector({
  brandVoice: makeSelectBrandVoice(),
});

interface Props {}

function BrandVoice(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'brandVoice', reducer: reducer });
  useInjectSaga({ key: 'brandVoice', saga: saga });

  const { brandVoice } = useSelector(stateSelector);
  let { brandVoiceData } = brandVoice;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestbrandVoice());
  }, []);
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>Curate Brand Voice</title>
        <meta name="description" content="Description of BrandVoice" />
      </Helmet>
      <div className={classes.headWrap}>
        <div className={classes.head}>Curate: Brand Voice</div>
      </div>
      <HomePageSection list={brandVoiceData} />
      <PostWatchSection list={brandVoiceData} />
      <InviteMessage
        componentData={{
          componentTitle: 'Email Message',
          invitePeopletoNeoU: brandVoiceData.invitePeopletoNeoU,
          inviteDisplayText: brandVoiceData.inviteDisplayText,
        }}
      />
    </div>
  );
}

export default BrandVoice;
