import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { PageTitle } from '../../styles/Typography';
import { SvgShare, SvgBack } from '../../components/NeIcons';

import { IHeaderProps, useStyles } from '../../components/Header';
import { withRouter } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';

const ProgramHeader: React.FunctionComponent<IHeaderProps> = ({
  history,
  title,
  page,
  id,
  permaLink,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      <Grid container alignItems={'center'} className={classes.containerStyle}>
        <Grid item xs={3} md={2}>
          <IconButton className={classes.goBackIcon} onClick={history.goBack}>
            <SvgBack />
          </IconButton>
        </Grid>

        <Grid className={classes.title} item xs={6} md={8}>
          <PageTitle style={{ textTransform: 'capitalize' }}>{title}</PageTitle>
        </Grid>

        <Grid item className={classes.iconWrapper} xs={3} md={2}>
          <IconButton className={classes.shareIcon}>
            <div
            // onClick={() => {
            //   shareSelected(id, page, title);
            // }}
            >
              <SvgShare />
            </div>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(ProgramHeader);
