import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { colors } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SvgClose, SvgIconSearch } from '../NeIcons';

const focusedColor = '#000';
const defaultColor = '#909599';
const defaultLabelColor = '#909599';
const hoverLabelColor = '#060B0F';
const focusedLabelColor = '#060B0F';
const inputHoverColor = '#E3E3E4';
const inputFocusedColor = '#060B0F';
const errorColor = '#FF1C58';
const borderColor = '#E3E3E4';

export const AutocompleteInput = withStyles({
  root: {
    width: '100%',
    '& label.MuiInputLabel-outlined': {
      color: defaultColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 16px) scale(1)',
      color: defaultColor,
      fontSize: '16px',
      fontFamily: 'Proxima-Nova-Regular',
      fontWeight: '500',
      letterSpacing: '0',
      lineHeight: '20px',
    },

    '& label.Mui-focused': {
      color: focusedLabelColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.Mui-focused.Mui-error': {
      color: errorColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: borderColor,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      '& fieldset': {
        borderColor,
      },
      '&:hover fieldset': {
        borderColor: inputHoverColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${inputFocusedColor}`,
      },
    },
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        color: focusedColor,
        padding: '5.5px 14px',
        fontFamily: 'Proxima-Nova-Regular',
        fontStyle: 'italic',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      borderRadius: '6px',
      '& fieldset': {
        borderColor: errorColor,
      },
      '&:hover fieldset': {
        color: defaultColor,
        borderColor: errorColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${errorColor}`,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '4px',
      '&.Mui-error': {
        color: errorColor,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '16px 0 12px',
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
    tag: {
      background: '#3C4042',
      color: '#fff',
      height: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    deleteIcon: {
      color: '#fff',
      width: '18px',
      opacity: 1,
      '&:hover': {
        color: '#fff',
        opacity: '0.5',
      },
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    autocompleteOptions: {
      fontFamily: 'Proxima-Nova-Regular',
    },
    popupIndicatorOpen: {
      transform: 'none',
    },
    popupIndicator: {
      marginRight: '12px',
    },
  }),
);

export default function SearchConcept(props) {
  const classes = useStyles();
  const {
    options = [],
    placeholder = 'Add',
    label = 'Pin a Concept',
    name,
    defaultValue,
  } = props;

  const [value, setValue] = useState(true);

  React.useEffect(() => {
    setValue(!value);
  }, [defaultValue]);

  return (
    <div className={classes.root}>
      <Autocomplete
        classes={{
          tag: classes.tag,
          option: classes.autocompleteOptions,
          popupIndicatorOpen: classes.popupIndicatorOpen,
          popupIndicator: classes.popupIndicator,
        }}
        id="multiple-limit-tags"
        getOptionLabel={option => option.title}
        ChipProps={{
          classes: {
            deleteIcon: classes.deleteIcon,
            label: classes.chipLabel,
          },
        }}
        options={props.options || []}
        ref={props.setInputRef}
        renderInput={params => (
          <AutocompleteInput
            {...params}
            variant="outlined"
            name="searchConcept"
            label={label}
            placeholder={placeholder}
            onChange={e => props.onSearchTextChange(e.target.value)}
          />
        )}
        popupIcon={<SvgIconSearch />}
        {...props}
      />
    </div>
  );
}
