/*
 *
 * MyClassDetail reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  clients: [],
  classInfo: {},
};

function myClassDetailReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_MY_CLIENT:
      return { ...state };
    case ActionTypes.STORE_CLASS_INFO:
      return { ...state, classInfo: action.payload };
    case ActionTypes.STORE_CLIENTS:
      return { ...state, clients: action.payload };
    default:
      return state;
  }
}

export default myClassDetailReducer;
