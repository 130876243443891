/*
 *
 * Authentication actions
 *
 */

import { action } from 'typesafe-actions';
import ActionTypes from './constants';
import { Userdata, ResetPassword, UserInfo } from './types';

export const login = (userData: Userdata) => {
  return action(ActionTypes.USER_LOGIN, userData);
};
export const loginSuccess = data => action(ActionTypes.LOGIN_SUCCESS, data);
export const loginFailed = data => action(ActionTypes.LOGIN_FAILED, data);

export const reset = (email: string) => {
  return action(ActionTypes.USER_RESET, email);
};
export const resetSuccess = data => action(ActionTypes.RESET_SUCCESS, data);
export const resetFailed = data => action(ActionTypes.RESET_FAILED, data);

export const storeResetPassEmail = (email: string) => {
  return action(ActionTypes.STORE_RESET_EMAIL, email);
};

export const resetPwd = (resetPassword: ResetPassword) => {
  return action(ActionTypes.SET_NEW_PASSWORD, resetPassword);
};

export const isLoginFetching = isAuth => {
  return action(ActionTypes.IS_AUTH_MODEL, isAuth);
};

// USER
export const requestUserBy = () => action(ActionTypes.REDIRECT_USER);
export const requestUserInfo = () => action(ActionTypes.REQUEST_USER_INFO);
export const storeUserInfo = (userInfo: UserInfo) =>
  action(ActionTypes.STORE_USER_INFO, userInfo);
export const removeUserInfo = () => action(ActionTypes.REMOVE_USER_INFO);
