/*
 *
 * Achivement actions
 *
 */

import { action } from 'typesafe-actions';
import { AchievementList, ProgramChallenge, Genres } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

//Get ACHIEVEMENT list
export const requestAchievements = () => {
  return action(ActionTypes.REQUEST_ACHIEVEMENT_LIST);
};

export const setSearchKey = payload => {
  return action(ActionTypes.GET_SEARCH_RESULT, payload);
};

export const resetAchievement = () => {
  return action(ActionTypes.RESET_ACHIEVEMENT);
};
export const requestAchievementList = () => {
  return action(ActionTypes.REQUEST_ACHIEVEMENT_LIST);
};

export const achievementListSuccess = (achievementList: AchievementList) => {
  return action(ActionTypes.ACHIEVEMENT_LIST_SUCCESS, achievementList);
};

export const programChallengeSuccess = (programChallenge: ProgramChallenge) => {
  return action(ActionTypes.PROGRAM_CHALLENGE_LIST, programChallenge);
};

export const genresSuccess = (genres: Genres) => {
  return action(ActionTypes.GENRES_LIST, genres);
};

export const achievementDetailSuccess = result => {
  return action(ActionTypes.ACHIEVEMENT_DETAIL_SUCCESS, result);
};

export const setLoader = (isLoading: boolean) =>
  action(ActionTypes.ACHIEVEMENT_DETAILS_LOADING, isLoading);

export const achievementListFailed = () => {
  return action(ActionTypes.ACHIEVEMENT_LIST_FAILED);
};

export const serSortBy = sortBy => {
  return action(ActionTypes.SET_SORT_BY, sortBy);
};

export const setTabChange = tab => {
  return action(ActionTypes.SET_ACTIVE_TAB, tab);
};

export const getAchievementDetail = (id: string) => {
  return action(ActionTypes.FETCH_ACHIEVEMENT_BY_ID, id);
};

export const editAchievementDetailInfo = data => {
  return action(ActionTypes.EDIT_ACHIEVEMENT_DETAIL_INFO, data);
};

export const storeAchievementDetailInfo = achievementDetailInfo => {
  return action(ActionTypes.STORE_ACHIEVEMENT_DETAIL, achievementDetailInfo);
};

export const requestAchievementDetail = id => {
  return action(ActionTypes.REQUEST_ACHIEVEMENT_DETAIL, id);
};

export const updateAchievementInfo = updatedInfo => {
  return action(ActionTypes.ACHIEVEMENT_UPDATED_INFO, updatedInfo);
};

export const deleteAchievementInfo = data => {
  return action(ActionTypes.ACHIEVEMENT_DELETE_INFO, data);
};

export const deleteAchievementInfoSuccess = result =>
  action(ActionTypes.DELETE_ACHIEVEMENT_INFO_SUCCESS, result);

export const deleteAchievementInfoFailed = error =>
  action(ActionTypes.DELETE_ACHIEVEMENT_INFO_FAILED, error);

export const requestOtherDetails = id => {
  return action(ActionTypes.REQUEST_OTHER_DETAILS, id);
};

export const otherDetailSuccess = result => {
  return action(ActionTypes.OTHER_DETAIL_SUCCESS, result);
};

export const addAchievementDetails = value => {
  return action(ActionTypes.POST_ADD_ACHIEVEMENT, value);
};

export const achievementLoader = (isAchievementLoading: boolean) =>
  action(ActionTypes.ACHIEVEMENT_LOADING, isAchievementLoading);

export const achievementListCountSuccess = (total: number) => {
  return action(ActionTypes.ACHIEVEMENT_LIST_TOTAL, total);
};

export const achievementListPaginationSuccess = payload => {
  return action(ActionTypes.ACHIEVEMENT_LIST_PAGINATION_SUCCESS, payload);
};
export const requestAchievementListPagination = payload => {
  return action(ActionTypes.REQUEST_ACHIEVEMENT_LIST_PAGINATION, payload);
};
