/*
 *
 * Authentication constants
 *
 */

enum ActionTypes {
  USER_LOGIN = 'app/Authentication/USER_LOGIN',
  LOGIN_SUCCESS = 'app/Authentication/LOGIN_SUCCESS',
  LOGIN_FAILED = 'app/Authentication/LOGIN_FAILED',
  USER_RESET = 'USER_RESET',
  RESET_SUCCESS = 'RESET_SUCCESS',
  RESET_FAILED = 'RESET_FAILED',
  STORE_RESET_EMAIL = 'STORE_RESET_EMAIL',
  SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
  REQUEST_USER_INFO = 'REQUEST_USER_INFO',
  STORE_USER_INFO = 'STORE_USER_INFO',
  REMOVE_USER_INFO = 'REMOVE_USER_INFO',
  REDIRECT_USER = 'REDIRECT_USER',
  IS_AUTH_MODEL = 'IS_AUTH_MODEL',
}

export default ActionTypes;
