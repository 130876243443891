/*
 *
 * MyClassDetail constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/MyClassDetail/DEFAULT_ACTION',
  REQUEST_MY_CLIENT = 'REQUEST_MY_CLIENT',
  STORE_CLIENTS = 'STORE_CLIENTS',
  STORE_CLASS_INFO = 'STORE_CLASS_INFO',
}

export default ActionTypes;
