import React from 'react';
import { Grid } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import moment from 'moment';
import ConceptFallbackIcon from '../images/concept_fallback_icon.png';
import { IconButton, Avatar } from '@material-ui/core';

export function PinnedTray({
  classes,
  item,
  title,
  graphics,
  classCount,
  categories,
//   getCategories,
  subTitle,
}: {
  classes: Record<
    | 'lower'
    | 'topHead'
    | 'addBtn'
    | 'newComponent'
    | 'topWrapper'
    | 'buttonWrap'
    | 'subtitleLow'
    | 'subtitle1'
    | 'body2'
    | 'typeWrap'
    | 'text'
    | 'conceptTitle'
    | 'imageWrapper'
    | 'iconHolder'
    | 'iconSquare'
    | 'iconImg'
    | 'box',
    string
  >;
  item: any;
  title: string;
  graphics: any;
  classCount: any,
  subTitle: string,
  categories: any,
//   getCategories(categories);
}) {
  return (
    <Grid container>
      <div className={classes.topWrapper}>
        <div className={classes.buttonWrap}>
          <div className={classes.subtitleLow}>
              <div className={classes.imageWrapper}>
                <div className={classes.box}>
                  <Avatar
                    variant="square"
                    src={graphics}
                    classes={{
                    root: classes.iconHolder,
                    square: classes.iconSquare,
                    img: classes.iconImg,
                    }}
                  >
                  <img src={ConceptFallbackIcon} />
                  </Avatar>
                </div>
          </div>
          <div>
          <div className={classes.conceptTitle}>{title}</div>
          <div className={classes.text}>{categories}</div>
          <div className={classes.text}>{classCount}</div>
        </div>
          </div>
        </div>
      </div>
    </Grid>
    
  );

}
