/*
 *
 * Concept reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  conceptList: [],
  conceptPaginationList: [],
  sortBy: 'updatedAt=desc',
  activeTab: 'isPublished=true&isHidden=false',
  searchKey: '',
  isInteractiveFilterOn: false,
  isListFetchingError: false,
  isLoading: false,
  conceptDetailInfo: {
    isLoading: false,
    flag: [],
    instructors: [],
    genres: [],
    categories: [],
    data: {
      categories: [],
      collectionType: '',
      createdAt: new Date(),
      description: '',
      flag: '',
      gallery: [],
      genres: [],
      graphics: {
        '16x9': '',
        '16x9_trans': '',
        '1x1': '',
        '32x9': '',
        '9x16': '',
      },
      id: '',
      instructors: [],
      interactive: [],
      isHidden: false,
      isPublished: false,
      kicker: '',
      permaLink: '',
      publishedDate: new Date(),
      seriesID: '',
      title: '',
      updatedAt: new Date(),
      videos: {
        videoIds: [],
      },
    },
  },
  isConceptLoading: false,
  total: 0,
};

function conceptReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.REQUEST_CONCEPT_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.CONCEPT_LIST_SUCCESS:
      return { ...state, conceptList: [...action.payload], isLoading: false };
    case ActionTypes.CONCEPT_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        conceptPaginationList: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.PUT_CONCEPT_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        conceptList: [...state.conceptList, ...state.conceptPaginationList],
        isLoading: false,
        isConceptLoading: false,
      };
    case ActionTypes.CONCEPT_LIST_TOTAL:
      return {
        ...state,
        total: action.payload,
        isLoading: false,
      };
    case ActionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case ActionTypes.RESET_CONCEPT:
      return (state = initialState);
    case ActionTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case ActionTypes.GET_SEARCH_RESULT:
      return { ...state, searchKey: action.payload };
    case ActionTypes.CONCEPT_LIST_FAILED:
      return { ...state, isListFetchingError: true };
    case ActionTypes.STORE_CONCEPT_DETAIL:
      return { ...state, conceptDetailInfo: action.payload };
    case ActionTypes.SET_CONCEPT_LOADER:
      return { ...state, isLoading: action.payload };
    case ActionTypes.SET_CONCEPT_PAGINATION_LOADER:
      return { ...state, isConceptLoading: action.payload };
    case ActionTypes.SET_INTERACTIVE_CLASSES_ONLY:
      return { ...state, isInteractiveFilterOn: action.payload };

    case ActionTypes.ADD_CONCEPT:
      return { ...state, addConcept: action.payload };
    case ActionTypes.ADD_CONCEPT_DETAIL_INFO:
      return { ...state, conceptDetailInfo: action.payload };

    case ActionTypes.EDIT_CONCEPT_DETAIL_INFO:
      return { ...state, conceptDetailInfo: action.payload };

    case ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS:
      return { ...state, videoList: action.payload };

    case ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS:
      return { ...state, videoList: action.payload };

    // case ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID_FAILED:
    //   return { ...state, videoListerror: action.payload };

    default:
      return state;
  }
}

export default conceptReducer;
