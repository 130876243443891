/**
 *
 * VoiceBrandPage
 *
 */
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PostWatchComponent from './PostWatchComponent';
import moment from 'moment';

// import styled from 'styles/styled-components';

import { FormattedMessage } from 'react-intl';

interface Props {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      padding: '20px',
      borderBottom: '1px solid #E3E3E4',
    },
    topHead: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '28px',
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    subtitleWrap: {
      height: '120px',
      borderRadius: '6px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      alignItems: 'center',
      marginBottom: '12px',
    },
    topWrapper: {
      width: '100%',
      padding: '38px 20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    subtitle: {
      width: '300px',
      fontFamily: 'Proxima-Nova-Regular',
      paddingTop: '8px',
    },
    subtitle1: {
      color: '#12161A',
      fontSize: '17px',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '24px',
    },
    body2: {
      color: '#000000',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      fontFamily: 'ProximaNova-Medium',
    },
    buttonWrap: {
      display: 'flex',
    },
    typeWrap: {
      width: '240px',
      color: '#909599',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '20px',
      paddingTop: '9px',
      float: 'left',
    },
  }),
);

function PostWatchSection(props) {
  const classes = useStyles();
  let {
    list: { achievementsTxt, achievementsUpdatedDate },
  } = props;
  return (
    <Grid className={classes.top}>
      <div className={classes.topHead}>POST WATCH</div>
      <TraySection
        classes={classes}
        updatedAt={achievementsUpdatedDate}
        achievementsTxt={achievementsTxt}
        handleManage={() => {}}
      />
    </Grid>
  );
}
//function HandleManage();
function TraySection({
  classes,
  handleManage,
  achievementsTxt = null,
  updatedAt,
}: {
  classes: Record<
    | 'top'
    | 'topHead'
    | 'subtitleWrap'
    | 'topWrapper'
    | 'subtitle'
    | 'subtitle1'
    | 'body2'
    | 'buttonWrap'
    | 'typeWrap',
    string
  >;
  item: any;
  updatedAt: Date;
  achievementsTxt: [];
  handleManage();
}) {
  return (
    <Grid container className={classes.subtitleWrap}>
      <div className={classes.topWrapper}>
        <div className={classes.subtitle}>
          <div className={classes.subtitle1}>Achievement Messaging</div>
        </div>
        <div className={classes.buttonWrap}>
          <div className={classes.typeWrap}>
            <div>Last Updated: {moment(updatedAt).format(`L`)}</div>
          </div>
          <PostWatchComponent
            onClick={handleManage}
            title="ACHIEVEMENT MESSAGING"
            achievementsTxt={achievementsTxt}
          />
        </div>
      </div>
    </Grid>
  );
}

export default PostWatchSection;
