/*
 *
 * Live constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/Video/DEFAULT_ACTION',
  SET_INTERACTIVE_ONLY = 'SET_INTERACTIVE_ONLY',
  TOGGLE_EXPLICIT_LANG = 'TOGGLE_EXPLICIT_LANG',
  REQUEST_PAGINATION_LIVE_VIDEO = 'REQUEST_PAGINATION_LIVE_VIDEO',
  VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS',
  VIDEO_LIST_PAGINATION_SUCCESS = 'VIDEO_LIST_PAGINATION_SUCCESS',
  VIDEO_LIST_TOTAL = 'VIDEO_LIST_TOTAL',
  VIDEO_LIST_FAILED = 'VIDEO_LIST_FAILED',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  REQUEST_LIVE_FILTER_PARAMS = 'REQUEST_LIVE_FILTER_PARAMS',
  FILTERS_FETCH_SUCCESS = 'FILTERS_FETCH_SUCCESS',
  FILTERS_FETCH_FAILED = 'FILTERS_FETCH_FAILED',
  UPDATE_FILTERS_SUCCESS = 'UPDATE_FILTERS_SUCCESS',
  LIVE_SEARCH_SUGGESTIONS = 'LIVE_SEARCH_SUGGESTIONS',
  SEARCH_SUGGESTION_FETCH_SUCCESS = 'SEARCH_SUGGESTION_FETCH_SUCCESS',
  REQUEST_lIVE_VIDEO_LIST_WITH_FILTERS = 'REQUEST_lIVE_VIDEO_LIST_WITH_FILTERS',
  VIDEO_LIST_SUCCESS_WITH_FILTERS = 'VIDEO_LIST_SUCCESS_WITH_FILTERS',
  VIDEO_LIST_FAILED_WITH_FILTERS = 'VIDEO_LIST_FAILED_WITH_FILTERS',
  VIDEO_LIVE_LIST_WITH_FILTERS_PAGINATION = 'VIDEO_LIVE_LIST_WITH_FILTERS_PAGINATION',
  VIDEO_LIST_SUCCESS_WITH_FILTERS_PAGINATION = 'VIDEO_LIST_SUCCESS_WITH_FILTERS_PAGINATION',
  UPDATE_VIDEO_LIST = 'UPDATE_VIDEO_LIST',
  UPDATE_SEARCH_COUNT = 'UPDATE_SEARCH_COUNT',
  LIVE_RE_UPDATE_LIST = 'LIVE_RE_UPDATE_LIST',
  GET_DURATION_DETAILS = 'GET_DURATION_DETAILS',
  DURATION_DETAILS_SUCCESS = 'DURATION_DETAILS_SUCCESS',
  DURATION_DETAILS_FAILED = 'DURATION_DETAILS_FAILED',
  ADD_LIVE_VIDEO_EVENT = 'ADD_LIVE_VIDEO_EVENT',
  ADD_VIDEO_EVENT = 'ADD_VIDEO_EVENT',
  RE_ENCODE_VIDEO = 'RE_ENCODE_VIDEO',
  ADD_MULTIPLE_AUDIO_TRACKS = 'ADD_MULTIPLE_AUDIO_TRACKS',
  CLOSE_ADD_VIDEO_DIALOG = 'CLOSE_ADD_VIDEO_DIALOG',
  FILTER_UPDATE_VIDEO_LIST = 'FILTER_UPDATE_VIDEO_LIST',
  SET_VIDEO_LOADER = 'SET_VIDEO_LOADER',
  SET_LIVE_SEARCH_VIDEO_LOADER = 'SET_LIVE_SEARCH_VIDEO_LOADER',
  GET_LIVE_VIDEOS = 'GET_LIVE_VIDEOS',
}

export default ActionTypes;
