/*
 *
 * InteractiveCoupon reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  isLoading: false,
  couponList: [],
  packagesList: [],
  searchKey: '',
  total: 0,
  isCouponLoading: false,
  isListFetchingError: false,
  couponPaginationList: [],
};

function interactiveCouponReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_PROMO:
      return { ...state };
    case ActionTypes.REQUEST_PACKAGES:
      return { ...state };
    case ActionTypes.GET_SEARCH_RESULT:
      return { ...state, searchKey: action.payload };
    case ActionTypes.COUPON_LIST_SUCCESS:
      return {
        ...state,
        couponList: action.payload,
        isLoading: false,
      };
    case ActionTypes.COUPON_LIST_TOTAL:
      return {
        ...state,
        total: action.payload,
        isLoading: false,
      };
    case ActionTypes.COUPON_LIST_FAILED:
      return { ...state, isListFetchingError: true };
    case ActionTypes.COUPON_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        couponPaginationList: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.PUT_COUPON_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        couponList: [...state.couponList, ...state.couponPaginationList],
        isLoading: false,
        isCouponLoading: false,
      };
    case ActionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case ActionTypes.STORE_COUPON:
      return { ...state, couponList: action.payload };
    case ActionTypes.STORE_PACKAGES:
      return { ...state, packagesList: action.payload };
    case ActionTypes.SET_COUPON_PAGINATION_LOADER:
      return { ...state, isCouponLoading: action.payload };
    default:
      return state;
  }
}

export default interactiveCouponReducer;
