/*
 *
 * SignOutHome actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const requestSignOutPage = () =>
  action(ActionTypes.REQUEST_SIGN_OUT_HOME);
export const editSignOut = concept =>
  action(ActionTypes.EDIT_SIGN_OUT, concept);
export const storeSignOutInfo = data =>
  action(ActionTypes.STORE_SIGN_OUT_INFO, data);
export const setLoader = isLoading => action(ActionTypes.SET_LOADER, isLoading);
