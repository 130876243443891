/*
 *
 * MyClassDetail actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const storeClients = clients => {
  return action(ActionTypes.STORE_CLIENTS, clients);
};

export const storeClassInfo = classInfo => {
  return action(ActionTypes.STORE_CLASS_INFO, classInfo);
};

export const requestClientList = videoId => {
  return action(ActionTypes.REQUEST_MY_CLIENT, videoId);
};
