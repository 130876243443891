/*
 *
 * InstructorAddClasses
 *
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@material-ui/core';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectInstructorAddClasses from './selectors';
import reducer from './reducer';
import saga from './saga';
import ClassListTable from './ClassTable/ClassTable';
import Loader from '../../components/Loader';
import { useStyles } from './styles';
import SortSelect from './Select';
import StyledDialog from '../../components/StyledDialog';
import { setTabChange, requestInteractiveVideoList } from './actions';
import makeSelectAuthentication from '../Authentication/selectors';
import AddClasses from './Components/AddClasses';
import { SvgIconAdd } from '../../components/NeIcons';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';

const stateSelector = createStructuredSelector({
  instructorAddClasses: makeSelectInstructorAddClasses(),
});

const stateSelector1 = createStructuredSelector({
  authentication: makeSelectAuthentication(),
});

interface Props {
  classes: StyleType;
}

type StyleType = Record<
  | 'root'
  | 'head'
  | 'upperHeaderSection'
  | 'lowerHeaderSection'
  | 'headWrap'
  | 'addVideoBtn'
  | 'newVideo'
  | 'searchIcon'
  | 'styledTab'
  | 'alertMsg'
  | 'noData'
  | 'divider'
  | 'filterPanel'
  | 'footer'
  | 'title__heading'
  | 'searchHeader'
  | 'searchBar'
  | 'startOver'
  | 'selectedContainer'
  | 'selectedcontained'
  | 'chipBoxes'
  | 'addFilter'
  | 'sortItem'
  | 'dividers'
  | 'liveEvent'
  | 'loadMoreBtn',
  string
>;

function InstructorAddClasses(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'instructorAddClasses', reducer });
  useInjectSaga({ key: 'instructorAddClasses', saga });

  const { instructorAddClasses } = useSelector(stateSelector);
  const { authentication } = useSelector(stateSelector1);
  const { videoList, isListFetchingError, isLoading } = instructorAddClasses;
  const { interactive } = authentication.userInfo;

  const dispatch = useDispatch();
  const classes: StyleType = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const [addClassOpen, setAddClassData] = React.useState({
    isOpen: false,
  });

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    if (tabIndex === 0) {
      dispatch(
        setTabChange(
          'isPublished=false&isHidden=false&inreview=false&flagged=false&isCancelled=false',
        ),
      );
      dispatch(requestInteractiveVideoList(interactive.instrId));
    } else if (tabIndex === 1) {
      dispatch(setTabChange('isPublished=false&inreview=true'));
      dispatch(requestInteractiveVideoList(interactive.instrId));
    } else if (tabIndex === 2) {
      dispatch(setTabChange('isPublished=false&flagged=true'));
      dispatch(requestInteractiveVideoList(interactive.instrId));
    }
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (activeTab === 0) {
      dispatch(
        setTabChange(
          'isPublished=false&isHidden=false&inreview=false&flagged=false&isCancelled=false',
        ),
      );
    }
    if (interactive && interactive.instrId) {
      dispatch(requestInteractiveVideoList(interactive.instrId));
    }
  }, []);

  const tabConfig = [
    {
      label: 'DRAFTS',
      component: <ClassListTable />,
    },
    {
      label: 'SUBMITTED',
      component: <ClassListTable />,
    },
    {
      label: 'FLAGGED',
      component: <ClassListTable />,
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      {addClassOpen.isOpen && (
        <StyledDialog
          isOpen={addClassOpen.isOpen || false}
          title="Add New Class"
          handleClose={() => {
            setAddClassData({ isOpen: false });
          }}
        >
          <AddClasses
            instrId={interactive.instrId}
            onClearEvent={() => {
              setAddClassData({ isOpen: false });
            }}
          />
        </StyledDialog>
      )}

      <div className={classes.root}>
        <div className={classes.headWrap}>
          <div className={classes.upperHeaderSection}>
            <SortSelect />
            <div className={classes.head}>Add Classes</div>
            <Button
              onClick={() => {
                setAddClassData({ isOpen: true });
              }}
              variant="contained"
              color="secondary"
              className={classes.addVideoBtn}
              disableRipple
            >
              <SvgIconAdd /> <div className={classes.newVideo}>Add a Class</div>
            </Button>
          </div>
        </div>
        <StyledTabs
          value={activeTab}
          onChange={onTabChange}
          aria-label="My account tabs"
          centered
          className={classes.styledTab}
        >
          {tabConfig.map((t, index) => (
            <StyledTab key={index} label={t.label} />
          ))}
        </StyledTabs>
        <div className={classes.alertMsg}>
          {activeTab === 0
            ? 'New classes must be submitted on Friday by 12 pm EST.'
            : activeTab === 1
            ? 'Your Submitted classes are in review. Reviewer will flag classes that need corrections.'
            : 'Reviewer will contact you by email or phone to resolve issues with your submission.'}
        </div>
        {isListFetchingError ||
        !videoList ||
        (videoList && videoList.length === 0) ? (
          <div className={classes.noData}>No Data found</div>
        ) : (
          <ClassListTable list={videoList} isLoading={isLoading} />
        )}
      </div>
    </>
  );
}

export default InstructorAddClasses;
