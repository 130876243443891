import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
    '& .MuiTableRow-root': {
      'td.MuiTableCell-root.MuiTableCell-body:nth-child(1)': {
        flex: '1',
        width: '40%',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(2)': {
        width: '34%',
        minWidth: '224px',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(3)': {
        minWidth: '200px',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(4)': {
        width: '10%',
      },
    },
  },
  container: {
    maxHeight: '100%',
  },
  box: {
    height: '64px',
    width: '64px',
    borderRadius: '6px',
    backgroundColor: '#D4D8DC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '18px',
      height: '24px',
    },
  },
  saveLegalBtn: {
    padding: '0px',
    height: '40px',
    width: '220px',
    borderRadius: '6px',
    backgroundColor: '#12161A',
  },
  newLegal: {
    paddingTop: '10px',
  },
  text: {
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '20px',
    color: '#909599',
    float: 'left',
    width: '63%',
  },
  legalTxt: {
    overflow: 'scroll !important',
    width: '100%',
    maxHeight: '330px',
    outline: 'none',
    padding: '10px',
    resize: 'none',
  },
});
