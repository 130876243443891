/*
 *
 * CurateGenre
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectCurateGenre from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  Box,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Drawer,
} from '@material-ui/core';
import { SvgIconAdd } from '../../components/NeIcons';
import { requestBrowseGenreList, setLoader, reorderTrays } from './actions';
import LowerSectionTrayList from '../../components/CurrateTopSection/LowerSection';
import { DrawerView } from '../HomeCurate/components/DrawerView';
import ConfirmDialog from '../../components/ConfirmDialog';
import { ComponentEditor } from './GenreEditor';
import request from '../../shared/lib/request';
import { openSnackBar } from '../Root/actions';
import Loader from '../../components/Loader';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0px 0px 16px',
      fontFamily: 'Proxima-Nova-Regular',
      maxWidth: '1440px',
      margin: 'auto',
    },
    head: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    headWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '18px 0px',
      borderBottom: '1px solid #000000',
    },
    addBtn: {
      padding: '0px',
      height: '40px',
      width: '162px',
      borderRadius: '6px',
      backgroundColor: '#12161A',
    },
    ctaWrap: {
      display: 'flex',
      width: '100%',
      padding: '20px',
    },
    topHead: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '18px',
      fontWeight: 'bold',
      padding: '10px 0px',
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  }),
);

const stateSelector = createStructuredSelector({
  curateGenre: makeSelectCurateGenre(),
});

interface Props {}

const initialFormValue = {
  title: '',
  genres: [],
  imageURL: '',
  order: 1,
  isPublished: false,
};

export enum FormType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

function CurateGenre(props: Props) {
  useInjectReducer({ key: 'curateGenre', reducer: reducer });
  useInjectSaga({ key: 'curateGenre', saga: saga });
  const classes = useStyles();
  const { curateGenre } = useSelector(stateSelector);
  const { browseGenreList = [], isLoading = false } = curateGenre;
  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);

  const [componentFormInfo, setComponentFormInfo] = React.useState({
    isOpen: false,
    type: FormType.ADD,
    item: { ...initialFormValue },
    isRemovable: false,
  });

  useEffect(() => {
    dispatch(requestBrowseGenreList());
  }, []);

  const hanldeLowerSectionTrayManage = item => {
    let formValue = { ...item };
    setComponentFormInfo({
      isOpen: true,
      item: { ...initialFormValue, ...formValue },
      type: FormType.EDIT,
      isRemovable: true,
    });
  };

  const handleLowerSectionListChange = list => {
    dispatch(reorderTrays(list));
  };

  const formData = (formValues, type) => {
    let data = [];
    if (formValues.genres && formValues.genres.length) {
      data = formValues.genres.map(i => i.id);
    }
    let requestBody = {
      ...formValues,
      updatedAt: new Date().getTime(),
      title: formValues.title,
      isPublished: formValues.isPublished ? formValues.isPublished : false,
      imageURL: formValues.imageURL ? formValues.imageURL : '',
      genres: data,
      order: type === 'ADD' ? 0 : formValues.order,
    };
    return requestBody;
  };
  const handleEditFormSubmit = async (formValues, type) => {
    let componentInfo = formData(formValues, type);
    try {
      dispatch(setLoader(true));
      await request({
        url: '/neouadmin/v1/browse-genre',
        method: type === 'ADD' ? 'POST' : 'PUT',
        data: componentInfo,
      });
      dispatch(requestBrowseGenreList());
      dispatch(openSnackBar('Component has been updated successfully!'));
      setComponentFormInfo({
        isOpen: false,
        type: FormType.ADD,
        item: { ...initialFormValue },
        isRemovable: false,
      });
      dispatch(setLoader(false));
      if (type === 'ADD') {
        let { result } = await request({
          url: `neouadmin/v1/browse-genre`,
          method: 'GET',
        });
        setTimeout(() => {
          dispatch(reorderTrays(result));
        }, 5000);
      }
    } catch (error) {
      dispatch(setLoader(false));
      if (
        error.data &&
        error.data.error &&
        typeof error.data.error === 'string' &&
        error.data.error.includes('duplicate')
      ) {
        dispatch(openSnackBar('Duplicate Title!'));
      } else {
        dispatch(openSnackBar('Component failed to save!'));
      }
    }
  };

  const handleEditDrawerClose = () =>
    setComponentFormInfo({ isOpen: false, item: { ...initialFormValue } });

  return (
    <div>
      <Helmet>
        <title>Curate Browse Genre</title>
        <meta name="description" content="Description of CurateGenre" />
      </Helmet>
      {isLoading && <Loader />}
      <Box p={2}>
        <div className={classes.root}>
          <div className={classes.headWrap}>
            <div className={classes.head}>Curate: Browse Genre</div>
          </div>

          <div className={classes.ctaWrap}>
            <div className={classes.topHead}></div>
            <Button
              variant="contained"
              color="secondary"
              className={classes.addBtn}
              disableRipple={true}
              startIcon={<SvgIconAdd />}
              onClick={() => {
                setComponentFormInfo({
                  isOpen: true,
                  type: FormType.ADD,
                  item: { ...initialFormValue },
                  isRemovable: true,
                });
              }}
            >
              GENRE
            </Button>
          </div>
          <LowerSectionTrayList
            lowerSection={browseGenreList}
            handleComponentManage={hanldeLowerSectionTrayManage}
            handleListChanged={handleLowerSectionListChange}
            showStatus={true}
          />
          <Drawer
            anchor={'right'}
            open={componentFormInfo.isOpen}
            disableBackdropClick={true}
            onClose={() => showAlertToClose(true)}
          >
            <ConfirmDialog
              isOpen={isCloseAlertOpen}
              headerText="Are you sure?"
              bodyText="You are about to close the component."
              onAgree={() => handleEditDrawerClose()}
              handleClose={() => showAlertToClose(false)}
              onDisagree={() => showAlertToClose(false)}
            ></ConfirmDialog>

            <DrawerView
              handleClose={showAlertToClose}
              type={componentFormInfo.type}
              removable={componentFormInfo.type === FormType.EDIT}
              title={`${componentFormInfo.type} GENRE COMPONENT`}
            ></DrawerView>
            <ComponentEditor
              componentFormInfo={componentFormInfo}
              handleEditFormSubmit={data =>
                handleEditFormSubmit(data, componentFormInfo.type)
              }
              onDrawerClose={() => handleEditDrawerClose()}
            />
          </Drawer>
        </div>
      </Box>
    </div>
  );
}

export default CurateGenre;
