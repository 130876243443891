/*
 *
 * Authentication reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  resetEmail: '',
  isUserInfoFetching: false,
  isAuth: false,
  userInfo: {
    email: '',
    id: '',
    createdAt: null,
    loggedInDevices: [],
    permissions: [],
    registeredDate: null,
    registeredFrom: null,
    role: '',
    userStatus: '',
  },
};

function reducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      return state;
    case ActionTypes.STORE_RESET_EMAIL:
      return { ...state, resetEmail: action.payload };
    case ActionTypes.REQUEST_USER_INFO:
      return { ...state, isUserInfoFetching: true };
    case ActionTypes.IS_AUTH_MODEL:
      return { ...state, isAuth: action.payload };
    case ActionTypes.STORE_USER_INFO:
      return { ...state, userInfo: action.payload, isUserInfoFetching: false };
    case ActionTypes.REMOVE_USER_INFO:
      return { ...initialState };
    default:
      return state;
  }
}

export default reducer;
