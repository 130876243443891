/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Import all the third party stuff
import * as React from 'react';
// import * as ReactDOM from 'react-dom';
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import FontFaceObserver from 'fontfaceobserver';
import history from './utils/history';
import 'sanitize.css/sanitize.css';

// Import Language Provider
import LanguageProvider from './containers/LanguageProvider';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess';

import configureStore from './configureStore';
import themeOptions from './theme/customTheme';

import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import HttpsRedirect from 'react-https-redirect';

const engine = new Styletron();

// Import i18n messages
import { translationMessages } from 'i18n';
import { createTheme, Theme, ThemeProvider } from '@material-ui/core';
import Root from './containers/Root';
import { configSegment } from './setup/segment';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
// const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
// openSansObserver.load().then(() => {
//   document.body.classList.add('fontLoaded');
// });

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app') as HTMLElement;
const theme: Theme = createTheme(themeOptions);

const ConnectedApp = ({ messages, Component = Root }) => {
  React.useEffect(() => {
    configSegment();
  }, []);

  return (
    // tslint:disable-next-line:jsx-wrap-multiline
    <>
      <HttpsRedirect>
        <Provider store={store}>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <ThemeProvider theme={theme}>
                <StyletronProvider value={engine}>
                  <BaseProvider theme={LightTheme}>
                    <Component />
                  </BaseProvider>
                </StyletronProvider>
              </ThemeProvider>
            </ConnectedRouter>
          </LanguageProvider>
        </Provider>
      </HttpsRedirect>
    </>
  );
};

const render = (messages: any, Component) => {
  ReactDOM.render(<ConnectedApp messages Component={Component} />, MOUNT_NODE);
  // ReactDOM.createRoot(<ConnectedApp messages Component={Component} />, MOUNT_NODE);

};

// if (module.hot) {
//   module.hot.accept(['./i18n', './containers/Root'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./containers/Root').default; 
    render(translationMessages, App);
//   });
// }
// Chunked polyfill for browsers without Intl support
// if (!(window as any).Intl) {
//   new Promise(resolve => {
//     resolve(import('intl'));
//   })
//     .then(() =>
//       Promise.all([
//         import('intl/locale-data/jsonp/en.js'),
//         import('intl/locale-data/jsonp/de.js'),
//       ]),
//     )
//     .then(() => render(translationMessages))
//     .catch(err => {
//       throw err;
//     });
// } else {
//   render(translationMessages);
// }

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install();
// }
