/*
 *
 * Members constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/Members/DEFAULT_ACTION',

  PASSWORD_RESET = 'PASSWORD_RESET',
  ACTION_FAILED = 'ACTION_FAILED',
  REVOKE_ACCESS = 'REVOKE_ACCESS',
  MEMBER_LOADER = 'MEMBER_LOADER',
  SWITCH_EMAILLOGIN = 'SWITCH_EMAILLOGIN',
  SUBSCRIPTION_PLANS = 'SUBSCRIPTION_PLANS',
}

export default ActionTypes;
