import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the Legal state domain
 */

const selectLegalDomain = (state: ApplicationRootState) => {
  return state.legal || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Legal
 */

const makeSelectLegal = () =>
  createSelector(
    selectLegalDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectLegal;
export { selectLegalDomain };
