import * as React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import styled from 'styled-components';
import logo from '../../images/neou@2x.png';
import { login, reset, resetPwd } from './action';
import { encrypt } from '../../utils/crypto';
import { KEY_BASE64 } from '../../utils/config';
import PasswordField from '../../components/PasswordField';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useStyles } from './resetPasswordStyle';
import { createStructuredSelector } from 'reselect';
import makeSelectAuthentication from './selectors';

import reducer from './reducer';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import { passwordSchema } from '../../validators/Authentication';
import { customMessage, formError } from '../../utils/validator';
import { push } from 'connected-react-router';

export interface INewPasswordProps {}

const Image = styled.img`
  color: #ffffff;
`;

const stateSelector = createStructuredSelector({
  authenticationState: makeSelectAuthentication,
});

export default function NewPassword(props: INewPasswordProps) {
  const { authenticationState } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState({ email: '', password: '' });

  useInjectReducer({ key: 'authentication', reducer: reducer });
  useInjectSaga({ key: 'authentication', saga: saga });

  const handlePassword = e => {
    setPassword(e.target.value);
  };

  const validateForm = () => {
    const { error, value } = passwordSchema.validate(
      { password },
      {
        abortEarly: false,
        messages: customMessage,
      },
    );
    return formError(error);
  };

  const onResetPassword = () => {
    let e = validateForm();
    setError(e);

    let code = window.location.href.replace(
      window.location.origin + '/new-password?code=',
      '',
    );
    let data = {
      code: code,
      newPwd: encrypt(password, KEY_BASE64),
    };
    if (Object.keys(e).length === 0) {
      dispatch(resetPwd(data));
      //TODO once reset password email started working need to change this line
      // dispatch(push(`/neou`));
    }
  };

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={4} className={classes.root}>
          <div className={classes.imgWrapper}>
            <Image src={logo} className={classes.img} />
            <div className={classes.admin}>Admin</div>
          </div>
          <div className={classes.description}>Reset Password</div>
          <PasswordField
            className={classes.passwordField}
            variant="outlined"
            password={password}
            setPassword={e => handlePassword(e)}
            errorMsg={error['password']}
          />
          <Button
            color="primary"
            className={classes.signinBtn}
            onClick={() => {
              onResetPassword();
            }}
          >
            SAVE & RETURN TO SIGN IN
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
