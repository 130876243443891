import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// import other assets
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { push } from 'connected-react-router';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { StyledSpinnerNext } from 'baseui/spinner';
import iconU from '../../../images/icon-U.svg';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import { genres } from './stub';
import Autocomplete from '../../../components/Autocomplete';
import { useStyles } from '../styles';
import SelectMenu from './SelectMenu';
import {
  requestOtherDetails,
  requestPeopleDetail,
  updatePeopleInfo,
  requestPeopleList,
  addInstructorDetails,
} from '../actions';
import makeSelectPeople from '../selectors';
import GalleryDialog from '../../../components/Gallery/Gallery';
import { openAlertModal, openSnackBar } from '../../Root/actions';
import { addPhoto, deletePhoto, addVideo } from '../../../services/s3';
import Loader from '../../../components/Loader';
import {
  cloudfrontUrl,
  BUCKET_NAME,
  BUCKET_REGION,
} from '../../../utils/config';

import {
  HistoryText,
  StatusLabel,
  StatusText,
  LabelText,
} from '../../../components/Typography';
import ConfirmDialog from '../../../components/ConfirmDialog';
import ImageUploader from '../../../components/ImageUploader';
import AddIcon from '@material-ui/icons/Add';
import NEAutocomplete from '../../../components/NEAutocomplete';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#000',
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <img src={iconU} className={classes.iconU} />
      <Typography variant="h6">{children}</Typography>
      <div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dividers: {
    borderTop: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(MuiDialogActions);

const useStylesLocal = makeStyles((theme: Theme) =>
  createStyles({
    tag: {
      background: '#3C4042',
      color: '#fff',
      height: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    deleteIcon: {
      color: '#fff',
      width: '18px',
      opacity: 1,
      '&:hover': {
        color: '#fff',
        opacity: '0.5',
      },
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    dialogContainer: {
      borderRadius: 0,
      minWidth: '1032px',
    },
    spanHeader: {
      fontSize: '16px',
      fontFamily: 'ProximaNova-Bold',
      color: '#060B0F',
    },
    inputWrap: {
      '& .MuiInputBase-root': {
        '& .MuiInputBase-input': {
          color: '#060B0F !important',
          fontFamily: 'Proxima-Nova-Regular',
          fontSize: '16px',
          fontWeight: 500,
        },
      },
      '& .MuiOutlinedInput-inputMultiline': {
        padding: '0px',
      },
      '& .MuiAvatar-colorDefault': {
        backgroundColor: '#D4D8DC',
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'Proxima-Nova-Regular',
      },
    },
    imageBox: {
      marginBottom: '20px',
      height: '256px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: '#12161A',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '17px',
    },
    galleryDrop: {
      height: '142px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '180px',
      height: '180px',
      borderRadius: '50%',
      textAlign: 'center',
      color: 'white',
    },
    uploadBtn: {
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      textAlign: 'center',
      backgroundColor: '#3c4042',
      textTransform: 'capitalize',
      borderRadius: '20px',
      padding: '0px 10px',
      cursor: 'pointer',
    },
    flag: {
      marginBottom: '20px',
    },
    subTitle: {
      color: '#060B0F',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    subInTitle: {
      color: '#000000',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      lineHeight: '20px',
    },
    marginTitle: {
      marginTop: '20px',
    },
    inputHidden: {
      display: 'none',
    },
    manageGallery: {
      textTransform: 'capitalize',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: '#12161A',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 22px',
      position: 'relative',
      bottom: '103px',
    },
    galleryDiv: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    hiddenStatus: {
      color: '#000000',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      lineHeight: '20px',
    },
    spanError: {
      color: '#FF1C58',
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    galleryError: {
      marginRight: '80px',
      color: '#FF1C58',
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    preViewSaveBtn: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'flex-end',
      width: '100%',
    },
  }),
);

const stateSelector = createStructuredSelector({
  people: makeSelectPeople(),
});

export default function EditPersonDialog({ isOpen, handleClose, title, id }) {
  const classes = useStylesLocal();
  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  // get data from redux state
  const { people } = useSelector(stateSelector);
  const { aSpecialty, aFlag } = people;
  const [errImg, setErrImg] = useState(false);

  const { instructorDetail, isInstructorLoading } = people;
  const {
    firstName = '',
    lastName = '',
    description = '',
    gallery = [],
    adminTags,
    imageUrl,
    publishedDate,
    specialty,
    associatedConcepts,
    associatedCollections,
    createdAt,
    updatedAt,
    isHidden,
    isPublished,
  } = instructorDetail || {};

  let galleryUrl = gallery.filter(i => i.type === 'image').slice(0);
  const [isGalleryDialogOpen, openGalleryDialog] = React.useState(false);
  const [imgObj, setImgObj] = useState({});
  const [delData, setDelData] = useState({ key: '', isTrue: Boolean });
  // all local states
  const [values, setValues] = useState({});
  const [img, setImg] = useState('');
  const [banImg, setBanImg] = useState({});
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  const [isConfirmPublishOpen, openConfirmPublishDialog] = React.useState(
    false,
  );

  useEffect(() => {
    setValues({ ...instructorDetail });
  }, [instructorDetail]);
  useEffect(() => {
    if (isOpen) {
      dispatch(requestOtherDetails(true));
      dispatch(requestPeopleDetail(id));
      // resetting local state
      setErrImg(false);
      setImg('');
      setError({});
      setValues({});
      setError('');
    }
  }, [isOpen]);

  // handle input values
  const handleInput = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // profile image upload - s3
  const getBaseUrl = async e => {
    setLoader(true);
    const imgUrl = URL.createObjectURL(e.target.files[0]);

    // s3 add function
    const result = await addPhoto('person', e.target.files);

    const image = {
      imageUrl: result.Location.replace(
        `https://${BUCKET_NAME}.s3.${BUCKET_REGION}.amazonaws.com`,
        cloudfrontUrl,
      ),
    };
    setErrImg(true);
    setImg(image.imageUrl);
    setValues({ ...values, imageUrl: image.imageUrl });
    setLoader(false);
  };

  const handleOk = () => {
    const updateInfo = {
      instrId: instructorDetail.id,
      isHidden: true,
      isPublished: false,
      type: 'person',
    };
    dispatch(updatePeopleInfo({ updateInfo: updateInfo, isPublish: false }));
    dispatch(requestPeopleList());
    openConfirmDialog(false);
  };

  //save & publish handle
  const handleFormSubmit = btnVal => {
    if (isPublished) {
      openConfirmPublishDialog(true);
    } else {
      saveInstructor({
        btnVal,
        isPublish: btnVal === 'publish' ? true : false,
      });
    }
  };

  // handle save & publish with validations
  const saveInstructor = ({ btnVal, isPublish = false }) => {
    const firstNameErr = document.getElementById('firstName').value;
    const descriptionErr = document.getElementById('description').value;
    const imageErr = errImg || imageUrl;
    const galleryErr =
      (galleryUrl && galleryUrl.length > 0) || (imgObj && imgObj.length > 0);
    const formError = validateFields({
      firstNameErr,
      descriptionErr,
      imageErr,
      galleryErr,
    });
    if (!firstNameErr || !descriptionErr || !imageErr || !galleryErr) {
      setError(formError);
      return null;
    } else {
      setError('');
      const finalData = {
        ...instructorDetail,
        ...values,
        specialty:
          specialty && specialty.length > 0
            ? specialty
                .map(i => i.id)
                .filter(s => s !== null && s !== undefined)
            : [],
        lastName: values.lastName ? values.lastName : '',
        isPublished: btnVal === 'submit' ? isPublished : true,
        isHidden: btnVal === 'submit' ? isHidden : false,
        type: 'person',
        instrId: id,
      };
      dispatch(updatePeopleInfo({ updateInfo: finalData, isPublish }));
      dispatch(requestPeopleList());
      handleClose();
    }
  };

  //for delete functionality -s3
  const isDelete = (delImageKey, fromDelete) => {
    if (fromDelete) {
      let delValues = {
        key: delImageKey,
        isTrue: fromDelete,
      };
      setDelData(delValues);
      let delResult = deletePhoto('people', delImageKey);
    }
  };

  const handleSaveBtn = () => {
    openGalleryDialog(false);
    setValues({
      ...values,
      gallery: imgObj,
      instrId: id,
    });
    dispatch(openSnackBar('Person gallery added/deleted, save form to update'));
  };

  const galleryData = images => {
    let finalData: any;
    finalData = {
      gallery: images,
      instrId: id,
      isPublished: false,
      isHidden: true,
    };
    setImgObj(images);
  };

  if (isInstructorLoading) return null;
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
        >
          {title}
          <div></div>
        </DialogTitle>
        <DialogContent dividers>
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the person profile."
            onAgree={() => handleClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box component="span" className={classes.spanHeader}>
                Profile Image*
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box component="span" className={classes.spanHeader}>
                Profile Information
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className={classes.imageBox}>
                <div className={classes.image}>
                  {loader ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        top: '40%',
                      }}
                    >
                      <StyledSpinnerNext style={{ borderTopColor: 'black' }} />
                    </div>
                  ) : (
                    <Avatar
                      alt="img"
                      src={img || imageUrl}
                      className={classes.image}
                    ></Avatar>
                  )}
                </div>
                {loader ? (
                  ''
                ) : (
                  <label className={classes.uploadBtn}>
                    <input
                      accept="image/*"
                      className={classes.inputHidden}
                      id="icon-button-file"
                      type="file"
                      onChange={e => getBaseUrl(e)}
                    />
                    Update Image
                  </label>
                )}
              </div>
              {error && error.imageUrl ? (
                <div>
                  <Box component="span" className={classes.spanError}>
                    {error.imageUrl}
                  </Box>
                </div>
              ) : (
                ''
              )}

              <Box component="span" className={classes.spanHeader}>
                PERSON PAGE *<LabelText> </LabelText>
              </Box>
              <div className={classes.galleryDiv}>
                <div>
                  <Avatar
                    alt="img"
                    src={
                      (imgObj &&
                        imgObj.length > 0 &&
                        imgObj[0].type === 'image' &&
                        imgObj[0].url) ||
                      (galleryUrl &&
                        galleryUrl.length > 0 &&
                        galleryUrl[0].url) ||
                      ''
                    }
                    className={classes.galleryDrop}
                  />
                </div>
                {error && error.galleryUrl ? (
                  <div>
                    <Box component="span" className={classes.galleryError}>
                      {error.galleryUrl}
                    </Box>
                  </div>
                ) : (
                  ''
                )}
                <div
                  className={classes.manageGallery}
                  onClick={() => {
                    setTimeout(() => {
                      openGalleryDialog(true);
                    });
                  }}
                >
                  Manage Gallery
                </div>
              </div>

              <Grid item xs={12}>
                {isGalleryDialogOpen && (
                  <GalleryDialog
                    isOpen={isGalleryDialogOpen}
                    id={'1'}
                    key={'1'}
                    title="MANAGE PERSON GALLERY"
                    galleryDetails={gallery}
                    handleSaveBtn={handleSaveBtn}
                    isDelete={isDelete}
                    type="people"
                    handleClose={() => {
                      openGalleryDialog(false);
                    }}
                    galleryData={galleryData}
                  ></GalleryDialog>
                )}
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <NEInput
                label="First Name"
                variant="outlined"
                fullWidth
                required
                className={classes.inputWrap}
                name="firstName"
                id="firstName"
                defaultValue={firstName}
                onChange={e => handleInput(e)}
                error={!!error.firstName || ''}
                helperText={error.firstName || ''}
              />
              <NEInput
                label="Last Name"
                variant="outlined"
                fullWidth
                className={classes.inputWrap}
                defaultValue={lastName}
                name="lastName"
                onChange={e => handleInput(e)}
              />
              <NEInput
                id="description"
                label="Description"
                variant="outlined"
                fullWidth
                className={classes.inputWrap}
                defaultValue={description}
                name="description"
                multiline
                rows="4"
                required
                onChange={e => handleInput(e)}
                error={!!error.description || ''}
                helperText={error.description || ''}
              />
              <NEAutocomplete
                multiple
                key={'specialty'}
                options={aSpecialty || []}
                label="Specialty"
                name="specialty"
                defaultValue={values.specialty || []}
                id="specialty"
                onChange={(e, data) => {
                  setValues({ ...values, specialty: data, instrId: id });
                }}
              />
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                label="Admin Tags"
                popupIcon={<AddIcon />}
                forcePopupIcon={true}
                disableClearable={true}
                required
                name="adminTags"
                defaultValue={adminTags}
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      classes={{
                        label: classes.chipLabel,
                        deleteIcon: classes.deleteIcon,
                      }}
                    />
                  ))
                }
                onChange={(e, data) => {
                  setValues({ ...values, adminTags: data, instrId: id });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {associatedConcepts && associatedConcepts.length > 0 ? (
                <div>
                  <Box component="span" className={classes.subTitle}>
                    Associated Concepts
                  </Box>
                  {associatedConcepts &&
                    associatedConcepts.map(concept => (
                      <div>
                        <Box component="span" className={classes.subInTitle}>
                          {concept}
                        </Box>
                      </div>
                    ))}
                </div>
              ) : (
                ''
              )}

              {associatedCollections && associatedCollections.length > 0 ? (
                <div>
                  <Box component="span" className={classes.subTitle}>
                    Associated Collections
                  </Box>
                  {associatedCollections &&
                    associatedCollections.map(collection => (
                      <div>
                        <Box component="span" className={classes.subInTitle}>
                          {collection}
                        </Box>
                      </div>
                    ))}
                </div>
              ) : (
                ''
              )}

              <div>
                <Typography variant="h6" className={classes.subTitle}>
                  HISTORY
                </Typography>
                <div>
                  <Box component="span" className={classes.subInTitle}>
                    <HistoryText>
                      Updated {moment(updatedAt).format(`L`)}
                    </HistoryText>
                    {isPublished && (
                      <HistoryText>
                        Published {moment(publishedDate).format(`L`)}
                      </HistoryText>
                    )}
                    <HistoryText>
                      Added {moment(createdAt).format(`L`)}
                    </HistoryText>
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isHidden ? (
            <Box display="flex" alignItems="center" mr={1}>
              <StatusLabel>STATUS</StatusLabel>
              <StatusText>HIDDEN</StatusText>
            </Box>
          ) : (
            <div>
              <Button
                onClick={() => {
                  openConfirmDialog(true);
                }}
                color="secondary"
              >
                Hide
              </Button>
            </div>
          )}

          <div className={classes.preViewSaveBtn}>
            {!isPublished ? (
              <Button
                color="secondary"
                size="large"
                onClick={() => {
                  const btnVal = 'publish';
                  handleFormSubmit(btnVal);
                }}
              >
                Publish
              </Button>
            ) : (
              <Box display="flex" alignItems="center" mr={1}>
                <StatusLabel>STATUS</StatusLabel>
                <StatusText>PUBLISHED</StatusText>
              </Box>
            )}
            <Button
              variant="contained"
              onClick={() => {
                const btnVal = 'submit';
                handleFormSubmit(btnVal);
              }}
              color="secondary"
              size="large"
            >
              Save & Close
            </Button>
          </div>
          <ConfirmDialog
            isOpen={isConfirmPublishOpen}
            headerText="Are you sure?"
            bodyText="This content is already published. Changes will impact existing behavior"
            handleClose={() => openConfirmPublishDialog(false)}
            onAgree={() => {
              const btnVal = 'submit';
              saveInstructor(btnVal);
            }}
            onDisagree={() => openConfirmPublishDialog(false)}
          />
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            handleClose={openConfirmDialog}
            onAgree={handleOk}
            onDisagree={() => openConfirmDialog(false)}
            agreeButtonText="Yes"
            disagreeBtnText="Cancel"
            headerText="Are you sure?"
            bodyText="You’re about to hide this person."
          ></ConfirmDialog>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function validateFields({
  firstNameErr,
  descriptionErr,
  imageErr,
  galleryErr,
}) {
  let formError = {};
  if (!firstNameErr) {
    formError = { ...formError, firstName: 'Please enter First Name' };
  }
  if (!descriptionErr) {
    formError = { ...formError, description: 'Please enter Discription' };
  }
  if (!galleryErr) {
    formError = { ...formError, galleryUrl: 'Please upload Gallery Image' };
  }

  if (!imageErr) {
    formError = { ...formError, imageUrl: 'Please upload a Profile Image' };
  }
  return formError;
}
