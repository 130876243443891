import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { searchSuggestions } from '../../containers/Video/actions';
import { createStructuredSelector } from 'reselect';
import makeSelectVideo from '../../containers/Video/selectors';
import { useSelector, useDispatch } from 'react-redux';
import reducer from '../../containers/Video/reducer';
import saga from '../../containers/Video/saga';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SvgIconSearch } from '../NeIcons';
import { searchLiveSuggestions } from '../../containers/Live/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: '16px 0px 10px 0px',
      color: '#222A31',
      // borderBottom: '1px solid #35414A',
      fontFamily: 'Proxima-Nova-Regular',
      [theme.breakpoints.only('sm')]: {
        margin: '0px 16px',
      },

      '& .MuiInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiAutocomplete-noOptions': {
        color: '#ccc',
      },
      '& .MuiAutocomplete-endAdornment': {
        display: 'none',
      },
      '& input': {
        fontSize: '16px',
        color: '#222A31',
        '&::placeholder': {
          color: '#8C949B',
          fontStyle: 'italic',
        },
      },
      '& .Mui-focused': {
        // color: '#909599',
        color: '#ccc',
      },
      '& .MuiFocused-Input': {
        color: '#909599',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#909599',
        borderWidth: '2px',
      },
    },
    endAdornment: {
      // display: 'none',
    },
    underline: {
      '& .underline': {
        borderBottom: 'none',
      },
    },
    onOptions: {
      color: '#ccc',
    },
    paper: {
      background: '#1D2429',
      color: '#E6EAED',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '14px',
      marginTop: '60px',
      '& .MuiAutocomplete-option[data-focus="true"]': {
        color: '#FFFFFF',
        background: '#35414A',
      },
      '& .MuiAutocomplete-noOptions': {
        color: '#FFFFFF',
      },
    },
    textField: {
      position: 'relative',
      display: 'flex',
    },
    icon: {
      color: '#3C4042',
      position: 'absolute',
      right: '20px',
      top: '13px',
    },
  }),
);

const stateSelector = createStructuredSelector({
  video: makeSelectVideo(),
});

export default function FilterSearchLabel(props) {
  useInjectReducer({ key: 'video', reducer: reducer });
  useInjectSaga({ key: 'video', saga: saga });
  const dispatch = useDispatch();
  const { video } = useSelector(stateSelector);
  let { searchSuggestion = [] } = video;

  const classes = useStyles();
  const [value, setValue] = useState(props.values);
  const handleChange = value => {
    if (value) {
      setValue(value);
      props.onChange(value);
    }
  };

  const triggerhandleChange = () => {
    props.onClick(value);
  };

  const triggerAPICall = value => {
    setValue(value);
    props.onChange(value);
    if (value && value.length > 2) {
      if (window.location.href.includes(`\live`)) {
        dispatch(searchLiveSuggestions(value));
      } else {
        dispatch(searchSuggestions(value));
      }
    }
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        id="combobox"
        options={searchSuggestion || []}
        value={props.values}
        onChange={(event, value) => handleChange(value)}
        onInputChange={(event, value) => triggerAPICall(value)}
        disablePortal={true}
        style={{
          width: '100%',
          borderBottom: 'none',
        }}
        classes={{
          endAdornment: classes.endAdornment,
          paper: classes.paper,
        }}
        renderInput={params => (
          <div className={classes.textField}>
            <TextField
              {...params}
              label="Keyword(s)"
              id="keywords"
              placeholder="Keyword(s)"
              variant="outlined"
            />
            <div className={classes.icon}>
              <SvgIconSearch
                width="16px"
                height="16px"
                onClick={triggerhandleChange}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
}
