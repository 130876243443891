import React from 'react';
import Fab from '@material-ui/core/Fab';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      background: '#12161A',
      width: '70px',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      letterSpacing: '0',
      lineHeight: '20.4px',
      textAlign: 'center',
      minWidth: '85px !important',
      height: '24px !important',
      textTransform: 'none !important',
      letterSpacing: '1px !important',
      '&:hover': {
        background: '#12161A',
      },
    },
  }),
);

export default function FloatingActionButton({
  onClick,
  buttonText = 'Manage',
}) {
  const classes = useStyles();

  return (
    <div>
      <div>
        <Fab
          variant="extended"
          size="medium"
          aria-label="add"
          className={classes.margin}
          onClick={onClick}
        >
          {buttonText}
        </Fab>
      </div>
    </div>
  );
}
