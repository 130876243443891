/*
 *
 * administrator constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/Video/DEFAULT_ACTION',
  SAVE_ADMINSTRATOR_DETAIL = 'SAVE_ADMINSTRATOR_DETAIL',
  REQUEST_ADMINISTRATOR_LIST = 'REQUEST_ADMINISTRATOR_LIST',
  ADMINISTRATOR_LIST_SUCCESS = 'ADMINISTRATOR_LIST_SUCCESS',
  ADMINISTRATOR_LIST_FAILED = 'ADMINISTRATOR_LIST_FAILED',
  SET_ADMINISTARTOR_LOADER = 'SET_ADMINISTARTOR_LOADER',
  FETCH_ADMINISTRATOR_BY_ID = 'FETCH_ADMINISTRATOR_BY_ID',
  PASSWORD_RESET = 'PASSWORD_RESET',
  REMOVE_ADMINISTRATOR = 'REMOVE_ADMINISTRATOR',
  STORE_INSTRUCTOR_NAME = 'STORE_INSTRUCTOR_NAME',
  REQUEST_INSTRUCTOR = 'REQUEST_INSTRUCTOR',
  OPEN_ADMIN_DRAWER_ADD = 'OPEN_ADMIN_DRAWER_ADD',
  SET_LOADER = 'SET_LOADER',
}

export default ActionTypes;
