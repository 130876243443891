import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { Formik, Form, ErrorMessage, FieldArray } from 'formik';
import FormikField from '../../../components/Formik/FormikField';
import {
  StatusLabel,
  StatusText,
  ErrorText,
} from '../../../components/Typography';
import { useStyles } from '../styles';
import FormikSelect from '../../../components/Formik/FormikSelect';
import StartDateTime from '../../../components/Formik/StartDateTime';
import { getUniqItems } from '../../../utils/array';
import { GreenCheckbox } from '../../../components/GreenCheckbox';
import FormikAutocomplete from '../../../components/Formik/FormikAutocomplete';
import { SearchInteractiveConcept } from '../../../components/SearchInteractiveConcept';
import { SearchInteractiveInstructor } from '../../../components/SearchInteractiveInstructor';
import { SearchTypeInteractiveComponent } from '../../../components/searchInteractiveComponent';
import { openSnackBar } from '../../Root/actions';
import { useDispatch } from 'react-redux';
import request from '../../../shared/lib/request';
import moment from 'moment';
import * as Yup from 'yup';
import { requestPromoList } from '../actions';

const validateSlot = value => {
  let result = /^[0-9]*$/.test(value);
  let result2 = /^[a-z]*$/.test(value);
  return !value || (result && value > 0) || (result2 && value === 'unlimited');
};

Yup.addMethod(Yup.string, 'checkSlot', function(errorMessage) {
  return this.test(`test-card-type`, errorMessage, function(value) {
    return validateSlot(value);
  });
});

const { object, string, number, date, boolean, array } = Yup;

export const CouponFormSchema = object({
  uniqueCode: string()
    .required('Enter Code')
    .min(3)
    .matches(
      /^[A-Z0-9]*$/,
      'Enter only UPPERCASE character/digits without space',
    )
    .max(25),
  offerPercentage: number()
    .min(1)
    .max(100)
    .required('Enter discount percentage'),
  maxSlot: Yup.string()
    .checkSlot(`Enter limit greater than 0 or ‘unlimited'`)
    .required(`Enter limit greater than 0 or ‘unlimited'`),
  availableSlot: Yup.string()
    .checkSlot(`Enter limit greater than 0 or ‘unlimited'`)
    .required(`Enter limit greater than 0 or ‘unlimited'`),
});

const initialFormValues = {
  uniqueCode: '',
  startDate: new Date().setHours(0, 0, 0, 0),
  endDate: new Date().setHours(23, 59, 59, 0),
  offerPercentage: '',
  mappedTo: 'firstclass',
  isPublished: false,
  maxSlot: 'unlimited',
  availableSlot: 'unlimited',
  referenceIdDetails: {},
  validFor: '',
  referenceId: '',
  package: '',
  content: { data: [] },
};

const initialPossibleOptions = {
  mappedToOptions: [],
};

export default function EditCouponForm(props) {
  const {
    id,
    couponData,
    packagesList,
    formType,
    isOpen,
    handleEditDrawerClose,
  } = props;
  const [formValues, setFormValues] = useState(initialFormValues);
  const [isError, setError] = useState(false);
  const [isPackageLive, setPackageLive] = useState(false);
  const [isPublish, setPublish] = useState(false);
  const [isContentError, setContentError] = useState(false);
  const [isStartDateError, setStartDateError] = useState(false);
  const [isEndDateError, setEndDateError] = useState(false);
  const [possibleOptions, setPossibleOptions] = useState(
    initialPossibleOptions,
  );

  const initialValue = {
    uniqueCode: '',
    startDate: new Date().setHours(0, 0, 0, 0),
    endDate: new Date().setHours(23, 59, 59, 0),
    offerPercentage: '100',
    mappedTo: 'firstclass',
    isPublished: false,
    maxSlot: 'unlimited',
    availableSlot: 'unlimited',
    referenceIdDetails: {},
    validFor: '',
    referenceId: '',
    package: '',
    content: { data: [] },
    ...couponData,
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  const _fetchVideoById = () =>
    request({
      url: `/neouadmin/v1/promocode/${id}`,
      method: 'GET',
    });

  const mappedToOptions = [
    { label: 'First-time class only (Drop In)', value: 'firstclass' },
    {
      label: 'First-time concept only (Drop In)',
      value: 'firstconceptclass',
    },
    {
      label: 'First-time instructor only (Drop In)',
      value: 'firstinstructorclass',
    },
    { label: 'Class Drop In', value: 'class' },
    { label: 'Instructor Drop In', value: 'instructor' },
    { label: 'Concept Drop In', value: 'concept' },
    { label: 'Global Drop In', value: 'global' },
    { label: 'Packages Only', value: 'package' },
  ];

  const formData = formValues => {
    if (formValues.isPackageLive) {
      let requestBody = {
        ...couponData,
        id: (formValues && formValues.id) || couponData.id,
        isRecurringPackage: formValues.isRecurringPackage
          ? formValues.isRecurringPackage
          : false,
        endDate: new Date(formValues.endDate).getTime(),
        maxSlot:
          formType !== 'ADD'
            ? isNaN(formValues && formValues.maxSlot)
              ? (formValues && formValues.maxSlot) || 'unlimited'
              : parseInt(formValues && formValues.maxSlot)
            : formType === 'ADD'
            ? formValues && formValues.maxSlot
            : 'unlimited',
        isPublished: (formValues && formValues.isPublished) || false,
      };
      return requestBody;
    } else {
      let requestBody = {
        id: (formValues && formValues.id) || couponData.id,
        uniqueCode: formValues && formValues.uniqueCode,
        startDate: new Date(formValues.startDate).setHours(0, 0, 0, 0),
        endDate: new Date(formValues.endDate).setHours(23, 59, 59, 0),
        offerPercentage: formValues && formValues.offerPercentage,
        mappedTo: formValues && formValues.mappedTo,
        isRecurringPackage:
          formValues &&
          (formValues.package && formValues.package.recurring !== null) &&
          formValues.isRecurringPackage
            ? formValues.isRecurringPackage
            : formValues.isRecurringPackage || false,
        referenceId: formValues.referenceId,
        maxSlot: isNaN(formValues && formValues.maxSlot)
          ? (formValues && formValues.maxSlot) || 'unlimited'
          : parseInt(formValues && formValues.maxSlot),

        isPublished: (formValues && formValues.isPublished) || false,
        availableSlot: formValues.availableSlot,
      };
      if (formType === 'ADD') {
        requestBody.availableSlot = isNaN(formValues && formValues.maxSlot)
          ? (formValues && formValues.maxSlot) || 'unlimited'
          : parseInt(formValues && formValues.maxSlot);
      }
      return requestBody;
    }
  };

  const saveCoupon = data => {
    data.isPublished = isPublish;
    data.isPackageLive = isPackageLive;
    setContentError(false);
    if (
      (data.mappedTo === 'instructor' ||
        data.mappedTo === 'firstinstructorclass' ||
        data.mappedTo === 'concept' ||
        data.mappedto === 'firstconceptclass' ||
        data.mappedTo === 'class') &&
      (data.content.data.length > 1 || data.referenceId === '')
    ) {
      setContentError(true);
    } else {
      setStartDateError(false);
      setEndDateError(false);
      let currentTime = moment(Date.now());
      let startTime = moment(data.startDate);
      let endTime = moment(data.endDate);
      let difference = currentTime.diff(startTime, 'hours');
      let isStarted = difference < 24 || difference <= 0;
      let isSameDate = endTime.diff(startTime, 'hours') < 24;
      let isEnded = endTime.diff(startTime) >= 30000;

      if (
        isSameDate ||
        (formType === 'ADD' && (!isStarted || !isEnded)) ||
        (formType !== 'ADD' && !isEnded)
      ) {
        if (!isStarted) {
          setStartDateError(true);
        }
        if (!isEnded) {
          setEndDateError(true);
        }
        if (isSameDate) {
          setEndDateError(true);
        }
        return;
      } else {
        handleEditFormSubmit(data);
      }
    }
  };

  const handleEditFormSubmit = async formValues => {
    let componentInfo = formData(formValues);
    delete componentInfo.referenceIdDetails;
    try {
      if (componentInfo.id && formType === 'EDIT') {
        let { result } = await request({
          url: `/neouadmin/v1/promocode/${componentInfo.id}`,
          method: 'PUT',
          data: componentInfo,
        });
      } else {
        await request({
          url: `/neouadmin/v1/promocode`,
          method: 'POST',
          data: componentInfo,
        });
      }

      dispatch(requestPromoList());
      dispatch(openSnackBar('Component has been updated successfully!'));
      handleEditDrawerClose();
    } catch (error) {
      if (
        error &&
        error.data &&
        error.data.error &&
        typeof error.data.error === 'string'
      ) {
        dispatch(openSnackBar(error.data.error));
      } else {
        dispatch(openSnackBar('Error in saving form'));
      }
    }
  };

  const _formInitialValues = (possibleValues, result) => {
    let { mappedToOptions = [] } = possibleValues;
    let possibleOptions = {
      mappedToOptions: mappedToOptions || [],
      packagesList: packagesList || [],
    };

    let {
      id,
      uniqueCode,
      startDate,
      endDate,
      offerPercentage,
      mappedTo,
      isPublished,
      maxSlot,
      availableSlot,
    } = result;

    if (startDate) {
      result.startDate = moment(+startDate).format('YYYY-MM-DDTHH:mm');
    }
    if (endDate) {
      result.endDate = moment(+endDate).format('YYYY-MM-DDTHH:mm');
    }
    let couponInfo = {
      ...result,
      uniqueCode: result.uniqueCode ? result.uniqueCode : '',
      offerPercentage: result.offerPercentage ? result.offerPercentage : '',
      mappedTo: result.mappedTo ? result.mappedTo : 'firstclass',
      isPublished: result.isPublished ? result.isPublished : false,
      maxSlot: result.maxSlot ? result.maxSlot : 'unlimited',
      availableSlot: result.availableSlot ? result.availableSlot : 'unlimited',
      referenceId: result.referenceId ? result.referenceId : '',
      package:
        result.mappedTo === 'package'
          ? result.referenceIdDetails && result.referenceIdDetails.nickname
          : '',
      referenceIdDetails: result.referenceIdDetails
        ? result.referenceIdDetails
        : {},
      validFor:
        result.referenceIdDetails && result.referenceIdDetails.title
          ? result.referenceIdDetails.title
          : result.referenceIdDetails && result.referenceIdDetails.firstName
          ? `${
              result.referenceIdDetails.firstName
            } ${result.referenceIdDetails &&
              result.referenceIdDetails.lastName}`
          : result.referenceIdDetails && result.referenceIdDetails.nickname
          ? result.referenceIdDetails.nickname
          : '',
      content: { data: [] },
    };
    return { possibleOptions, couponInfo };
  };

  const _init = async couponId => {
    try {
      setPackageLive(false);
      if (formType !== 'ADD') {
        let { responseCode, result, ...possibleValues } = await _fetchVideoById(
          couponId,
        );
        const { possibleOptions, couponInfo } = _formInitialValues(
          possibleValues,
          result,
        );

        setFormValues({
          ...initialFormValues,
          ...couponInfo,
        });
      }
      setPossibleOptions({ ...initialPossibleOptions, ...possibleOptions });
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    _init(id);
  }, [id]);

  const addContentList = (list, setFieldValue) => {
    let uniqItems = getUniqItems(list, 'id');
    setFieldValue('content.data', uniqItems);
    if (uniqItems.length > 0) {
      setFieldValue('referenceId', uniqItems[0] && uniqItems[0].id);
    }
  };

  const errorMessageValue = () => {
    return (
      <ErrorText>
        <ErrorMessage name="content.data" />
      </ErrorText>
    );
  };

  const renderSearchComponent = ({ values, setFieldValue }) => {
    if (values.mappedTo !== '') {
      switch (values.mappedTo) {
        case 'class':
          return (
            <SearchTypeInteractiveComponent
              videoList={
                (values && values.content && values.content.data) || []
              }
              onChange={list => addContentList(list, setFieldValue)}
              errorMessage={errorMessageValue}
            />
          );

        case 'concept':
          return (
            <SearchInteractiveConcept
              contentList={
                (values && values.content && values.content.data) || []
              }
              onChange={list => {
                addContentList(list, setFieldValue);
                setContentError(false);
              }}
              errorMessage={errorMessageValue}
            />
          );
        case 'firstconceptclass':
          return (
            <SearchInteractiveConcept
              contentList={
                (values && values.content && values.content.data) || []
              }
              onChange={list => {
                addContentList(list, setFieldValue);
                setContentError(false);
              }}
              errorMessage={errorMessageValue}
            />
          );

        case 'instructor':
          return (
            <SearchInteractiveInstructor
              contentList={
                (values && values.content && values.content.data) || []
              }
              onChange={list => {
                addContentList(list, setFieldValue);
                setContentError(false);
              }}
              errorMessage={errorMessageValue}
            />
          );
        case 'firstinstructorclass':
          return (
            <SearchInteractiveInstructor
              contentList={
                (values && values.content && values.content.data) || []
              }
              onChange={list => {
                addContentList(list, setFieldValue);
                setContentError(false);
              }}
              errorMessage={errorMessageValue}
            />
          );
        default:
          return (
            <SearchTypeInteractiveComponent
              videoList={
                (values && values.content && values.content.data) || []
              }
              onChange={list => {
                addContentList(list, setFieldValue);
                setContentError(false);
              }}
              errorMessage={errorMessageValue}
            />
          );
      }
    } else return;
  };

  const renderMessage = startDate => {
    let currentTime = moment(Date.now());
    let startTime = moment(startDate);
    let isStarted = startTime.diff(currentTime) >= 30000;
    if (!isStarted) {
      setPackageLive(true);
    } else {
      setPackageLive(false);
    }
    console.log(isPackageLive);

    if (isPackageLive) {
      return (
        <div className={classes.message}>
          The coupon is already activated. <br /> You can update only limit and
          expiry date.{' '}
        </div>
      );
    } else {
      return <> </>;
    }
  };

  return (
    <Formik
      initialValues={!isError ? formValues : initialValue}
      enableReinitialize
      validationSchema={CouponFormSchema}
      onSubmit={saveCoupon}
    >
      {({
        values,
        setFieldValue,
        submitForm,
        errors,
        isValid,
        setErrors,
        validateForm,
        setTouched,
      }) => {
        return (
          <Form noValidate>
            <div className={classes.drawerBody}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                  <Box m={2}>
                    <Grid container spacing={2}>
                      {formType === 'EDIT' &&
                        values.mappedTo === 'package' &&
                        renderMessage(values.startDate)}
                      <Grid item xs={6}>
                        <FormikField
                          name="uniqueCode"
                          label="Code*"
                          placeholder="Enter coupon code"
                          disabled={isPackageLive}
                        ></FormikField>
                      </Grid>
                      <Grid item xs={6}>
                        <FormikField
                          name="offerPercentage"
                          label="Discount Percentage*"
                          placeholder="Enter discount percentage"
                          disabled={isPackageLive}
                        ></FormikField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormikField
                          name="maxSlot"
                          label="Limit *"
                          placeholder="Enter limit"
                          onBlur={() => {
                            if (formType === 'ADD') {
                              setFieldValue(
                                'availableSlot',
                                values && values.maxSlot,
                              );
                            }
                          }}
                        ></FormikField>
                      </Grid>

                      <Grid item xs={6}>
                        <FormikField
                          name="availableSlot"
                          label="Remaining *"
                          placeholder="Enter available slot"
                          disabled={true}
                        ></FormikField>
                      </Grid>
                    </Grid>
                    {formType !== 'ADD' && values && values.validFor && (
                      <FormikField
                        name="validFor"
                        label="Valid for"
                        placeholder="Valid For"
                        disabled={true}
                      ></FormikField>
                    )}
                    <Grid container spacing={2}>
                      {values.mappedTo === 'package' &&
                      values.package &&
                      values.package.type !== null ? (
                        <>
                          <Grid item xs={6}>
                            <FormikAutocomplete
                              items={packagesList}
                              disabled={isPackageLive}
                              getOptionLabel={option =>
                                option.nickname ||
                                (values && values.validFor) ||
                                ''
                              }
                              name="package"
                              label="Package *"
                              onBlur={() => {
                                setFieldValue(
                                  'referenceId',
                                  (values.package && values.package.id) || '',
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              classes={{
                                root: classes.customStyle,
                              }}
                              control={
                                <GreenCheckbox
                                  name="isRecurringPackage"
                                  checked={values.isRecurringPackage}
                                  disabled={
                                    (values.package &&
                                      values.package.recurring === null) ||
                                    isPackageLive
                                  }
                                  onChange={e =>
                                    setFieldValue(
                                      'isRecurringPackage',
                                      event.target.checked,
                                    )
                                  }
                                />
                              }
                              label={
                                <Typography
                                  className={classes.formControlLabel}
                                >
                                  Recurring Package
                                </Typography>
                              }
                              labelPlacement="end"
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          {' '}
                          {values.mappedTo === 'package' && (
                            <FormikAutocomplete
                              items={packagesList}
                              getOptionLabel={option => option.nickname || ''}
                              name="package"
                              label="Package *"
                              disabled={isPackageLive}
                              onBlur={() => {
                                setFieldValue(
                                  'referenceId',
                                  (values.package && values.package.id) || '',
                                );
                              }}
                            />
                          )}{' '}
                        </>
                      )}
                    </Grid>

                    <Grid container spacing={2}>
                      {values.mappedTo === 'firstclass' ||
                      values.mappedTo === 'global' ? (
                        <FormikSelect
                          name="mappedTo"
                          label="Type *"
                          items={mappedToOptions}
                        />
                      ) : (
                        <Grid item xs={6}>
                          <FormikSelect
                            name="mappedTo"
                            label="Type *"
                            items={mappedToOptions}
                            disabled={isPackageLive}
                          />
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        {values && values.mappedTo !== 'package' ? (
                          <>
                            {(values.mappedTo === 'class' ||
                              values.mappedTo === 'instructor' ||
                              values.mappedTo === 'firstinstructorclass' ||
                              values.mappedTo === 'concept' ||
                              values.mappedTo === 'firstconceptclass') && (
                              <FormikField
                                name="referenceId"
                                label="ReferenceId *"
                                placeholder="Enter reference Id"
                                disabled={true}
                              ></FormikField>
                            )}
                          </>
                        ) : (
                          <FormikField
                            name="referenceId"
                            label="ReferenceId *"
                            placeholder="Enter reference Id"
                            disabled={true}
                          ></FormikField>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <StartDateTime
                          date={values.startDate}
                          selectedValue={setFieldValue}
                          label={'Start'}
                          value="startDate"
                          defaultTime={true}
                          disabled={isPackageLive}
                          onBlur={() => {
                            setStartDateError(false);
                          }}
                        />
                        {isStartDateError && (
                          <div className={classes.errorMsg}>
                            Enter future start date
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <StartDateTime
                          date={values.endDate}
                          selectedValue={setFieldValue}
                          label={'Expiry'}
                          value="endDate"
                          defaultTime={true}
                          onBlur={() => {
                            setEndDateError(false);
                          }}
                        />
                        {isEndDateError && (
                          <div className={classes.errorMsg}>
                            Enter future expiry date
                          </div>
                        )}
                      </Grid>
                    </Grid>

                    {(values.mappedTo === 'class' ||
                      values.mappedTo === 'instructor' ||
                      values.mappedTo === 'firstinstructorclass' ||
                      values.mappedTo === 'concept' ||
                      values.mappedTo === 'firstconceptclass') && (
                      <FieldArray
                        name="content.data"
                        render={arrayHelpers => (
                          <div>
                            {renderSearchComponent({
                              values,
                              setFieldValue,
                            })}
                          </div>
                        )}
                      />
                    )}
                    {isContentError && (
                      <ErrorText>Enter only one item</ErrorText>
                    )}

                    <Grid>
                      <div className={classes.btnContainer}>
                        <Button
                          onClick={e => {
                            if (isValid) {
                              setPublish(true);
                            }
                          }}
                          disableRipple
                          variant="contained"
                          type="submit"
                          color="secondary"
                        >
                          SAVE & PUBLISH
                        </Button>
                        <Button
                          color="secondary"
                          type="submit"
                          onClick={e => {
                            if (isValid) {
                              setPublish(false);
                            }
                          }}
                          disableRipple
                        >
                          SAVE DRAFT
                        </Button>

                        <Box display="flex" alignItems="center" mr={1}>
                          <StatusLabel>STATUS</StatusLabel>
                          <StatusText>
                            {formValues.isPublished || initialValue.isPublished
                              ? 'PUBLISHED'
                              : 'DRAFT'}
                          </StatusText>
                        </Box>
                      </div>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
