import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import Checkbox from '@material-ui/core/Checkbox';
import { createStructuredSelector } from 'reselect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeSelectAuthentication from '../../Authentication/selectors';
import { requestUserInfo } from '../../Authentication/action';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer } from '../../../utils/injectReducer';
import saga from '.././saga';
import { addPhoto } from '../../../services/s3';
import reducer from '.././reducer';
import {
  AddAdministrator,
  passwordRestAction,
  deleteAdministrator,
} from '../actions';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import ImageUploader from '../../../components/ImageUploader';
import { green } from '@material-ui/core/colors';
import isEmpty from 'lodash/isEmpty';
import {
  cloudfrontUrl,
  BUCKET_NAME,
  BUCKET_REGION,
} from '../../../utils/config';
//form component

const stateSelector1 = createStructuredSelector({
  authentication: makeSelectAuthentication(),
});

export default function EditAdministratorForm({
  administratorDetail,
  classes,
  toggleFn,
}) {
  const dispatch = useDispatch();
  useInjectReducer({ key: 'attribute', reducer: reducer });
  useInjectSaga({ key: 'attribute', saga: saga });
  const types = ['admin', 'superadmin', 'instructor'];
  const {
    authentication,
  }: { authentication: AuthenticationState } = useSelector(stateSelector1);
  const { userInfo } = authentication;
  const [state, setState] = React.useState({
    id: administratorDetail.id,
    name: administratorDetail.name,
    email: administratorDetail.email,
    type: administratorDetail.role,
    instrId:
      (administratorDetail &&
        administratorDetail.interactive &&
        administratorDetail.interactive.instrId) ||
      '',
    manageLibrary:
      administratorDetail.permissions &&
      !isEmpty(administratorDetail.permissions.filter(el => el === 'library')),
    liveParticipants:
      administratorDetail.permissions &&
      !isEmpty(
        administratorDetail.permissions.filter(
          el => el === 'live-participants-page',
        ),
      ),
    myClasses:
      administratorDetail.permissions &&
      !isEmpty(
        administratorDetail.permissions.filter(el => el === 'my-classes'),
      ),
    addClasses:
      administratorDetail.permissions &&
      !isEmpty(
        administratorDetail.permissions.filter(el => el === 'add-classes'),
      ),
    interactive:
      administratorDetail.permissions &&
      !isEmpty(
        administratorDetail.permissions.filter(el => el === 'interactive'),
      ),
    manageMembers:
      administratorDetail.permissions &&
      !isEmpty(administratorDetail.permissions.filter(el => el === 'members')),
    curateLabel:
      administratorDetail.permissions &&
      !isEmpty(administratorDetail.permissions.filter(el => el === 'curate')),
    configure:
      administratorDetail.permissions &&
      !isEmpty(
        administratorDetail.permissions.filter(el => el === 'configure'),
      ),
    managePlans:
      administratorDetail.permissions &&
      !isEmpty(administratorDetail.permissions.filter(el => el === 'plans')),
    titleError: '',
    isTitleError: false,
  });

  const change = (name, e) => {
    e.persist();
    handleChange(e);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange1 = event => {
    setState({ ...state, type: event.target.value });
  };
  const handleChange2 = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

  const onUpdateAdministrator = event => {
    let permissions = [];
    if (state.manageMembers) {
      permissions.push('members');
    }
    if (state.interactive) {
      permissions.push('interactive');
    }
    if (state.manageLibrary) {
      permissions.push('library');
    }
    if (state.liveParticipants) {
      permissions.push('live-participants-page');
    }
    if (state.myClasses) {
      permissions.push('my-classes');
    }
    if (state.addClasses) {
      permissions.push('add-classes');
    }
    if (state.curateLabel) {
      permissions.push('curate');
    }
    if (state.managePlans) {
      permissions.push('plans');
    }
    if (state.configure) {
      permissions.push('configure');
    }
    let useDate = {};
    if (state.type === 'instructor') {
      useDate = {
        instrId: state.instrId,
        id: state.id,
        name: state.name,
        email: state.email,
        role: state.type,
        permissions: permissions,
      };
    } else {
      useDate = {
        id: state.id,
        name: state.name,
        email: state.email,
        role: state.type,
        permissions: permissions,
      };
    }
    setState({ ...state });
    toggleFn();
    dispatch(AddAdministrator(useDate));
    if (userInfo.email === state.email) {
      dispatch(requestUserInfo());
    }
  };

  const onResetPassword = event => {
    dispatch(passwordRestAction(state.email));
  };

  const onSubmitDelete = event => {
    let permissions = [];
    if (state.manageMembers) {
      permissions.push('members');
    }
    if (state.manageLibrary) {
      permissions.push('library');
    }
    if (state.liveParticipants) {
      permissions.push('live-participants-page');
    }
    if (state.myClasses) {
      permissions.push('my-classes');
    }
    if (state.addClasses) {
      permissions.push('add-classes');
    }
    if (state.curateLabel) {
      permissions.push('curate');
    }
    if (state.managePlans) {
      permissions.push('plans');
    }
    if (state.configure) {
      permissions.push('configure');
    }
    const useDate = {
      id: state.id,
      name: state.name,
      email: state.email,
      role: state.type,
      permissions: permissions,
      deleteAdmin: true,
    };
    setState({ ...state });
    toggleFn();
    dispatch(deleteAdministrator(useDate));
  };
  const getPermissions = administratorDetail.permissions;
  const accessDelete = userInfo.email === state.email;
  return (
    <React.Fragment>
      <div className={classes.titleWrapper}>
        <NEInput
          id="administratorName"
          name="name"
          label="Name"
          value={state.name}
          defaultValue={administratorDetail.name}
          onChange={change.bind(null, 'name')}
          fullWidth
          variant="outlined"
          error={state.isNameError}
          helperText={state.nameError}
        />
        <NEInput
          id="administratorEmail"
          name="email"
          label="E-Mail Address"
          value={state.email}
          defaultValue={administratorDetail.email}
          onChange={change.bind(null, 'email')}
          fullWidth
          variant="outlined"
          error={state.isEmailError}
          helperText={state.nameError}
          disabled
        />
      </div>
      <div className={classes.secondRow}>
        <NEInput
          id="AdministratorType"
          name="type"
          className={classes.typeInput}
          select
          label="Type"
          defaultValue={administratorDetail.role}
          onChange={e => handleChange1(e)}
          variant="outlined"
        >
          {types &&
            types.map(t => (
              <MenuItem value={t} key={t}>
                {t}
              </MenuItem>
            ))}
        </NEInput>
      </div>
      {/* <div className={classes.resetBlk}>
        <Button
          color="secondary"
          fullWidth
          onClick={event => {
            onResetPassword(event);
          }}
        >
          SEND PASSWORD RESET
        </Button>
      </div> */}

      <div className={classes.secondRow}>
        <h4 className={classes.perLabel}>Permissions</h4>
        <div className={classes.checkboxBlock}></div>
        {state.type === 'instructor' && (
          <>
            <FormControlLabel
              value="top"
              classes={{
                root: classes.customStyle,
              }}
              control={
                <GreenCheckbox
                  name="myClasses"
                  checked={state.myClasses}
                  onChange={e => handleChange2(e)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  My Classes
                </Typography>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              value="top"
              classes={{
                root: classes.customStyle,
              }}
              control={
                <GreenCheckbox
                  name="addClasses"
                  checked={state.addClasses}
                  onChange={e => handleChange2(e)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Add Classes
                </Typography>
              }
              labelPlacement="end"
            />
          </>
        )}
        {state.type !== 'instructor' && (
          <>
            <FormControlLabel
              value="top"
              classes={{
                root: classes.customStyle,
              }}
              control={
                <GreenCheckbox
                  name="manageLibrary"
                  checked={state.manageLibrary}
                  onChange={e => handleChange2(e)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Manage Library
                </Typography>
              }
              labelPlacement="end"
            />

            <FormControlLabel
              value="top"
              classes={{
                root: classes.customStyle,
              }}
              control={
                <GreenCheckbox
                  name="liveParticipants"
                  checked={state.liveParticipants}
                  onChange={e => handleChange2(e)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Live Class View Only
                </Typography>
              }
              labelPlacement="end"
            />

            <FormControlLabel
              value="top"
              classes={{
                root: classes.customStyle,
              }}
              control={
                <GreenCheckbox
                  name="interactive"
                  checked={state.interactive}
                  onChange={e => handleChange2(e)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Manage Interactive
                </Typography>
              }
              labelPlacement="end"
            />

            <FormControlLabel
              classes={{
                root: classes.customStyle,
              }}
              control={
                <GreenCheckbox
                  name="manageMembers"
                  checked={state.manageMembers}
                  onChange={e => handleChange2(e)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Manage Members
                </Typography>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              classes={{
                root: classes.customStyle,
              }}
              control={
                <GreenCheckbox
                  name="curateLabel"
                  checked={state.curateLabel}
                  onChange={e => handleChange2(e)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Curate
                </Typography>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              classes={{
                root: classes.customStyle,
              }}
              control={
                <GreenCheckbox
                  name="managePlans"
                  checked={state.managePlans}
                  onChange={e => handleChange2(e)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Manage Plans
                </Typography>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              classes={{
                root: classes.customStyle,
              }}
              control={
                <GreenCheckbox
                  name="configure"
                  checked={state.configure}
                  onChange={e => handleChange2(e)}
                />
              }
              label={
                <Typography className={classes.formControlLabel}>
                  Configure
                </Typography>
              }
              labelPlacement="end"
            />
          </>
        )}
      </div>

      <Grid>
        <div className={classes.btnContainer}>
          {!accessDelete && (
            <Button
              variant="contained"
              color="primary"
              onClick={event => {
                onSubmitDelete(event);
              }}
              disableRipple={true}
            >
              <div className={classes.delBtn}>DELETE</div>
            </Button>
          )}
          <Button
            onClick={event => {
              onUpdateAdministrator(event);
            }}
          >
            <div className={classes.saveLegalBtn1}>SAVE</div>
          </Button>
        </div>
      </Grid>
    </React.Fragment>
  );
}
