import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the video state domain
 */

const selectVideoDomain = (state: ApplicationRootState) => {
  return state.video || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Video
 */

const makeSelectVideo = () =>
  createSelector(
    selectVideoDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectVideo;
export { selectVideoDomain };
