import * as React from 'react';

function SvgIconConfigure(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <path
        d="M11 2v2.67l-1 1-1-1V2h2zm7 7v2h-2.67l-1-1 1-1H18zM4.67 9l1 1-1 1H2V9h2.67zM10 14.33l1 1V18H9v-2.67l1-1zM13 0H7v5.5l3 3 3-3V0zm7 7h-5.5l-3 3 3 3H20V7zM5.5 7H0v6h5.5l3-3-3-3zm4.5 4.5l-3 3V20h6v-5.5l-3-3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconConfigure;
