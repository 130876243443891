import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayText: {
      color: '#FFF',
      fontFamily: 'ProximaNova-Bold, Helvetica, Arial, sans-serif',
      fontSize: '10px',
      lineHeight: '13px',
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.up('xl')]: {
        fontSize: 12,
      },
    },
    dayCount: {
      color: '#FFF',
      fontFamily: 'Proxima-Nova-Regular, Helvetica, Arial, sans-serif',
      fontSize: '20px',
      lineHeight: '24px',
      textAlign: 'center',
      [theme.breakpoints.up('xl')]: {
        fontSize: 24,
      },
    },
    selectedText: {
      color: '#12161A',
    },
  }),
);

export default useStyles;
