import { call, put, takeLatest } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import { openSnackBar, closeSnackBar } from '../Root/actions';
import { actionFaild, memberLoader } from './actions';
import { AnyAction } from 'redux';

// Individual exports for testing
export default function* membersSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.PASSWORD_RESET, restPasswordAction);
  yield takeLatest(ActionTypes.REVOKE_ACCESS, revokeAccessAction);
  yield takeLatest(ActionTypes.SWITCH_EMAILLOGIN, switchEmailAction);
  yield takeLatest(ActionTypes.SUBSCRIPTION_PLANS, getSubscriptionPlans);
}

// reset
export function* restPasswordAction(action: AnyAction) {
  try {
    let { link } = yield call(request, {
      url: '/neouadmin/v1/members/send-reset-pwd-link',
      method: 'POST',
      data: {
        email: action.payload,
      },
    });
    yield put(openSnackBar('Email sent to reset password!'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  } catch (error) {
    if (error && error.data) {
      yield put(openSnackBar(error.data.message || 'Reset password failed'));
      setTimeout(() => {
        put(closeSnackBar());
      }, 1000);
    }
    yield put(actionFaild());
  }
}

// switchEmailAction
export function* switchEmailAction(action: AnyAction) {
  try {
    const data = action.payload;
    let { link } = yield call(() =>
      request({
        url: `/neouadmin/v1/members/update`,
        method: 'PUT',
        data: data,
      }),
    );
    yield put(openSnackBar('Switch email login success'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(openSnackBar('SWITCH TO EMAIL LOGIN failed'));
      setTimeout(() => {
        put(closeSnackBar());
      }, 1000);
    }
    yield put(actionFaild());
  }
}

// revoke
export function* revokeAccessAction(action) {
  let { userId, status } = action.payload;
  const getData = {
    id: userId,
    userStatus: 'inactive',
  };
  yield put(memberLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/members/update`,
        data: getData,
        method: 'PUT',
      }),
    );
    yield put(memberLoader(false));
    yield put(openSnackBar('User access has been revoked.'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  } catch (error) {
    yield put(memberLoader(false));
  }
}
// subscription plans
export function* getSubscriptionPlans(action) {
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/subscription/plan`,
        method: 'GET',
      }),
    );
  } catch (error) {
    yield put(memberLoader(false));
  }
}
