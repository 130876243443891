/*
 *
 * InteractivePublishedClasses actions
 *
 */

import { action } from 'typesafe-actions';
import { VideoList } from './types';

import ActionTypes from './constants';

export const requestClientList = videoId => {
  return action(ActionTypes.REQUEST_MY_CLIENT, videoId);
};

export const storeClients = clients => {
  return action(ActionTypes.STORE_CLIENTS, clients);
};

export const addClassEvent = data => {
  return action(ActionTypes.ADD_CLASS_EVENT, data);
};

export const storeClassDetailPreview = data => {
  return action(ActionTypes.STORE_CLASS_DETAIL_PREVIEW, data);
};

export const requestInteractiveVideoList = () => {
  return action(ActionTypes.REQUEST_INTERACTIVE_VIDEO_LIST);
};

export const requestUpcomingCalenderList = () => {
  return action(ActionTypes.REQUEST_INTERACTIVE_CALENDER_LIST);
};

export const serSortBy = sortBy => {
  return action(ActionTypes.SET_SORT_BY, sortBy);
};

export const setTabChange = tab => {
  return action(ActionTypes.SET_ACTIVE_TAB, tab);
};

export const durationFailed = () => {
  return action(ActionTypes.DURATION_DETAILS_FAILED);
};

export const VideoListSuccess = (videoList: VideoList) => {
  return action(ActionTypes.VIDEO_LIST_SUCCESS, videoList);
};

export const UpcomingCalenderListSuccess = (videoList: VideoList) => {
  return action(ActionTypes.UPCOMING_CALENDER_LIST_SUCCESS, videoList);
};
export const VideoListCountSuccess = (total: number) => {
  return action(ActionTypes.VIDEO_LIST_TOTAL, total);
};

export const videoListFailed = () => {
  return action(ActionTypes.VIDEO_LIST_FAILED);
};

/* To fetch the Filters Params */
export const requestFilters = (keyword: string) =>
  action(ActionTypes.REQUEST_FILTERS, keyword);

/* Holds the Selected Filters Params Data */

export const changedFiltersParamsI = data =>
  action(ActionTypes.CHANGED_FILTERS_DATA, data);

export const filtersFetchSuccess = data =>
  action(ActionTypes.FILTERS_FETCH_SUCCESS, data);

/* Sets the data to initial state if any API failures occurs */

export const filtersFetchFailed = () =>
  action(ActionTypes.FILTERS_FETCH_FAILED);

export const requestVideoListWithFiltersInteractive = data => {
  return action(ActionTypes.REQUEST_VIDEO_LIST_WITH_FILTERS_INTERACTIVE, data);
};

export const VideoListSuccessWithFiltersInteractive = searchObj => {
  return action(
    ActionTypes.VIDEO_LIST_SUCCESS_WITH_FILTERS_INTERACTIVE,
    searchObj,
  );
};

export const requestFilterParamsList = () => {
  return action(ActionTypes.REQUEST_FILTER_PARAMS);
};

export const filterUpdateList = () => {
  return action(ActionTypes.FILTER_UPDATE_VIDEO_LIST);
};

export const updateSearchCount = () => {
  return action(ActionTypes.UPDATE_SEARCH_COUNT);
};

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const putClassPagination = () => {
  return action(ActionTypes.PUT_CLASS_LIST_PAGINATION_SUCCESS);
};

export const setClassPaginationLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_CLASS_PAGINATION_LOADER, isLoading);
};

export const requestClassListPagination = payload => {
  return action(ActionTypes.REQUEST_CLASS_LIST_PAGINATION, payload);
};

export const classListPaginationSuccess = payload => {
  return action(ActionTypes.CLASS_LIST_PAGINATION_SUCCESS, payload);
};

export const searchResultsPagination = searchObj => {
  return action(ActionTypes.VIDEO_LIST_WITH_FILTERS_PAGINATION, searchObj);
};

export const classListCountSuccess = (total: number) => {
  return action(ActionTypes.CLASS_LIST_TOTAL, total);
};

export const sendCampaignEmailAction = payload => {
  return action(ActionTypes.SEND_CAMPAIGN_EMAIL, payload);
};

export const requestClientsJoinedList = videoId => {
  return action(ActionTypes.REQUEST_JOINED_CLIENTS_LIST, videoId);
};

export const storeClientsJoined = data => {
  return action(ActionTypes.STORE_JOINED_CLIENTS_LIST, data);
};
