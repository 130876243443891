import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(5,6,6,0.7)',
    },
    paper: {
      width: '400px',
      maxWidth: '400px',
      borderRadius: '0px',
      backgroundColor: 'white',
      '& .MuiDialogContent-root': {},
      '& .MuiFormControlLabel-label': {
        fontSize:'15px',
      },
    },
    heading: {
      letterSpacing: 0,
      color: '#12161A',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '18px',
      lineHeight: '20px',
      textAlign: 'left',
      marginBottom: '8px',
    },
    subHeading: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'justify',
    },
    okButton: {
      minWidth: '100px',
      '& .MuiButton-label': {
        color: '#5AA700',
        fontFamily: 'ProximaNova-Bold',
        fontSize: '15px',
        fontWeight: 'bold',
        letterSpacing: '0.3px',
        lineHeight: '20px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    cancelButton: {
      minWidth: '100px',
      '& span': {
        color: '#5AA700',
        fontFamily: 'ProximaNova-Bold',
        fontSize: '15px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        lineHeight: '20px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    btnWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0px 16px 16px',
    },
    textArea: {
      background: '#1D2429',
      color: 'white',
      border: 'none',
      textAlign: 'justify',
      resize: 'none',
      fontSize: '16px',
      '&:focus': {
        outline: 'none',
      },
    },
  }),
);

export default function ConfirmDialog({
  isOpen,
  onAgree,
  onDisagree,
  handleClose,
  agreeButtonText = 'YES',
  disagreeBtnText = 'CANCEL',
  headerText = 'Are you Sure?',
  bodyText,
  isDisAgreeShow = true,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const onAgreeClick = () => {
    onAgree();
    handleClose();
  };

  const onDisagreeClick = () => {
    onDisagree();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ root: classes.root, paper: classes.paper }}
      >
        <DialogContent>
          <DialogContentText className={classes.heading}>
            <Typography classes={{ root: classes.heading }}>
              {headerText}
            </Typography>
            <Typography classes={{ root: classes.subHeading }}>
              {bodyText ? bodyText : ''}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <div className={classes.btnWrap}>
          {isDisAgreeShow && (
            <Button
              onClick={onDisagreeClick}
              classes={{ root: classes.cancelButton }}
              disableRipple={true}
            >
              {disagreeBtnText}
            </Button>
          )}
          <Button
            onClick={onAgreeClick}
            classes={{ root: classes.okButton }}
            disableRipple={true}
          >
            {agreeButtonText}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
