/*
 *
 * HomeCurate actions
 *
 */

import { action } from 'typesafe-actions';
import { HomeCurate, LowerSection } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const requestHomeCurateInfo = (segmentFilter = '') =>
  action(ActionTypes.REQUEST_HOME_PAGE_INFO, segmentFilter);
export const storeHomeCurateInfo = (homeCurateInfo: HomeCurate) =>
  action(ActionTypes.STORE_HOME_PAGE_INFO, homeCurateInfo);
export const reorderTrays = (list: LowerSection) =>
  action(ActionTypes.REORDER_TRAYS, { list });
export const updateHomeComponent = (componentInfo, type) =>
  action(ActionTypes.UPDATE_HOME_COMPONENT, { componentInfo, type });

export const deleteHomeComponent = componentInfo =>
  action(ActionTypes.DELETE_HOME_COMPONENT, { componentInfo });
export const setLoader = isLoading => action(ActionTypes.SET_LOADER, isLoading);
