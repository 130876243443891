import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import request from '../../shared/lib/request';
import ActionTypes from './constants';
import { AnyAction } from 'redux';

import {
  storeProgramDetail,
  setProgramFetchFailed,
  setProgramFetchLoading,
  requestProgramDetail,
  setProgramFetchUpdating,
} from './actions';
import { Program } from './types';
import appRequest from '../../shared/lib/appRequest';

// Individual exports for testing
export default function* watchProgramDetailSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_PROGRAM_DETAIL, getProgramDetail);
  yield takeLatest(ActionTypes.START_PROGRAM, startProgram);
  yield takeLatest(ActionTypes.STOP_PROGRAM, stopProgram);
  yield takeLatest(ActionTypes.REMOVE_CLASS_LIST, removeClassList);
}

export function* getProgramDetail({ payload }: AnyAction) {
  yield put(setProgramFetchLoading());
  try {
    let { result, total } = yield call(() =>
      request({
        url: `/neouadmin/v1/collection-detail?id=${payload}`,
        method: 'GET',
      }),
    );

    if (result && result.data) {
      // let programDetail = result[0];
      // programDetail.isProgramStarted =
      //   programDetail.completedClassCount > 0 || programDetail.isStarted;
      yield put(storeProgramDetail(result.data));
    }
  } catch (error) {
    yield put(setProgramFetchFailed());
  }
}

export function* startProgram({ payload }: AnyAction) {
  let { id: programId, permaLink, title } = payload;
  yield put(setProgramFetchUpdating(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neou/v1/user/start-program`,
        method: 'POST',
        data: { programId },
      }),
    );
    yield put(setProgramFetchUpdating(false));
    trackSegmentEvents('Program Challenge Start', {
      collection_id: programId,
      collection_name: title,
      date: new Date(),
    });
  } catch (error) {
    yield put(setProgramFetchUpdating(false));
  }
}

export function* stopProgram({ payload }: AnyAction) {
  let { id: programId, permaLink } = payload;
  yield put(setProgramFetchUpdating(true));
  try {
    yield call(() =>
      request({
        url: `/neou/v1/user/stop-program`,
        method: 'POST',
        data: { programId },
      }),
    );
    yield put(setProgramFetchUpdating(false));
  } catch (error) {
    yield put(setProgramFetchUpdating(false));
  }
}

export function* removeClassList() {
  const myClassInfo = yield select(selectMyClassListDomain);
  let { addToClassInfo } = myClassInfo;
  let { id, title, genre } = addToClassInfo;
  let data = { classId: id, type: 'remove' };
  let className = addToClassInfo && addToClassInfo.title;
  let classGenre =
    addToClassInfo &&
    addToClassInfo.genres &&
    addToClassInfo.genres.length > 0 &&
    addToClassInfo.genres[0].title;
  let permalink = window.location.href.replace(
    window.location.origin + '/neou/programs',
    '',
  );
  try {
    let { result } = yield call(() =>
      request({
        url: `/neou/v1/user/list`,
        method: 'POST',
        data: data,
      }),
    );
    yield put(requestProgramDetail(permalink));
    trackSegmentEvents('Remove From List', {
      class_genre: classGenre,
      class_id: id,
      class_name: className,
    });
  } catch (error) {
    // yield put(displayToaster('Remove list failed'));
  }
}
