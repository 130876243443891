export function trackSegmentEvents(eventName: string, eventObj: any) {
  //@ts-ignore
  if (!window.analytics) return;
  //@ts-ignore
  window.analytics.track(eventName, eventObj);
}

export function identifyUser(userId, traits) {
  //@ts-ignore
  if (!window.analytics) return;
  //@ts-ignore
  window.analytics.identify(userId, traits);
}

export function trackPageEvent(pageName) {
  //@ts-ignore
  if (!window.analytics) return;
  //@ts-ignore
  window.analytics.page(pageName);
}
