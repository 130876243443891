/*
 *
 * Collection
 *
 */

import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectCollection from './selectors';
import reducer from './reducer';
import saga from './saga';
import debounce from 'lodash.debounce';
import { Button, InputAdornment } from '@material-ui/core';
import StyledDialog from '../../components/StyledDialog';
import {
  requestCollectionList,
  setTabChange,
  setSeachKey,
  getCollectionDetail,
  requestCollectionListPagination,
  resetCollection,
} from './actions';
import CollectionListTable from './CollectionTable/CollectionTable';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';
import { useStyles } from './styles';
import {
  SvgIconSearch,
  SvgIconAdd,
  SvgLoaderGif,
} from '../../components/NeIcons';
import SortSelect from './Select';
import TypeSelect from './TypeSelect';
import Loader from '../../components/Loader';
import NEInput from '../../components/NEInput';
import CollectionFormDialog, {
  FormType,
} from './components/CollectionFormDialog';
import { Helmet } from 'react-helmet';

const stateSelector = createStructuredSelector({
  collection: makeSelectCollection(),
});

interface Props {}

function Collection(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'collection', reducer: reducer });
  useInjectSaga({ key: 'collection', saga: saga });

  const { collection } = useSelector(stateSelector);
  let {
    collectionList,
    isListFetchingError,
    isLoading,
    collectionDetail = {},
    isCollectionLoading,
    total,
  } = collection;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [isAddCollectionOpen, openAddCollectionDialog] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [activeTab, setActiveTab] = React.useState(0);
  const [formDialogInfo, setFormDialogInfo] = React.useState({
    isOpen: false,
    id: '',
  });

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    if (tabIndex === 0) {
      dispatch(setTabChange('isHidden=false'));
      dispatch(requestCollectionList());
    } else if (tabIndex === 1) {
      dispatch(setTabChange('isPublished=false&isHidden=false'));
      dispatch(requestCollectionList());
    } else if (tabIndex === 2) {
      dispatch(setTabChange('isPublished=true'));
      dispatch(requestCollectionList());
    } else if (tabIndex === 3) {
      dispatch(setTabChange('isHidden=true'));
      dispatch(requestCollectionList());
    }
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    if (activeTab === 0) {
      dispatch(setTabChange('isHidden=false'));
    }
    // Update the document title using the browser API
    dispatch(requestCollectionList());
    dispatch(getCollectionDetail(null));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetCollection());
    };
  }, []);

  const tabConfig = [
    {
      label: 'ALL',
      component: <CollectionListTable />,
    },
    {
      label: 'DRAFT',
      component: <CollectionListTable />,
    },
    {
      label: 'PUBLISHED',
      component: <CollectionListTable />,
    },
    {
      label: 'HIDDEN',
      component: <CollectionListTable />,
    },
  ];

  const debouncedGet = useCallback(
    debounce(textValue => {
      onSearchClick(textValue);
    }, 1500),
    [], // will be created only once initially
  );

  const handleInput = e => {
    setSearchText(e.target.value);
    debouncedGet(e.target.value);
  };

  const onSearchClick = (text = '') => {
    dispatch(setSeachKey(text ? text : searchText));
    dispatch(requestCollectionList());
  };

  function loadMore() {
    if (collectionList && collectionList.length < total) {
      dispatch(requestCollectionListPagination(collectionList.length));
    }
  }
  return (
    <>
      <Helmet>
        <title>Library Collections</title>
        <meta name="description" content="Neou Fitness Collections" />
      </Helmet>
      {isLoading && <Loader />}
      {formDialogInfo.isOpen && (
        <CollectionFormDialog
          isOpen={formDialogInfo.isOpen}
          onClose={() => setFormDialogInfo({ isOpen: false, id: '' })}
          title="Add Collection"
          type={FormType.ADD}
          id={formDialogInfo.id}
        ></CollectionFormDialog>
      )}
      <div className={classes.root}>
        <div>
          <div className={classes.headWrap}>
            <div className={classes.head}>Library: Collections</div>
            <div className={classes.searchWrap}>
              <NEInput
                label="Search"
                variant="outlined"
                id="custom-css-outlined-input"
                fullWidth={true}
                onChange={e => handleInput(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={() => onSearchClick()}>
                        <SvgIconSearch />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.headWrap}>
            <Button
              onClick={() => {
                // openAddCollectionDialog(true);
                setFormDialogInfo({ isOpen: true, id: null });
              }}
              variant="contained"
              color="secondary"
              className={classes.addCollectionBtn}
              disableRipple={true}
            >
              <SvgIconAdd />
              <div className={classes.newCollection}>New Collection</div>
            </Button>
            <TypeSelect />
            <SortSelect />
          </div>

          <StyledTabs
            value={activeTab}
            onChange={onTabChange}
            aria-label="My account tabs"
            centered
            className={classes.styledTab}
          >
            {tabConfig.map((t, index) => (
              <StyledTab key={index} label={t.label} />
            ))}
          </StyledTabs>
          {isListFetchingError &&
          (collectionList && collectionList.length === 0) ? (
            <div className={classes.noData}>No Data Found</div>
          ) : (
            <CollectionListTable list={collectionList} isLoading={isLoading} />
          )}
          {isCollectionLoading && (
            <div style={{ padding: '10px' }}>
              <SvgLoaderGif />
            </div>
          )}
          {!isCollectionLoading &&
            collectionList &&
            collectionList.length > 0 &&
            total !== collectionList.length && (
              <div className={classes.loadMoreBtn}>
                <Button color="secondary" onClick={() => loadMore()}>
                  Load More Collections
                </Button>
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default Collection;
