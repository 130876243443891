import * as React from 'react';
import { View, Text } from 'react-native';
import { withStyles, Button, Typography } from '@material-ui/core';
import useStyles from './style';
export interface DayCalenderProps { }

const DayCalenderBtn = withStyles(theme => ({
  root: {
    borderRadius: '4px',
    minWidth: '34px',
    minHeight: '40px',
    color: 'rgba(53,65,74,0.5)',
    transition: 'none',
    height: 57,
    flex: 1,
    '&:not(:last-child)': {
      marginRight: 8,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '46px',
    },
    '&:hover': {
      background: '#35414A!important',
      '& .MuiTypography-root': {
        color: '#fff!important',
      },
    },
    '&$focusVisible': {
      background: '#FFF',
    },
    '&:active': {
      background: '#000',
    },
    '&$disabled': {
      color: '#12161A',
      opacity: '0.3',
    },
  },
  label: {
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
  },
  dark: {
    color: '#fff',
  },
}))(Button);

const DayCalender = (props: DayCalenderProps) => {
  const classes = useStyles();
  const {
    dayText = 'DAY',
    index,
    selected,
    selectedColor,
    selectedFontColor,
    theme,
  } = props;
  return (
    <DayCalenderBtn
      style={{
        background: selected
          ? theme !== 'dark'
            ? 'rgb(255, 255, 255)'
            : '#12161A'
          : 'rgba(53,65,74,0.5)',
      }}
      onClick={props.onClick}
    >
      <Typography
        className={`${classes.dayText} ${selected
          ? theme === 'dark'
            ? classes.dark
            : classes.selectedText
          : ''
          }`}
      >
        {dayText}
      </Typography>
      <Typography
        className={`${classes.dayCount} ${selected
          ? theme === 'dark'
            ? classes.dark
            : classes.selectedText
          : ''
          }`}
      >
        {index}
      </Typography>
    </DayCalenderBtn>
  );
};
export default DayCalender;
