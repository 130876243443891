import * as Yup from 'yup';
import { validatePermaLink } from '../../../utils/commonFunctions';
const { object, string, number, date, boolean, array } = Yup;

const validateRestrictedEmail = value => {
  const emailsWithCommaRegEx = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
  const domainRegEx = /^(?!-)^[@][A-Za-z0-9]+\.[A-Za-z]{2,6}$/;

  return (
    !value || (emailsWithCommaRegEx.test(value) || domainRegEx.test(value))
  );
};

Yup.addMethod(Yup.string, 'checkPermalink', function(errorMessage) {
  return this.test(`test-card-type`, errorMessage, function(value) {
    let check = /^\/classes\//;
    return validatePermaLink(value, check);
  });
});

Yup.addMethod(Yup.string, 'checkEmail', function(errorMessage) {
  return this.test(`test-card-type`, errorMessage, function(value) {
    return validateRestrictedEmail(value);
  });
});

const ClassSchema = object({
  title: string().required('Title is required'),
  permaLink: Yup.string()
    .checkPermalink(`Permalink is required and start with '/classes/'`)
    .required(`Permalink is required and start with '/classes/'`),
  videoType: string().required(),
  concept: string()
    .required('Enter a concept')
    .nullable(),
  instructors: array()
    .min(1)
    .required(),
  genres: array()
    .min(1)
    .max(2)
    .required(),
  interactive: object().shape({
    type: string(),
    dropInPrice: string()
      .required()
      .matches(/((\d+)+(\.\d{2,2}))$/, 'Enter the price of format $0.00'),
    spots: number()
      .min(1)
      .max(300)
      .required('Max spots can be upto 50'),
    bannerImg: string(),
    restrictedToEmails: Yup.string().checkEmail(
      `Enter the emails/domain in either ‘xxx@gmail.com,yyy@gmail.com' or '@domain.com’ format`,
    ),
  }),
  airedDate: date(),
  difficulty: string()
    .oneOf(['Beginner', 'Intermediate', 'Advanced', 'Beginner Basics'])
    .required('Choose a difficulty'),
  platform: string()
    //.oneOf(['signalwire', 'zoom'])
    .required('Choose a platform'),
  duration: number().required('Duration is required'),
  bodyFocus: array()
    .min(1)
    .required(),
  music: array()
    .min(1)
    .max(2)
    .required(),
  equipments: object().shape({
    required: array(),
    optional: array(),
  }),
});

export { ClassSchema };
