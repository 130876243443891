import { createSelector } from 'reselect';
import { RootState } from './types';
import { initialState } from './reducer';

/**
 * Direct selector to the programDetail state domain
 */

const selectProgramDetailDomain = (state: RootState) => {
  return state.programDetail || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProgramDetail
 */

const makeSelectProgramDetail = () =>
  createSelector(
    selectProgramDetailDomain,
    substate => {
      return { ...substate.programDetail, ...substate };
    },
  );

export default makeSelectProgramDetail;
export { selectProgramDetailDomain };
