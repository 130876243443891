/*
 *
 * ProgramDetail reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  data: null,
  isFetching: false,
  isFetchFailed: false,
  isUpdating: false,
};

function programDetailReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.PROGRAM_DETAIL_LOADING:
      return { ...state, isFetching: true };
    case ActionTypes.STORE_PROGRAM_DETAIL:
      return { ...state, data: action.payload, isFetching: false, isFetchFailed: false };
    case ActionTypes.PROGRAM_DETAIL_FAILED:
      return { ...state, isFetching: false, isFetchFailed: true };
    case ActionTypes.PROGRAM_DETAIL_UPDATING:
      return { ...state, isUpdating: action.payload };
    default:
      return state;
  }
}

export default programDetailReducer;
