/*
 *
 * Administrator
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import {
  requestAdministrators,
  requestInstructors,
  openAdminDrawerAdd,
} from './actions';
import makeSelectAdministrator from './selectors';
import Loader from '../../components/Loader';
import { SvgIconAdd } from '../../components/NeIcons';
import AdministratorListTable from './AdministratorTable/AdministratorTableList';
import AddAdministratorDialog from './AdministratorTable/AddAdministratorDialog';
import { useStyles } from './styles';
import ConfirmDialog from '../../components/ConfirmDialog';

const stateSelector = createStructuredSelector({
  administrator: makeSelectAdministrator(),
});

interface Props {}

function Administrator(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'administrator', reducer: reducer });
  useInjectSaga({ key: 'administrator', saga: saga });

  const { administrator } = useSelector(stateSelector);
  let {
    administratorList,
    isListFetchingError,
    isLoading,
    searchSuggestion,
    isAdminDrawerOpenAdd = false,
  } = administrator;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [state, setState] = React.useState({
    isAddClick: false,
  });

  useEffect(() => {
    // Update the document title using the browser API
    dispatch(requestAdministrators());
    dispatch(requestInstructors());
  }, []);

  const openAddAdministratorDialog = e => {
    setState({ ...state, isAddClick: true });
    dispatch(openAdminDrawerAdd(true));
  };
  return (
    <>
      <Helmet>
        <title>Administrators</title>
        <meta name="description" content="Tags configuration" />
      </Helmet>
      {isLoading && <Loader />}
      <div className={classes.root}>
        <div>
          <div className={classes.headWrap}>
            <div className={classes.head}>Administrators</div>
            <Button
              onClick={event => {
                openAddAdministratorDialog(event);
              }}
              variant="contained"
              color="secondary"
              className={classes.addAttributeBtn}
              disableRipple={true}
            >
              <SvgIconAdd />{' '}
              <div className={classes.newAttribute}>Administrator</div>
            </Button>
          </div>
          {isListFetchingError &&
          (administratorList && administratorList.length === 0) ? (
            <div className={classes.noData}>No Data Found</div>
          ) : (
            <AdministratorListTable
              list={administratorList}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>

      <Drawer
        anchor={'right'}
        disableBackdropClick={true}
        open={isAdminDrawerOpenAdd}
        onClose={() => showAlertToClose(true)}
      >
        <ConfirmDialog
          isOpen={isCloseAlertOpen}
          headerText="Are you sure?"
          bodyText="You are about to close the admin."
          onAgree={() => dispatch(openAdminDrawerAdd(false))}
          handleClose={() => showAlertToClose(false)}
          onDisagree={() => showAlertToClose(false)}
        ></ConfirmDialog>
        <div className={classes.rightContainer}>
          <div className={classes.titleDiv}>
            <div className={classes.title}>ADD ADMINISTRATOR</div>
            <Button
              onClick={() => showAlertToClose(true)}
              className={classes.closeBtn}
            >
              <CloseIcon />
            </Button>
          </div>
          <AddAdministratorDialog searchSuggestion={searchSuggestion} />
        </div>
      </Drawer>
    </>
  );
}

export default Administrator;
