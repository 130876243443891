/*
 *
 * InteractivePublishedClasses constants
 *
 */
enum ActionTypes {
  DEFAULT_ACTION = 'app/InteractivePublishedClasses/DEFAULT_ACTION',
  ADD_CLASS_EVENT = 'ADD_CLASS_EVENT',
  DURATION_DETAILS_FAILED = 'DURATION_DETAILS_FAILED',
  VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS',
  VIDEO_LIST_TOTAL = 'VIDEO_LIST_TOTAL',
  VIDEO_LIST_FAILED = 'VIDEO_LIST_FAILED',
  REQUEST_INTERACTIVE_VIDEO_LIST = 'REQUEST_INTERACTIVE_VIDEO_LIST',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  REQUEST_FILTERS = 'REQUEST_FILTERS',
  CHANGED_FILTERS_DATA = 'CHANGED_FILTERS_DATA',
  SELECTED_FILTERS_DATA = 'SELECTED_FILTERS_DATA',
  FILTERS_FETCH_SUCCESS = 'FILTERS_FETCH_SUCCESS',
  STORE_CLASS_DETAIL_PREVIEW = 'STORE_CLASS_DETAIL_PREVIEW',
  FILTERS_FETCH_FAILED = 'FILTERS_FETCH_FAILED',
  REQUEST_VIDEO_LIST_WITH_FILTERS_INTERACTIVE = 'REQUEST_VIDEO_LIST_WITH_FILTERS_INTERACTIVE',
  VIDEO_LIST_SUCCESS_WITH_FILTERS_INTERACTIVE = 'VIDEO_LIST_SUCCESS_WITH_FILTERS_INTERACTIVE',
  SET_SORT_BY = 'SET_SORT_BY',
  REQUEST_FILTER_PARAMS = 'REQUEST_FILTER_PARAMS',
  PUT_CLASS_LIST_PAGINATION_SUCCESS = 'PUT_CLASS_LIST_PAGINATION_SUCCESS',
  SET_CLASS_PAGINATION_LOADER = 'SET_CLASS_PAGINATION_LOADER',
  REQUEST_CLASS_LIST_PAGINATION = 'REQUEST_CLASS_LIST_PAGINATION',
  CLASS_LIST_PAGINATION_SUCCESS = 'CLASS_LIST_PAGINATION_SUCCESS',
  CLASS_LIST_TOTAL = 'CLASS_LIST_TOTAL',
  REQUEST_MY_CLIENT = 'REQUEST_MY_CLIENT',
  STORE_CLIENTS = 'STORE_CLIENTS',
  UPCOMING_CALENDER_LIST_SUCCESS = 'UPCOMING_CALENDER_LIST_SUCCESS',
  REQUEST_INTERACTIVE_CALENDER_LIST = 'REQUEST_INTERACTIVE_CALENDER_LIST',
  FILTER_UPDATE_VIDEO_LIST = 'FILTER_UPDATE_VIDEO_LIST',
  UPDATE_SEARCH_COUNT = 'UPDATE_SEARCH_COUNT',
  VIDEO_LIST_WITH_FILTERS_PAGINATION = 'VIDEO_LIST_WITH_FILTERS_PAGINATION',
  SEND_CAMPAIGN_EMAIL = 'SEND_CAMPAIGN_EMAIL',
  REQUEST_JOINED_CLIENTS_LIST = 'REQUEST_JOINED_CLIENTS_LIST',
  STORE_JOINED_CLIENTS_LIST = 'STORE_JOINED_CLIENTS_LIST',
}

export default ActionTypes;
