/*
 *
 * Concept constants
 *
 */

enum ActionTypes {
  REQUEST_CONCEPT_LIST = 'REQUEST_CONCEPT_LIST',
  CONCEPT_LIST_SUCCESS = 'CONCEPT_LIST_SUCCESS',
  CONCEPT_LIST_FAILED = 'CONCEPT_LIST_FAILED',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  GET_SEARCH_RESULT = 'app/concepts/GET_SEARCH_RESULT',
  FETCH_CONCEPT_BY_ID = 'FETCH_CONCEPT_BY_ID',
  STORE_CONCEPT_DETAIL = 'STORE_CONCEPT_DETAIL',
  SET_CONCEPT_LOADER = 'SET_CONCEPT_LOADER',
  SET_CONCEPT_PAGINATION_LOADER = 'SET_CONCEPT_PAGINATION_LOADER',
  SET_INTERACTIVE_CLASSES_ONLY = 'SET_INTERACTIVE_CLASSES_ONLY',

  // This Empty Details getting
  ADD_CONCEPT = 'ADD_CONCEPT',
  //
  ADD_CONCEPT_DETAIL_INFO = 'ADD_CONCEPT_DETAIL_INFO',
  ADD_TO_CONCEPT_DETAIL_INFO_SUCCESS = 'ADD_TO_CONCEPT_DETAIL_INFO_SUCCESS',
  ADD_TO_CONCEPT_DETAIL_INFO_FAILED = 'ADD_TO_CONCEPT_DETAIL_INFO_FAILED',

  EDIT_CONCEPT_DETAIL_INFO = 'EDIT_CONCEPT_DETAIL_INFO',
  EDIT_TO_CONCEPT_DETAIL_INFO_SUCCESS = 'EDIT_TO_CONCEPT_DETAIL_INFO_SUCCESS',
  EDIT_TO_CONCEPT_DETAIL_INFO_FAILED = 'EDIT_TO_CONCEPT_DETAIL_INFO_FAILED',

  SEARCH_VIDEO_BY_TITLE_OR_ID = 'SEARCH_VIDEO_BY_TITLE_OR_ID',
  SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS = 'SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS',
  SEARCH_VIDEO_BY_TITLE_OR_ID_FAILED = 'SEARCH_VIDEO_BY_TITLE_OR_ID_FAILED',

  CONCEPT_LIST_TOTAL = 'CONCEPT_LIST_TOTAL',
  REQUEST_CONCEPT_LIST_PAGINATION = 'REQUEST_CONCEPT_LIST_PAGINATION',
  CONCEPT_LIST_PAGINATION_SUCCESS = 'CONCEPT_LIST_PAGINATION_SUCCESS',
  PUT_CONCEPT_LIST_PAGINATION_SUCCESS = 'PUT_CONCEPT_LIST_PAGINATION_SUCCESS',

  RESET_CONCEPT = 'RESET_CONCEPT',
}

export default ActionTypes;
