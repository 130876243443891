import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import {
  conceptListSuccess,
  conceptListFailed,
  storeConceptDetailInfo,
  setConceptLoader,
  addConceptDetailInfoFailed,
  addConceptDetailInfoSuccess,
  addConcept,
  setSearchKey,
  searchVideosByTitleOrIDSuccess,
  editConceptDetailInfoFailed,
  editConceptDetailInfoSuccess,
  conceptListPaginationSuccess,
  conceptListCountSuccess,
  putConceptPagination,
  setConceptPaginationLoader,
} from './actions';
import { selectConceptDomain } from './selectors';
import { AnyAction } from 'redux';
import { initialState } from './reducer';
const SLICE_COUNT_OF_CLASSES = 50;

// Individual exports for testing
export default function* videoSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_CONCEPT_LIST, getVideoList);
  yield takeLatest(
    ActionTypes.REQUEST_CONCEPT_LIST_PAGINATION,
    getConceptListPagination,
  );
  yield takeLatest(ActionTypes.FETCH_CONCEPT_BY_ID, fetchConceptById);
  yield takeLatest(ActionTypes.ADD_CONCEPT_DETAIL_INFO, addConceptDetailInfo);
  yield takeLatest(
    ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID,
    searchVideosByTitleOrID,
  );
  yield takeLatest(ActionTypes.EDIT_CONCEPT_DETAIL_INFO, editConceptDetailInfo);
}

function arrayDefaultValue(mainArray, selecteArray, filed) {
  let arr = [];
  for (let k = 0; k < selecteArray.length; k++) {
    for (let i = 0; i < mainArray.length; i++) {
      if (mainArray[i].id == selecteArray[k]) {
        arr.push(mainArray[i]);
        break;
      }
    }
  }
  return arr;
}

export function setDefaultOption(result, isId) {
  let obj = {
    isLoading: false,
    data: {
      categories: [],
      collectionType: '',
      createdAt: new Date(),
      description: '',
      flag: '',
      gallery: [],
      genres: [],
      graphics: {
        '16x9': '',
        '16x9_trans': '',
        '1x1': '',
        '32x9': '',
        '9x16': '',
      },
      instructors: [],
      interactive: [],
      isHidden: false,
      isPublished: false,
      kicker: '',
      permaLink: '',
      publishedDate: new Date(),
      seriesID: '',
      title: '',
      updatedAt: new Date(),
      videos: {
        videoIds: [],
      },
    },
  };
  if (isId) {
    let defaultInstructors = arrayDefaultValue(
      result.instructors,
      result.data.instructors,
      'instructors',
    );
    let defaultGenres = arrayDefaultValue(
      result.genres,
      result.data.genres,
      'genres',
    );
    let defaultCategories = arrayDefaultValue(
      result.categories,
      result.data.categories,
      'categories',
    );
    result = {
      ...result,
      defaultInstructors: defaultInstructors,
      defaultGenres: defaultGenres,
      defaultCategories: defaultCategories,
    };
  } else {
    result = { ...result, ...obj };
  }
  return result;
}

export function* getVideoList() {
  try {
    // let { sortBy, activeTab, searchKey, isInteractiveFilterOn } = yield select(selectConceptDomain);
    const conceptState = yield select(selectConceptDomain);
    if (conceptState.sortBy === '') {
      conceptState.sortBy = 'updatedAt=desc';
    }
    const splitResult = conceptState.sortBy.split('=');
    const interactiveClasses = conceptState.isInteractiveFilterOn ? "interactive=true&" : '';
    let { result, total } = yield call(() =>
      request({
        url: `neouadmin/v1/concepts?${interactiveClasses}${conceptState.activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&keyword=${conceptState.searchKey}`,
        method: 'GET',
      }),
    );
    yield put(conceptListSuccess(result));
    yield put(conceptListCountSuccess(total));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(conceptListSuccess([]));
    }
    yield put(conceptListSuccess([]));
    yield put(conceptListFailed());
  }
}
export function* getConceptListPagination(action) {
  yield put(setConceptPaginationLoader(true));
  try {
    let { sortBy, activeTab, searchKey } = yield select(selectConceptDomain);
    if (sortBy === '') {
      sortBy = 'updatedAt=desc';
    }
    const splitResult = sortBy.split('=');

    let { result } = yield call(() =>
      request({
        url: `neouadmin/v1/concepts?${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&keyword=${searchKey}&offset=${action.payload}&limit=${SLICE_COUNT_OF_CLASSES}`,
        method: 'GET',
      }),
    );
    yield put(conceptListPaginationSuccess(result));
    yield delay(2000);
    yield put(putConceptPagination());
    yield put(conceptListPaginationSuccess([]));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(conceptListPaginationSuccess([]));
      yield put(setConceptPaginationLoader(false));
    }
    yield put(conceptListFailed());
    yield put(setConceptPaginationLoader(false));
  }
}

export function* fetchConceptById(action: AnyAction) {
  let id = action.payload;
  let url = {
    url: `/neouadmin/v1/concept-detail`,
  };
  if (id) {
    url['params'] = {
      id,
    };
  }
  yield put(setConceptLoader(true));
  try {
    let { result } = yield call(() => request(url));
    yield put(setConceptLoader(false));
    if (!id) {
      let newResult = setDefaultOption(result, false);
      yield put(addConcept(newResult));
    } else {
      let newResult = setDefaultOption(result, true);
      yield put(storeConceptDetailInfo(newResult));
    }
  } catch (error) {
    yield put(setConceptLoader(false));
  }
}

export function* addConceptDetailInfo(action: AnyAction) {
  let data = action.payload;
  let updataData = JSON.parse(JSON.stringify(data));
  dataBuild(updataData);
  //TODO Add Concept remove after manage gallery is finished
  updataData.gallery.push({
    type: 'image',
    url:
      'https://snagfilms-a.akamaihd.net/e92e55d5-a8e5-4267-bcf0-667e2b630d81/images/83/ad/dad1ac764735a07f7714dfc50eb8/1567786724908_1920x1080jumpstart_16x9Images.png',
  });
  updataData.collectionType = 'concept';

  yield put(setConceptLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/concepts`,
        method: 'POST',
        data: updataData,
      }),
    );
    yield put(setConceptLoader(false));
    // yield getVideoList();
    yield put(addConceptDetailInfoSuccess(result));
  } catch (error) {
    yield put(setConceptLoader(false));
    yield put(addConceptDetailInfoFailed(error));
  }
}

export function* editConceptDetailInfo(action: AnyAction) {
  let data = action.payload;
  let updataData = JSON.parse(JSON.stringify(data));
  dataBuild(updataData);
  yield put(setConceptLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/concepts`,
        method: 'PUT',
        data: updataData,
      }),
    );
    yield put(setConceptLoader(false));
    // yield getVideoList();
    yield put(editConceptDetailInfoSuccess(result));
    yield put(storeConceptDetailInfo(initialState));
  } catch (error) {
    yield put(setConceptLoader(false));
    yield put(editConceptDetailInfoFailed(error));
  }
}

export function* searchVideosByTitleOrID(action: AnyAction) {
  let key = action.payload;
  yield put(setConceptLoader(true));
  try {
    let { result, total } = yield call(() =>
      request({
        url: `/neouadmin/v1/search`,
        method: 'POST',
        data: {
          keyword: key,
        },
      }),
    );
    yield put(setConceptLoader(false));
    yield put(searchVideosByTitleOrIDSuccess(result));
    yield put(conceptListCountSuccess(total));
  } catch (error) {
    yield put(setConceptLoader(false));
    // yield put(addConceptDetailInfoFailed(error));
  }
}

function dataBuild(data) {
  objectTostring(data.instructors);
  objectTostring(data.genres);
  objectTostring(data.categories);
  objectTostring(data.videos.videoIds);
}

function objectTostring(data) {
  for (let i = 0; i < data.length; i++) {
    data[i] = data[i].id ? data[i].id : data[i];
  }
}
