import React, { useState } from 'react';
import SearchVideo from './SearchVideo';
import request from '../shared/lib/request';
import DraggableList from './DraggableList';
import { Divider, Typography } from '@material-ui/core';
import { getTimeAfterMinutes, UnivDate } from '../utils/calender';
import moment from 'moment';

interface VideoID {
  [key: string]: string;
}

export interface video {
  id: string;
  title: string;
  videoID: VideoID;
}

interface getSubTitleFunc {
  (): string;
}

interface SearchVideoComponentProps {
  videoList: video[];
  onVideoListChange: (newValue: video[], action: string) => void;
  id?: any;
  subTitle?: string | getSubTitleFunc;
  onChange?: (videoList) => void;
  searchingInteractiveClasses?: boolean;
}

export function FindVideo({
  videoList: defaultValue,
  subTitle = '',
  onChange = () => {},
  searchingInteractiveClasses: isInteractive = false,
}: SearchVideoComponentProps): React.ReactNode {
  const [isSearchResult, setSearchVideo] = useState([]);
  const [videos, setVideos] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [inputRef, setInputRef] = useState(null);
  let timeout;
  const searchHandleChange = value => {
    timeout = setTimeout(() => {
      setSearchText('');
      if (inputRef) inputRef.value = '';
    }, 1000);
    if (value) {
      setSearchText(value.title);
      setVideos([value, ...videos]);
      onChange([value, ...videos]);
    }
  };
  function searchVideos(data) {
    if (isInteractive) {
      return request({
        url: `/neouadmin/v1/search?isUpcoming=true&isCompleted=false`,
        method: 'POST',
        data: {
          keyword: data,
          interactive: {
            isPublished: true,
            isHidden: false,
          },
          videoType: ['groupInteractive'],
        },
      });
    } else {
      return request({
        url: `/neouadmin/v1/search`,
        method: 'POST',
        data: {
          keyword: data,
          isPublished: true,
          isHidden: false,
          videoType: ['class', 'generic'],
        },
      });
    }
  }
  const onSearchChange = async value => {
    try {
      let result = await searchVideos(value);
      setSearchVideo(result.result.classes.result);
    } catch (error) {
      setSearchVideo([]);
    }
  };

  React.useEffect(() => {
    setVideos(defaultValue);
    return () => {
      clearTimeout(timeout);
    };
  }, [defaultValue]);

  function getLastUpdateTime() {
    var momentTZ = require('moment-timezone');
    var zone = momentTZ.tz.guess();
    const lastUpdateTime = momentTZ.tz(zone).format('z');
    return lastUpdateTime;
  }

  function getTime(startDate, duration) {
    let time;
    if (startDate && duration) {
      time =
        moment(UnivDate(startDate))
          .utc()
          .format('ddd, MMM D') +
        ' • ' +
        moment(UnivDate(startDate))
          .utc()
          .format('LT') +
        ' ' +
        getTimeAfterMinutes(startDate, duration) +
        ' ' +
        getLastUpdateTime();
    } else {
      time =
        moment(UnivDate(startDate))
          .utc()
          .format('ddd, MMM D') +
        ' • ' +
        moment(UnivDate(startDate))
          .utc()
          .format('LT') +
        ' ' +
        getLastUpdateTime();
    }
    return time;
  }

  return (
    <div style={{ width: '100%' }}>
      {isInteractive ? (
        <SearchVideo
          options={isSearchResult || []}
          name="searchVideos"
          inputValue={searchText}
          onChange={(event, value) => searchHandleChange(value)}
          limitTags={2}
          onInputChange={(event, value) => onSearchChange(value)}
          getOptionLabel={option =>
            `${option.title} at ${getTime(
              option.startDate,
              option.duration || 0,
            )}`
          }
          renderOption={option => (
            <div
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                lineHeight: '15px',
                fontFamily: 'Proxima-Nova-Regular',
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: '16px',
                  lineHeight: '15px',
                }}
              >
                {option.title}{' '}
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontSize: '15px',
                  color: '#909599',
                }}
              >
                {' at '} {getTime(option.startDate, option.duration || 0)}
              </Typography>
            </div>
          )}
          onSearchTextChange={e => setSearchText(e)}
          ref={inputRef}
          setInputRef={ref => setInputRef(ref)}
          blurOnSelect
          clearOnBlur
        />
      ) : (
        <SearchVideo
          options={isSearchResult || []}
          name="searchVideos"
          inputValue={searchText}
          onChange={(event, value) => searchHandleChange(value)}
          limitTags={2}
          onInputChange={(event, value) => onSearchChange(value)}
          getOptionLabel={option => option.title}
          onSearchTextChange={e => setSearchText(e)}
          ref={inputRef}
          setInputRef={ref => setInputRef(ref)}
          blurOnSelect
          clearOnBlur
        />
      )}
      <Divider style={{ backgroundColor: '#999FA4', marginBottom: 8 }} />
      <DraggableList
        list={videos}
        isInteractive={isInteractive}
        onListChange={(list, updateType) => {
          setVideos(list);
          onChange(list);
        }}
        subTitle={subTitle}
      />
    </div>
  );
}
