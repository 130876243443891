import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxShadow: 'none',
    },
    title: {
      float: 'left',
      fontSize: '16px',
      fontFamily: 'ProximaNova-Bold',
      color: '#12161A',
    },
    basecolor: {
      color: '#12161A',
      boxShadow: 'none',
    },
    line: {
      borderBottom: '1px solid #E3E3E4',
    },
    // fluid: {
    //   width: '100%',
    //   maxHeight: '200px',
    //   overflow: 'auto',
    // },
    details: {
      flexDirection: 'column',
      padding: '0',
    },
    noPadd: {
      paddingLeft: '0',
    },
  }),
);

export default function Accordian(props) {
  const classes = useStyles();
  const { title } = props;
  return (
    <div className={classes.root}>
      <ExpansionPanel className={classes.basecolor}>
        <ExpansionPanelSummary
          className={classes.noPadd}
          expandIcon={<ArrowDropDownIcon className={classes.basecolor} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.title}>{props.title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <div className={classes.fluid}>{props.children}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <div className={classes.line} />
    </div>
  );
}
