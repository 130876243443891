import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import {
  brandVoiceListSuccess,
  brandVoiceListFailure,
  requestbrandVoice,
} from './actions';
import { openSnackBar } from '../Root/actions';

// Individual exports for testing
export default function* brandVoiceSaga() {
  yield takeLatest(ActionTypes.REQUEST_BRAND_VOICE, getBrandVoice);
  yield takeLatest(ActionTypes.UPDATE_BRAND_VOICE, updateBrandVoice);
}

export function* getBrandVoice() {
  try {
    let { result } = yield call(() =>
      request({
        url: 'neouadmin/v1/brand-voice',
        method: 'GET',
      }),
    );
    yield put(brandVoiceListSuccess(result));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(brandVoiceListSuccess({}));
    }
    yield put(brandVoiceListFailure());
  }
}

export function* updateBrandVoice(action) {
  try {
    let { result } = yield call(() =>
      request({
        url: 'neouadmin/v1/brand-voice',
        method: 'PUT',
        data: action.payload,
      }),
    );
    yield put(requestbrandVoice());
    yield put(openSnackBar('Component has been updated successfully!'));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(brandVoiceListSuccess({}));
    }
    yield put(brandVoiceListFailure());
  }
}
