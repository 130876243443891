/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './utils/history';
import languageProviderReducer from './containers/LanguageProvider/reducer';
import appReducer from './containers/Root/reducer';
import peopleReducer from './containers/People/reducer';
import conceptReducer from './containers/Concept/reducer';
import authenticationReducer from './containers/Authentication/reducer';
import homePageReducer from './containers/HomeCurate/reducer';
import browsePageReducer from './containers/BrowseCurate/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    router: connectRouter(history),
    root: appReducer,
    authentication: authenticationReducer,
    people: peopleReducer,
    concept: conceptReducer,
    homeCurate: homePageReducer,
    browseCurate: browsePageReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
