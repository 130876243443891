import * as React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import styled from 'styled-components';
import logo from '../../images/neou@2x.png';
import { encrypt } from '../../utils/crypto';
import { KEY_BASE64 } from '../../utils/config';
import PasswordField from '../../components/PasswordField';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useStyles } from './style';
import { useInjectReducer } from '../../utils/injectReducer';

export interface IResetPasswordProps {}

const Image = styled.img`
  color: #ffffff;
`;

export default function ResetPassword(props: IResetPasswordProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={12} className={classes.root}>
          <div className={classes.imgWrapper}>
            <Image src={logo} className={classes.img} />
            <div className={classes.admin}>Admin</div>
          </div>
          <div className={classes.description}>
            Currently, You have no permissions for any of the modules.
            <br /> Request an administrator for more permission
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
