import * as React from 'react';
import { FindVideo, video } from '../FindVideo';
import { Field, ErrorMessage } from 'formik';
import { ErrorText } from '../Typography';
import { getUniqItems } from '../../utils/array';

interface FormikSearchVideoProps {
  name: string;
  videoList: video[];
  handleChange: () => void;
  searchingInteractiveClasses: boolean;
}

const FormikSearchVideo: React.FC<FormikSearchVideoProps> = ({
  name,
  videoList,
  handleChange,
  searchingInteractiveClasses
}) => {
  return (
    <Field>
      {({ field, form, meta }) => {
        return (
          <>
            <FindVideo
              videoList={videoList}
              onChange={list => {
                let uniqItems = getUniqItems(list, 'id');
                form.setFieldValue(name, uniqItems);
                form.setFieldTouched(name, true, false);
                handleChange && handleChange(uniqItems);
              }}
              subTitle={() => {}}
              searchingInteractiveClasses={searchingInteractiveClasses}
            />
            <ErrorText>
              <ErrorMessage name={name} />
            </ErrorText>
          </>
        );
      }}
    </Field>
  );
};

export default FormikSearchVideo;
