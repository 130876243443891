import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the Authentication state domain
 */

const authenticationState = (state: RootState) => {
  return state.authentication || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Authentication
 */

const makeSelectAuthentication = () =>
  createSelector(
    authenticationState,
    substate => {
      return substate;
    },
  );

export default makeSelectAuthentication;
export { authenticationState };
