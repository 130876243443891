import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { generateDays } from '../../utils/calender';
import { AnyAction } from 'redux';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import {
  storeClass,
  setWeeks,
  setActiveWeek,
  setActiveWeekInfo,
  setDates,
  setLoading,
} from './actions';
import { DateTime } from 'luxon';
// Individual exports for testing
export default function* instructorMyClassesSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_MY_CLASSES, getMyClasses);
}

// get
export function* getMyClasses(action: AnyAction) {
  try {
    let timezone = DateTime.local().zoneName;
    const instrId = action.payload;
    if (instrId) {
      yield put(setLoading(true));
      // 1. Fetch data from API
      let { result } = yield call(() =>
        request({
          url: `/neouadmin/v1/instructor/get-classes?instructorId=${instrId}&isCancelled=false&isPublished=true&isHidden=false&timeZone=${timezone}`,
          method: 'GET',
        }),
      );
      let calenderGroup = generateDays(2);
      // 2. Fill the missing date in between
      // 3. Fill the missing date to form a week
      let newResult = {
        ...calenderGroup,
        ...result,
      };
      // newResult = yield fillDaysToWeek(newResult);
      // 4. Group dates by week
      const weekGroups: any = groupArrayByLimit(newResult, 14);
      const classCount = getClassCount(weekGroups);
      yield put(storeClass(weekGroups, classCount));
      const weekLabels = Object.keys({ ...weekGroups });
      const activeWeek = weekLabels.length > 0 ? weekLabels[0] : 'week_0';
      // set weeks
      yield put(setWeeks(weekLabels));

      yield call(() => moveToWeek(weekGroups, activeWeek));
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(setLoading(false));
    if (error) {
    }
  }
}

function getClassCount(weekGroups) {
  var count = 0;
  try {
    Object.keys(weekGroups).forEach((weekGroup, n) => {
      if (weekGroups[weekGroup] && Object.keys(weekGroups[weekGroup])) {
        let days = Object.keys(weekGroups[weekGroup]);
        days.forEach(day => {
          count += Object.keys(weekGroups[weekGroup][day]).length;
        });
      }
    });
  } catch (error) {
  } finally {
    return count;
  }
}

function* moveToWeek(weekGroups, activeWeek) {
  if (!activeWeek) {
    return;
  }
  //  set Active week
  yield put(setActiveWeek(activeWeek));
  const activeWeekInfo = weekGroups[activeWeek];
  const activeWeekDates = Object.keys(activeWeekInfo);

  // set active week info
  yield put(setActiveWeekInfo(activeWeekInfo));

  // set active week info dates
  yield put(setDates(activeWeekDates));
}

function groupArrayByLimit(result, limit) {
  try {
    const list = result
      ? Object.keys(result).sort((a, b) => (a > b ? 1 : -1))
      : [];
    const count = list.length;
    const weeks = {};
    for (let i = 0; i < 1; i++) {
      const key = `week_${i}`;
      weeks[key] = {};

      const start = i * limit;
      const end = i * limit + limit > count ? count : i * limit + limit;

      for (let day = start; day < end; day++) {
        const dayKey = list[day];
        weeks[key][dayKey] = result[dayKey];
      }
    }
    return weeks;
  } catch (error) {
    return {};
  }
}
