import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tag from '../components/Tag';
import { Label1, H5, Body3, Body2 } from '../styles/Typography';
import { SvgBookmark, SvgBookmarkFilled } from '../components/NeIcons/index';
import { IconButton, Box, Avatar } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import makeSelectRoot from '../containers/Root/selectors';
import { Cookies } from 'react-cookie';

export interface IClassCardProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #35414A',
      '& .MuiCard-root': {
        display: 'flex',
        backgroundColor: '#12161A',
        padding: '18px 0px',
        [theme.breakpoints.between('xs', 'sm')]: {
          padding: '14px 16px',
        },
      },
      '& .MuiCardActionArea-root': {
        maxWidth: '132px',
      },
      '& .MuiCardMedia-root': {
        width: '100%',
        height: 'auto',
        minHeight: '74px',
      },
      '& .MuiCardContent-root': {
        padding: '8px',
        width: '100%',
      },
      '& .MuiSvgIcon-root': {
        margin: '8px',
      },
    },
    title: {
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
    },
    classBody: {
      display: 'flex',
      paddingLeft: '15px',
      flex: 1,
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    bookMarkIcon: {
      padding: '0px 16px',
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '4px 0px 4px',
      },
    },
    coceptName: {
      color: '#CDD3D8',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '12px',
      lineHeight: '17px',
      [theme.breakpoints.up('xl')]: {
        fontSize: '16px',
        lineHeight: '18px',
      },
    },
    videoThumbnailAvatar: {
      width: '100%',
      height: 'auto',
      paddingTop: '56.25%',
      position: 'relative',
      borderRadius: 'unset',
      '& img': {
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
      },
    },
  }),
);
const stateSelector = createStructuredSelector({
  root: makeSelectRoot(),
});

export default function ClassCard({ item }) {
  let {
    genres,
    duration,
    thumbnail,
    flag,
    pageTitle,
    permaLink,
    concept,
    id,
    title,
    isAddedToList,
  } = item;

  let genresTitle = genres && genres.length > 0 && genres[0].title;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card>
        <CardActionArea>
          <Avatar
            aria-label="recipe"
            classes={{
              root: classes.videoThumbnailAvatar,
            }}
            src={thumbnail}
            title={title}
            alt={title}
          >
            <img src={require('../images/16_9 Image Placeholder.svg')} />
          </Avatar>
          <Tag content={flag} isContent={true} />
        </CardActionArea>
        <div className={classes.classBody}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
          >
            <div style={{ flex: '1' }}>
              <Label1 color="#CDD3D8">
                {duration}M • {genresTitle}
              </Label1>
              <div className={classes.titleWrapper}>
                <H5 className={classes.title}>{title}</H5>
                <IconButton className={classes.bookMarkIcon}>
                  {false ? <SvgBookmarkFilled /> : <SvgBookmark />}
                </IconButton>
              </div>
              <div className={classes.coceptName}>
                {concept &&
                  concept.length > 0 &&
                  concept[0] &&
                  concept[0].title}
              </div>
            </div>

            <Body2 color="#CDD3D8">{pageTitle}</Body2>
          </Box>
        </div>
      </Card>
    </div>
  );
}
