import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const focusedColor = '#060B0F';
const defaultColor = '#909599';
const labelColor = '#909599';
const inputHoverColor = '#FFFFFF';
const errorColor = '#FF1C58';
const borderColor = ' #909599';

const LabelInput = withStyles({
  root: {
    minHeight: 76,
    width: '100%',
    '& label.MuiInputLabel-outlined': {
      color: defaultColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& input': {
      fontSize: '16px',
      color: '#222A31',
      '&::placeholder': {
        color: '#8C949B',
        fontStyle: 'italic',
      },
    },
    '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 16px) scale(1)',
      color: labelColor,
      fontSize: '16px',
    },

    '& label.Mui-focused': {
      color: labelColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.Mui-focused.Mui-error': {
      color: errorColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: borderColor,
    },
    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: '6px',
      '& fieldset': {
        borderColor: borderColor,
      },
      '&:hover fieldset': {
        borderColor: borderColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${borderColor}`,
      },
    },
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        color: focusedColor,
        padding: '14.5px 14px',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      height: 48,
      borderRadius: '6px',
      '& fieldset': {
        borderColor: errorColor,
      },
      '&:hover fieldset': {
        color: defaultColor,
        borderColor: errorColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${errorColor}`,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '4px',
      '&.Mui-error': {
        color: errorColor,
      },
    },
  },
})(TextField);

export default LabelInput;
