import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  whiteColor,
  blackColor,
  hexToRgb,
} from '../../assets/jss/globalStyles';

const sidebarStyle = theme => ({
  drawerPaper: {
    backgroundColor: '#080B0D',
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: 1000,
    ...boxShadow,
    width: drawerWidth,
    maxWidth: '266px',
    minWidth: 200,
    padding: '0 18px',
    [theme.breakpoints.down('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      right: 'auto',
      left: '0',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(-${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },

  InstBrand: {
    position: 'relative',
    padding: '44px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: '4',

    '& > img': {
      maxWidth: 108,
    },
    '& > .MuiButtonBase-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  brand: {
    position: 'relative',
    padding: '44px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: '4',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: 0,
      left: 0,
      width: '100%',
      backgroundColor: '#222A31',
    },
    '& > img': {
      maxWidth: 108,
    },
    '& > .MuiButtonBase-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  logoLink: {
    ...defaultFont,
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: whiteColor,
    },
  },
  logoLinkRTL: {
    textAlign: 'right',
  },
  logoImage: {
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '10px',
    marginRight: '15px',
  },
  img: {
    width: '35px',
    top: '22px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    '&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      background: blackColor,
      opacity: '.8',
    },
  },
  list: {
    minWidth: '100%',
    margin: 'auto',
    padding: '8px 0px',
    paddingLeft: '0',
    listStyle: 'none',
    position: 'unset',
    borderBottom: '1px solid #222A31',
  },
  signOutLink: {
    borderBottom: 'none',
  },
  signOutLinkText: {
    textTransform: 'Capitalize',
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    maxWidth: drawerWidth,
    margin: 'auto',
    '&:hover,&:focus,&:visited,&': {
      color: whiteColor,
    },
  },
  itemLink: {
    width: 'auto',
    transition: 'all 300ms linear',
    position: 'relative',
    display: 'block',
    padding: '14px 15px',
    backgroundColor: 'transparent',
    ...defaultFont,
    '&:hover > *': {
      color: 'white',
    },
  },
  itemIcon: {
    width: '24px',
    height: '20px',
    fontSize: '20px',
    lineHeight: '24px',
    float: 'left',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: '#8B949B',
  },
  itemText: {
    ...defaultFont,
    margin: '0',
    lineHeight: '22px',
    fontSize: '11px',
    color: '#8B949B',
    textTransform: 'Uppercase',
    letterSpacing: '0.6px',
  },
  itemTextRTL: {
    textAlign: 'right',
  },
  whiteFont: {
    color: whiteColor,
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'auto',
    overflow: 'auto',
    width: '100%',
    zIndex: '4',
    overflowScrolling: 'touch',
    '& .MuiTreeView-root': {
      borderBottom: '1px solid #272B2E',
    },
  },
  activeRouteText: {
    color: '#56FFFE',
  },
  userAvatarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '22px 4px',
    borderBottom: '1px solid #272B2E',
  },
  userAvatarName: {
    fontFamily: 'Proxima-Nova-Regular',
    color: '#fff',
    marginLeft: 8,
    textOverflow: 'ellipsis',
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: '120px',
  },
  userAvatar: {
    display: 'flex',
    alignItems: 'center',
  },
  userAvatarRoot: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Proxima-Nova-Regular',
    color: '#000',
    background: '#fff',
    textTransform: 'uppercase',
  },
  signOutText: {
    color: '#909599',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'Proxima-Nova-Regular',
  },
  profileSmallText: {
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'left',
    maxWidth: '100px',
    margin: '8px 0',
    marginLeft: '10px',
  },
  brandSmall: {
    display: 'block',
    padding: '15px 15px 30px 15px',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: 0,
      left: 0,
      width: '100%',
      backgroundColor: '#222A31',
    },
  },
  InstSignOutText: {
    color: '#909599',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'Proxima-Nova-Regular',
    padding: '40px 0 0 85px',
    ['@media (max-width:768px)']: {
      padding: '40px 0 0 110px',
    },
    ['@media (min-width:768px)']: {
      padding: '40px 0 0 0',
    },
    ['@media (min-width:1024px)']: {
      padding: '40px 0 0 85px',
    },
  },
});

export default sidebarStyle;
