/*
 *
 * Members
 *
 */

import React, { memo, useCallback, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { withStyles, createStyles } from '@material-ui/styles';

import debounce from 'lodash.debounce';
import {
  WithStyles,
  makeStyles,
  Theme,
  InputAdornment,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { isEmpty } from 'lodash';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectMembers from './selectors';
import reducer from './reducer';
import saga from './saga';
import { SvgIconSearch } from '../../components/NeIcons';
import NEInput from '../../components/NEInput';
import { getSubscriptions } from './actions';

import request from '../../shared/lib/request';
import { MembersTable } from './components/MembersTable';

const stateSelector = createStructuredSelector({
  members: makeSelectMembers(),
});

export interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '50px 20px',
    },
    headWrap: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 0px 12px 0px',
      width: '100%',
      flexWrap: 'wrap',
      borderBottom: '1px solid #000000',
    },
    upperHeaderSection: {
      width: '100%',
      justifyContent: 'space-between',
      display: 'flex',
    },
    head: {
      color: '#12161A',
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    searchWrap: {
      '& .MuiFormControl-root': {
        minWidth: '240px',
        fontFamily: 'Proxima-Nova-Regular',
        minHeight: '38px',
        height: '38px',
      },
      '& .MuiInputBase-root': {
        maxWidth: '240px',
        width: '241px',
        boxSizing: 'border-box',
        fontFamily: 'Proxima-Nova-Regular',
        paddingLeft: '8px',
        height: '38px',
        '& .MuiInputBase-input': {
          color: '#060B0F !important',
          fontFamily: 'Proxima-Nova-Regular',
          fontSize: '16px',
          fontWeight: 500,
          letterSpacing: 0,
          lineHeight: '20px',
          padding: '0px !important',
        },
      },
    },
    lowerHeaderSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 0px 0px 0px',
      position: 'relative',
      '& .MuiLinearProgress-root': {
        position: 'absolute',
        top: 0,
      },
    },
    count: {
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '70px',
    },
    countLabel: {
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '20px',
      textTransform: 'uppercase',
    },
    memberCountBox: {
      padding: '150px 0',
    },
  }),
);

function Members(props: Props) {
  const dispatch = useDispatch();
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'members', reducer });
  useInjectSaga({ key: 'members', saga });

  const [searchKey, setSearchKey] = useState('');
  const [membersList, setMembersList] = useState([]);
  const [count, setCount] = useState({
    totalMembers: 0,
    subscribedMembers: 0,
  });
  const [isListShown, showList] = useState(false);
  const [isSearching, setSearchLoader] = useState(false);

  const classes = useStyles(props);

  useEffect(() => {
    _init();
    return () => {};
  }, []);

  const _init = async () => {
    try {
      const { total: totalMembers, subscribedMembers } = await request({
        url: `/neouadmin/v1/members/count`,
        method: 'GET',
      });
      setCount({ totalMembers, subscribedMembers });
      // dispatch(getSubscriptions());
    } catch (error) {
      setCount({ totalMembers: 0, subscribedMembers: 0 });
    }
  };

  const _searchMembers = async (
    e: React.SyntheticEvent<HTMLLinkElement>,
    text = '',
  ) => {
    if (e) e.preventDefault();
    showList(true);
    setSearchLoader(true);
    try {
      const res = await request({
        url: `/neouadmin/v1/members/search`,
        method: 'GET',
        params: { keyword: text || searchKey },
      });
      setMembersList(res.result || []);
    } catch (error) {
      setMembersList([]);
    } finally {
      setSearchLoader(false);
    }
  };

  const debouncedGet = useCallback(
    debounce(textValue => {
      _searchMembers(null, textValue);
    }, 1500),
    [], // will be created only once initially
  );

  const _setMemberSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value);
    debouncedGet(e.target.value);
  };

  const _renderMemberCount = () => (
    <Grid container className={classes.memberCountBox} spacing={3}>
      <Grid item xs>
        <Typography className={classes.count} align="center">
          {count.totalMembers}
        </Typography>
        <Typography className={classes.countLabel} align="center">
          Registered Users
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography className={classes.count} align="center">
          {count.subscribedMembers}
        </Typography>
        <Typography className={classes.countLabel} align="center">
          ACTIVE SUBSCRIBERS
        </Typography>
      </Grid>
    </Grid>
  );

  const _renderSearchResults = () => (
    <>
      <MembersTable
        list={membersList}
        searchKey={searchKey}
        listRefresh={_searchMembers}
      />
    </>
  );

  return (
    <div>
      <Helmet>
        <title>Members</title>
        <meta name="description" content="Description of Members" />
      </Helmet>
      <div className={classes.root}>
        <div className={classes.headWrap}>
          <div className={classes.upperHeaderSection}>
            <div className={classes.head}>Member Lookup</div>
            <div className={classes.searchWrap}>
              <SearchInput
                handleSearchText={_setMemberSearchText}
                handleSearch={_searchMembers}
              />
            </div>
          </div>
        </div>

        <div className={classes.lowerHeaderSection}>
          {isSearching ? (
            <LinearProgress />
          ) : isListShown ? (
            isEmpty(membersList) ? (
              <h1>No Result</h1>
            ) : (
              _renderSearchResults()
            )
          ) : (
            _renderMemberCount()
          )}
        </div>
      </div>
    </div>
  );
}

// Partial components
const SearchInput = ({ handleSearchText, handleSearch }) => (
  <form onSubmit={handleSearch}>
    <NEInput
      label="Search"
      variant="outlined"
      id="custom-css-outlined-input"
      fullWidth
      onChange={handleSearchText}
      name="searchMembers"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button onClick={handleSearch}>
              <SvgIconSearch />
            </Button>
          </InputAdornment>
        ),
      }}
    />
  </form>
);

export default memo(Members);
