/*
 *
 * Concept constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/Video/DEFAULT_ACTION',

  REQUEST_ACHIEVEMENT_LIST = 'REQUEST_ACHIEVEMENT_LIST',
  ACHIEVEMENT_LIST_SUCCESS = 'ACHIEVEMENT_LIST_SUCCESS',
  ACHIEVEMENT_LIST_FAILED = 'ACHIEVEMENT_LIST_FAILED',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  GET_SEARCH_RESULT = 'GET_SEARCH_RESULT',
  FETCH_ACHIEVEMENT_BY_ID = 'FETCH_ACHIEVEMENT_BY_ID',
  EDIT_ACHIEVEMENT_DETAIL_INFO = 'EDIT_ACHIEVEMENT_DETAIL_INFO',
  STORE_ACHIEVEMENT_DETAIL = 'STORE_ACHIEVEMENT_DETAIL',
  REQUEST_ACHIEVEMENT_DETAIL = 'REQUEST_ACHIEVEMENT_DETAIL',
  ACHIEVEMENT_UPDATED_INFO = 'ACHIEVEMENT_UPDATED_INFO',
  ACHIEVEMENT_DETAIL_SUCCESS = 'ACHIEVEMENT_DETAIL_SUCCESS',
  ACHIEVEMENT_DETAILS_LOADING = 'ACHIEVEMENT_DETAILS_LOADING',
  PROGRAM_CHALLENGE_LIST = 'PROGRAM_CHALLENGE_LIST',
  GENRES_LIST = 'GENRES_LIST',
  ACHIEVEMENT_DELETE_INFO = 'ACHIEVEMENT_DELETE_INFO',
  DELETE_ACHIEVEMENT_INFO_SUCCESS = 'DELETE_ACHIEVEMENT_INFO_SUCCESS',
  DELETE_ACHIEVEMENT_INFO_FAILED = 'DELETE_ACHIEVEMENT_INFO_FAILED',
  REQUEST_OTHER_DETAILS = 'REQUEST_OTHER_DETAILS',
  OTHER_DETAIL_SUCCESS = 'OTHER_DETAIL_SUCCESS',
  POST_ADD_ACHIEVEMENT = 'POST_ADD_ACHIEVEMENT',
  ACHIEVEMENT_LOADING = 'ACHIEVEMENT_LOADING',
  ADD_ACHIEVEMENT = 'ADD_ACHIEVEMENT',

  ACHIEVEMENT_LIST_TOTAL = 'ACHIEVEMENT_LIST_TOTAL',
  REQUEST_ACHIEVEMENT_LIST_PAGINATION = 'REQUEST_ACHIEVEMENT_LIST_PAGINATION',
  ACHIEVEMENT_LIST_PAGINATION_SUCCESS = 'ACHIEVEMENT_LIST_PAGINATION_SUCCESS',

  RESET_ACHIEVEMENT = 'RESET_ACHIEVEMENT',
}

export default ActionTypes;
