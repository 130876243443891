import React from 'react';
import { SvgFavorite } from './NeIcons';
import IconButton from '@material-ui/core/IconButton';
import { createStructuredSelector } from 'reselect';
import { Cookies } from 'react-cookie';

export enum FavoriteType {
  Concept = 'concept',
  Instructor = 'instructor',
}

export interface IFavoriteBtn {
  id: string;
  title?: string;
  type: FavoriteType;
  isFavorited: boolean;
  permaLink?: string;
}
const stateSelector = createStructuredSelector({
  // root: makeSelectRoot(),
});

export function FavoriteBtn({ classes }: { classes: any }) {
  return (
    <>
      <IconButton className={classes}>
        <SvgFavorite color="#fff" />
      </IconButton>
    </>
  );
}
