import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // container:{
    //   borderBottom: '2px solid #050606',
    // },
    videoScrollWrap: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '16px',
      maxHeight: '640px',
      overflowY: 'auto',
      marginBottom: '24px',
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '14px 16px',
        marginBottom: '0',
        marginTop: '0',
      },
    },
    slider: {
      borderBottom: '1px solid #222A31',
      padding: '0 16px',
      boxSizing: 'border-box',
      paddingBottom: '25px',
      [theme.breakpoints.up('md')]: {
        borderBottom: 'none',
        padding: '0',
      },
    },
    miniConceptImg: {
      width: '104px',
      height: 'auto',
      borderRadius: '5px',
      backgroundColor: 'transparent',
      marginRight: '8px',
      '& img': {
        width: '100%',
      },
    },
    classHeadWrapper: {
      boxSizing: 'border-box',
      padding: '13px 0px',
      display: 'block',
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '13px 16px',
      },
    },
    subHead: {
      color: '#FFFFFF',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '17px',
      lineHeight: '18px',
      borderBottom: '2px solid #222A31',
      paddingBottom: '25px',
    },
    classWrapper: {
      maxHeight: 'calc(100vh - 180px)',
      overflow: 'auto',
    },
    emptyClass: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '240px',

      color: 'rgba(140,148,155,1)',
      '& svg': {
        width: '67px',
        height: '60px',
        marginBottom: '24px',
      },
      '& p': {
        fontStyle: 'italic',
        textAlign: 'center',
        maxWidth: '280px',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Proxima-Nova-Regular',
      },
    },
    videoThumbanil: {
      position: 'relative',
      '& .MuiIconButton-root': {
        position: 'absolute',
        top: 'calc(50% - 32px)',
        left: 'calc(50% - 32px)',
        width: '64px',
        height: '64px',
        padding: 0,
        [theme.breakpoints.down('md')]: {
          position: 'absolute',
          top: 'calc(50% - 24px)',
          left: 'calc(50% - 24px)',
          width: '48px',
          height: '48px',
        },
        zIndex: 1,
        cursor: 'pointer',
      },
    },
    AboutConcept: {
      [theme.breakpoints.up('md')]: {
        borderBottom: 'none',
        '& .MuiExpansionPanelSummary-root': {
          padding: 0,
        },
        '& .MuiExpansionPanelDetails-root': {
          padding: '0px 0px 16px',
        },
      },
      borderBottom: '1px solid #222A31',
      boxSizing: 'border-box',
    },
    headerWrap: {
      maxWidth: '1200px',
      margin: 'auto',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        padding: '0 24px',
      },
    },
    classHeadWrapper: {
      boxSizing: 'border-box',
      borderBottom: '1px solid #222A31',
      padding: '13px 0px',
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '13px 16px',
      },
    },
    filterMargin: {
      marginRight: '8px',
    },
    filterWrapper: {
      marginRight: '8px',
      height: '32px',
      width: '89px',
      borderRadius: '6px',
      backgroundColor: '#222A31',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
    },
    youMayLikeHead: {
      color: '#56FFFE',
      fontFamily: 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      textTransform: 'capitalize',
      [theme.breakpoints.up('xl')]: {
        fontSize: '20px',
        paddingBottom: '20px',
      },
    },
    classesScroll: {
      maxHeight: '60vh',
      overflowY: 'auto',
      scrollBehavior: 'smooth',
      [theme.breakpoints.up('xl')]: {
        maxHeight: '100vh',
      },
    },
    paper: {
      // padding: theme.spacing(2),
      textAlign: 'center',
      // color: theme.palette.text.secondary,
      // backgroundColor: 'aqua',
    },
    conceptRoot: {
      width: '100%',
    },
    container: {
      borderBottom: '2px solid #050606',
    },
    conceptItem: {
      width: '100%',
      [theme.breakpoints.up('xl')]: {
        marginTop: '26px',
      },
      borderBottom: '2px solid #050606',
      padding: '16px',
    },
    conceptBody: {
      maxWidth: '1200px',
      margin: 'auto',
    },
    leftPanel: {
      padding: '16px 0 0',
      [theme.breakpoints.up('md')]: {
        padding: '24px 12px 8px 32px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '32px 16px 22px 32px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '32px 20px 28px 32px',
      },
    },
    rightPanel: {
      [theme.breakpoints.up('md')]: {
        padding: '16px 24px 16px 12px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '22px 32px 22px 16px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '28px 20px 28px 20px',
      },
    },
    readOnly: {
      pointerEvents: 'none',
    },
    load: {
      margin: 'auto',
      display: 'flex',
      padding: '16px 0px 8px 0px;',
      [theme.breakpoints.only('xl')]: {
        padding: '32px 0px 24px 0px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '28px 0px 22px 0px',
      },
      '& img': {
        width: '28px',
        height: '28px',
      },
    },
    dayTitle: {
      color: '#fff',
      textTransform: 'capitalize',
      fontSize: '16px',
      lineHeight: '20px',
      margin: 0,
    },
    weekSelector: {
      borderTop: '1px solid #222A31',
      borderBottom: '1px solid #222A31',
      minHeight: '44px',
    },
    daySelector: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '14px 16px',
      borderBottom: '1px solid #222A31',
      [theme.breakpoints.up('md')]: {
        padding: '14px 0',
      },
      [theme.breakpoints.up('md')]: {
        padding: '23px 0',
      },
    },
    dayTitleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: '17px',
      marginTop: '13px',
      [theme.breakpoints.up('md')]: {
        paddingLeft: 0,
      },
    },
    restDayTitleWrapper: {
      paddingRight: '16px',
      borderBottom: '1px solid #222A31',
      margin: '0',
      padding: '12px 0px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '17px',
      },
    },
    restDay: {
      color: '#8C949B',
      fontFamily: 'Proxima-Nova-Regular, Helvetica, Arial, sans-serif',
      fontSize: '12px',
      lineHeight: '20px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('xl')]: {
        fontSize: '15px',
        lineHeight: '24px',
      },
    },
    relatedPrograms: {
      borderTop: '2px solid #050606',
      padding: '20px 16px',
      '& .MuiTypography-body1': {
        marginBottom: '14px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '24px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '32px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '32px',
        maxWidth: '1200px',
        margin: 'auto',
      },
    },
    ctaWrapper: {
      position: 'fixed',
      bottom: '0px',
      padding: '16px',
      background: '#000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: '1px solid #222A31',
      zIndex: '10',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '84%',
      },
      [theme.breakpoints.up('xl')]: {
        width: 'calc(100% - 300px)',
        maxWidth: '100%',
      },
    },
    cta: {
      width: '288px',
      textAlign: 'center',
      '& button': {
        width: '290px',
        [theme.breakpoints.up('md')]: {
          width: '290px',
        },
        [theme.breakpoints.up('xl')]: {
          width: '380px',
        },
      },
    },
  }),
);
