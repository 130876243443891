import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// import other assets
import { Grid } from '@material-ui/core';
import NEInput from '../NEInput';
import { SvgDelete, SvgIconAdd, SvgCross } from '../../components/NeIcons';
import clsx from 'clsx';
import Drag from './Drag';
import { addPhoto, deletePhoto, addVideo } from '../../services/s3';
import { StyledSpinnerNext } from 'baseui/spinner';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#000',
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dividers: {
    borderTop: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const dialogStyles = (theme: Theme) =>
  createStyles({
    dialogContainer: {
      borderRadius: 0,
      minWidth: '464px',
    },
    headWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 0px',
    },
    addbtnWrap: {
      display: 'flex',
      width: '340px',
      justifyContent: 'space-between',
    },
    newPeople: {
      paddingLeft: '8px',
    },
    dividers: {
      borderTop: '1px solid #D9D9D9',
      paddingLeft: '16px',
    },
    btnWidth: {
      width: '100%',
    },
    gridBox: {
      border: '1px solid #E3E3E4',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
      padding: '11px',
      boxSizing: 'border-box',
    },
    inputHidden: {
      display: 'none',
    },
    imagePath: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    imgBoxGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imgUrl: {
      borderRadius: '6px',
      width: '114px',
    },
  });

function ThumbDialog({ isOpen, handleClose, title, classes }) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      classes={{
        paperScrollPaper: classes.dialogContainer,
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
        <div></div>
      </DialogTitle>

      <DialogContent dividers>Div Contents</DialogContent>
      <DialogActions style={{ display: 'block' }}>
        <div>
          <Button
            variant="contained"
            onClick={handleClose}
            color="secondary"
            className={classes.btnWidth}
          >
            Done
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(dialogStyles)(ThumbDialog);
