import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import request from '../../shared/lib/request';
import { AnyAction } from 'redux';
import ActionTypes from './constants';
import { storeSignOutInfo, requestSignOutPage, setLoader } from './actions';
import { openSnackBar } from '../Root/actions';

// Individual exports for testing
export default function* signOutHomeSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_SIGN_OUT_HOME, fetchSignOutPage);
  yield takeLatest(ActionTypes.EDIT_SIGN_OUT, updateConcept);
}

export function* fetchSignOutPage(action: AnyAction) {
  try {
    let { result } = yield call(request, {
      url: `neouadmin/v2/pages/signout`,
      method: 'GET',
    });
    yield put(storeSignOutInfo(result && result.lowerSection));
    yield put(setLoader(false));
  } catch (error) {
    yield put(setLoader(false));
    if (error && error.data && error.data.responseCode === 404) {
    }
  }
}

export function* updateConcept({ payload }: AnyAction) {
  try {
    yield put(setLoader(true));
    let { result } = yield call(request, {
      url: `neouadmin/v2/pages/`,
      method: 'PUT',
      data: payload,
    });
    yield put(requestSignOutPage());
    yield put(openSnackBar('Component has been updated successfully!'));
    yield put(setLoader(false));
  } catch (error) {
    yield put(setLoader(false));

    if (
      error &&
      error.data &&
      error.data.responseCode === 404 &&
      typeof error.data === 'string'
    ) {
      yield put(openSnackBar(`Error in saving the form - ${error.data}`));
    } else {
      yield put(openSnackBar('Error in saving the form!'));
    }
  }
}
