import React from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { createStructuredSelector } from 'reselect';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { string, array, object, boolean, date } from 'yup';
import FormikField from '../../../components/Formik/FormikField';
import FormikSelect from '../../../components/Formik/FormikSelect';
import {
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import { _formatOptions } from './stub';
import request from '../../../shared/lib/request';
import { openSnackBar } from '../../Root/actions';
import ConfirmDialog from '../../../components/ConfirmDialog';
import {
  requestAchievementList,
  deleteAchievementInfo,
  requestAchievements,
} from '../actions';
import {
  StatusLabel,
  StatusText,
  HistoryText,
  LabelText,
} from '../../../components/Typography';
import Loader from '../../../components/Loader';
import moment from 'moment';
import FormikAutocomplete from '../../../components/Formik/FormikAutocomplete';
import BadgeImage from '../../../components/BadgeImage';
const CLASS_COMPLETIONS = [
  1,
  10,
  25,
  50,
  75,
  100,
  150,
  200,
  250,
  300,
  350,
  400,
  450,
  500,
];
enum BadgeType {
  special = 'special',
  milestones = 'milestones',
}
const AchievementSchema = object({
  title: string().required('Enter a achievement title'),
  description: string().required('Enter a description'),
  //imageUrl: string().required('Enter a image'),
  imageUrl: string().when('formType', {
    is: formType => formType === 'edit',
    then: string().required('Active image is required'),
    otherwise: string(),
  }),
  placeholderImageUrl: string().when('badgeType', {
    is: badgeType => badgeType === 'milestones',
    then: string().when('formType', {
      is: formType => formType === 'edit',
      then: string().required('Inactive image is required'),
      otherwise: string(),
    }),
    otherwise: string(),
  }),
  badgeType: string().oneOf(['special', 'milestones']),
  category: object()
    .shape({
      id: string(),
      title: string(),
    })
    .required('Category is a required field'),
  classCount: string().required('Class Completion is a required field'),
});

const dialogStyles = () =>
  createStyles({
    dialogContainer: {
      borderRadius: 0,
      maxWidth: '1080px',
    },
    imageContainer: {
      maxWidth: '252px',
      margin: '0px 16px',
    },
    videoHeader: {},
    classWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    form: {
      // maxWidth: '33.33%',
    },
    dialogContent: {
      padding: '24px 16px 72px',
    },
    mr6: {
      marginRight: '6px',
    },
    ml6: {
      marginLeft: '6px',
    },
    dialogActions: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      background: '#fff',
      border: '1px solid #D9D9D9',
      margin: '0px -16px',
      justifyContent: 'space-between',
      display: 'flex',
      zIndex: 140000,
    },
    videoHeaderContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
    },
    contentWrap: {
      margin: '10px',
    },
    wrapField: {
      marginBottom: '17px',
      width: '100%',
    },
    deleteDraft: {
      textAlign: 'left',
    },
    btnBottom: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    btnRight: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    badgeWrap: {
      display: 'flex',
    },
    errorText: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#FF1C58',
    },
    mileWrap: {
      marginLeft: '-8px',
    },
  });

interface videoId {
  id: string;
  title: string;
  videoID: {
    [key in string]: string;
  };
}
enum CollectionType {
  ACHIEVEMENT = 'achievement',
}

export enum FormType {
  ADD = 'add',
  EDIT = 'edit',
}

interface FormValues {
  id: string;
  title: string;
  description: string;
  badgeType: any;
  category: any;
  classCompletion: string;
  isHidden: boolean;
  isPublished: boolean;
  formType: FormType;
}

const initialValues: FormValues = {
  title: '',
  description: '',
  classCompletion: 'ALL',
  classCount: '',
  badgeType: '',
  category: null,
  isHidden: false,
  isPublished: false,
  imageUrl: '',
  placeholderImageUrl: '',
  formType: FormType.ADD,
};

interface IAchievementFormDialog {
  isOpen: boolean;
  onClose: (
    event: React.SyntheticEvent,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  title: string;
  id: string;
  type: FormType;
  badgeType: string;
  genres: any;
  programChallenge: any;
  classes: Record<
    | 'dialogContainer'
    | 'imageContainer'
    | 'videoHeader'
    | 'classWrapper'
    | 'form'
    | 'dialogContent'
    | 'mr6'
    | 'ml6'
    | 'dialogActions'
    | 'videoHeaderContainer'
    | 'contentWrap'
    | 'wrapField'
    | 'deleteDraft'
    | 'btnBottom'
    | 'btnRight'
    | 'badgeWrap'
    | 'mileWrap',
    string
  >;
}

function AchievementFormDialog({
  isOpen,
  onClose,
  title,
  classes,
  id,
  badgeItem,
  badgeType,
  genres,
  programChallenge,
  type,
}: IAchievementFormDialog) {
  let [initialFormValues, setInitialFormValues] = React.useState(initialValues);
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  const [isLoading, setLoader] = React.useState(false);
  const [isHideAlertOpen, showAlertToHide] = React.useState(false);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [isAlertOpen, alertToClose] = React.useState(false);
  const [isPublish, setIsPublish] = React.useState(false);
  const [possibleOptions, setPossibleOptions] = React.useState({
    genres: [],
    programChallenge: [],
    classCompletions: [],
    classCompletionsMile: [],
  });

  const dispatch = useDispatch();

  const _formatOptions = ({ genres, programChallenge, CLASS_COMPLETIONS }) => {
    //if (badgeItem.badgeType == 'special' || badgeType == 'special') {
    let result = {
      programChallenge: programChallenge
        ? programChallenge.map(i => ({ ...i, id: i.id, title: i.title }))
        : [],
      classCompletions: [{ label: 'All fitness Classes', value: 'ALL' }],
      genres: genres
        ? genres.map(i => ({ ...i, id: i.id, title: i.title }))
        : [],

      classCompletionsMile: CLASS_COMPLETIONS
        ? CLASS_COMPLETIONS.map(i => ({ label: i, value: i }))
        : [],
    };
    return result;
  };

  const formValues = data => {
    let { idDetails } = data || {};
    let filteredGenre = genres.filter(i => {
      if (idDetails && idDetails.id) {
        return i.id === idDetails.id;
      } else {
        return false;
      }
    });
    let filteredProg = programChallenge.filter(i => {
      if (idDetails && idDetails.id) {
        return i.id === idDetails.id;
      } else {
        return false;
      }
    });
    if (
      badgeType === 'special' ||
      (badgeItem && badgeItem.badgeType === 'special')
    ) {
      let value = {
        ...data,
        formType: type,
        category:
          filteredProg && filteredProg.length > 0 ? filteredProg[0] : null,
        badgeType: badgeType || badgeItem.badgeType,
      };
      return { ...initialValues, ...value };
    } else {
      let value = {
        ...data,
        formType: type,
        category:
          filteredGenre && filteredGenre.length > 0 ? filteredGenre[0] : null,
        badgeType: badgeType || badgeItem.badgeType,
      };
      return { ...initialValues, ...value };
    }
  };

  const _init = async (badgeItem, genres, programChallenge) => {
    let possibleValues = _formatOptions({
      genres,
      programChallenge,
      CLASS_COMPLETIONS,
    });
    setPossibleOptions({ ...possibleOptions, ...possibleValues });
    setInitialFormValues(formValues(badgeItem));
  };

  React.useEffect(() => {
    _init(badgeItem, genres, programChallenge);
  }, [badgeItem, genres, programChallenge]);

  const frameData = (values: any) => {
    let framedData: FormValues = {
      badgeId: values.id,
      title: values.title,
      description: values.description,
      classCount: values.classCount,
      idDetails: {
        idType: values.category.type === 'genre' ? 'genres' : 'collections',
        id: values.category.id,
      },
      isPublished: values.isPublished,
      imageUrl: values.imageUrl,
      placeholderImageUrl: values.placeholderImageUrl,
      badgeType: values.badgeType || badgeType,
    };
    return framedData;
  };

  const handleSubmit = async (values: FormValues): Promise<any> => {
    let formData = frameData(values);
    // update status as default
    formData.isPublished = Boolean(formData.isPublished);
    if (isPublish) {
      formData.isPublished = Boolean(true);
    }
    setLoader(true);
    try {
      await request({
        url: '/neouadmin/v1/badges',
        method: type === FormType.ADD ? 'POST' : 'PUT',
        data: formData,
      });
      setLoader(false);
      if (isPublish) {
        dispatch(
          openSnackBar('The Achievement has been published successfully!'),
        );
      } else {
        dispatch(openSnackBar('The Achievement has been saved successfully!'));
      }
      dispatch(requestAchievementList());
      onClose();
    } catch ({ data: error }) {
      setLoader(false);
      if (error.error) {
        dispatch(openSnackBar(error.error));
      } else {
        dispatch('Error in saving the achievement..');
      }
    }
  };

  const UnpublishAchievement = async (
    values: FormValues,
    { submitForm, errors, isValid, isPublished },
  ) => {
    let formData = frameData(values);
    formData.isPublished = Boolean(false);
    setLoader(true);
    try {
      await request({
        url: '/neouadmin/v1/badges',
        method: type === FormType.ADD ? 'POST' : 'PUT',
        data: formData,
      });
      setLoader(false);
      dispatch(
        openSnackBar('The Achievement status has been updated successfully!'),
      );
      dispatch(requestAchievementList());
      onClose();
    } catch ({ data: error }) {
      setLoader(false);
      dispatch(openSnackBar(`Failed to update Achievement - ${error.error}`));
    }
  };

  const onDeleteComponent = data => {
    setLoader(true);
    dispatch(deleteAchievementInfo(data));
    onClose();
    setLoader(false);
    dispatch(requestAchievements());
  };

  return (
    <>
      {isLoading && <Loader fullWidth />}

      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick={true}
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers classes={{ root: classes.dialogContent }}>
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the achievement."
            onAgree={() => onClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            validationSchema={AchievementSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              //setFieldValue,
              submitForm,
              errors,
              isValid,
              setErrors,
              validateForm,
              setTouched,
            }) => {
              return (
                <Form noValidate>
                  <Grid
                    container
                    alignItems="flex-start"
                    style={{ minWidth: '1060px' }}
                  >
                    <Grid item xs={3}>
                      {badgeType === 'special' ? (
                        <>
                          <BadgeImage
                            name="imageUrl"
                            title="ACTIVE"
                            defaultValue={values.imageUrl}
                          />
                          <div className={classes.errorText}>
                            {errors && errors.imageUrl}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.badgeWrap}>
                            <BadgeImage
                              name="imageUrl"
                              title="ACTIVE"
                              defaultValue={values.imageUrl}
                              type="milestones"
                            />
                            <div className={classes.mileWrap}>
                              <BadgeImage
                                name="placeholderImageUrl"
                                title="INACTIVE"
                                defaultValue={values.placeholderImageUrl}
                                type="milestones"
                              />
                            </div>
                          </div>
                          <div className={classes.errorText}>
                            {errors.imageUrl}
                          </div>
                          <div className={classes.errorText}>
                            {errors.placeholderImageUrl}
                          </div>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={4} container className={classes.contentWrap}>
                      <FormikField
                        name="title"
                        label="Title"
                        placeholder="Enter a title"
                      ></FormikField>

                      <FormikField
                        name="description"
                        label="Description"
                        placeholder="Enter a description"
                        multiline
                      />
                      {type === FormType.EDIT && (
                        <Grid item>
                          <Typography variant="h6">HISTORY</Typography>
                          <HistoryText>
                            Added:{' '}
                            {moment(values.createdAt).format('MM/DD/YYYY')}
                          </HistoryText>
                          {values.isPublished && (
                            <HistoryText>
                              Published:{' '}
                              {moment(values.publishedDate).format(
                                'MM/DD/YYYY',
                              )}
                            </HistoryText>
                          )}
                          {values.updatedAt && (
                            <HistoryText>
                              Updated:{' '}
                              {moment(values.updatedAt).format('MM/DD/YYYY')}
                            </HistoryText>
                          )}
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={4} container className={classes.contentWrap}>
                      {badgeType === 'special' ? (
                        <>
                          <div className={classes.wrapField}>
                            <FormikAutocomplete
                              name="category"
                              label="Program or Challenge"
                              items={possibleOptions.programChallenge}
                            />
                          </div>
                          <FormikSelect
                            name="classCount"
                            label="Class Completions"
                            items={possibleOptions.classCompletions}
                          />
                        </>
                      ) : (
                        <>
                          <div className={classes.wrapField}>
                            <FormikAutocomplete
                              name="category"
                              label="Category"
                              items={possibleOptions.genres}
                            />
                          </div>
                          <FormikSelect
                            name="classCount"
                            label="Class Completions"
                            items={possibleOptions.classCompletionsMile}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <ConfirmDialog
                    isOpen={isConfirmDialogOpen}
                    headerText="Are you sure?"
                    bodyText="You are about to delete all the videos."
                    handleClose={() => openConfirmDialog(false)}
                    onAgree={() => {
                      //setFieldValue('videos.videoIds', []);
                    }}
                    onDisagree={() => openConfirmDialog(false)}
                  ></ConfirmDialog>

                  <DialogActions classes={{ root: classes.dialogActions }}>
                    <div className={classes.btnBottom}>
                      {type === FormType.EDIT && (
                        <>
                          {!values.isPublished ? (
                            <div className={classes.btnRight}>
                              <div className={classes.deleteDraft}>
                                <Button
                                  size="large"
                                  color="secondary"
                                  type="button"
                                  onClick={() => {
                                    let newObj = {
                                      badgeId: values.id,
                                    };
                                    onDeleteComponent(newObj);
                                  }}
                                >
                                  Delete Draft
                                </Button>
                              </div>
                              <div>
                                <Button
                                  size="large"
                                  color="secondary"
                                  type="submit"
                                  onClick={() => setIsPublish(true)}
                                >
                                  Publish
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <Button
                                size="large"
                                color="secondary"
                                type="button"
                                onClick={() => alertToClose(true)}
                              >
                                UnPublish
                              </Button>
                              <ConfirmDialog
                                isOpen={isAlertOpen}
                                headerText="Are you sure?"
                                bodyText="You are about to unpublish the achievement."
                                onAgree={() =>
                                  UnpublishAchievement(values, {
                                    submitForm,
                                    errors,
                                    isValid,
                                  })
                                }
                                handleClose={() => alertToClose(false)}
                                onDisagree={() => alertToClose(false)}
                              ></ConfirmDialog>
                            </>
                          )}
                        </>
                      )}

                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        size="large"
                      >
                        Save & Close
                      </Button>
                    </div>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withStyles(dialogStyles)(AchievementFormDialog);
