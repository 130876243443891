/*
 *
 * ProgramDetail constants
 *
 */

enum ActionTypes {
  REQUEST_PROGRAM_DETAIL = 'app/ProgramDetail/REQUEST_PROGRAM_DETAIL',
  STORE_PROGRAM_DETAIL = 'app/ProgramDetail/STORE_PROGRAM_DETAIL',
  PROGRAM_DETAIL_FAILED = 'app/ProgramDetail/PROGRAM_DETAIL_FAILED',
  PROGRAM_DETAIL_LOADING = 'app/ProgramDetail/PROGRAM_DETAIL_LOADING',
  REQUEST_RELATED_PROGRAMS = 'app/ProgramDetail/REQUEST_RELATED_PROGRAMS',
  STORE_RELATED_PROGRAMS = 'app/ProgramDetail/STORE_RELATED_PROGRAMS',
  START_PROGRAM = 'app/ProgramDetail/START_PROGRAM',
  STOP_PROGRAM = 'app/ProgramDetail/STOP_PROGRAM',
  PROGRAM_DETAIL_UPDATING = 'app/ProgramDetail/PROGRAM_DETAIL_UPDATING',
  REMOVE_CLASS_LIST = 'app/ProgramDetail/REMOVE_CLASS_LIST',
}

export default ActionTypes;
