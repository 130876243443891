import * as React from 'react';
import {
  Button,
  makeStyles,
  Theme,
  createStyles,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';

interface PickerBtnProps {
  title: string;
  selected?: boolean;
  onPick();
}

const pickerBtnStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.only('sm')]: {
        width: '47%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '143px',
      },
      [theme.breakpoints.only('md')]: {
        width: '108px',
      },
      height: '40px',
      background: '##D4D8DC',
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      borderRadius: '6px',
      textTransform: 'initial',
      fontSize: '12px',
      margin: '4px 8px 4px 0px',
      boxShadow: 'none',
      '&:hover': {
        background: '#505B63',
        color: 'white',
      },
      '& .MuiButton-label': {
        height: '28px',
        width: '126px',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'center',
      },
    },
    selected: {
      background: '#3E4142',
      color: '#FFFFFF',
    },
    control: {
      padding: theme.spacing(2),
    },
    searchFilter: {
      [theme.breakpoints.only('sm')]: {
        width: '85px',
      },
      marginRight: '16px',
    },
    startOver: {
      width: '240px',
      // width: '100%',
      height: '50px',
      color: '#12161A',
      fontFamily: 'ProximaNova-Bold',
      '& .MuiButton-label': {
        color: '#12161A',
        fontFamily: 'ProximaNova-Bold',
        fontSize: '15px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
  }),
);

export default function FilterpickerButton(props) {
  const classes = pickerBtnStyles();
  if (props.title) {
    return (
      <Button
        className={clsx(
          classes.root,
          { [classes.selected]: props.selected },
          { [classes.searchFilter]: props.clearAll },
          { [classes.startOver]: props.startover },
        )}
        onClick={props.onSelect}
        fullWidth
        variant="contained"
      >
        {props.title}
      </Button>
    );
  } else {
    return null;
  }
}
