import React, { useLayoutEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { H5, Body1, H7 } from '../styles/Typography';
import { Grid, Link } from '@material-ui/core';
import { SvgInstructorPlaceholder } from '../components/NeIcons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      background: '#12161A',
      [`@media (min-width: 650px)`]: {
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },
      '& .MuiCardHeader-subheader': {
        color: '#FFFFFF',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '14px',
        lineHeight: '18px',
        [theme.breakpoints.up('xl')]: {
          fontSize: '16px',
          lineHeight: '18px',
        },
      },
      '& .MuiIconButton-label': {
        color: 'white',
      },
      '& .MuiPaper-root': {
        background: '#12161A',
        color: 'white',
        boxShadow: 'none',
      },
      '& .MuiExpansionPanelSummary-root': {
        padding: '0',
        minHeight: '0px',
      },
      '& .MuiExpansionPanelSummary-content.Mui-expanded': {
        margin: '14px 0',
      },
      '& .MuiExpansionPanelDetails-root': {
        display: 'block',
        padding: '0px 0px 16px',
      },
      '& .MuiCardHeader-root': {
        padding: '8px',
        paddingLeft: '0px',
      },
      '& .rectangle': {
        height: '42.5px',
        width: '42.5px',
        borderRadius: '6px',
        backgroundColor: '#35414A',
        marginRight: '16.25px',
      },
      '& .gridPad': {
        marginTop: '25px',
      },
      '& .pad-icons': {
        padding: '12px 13px',
        fontSize: '20px',
        color: '#ffff',
      },
      '& .MuiTypography-body1': {
        '& p': {
          fontFamily: 'Proxima-Nova-Regular',
        },
      },
    },
    instructor: {
      paddingTop: '20px',
      fontFamily: 'ProximaNova-Semibold',
    },
    head: {
      fontFamily: 'ProximaNova-Semibold',
      [theme.breakpoints.up('xl')]: {
        fontSize: '20px',
      },
    },
    svgImg: {
      borderRadius: '50%',
      width: '40px',
    },
  }),
);

export default function InstructorExpansionPanel({
  head = '',
  body,
  instructors,
  socialInsta = '',
  socialFb = '',
  socialTwitter = '',
  socialWeb = '',
}) {
  const [size, setSize] = useState(0);
  const [isExpandable, setExpandable] = useState(true);
  const classes = useStyles();

  const instructorData =
    instructors &&
    instructors.length &&
    instructors.filter(inst => inst.type === 'instructor');

  const personData =
    instructors &&
    instructors.length &&
    instructors.filter(inst => inst.type === 'person');
    
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
      setExpandable(window.innerWidth < 650);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={!isExpandable}
        onClick={() =>
          window.innerWidth < 650 ? setExpandable(!isExpandable) : null
        }
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <H5 className={classes.head}>{head}</H5>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Body1>{body}</Body1>
          {instructorData && instructorData.length > 0 ? (
            <H7 className={classes.instructor}>
              {instructorData && instructorData.length > 1
                ? 'INSTRUCTORS'
                : 'INSTRUCTOR'}
            </H7>
          ):('')}
          <Grid container>
            {instructorData &&
            instructorData.length > 0 ?
              instructorData.map((instructor, index) => (
                <Grid
                  xs={6}
                  md={6}
                  key={instructor.id}
                  className={classes.instructorHover}
                >
                  <InstructorPanel
                    instructorDetails={instructor}
                    key={`${instructor.id}${index}`}
                  />
                </Grid>
              )): ('')}
          </Grid>
          {personData && personData.length > 0 ? (
            <H7 className={classes.instructor}>Featuring</H7>
          ):('')}
          <Grid container>
            {personData &&
            personData.length > 0 ?
              personData.map((instructor, index) => (
                <Grid
                  xs={6}
                  md={6}
                  key={instructor.id}
                  className={classes.personHover}
                >
                  <InstructorPanel
                    instructorDetails={instructor}
                    key={`${instructor.id}${index}`}
                  />
                </Grid>
              )): ('')}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );

  function InstructorPanel({ instructorDetails }) {
    const { imageUrl, firstName, lastName = '', permaLink } = instructorDetails;
    return (
      <CardHeader
      avatar={
        imageUrl ? (
          <Avatar aria-label="recipe" src={imageUrl} />
        ) : (
          <SvgInstructorPlaceholder className={classes.svgImg} />
        )
      }
        subheader={`${instructorDetails.firstName} ${instructorDetails.lastName}`}
      />
    );
  }
}

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function ShowWindowDimensions(props) {
  const [width, height] = useWindowSize();
  return (
    <span>
      Window size: {width} x {height}
    </span>
  );
}
