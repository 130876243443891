import React from 'react';
import { Drawer, IconButton, Divider, Button } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import reducer from '../reducer';
import saga from '../saga';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useInjectReducer } from '../../../utils/injectReducer';
import makeSelectAttribute from '../selectors';
import { getAttributeDetail } from '../actions';
import EditAttributeForm from './EditAttributeForm';
import { SvgIconEdit } from '../../../components/NeIcons';
import Loader from '../../../components/Loader';
import ConfirmDialog from '../../../components/ConfirmDialog';
import makeSelectFitnessGoals from '../../FitnessGoals/selectors';
import { openSnackBar } from '../../Root/actions';
import { getFitness } from '../../FitnessGoals/actions';

const stateSelector = createStructuredSelector({
  attributes: makeSelectAttribute(),
  fitnessGoals: makeSelectFitnessGoals(),
});

export default function EditDialog({ id, classes, children, title }) {
  const { attributes } = useSelector(stateSelector);
  const attributeDetail = attributes.attributeDetailInfo;
  useInjectReducer({ key: 'attribute', reducer });
  useInjectSaga({ key: 'attribute', saga });

  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
    // left: false,
    isLoading: true,
  });
  const toggleDrawer = (anchor, open) => event => {
    if (id) {
      dispatch(getAttributeDetail(id));
    }
    setState({ ...state, [anchor]: open, isLoading: open });
  };

  const closeDrawer = (anchor = 'right', open = false) => {
    setState({ ...state, [anchor]: open, isLoading: open });
  };

  const onSubmit = (isSubmit, message) => {
    if (isSubmit) {
      dispatch(getFitness());
    }
    dispatch(openSnackBar(message));
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={toggleDrawer('right', true)}
        className={classes.editIcon}
        id={id}
      >
        <SvgIconEdit />
      </IconButton>

      <Drawer
        anchor="right"
        disableBackdropClick
        open={state.right}
        // onClose={toggleDrawer('right', false)}
        onClose={() => showAlertToClose(true)}
      >
        <ConfirmDialog
          isOpen={isCloseAlertOpen}
          headerText="Are you sure?"
          bodyText={`You are about to close this form.`}
          onAgree={toggleDrawer('right', false)}
          handleClose={() => showAlertToClose(false)}
          onDisagree={() => showAlertToClose(false)}
        ></ConfirmDialog>

        <div className={classes.rightContainer}>
          <div className={classes.titleDiv}>
            <div className={classes.title}>{title || 'EDIT ATTRIBUTE'}</div>
            <Button
              onClick={() => showAlertToClose(true)}
              className={classes.closeBtn}
            >
              <CloseIcon />
            </Button>
          </div>

          <Divider className={classes.divider} />

          {children
            ? React.cloneElement(children, {
                closeDrawer,
                onSubmit,
              })
            : attributeDetail &&
              attributeDetail.title && (
                <>
                  {(!attributeDetail || !attributeDetail.title) && <Loader />}

                  <EditAttributeForm
                    formDetail={attributeDetail}
                    classes={classes}
                    toggleFn={() => closeDrawer('right', false)}
                  ></EditAttributeForm>
                </>
              )}
        </div>
      </Drawer>
    </React.Fragment>
  );
}
