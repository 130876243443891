/*
 *
 * AddInteractiveClass
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import AddClasses from '../InteractivePublishedClasses/components/AddClasses';
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '50px 20px',
    },
    sectionTitle: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: '18px',
      lineHeight: '20px',
    },
    head: {
      color: '#12161A',
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    upperHeaderSection: {
      width: '100%',
      justifyContent: 'space-between',
      display: 'flex',
      paddingBottom: '20px',
      borderBottom: '1px solid #000000',
      marginBottom: '20px',
    },
    headWrap: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 0px 12px 0px',
      width: '100%',
      flexWrap: 'wrap',
    },
    addClass: {
      width: '650px',
      margin: '40px auto',
      padding: '40px',
      border: '1px solid #000000',
      borderRadius: '10px',
    },
  }),
);
function AddInteractiveClass(props: Props) {
  const classes = useStyles();
  return (
    <div>
      <Helmet>
        <title>Interactive Add Class</title>
        <meta name="description" content="Description of AddInteractiveClass" />
      </Helmet>
      <div className={classes.root}>
        <div className={classes.headWrap}>
          <div className={classes.upperHeaderSection}>
            <div className={classes.head}>Interactive: Add</div>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              classes={{
                root: classes.sectionTitle,
              }}
            >
              GROUP INTERACTIVE CLASSES
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.addClass}>
              <AddClasses onClearEvent={() => {}} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AddInteractiveClass;
