import React from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { SvgIconEdit } from '../../../components/NeIcons';
import ConfirmDialog from '../../../components/ConfirmDialog';
import EditMembers from './EditMembers';
import request from '../../../shared/lib/request';
import { openSnackBar } from '../../Root/actions';

const useTableStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
    '& .MuiTableRow-root': {
      'td.MuiTableCell-root.MuiTableCell-body:nth-child(1)': {
        flex: '1',
        width: '20%',
      },
      'td.MuiTableCell-root.MuiTableCell-body:nth-child(2)': {
        flex: '1',
        width: '20%',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(3)': {
        width: '27%',
        minWidth: '224px',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(4)': {
        minWidth: '100px',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(5)': {
        width: '10%',
      },
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'red',
    },
  },
  container: {
    maxHeight: '100%',
  },
  box: {
    height: '64px',
    width: '64px',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '64px',
      height: 'auto',
    },
  },
  firstColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'inherit',
  },
  firstCoulmContent: {
    textAlign: 'start',
  },
  thirdColumn: {
    height: '20px',
    width: '70px',
    color: '#3E4142',
    fontFamily: 'ProximaNova-Bold',
    fontSize: '8px',
    fontWeight: 'bold',
    letterSpacing: '0.57px',
    lineHeight: '10px',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    boxSizing: 'border-box',
    border: '1px solid #E8EBED',
    backgroundColor: '#E8EBED',
  },
  enabled: {
    boxSizing: 'border-box',
    border: '1px solid #E8EBED',
  },
  conceptTitle: {
    color: '#12161A',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '17px',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  text: {
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '20px',
    color: '#909599',
  },
  editIcon: {
    width: '36px',
    height: '36px',
    color: '#000000',
    padding: '6px',
  },
  iconHolder: {
    width: 64,
    height: 64,
  },
  iconSquare: {
    borderRadius: 6,
  },
  iconImg: {
    border: '1px solid #eee',
    background: '#080b0d',
    borderRadius: 6,
  },
  avatarProfile: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    textTransform: 'uppercase',
  },
});

export function MembersTable(props) {
  const classes = useTableStyles();
  const { list, isLoading, searchKey, listRefresh } = props;
  const [isEditDialogOpen, openEditDialog] = React.useState(false);
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  const [selectedMemberId, selectMember] = React.useState(null);
  const [selectedMemberName, selectMemberName] = React.useState(null);
  const dispatch = useDispatch();

  const _renderCTA = ({ userStatus }) => (
    <div className={`${classes.thirdColumn} ${classes.enabled}`}>
      {userStatus}
    </div>
  );

  const _onMemberEdit = async (id, name) => {
    try {
      selectMember(id);
      selectMemberName(name);
      openEditDialog(true);
    } catch ({ data }) {
      dispatch(
        openSnackBar(
          typeof data.error === 'string'
            ? data.error
            : data.message || "Couldn't fetch the member",
        ),
      );
    }
  };

  const _onCloseAgree = () => {
    openEditDialog(false);
  };

  const _onCloseDisAgree = () => {
    openEditDialog(true);
  };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead></TableHead>
          <TableBody>
            <TableRow key={list.id} tabIndex={-1}>
              <TableCell align="left">
                <div className={classes.conceptTitle}>Name</div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.conceptTitle}>User name</div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.conceptTitle}>Email</div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.conceptTitle}>Created</div>
              </TableCell>
              <TableCell align="center">
                <div className={classes.conceptTitle}>Status</div>
              </TableCell>
              <TableCell align="center">
                <div className={classes.conceptTitle}></div>
              </TableCell>
            </TableRow>
            {list.map(item => (
              <TableRow key={list.id} hover role="checkbox" tabIndex={-1}>
                <TableCell align="left">
                  <Grid container spacing={1} className={classes.avatarProfile}>
                    <Grid item xs={4} className={classes.avatar}>
                      {item.imageUri ? (
                        <Avatar
                          alt={item.firstName}
                          src={item.imageUri}
                        ></Avatar>
                      ) : (
                        <Avatar
                          alt={item.firstName}
                          src="/static/images/avatar/1.jpg"
                        ></Avatar>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      <div className={classes.firstColumn}>
                        <div className={classes.firstCoulmContent}>
                          <div className={classes.conceptTitle}>
                            {item.firstName || '-'}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left">
                  <div className={classes.firstColumn}>
                    <div className={classes.firstCoulmContent}>
                      <div className={classes.text}>
                        {item.displayName || '-'}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="left">
                  <div className={classes.text}>{item.email}</div>
                </TableCell>
                <TableCell align="left">
                  <div className={classes.text}>
                    {moment(item.createdAt).format(`L`)}
                  </div>
                </TableCell>
                <TableCell align="center">
                  {item.userStatus && _renderCTA(item)}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => _onMemberEdit(item.id, item.firstName)}
                    classes={{ root: classes.editIcon }}
                  >
                    <SvgIconEdit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEditDialogOpen && (
        <EditMembers
          isOpen={isEditDialogOpen}
          title="MANAGE MEMBER ACCOUNT"
          handleClose={(isForce = false) => {
            if (isForce) {
              openEditDialog(false);
            } else openConfirmDialog(true);
          }}
          id={selectedMemberId}
          name={selectedMemberName}
          listRefresh={listRefresh}
        ></EditMembers>
      )}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        handleClose={openConfirmDialog}
        onAgree={_onCloseAgree}
        onDisagree={_onCloseDisAgree}
        agreeButtonText="Yes"
        disagreeBtnText="Cancel"
        headerText="Are you sure?"
        bodyText="You’re about to close this Edit Member dialog box."
      ></ConfirmDialog>
    </Paper>
  );
}
