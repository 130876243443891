import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '40px 20px 50px 20px',
    },
    head: {
      color: '#12161A',
      fontFamily: 'TurbinadoDryRegular',
      fontSize: '45px',
      letterSpacing: 0,
      lineHeight: '56px',
      textAlign: 'center',
    },
    subHead: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      // fontFamily: 'ProximaNova-SemiBold',
      fontSize: '18px',
      fontWeight: 250,
      lineHeight: '16px',
    },
    upperHeaderSection: {
      width: '100%',
      justifyContent: 'space-between',
      display: 'flex',
    },
    lowerHeaderSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 0px 0px 0px',
    },
    headWrap: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 0px 12px 0px',
      width: '100%',
      flexWrap: 'wrap',
    },
    addVideoBtn: {
      padding: '0px',
      height: '40px',
      width: '162px',
      borderRadius: '6px',
      backgroundColor: '#12161A',
    },
    newVideo: {
      paddingLeft: '10px',
    },
    searchIcon: {
      paddingRight: '22px',
      cursor: 'pointer',
      alignSelf: 'center',
    },
    styledTab: {
      '& .MuiTabs-centered': {
        justifyContent: 'space-between',
        borderTop: '1px solid #000000',
      },
    },
    noData: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      padding: '20px',
    },
    alertMsg: {
      background: '#F1F2F5',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      padding: '20px',
    },
    divider: {
      margin: '12px 0',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      '&::before': {
        content: "' '",
        position: 'absolute',
        top: '50%',
        // border: '0.5px solid #E3E3E4',
        width: '100%',
      },
      '& > span': {
        background: '#fff',
        padding: '0px 6px',
        zIndex: 1,
        fontSize: '11px',
        lineHeight: '16px',
        fontFamily: 'Proxima-Nova-Regular',
        color: '#4A4A4A',
      },
    },
    filterPanel: {
      width: '100%',
      // height: 'auto',
      minHeight: '140vh',
    },
    footer: {
      bottom: '0px',
      position: 'fixed',
      width: '100%',
      borderTop: '1px solid #272B2E',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#FFFFFF',
      zIndex: 100,
      [theme.breakpoints.only('sm')]: {
        height: '72px',
      },
      [theme.breakpoints.up('md')]: {
        height: '71px',
      },
      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 150px)',
      },
      [theme.breakpoints.up('xl')]: {
        // margin: 'auto',
        width: 'calc(100% - 230px)',
      },
    },
    title__heading: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flexStart',
      fontSize: '26px',
    },
    searchHeader: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    searchBar: {
      width: '60%',
      // width: '510px',
    },
    startOver: {
      // width: '40%',
      padding: '12px 0px 22px 18px',
      alignSelf: 'center',
    },
    selectedContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    selectedcontained: {
      width: '60%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    chipBoxes: {
      width: '85%',
      padding: '12px 0px 22px 0px',
      flexWrap: 'wrap',
    },
    addFilter: {
      width: '15%',
      padding: '16px 15px 22px 0px',
      // alignSelf: 'center',
      color: '#5AA700',
      fontSize: '13px',
      fontFamily: 'Proxima-Nova-Medium',
      cursor: 'pointer',
    },
    sortItem: {
      width: '50%',
      padding: '12px 0px 22px 0px',
      alignSelf: 'center',
      justifyContent: 'flex-end',
    },
    dividers: {
      borderTop: '1px solid #E3E3E4',
      borderBottom: 'none',
      padding: '16px 70px',
      minHeight: '260px',
    },
    liveEvent: {
      padding: '10px 21px 21px 0px',
      minHeight: '260px',
    },
    loadMoreBtn: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px',
    },
  }),
);
