import React from 'react';
import { Grid, makeStyles, createStyles, Theme } from '@material-ui/core';
import FloatingActionButton from './FloatingActionButton';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      padding: '0 20px',
      borderBottom: '1px solid #E3E3E4',
    },
    topHead: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '28px',
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    subtitleWrap: {
      height: '120px',
      borderRadius: '6px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      alignItems: 'center',
      marginBottom: '12px',
    },
    topWrapper: {
      width: '100%',
      padding: '38px 20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    subtitle: {
      width: '396px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    subtitle1: {
      color: '#12161A',
      fontSize: '17px',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '24px',
    },
    body2: {
      color: '#000000',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      fontFamily: 'ProximaNova-Medium',
    },
    buttonWrap: {
      display: 'flex',
    },
    typeWrap: {
      width: '255px',
      marginLeft: '10px',
      color: '#909599',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '20px',
      float: 'left',
      textTransform: 'capitalize',
    },
  }),
);

export function TraySection({
  item,
  title,
  subTitle,
  componentType,
  lastUpdated,
  handleManage,
  contentType,
  content,
  headLine = '',
  showStatus = '',
}: {
  item: any;
  title: string;
  subTitle: string;
  componentType: string;
  lastUpdated: any;
  handleManage();
  contentType: any;
  content: any;
  headLine: string;
  showStatus: string;
}) {
  const classes = useStyles();

  return (
    <Grid container>
      <div className={classes.topWrapper}>
        <div className={classes.buttonWrap}>
          <div className={classes.subtitleLow}>
            {headLine && <div className={classes.subtitle1}>{headLine}</div>}
            <div className={classes.subtitle1}>{title}</div>
            <div className={classes.body2}>{subTitle}</div>
            <div className={classes.body2}>{showStatus}</div>
          </div>
        </div>

        <div className={classes.buttonWrap}>
          <div className={classes.typeWrap}>
            <div className={classes.transform}>{componentType}</div>
            <div>{lastUpdated}</div>
          </div>
          <FloatingActionButton
            title={title}
            type={componentType}
            contentType={contentType}
            subTitle={subTitle}
            contentMode={content}
            onClick={handleManage}
          />
        </div>
      </div>
    </Grid>
  );
}
