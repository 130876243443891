import crypto from 'crypto';
import cryptLib from '@skavinvarnan/cryptlib';
function getAlgorithm(keyBase64) {
  const key = Buffer.from(keyBase64, 'base64');
  switch (key.length) {
    case 16:
      return 'aes-128-cbc';
    case 32:
      return 'aes-256-cbc';
  }

  throw new Error('Invalid key length: ' + key.length);
}

export const encrypt = function(plainText, keyBase64) {
  const encrypted = cryptLib.encryptPlainTextWithRandomIV(plainText, keyBase64);

  return encrypted;
};

export const decrypt = function(cipherText, keyBase64) {
  const key = Buffer.from(keyBase64, 'base64');
  const iv = Buffer.from(ivBase64, 'base64');

  const decipher = crypto.createDecipheriv(getAlgorithm(keyBase64), key, iv);
 let decrypted = cryptLib.decryptCipherTextWithRandomIV(
    cipherText,
    keyBase64,
  );
  decrypted += decipher.final();
  return decrypted;
};
