/*
 *
 * InteractiveCoupon constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/InteractiveCoupon/DEFAULT_ACTION',
  SET_LOADING = 'SET_LOADING',
  REQUEST_PROMO = 'REQUEST_PROMO',
  STORE_COUPON = 'STORE_COUPON',
  REQUEST_PACKAGES = 'REQUEST_PACKAGES',
  STORE_PACKAGES = 'STORE_PACKAGES',
  GET_SEARCH_RESULT = 'GET_SEARCH_RESULT',
  SET_COUPON_PAGINATION_LOADER = 'SET_COUPON_PAGINATION_LOADER',
  REQUEST_COUPON_LIST_PAGINATION = 'REQUEST_COUPON_LIST_PAGINATION',
  COUPON_LIST_SUCCESS = 'COUPON_LIST_SUCCESS',
  COUPON_LIST_FAILED = 'COUPON_LIST_FAILED',
  COUPON_LIST_PAGINATION_SUCCESS = 'COUPON_LIST_PAGINATION_SUCCESS',
  PUT_COUPON_LIST_PAGINATION_SUCCESS = 'PUT_COUPON_LIST_PAGINATION_SUCCESS',
  COUPON_LIST_TOTAL = 'COUPON_LIST_TOTAL',
}

export default ActionTypes;
