import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  FormControlLabel,
} from '@material-ui/core';
import { arrayMove, arrayRemove, List } from 'baseui/dnd-list';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import ConfirmDialog from '../../../components/ConfirmDialog';
import FormikAutocomplete from '../../../components/Formik/FormikAutocomplete';
import FormikField from '../../../components/Formik/FormikField';
import { NEInputLight } from '../../../components/NEInput';
import { SearchCarouselWithDnD } from '../../../components/SearchCarouselWithDnD';
import { SearchVideoWithDnD } from '../../../components/SearchVideoWithDnD';
import {
  ErrorText,
  LabelText,
  ItalicInfoText,
} from '../../../components/Typography';
import {
  ComponentFormSchema,
  BrowseComponentSchema,
} from './ComponentFormSchema';
import { ComponentType, ContentType, Mode } from '../types';
import { FormType } from '../index';
import { getUniqItems } from '../../../utils/array';
import { SvgIconAdd } from '../../../components/NeIcons';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FormikImageUploader from '../../../components/Formik/FormikImageUploader';
import { openSnackBar } from '../../Root/actions';
import { useDispatch } from 'react-redux';
import { GreenCheckbox } from '../../../components/GreenCheckbox';

export const ComponentEditor = ({
  componentFormInfo,
  possibleOptions = null,
  handleEditFormSubmit,
  handleComponentDelete,
  isBrowse = false,
  lowerSection,
  ComponentSchema,
}) => {
  const [refList, setReference] = React.useState([]);
  const [refListPromo, setRefList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [tempFormValue, setTempFormValue] = React.useState({
    componentType: '',
    contentType: '',
    fieldToohange: '',
    isAlertOpen: false,
  });
  const [tempContentValue, setTempContentValue] = React.useState({
    componentType: '',
    contentType: '',
    fieldToohange: '',
    isAlertOpen: false,
  });
  const [deleteDialogInfo, setDeleteDialogInfo] = React.useState({
    componentTitle: '',
    componentId: '',
    componentSection: '',
    contentType: '',
    isOpen: false,
    content: {},
  });
  const dispatch = useDispatch();

  const addContentList = (list, setFieldValue) => {
    let uniqItems = getUniqItems(list, 'id');
    setFieldValue('content.data', uniqItems);
  };

  const errorMessageValue = () => {
    return (
      <ErrorText>
        <ErrorMessage name="content.data" />
      </ErrorText>
    );
  };

  const renderSearchComponent = ({ values, setFieldValue }) => {
    if (values.contentType !== ContentType.PromoTray) {
      switch (values.contentType) {
        case ContentType.Videos:
          return (
            <SearchVideoWithDnD
              videoList={values.content.data}
              onChange={list => addContentList(list, setFieldValue)}
              errorMessage={errorMessageValue}
            />
          );

        default:
          return (
            <SearchCarouselWithDnD
              searchUrl={
                isBrowse
                  ? 'search-collections'
                  : values && values.componentSection === 'collections'
                  ? 'search-concept-collections'
                  : 'search-concepts'
              }
              type={values.componentSection}
              contentList={values.content.data}
              onChange={list => addContentList(list, setFieldValue)}
              errorMessage={errorMessageValue}
            />
          );
      }
    } else return;
  };

  function renderCarouselSearchComponent(
    values: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
    ListContent: ({
      $value,
      other,
    }: {
      $value: any;
      other: any;
    }) => JSX.Element,
  ) {
    if (values.componentType === ComponentType.Carousel) {
      return (
        <SearchCarouselWithDnD
          searchUrl={
            isBrowse
              ? values.componentSection === 'concept'
                ? 'search-concepts'
                : 'search-collections'
              : 'search-concept-collections'
          }
          type={values.componentSection}
          contentList={values.content.data}
          contentType={values.contentType}
          onChange={list => addContentList(list, setFieldValue)}
          listItem={({ $value, ...other }) => (
            <ListContent
              key={other.$index}
              $value={$value}
              other={{
                ...other,
                values,
                setFieldValue,
              }}
            />
          )}
          errorMessage={errorMessageValue}
        />
      );
    } else return;
  }

  const handleContentTypChange = e => {
    let { name, value } = e.target;
    setTempFormValue({
      ...tempFormValue,
      [name]: value,
      fieldToChange: name,
      isAlertOpen: true,
    });
  };

  const confirmFormChange = ({ setFieldValue }) => {
    let { fieldToChange } = tempFormValue;
    setFieldValue(fieldToChange, tempFormValue[fieldToChange]);
    setFieldValue('content.data', []);
  };

  const confirmContentChange = ({ setFieldValue }) => {
    let { fieldToChange } = tempContentValue;
    setFieldValue(fieldToChange, tempContentValue[fieldToChange]);
  };

  const closeForChangeAlert = () => {
    setTempFormValue({
      componentType: '',
      contentType: '',
      fieldToChange: '',
      isAlertOpen: false,
    });
  };

  const closeContentChangeAlert = () => {
    setTempContentValue({
      componentType: '',
      contentType: '',
      fieldToChange: '',
      isAlertOpen: false,
    });
  };

  const saveContent = ({ values, setFieldValue }) => {
    let newContentData = values.content.data.map((c, index) => {
      if (refList[index] && refList[index].querySelector('input'))
        c.text = refList[index].querySelector('input').value;
      return c;
    });
    setFieldValue('content.data', newContentData);
  };

  const ListContent = ({ $value, other }) => {
    let { $index, values, setFieldValue } = other;
    return (
      <div key={`input_change_${$index}`} style={{ flex: 1 }}>
        <LabelText>
          {($value && $value.title) ||
            `${$value.firstName} ${$value.lastName && $value.lastName}`}
        </LabelText>
        <NEInputLight
          ref={ref => {
            refList[$index] = ref;
            if (refList[$index] && refList[$index].querySelector('input'))
              setReference(refList);
          }}
          defaultValue={$value.text || $value.teaserText || ''}
          inputProps={{ maxLength: 50 }}
          id={name}
          name={name}
          label="Teaser Text*"
          fullWidth
          variant="outlined"
          onMouseLeave={() => saveContent({ values, setFieldValue })}
          error={Boolean(
            values.content.data[$index] &&
              values.content.data[$index].text &&
              values.content.data[$index].text.length > 50 && (
                <ErrorText>only 50 characters is allowed</ErrorText>
              ),
          )}
          helperText={
            values.content.data[$index] &&
            values.content.data[$index].text &&
            values.content.data[$index].text.length === 50 && (
              <ErrorText>only 50 characters is allowed</ErrorText>
            )
          }
        ></NEInputLight>
      </div>
    );
  };

  const addPromo = ({ setFieldValue, content }) => {
    let { data = [] } = content || {};
    let newPromo = {
      imageUri: '',
      url: '',
    };
    data = [newPromo, ...data];
    setFieldValue('content.data', data);
  };

  const saveContentPromo = ({ values, setFieldValue }) => {
    let newContentData = [];
    newContentData = values.content.data.map((c, index) => {
      if (refListPromo[index] && refListPromo[index].querySelector('input')) {
        c.url =
          refListPromo[index].querySelector('input').value ||
          values.content.data[index].url;
        c.imageUri = values.content.data[index].imageUri;
      }
      return c;
    });
    setFieldValue('content.data', newContentData);
  };

  const ListPromo = ({ $value, other }) => {
    let { $index, values, setFieldValue } = other;
    if (values.content.data[$index].url === '') {
      setError(true);
    }
    return (
      <div style={{ flex: 1, margin: '0 8px' }}>
        <FormikImageUploader
          id={'ImageUploader' + $index}
          key={'ImageUploader' + $index}
          name={`content.data[${other.$index}].imageUri`}
          aspectRatio="32x9"
          title=""
          defaultValue={($value && $value.imageUri) || ''}
        />
        <NEInputLight
          ref={reff => {
            refListPromo[$index] = reff;
            if (
              refListPromo[$index] &&
              refListPromo[$index].querySelector('input')
            )
              setRefList(refList);
          }}
          required={true}
          defaultValue={($value && $value.url) || ''}
          id={`content.data[${other.$index}].url`}
          name={`content.data[${other.$index}].url`}
          label="Click Through URL"
          fullWidth
          variant="outlined"
          error={Boolean(
            values.content.data[$index].url === '' && (
              <ErrorText>url is a required field</ErrorText>
            ),
          )}
          helperText={
            values.content.data[$index].url === '' && (
              <ErrorText>url is a required field</ErrorText>
            )
          }
          onMouseLeave={e => {
            if (values.content.data[$index].url !== '') {
              setError(false);
            }
            saveContentPromo({ values, setFieldValue });
          }}
        ></NEInputLight>

        <FormControlLabel
          value="top"
          name={`content.data[${other.$index}].isExternal`}
          control={
            <GreenCheckbox
              checked={Boolean(values.content.data[$index].isExternal)}
              onChange={e =>
                setFieldValue(
                  `content.data[${other.$index}].isExternal`,
                  !values.content.data[$index].isExternal,
                )
              }
            />
          }
          label="External"
          labelPlacement="start"
        />
      </div>
    );
  };

  function renderPromoDnD(
    values: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
    ListPromo: ({ $value, other }: { $value: any; other: any }) => JSX.Element,
  ) {
    return (
      <List
        key="renderPromoDnD"
        removable={true}
        items={values.content.data}
        overrides={{
          Label: {
            component: ({ $value, ...other }) => (
              <ListPromo
                $value={$value}
                other={{
                  ...other,
                  values,
                  setFieldValue,
                }}
                key={other.$index}
              />
            ),
          },
          Root: {
            style: {
              maxWidth: '100%',
            },
          },
          Item: {
            style: ({ $theme }) => {
              return {
                boxShadow: '0 2px 2px 0 rgba(0,0,0, 0);',
                borderLeftColor: '#E3E3E4!important',
                borderRightColor: ' #E3E3E4!important',
                borderTopColor: ' #E3E3E4!important',
                borderBottomColor: ' #E3E3E4!important',
                borderLeftWidth: '1px!important',
                borderRightWidth: '1px!important',
                borderTopWidth: '1px!important',
                borderBottomWidth: '1px!important',
                borderRadius: '6px',
                marginBottom: '15px',
                zIndex: 140000,
              };
            },
          },
          DragHandle: {
            style: ({ $theme }) => {
              return {
                marginTop: '-90px !important',
              };
            },
          },
          CloseHandle: {
            component: props => (
              <IconButton {...props}>
                <DeleteOutlinedIcon />
              </IconButton>
            ),
          },
        }}
        onChange={({ oldIndex, newIndex }) => {
          let newList =
            newIndex === -1
              ? arrayRemove(values.content.data, oldIndex)
              : arrayMove(values.content.data, oldIndex, newIndex);
          setFieldValue('content.data', newList);
        }}
      />
    );
  }

  const classes = useStyles();
  return (
    <Formik
      initialValues={componentFormInfo.item}
      enableReinitialize
      validationSchema={
        ComponentSchema
          ? ComponentSchema
          : isBrowse
          ? BrowseComponentSchema
          : ComponentFormSchema
      }
      onSubmit={!isError && handleEditFormSubmit}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form noValidate>
            <ConfirmDialog
              isOpen={tempFormValue.isAlertOpen}
              headerText="Are you sure?"
              bodyText="Switching component/content type will leads to current data loss"
              onAgree={() => confirmFormChange({ setFieldValue })}
              handleClose={closeForChangeAlert}
              onDisagree={closeForChangeAlert}
            />
            <ConfirmDialog
              isOpen={tempContentValue.isAlertOpen}
              headerText="Are you sure?"
              bodyText="You are about to switch the component/content type/section"
              onAgree={() => confirmContentChange({ setFieldValue })}
              handleClose={closeContentChangeAlert}
              onDisagree={closeContentChangeAlert}
            />
            <ConfirmDialog
              isOpen={deleteDialogInfo.isOpen}
              headerText="Are you sure?"
              bodyText="You are about to delete the component"
              onAgree={() => handleComponentDelete(deleteDialogInfo)}
              handleClose={() =>
                setDeleteDialogInfo({
                  componentTitle: '',
                  componentId: '',
                  componentSection: '',
                  contentType: '',
                  isOpen: false,
                  content: {},
                })
              }
              onDisagree={() =>
                setDeleteDialogInfo({
                  componentTitle: '',
                  componentId: '',
                  componentSection: '',
                  contentType: '',
                  isOpen: false,
                  content: {},
                })
              }
            />
            <div className={classes.drawerBody}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                  <Box m={2}>
                    <FormikField
                      name="componentTitle"
                      label={
                        values.contentType !== ContentType.PromoTray
                          ? 'Title*'
                          : 'Title (not displayed)'
                      }
                      placeholder="Enter a title"
                    ></FormikField>
                    {values.content.mode === Mode.ManuallyCurated && (
                      <Grid container spacing={2}>
                        {possibleOptions && possibleOptions.componentTypes && (
                          <>
                            {values.contentType !== ContentType.PromoTray && (
                              <Grid item xs={6}>
                                <Field name="componentType">
                                  {({ meta }) => (
                                    <NEInputLight
                                      id="componentType"
                                      name="componentType"
                                      label="Component Type"
                                      select
                                      fullWidth
                                      variant="outlined"
                                      helperText={meta.touched && meta.error}
                                      error={Boolean(
                                        meta.touched && meta.error,
                                      )}
                                      value={values.componentType}
                                      onChange={handleContentTypChange}
                                    >
                                      {possibleOptions.componentTypes &&
                                        possibleOptions.componentTypes.length >
                                          0 &&
                                        possibleOptions.componentTypes.map(
                                          (item, index) => (
                                            <MenuItem
                                              value={item.value}
                                              key={`${item.value}${index}`}
                                            >
                                              {item.label}
                                            </MenuItem>
                                          ),
                                        )}
                                    </NEInputLight>
                                  )}
                                </Field>
                              </Grid>
                            )}
                          </>
                        )}
                        <Grid
                          item
                          xs={
                            values.contentType !== ContentType.PromoTray
                              ? 6
                              : 12
                          }
                        >
                          {possibleOptions && possibleOptions.contentTypes && (
                            <Field name="contentType">
                              {({ meta }) => (
                                <NEInputLight
                                  id="contentType"
                                  name={
                                    isBrowse
                                      ? 'componentSection'
                                      : 'contentType'
                                  }
                                  label={
                                    isBrowse
                                      ? 'Component Section'
                                      : 'Content Type'
                                  }
                                  select
                                  fullWidth
                                  variant="outlined"
                                  helperText={meta.touched && meta.error}
                                  error={Boolean(meta.touched && meta.error)}
                                  value={
                                    isBrowse
                                      ? values.componentSection === 'interest'
                                        ? 'collections'
                                        : values.componentSection
                                      : values.contentType
                                  }
                                  onChange={e => {
                                    let { name, value } = e.target;
                                    // Check PromoTray to avoid duplicate
                                    if (value === ContentType.PromoTray) {
                                      if (
                                        lowerSection &&
                                        lowerSection.length > 0
                                      ) {
                                        lowerSection &&
                                          lowerSection.map((data, index) => {
                                            if (
                                              data &&
                                              data.contentType ===
                                                ContentType.PromoTray
                                            ) {
                                              if (
                                                values.contentType !== '' &&
                                                values.contentType !==
                                                  ContentType.PromoTray
                                              ) {
                                                setFieldValue(
                                                  'contentType',
                                                  values.contentType,
                                                );
                                                dispatch(
                                                  openSnackBar(
                                                    `Cannot update contentType. Type bannerImg is already available`,
                                                  ),
                                                );
                                              } else {
                                                dispatch(
                                                  openSnackBar(
                                                    `Cannot update contentType. Type bannerImg is already available`,
                                                  ),
                                                );
                                                return setFieldValue(
                                                  'contentType',
                                                  ContentType.Videos,
                                                );
                                              }
                                            } else
                                              setFieldValue(
                                                'contentType',
                                                value,
                                              );
                                          });
                                      }
                                    }

                                    //Data loss if content type is videos
                                    else if (
                                      values.contentType == 'videos' ||
                                      value == 'videos' ||
                                      values.contentType == 'instructor' ||
                                      value == 'instructor' ||
                                      values.contentType == 'bannerImg' ||
                                      value === 'bannerImg'
                                    ) {
                                      setTempFormValue({
                                        ...tempFormValue,
                                        [name]: value,
                                        fieldToChange: name,
                                        isAlertOpen: true,
                                      });
                                    } else {
                                      setTempContentValue({
                                        ...tempContentValue,
                                        [name]: value,
                                        fieldToChange: name,
                                        isAlertOpen: true,
                                      });
                                      if (isBrowse) {
                                        setTempFormValue({
                                          ...tempFormValue,
                                          [name]: value,
                                          fieldToChange: name,
                                          isAlertOpen: true,
                                        });
                                      } else {
                                        setTempContentValue({
                                          ...tempContentValue,
                                          [name]: value,
                                          fieldToChange: name,
                                          isAlertOpen: true,
                                        });
                                        if (isBrowse) {
                                          setFieldValue(
                                            'contentType',
                                            value === 'concept'
                                              ? 'concept'
                                              : 'collections',
                                          );
                                        }
                                      }
                                    }
                                  }}
                                >
                                  {possibleOptions &&
                                    possibleOptions.contentTypes &&
                                    possibleOptions.contentTypes.length > 0 &&
                                    possibleOptions.contentTypes.map(
                                      (item, index) => (
                                        <MenuItem
                                          value={item.value}
                                          key={`${item.value}${index}`}
                                        >
                                          {item.label}
                                        </MenuItem>
                                      ),
                                    )}
                                </NEInputLight>
                              )}
                            </Field>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    {!isBrowse &&
                      possibleOptions &&
                      possibleOptions.segments && (
                        <FormikAutocomplete
                          name="userSegments"
                          label="User Segment(s)"
                          placeholder="Add"
                          items={possibleOptions.segments}
                          multiple={true}
                        />
                      )}
                  </Box>
                  <Divider />
                  <Box m={2}>
                    <ItalicInfoText align="center">
                      {values.content.mode === Mode.Auto
                        ? 'Content is automatically populated'
                        : 'Manage Content'}
                    </ItalicInfoText>
                  </Box>
                  <Box m={2}>
                    {possibleOptions && possibleOptions.modes && (
                      <Field name="content.mode">
                        {({ meta }) => (
                          <NEInputLight
                            id="content.mode"
                            name="content.mode"
                            label="Mode"
                            select
                            fullWidth
                            variant="outlined"
                            helperText={meta.touched && meta.error}
                            error={Boolean(meta.touched && meta.error)}
                            value={values.content.mode}
                            onChange={handleContentTypChange}
                          >
                            {possibleOptions.modes &&
                              possibleOptions.modes.length > 0 &&
                              possibleOptions.modes.map((item, index) => (
                                <MenuItem
                                  value={item.value}
                                  key={`${item.value}${index}`}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </NEInputLight>
                        )}
                      </Field>
                    )}
                    {values.content.mode === Mode.ManuallyCurated &&
                    values.contentType === ContentType.PromoTray ? (
                      <>
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            padding: '8px 16px',
                          }}
                        >
                          <Grid item xs>
                            <Button
                              variant="contained"
                              color="secondary"
                              startIcon={<SvgIconAdd />}
                              onClick={() =>
                                addPromo({
                                  setFieldValue,
                                  content: values.content,
                                })
                              }
                            >
                              PROMO
                            </Button>
                          </Grid>
                          <Grid item xs align="right">
                            <ItalicInfoText>
                              Recommending Image in 1300x460
                            </ItalicInfoText>
                          </Grid>
                        </Grid>
                        <Divider />
                        {renderPromoDnD(values, setFieldValue, ListPromo)}
                      </>
                    ) : (
                      <>
                        {values.componentType === ComponentType.Carousel &&
                        values.content.mode === Mode.ManuallyCurated &&
                        values.contentype !== ContentType.PromoTray ? (
                          <>
                            {renderCarouselSearchComponent(
                              values,
                              setFieldValue,
                              ListContent,
                            )}
                          </>
                        ) : (
                          <div>
                            {values.contentype !== ContentType.PromoTray
                              ? renderSearchComponent({
                                  values,
                                  setFieldValue,
                                })
                              : ''}
                          </div>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </div>
            {componentFormInfo.type && (
              <>
                <Divider />
                <div className={classes.drawerFooter}>
                  {componentFormInfo.type === FormType.EDIT &&
                    componentFormInfo.isRemovable && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          setDeleteDialogInfo({
                            componentTitle: values.componentTitle,
                            componentId: values.componentId,
                            componentSection: values.componentSection,
                            contentType: values.contentType,
                            isOpen: true,
                            content: values.content,
                          })
                        }
                      >
                        Delete
                      </Button>
                    )}
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={() => setIsLoading(true)}
                  >
                    Save, Publish and Close
                  </Button>
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerBody: {
      height: 'calc(100vh - 144px)',
      overflow: 'auto',
    },
    drawerFooter: {
      padding: '16px',
      display: 'flex',
      '& .MuiButton-root': {
        marginRight: 8,
      },
    },
  }),
);
