import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  createStyles,
  makeStyles,
  TextField,
  Theme,
  withStyles,
} from '@material-ui/core';
const focusedColor = '#000';
const defaultColor = '#909599';
const defaultLabelColor = '#060B0F';
const hoverLabelColor = '#060B0F';
const focusedLabelColor = '#060B0F';
const inputHoverColor = '#E3E3E4';
const inputFocusedColor = '#060B0F';
const errorColor = '#FF1C58';
const borderColor = '#E3E3E4';

export const AutocompleteInput = withStyles({
  root: {
    marginBottom: 16,
    width: '100%',
    '& label.MuiInputLabel-outlined': {
      color: defaultColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 16px) scale(1)',
      color: defaultColor,
      fontSize: '16px',
      fontFamily: 'Proxima-Nova-Regular',
      fontWeight: '500',
      letterSpacing: '0',
      lineHeight: '20px',
    },

    '& label.Mui-focused': {
      color: focusedLabelColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.Mui-focused.Mui-error': {
      color: errorColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: borderColor,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      padding: '9px 14px',
      '& fieldset': {
        borderColor,
      },
      '&:hover fieldset': {
        borderColor: inputHoverColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${inputFocusedColor}`,
      },
    },
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        color: focusedColor,
        padding: '5.5px 14px',
        fontFamily: 'Proxima-Nova-Regular',
        fontStyle: 'italic',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      borderRadius: '6px',
      '& fieldset': {
        borderColor: errorColor,
      },
      '&:hover fieldset': {
        color: defaultColor,
        borderColor: errorColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${errorColor}`,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '4px',
      '&.Mui-error': {
        color: errorColor,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
    tag: {
      background: '#3C4042',
      color: '#fff',
      height: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    deleteIcon: {
      color: '#fff',
      width: '18px',
      opacity: 1,
      '&:hover': {
        color: '#fff',
        opacity: '0.5',
      },
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    autocompleteOptions: {
      fontFamily: 'Proxima-Nova-Regular',
    },
    autocompleteInputRoot: {},
    autocompleteRoot: {},
  }),
);

function NEAutocomplete({ options, defaultValue, filterBy = 'id', ...props }) {
  const [_value, _setValue] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    if (props.multiple) {
      const givenValue = props.value || defaultValue || [];
      const av = getSelectedValue(options, givenValue);
      _setValue(av);
    }
  }, [options, defaultValue]);

  function disableOption(value) {
    if (defaultValue && defaultValue.length > 0) {
      return !!defaultValue.find(c => c.id === value.id);
    } else if (props.value && props.value.length > 0) {
      return !!props.value.find(c => c.id === value.id);
    } else {
      return false;
    }
  }

  if (!props.multiple)
    <Autocomplete
      {...props}
      options={options}
      defaultValue={defaultValue}
      getOptionLabel={option => option.title}
      classes={{
        root: classes.autocompleteRoot,
        tag: classes.tag,
        option: classes.autocompleteOptions,
        inputRoot: classes.autocompleteInputRoot,
      }}
      ChipProps={{
        //   deleteIcon: <SvgClose />,
        classes: {
          deleteIcon: classes.deleteIcon,
          label: classes.chipLabel,
        },
      }}
      renderInput={params => (
        <AutocompleteInput
          {...params}
          variant="outlined"
          label={props.label}
          name="genres"
          placeholder={props.placeholder}
        />
      )}
    />;

  if (_value && _value.length > 0 && !props.value)
    return (
      <>
        <Autocomplete
          {...props}
          getOptionLabel={option => option.title}
          defaultValue={_value || []}
          value={_value || []}
          options={options}
          getOptionDisabled={option => disableOption(option)}
          classes={{
            root: classes.autocompleteRoot,
            tag: classes.tag,
            option: classes.autocompleteOptions,
            inputRoot: classes.autocompleteInputRoot,
          }}
          ChipProps={{
            //   deleteIcon: <SvgClose />,
            classes: {
              deleteIcon: classes.deleteIcon,
              label: classes.chipLabel,
            },
          }}
          renderInput={params => (
            <AutocompleteInput
              {...params}
              variant="outlined"
              label={props.label}
              name="genres"
              placeholder={props.placeholder}
            />
          )}
        />
      </>
    );

  return (
    <Autocomplete
      {...props}
      options={options}
      getOptionLabel={option => option.title}
      defaultValue={defaultValue || []}
      getOptionDisabled={option => disableOption(option)}
      value={_value || []}
      classes={{
        root: classes.autocompleteRoot,
        tag: classes.tag,
        option: classes.autocompleteOptions,
        inputRoot: classes.autocompleteInputRoot,
      }}
      ChipProps={{
        //   deleteIcon: <SvgClose />,
        classes: {
          deleteIcon: classes.deleteIcon,
          label: classes.chipLabel,
        },
      }}
      renderInput={params => (
        <AutocompleteInput
          {...params}
          variant="outlined"
          label={props.label}
          name="genres"
          placeholder={props.placeholder}
        />
      )}
    />
  );

  function getSelectedValue(options, defaultValue) {
    return (
      options.filter(
        a1 => defaultValue.filter(o => o[filterBy] === a1[filterBy]).length > 0,
      ) || []
    );
  }
}

export default NEAutocomplete;
