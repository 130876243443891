import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%',
      backgroundColor: 'black',
    },
    root: {
      '& #outlined-basic': {
        padding: '15.5px 14px',
      },
      '& .MuiFormLabel-root': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '22px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #49494A !important',
        '&:focus': {
          backgroundColor: 'red',
          border: '1px solid #49494A',
        },
      },
      '& .MuiInputBase-input': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '22px',
        height: 'inherit',
      },
      '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 16px) scale(1)',
        color: '#909599',
        fontSize: '16px',
        fontFamily: 'Proxima-Nova-Regular',
        fontWeight: '500',
        letterSpacing: '0',
        lineHeight: '22px',
      },
      '& label.Mui-focused': {
        color: '#909599',
      },
    },
    imgWrapper: {
      maxWidth: '328px !important',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    img: {
      width: '108px',
      height: '28px',
    },
    admin: {
      height: '28px',
      width: '119px',
      color: '#FFFFFF',
      fontFamily: 'ProximaNova-Light',
      fontSize: '39px',
      fontWeight: '300',
      lineHeight: '28px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    emailField: {
      height: '47px',
      width: '100%',
      marginBottom: '22px',
      '& #outlined-basic': {
        padding: '15.5px 14px',
      },
      '& .MuiFormLabel-root': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '22px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #49494A',
        '&:focus': {
          backgroundColor: 'red',
          border: '1px solid #49494A',
        },
      },
      '& .MuiInputBase-input': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '22px',
      },
    },
    resetField: {
      border: '1px solid #49494A',
    },
    passwordField: {
      margin: '0px',
      height: '47px',
      width: '100%',
      marginBottom: '8px',
      '& #outlined-basic': {
        padding: '15.5px 14px',
      },
      '& .MuiFormLabel-root': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '22px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #49494A',
        '&:focus': {
          backgroundColor: 'red',
          border: '1px solid #49494A',
        },
      },
      '& .MuiInputBase-input': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '22px',
      },
    },

    signinBtn: {
      width: '100%',
      transition: 'none',
    },
    description: {
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '20px',
      fontWeight: '300',
      lineHeight: '24px',
      textAlign: 'center',
      paddingTop: '23px',
      paddingBottom: '30px',
    },
  }),
);
