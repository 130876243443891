import React, { useState } from 'react';
import SearchVideo from './SearchVideo';
import request from '../shared/lib/request';
import { Divider } from '@material-ui/core';
import { getUniqItems } from '../utils/array';
import DraggableListData from './DraggableInteractiveList';
import { ErrorText } from '../components/Typography';

export interface genre {
  id: string;
  type: string;
  title: string;
}

interface SearchGenreComponentProps {
  genreList: genre[];
  id?: any;
  onChange?: (genreList) => void;
  errorMessage?: any;
}

export const SearchGenreComponent: React.FC<SearchGenreComponentProps> = ({
  genreList: defaultValue,
  onChange = () => {},
  errorMessage = () => {},
}) => {
  const [isSearchResult, setSearchGenre] = useState([]);
  const [videos, setGenres] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [inputRef, setInputRef] = useState(null);
  let timeout;

  const handleSearchInputChange = value => {
    timeout = setTimeout(() => {
      setSearchText('');
      if (inputRef) inputRef.value = '';
    }, 1000);
    if (value) {
      setSearchText(
        value.interactive && value.interactive.title
          ? value.interactive.title
          : value.title,
      );
      let uniqueList = getUniqItems([value, ...videos], 'id');
      setGenres(uniqueList);
      onChange([value, ...videos]);
    }
  };

  function searchGenres(data) {
    return request({
      url: `/neouadmin/v1/search-genre/${data}`,
      method: 'GET',
    });
  }
  const onSearchChange = async value => {
    try {
      let result = await searchGenres(value);
      setSearchGenre(result.result);
    } catch (error) {
      setSearchGenre([]);
    }
  };

  React.useEffect(() => {
    setGenres(defaultValue);
    return () => {
      clearTimeout(timeout);
    };
  }, [defaultValue]);

  return (
    <div style={{ width: '100%' }}>
      <SearchVideo
        options={isSearchResult || []}
        name="searchVideos"
        labelText="Add an Item: Search for Genre title"
        inputValue={searchText}
        onChange={(event, value) => handleSearchInputChange(value)}
        limitTags={2}
        onInputChange={(event, value) => onSearchChange(value)}
        getOptionLabel={option =>
          `${option.title ? option.title : option.id || ''}`
        }
        onSearchTextChange={e => setSearchText(e)}
        ref={inputRef}
        setInputRef={ref => setInputRef(ref)}
        blurOnSelect
        clearOnBlur
      />
      <ErrorText>
        <h2>{errorMessage()}</h2>
      </ErrorText>
      <Divider style={{ backgroundColor: '#999FA4', marginBottom: 8 }} />
      <DraggableListData
        list={videos}
        onListChange={(list, updateType) => {
          setGenres(list);
          onChange(list);
        }}
      />
    </div>
  );
};
