import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import request from '../../shared/lib/request';
import {
  setLoading,
  storeCoupon,
  storePackages,
  setCouponPaginationLoader,
  couponListFailed,
  couponListPaginationSuccess,
  putCouponPagination,
  couponListCountSuccess,
} from './actions';
import ActionTypes from './constants';
import { openSnackBar } from '../Root/actions';
import { selectInteractiveCouponDomain } from './selectors';

const SLICE_COUNT_OF_CLASSES = 20;

// Individual exports for testing
export default function* interactiveCouponSaga() {
  yield takeLatest(ActionTypes.REQUEST_PROMO, getPromo);
  yield takeLatest(ActionTypes.REQUEST_PACKAGES, getPackages);
  yield takeLatest(
    ActionTypes.REQUEST_COUPON_LIST_PAGINATION,
    getCouponListPagination,
  );
}

// get promo list
export function* getPromo(action: AnyAction) {
  try {
    const couponState = yield select(selectInteractiveCouponDomain);
    let { searchKey = '' } = couponState;
    let url = `/neouadmin/v1/get-promocode`;
    if (searchKey !== '') {
      url = `/neouadmin/v1/get-promocode?keyword=${searchKey}`;
    }
    yield put(setLoading(true));
    // 1. Fetch data from API
    let { result, total } = yield call(() =>
      request({
        url: url,
        method: 'POST',
      }),
    );
    yield put(storeCoupon(result));
    yield put(couponListCountSuccess(total));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    if (error) {
      yield put(openSnackBar('Request Coupons Failed!'));
    }
  }
}

// get packages list
export function* getPackages(action: AnyAction) {
  try {
    yield put(setLoading(true));
    // 1. Fetch data from API
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/get-packages-list`,
        method: 'GET',
      }),
    );
    yield put(storePackages(result));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    if (error) {
      yield put(openSnackBar('Request Packages Failed!'));
    }
  }
}

export function* getCouponListPagination(action) {
  yield put(setCouponPaginationLoader(true));
  try {
    let { total, couponList, searchKey } = yield select(
      selectInteractiveCouponDomain,
    );
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/get-promocode?offset=${action.payload}&limit=${SLICE_COUNT_OF_CLASSES}`,
        method: 'POST',
      }),
    );
    const data = [...couponList, ...result];
    yield put(storeCoupon(data));
    yield delay(1000);
    yield put(putCouponPagination());
    yield put(couponListPaginationSuccess([]));
  } catch (error) {
    yield put(couponListFailed());
    yield put(setCouponPaginationLoader(false));
  }
}
