import * as React from 'react';
import styled from 'styled-components';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';

import { createStructuredSelector } from 'reselect';
// import makeSelectAuthentication from './selectors';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useDispatch } from 'react-redux';
import saga from '.././saga';
import reducer from '.././reducer';
// import { login } from './action';
import LabelInput from '../../../components/LabelInput';
import AddButton from '../../../components/AddButton';
import TextField from '@material-ui/core/TextField';
import CancelButton from '../../../components/CancelButton';

import { LiveEvent } from '../../../validators/Authentication';
import { formError, customMessage } from '../../../utils/validator';
import { addLiveEvent } from '.././actions';
import moment from 'moment';
import StartDateTime from '../../../components/Formik/StartDateTime';
import { openSnackBar } from '../../Root/actions';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },

  common: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  startTime: {
    width: '55%',
  },
  durations: {
    width: '40%',
    '& label.Mui-focused': {
      color: '#909599',
      '&.Mui-error': {
        color: '#FF1C58',
      },
    },
    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#909599',
      },
      '&:hover fieldset': {
        borderColor: '#909599',
      },
      '&.Mui-focused fieldset': {
        border: `1px solid #909599`,
      },
    },
  },

  root: {},
}));

export default function AddLiveEvent(props, videoType) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'video', reducer: reducer });
  useInjectSaga({ key: 'video', saga: saga });

  const dispatch = useDispatch();
  const classes = useStyles();
  videoType = videoType;
  // let { duration } = props;
  const [error, setError] = React.useState({
    liveStreamtitle: '',
    liveStreamURL: '',
    liveStreamDuration: '',
  });
  const [liveStreamtitle, setLiveStreamtitle] = React.useState('');
  const [liveStreamURL, setLiveStreamURL] = React.useState('');
  const [liveStreamDuration, setLiveStreamDuration] = React.useState('');
  const [durationData, setDurationData] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [duration, setDuration] = React.useState([
    { title: 5 },
    { title: 10 },
    { title: 15 },
    { title: 20 },
    { title: 30 },
    { title: 45 },
    { title: 60 },
    { title: 90 },
  ]);

  function handleTitleInput(e) {
    setLiveStreamtitle(e.target.value);
  }
  function handleStreamURLInput(e) {
    setLiveStreamURL(e.target.value);
  }

  function handleDurationInput(e) {
    setLiveStreamDuration(e.target.value);
    setDurationData(e.target.value);
  }

  const validateForm = () => {
    const { error, value } = LiveEvent.validate(
      { liveStreamtitle, liveStreamURL, liveStreamDuration },
      {
        abortEarly: false,
        messages: customMessage,
      },
    );
    return formError(error);
  };

  const onAddEvent = () => {
    const e = validateForm();
    setError(e);
    let currenTimeStamp = moment().valueOf();
    const data = {
      liveStreamUrl: liveStreamURL,
      duration: liveStreamDuration,
      isPublished: false,
      title: liveStreamtitle,
      startDate: moment(selectedDate)
        .utc()
        .valueOf()
        .toString(),
      permaLink: `/videos/${currenTimeStamp}`,
      videoType: 'live',
      isHidden: false,
    };
    if (Object.keys(e).length === 0) {
      let currentTime = moment(Date.now());
      let startTime = moment(selectedDate);
      let isStarted = startTime.diff(currentTime) >= 30000;
      if (!isStarted) {
        dispatch(openSnackBar('Live video should have future time'));
        return;
      } else {
        dispatch(addLiveEvent(data));
        props.onClearEvent();
      }
    }
  };

  function onClearEvent() {
    props.onClearEvent();
  }

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <LabelInput
          label="Title"
          placeholder="Please Enter a Title"
          variant="outlined"
          id="custom-css-outlined-input"
          fullWidth={true}
          onChange={e => handleTitleInput(e)}
          error={!!error.liveStreamtitle}
          helperText={error['liveStreamtitle']}
        />
        <LabelInput
          label="Live Stream URL"
          placeholder="Enter Live Stream URL"
          variant="outlined"
          id="custom-css-outlined-input"
          fullWidth={true}
          onChange={e => handleStreamURLInput(e)}
          error={!!error.liveStreamURL}
          helperText={error['liveStreamURL']}
        />
        <LabelInput
          id="select"
          variant="outlined"
          label="Duration"
          //className={classes.durations}
          select
          value={durationData}
          onChange={e => handleDurationInput(e)}
          error={!!error.liveStreamDuration}
          helperText={error['liveStreamDuration']}
        >
          {duration &&
            duration.length > 0 &&
            duration.map(itm => {
              return <MenuItem value={itm.title}>{itm.title} Mins</MenuItem>;
            })}
        </LabelInput>

        <div className={classes.common}>
          <StartDateTime date={selectedDate} selectedValue={setSelectedDate} />
        </div>

        <div className={classes.common}>
          <CancelButton
            key={1}
            title={'CANCEL'}
            clearAll={true}
            onClick={onClearEvent}
          />
          <AddButton title={'ADD LIVE EVENT'} onClick={e => onAddEvent()} />
        </div>
      </Grid>
    </div>
  );
}
