import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the liveClass state domain
 */

const selectLiveClassDomain = (state: ApplicationRootState) => {
  return state.liveClass || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by LiveClass
 */

const makeSelectLiveClass = () =>
  createSelector(
    selectLiveClassDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectLiveClass;
export { selectLiveClassDomain };
