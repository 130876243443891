import React from 'react';
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

interface StyledTabProps {
  label: string;
}

const StyledTabs = withStyles({
  root: {
    borderBottom: '1px solid #E3E3E4',
  },
  indicator: {
    backgroundColor: '#060B0F',
  },
})(Tabs);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "fontFamily": ['Proxima-Nova-Regular', 'sans-serif'].join(','),
      "opacity": 1,
      "color": '#12161A',
      "fontSize": '16px',
      "lineHeight": '16px',
      "textAlign": 'center',
      "fontWeight": 500,
      "letterSpacing": '1px',
      "textTransform": 'uppercase',
      '&:hover': {
        color: '#12161A',
        opacity: 1,
      },
      '&$selected': {
        color: '#12161A',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#12161A',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export default StyledTabs;
export { StyledTab };
