import * as React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LabelInput from '../../../components/LabelInput';
import AddButton from '../../../components/AddButton';
import CancelButton from '../../../components/CancelButton';
import { formError, customMessage } from '../../../utils/validator';
import StyledDialog from '../../../components/StyledDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { openSnackBar } from '../../Root/actions';
import request from '../../../shared/lib/request';
import { baseUrl, uploadBaseUrl } from '../../../utils/config';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },

  common: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export default function ReplaceVideoUrl(props) {
  let { videoId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = React.useState({
    videoUrl: '',
  });
  const [videoUrl, setVideoUrl] = React.useState('');
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [payload, setPayload] = React.useState({});

  function handleTitleInput(e) {
    setVideoUrl(e.target.value);
  }

  const onReplaceEvent = () => {
    if (videoUrl === '') {
      setError({
        videoUrl: 'videoUrl is required to replace video',
      });
    } else {
      let data = {
        id: videoId,
        url: videoUrl,
      };
      showAlertToClose(true);
      setPayload(data);
    }
  };

  const onSubmit = async () => {
    try {
      fetch(`${uploadBaseUrl}/neou/v1/rencode-video`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: videoId,
          url: videoUrl,
        }),
      });
      dispatch(openSnackBar('Replacing Video is in-progress...'));
      props.onClearEvent();
    } catch (error) {
      dispatch(
        openSnackBar(
          `Replace Video Failed -  ${error.data && error.data.message}`,
        ),
      );
    }
  };

  return (
    <div>
      <StyledDialog
        isOpen={props.isOpen}
        title={props.title}
        handleClose={() => {
          props.onClearEvent();
        }}
      >
        <div className={classes.container}>
          <>
            <Grid container justify="center">
              <LabelInput
                label="Video Url"
                placeholder="Please Enter a Video URL"
                variant="outlined"
                id="custom-css-outlined-input"
                defaultValue={videoUrl}
                fullWidth={true}
                onChange={e => handleTitleInput(e)}
                error={!!error.videoUrl}
                helperText={error['videoUrl']}
              />
              <div className={classes.common}>
                <CancelButton
                  key={1}
                  title={'CANCEL'}
                  clearAll={true}
                  onClick={() => {
                    props.onClearEvent();
                  }}
                />
                <AddButton title={'SAVE '} onClick={e => onReplaceEvent()} />
              </div>
            </Grid>
            {isCloseAlertOpen && (
              <ConfirmDialog
                isOpen={isCloseAlertOpen}
                headerText="Replacing Video. Are you sure?"
                bodyText={`On replacing the video url the video will be replace for this track`}
                onAgree={() => onSubmit()}
                handleClose={() => showAlertToClose(false)}
                onDisagree={() => showAlertToClose(false)}
              ></ConfirmDialog>
            )}
          </>
        </div>
      </StyledDialog>
    </div>
  );
}
