import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import moment from 'moment';
import { Upcoming } from './Upcoming';
import { getTimeWithZone } from '../utils/calender';

const useStyles = makeStyles(theme => ({
  dayTitleWrapper: {
    background: '#F1F2F5',
    borderTop: '1px solid #161D22',
    paddingLeft: '16px',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
  },
  stickyTitle: {
    position: 'sticky',
    top: -1,
    zIndex: 1000,
  },
  stickyDate: {
    position: 'sticky',
    top: -1,
    zIndex: 1,
  },
  dayTitle: {
    fontFamily: 'ProximaNova-Bold, Helvetica, Arial, sans-serif',
    textTransform: 'uppercase',
    margin: 0,
    fontSize: '13px',
    lineHeight: '24px',
    [theme.breakpoints.up('xl')]: {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  noClassesScheduled: {
    color: '#8B949B',
    fontFamily: 'Proxima-Nova-Regular, Helvetica, Arial, sans-serif',
    fontSize: '14px',
    padding: '20px',
    fontStyle: 'italic',
    lineHeight: '18px',
    textAlign: 'center',
  },
}));
export interface IClassItemProps {
  dayCount: any;
  videoDetails: any;
  classItems: any;
}
export const ClassCalenderList = ({ classItems, Selected }) => {
  const classes = useStyles();
  const [timeStamb, setTimeStamb] = React.useState({});
  var time = 0;

  useEffect(() => {
    if (time === 0) {
      var timer = setInterval(() => {
        setTimeStamb(moment());
      }, 10000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  return (
    <>
      {classItems &&
        Object.keys(classItems)
          .sort()
          .map((dayCount, index) => {
            const classItemDetails: any = classItems[dayCount];

            return (
              <div key={`daily_class_wrapper_${index}`}>
                <div
                  id={dayCount}
                  key={dayCount}
                  style={{
                    background: 'transparent',
                    height: 'auto',
                  }}
                  className={`${classItemDetails.length === 0}`}
                >
                  <div
                    className={`${
                      Selected === dayCount
                        ? classes.stickyTitle
                        : classes.stickyDate
                    }`}
                  >
                    <div className={`${classes.dayTitleWrapper}`}>
                      <div
                        //  style={{ position: (Selected === dayCount) ? 'fixed' : 'none' }}
                        className={classes.dayTitle}
                      >
                        {moment(Date.now()).format('dddd, MMM D') ===
                        moment(dayCount).format('dddd, MMM D')
                          ? `TODAY`
                          : moment(dayCount).format('dddd, MMM D')}
                      </div>
                    </div>
                  </div>
                  <UpcomingItem
                    time={timeStamb}
                    classItemDetails={classItemDetails}
                  />
                </div>
              </div>
            );
          })}
    </>
  );
};

function UpcomingItem({ classItemDetails, time }) {
  const classes = useStyles();
  return (
    <>
      {classItemDetails.length === 0 && (
        <div className={classes.noClassesScheduled}> No Classes Scheduled </div>
      )}
      {classItemDetails &&
        classItemDetails.length > 0 &&
        classItemDetails.map((item, index) => {
          let end_time = moment(new Date(+item.startDate))
            .add(item.duration, 'minutes')
            .format('HH:mm A');
          let start_time = moment(new Date(+item.startDate))
            .add(0, 'minutes')
            .format('HH:mm A');
          let timezone = getTimeWithZone(+item.startDate);
          return (
            <Upcoming
              time={time}
              key={index}
              isPublishedClasses={true}
              classItem={item}
              start_time={start_time}
              end_time={end_time}
              time_zone={timezone}
              instructor={{
                firstName:
                  item &&
                  Array.isArray(item.instructor) &&
                  item.instructor.length > 0 &&
                  item.instructor[0].firstName
                    ? item.instructor[0].firstName
                    : '',
                lastName:
                  item &&
                  Array.isArray(item.instructor) &&
                  item.instructor.length > 0 &&
                  item.instructor[0].lastName
                    ? item.instructor[0].lastName
                    : '',
              }}
            />
          );
        })}
    </>
  );
}
