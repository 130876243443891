import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  SvgIconPlayArrow,
  SvgIconEdit,
  SvgIconAlert,
  SvgLoaderGif,
} from '../../../components/NeIcons';
import moment from 'moment';
import { useStyles } from './styles';
import { IconButton, CircularProgress } from '@material-ui/core';
import VideoFormDialog from '../components/VideoFormDialog';
import { useDispatch } from 'react-redux';
import { reEncodeVideo } from '../actions';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { StatusText } from '../../../components/Typography';

export default function VideoListTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { list, isLoading = false } = props;
  const [editVideoInfo, setEditVideoInfo] = React.useState({
    isOpen: false,
    id: '',
  });
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [payload, setPayload] = React.useState('');
  const getDate = list => {
    if (list.isPublished) {
      return (
        <div>
          {`Updated ${moment(list.updatedAt).format(`L`)}`} <br />
          {list.publishedDate
            ? `Published ${moment(list.publishedDate).format(`L`)}`
            : ''}
        </div>
      );
    } else {
      return `Saved ${moment(list.updatedAt).format(`L`)}`;
    }
  };

  const renderCTA = ({
    isPublished = false,
    isHidden = false,
    isError = false,
  }) => {
    if (isError) {
      return <div></div>;
    }
    if (isHidden) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'HIDDEN'}
        </div>
      );
    } else if (isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'PUBLISHED'}
        </div>
      );
    } else if (!isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.draft}`}>
          {'DRAFT'}
        </div>
      );
    }
  };

  const getVideoTitle = list => {
    if (list.title.includes('http')) {
      return '';
    } else {
      return list.title;
    }
  };

  const getVideoType = list => {
    if (list.videoType === 'class') {
      return 'Class';
    } else if (list.videoType === 'live') {
      return `Live Class: ${moment(+list.startDate).format(`L`)}`;
    } else if (list.videoType === 'generic') {
      return 'Generic Video';
    }
  };

  const getUpcomingLive = list => {
    let currentTime = moment(Date.now());
    let startTime = moment(new Date(+list.startDate));
    let isStarted = currentTime.diff(startTime, 'minutes') <= 0;
    return isStarted;
  };

  function reEncode(list) {
    setPayload(list.id);
    showAlertToClose(true);
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead />
          <TableBody>
            {isLoading ? (
              <div style={{ padding: '10px' }}>
                <SvgLoaderGif />
              </div>
            ) : (
              <>
                {list &&
                  list.map(list => {
                    return (
                      <TableRow
                        key={list.id}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <TableCell align="left">
                          <div className={classes.firstColumn}>
                            <div className={classes.box}>
                              <SvgIconPlayArrow />
                            </div>
                            <div className={classes.firstCoulmContent}>
                              <div className={classes.conceptTitle}>
                                {/* {getVideoTitle(list)} */}
                                {list.title}
                              </div>
                              <div className={classes.text}>
                                {list.concept && list.concept.title}
                              </div>
                              {list.videoType === 'live' &&
                              getUpcomingLive(list) ? (
                                <StatusText>Upcoming</StatusText>
                              ) : (
                                ''
                              )}
                              <div className={classes.text}>
                                {getVideoType(list)}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className={classes.text}>{getDate(list)}</div>
                        </TableCell>
                        <TableCell align="center">{renderCTA(list)}</TableCell>
                        <TableCell align="right">
                          {list.isError && list.videoStatus !== 'processing' ? (
                            <div style={{ cursor: 'pointer' }}>
                              <SvgIconAlert onClick={() => reEncode(list)} />
                            </div>
                          ) : list.videoStatus == 'processing' ? (
                            <CircularProgress color="secondary" value={25} />
                          ) : (
                            <IconButton
                              onClick={() => {
                                // setActiveVideo(list.id);
                                // openEdiDialog(true);
                                setEditVideoInfo({ isOpen: true, id: list.id });
                              }}
                              classes={{ root: classes.editIcon }}
                            >
                              <SvgIconEdit />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {editVideoInfo.isOpen && (
        <VideoFormDialog
          isOpen={editVideoInfo.isOpen}
          id={editVideoInfo.id}
          handleClose={() => setEditVideoInfo({ isOpen: false, id: '' })}
        />
      )}

      {isCloseAlertOpen && (
        <ConfirmDialog
          isOpen={isCloseAlertOpen}
          headerText="Are you sure?"
          bodyText="You are about to Re-Encode the Video Again."
          onAgree={() => dispatch(reEncodeVideo(payload))}
          handleClose={() => showAlertToClose(false)}
          onDisagree={() => showAlertToClose(false)}
        ></ConfirmDialog>
      )}
    </Paper>
  );
}
