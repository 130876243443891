/*
 *
 * Root
 *
 */

import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CookiesProvider } from 'react-cookie';
// import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectRoot from './selectors';
import reducer from './reducer';
import saga, { fetchApiToken } from './saga';
import authenticationSaga from '../Authentication/saga';

import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { LightTheme, BaseProvider } from 'baseui';

import GlobalFonts from '../../fonts/font';
import GlobalStyle from '../../global-styles';

import NotFoundPage from '../../containers/NotFoundPage/Loadable';
import { Protected } from '../../layouts/Protected';
import {
  requestAgeGroup,
  fetApiToken,
  toggleAuthModalVisibility,
} from './actions';
import '../../fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import MainModal from '../../components/MainModal';
import Authentication from '../Authentication';
import ResetPassword from '../Authentication/ResetPassword';
import NewPassword from '../Authentication/NewPassword';
import SnackbarUI from '../../components/Snackbar';
import NotFound from '../NotFound';
import Video from '../Video';
import Alert from '../../components/Alert';
// import ConceptLandingPage from '../ConceptLandingPage';
import ProgramDetail from '../ProgramDetail';
import InstructorLandingPage from '../People/Preview';
import ConceptLandingPage from '../Concept/Preview';
import { requestUserInfo } from '../Authentication/action';
import Cookies from 'universal-cookie';
import ClassPreview from '../InteractivePublishedClasses/ClassPreview';

const stateSelector = createStructuredSelector({
  root: makeSelectRoot(),
});

interface Props { }
const engine = new Styletron();
function Root(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'root', reducer: reducer });
  useInjectSaga({ key: 'root', saga: saga });
  useInjectSaga({ key: 'authentication', saga: authenticationSaga });

  const { root } = useSelector(stateSelector);
  const { isAuthModalOpen, alertModalOpen } = root;
  const dispatch = useDispatch();

  const THEME = {
    light: 'light',
    dark: 'dark',
  };
  const [] = React.useState(THEME.light);
  const { isSnackBarOpen, snackBarMsg } = root;

  React.useEffect(() => {
    const cookies = new Cookies();

    let TOKEN = cookies.get('apiToken');
    if (!TOKEN) {
      dispatch(fetApiToken());
    }

    let isAuthenticated = !!cookies.get('adminToken');
    if (isAuthenticated) {
      dispatch(requestUserInfo());
    }
  }, []);

  const onLogIn = () => {
    setTimeout(() => {
      dispatch(toggleAuthModalVisibility());
    }, 100);
  };

  const NoMatch = ({ location }) => (
    <div className={classes.noMatch}>
      <h3>You have no permissions for this page</h3>
    </div>
  );
  return (
    <>
      <Helmet>
        <title>Neou Fitness</title>
        <meta name="description" content="Description of Root" />
      </Helmet>
      <CookiesProvider>
        <BrowserRouter>
          <Route path="/signin" component={Authentication} />
          <Route path="/reset" component={ResetPassword} />
          <Route path="/new-password" component={NewPassword} />
          <Route path="/concept-preview/:id/:url" component={ConceptLandingPage} />
          {/* <Route path="/preview/interest/:id" component={ConceptLandingPage} /> */}
          <Route path="/preview/collection/:id/:url" component={ProgramDetail} />
          <Route path="/people-preview/:id/:url" component={InstructorLandingPage} />
          <Route path="/preview/group-class/:id" component={ClassPreview} />
          <Route path="/notfound" component={NotFound} />
          <Route path="/" component={Protected} />
          <Route path="/neou-admin" component={Protected} />
          {/* <Route
            render={() => <Redirect to={{ pathname: '/neou/videos' }} />}
          /> */}
          {/* <Route
            render={() => (
              <Redirect
                to={{
                  pathname: '/signin',
                }}
              />
            )}
          ></Route> */}
        </BrowserRouter>
      </CookiesProvider>
      <SnackbarUI isOpen={isSnackBarOpen} msg={snackBarMsg} />
      <Alert alertData={alertModalOpen} />
      <GlobalFonts />
      <GlobalStyle />
    </>
  );
}

export default memo(Root);
