/*
 *
 * LiveClass
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectLiveClass from './selectors';
import reducer from './reducer';
import saga from './saga';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { requestLiveVideoList } from './actions';
import LiveVideoListTable from './LiveVideoTable/LiveClassTable';
import Loader from '../../components/Loader';

const stateSelector = createStructuredSelector({
  liveClass: makeSelectLiveClass(),
});

interface Props {}

function LiveClass(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'liveClass', reducer: reducer });
  useInjectSaga({ key: 'liveClass', saga: saga });
  const classes = useStyles();
  const { liveClass } = useSelector(stateSelector);
  const { currentLiveClass = {}, isLoading } = liveClass;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(requestLiveVideoList());
  }, []);

  return (
    <div>
      {isLoading && <Loader />}
      <Helmet>
        <title>Live Class</title>
        <meta name="description" content="Description of LiveClass" />
      </Helmet>
      <Box p={2}>
        <div className={classes.root}>
          <div>
            <div className={classes.headWrap}>
              <div className={classes.head}>Library: Live Class</div>
            </div>
          </div>
          {currentLiveClass && currentLiveClass.id ? (
            <LiveVideoListTable list={currentLiveClass} />
          ) : (
            <div className={classes.noData}>No Live Class Found Now !</div>
          )}
        </div>
      </Box>
    </div>
  );
}

export default LiveClass;
