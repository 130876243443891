import { Divider, IconButton } from '@material-ui/core';
import MaterialList from '@material-ui/core/List';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useStyles } from '../index';

export const DrawerView = ({ handleClose, title, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.list} role="presentation">
      <MaterialList>
        <div className={classes.title}>{title}</div>
        <div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </MaterialList>

      <Divider />
      {children}
    </div>
  );
};
