import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '50px 20px',
    },
    head: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    headWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 0px',
    },

    styledTab: {
      '& .MuiTabs-centered': {
        justifyContent: 'space-between',
        borderTop: '1px solid #000000',
      },
    },
    noData: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      padding: '20px',
    },

    container: {
      maxHeight: '100%',
    },
    box: {
      height: '64px',
      width: '64px',
      borderRadius: '6px',
      backgroundColor: '#D4D8DC',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: '18px',
        height: '24px',
      },
    },
    saveLegalBtn: {
      padding: '0px',
      height: '40px',
      width: '220px',
      borderRadius: '6px',
      backgroundColor: '#12161A',
    },
    newLegal: {
      paddingTop: '10px',
    },
    text: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      float: 'left',
      width: '63%',
    },
    legalTxt: {
      overflow: 'scroll !important',
      width: '100%',
      maxHeight: '330px',
      outline: 'none',
      padding: '10px',
      resize: 'none',
    },
  }),
);
