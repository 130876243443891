import { createGlobalStyle } from './styles/styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    font-family: 'Proxima-Nova-Regular';
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: 100%;
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #222A31;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #35414A !important;
  }

  .ps__thumb-y {
    width: 4px !important;
    border-radius: 3px !important;
    background-color: #222A31 !important;
    height: 90px !important;
    right: 0px !important;
  }

  .ps__rail-y {
    opacity: 1 !important;
  }

  .ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
    width: 4px !important;
    border-radius: 3px !important;
    background-color: #35414A !important;
    height: 90px !important;
  }

  .ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
  }
`;

export default GlobalStyle;
