import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '50px 20px',
    },
    head: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    headWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 0px',
    },
    addConceptBtn: {
      padding: '0px',
      height: '40px',
      width: '162px',
      borderRadius: '6px',
      backgroundColor: '#12161A',
    },
    newPeople: {
      paddingLeft: '8px',
    },
    addbtnWrap: {
      display: 'flex',
      width: '340px',
      justifyContent: 'space-between',
    },
    styledTab: {
      '& .MuiTabs-centered': {
        justifyContent: 'space-between',
        borderTop: '1px solid #000000',
      },
    },
    noData: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      padding: '20px',
    },
    searchWrap: {
      '& .MuiFormControl-root': {
        minWidth: '240px',
        fontFamily: 'Proxima-Nova-Regular',
        minHeight: '38px',
        height: '38px',
      },
      '& .MuiInputBase-root': {
        maxWidth: '240px',
        width: '241px',
        boxSizing: 'border-box',
        fontFamily: 'Proxima-Nova-Regular',
        paddingLeft: '8px',
        height: '38px',
        '& .MuiInputBase-input': {
          color: '#060B0F !important',
          fontFamily: 'Proxima-Nova-Regular',
          fontSize: '16px',
          fontWeight: 500,
          letterSpacing: 0,
          lineHeight: '20px',
          padding: '0px !important',
        },
      },
      '& .MuiOutlinedInput-root fieldset': {
        border: '1px solid #E3E3E4',
        borderColor: '#E3E3E4',
      },
      '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    loadMoreBtn: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px',
    },
  }),
);
