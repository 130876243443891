import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '0px 8px',
    fontFamily: 'Proxima-Nova-Regular',
    '& .MuiSlider-root': {
      color: '#3E4142',
    },
    '& .PrivateValueLabel-label-285': {
      color: '#000',
      transform: 'rotate(45deg)',
    },
    '& .MuiSlider-rail': {
      height: '6px',
      borderRadius: '3px',
    },
    '& .MuiSlider-track': {
      height: '6px',
      borderRadius: '3px',
    },
    '& .MuiSlider-thumb': {
      height: '20px',
      width: '20px',
      color: '#3E4142',
    },
    '& .MuiSlider-valueLabel': {
      display: 'none',
    },
    '& .MuiSlider-mark': {
      display: 'none',
    },
  },
  holder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flexStart',
    color: '#46484A',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '14px',
  },
});

export default function FilterRangeSlider(props) {
  const classes = useStyles();
  const val = [props.value[0], props.value[props.value.length - 1]];
  const handleChange = (event, newValue) => {
    props.onChange(newValue);
  };

  if (props && props.value && props.value.length) {
    return (
      <div className={classes.root}>
        <Slider
          value={[props.value[0], props.value[props.value.length - 1]]}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          step={5}
          min={5}
          max={90}
          style={{ width: '99%' }}
        />
        <div className={classes.holder}>
          <div>{props.value[0]} Min</div>
          <div>{props.value[props.value.length - 1]} Min</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
