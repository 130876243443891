/*
 *
 * InteractiveCoupon actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const setLoading = data => {
  return action(ActionTypes.SET_LOADING, data);
};

export const storeCoupon = data => {
  return action(ActionTypes.STORE_COUPON, data);
};

export const setSearchKey = key => {
  return action(ActionTypes.GET_SEARCH_RESULT, key);
};

export const setCouponPaginationLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_COUPON_PAGINATION_LOADER, isLoading);
};

export const requestCouponListPagination = payload => {
  return action(ActionTypes.REQUEST_COUPON_LIST_PAGINATION, payload);
};

export const storePackages = data => {
  return action(ActionTypes.STORE_PACKAGES, data);
};

export const requestPromoList = () => {
  return action(ActionTypes.REQUEST_PROMO);
};

export const requestPackagesList = () => {
  return action(ActionTypes.REQUEST_PACKAGES);
};

export const couponListSuccess = couponList => {
  return action(ActionTypes.COUPON_LIST_SUCCESS, couponList);
};

export const couponListFailed = () => {
  return action(ActionTypes.COUPON_LIST_FAILED);
};

export const couponListPaginationSuccess = payload => {
  return action(ActionTypes.COUPON_LIST_PAGINATION_SUCCESS, payload);
};

export const putCouponPagination = () => {
  return action(ActionTypes.PUT_COUPON_LIST_PAGINATION_SUCCESS);
};

export const couponListCountSuccess = (total: number) => {
  return action(ActionTypes.COUPON_LIST_TOTAL, total);
};
