import React from 'react';
import {
  Box,
  Divider,
  FormControlLabel,
  Typography,
  Button,
  Grid,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import Joi from '@hapi/joi';
import { GreenCheckbox } from '../../../components/GreenCheckbox';
import request from '../../../shared/lib/request';
import FormikField from '../../../components/Formik/FormikField';
import { RecommendedCollection } from './RecommendedCollection';
import { ErrorText } from '../../../components/Typography';
import Loader from '../../../components/Loader';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import { SearchCarouselWithDnD } from '../../../components/SearchCarouselWithDnD';
import { getUniqItems } from '../../../utils/array';
import ConfirmDialog from '../../../components/ConfirmDialog';

export const useStyles = makeStyles({
  drawerFooter: {
    width: '650px',
    padding: '16px',
    display: 'flex',
    position: 'fixed',
    bottom: '0',
    background: '#fff',
    '& .MuiButton-root': {
      marginRight: 8,
    },
  },
});

const FitnessGoalSchema = Joi.object({
  title: Joi.string()
    .required()
    .min(3),
});

const confirmFormChange = ({ setFieldValue }) => {
  setFieldValue('content.data', []);
};

const addContentList = (list, setFieldValue) => {
  let uniqItems = getUniqItems(list, 'id');
  setFieldValue('content.data', uniqItems);
};

function renderCarouselSearchComponent(
  values: any,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  contents,
  contentType,
) {
  return (
    <SearchCarouselWithDnD
      contentList={contents || []}
      type={values && values.contentType}
      listItem={RecommendedCollection}
      contentType={
        values && values.contentType ? values.contentType : contentType
      }
      onChange={list => addContentList(list, setFieldValue)}
    />
  );
}

export class FitnessProfileForm extends React.Component {
  state = {
    goalInfo: {},
    isLoading: false,
    contents: [],
    searchText: '',
    inputRef: null,
    programDetails: {},
    classDetails: {},
    isProgramError: false,
    isError: false,
    isAlertOpen: false,
    isSearchResult: [],
  };

  componentDidMount() {
    this.fetchFitnessGoalById(this.props && this.props.id);
  }

  async fetchFitnessGoalById(id) {
    if (id) {
      let goalInfo = {};
      let contents = [];
      try {
        const response = await request({
          url: `/neouadmin/v1/get-recommend`,
          method: 'GET',
          params: {
            id,
          },
        });
        goalInfo = response.result;
        let contentData =
          goalInfo && goalInfo.programDetails
            ? goalInfo.programDetails
            : goalInfo && goalInfo.classDetails
            ? goalInfo.classDetails
            : {};
        if (contentData) {
          contents = [contentData];
        }
      } catch (error) {
      } finally {
        this.setState({
          isLoading: false,
          goalInfo,
          contents,
        });
      }
    }
  }

  async saveProfile(data) {
    try {
      this.setState({
        isLoading: true,
      });
      const { result } = await request({
        url: `/neouadmin/v1/update-recommend?`,
        method: 'PUT',
        data,
      });
      this.setState({
        isLoading: false,
      });
      this.setState({
        isSnackBarOpen: true,
        snackBarMsg: 'Fitness Goal updated successfully',
      });
      this.props &&
        this.props.onSubmit(true, 'Fitness Goal updated successfully');
      this.props && this.props.closeDrawer('right', false);
    } catch ({ data }) {
      this.setState({
        isLoading: false,
      });
      this.props && this.props.onSubmit(false, data.error || data.message);
    }
  }

  onFitnessSubmit = values => {
    let contentData =
      values && values.content && values.content.data && values.content.data
        ? values.content.data
        : this.state.contents;
    if (contentData && contentData.length === 1) {
      let programData = contentData && contentData[0];
      this.setState({
        isProgramError: false,
        programDetails: programData,
        classDetails: programData,
      });

      let updateData = {
        id: this.props.id,
        title: values.title,
        isVisible: values.isVisible,
        isPublished: true,
        programId: programData.id,
        classId: programData.id,
        isProgramError: false,
      };
      if (programData && programData.videoType === 'class') {
        delete updateData.programId;
      } else {
        delete updateData.classId;
      }
      this.saveProfile(updateData);
    } else {
      this.setState({
        isProgramError: true,
      });
    }
  };

  render() {
    const { closeDrawer } = this.props;
    const {
      goalInfo,
      isLoading,
      contents,
      isProgramError,
      isError,
      inputRef,
      isSearchResult,
      searchText,
      isAlertOpen,
    } = this.state;
    let contentType =
      goalInfo && goalInfo.programDetails
        ? goalInfo.programDetails.collectionType === 'concept'
          ? 'concepts'
          : 'collections'
        : 'videos';
    const possibleOptions = [
      { label: 'videos', value: 'videos' },
      { label: 'concepts', value: 'concepts' },
      { label: 'collections', value: 'collections' },
    ];

    return (
      <>
        {!isLoading && goalInfo && goalInfo.id ? (
          <Box p={2}>
            <Formik
              initialValues={goalInfo}
              enableReinitialize
              validationSchema={FitnessGoalSchema}
              onSubmit={() => {
                this.onFitnessSubmit;
              }}
            >
              {({
                values,
                setFieldValue,
                submitForm,
                errors,
                isValid,
                setErrors,
                validateForm,
                setTouched,
              }) => {
                return (
                  <Form noValidate>
                    <>
                      <ConfirmDialog
                        isOpen={isAlertOpen}
                        headerText="Are you sure?"
                        bodyText="Switching content type will leads to current data loss"
                        onAgree={() => {
                          this.setState({
                            contents: [],
                          });
                          confirmFormChange({ setFieldValue });
                        }}
                        handleClose={() => {
                          this.setState({
                            isAlertOpen: false,
                          });
                        }}
                        onDisagree={() => {
                          this.setState({
                            isAlertOpen: false,
                          });
                        }}
                      />
                      <FormikField
                        name="title"
                        label="Goal Name"
                        placeholder="Enter a title"
                      ></FormikField>

                      <NEInput
                        name={contentType}
                        label="Content Type"
                        defaultValue={contentType}
                        select
                        fullWidth
                        variant="outlined"
                        onChange={e => {
                          setFieldValue('contentType', e.target.value);
                          this.setState({
                            isAlertOpen: true,
                          });
                        }}
                      >
                        {possibleOptions &&
                          possibleOptions.map(item => {
                            return (
                              <MenuItem value={item && item.label}>
                                {item && item.label}
                              </MenuItem>
                            );
                          })}
                      </NEInput>
                      <FormControlLabel
                        value="top"
                        name="isVisible"
                        control={
                          <GreenCheckbox
                            checked={Boolean(values.isVisible)}
                            onChange={e =>
                              setFieldValue('isVisible', !values.isVisible)
                            }
                          />
                        }
                        label="Visible"
                        labelPlacement="start"
                      />

                      <Divider />
                      <Typography
                        variant="subtitle1"
                        align="center"
                        style={{ color: '#909599', marginTop: '10px' }}
                      >
                        Manage Recommended Program or Challenge
                      </Typography>

                      {renderCarouselSearchComponent(
                        values,
                        setFieldValue,
                        this.state.contents,
                        contentType,
                      )}
                      {isProgramError && (
                        <ErrorText>Enter only one item</ErrorText>
                      )}

                      <Grid>
                        <div
                          style={{
                            display: 'flex',
                            position: 'fixed',
                            bottom: '0',
                            background: '#fff',
                            width: '630px',
                            padding: '16px',
                          }}
                        >
                          <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              this.onFitnessSubmit(values);
                            }}
                          >
                            SAVE & PUBLISH
                          </Button>
                        </div>
                      </Grid>
                    </>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        ) : (
          <Loader />
        )}{' '}
      </>
    );
  }
}
