import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  Typography,
  CardMedia,
  Fab,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import FormikField from '../../../components/Formik/FormikField';
import { ItalicInfoText, ErrorText } from '../../../components/Typography';
import { useDispatch } from 'react-redux';
import FormikGallery from '../../../components/Formik/ManageGallery';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { List, arrayRemove, arrayMove } from 'baseui/dnd-list';
import FormikImageUploader from '../../../components/Formik/FormikImageUploader';

export const ProgramEditor = ({
  componentFormInfo,
  handleEditFormSubmit,
  ComponentSchema,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Formik
      initialValues={componentFormInfo.item}
      enableReinitialize
      validationSchema={ComponentSchema}
      onSubmit={handleEditFormSubmit}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form noValidate>
            <div className={classes.drawerBody}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                  <Box m={2}>
                    <FormikField
                      name="componentTitle"
                      label={'Title*'}
                      placeholder="Enter a title"
                    ></FormikField>
                    <FormikField
                      name="subTitle"
                      label={'Description*'}
                      placeholder="Description"
                      multiline
                    ></FormikField>
                    <FormikField
                      name="buttonTxt"
                      label={'Button Text*'}
                      placeholder="Enter a Button Text"
                    ></FormikField>
                  </Box>
                  <Divider />
                  <Box m={2}>
                    <ItalicInfoText align="center">
                      {'Manage Content'}
                    </ItalicInfoText>
                    <div className={classes.galleryWrap}>
                      <FormikGallery
                        galleryItems={(values && values.gallery) || []}
                        albumName="videos"
                        name={'gallery'}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
            {componentFormInfo.type && (
              <>
                <Divider />
                <div className={classes.drawerFooter}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    onClick={() => setIsLoading(true)}
                  >
                    Save, Publish and Close
                  </Button>
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export const CarouselEditor = ({
  componentFormInfo,
  handleEditFormSubmit,
  ComponentSchema,
  CarouselItemSchema,
}) => {
  const classes = useStyles();
  const [isOpen, openDialog] = React.useState(false);
  const [carousalData, setCarouselData] = React.useState(null);
  const ListContent = React.memo(({ $value, other }) => {
    let { $index } = other;
    return (
      <div className={classes.wrapper}>
        <Typography variant="caption">{$value.title}</Typography>
        <div className={classes.root} id={'ImageUploader' + $index}>
          <Typography variant="body2">{''}</Typography>
          <CardMedia image={$value.imgURL} className={`card${'32x9'}`} />

          <Fab
            variant="extended"
            size="medium"
            aria-label="add"
            className={classes.manageGallery}
            onClick={() => {
              if ($value) {
                setCarouselData({
                  imgURL: $value.imgURL || '',
                  smallImgUrl: $value.smallImgUrl || '',
                  buttonTxt: $value.buttonTxt || '',
                  title: $value.title || '',
                  genresName: $value.genresName || '',
                  videoUrl: $value.videoUrl || '',
                  trailerTxt: $value.trailerTxt || '',
                  thumbNail: $value.thumbNail || '',
                  order: $value.order || $index + 1,
                });
              }
              openDialog(true);
            }}
          >
            Edit Feature
          </Fab>
        </div>
      </div>
    );
  });

  function renderContentDnD(
    values: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
    ListContent: ({
      $value,
      other,
    }: {
      $value: any;
      other: any;
    }) => JSX.Element,
  ) {
    return (
      <List
        removable={true}
        items={values.content.data}
        overrides={{
          Label: {
            component: ({ $value, ...other }) => (
              <ListContent
                $value={$value}
                other={{
                  ...other,
                  values,
                  setFieldValue,
                }}
                key={other.$index}
              />
            ),
          },
          Root: {
            style: {
              maxWidth: '100%',
            },
          },
          Item: {
            style: ({ $theme }) => {
              return {
                boxShadow: '0 2px 2px 0 rgba(0,0,0, 0);',
                borderLeftColor: '#E3E3E4!important',
                borderRightColor: ' #E3E3E4!important',
                borderTopColor: ' #E3E3E4!important',
                borderBottomColor: ' #E3E3E4!important',
                borderLeftWidth: '1px!important',
                borderRightWidth: '1px!important',
                borderTopWidth: '1px!important',
                borderBottomWidth: '1px!important',
                borderRadius: '6px',
                marginBottom: '15px',
                zIndex: 140000,
              };
            },
          },
          DragHandle: {
            style: ({ $theme }) => {
              return {
                marginTop: '-90px !important',
              };
            },
          },
          CloseHandle: {
            component: props => (
              <IconButton {...props}>
                <DeleteOutlinedIcon />
              </IconButton>
            ),
          },
        }}
        onChange={({ oldIndex, newIndex }) => {
          let newList =
            newIndex === -1
              ? arrayRemove(values.content.data, oldIndex)
              : arrayMove(values.content.data, oldIndex, newIndex);
          setFieldValue('content.data', newList);
        }}
      />
    );
  }

  return (
    <Formik
      initialValues={componentFormInfo.item}
      enableReinitialize
      validationSchema={ComponentSchema}
      onSubmit={handleEditFormSubmit}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <>
            {isOpen ? (
              <CarouselItem
                componentFormInfo={carousalData}
                ComponentSchema={CarouselItemSchema}
                handleEditFormSubmit={handleEditFormSubmit}
              />
            ) : (
              <Form noValidate>
                <div className={classes.drawerBody}>
                  <Grid container alignItems="center" justify="center">
                    <Grid item xs={12}>
                      <Box m={2}>
                        {renderContentDnD(values, setFieldValue, ListContent)}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
                {componentFormInfo.type && (
                  <>
                    <Divider />
                    <div className={classes.drawerFooter}>
                      <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          setFieldValue('content.data', values.content.data);
                        }}
                      >
                        Save, Publish and Close
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            )}{' '}
          </>
        );
      }}
    </Formik>
  );
};

export const CarouselItem = ({
  componentFormInfo,
  handleEditFormSubmit,
  ComponentSchema,
  CarouselComponent,
}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={
        componentFormInfo.type === 'ADD'
          ? componentFormInfo.item
          : componentFormInfo
      }
      enableReinitialize
      validationSchema={ComponentSchema}
      onSubmit={handleEditFormSubmit}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form noValidate>
            <div className={classes.drawerBody}>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                  <Box m={2}>
                    {CarouselComponent ? (
                      CarouselComponent
                    ) : (
                      <>
                        <FormikField
                          name="title"
                          label={'Headline*'}
                          placeholder="Enter a headline"
                          multiline
                        ></FormikField>
                        <FormikField
                          name="trailerTxt"
                          label={'Video Headline*'}
                          placeholder="Enter a video headline"
                        ></FormikField>
                        <FormikField
                          name="genresName"
                          label={'Genre*'}
                          placeholder="Enter a genre"
                        ></FormikField>
                        <FormikField
                          name="videoUrl"
                          label={'video URL*'}
                          placeholder="Enter a video url"
                        ></FormikField>
                        <FormikField
                          name="buttonTxt"
                          label={'Button Text*'}
                          placeholder="Enter a Button Text"
                        ></FormikField>
                        <div className={classes.imageWrap}>
                          <div className={classes.imgURL}>
                            <FormikImageUploader
                              id="imgURL"
                              name="imgURL"
                              aspectRatio="16x9"
                              key="16x9"
                              title="Background Image (large)*"
                              subTitle="2394x1358"
                              defaultValue={values.imgURL || ''}
                            />
                          </div>
                          <div className={classes.smallImgUrl}>
                            <FormikImageUploader
                              id="smallImgUrl"
                              name="smallImgUrl"
                              aspectRatio="16x9"
                              key="16x9"
                              title="Background Image (small)*"
                              subTitle="1440x812"
                              defaultValue={values.smallImgUrl || ''}
                            />
                          </div>
                        </div>
                        <div className={classes.thumbNail}>
                          <FormikImageUploader
                            id="thumbNail"
                            name="thumbNail"
                            aspectRatio="16x9"
                            key="16x9"
                            title="Video Thumbnail"
                            defaultValue={values.thumbNail || ''}
                          />
                        </div>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </div>

            <>
              <Divider />
              <div className={classes.drawerFooter}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={() => {}}
                >
                  Save, Publish and Close
                </Button>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbNail: {
      paddingRight: '10px',
      width: '50%',
      paddingBottom: '20px',
    },
    smallImgUrl: { paddingLeft: '10px', width: '50%' },
    imgURL: { paddingRight: '10px', width: '50%' },
    drawerBody: {
      overflow: 'auto',
      height: 'calc(100vh - 144px)',
    },
    imageWrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    galleryWrap: {
      width: '300px',
      '& img': {
        width: '300px',
      },
    },
    drawerFooter: {
      padding: '16px',
      display: 'flex',
      '& .MuiButton-root': {
        marginRight: 8,
      },
    },
    wrapper: {
      flex: 1,
      margin: '0 8px',
    },
    root: {
      position: 'relative',
      marginBottom: '16px',
      width: '100%',

      '& .MuiTypography-body2': {
        marginBottom: '6px',
      },
      '& .MuiCardMedia-root': {
        borderRadius: '6px',
        backgroundColor: 'rgba(212,216,220,0.5)',
      },
      '& .card16x9': {
        paddingTop: '56.25%',
      },
      '& .card9x16': {
        paddingTop: '177%',
        minWidth: '85px',
      },
      '& .card32x9': {
        paddingTop: '28.125%',
      },
      '& .card1x1': {
        paddingTop: '100%',
        minWidth: '150px',
      },
      '& .card3x3': {
        paddingTop: '50%',
        marginRight: '20%',
        marginLeft: '20%',
        marginBottom: '50%',

        minWidth: '50px',

        minHeight: '50px',
      },
    },
    manageGallery: {
      textTransform: 'capitalize',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: '#12161A',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 22px',
      position: 'absolute',
      height: '24px',
      top: '45%',
      left: '40%',
      '&:hover': {
        backgroundColor: '#12161A !important',
      },
    },
  }),
);
