/*
 *
 * Collection reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  collectionList: [],
  collectionPaginationList: [],
  sortBy: 'updatedAt=desc',
  collectionType: '',
  activeTab: '',
  isListFetchingError: false,
  isLoading: false,
  searchKey: '',
  collectionDetailInfo: {
    // isLoading: false,
    flag: [],
    instructors: [],
    genres: [],
    categories: [],
    data: {
      categories: [],
      collectionType: '',
      createdAt: new Date(),
      description: '',
      flag: '',
      gallery: [],
      genres: [],
      graphics: {
        '16x9': '',
        '16x9_trans': '',
        '1x1': '',
        '32x9': '',
        '9x16': '',
      },
      id: '',
      instructors: [],
      isHidden: false,
      isPublished: false,
      kicker: '',
      permaLink: '',
      publishedDate: new Date(),
      seriesID: '',
      title: '',
      updatedAt: new Date(),
      videos: {
        weeks: {},
      },
    },
  },
  total: 0,
  isCollectionLoading: false,
};

function videoReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_COLLECTION_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.COLLECTION_LIST_SUCCESS:
      return {
        ...state,
        collectionList: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.COLLECTION_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        collectionPaginationList: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.PUT_COLLECTION_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        collectionList: [
          ...state.collectionList,
          ...state.collectionPaginationList,
        ],
        isLoading: false,
        isCollectionLoading: false,
      };
    case ActionTypes.COLLECTION_LIST_TOTAL:
      return {
        ...state,
        total: action.payload,
        isLoading: false,
      };
    case ActionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case ActionTypes.SET_SORT_BY_TYPE:
      return { ...state, collectionType: action.payload };
    case ActionTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case ActionTypes.COLLECTION_LIST_FAILED:
      return { ...state, isListFetchingError: true };
    case ActionTypes.GET_SEARCH_RESULT:
      return { ...state, searchKey: action.payload };
    case ActionTypes.RESET_COLLECTION:
      return (state = initialState);

    case ActionTypes.COLLECTION_LIST_FAILED:
      return { ...state, isListFetchingError: true };
    case ActionTypes.STORE_COLLECTION_DETAIL:
      return { ...state, collectionDetailInfo: action.payload };
    case ActionTypes.SET_COLLECTION_LOADER:
      return { ...state, isLoading: action.payload };
    case ActionTypes.SET_COLLECTION_PAGINATION_LOADER:
      return { ...state, isCollectionLoading: action.payload };
    case ActionTypes.ADD_COLLECTION:
      return { ...state, addCollection: action.payload };
    case ActionTypes.ADD_COLLECTION_DETAIL_INFO:
      return { ...state, collectionDetailInfo: action.payload };

    case ActionTypes.EDIT_COLLECTION_DETAIL_INFO:
      return { ...state, collectionDetailInfo: action.payload };

    case ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS:
      return { ...state, videoList: action.payload };

    case ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS:
      return { ...state, videoList: action.payload };

    default:
      return state;
  }
}

export default videoReducer;
