import { createStyles } from '@material-ui/core';

export const dialogStyles = () =>
  createStyles({
    dialogContainer: {
      borderRadius: 0,
      maxWidth: '1080px',
      minWidth: '1080px',
    },
    imageContainer: {
      maxWidth: '252px',
      margin: '0px 16px',
    },
    videoHeader: {},
    classWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    form: {
      // maxWidth: '33.33%',
    },
    dialogContent: {
      padding: '24px 16px 72px',
    },
    mr6: {
      marginRight: '6px',
    },
    ml6: {
      marginLeft: '6px',
    },
    dialogActions: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      background: '#fff',
      border: '1px solid #D9D9D9',
      margin: '0px -16px',
      justifyContent: 'space-between',
      display: 'flex',
      zIndex: 140000,
    },
    videoHeaderContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
    },
  });
