/*
 *
 * Legal
 *
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Helmet } from 'react-helmet';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectLegal from './selectors';
import reducer from './reducer';
import saga from './saga';
import { requestLegal, setTabChange } from './actions';
import LegalListTable from './LegalTable/LegalTable';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';
import { useStyles } from './styles';
import Loader from '../../components/Loader';

const stateSelector = createStructuredSelector({
  legal: makeSelectLegal(),
});

interface Props {}

function Legal(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'legal', reducer: reducer });
  useInjectSaga({ key: 'legal', saga: saga });

  const { legal } = useSelector(stateSelector);
  let { legalList, isListFetchingError, isLoading } = legal;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    if (tabIndex === 0) {
      dispatch(setTabChange('privacypolicy'));
      dispatch(requestLegal());
    } else if (tabIndex === 1) {
      dispatch(setTabChange('termsofservice'));
      dispatch(requestLegal());
    } else if (tabIndex === 2) {
      dispatch(setTabChange('aboutneou'));
      dispatch(requestLegal());
    }
    setActiveTab(tabIndex);
  };

  const tabConfig = [
    {
      label: 'PRIVACY',
      component: <LegalListTable list={legalList} />,
    },
    {
      label: 'TERMS',
      component: <LegalListTable list={legalList} />,
    },
    {
      label: 'ABOUT',
      component: <LegalListTable list={legalList} />,
    },
  ];

  useEffect(() => {
    if (activeTab === 0) {
      dispatch(setTabChange('privacypolicy'));
    }
    dispatch(requestLegal());
  }, []);

  return (
    <>
      <Helmet>
        <title>Configure Legal</title>
        <meta name="description" content="Legal text configuration" />
      </Helmet>
      {isLoading && <Loader />}

      <div className={classes.root}>
        <div>
          <div className={classes.headWrap}>
            <div className={classes.head}>Configure: Legal</div>
          </div>
          <div className={classes.headWrap}></div>
          <StyledTabs
            value={activeTab}
            onChange={onTabChange}
            aria-label="Legal tabs"
            centered
            className={classes.styledTab}
          >
            {tabConfig.map((t, index) => (
              <StyledTab key={index} label={t.label} />
            ))}
          </StyledTabs>
          {isListFetchingError && (legalList && !legalList.htmlContent) ? (
            <div className={classes.noData}>No Data Found</div>
          ) : (
            <LegalListTable list={legalList} />
          )}
        </div>
      </div>
    </>
  );
}

export default Legal;
