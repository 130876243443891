import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the curateGenre state domain
 */

const selectCurateGenreDomain = (state: ApplicationRootState) => {
  return state.curateGenre || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by CurateGenre
 */

const makeSelectCurateGenre = () =>
  createSelector(
    selectCurateGenreDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectCurateGenre;
export { selectCurateGenreDomain };
