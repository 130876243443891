import React from 'react';
import {
  CardMedia,
  IconButton,
  createStyles,
  withStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { SvgIconAdd, SvgIconEdit, SvgLoaderGif } from './NeIcons';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginBottom: '16px',
      width: '100%',

      '& .MuiTypography-body2': {
        marginBottom: '6px',
      },
      '& .MuiCardMedia-root': {
        borderRadius: '6px',
        backgroundColor: 'rgba(212,216,220,0.5)',
      },
      '& .card16x9': {
        paddingTop: '56.25%',
      },
      '& .cardpromoSpotImg': {
        paddingTop: '56.25%',
      },
      '& .card9x16': {
        paddingTop: '177%',
        minWidth: '85px',
      },
      '& .card32x9': {
        paddingTop: '28.125%',
      },
      '& .card1x1': {
        paddingTop: '100%',
        minWidth: '150px',
      },
      '& .card3x3': {
        paddingTop: '50%',
        marginRight: '20%',
        marginLeft: '20%',
        marginBottom: '50%',

        minWidth: '50px',

        minHeight: '50px',
      },
    },
    addIcon: {
      position: 'absolute',
      top: 'calc(50% - 12px)',
      left: 'calc(50% - 24px)',
      '&  .MuiIconButton-label': {
        backgroundColor: '#3C4042',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        padding: '6px',
      },
      '& svg': {
        color: '#fff',
      },
    },
    input: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      opacity: 0,
      width: '100%',
    },
  });

const ImageUploader = ({
  title,
  subTitle,
  imgSrc,
  classes,
  aspectRatio,
  value,
  handleChange,
  id,
  videotray,
  isLoading,
}) => {
  return (
    <div className={classes.root} id={id}>
      <Typography variant="body2">
        {title} <br /> {subTitle}
      </Typography>
      <CardMedia image={value} className={`card${aspectRatio}`} />

      <label htmlFor="icon-button-file">
        <IconButton
          className={classes.addIcon}
          aria-label="upload picture"
          component="span"
        >
          {isLoading ? (
            <SvgLoaderGif />
          ) : value ? (
            <SvgIconEdit />
          ) : (
            <SvgIconAdd></SvgIconAdd>
          )}
          <input
            accept="image/*"
            className={classes.input}
            id={`file_${id}`}
            type="file"
            onChange={e => handleChange(e, videotray ? videotray : aspectRatio)}
          />
        </IconButton>
      </label>
    </div>
  );
};

export default withStyles(styles)(ImageUploader);
