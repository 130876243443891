import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SvgIconEdit, SvgIconAlert } from '../../../components/NeIcons';
import moment from 'moment';
import { useStyles } from './styles';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AchievementFormDialog, {
  FormType,
} from '../components/AchievementFormDialog';
import AchievementFallbackIcon from '../../../images/achievement_placeholder.png';

export default function AchievementListTable(props) {
  const classes = useStyles();
  let { list, programChallenge, genres } = props;

  const [achievementDialogData, setAchievementDialogData] = React.useState({
    isOpen: false,
    badgeItem: {},
    badgeType: '',
    genres: [],
    programChallenge: [],
    type: '',
  });

  const getDate = list => {
    if (list.isPublished) {
      return (
        <div>
          {`Updated ${moment(list.updatedAt).format(`L`)}`} <br /> Published{' '}
          {`${moment(list.publishedDate).format(`L`)}`}
        </div>
      );
    } else {
      return `Saved ${moment(list.updatedAt).format(`L`)}`;
    }
  };

  const renderCTA = ({
    isPublished = false,
    isHidden = false,
    isError = false,
  }) => {
    if (isError) {
      return <div></div>;
    }
    if (isHidden) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'HIDDEN'}
        </div>
      );
    } else if (isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'PUBLISHED'}
        </div>
      );
    } else if (!isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.draft}`}>
          {'DRAFT'}
        </div>
      );
    }
  };

  const onAchievementEdit = (
    badgeItem,
    badgeType,
    genres,
    programChallenge,
  ) => {
    setAchievementDialogData({
      isOpen: true,
      badgeItem: badgeItem,
      badgeType: badgeType,
      genres: genres,
      programChallenge: programChallenge,
      type: FormType.EDIT,
    });
  };

  const getCategories = list => {
    if (list && list.categories && list.categories.length > 0) {
      let cat = list.categories.slice(0, 2);
      let result = list.categories.map(item => {
        return item.title;
      });
      return result.join(', ');
    }
    return [];
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead></TableHead>
          <TableBody>
            {list &&
              list.length > 0 &&
              list.map(item => {
                return (
                  <TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell align="left">
                      <div className={classes.firstColumn}>
                        <div className={classes.box}>
                          <img src={item.imageUrl || AchievementFallbackIcon} />
                        </div>
                        <div className={classes.firstCoulmContent}>
                          <div className={classes.achievementTitle}>
                            {item.title}
                          </div>
                          <div className={classes.text}>
                            {item.categories && item.categories.length === 0 ? (
                              <div>{'Categories undefined'}</div>
                            ) : (
                              <span>{getCategories(item)}</span>
                            )}
                          </div>
                          <div className={classes.text}>
                            {item.classCount + ' videos'}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={classes.text}>{getDate(item)}</div>
                    </TableCell>
                    <TableCell align="center">{renderCTA(item)}</TableCell>
                    <TableCell align="right">
                      {item.isError ? (
                        <div>
                          <SvgIconAlert />
                        </div>
                      ) : (
                        <IconButton
                          onClick={() =>
                            onAchievementEdit(
                              item,
                              item.badgeType,
                              genres,
                              programChallenge,
                            )
                          }
                          classes={{ root: classes.editIcon }}
                        >
                          <SvgIconEdit />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {achievementDialogData && achievementDialogData.isOpen && (
        <AchievementFormDialog
          type={FormType.EDIT}
          isOpen={achievementDialogData.isOpen}
          id={achievementDialogData.id}
          badgeItem={achievementDialogData.badgeItem}
          badgeType={achievementDialogData.badgeType}
          genres={achievementDialogData.genres}
          programChallenge={achievementDialogData.programChallenge}
          title={
            FormType.EDIT
              ? `EDIT ${achievementDialogData.badgeType} ACHIEVEMENT`
              : `ADD ${achievementDialogData.badgeType} ACHIEVEMENT`
          }
          onClose={() =>
            setAchievementDialogData({
              isOpen: false,
              id: '',
              item: {},
              genres: [],
              programChallenge: [],
            })
          }
        />
      )}
    </Paper>
  );
}
