import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export interface ITagProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      width: 'auto',
      padding: '0px 10px',
      position: 'absolute',
      top: '8px',
      right: '8px',
      borderRadius: '8.5px',
      display: 'flex',
      justifyContent: 'center',
      background: 'white',
    },
    content: {
      textTransform: 'capitalize',
      color: '#1D2429',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '11px',
      lineHeight: '17px',
      [theme.breakpoints.up('xl')]: {},
    },
    noDisplay: {
      display: 'none',
    },
  }),
);

export default function Tag({ content, isContent }) {
  const classes = useStyles();
  return isContent ? (
    <div className={classes.root}>
      <div className={classes.content}>{content}</div>
    </div>
  ) : null;
}
