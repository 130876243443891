import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CloseIcon from '@material-ui/icons/Close';
interface ChipData {
  mainTitle: string;
  id: string;
  title: string;
  selected: boolean;
  index: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: 'Proxima-Nova-Regular',
      [theme.breakpoints.only('sm')]: {
        padding: '0px 16px',
      },
    },
    chip: {
      margin: theme.spacing(0.5),
      color: '#FFFFFF',
      backgroundColor: '#3C4042',
      height: '17px',
      fontSize: '13px',
    },
  }),
);

export default function FilterChipsBoxes(props) {
  const classes = useStyles();
  let { value, durationLength = 0 } = props;
  let chipDataKeys = Object.keys(value);
  let chipAppend = [];
  let durationTitle = '';

  if (value && chipDataKeys) {
    chipDataKeys.forEach(itm => {
      value &&
        value[itm] &&
        value[itm].length > 0 &&
        value[itm].map((val, ind) => {
          if (itm == 'duration' && value[itm].length !== durationLength) {
            if (ind == 0) {
              durationTitle = durationTitle + val + '-';
            } else if (ind == value[itm].length - 1) {
              durationTitle = durationTitle + val + ' Mins';
              chipAppend.push({
                id: 1,
                title: durationTitle,
                mainTitle: 'duration',
              });
            }
          } else if (itm == 'difficulty' && value[itm].length !== 4) {
            chipAppend.push(val);
          } else if (itm !== 'duration' && itm !== 'difficulty') {
            chipAppend.push(val);
          }
        });
    });
  }
  if (chipAppend && chipAppend.length) {
    return (
      <div className={classes.root}>
        {chipAppend &&
          chipAppend.map((data, index) => {
            return (
              <Chip
                key={index}
                deleteIcon={
                  <CloseIcon
                    style={{ color: '#FFFFFF', height: '13px', width: '13px' }}
                  />
                }
                label={data.title}
                onDelete={() => props.onSelect(data)}
                className={classes.chip}
              />
            );
          })}
      </div>
    );
  } else {
    return null;
  }
}
