import React, { useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { List, arrayMove, arrayRemove } from 'baseui/dnd-list';

// import other assets
import { Grid } from '@material-ui/core';
import NEInput from '../NEInput';
import { SvgDelete, SvgIconAdd, SvgCross } from '../../components/NeIcons';
import clsx from 'clsx';
import Drag from './Drag';
import {
  getProgress,
  multipart,
  addPhoto,
  deletePhoto,
  addVideo,
} from '../../services/s3';
import { StyledSpinnerNext } from 'baseui/spinner';
import ThumbDialog from './Thumbnail';
import { cloudfrontUrl, BUCKET_NAME, BUCKET_REGION } from '../../utils/config';
import { openSnackBar } from '../../containers/Root/actions';
import { useDispatch } from 'react-redux';
import { GetProgress } from '../../components/ProgressBar/ProgressBar';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#000',
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dividers: {
    borderTop: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const dialogStyles = (theme: Theme) =>
  createStyles({
    dialogContainer: {
      borderRadius: 0,
      minWidth: '464px',
    },
    headWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 0px',
    },
    addbtnWrap: {
      display: 'flex',
      width: '340px',
      justifyContent: 'space-between',
    },
    newPeople: {
      paddingLeft: '8px',
    },
    dividers: {
      borderTop: '1px solid #D9D9D9',
      paddingLeft: '16px',
    },
    btnWidth: {
      width: '100%',
    },
    gridBox: {
      border: '1px solid #E3E3E4',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
      padding: '11px',
      boxSizing: 'border-box',
    },
    inputHidden: {
      display: 'none',
    },
    imagePath: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    imgBoxGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imgUrl: {
      borderRadius: '6px',
      width: '114px',
    },
  });

function GalleryDialog({
  isOpen,
  handleClose,
  title,
  classes,
  galleryDetails: defaultGalleryInfo,
  galleryData: onGalleryInfoChange,
  handleSaveBtn,
  isDelete = () => {},
  type,
  onChange = () => {},
  albumName = '',
}) {
  const [images, setImages] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [isThumbDialogOpen, openThumbDialog] = React.useState(false);
  const dispatch = useDispatch();

  const dialogFunc = () => {
    // openThumbDialog(true);
  };

  const videoThumb = e => {};

  //for video thumbnail url state
  const [vThumb, setVThumb] = React.useState('');

  useEffect(() => {}, [vThumb]);

  React.useEffect(() => {
    if (defaultGalleryInfo && images.length === 0)
      setImages(defaultGalleryInfo);
  }, [defaultGalleryInfo]);

  //handling img - s3
  const handleImage = async e => {
    try {
      let files = e.target.files;
      if (files && files.length > 0) {
        setLoader(true);
        let result = await addPhoto('people', files);

        let image = {
          type: 'image',
          url: result.Location.replace(
            'https://' +
              BUCKET_NAME +
              '.s3.' +
              BUCKET_REGION +
              '.amazonaws.com',
            cloudfrontUrl,
          ),
          // name: result.Key,
        };
        setImages([image, ...images]);
        setLoader(false);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    onGalleryInfoChange(images);
  }, [images]);

  let [process, setProcess] = React.useState(0);

  const handleVideo = async e => {
    try {
      const files = e.target.files;
      let name = files.item(0).name;
      name = name.replace(/\s/g, '_');
      // files.item(0).name = files.item(0).name.replace(/\s/g, '_');

      // let updatedFiles = [...files.item(0), name];
      console.log(files);
      if (files && files.length > 0) {
        const fsize = files.item(0).size;
        const ftype = files.item(0).type;
        const file = Math.round(fsize / 1024);

        //Upload only Mp4 format
        if (ftype !== 'video/mp4') {
          dispatch(openSnackBar('File Should be MP4 Format'));
        }
        //upload file size less than 10mb
        //else if (file <= 10240) {
        else if (ftype === 'video/mp4') {
          let thumbnailImg = '';
          setLoader(true);
          let result = await multipart(type, files, thumbnailImg);

          let image = {
            type: 'video',
            url: result.Location.replace(
              'https://' +
                BUCKET_NAME +
                '.s3.' +
                BUCKET_REGION +
                '.amazonaws.com',
              cloudfrontUrl,
            ),
            thumbnail: '',
            // name: result.Key,
          };
          setImages([image, ...images]);
          setLoader(false);
        }
        // else if (file >= 10240) {
        //   dispatch(openSnackBar('Video size should be less than 10mb'));
        // }
        else {
          let thumbnailImg = '';
          setLoader(true);
          let result = await addVideo(type, files, thumbnailImg);

          let image = {
            type: 'video',
            url: result.Location.replace(
              'https://' +
                BUCKET_NAME +
                '.s3.' +
                BUCKET_REGION +
                '.amazonaws.com',
              cloudfrontUrl,
            ),
            thumbnail: '',
            // name: result.Key,
          };
          setImages([image, ...images]);
          setLoader(false);
        }
      }
    } catch (error) {}
  };

  const handleImageVideo = async e => {
    let itemIndex = parseInt(e.target.id);
    setLoader(true);
    let result = await addPhoto('instructor', e.target.files);
    let finalUrl = result.Location.replace(
      'https://' + BUCKET_NAME + '.s3.' + BUCKET_REGION + '.amazonaws.com',
      cloudfrontUrl,
    );

    setVThumb(finalUrl);
    setLoader(false);
    if (images && images.length > 0) {
      images.map((itm, index) => {
        if (itm.type === 'video' && itemIndex === index) {
          itm.thumbnail = finalUrl;
        }
      });
    }
    setImages(images);
  };

  //deleting img from s3
  const deleteImg = (imgList, oldIndex, newIndex) => {
    const delImageKey = imgList[oldIndex].name;
    const fromDelete = true;
    isDelete(delImageKey, fromDelete);
  };

  //setting up the updated imgList state from Drag
  const latestImgList = images => {
    setImages(images);
  };

  //<StyledSpinnerNext style={{ borderTopColor: 'black' }} />
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      disableBackdropClick={true}
      classes={{
        paperScrollPaper: classes.dialogContainer,
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
        <div></div>
      </DialogTitle>
      <div className={classes.dividers}>
        <div className={clsx(classes.headWrap)}>
          <div className={classes.addbtnWrap}>
            {albumName !== 'videos' && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.addPeopleBtn}
                disableRipple={true}
                onClick={() => {
                  document.getElementById('image_uploads').click();
                }}
              >
                <SvgIconAdd />{' '}
                <div className={classes.newPeople}>
                  <input
                    type="file"
                    id="image_uploads"
                    name="image_uploads"
                    accept=".jpg, .jpeg, .png"
                    className={classes.inputHidden}
                    onChange={handleImage}
                  />
                  Add Image
                </div>
              </Button>
            )}
            <Button
              onClick={() => {
                document.getElementById('video_uploads').click();
              }}
              variant="contained"
              color="secondary"
              className={classes.addPeopleBtn}
              disableRipple={true}
            >
              <SvgIconAdd />{' '}
              <div className={classes.newPeople}>
                <input
                  type="file"
                  id="video_uploads"
                  name="video_uploads"
                  accept=".mp4"
                  className={classes.inputHidden}
                  onChange={handleVideo}
                />
                Add Video
              </div>
            </Button>
          </div>
        </div>
      </div>
      <DialogContent dividers>
        {loader ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h4 id="process" style={{ color: 'black', maxWidth: '195px' }}>
              {' '}
              Uploading... Please Wait! <GetProgress />
            </h4>
          </div>
        ) : (
          <Drag
            data={images}
            deleteImg={deleteImg}
            dialogFunc={dialogFunc}
            latestImgList={latestImgList}
            videoThumb={videoThumb}
            handleImageVideo={handleImageVideo}
            videoThumbImg={vThumb}
          />
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block' }}>
        <div>
          <Button
            variant="contained"
            onClick={e => handleSaveBtn(images)}
            color="secondary"
            className={classes.btnWidth}
          >
            Save & Close
          </Button>
        </div>
      </DialogActions>

      <ThumbDialog
        isOpen={isThumbDialogOpen}
        // id={'1'}
        title="SELECT A VIDEO THUMBNAIL"
        handleClose={() => {
          openThumbDialog(false);
        }}
      ></ThumbDialog>
    </Dialog>
  );
}

export default withStyles(dialogStyles)(GalleryDialog);
