import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import request from '../../shared/lib/request';
import ActionTypes from './constants';
import { AnyAction } from 'redux';
import { storeBrowsePage, setLoader, requestBrowsePageInfo } from './actions';
import { LowerSection } from './types';
import { openSnackBar } from '../Root/actions';

// Individual exports for testing
export default function* browseCurateSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_BROWSE_PAGE_INFO, fetchBrowsePage);
  yield takeLatest(ActionTypes.UPDATE_BROWSE_COMPONENT, updateBrowseComponent);
  yield takeLatest(
    ActionTypes.REORDER_LOWER_SECTION_TRAYS,
    reorderLowerSectionTrays,
  );
}

function* fetchBrowsePage({ payload }: AnyAction) {
  try {
    yield put(setLoader(true));
    let url = '/neouadmin/v2/pages/browse';

    let { result } = yield call(request, {
      url: url,
      method: 'GET',
    });
    yield put(setLoader(false));
    let { topSection = [], lowerSection = [], segments = [] } = result;
    let data = {
      concepts: {
        topSection: topSection.filter(i => i.contentType === 'concept'),
        lowerSection: lowerSection.filter(i => i.contentType === 'concepts'),
      },
      collections: {
        topSection: topSection.filter(i => i.contentType === 'collections'),
        lowerSection: lowerSection
          .filter(i => i.contentType === 'collections')
          .sort((a, b) => {
            if (!a.componentOrder || !b.componentOrder) {
              return -1;
            }
            a.componentOrder['No Fitness Profile'] <
            a.componentOrder['No Fitness Profile']
              ? 1
              : -1;
          }),
      },
      collectionsV2: {
        topSection: topSection.filter(i => i.componentSection === 'interest'),
        lowerSection: lowerSection.filter(
          i => i.componentSection === 'interest',
        ),
      },
      program: {
        topSection: topSection.filter(i => i.componentSection === 'program'),
        lowerSection: lowerSection.filter(
          i => i.componentSection === 'program',
        ),
      },
      challenge: {
        topSection: topSection.filter(i => i.componentSection === 'challenge'),
        lowerSection: lowerSection.filter(
          i => i.componentSection === 'challenge',
        ),
      },
      segments,
    };
    yield put(storeBrowsePage(data));
  } catch (error) {
    yield put(setLoader(false));
    if (error && error.data && error.data.responseCode === 404) {
    }
  }
}

export function* updateBrowseComponent({ payload }: AnyAction) {
  let { component, type } = payload;
  component.updatedAt = new Date().getTime();
  let url = '/neouadmin/v2/pages/';

  try {
    yield put(setLoader(true));

    let { result } = yield call(request, {
      url: url,
      method: type === 'ADD' ? 'POST' : 'PUT',
      data: component,
    });
    yield put(setLoader(false));
    yield put(openSnackBar('Component has been updated successfully!'));
    yield put(requestBrowsePageInfo());
  } catch ({ data: error }) {
    yield put(setLoader(false));
    let errorMsg = "Couldn't save the information. Try again later!";
    if (error.error) {
      errorMsg = error.error;
    }
    yield put(openSnackBar(errorMsg));
  }
}

function* reorderLowerSectionTrays({ payload }: AnyAction) {
  let list: LowerSection[] = payload;
  let requestBody: any = {};
  let componentData: any = [];

  try {
    yield put(setLoader(true));
    list.map((item, index) => {
      let componentOrder = {};
      Object.keys(item.componentOrder).forEach(key => {
        if (item.userSegments.includes(key)) {
          componentOrder[key] = index + 4;
        } else {
          item.userSegments.push(key);
          componentOrder[key] = index + 4;
        }
      });

      requestBody = {
        components: {
          componentTitle: item.componentTitle,
          componentOrder: componentOrder,
          componentId: item.componentId,
        },
      };

      componentData[index] = requestBody.components;
    });
    console.log(componentData);
    yield call(request, {
      url: `neouadmin/v1/reorder-components/`,
      method: 'POST',
      data: { components: componentData, pageName: 'browse' },
    });
    yield put(setLoader(false));
    yield put(requestBrowsePageInfo());
  } catch (error) {
    yield put(setLoader(false));
    yield put(openSnackBar('Failed to reorder components'));
  }
}
