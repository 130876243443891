import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import ActionTypes from './constants';
import { requestFitnessGoals, getFitnessDetail } from './actions';
import { openSnackBar } from '../Root/actions';
import request from '../../shared/lib/request';
import { AnyAction } from 'redux';

// Individual exports for testing
export default function* fitnessGoalsSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.FETCH_FITNESS_BY_ID, fetchFitnessById);
  yield takeLatest(ActionTypes.REQUEST_FITNESS_LIST, getFitnessList);
}

export function* getFitnessList() {
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/get-recommend?`,
        method: 'GET',
      }),
    );
    yield put(requestFitnessGoals(result));
  } catch (error) {
    yield put(requestFitnessGoals([]));
    yield put(openSnackBar('Error in fetching data'));
  }
}

export function* fetchFitnessById(action: AnyAction) {
  let id = action.payload;

  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/get-recommend`,
        method: 'GET',
        params: {
          id,
        },
      }),
    );

    yield put(getFitnessDetail(result));
  } catch (error) {
    yield put(openSnackBar('Error in fetching data'));
  }
}
