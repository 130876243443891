import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export const SocialButton = withStyles({
  root: {
    width: '42px',
    height: '42px',
    padding: '0 16px',
    minWidth: 'auto',
    marginRight: '16px',
  },
})(Button);
