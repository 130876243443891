/*
 *
 * People reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  peopleList: [],
  peoplePaginationList: [],
  sortBy: 'updatedAt=desc',
  activeTab: 'isHidden=false',
  isInteractiveFilterOn: false,
  isListFetchingError: false,
  isLoading: false,
  specialtyValues: null,
  flagValues: null,
  instructorDetail: null,
  relatedClasses: null,
  isInstructorLoading: false,
  instructorPackage: null,
  //add instructor
  aFlag: null,
  aSpecialty: null,
  isPersonLoading: false,
  total: 0,
  searchKey: '',
};

function peopleReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_PEOPLE_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.PEOPLE_LIST_SUCCESS:
      return {
        ...state,
        peopleList: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.RESET_PEOPLE:
      return (state = initialState);
    case ActionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case ActionTypes.GET_SEARCH_RESULT:
      return { ...state, searchKey: action.payload };
    case ActionTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case ActionTypes.PEOPLE_LIST_FAILED:
      return { ...state, isListFetchingError: true };
    case ActionTypes.PEOPLE_DETAIL_SUCCESS:
      return {
        ...state,
        instructorDetail: action.payload,
        flagValues: action.payload && action.payload.flagValues,
        specialtyValues: action.payload && action.payload.specialtyValues,
      };
    case ActionTypes.PEOPLE_PACKAGE_SUCCESS:
      return {
        ...state,
        instructorPackage: action.payload,
      };
    case ActionTypes.PEOPLE_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        peoplePaginationList: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.PUT_PEOPLE_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        peopleList: [...state.peopleList, ...state.peoplePaginationList],
        isLoading: false,
        isInstructorLoading: false,
      };
    case ActionTypes.PEOPLE_LIST_TOTAL:
      return {
        ...state,
        total: action.payload,
        isLoading: false,
      };
    case ActionTypes.INSTRUCTOR_DETAILS_LOADING:
      return {
        ...state,
        isInstructorLoading: action.payload,
        // isLoading: action.payload,
      };
    case ActionTypes.PEOPLE_UPDATED_INFO:
      return {
        ...state,
        instructorDetail: {
          ...initialState.instructorDetail,
          ...action.payload,
        },
      };
    case ActionTypes.PEOPLE_CLASSES_SUCCESS:
      return { ...state, relatedClasses: action.payload };
    //add instructor
    case ActionTypes.OTHER_DETAIL_SUCCESS:
      return {
        ...state,
        aFlag: action.payload && action.payload.flagValues,
        aSpecialty: action.payload && action.payload.specialtyValues,
      };
    case ActionTypes.PERSON_LOADING:
      return { ...state, isPersonLoading: action.payload };
    case ActionTypes.SET_INTERACTIVE_PEOPLE_ONLY:
      return { ...state, isInteractiveFilterOn: action.payload };

    default:
      return state;
  }
}

export default peopleReducer;
