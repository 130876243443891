import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    display: 'inline',
    fontFamily: 'Proxima-Nova-Regular',
    '& .MuiSelect-root': {
      boxSizing: 'border-box',
      fontFamily: 'Proxima-Nova-Regular',
      width: '241px',
      border: '1px solid #E3E3E4',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
      padding: '0',
      paddingLeft: '8px',
      height: '38px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      borderLeft: '1px solid #E3E3E4',
    },
    '& .MuiFilledInput-root': {
      marginLeft: '25px',
    },
    '& .MuiFilledInput-underline:before': {
      content: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
  },
  menuItem: {
    fontFamily: 'Proxima-Nova-Regular',
  },
}));

export default function FilterSelect(props) {
  const classes = useStyles();
  let { items = [], value, onChange, label = '' } = props;

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        {label}
        <Select value={value ? value : ''} onChange={onChange}>
          {items &&
            items.length > 0 &&
            items.map((item, index) => (
              <MenuItem
                key={`filter_menu_${index}`}
                value={item.value}
                classes={{ root: classes.menuItem }}
              >
                {item.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
