import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { NEInputLight as NEInput } from '../NEInput';
import reducer from '../../containers/BrandVoice/reducer';
import saga from '../../containers/BrandVoice/saga';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import { useDispatch } from 'react-redux';
import { updateBrandVoice } from '../../containers/BrandVoice/actions';
import CloseIcon from '@material-ui/icons/Close';
import {
  Divider,
  List,
  Button,
  Drawer,
  Typography,
  Fab,
} from '@material-ui/core';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      background: '#12161A',
      width: '70px',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      padding: '0 !important',
      lineHeight: '20.4px',
      textAlign: 'center',
      minWidth: '85px !important',
      height: '24px !important',
      textTransform: 'none !important',
      letterSpacing: '1px !important',
      '&:hover': {
        background: '#12161A',
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    list: {
      width: 650,
    },
    fullList: {
      width: 650,
    },
    btnText: {
      color: 'white',
      textTransform: 'capitalize',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      letterSpacing: '0',
      lineHeight: '20.4px',
      textAlign: 'center',
    },
    title: {
      padding: '16px 24px',
      color: '#12161A',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '16px',
      lineHeight: '20px',
      float: 'left',
    },
    title1: {
      padding: '16px 24px',
      color: '#12161A',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '16px',
      lineHeight: '20px',
    },
    activityForm: {
      padding: '0 20px',
    },
    brandVoiceBtn: {
      position: 'absolute',
      bottom: '50px',
      left: '20px',
    },
    brandVoiceSubtitle: {
      fontFamily: 'ProximaNova-Bold !important',
      paddingBottom: '10px',
    },
    brandInput: {
      marginBottom: '10px',
    },
    closeBtn: {
      float: 'right',
    },
    titleDiv: {
      width: '100%',
      float: 'left',
    },
    divider: {
      width: '100%',
      overflow: 'hidden',
    },
  }),
);

export default function EditComponentSection({
  onClick,
  buttonText = 'Manage',
  title,
  activityTrackerGoalMet,
  activityTrackerGoalExceeded,
}) {
  const classes = useStyles();
  useInjectReducer({ key: 'brandVoice', reducer: reducer });
  useInjectSaga({ key: 'brandVoice', saga: saga });
  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
    // left: false,
    goalMetText: activityTrackerGoalMet,
    goalExceededText: activityTrackerGoalExceeded,
    isError: false,
    isError1: false,
    helperText: '',
    helperText1: '',
  });
  const toggleDrawer = (anchor, open) => event => {
    setState({ ...state, [anchor]: open });
  };

  const handleInput = event => {
    if (event.target.value.length < 1) {
      setState({
        ...state,
        isError: true,
        helperText: 'Message cannot be empty',
      });
    } else if (event.target.value.length > 54) {
      setState({
        ...state,
        isError: true,
        helperText: 'Please enter text within 54 characters',
      });
    } else {
      setState({
        ...state,
        isError: false,
        goalMetText: event.target.value,
        goalExceededText: document.getElementById('activityGoalExceeded').value,
        helperText: '',
      });
    }
  };
  const handleInput1 = event => {
    if (event.target.value.length < 1) {
      setState({
        ...state,
        isError1: true,
        helperText1: 'Message cannot be empty',
      });
    } else if (event.target.value.length > 54) {
      setState({
        ...state,
        isError1: true,
        helperText1: 'Please enter text within 54 characters',
      });
    } else {
      setState({
        ...state,
        isError1: false,
        goalMetText: document.getElementById('activityGoalMet').value,
        goalExceededText: event.target.value,
        helperText1: '',
      });
    }
  };
  const updateData = () => {
    if (!state.isError && !state.isError1) {
      dispatch(
        updateBrandVoice({
          activityTracker: {
            goalMetText: state.goalMetText,
            goalExceededText: state.goalExceededText,
          },
        }),
      );
      toggleDrawer('right', false);
    }
  };
  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'right' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List>
        <div className={classes.titleDiv}>
          <div className={classes.title}>EDIT MESSAGING</div>
          <Button
            // onClick={toggleDrawer(anchor, false)}
            onClick={() => showAlertToClose(true)}
            className={classes.closeBtn}
          >
            <CloseIcon />
          </Button>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.title1}>{title}</div>
      </List>
      <form className={classes.activityForm}>
        <Typography className={classes.brandVoiceSubtitle} variant="subtitle1">
          When goal has been met, show:
        </Typography>
        <NEInput
          id="activityGoalMet"
          name="title"
          label="Message (upto 54 characters)"
          error={state.isError}
          helperText={state.helperText}
          className={classes.brandInput}
          defaultValue={activityTrackerGoalMet}
          onChange={e => handleInput(e)}
          fullWidth
          variant="outlined"
        />
        <Typography variant="subtitle1" className={classes.brandVoiceSubtitle}>
          When goal has been exceeded, show:
        </Typography>
        <NEInput
          id="activityGoalExceeded"
          name="title"
          label="Message (upto 54 characters)"
          defaultValue={activityTrackerGoalExceeded}
          error={state.isError1}
          helperText={state.helperText1}
          onChange={e => handleInput1(e)}
          fullWidth
          variant="outlined"
        />
        <Button
          className={classes.brandVoiceBtn}
          variant="contained"
          onClick={updateData}
          color="secondary"
        >
          Save & Publish changes
        </Button>
      </form>
    </div>
  );

  return (
    <div>
      <div>
        <Fab
          variant="extended"
          size="medium"
          aria-label="add"
          className={classes.margin}
          //onClick={onClick}
        >
          {/* {['right'].map(anchor => ( */}
          <React.Fragment>
            <Button
              onClick={toggleDrawer('right', true)}
              className={classes.btnText}
            >
              {buttonText}
            </Button>
            <Drawer
              anchor={'right'}
              disableBackdropClick={true}
              open={state['right']}
              // onClose={toggleDrawer('right', false)}
              onClose={() => showAlertToClose(true)}
            >
              <ConfirmDialog
                isOpen={isCloseAlertOpen}
                headerText="Are you sure?"
                bodyText="You are about to close the home page message."
                onAgree={toggleDrawer('right', false)}
                handleClose={() => showAlertToClose(false)}
                onDisagree={() => showAlertToClose(false)}
              ></ConfirmDialog>
              {list('right')}
            </Drawer>
          </React.Fragment>
          {/* ))} */}
        </Fab>
      </div>
    </div>
  );
}
