import React from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from 'react-redux';
import { Grid, MenuItem, Typography, Box } from '@material-ui/core';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useInjectReducer } from '../../../utils/injectReducer';
import saga from '../saga';
import { addPhoto } from '../../../services/s3';
import reducer from '../reducer';
import {
  requestAttributes,
  removeAttribute,
  setAttributeLoader,
} from '../actions';
import ImageUploader from '../../../components/ImageUploader';
import {
  cloudfrontUrl,
  BUCKET_NAME,
  BUCKET_REGION,
} from '../../../utils/config';
import { StatusLabel, StatusText } from '../../../components/Typography';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { openSnackBar } from '../../Root/actions';
import request from '../../../shared/lib/request';
import { GreenCheckbox } from '../../../components/GreenCheckbox';

// form component

export default function EditAttributeForm({ formDetail, classes, toggleFn }) {
  const dispatch = useDispatch();

  useInjectReducer({ key: 'attribute', reducer });
  useInjectSaga({ key: 'attribute', saga });

  const [state, setState] = React.useState({
    id: formDetail.id,
    title: formDetail.title,
    type: formDetail.type,
    isVisible: formDetail.isVisible,
    imageUrl: formDetail.imageUrl || '',
    titleError: '',
    isTitleError: false,
    isProgramError: false,
    isPublished: formDetail.isPublished,
    isConfirmDialogOpen: false,
    isConfirmPublishOpen: false,
  });

  React.useEffect(() => {
    setState({
      id: formDetail.id,
      title: formDetail.title,
      type: formDetail.type,
      isVisible: formDetail.isVisible,
      imageUrl: formDetail.imageUrl || '',
      isPublished: formDetail.isPublished,
    });
  }, [formDetail]);

  const handleChange = event => {
    if (!event.target.value) {
      setState({
        ...state,
        title: event.target.value,
        isTitleError: true,
        titleError: 'Title is required',
      });
    } else {
      setState({
        ...state,
        title: event.target.value,
        isTitleError: false,
        titleError: '',
      });
    }
  };
  const handleChange1 = event => {
    setState({ ...state, type: event.target.value });
  };
  const handleChange2 = event => {
    setState({ ...state, isVisible: !state.isVisible });
  };

  const onHandleSubmit = () => {
    const updateData = {
      id: state.id,
      title: state.title,
      isVisible: state.isVisible,
      isPublished: Boolean(state.isPublished),
      type: state.type === 'bodyfocus' ? 'bodyFocus' : state.type,
    };
    if (state.type === 'equipment') {
      // if (state.imageUrl.png) updateData['imageUrl'] = state.imageUrl.png || '';
      // else updateData['imageUrl'] = { png: state.imageUrl.png } || '';
      if (state.imageUrl.png) {
        updateData.imageUrl = state.imageUrl.png || '';
      } else {
        updateData.imageUrl = state.imageUrl.png || '';
      }
    }
    if (!state.isTitleError) {
      // dispatch(saveAttribute(updateData));
      saveAttribute(updateData);
    }
  };
  const onPublishSubmit = () => {
    const updateData = {
      id: state.id,
      title: state.title,
      isVisible: state.isVisible,
      isPublished: true,
      type: state.type === 'bodyfocus' ? 'bodyFocus' : state.type,
    };
    if (state.type == 'equipment') {
      updateData['imageUrl.png'] = state.imageUrl.png;
    }
    if (!state.isTitleError) {
      saveAttribute(updateData);
    }
  };

  const onSubmitDelete = () => {
    dispatch(
      removeAttribute({
        id: state.id,
        type: state.type === 'bodyfocus' ? 'bodyFocus' : state.type,
      }),
    );
    toggleFn();
  };

  const saveAttribute = async data => {
    try {
      dispatch(setAttributeLoader(true));

      const { result } = await request({
        url: `/neouadmin/v1/attributes`,
        method: 'PUT',
        data,
      });

      dispatch(setAttributeLoader(false));
      toggleFn();
      dispatch(requestAttributes());
    } catch ({ data }) {
      dispatch(setAttributeLoader(false));
      dispatch(openSnackBar(data.error || data.message));
    }
  };
  const imageChange = async event => {
    try {
      const { files } = event.target;
      if (files && files.length > 0) {
        const result = await addPhoto('equipments', event.target.files);
        setState({
          ...state,
          imageUrl: {
            png: result.Location.replace(
              `https://${BUCKET_NAME}.s3.${BUCKET_REGION}.amazonaws.com`,
              cloudfrontUrl,
            ),
          },
        });
      }
    } catch (error) {}
  };

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  return (
    <React.Fragment>
      <ConfirmDialog
        isOpen={state.isConfirmDialogOpen}
        headerText="Are you sure?"
        bodyText="You are about to delete the attribute which might impact the Concepts / Collections / Videos / Instructors associated with it."
        handleClose={() => updateState('isConfirmDialogOpen', false)}
        onAgree={() => {
          onSubmitDelete();
        }}
        onDisagree={() => updateState('isConfirmDialogOpen', false)}
      />
      <ConfirmDialog
        isOpen={state.isConfirmPublishOpen}
        headerText="Are you sure?"
        bodyText="This content is already published. Changes will impact existing behavior"
        handleClose={() => updateState('isConfirmPublishOpen', false)}
        onAgree={onHandleSubmit}
        onDisagree={() => updateState('isConfirmPublishOpen', false)}
      />

      <div className={classes.titleWrapper}>
        <NEInput
          id="attributeTitle"
          name="title"
          label="Tag Name"
          defaultValue={formDetail.title}
          onChange={e => handleChange(e)}
          fullWidth
          variant="outlined"
          error={state.isTitleError}
          helperText={state.titleError}
        />
      </div>
      <div className={classes.secondRow}>
        <NEInput
          id="attributeType"
          name="type"
          className={classes.typeInput}
          select
          label="Tag Type"
          defaultValue={formDetail.type}
          onChange={e => handleChange1(e)}
          variant="outlined"
        >
          <MenuItem value={formDetail.type} key={formDetail.type}>
            {formDetail.type}
          </MenuItem>
        </NEInput>
        <FormControlLabel
          value="top"
          control={
            <GreenCheckbox
              checked={state.isVisible}
              onChange={e => handleChange2(e)}
            />
          }
          label="Visible"
          labelPlacement="start"
        />
      </div>
      {state.type === 'equipment' && (
        <div>
          <Typography variant="subtitle1" align="center">
            Manage Images
          </Typography>
          <ImageUploader
            title=""
            aspectRatio="3x3"
            imgSrc={state.imageUrl.png}
            value={state.imageUrl.png}
            handleChange={imageChange}
            className={classes.imgContainer}
          />
        </div>
      )}
      <Grid>
        <div className={classes.btnContainer}>
          <Button
            onClick={event => {
              updateState('isConfirmDialogOpen', true);
            }}
            disableRipple
          >
            <div className={classes.delBtn}>DELETE</div>
          </Button>
          <Button
            onClick={event => {
              state.isPublished
                ? updateState('isConfirmPublishOpen', true)
                : onHandleSubmit();
            }}
            className={classes.newbtn}
            disableRipple
          >
            <div className={classes.saveLegalBtn}>SAVE</div>
          </Button>
          {state.isPublished ? (
            <Box display="flex" alignItems="center" mr={1}>
              <StatusLabel>STATUS</StatusLabel>
              <StatusText>PUBLISHED</StatusText>
            </Box>
          ) : (
            <Button onClick={onPublishSubmit}>
              <div className={classes.saveLegalBtn1}>PUBLISH</div>
            </Button>
          )}
        </div>
      </Grid>
    </React.Fragment>
  );
}
