/*
 *
 * MyAccount actions
 *
 */

import { action } from 'typesafe-actions';
import { Userdata } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const storePastClasses = data => {
  return action(ActionTypes.SET_PAST_CLASS_INFO, data);
};

export const requestPastClasses = id => {
  return action(ActionTypes.REQUEST_PAST_CLASSES, id);
};

export const updatePassword = (userData: Userdata) => {
  return action(ActionTypes.UPDATE_PASSWORD, userData);
};
