import * as React from 'react';
import { Button, makeStyles, Theme, createStyles } from '@material-ui/core';

const selectBtnStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.only('sm')]: {
        width: '187px',
      },
      [theme.breakpoints.up('md')]: {
        width: '288px',
      },

      height: '40px',
      border: 'none',
      borderRadius: '6px',
      borderColor: 'black',
      textTransform: 'uppercase',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '13px',
      fontWeight: 'bold',
      letterSpacing: '1px',
      lineHeight: '20px',
      padding: '8px',
      cursor: 'pointer',
      // background: 'linear-gradient(45deg, #22d6fe, #51fbfe)',
      background: '#12161A',
      color: '#FFFFFF',
      '&:hover': {
        background: '#505B63',
        color: 'white',
      },
    },
    disabled: {
      [theme.breakpoints.only('sm')]: {
        width: '187px',
      },
      [theme.breakpoints.up('md')]: {
        width: '288px',
      },

      height: '40px',
      border: 'none',
      borderRadius: '6px',
      borderColor: 'black',
      textTransform: 'uppercase',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '13px',
      fontWeight: 'bold',
      letterSpacing: '1px',
      lineHeight: '20px',
      padding: '8px',
      cursor: 'pointer',
      // background: 'linear-gradient(45deg, #22d6fe, #51fbfe)',
      background: '#e0e0e0',
      color: '#000000',
    },
  }),
);

export default function FilterSearchResultsButton(props) {
  let data = null;
  if (props.value || props.value === 0) {
    data = '(' + props.value + ')';
  } else {
    data = null;
  }
  const classes = selectBtnStyle();
  const handleChange = () => {
    props.onClick();
  };

  return (
    <>
      {props.value === 0 || (props && props.isVideoLoading) ? (
        <Button className={classes.disabled} disabled>
          SHOW RESULTS
        </Button>
      ) : (
        props.value !== 0 && (
          <Button className={classes.root} onClick={handleChange}>
            SHOW RESULTS {data ? data : null}
          </Button>
        )
      )}
    </>
  );
}
