import React, { useEffect } from 'react';
import request from '../../../shared/lib/request';
import Loader from '../../../components/Loader';
import { useState } from 'react';
import { slugify } from '../../../utils/slugify';
import {
  Dialog,
  withStyles,
  Grid,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '../../../components/Dialog';
import { dialogStyles } from './VideoFormStyles';
import { Formik, Form } from 'formik';
import { ClassSchema } from './ClassSchema';
import FormikSelect from '../../../components/Formik/FormikSelect';
import FormikImageUploader from '../../../components/Formik/FormikImageUploader';
import FormikAutocomplete from '../../../components/Formik/FormikAutocomplete';
import {
  HistoryText,
  StatusLabel,
  StatusText,
} from '../../../components/Typography';
import moment from 'moment';
import FormikField from '../../../components/Formik/FormikField';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../../Root/actions';
import { requestVideoList } from '../actions';
import ManageRenditions from './ManageRenditions';
import ManageAudios from './ManageAudios';
import { green } from '@material-ui/core/colors';
import StartDateTime from '../../../components/Formik/StartDateTime';
import ReplaceVideoUrl from './ReplaceVideoUrl';

const initialFormValues = {
  videoStatus: 'active',
  instructors: [],
  genres: [],
  bodyFocus: [],
  music: [],
  language: '',
  adminTags: [],
  likes: 0,
  title: '',
  description: '',
  endurance: '',
  strength: '',
  recovery: '',
  videoType: 'class',
  concept: '',
  classPlan: '',
  thumbnail: '',
  permaLink: '/videos/',
  fileName: '',
  liveStreamUrl: '',
  duration: '',
  difficulty: 'Beginner', // ToDo: Empty this after removed validation for generic class
  equipments: {
    optional: [],
    required: [],
  },
  flag: '',
  airedDate: null,
  isPublished: true,
  dataURL: '',
  explicitLang: false,
  isHidden: false,
  isNewClassNotification: false,
  style: '',
  impact: '',
  streamType: '',
  renditions: {
    hls: '',
    videos: [],
  },
  contentId: '5ec67dbdc6a6e33f30fd5be3',
};

const initialPossibleOptions = {
  concepts: [],
  instructors: [],
  genres: [],
  bodyFocus: [],
  equipments: [],
  musics: [],
  flags: [{ label: 'None', value: 'None' }],
  impact: [{ label: 'low', value: 'low' }],
  streamType: [{ label: 'live', value: 'live' }],
  videoType: [],
  style: [],
  difficulty: [],
  duration: [],
  numberValues: [],
  language: [],
};

const VideoFormDialog = ({ id, isOpen, classes, handleClose }) => {
  const [isLoading, setLoader] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [possibleOptions, setPossibleOptions] = useState(
    initialPossibleOptions,
  );
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  const [isConfirmPublishOpen, openConfirmPublishDialog] = React.useState(
    false,
  );
  const [isHideAlertOpen, showAlertToHide] = React.useState(false);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [isRenditionOpen, openRendition] = React.useState(false);
  const [isAudioOpen, openAudio] = React.useState(false);
  const [isReplaceVideo, openReplaceVideo] = React.useState(false);
  const [isAudioStatus, setAudioStatus] = React.useState(false);
  const [isPublish, setPublish] = React.useState(false);
  const [
    isNotificationAlertOpen,
    showAlertToCloseNotification,
  ] = React.useState(false);
  const [isEnableNotification, setEnableNotification] = React.useState(false);
  const [savedFormData, setSavedFormData] = React.useState({});
  const dispatch = useDispatch();
  const formRef = React.useRef();
  const _fetchVideoById = () =>
    request({
      url: `/neouadmin/v1/videos/${id}`,
      method: 'GET',
    });

  const _formInitialValues = (possibleValues, result) => {
    let {
      streamType = [],
      videoType = [],
      style = [],
      difficulty = [],
      language = [],
      duration = [],
    } = possibleValues;
    let possibleFlags =
      possibleValues.flags && possibleValues.flags.length > 0
        ? possibleValues.flags.map(flag => ({
            label: flag.title,
            value: flag.title,
          }))
        : [];
    let flags = [{ label: 'None', value: 'None' }, ...possibleFlags];
    let possibleOptions = {
      concepts: possibleValues.concepts || [],
      instructors: possibleValues.instructors
        ? possibleValues.instructors.map(i => ({
            id: i.id,
            title: `${i.firstName} ${i.lastName}`,
          }))
        : [],
      genres: possibleValues.genres || [],
      bodyFocus: possibleValues.bodyFocus || [],
      equipments: possibleValues.Equipments || [],
      musics: possibleValues.musics || [],
      flags: flags,
      impact:
        possibleValues.impact && possibleValues.impact.length > 0
          ? possibleValues.impact.map(i => ({ label: i.title, value: i.title }))
          : [],
      streamType:
        streamType && streamType.length > 0
          ? streamType.map(s => ({ label: s.title, value: s.title }))
          : [],
      videoType: [
        { label: 'class', value: 'class' },
        { label: 'generic', value: 'generic' },
        { label: 'live', value: 'live' },
      ],
      style:
        style && style.length > 0
          ? style.map(v => ({ label: v.title, value: v.title }))
          : [],
      difficulty:
        difficulty && difficulty.length > 0
          ? difficulty.map(v => ({ label: v.title, value: v.title }))
          : [],
      language: possibleValues.language || [],
      duration:
        duration && duration.length > 0
          ? duration.map(d => ({ label: d.title, value: d.title }))
          : [],
      numberValues: [],
    };
    for (let i = 0; i < 6; i++) {
      possibleOptions.numberValues.push({ label: i, value: i });
    }
    let { permaLink, startDate, renditions } = result;
    let perma =
      result.videoType === 'live' && !permaLink.includes('live/')
        ? permaLink.replace('videos/', 'videos/live/')
        : permaLink;
    if (startDate) {
      result.airedDate = moment(+startDate).format('YYYY-MM-DDTHH:mm');
    }
    let videoInfo = {
      ...result,
      flag: !result.flag ? 'None' : result.flag,
      language: !result.language
        ? possibleValues &&
          possibleValues.language &&
          possibleValues.language.length > 0
          ? possibleValues.language[0]
          : 'English'
        : result.language,
      videoType: result.videoType == '' ? 'class' : result.videoType,
      permaLink: permaLink && permaLink.includes('http') ? '/videos/' : perma,
      fileName: result.fileName || (renditions && renditions.hls),
      title: result.title && result.title.includes('http') ? '' : result.title,
      instructors: result.instructors
        ? result.instructors.map(i => ({
            id: i.id,
            title: `${i.firstName} ${i.lastName}`,
          }))
        : [],
      equipments: {
        required:
          result.equipments &&
          result.equipments.required &&
          result.equipments.required.length > 0
            ? result.equipments.required
            : [],
        optional:
          result.equipments &&
          result.equipments.optional &&
          result.equipments.optional.length > 0
            ? result.equipments.optional
            : [],
      },
    };
    return { possibleOptions, videoInfo };
  };

  const _formRequestBody = formData => {
    if (!formData.style) {
      delete formData.style;
    }
    if (formData.videoType.toLowerCase() === 'generic') {
      if (formData.difficulty || formData.difficulty === '') {
        delete formData.difficulty;
      }
      if (formData.impact || formData.impact === '') {
        delete formData.impact;
      }
    }
    if (formData.title != '' && formData.permaLink == '/videos/') {
      formData.permaLink = `/videos/${formData.title}`;
    }
    if (formData.airedDate) {
      if (formData.videoType.toLowerCase() === 'live') {
        let currentTime = moment(Date.now());
        let startTime = moment(formData.airedDate);
        let isStarted = startTime.diff(currentTime) >= 30000;
        if (!isStarted) {
          dispatch(openSnackBar('Live video should have future time'));
          return;
        }
      }
      formData.startDate = moment(formData.airedDate)
        .utc()
        .valueOf()
        .toString();
    }
    if (formData.videoType !== 'live') {
      delete formData.startDate;
    }
    delete formData.fileName;
    return {
      ...formData,
      contentId: formData.id,
      flag: formData.flag === 'None' ? '' : formData.flag,
      instructors: formData.instructors.map(i => i.id),
      genres: formData.genres.map(i => i.id),
      bodyFocus: formData.bodyFocus.map(i => i.id),
      music: formData.music.map(i => i.id),
      language: (formData.language && formData.language.id) || '',
      concept: (formData.concept && formData.concept.id) || '',
      equipments: {
        required:
          formData.equipments && formData.equipments.required
            ? formData.equipments.required.map(i => i.id)
            : [],
        optional:
          formData.equipments && formData.equipments.optional
            ? formData.equipments.optional.map(i => i.id)
            : [],
      },
    };
  };

  const saveVideo = async formData => {
    try {
      if (isEnableNotification) {
        savedFormData.isNewClassNotification = true;
      }
      await request({
        url: '/neouadmin/v1/videos',
        method: 'PUT',
        data: formData || savedFormData,
      });
      setLoader(false);
      if (isPublish) {
        dispatch(openSnackBar('The video has been published successfully!'));
      } else {
        dispatch(openSnackBar('The video has been saved successfully!'));
      }
      dispatch(requestVideoList());
      handleClose();
    } catch ({ data: error }) {
      setLoader(false);
      if (error.error) {
        dispatch(openSnackBar(`Save Video Failed -  ${error.error}`));
      } else {
        dispatch('Error in saving the video..');
      }
    }
  };

  const _init = async videoId => {
    try {
      let { responseCode, result, ...possibleValues } = await _fetchVideoById(
        videoId,
      );
      if (result.videoStatus.toUpperCase() !== 'ACTIVE') {
        dispatch(
          openSnackBar(
            'Renditions is under processing! Try again after sometime',
          ),
        );
        handleClose();
      } else {
        const { possibleOptions, videoInfo } = _formInitialValues(
          possibleValues,
          result,
        );
        setFormValues({ ...initialFormValues, ...videoInfo });

        setPossibleOptions({ ...initialPossibleOptions, ...possibleOptions });
      }
    } catch (error) {
      dispatch(openSnackBar('Error in fetching the video details'));
    }
  };

  const _handleSubmit = values => {
    let body = _formRequestBody(values);
    if(body.airedDate === null){
      delete body.airedDate
    }
    if (isPublish) {
      if (body.isHidden) {
        body.isHidden = Boolean(false);
        body.isPublished = Boolean(true);
        setSavedFormData({ ...body });
        saveVideo(body);
      } else {
        body.isHidden = Boolean(false);
        body.isPublished = Boolean(true);
        setSavedFormData({ ...body });
        showAlertToCloseNotification(true);
      }
    } else {
      body.isHidden = Boolean(body.isHidden);
      body.isPublished = Boolean(body.isPublished);
      setSavedFormData({ ...body });
      if (body.isPublished) {
        openConfirmPublishDialog(true);
      } else {
        saveVideo(body);
      }
    }
  };

  const previewVideo = () => {
    window.open(`${window.origin}/preview/video/${id}`);
  };

  const hideVideo = async (
    values,
    { submitForm, errors, setErrors, setFieldValue, setTouched },
  ) => {
    let f = _formRequestBody(values);
    f.isHidden = Boolean(true);
    f.isPublished = Boolean(false);
    setSavedFormData({ ...f });
    setLoader(true);
    try {
      await request({
        url: '/neouadmin/v1/videos',
        method: 'PUT',
        data: f || savedFormData,
      });
      setLoader(false);
      dispatch(openSnackBar('The Video status has been updated successfully!'));
      dispatch(requestVideoList());
      handleClose();
    } catch ({ data: error }) {
      setLoader(false);
      if (error.error) {
        dispatch(openSnackBar(`Hide Video Failed -  ${error.error}`));
      } else {
        dispatch(openSnackBar('Error in hiding the video'));
      }
    }
  };

  useEffect(() => {
    _init(id);
  }, [id]);

  return (
    <>
      {isLoading && <Loader fullWidth />}

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick={true}
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
        >
          {'EDIT VIDEO DEFINITION'}
        </DialogTitle>
        <DialogContent dividers classes={{ root: classes.dialogContent }}>
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the Videos."
            onAgree={() => handleClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <Formik
            initialValues={formValues}
            enableReinitialize
            validationSchema={ClassSchema}
            onSubmit={_handleSubmit}
            innerRef={formRef}
          >
            {({
              values,
              setFieldValue,
              submitForm,
              errors,
              isValid,
              setErrors,
              validateForm,
              setTouched,
            }) => {
              return (
                <Form noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormikSelect
                        name="videoType"
                        items={possibleOptions.videoType}
                        label="Video Type*"
                        onBlur={() => {
                          setFieldValue(
                            'permaLink',
                            values.videoType === 'live'
                              ? '/videos/live/' + slugify(values.title)
                              : '/videos/' + slugify(values.title),
                          );
                        }}
                      />
                      <FormikImageUploader
                        name={'thumbnail'}
                        aspectRatio="16x9"
                        key="16x9"
                        defaultValue={values.thumbnail}
                      />
                      <Button
                        color="secondary"
                        type="button"
                        onClick={() => openRendition(true)}
                        fullWidth
                      >
                        Renditions
                      </Button>
                      <br />

                      <div
                        style={{
                          padding: '10px 0px',
                        }}
                      >
                        <>
                          <Button
                            color="secondary"
                            type="button"
                            onClick={() =>
                              !isAudioStatus
                                ? openAudio(true)
                                : dispatch(
                                    openSnackBar(
                                      'Multiple Audio tracks already added. Please save & close the video...',
                                    ),
                                  )
                            }
                            fullWidth
                          >
                            {values.audioTracks
                              ? 'Edit Audio Track'
                              : 'Add Audio Track'}
                          </Button>
                        </>
                      </div>

                      <div>
                        <Button
                          color="secondary"
                          type="button"
                          onClick={() => openReplaceVideo(true)}
                          fullWidth
                        >
                          Replace Video
                        </Button>
                      </div>

                      <br />
                      <br />
                      {values.videoType === 'live' && (
                        <StartDateTime
                          type="Edit"
                          date={values.airedDate}
                          selectedValue={setFieldValue}
                        />
                      )}
                      {values.videoType !== 'live' && (
                        <StartDateTime
                          type="Edit"
                          date={values.airedDate}
                          selectedValue={setFieldValue}
                          label={'Aired'}
                          value="airedDate"
                        />
                      )}
                      <FormikSelect
                        items={
                          possibleOptions.flags &&
                          possibleOptions.flags.length > 0
                            ? possibleOptions.flags
                            : []
                        }
                        name="flag"
                        label="Flag"
                      />

                      <FormikSelect
                        items={
                          possibleOptions && possibleOptions.style.length > 0
                            ? possibleOptions.style
                            : []
                        }
                        name="style"
                        label="Style"
                      />
                      <FormikSelect
                        items={
                          possibleOptions &&
                          possibleOptions.streamType.length > 0
                            ? possibleOptions.streamType
                            : []
                        }
                        name="streamType"
                        label="Stream Type *"
                      />

                      <FormikAutocomplete
                        name={'adminTags'}
                        label="Admin Tags"
                        freeSolo
                        multiple
                      />

                      <Grid item>
                        <Typography variant="h6">HISTORY</Typography>
                        <HistoryText>
                          Added:{' '}
                          {values.createdAt
                            ? moment(values.createdAt).format('MM/DD/YYYY')
                            : '-'}
                        </HistoryText>
                        {values.isPublished && (
                          <HistoryText>
                            Published:
                            {values.publishedDate
                              ? moment(values.publishedDate).format(
                                  'MM/DD/YYYY',
                                )
                              : '-'}
                          </HistoryText>
                        )}
                        <HistoryText>
                          Updated:{' '}
                          {values.updatedAt
                            ? moment(values.updatedAt).format('MM/DD/YYYY')
                            : '-'}
                        </HistoryText>
                        {values.updatedBy && (
                          <HistoryText>
                            Updated by: {values.updatedBy}
                          </HistoryText>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={8}>
                      <FormikField name="permaLink" label="Permalink" />
                      <FormikField
                        name="fileName"
                        label="FileName"
                        disabled={true}
                      />
                      {values.videoType === 'live' && (
                        <FormikField
                          name="liveStreamUrl"
                          label="Live Stream Url*"
                        />
                      )}
                      <Grid container spacing={2}>
                        <Grid item container xs={6}>
                          <FormikField
                            name="title"
                            label="Title *"
                            onBlur={() => {
                              setFieldValue(
                                'permaLink',
                                values.videoType === 'live'
                                  ? '/videos/live/' + slugify(values.title)
                                  : '/videos/' + slugify(values.title),
                              );
                            }}
                          />

                          <Grid
                            container
                            spacing={1}
                            style={{ display: 'flex' }}
                          >
                            <Grid item xs={5}>
                              <FormikSelect
                                items={possibleOptions.duration.sort(
                                  (a, b) => a.value - b.value,
                                )}
                                name="duration"
                                label="Duration *"
                              />
                            </Grid>
                            {values.videoType !== 'generic' && (
                              <Grid item xs={7}>
                                <FormikSelect
                                  items={possibleOptions.difficulty}
                                  name="difficulty"
                                  label="difficulty *"
                                />
                              </Grid>
                            )}
                          </Grid>

                          {values.videoType !== 'generic' && (
                            <Grid container spacing={1}>
                              <Grid item xs={4}>
                                <FormikSelect
                                  items={possibleOptions.numberValues}
                                  name="endurance"
                                  label="Endurance *"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormikSelect
                                  items={possibleOptions.numberValues}
                                  name="strength"
                                  label="Strength *"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormikSelect
                                  items={possibleOptions.numberValues}
                                  name="recovery"
                                  label="Recovery *"
                                />
                              </Grid>
                            </Grid>
                          )}

                          <FormikField
                            name="description"
                            label="Description *"
                            multiline
                          />
                          {values.videoType !== 'generic' && (
                            <FormikField
                              name="classPlan"
                              label="Class Plan"
                              multiline
                            />
                          )}
                        </Grid>

                        <Grid xs={6}>
                          <FormikAutocomplete
                            items={possibleOptions.concepts}
                            name="concept"
                            label="Concept *"
                            multiple={false}
                          />
                          <FormikAutocomplete
                            items={possibleOptions.instructors}
                            name="instructors"
                            label="Instructors *"
                            multiple
                          />
                          <FormikAutocomplete
                            items={possibleOptions.genres}
                            name="genres"
                            label="Genres *"
                            multiple
                          />
                          {values.videoType !== 'generic' && (
                            <>
                              <FormikAutocomplete
                                items={possibleOptions.bodyFocus}
                                name="bodyFocus"
                                label="Body Focus *"
                                multiple
                              />
                              <FormikSelect
                                items={possibleOptions.impact}
                                name="impact"
                                label="Impact *"
                              />
                              <FormikAutocomplete
                                items={possibleOptions.equipments}
                                name="equipments.required"
                                label="Required Equipment"
                                multiple
                              />
                              <FormikAutocomplete
                                items={possibleOptions.equipments}
                                name="equipments.optional"
                                label="Optional Equipment"
                                multiple
                              />
                              <FormikAutocomplete
                                items={possibleOptions.musics}
                                name="music"
                                label="Music *"
                                multiple
                              />
                              <FormikAutocomplete
                                items={possibleOptions.language}
                                name="language"
                                label="Language *"
                              />
                            </>
                          )}

                          <FormControlLabel
                            classes={{
                              root: classes.customStyle,
                            }}
                            control={
                              <GreenCheckbox
                                name="explicitLang"
                                checked={values.explicitLang}
                                onChange={e =>
                                  setFieldValue(
                                    'explicitLang',
                                    event.target.checked,
                                  )
                                }
                              />
                            }
                            label={
                              <Typography className={classes.formControlLabel}>
                                Explicit Language
                              </Typography>
                            }
                            labelPlacement="end"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <DialogActions classes={{ root: classes.dialogActions }}>
                    <div>
                      {values.isHidden ? (
                        <Box display="flex" alignItems="center" mr={1}>
                          <StatusLabel>STATUS</StatusLabel>
                          <StatusText>HIDDEN</StatusText>
                        </Box>
                      ) : (
                        <>
                          <Button
                            color="secondary"
                            onClick={() => showAlertToHide(true)}
                          >
                            Hide
                          </Button>
                          <ConfirmDialog
                            isOpen={isHideAlertOpen}
                            headerText="Are you sure?"
                            bodyText="You are about to hide the video"
                            onAgree={() =>
                              hideVideo(values, {
                                submitForm,
                                errors,
                                isValid,
                                setErrors,
                                validateForm,
                                setFieldValue,
                                setTouched,
                              })
                            }
                            handleClose={() => showAlertToHide(false)}
                            onDisagree={() => showAlertToHide(false)}
                          ></ConfirmDialog>
                        </>
                      )}
                    </div>
                    {/* {values.isPublished && (
                      <div>
                        <Box display="flex" alignItems="center" mr={1}>
                          <StatusLabel>STATUS</StatusLabel>
                          <StatusText>PUBLISHED</StatusText>
                        </Box>
                      </div>
                    )} */}
                    <div style={{ display: 'flex' }}>
                      <>
                        {!values.isPublished ? (
                          <Button
                            size="large"
                            color="secondary"
                            type="submit"
                            // onClick={() =>
                            //   publishVideo(values, {
                            //     submitForm,
                            //     errors,
                            //     isValid,
                            //     setFieldValue,
                            //   })
                            // }
                            onClick={e => {
                              if (isValid) {
                                setPublish(true);
                              }
                            }}
                          >
                            Publish
                          </Button>
                        ) : (
                          <Box display="flex" alignItems="center" mr={1}>
                            <StatusLabel>STATUS</StatusLabel>
                            <StatusText>PUBLISHED</StatusText>
                          </Box>
                        )}

                        {/* <Button
                          size="large"
                          color="secondary"
                          type="button"
                          onClick={() =>
                            previewVideo(values, {
                              submitForm,
                              errors,
                              isValid,
                            })
                          }
                        >
                          Preview
                        </Button> */}
                      </>
                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        size="large"
                      >
                        Save & Close
                      </Button>
                    </div>
                  </DialogActions>
                  <ConfirmDialog
                    isOpen={isConfirmDialogOpen}
                    headerText="Are you sure?"
                    bodyText="You are about to delete all the videos."
                    handleClose={() => openConfirmDialog(false)}
                    onAgree={() => {
                      setFieldValue('videos.videoIds', []);
                    }}
                    onDisagree={() => openConfirmDialog(false)}
                  />
                  <ConfirmDialog
                    isOpen={isConfirmPublishOpen}
                    headerText="Are you sure?"
                    bodyText="This content is already published. Changes will impact existing behavior"
                    handleClose={() => openConfirmPublishDialog(false)}
                    onAgree={() => {
                      saveVideo();
                    }}
                    onDisagree={() => openConfirmPublishDialog(false)}
                  />
                  <ConfirmDialog
                    isOpen={isNotificationAlertOpen}
                    headerText="Publishing a video for the first time?"
                    bodyText={
                      <FormControlLabel
                        value="top"
                        name={`name`}
                        control={
                          <GreenCheckbox
                            checked={isEnableNotification}
                            onChange={() => {
                              setEnableNotification(!isEnableNotification);
                            }}
                          />
                        }
                        label="Enable new class notification"
                        labelPlacement="end"
                      />
                    }
                    agreeButtonText="OK"
                    isDisAgreeShow={false}
                    onAgree={() => {
                      saveVideo();
                    }}
                    handleClose={() => showAlertToCloseNotification(false)}
                    onDisagree={() => showAlertToCloseNotification(false)}
                  ></ConfirmDialog>
                  {isRenditionOpen && (
                    <ManageRenditions
                      isOpen={isRenditionOpen}
                      value={values.renditions}
                      timing={values.runtime}
                      videoId={values.id}
                      onClearEvent={() => {
                        openRendition(false);
                      }}
                      onChange={videos => {
                        setFieldValue('renditions.videos', videos);
                      }}
                    />
                  )}
                  {isAudioOpen && !isAudioStatus && (
                    <ManageAudios
                      isOpen={isAudioOpen}
                      title={
                        values.audioTracks
                          ? 'EDIT AUDIO TRACKS'
                          : 'ADD AUDIO TRACKS'
                      }
                      audioTracks={values.audioTracks || ''}
                      videoId={values.id}
                      runtime={values.runtime || 0}
                      selectedValue={setFieldValue}
                      onClearEvent={() => {
                        openAudio(false);
                      }}
                      contentId={values.contentId}
                      isAudioAdded={() => {
                        setAudioStatus(true);
                      }}
                    />
                  )}
                  {isReplaceVideo && (
                    <ReplaceVideoUrl
                      isOpen={isReplaceVideo}
                      title={'REPLACE VIDEO'}
                      videoUrl={''}
                      videoId={values.id}
                      onClearEvent={() => {
                        openReplaceVideo(false);
                      }}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default withStyles(dialogStyles)(VideoFormDialog);

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);
