/*
 *
 * HomeCurate constants
 *
 */

/*
 *
 * HomeCurate constants
 *
 */
/*
 *
 * HomeCurate constants
 *
 */
/*
 *
 * HomeCurate constants
 *
 */
enum ActionTypes {
  DEFAULT_ACTION = 'app/HomeCurate/DEFAULT_ACTION',
  REQUEST_HOME_PAGE_INFO = 'app/HomeCurate/REQUEST_HOME_PAGE_INFO',
  STORE_HOME_PAGE_INFO = 'app/HomeCurate/STORE_HOME_PAGE_INFO',
  REORDER_TRAYS = 'app/HomeCurate/REORDER_TRAY',
  UPDATE_HOME_COMPONENT = 'app/HomeCurate/UPDATE_HOME_COMPONENT',
  DELETE_HOME_COMPONENT = 'app/HomeCurate/DELETE_HOME_COMPONENT',
  SET_LOADER = 'app/HomeCurate/SET_LOADER',
}

export default ActionTypes;
