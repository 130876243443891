/*
 *
 * MyAccount reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  pastClasses: {},
};

function myAccountReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_PAST_CLASSES:
      return { ...state };
    case ActionTypes.SET_PAST_CLASS_INFO:
      return { ...state, pastClasses: action.payload };
    default:
      return state;
  }
}

export default myAccountReducer;
