/**
 *
 * NeIcon
 *
 */
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import HomeSvg from '../../images/icons/navigation/home.svg';

// import styled from 'styles/styled-components';
// npx @svgr/cli --icon icon.svg

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

interface Props {}

function SvgChevron(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 9 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#12161A" d="M.5 15.5V.5h8v15z" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.25 1.294l6.5 6.77-6.5 6.771"
        />
      </g>
    </svg>
  );
}

export { SvgChevron };
