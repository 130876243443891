import { createGlobalStyle } from 'styled-components';

import ProximanovaBold from './proximanova-bold.otf';
import ProximanovaLight from './proximanova-light.otf';
import ProximanovaRegular from './proximanova-regular.otf';
import ProximanovaSemibold from './proximanova-semibold.otf';
import ProximanovaMedium from './proximanova-medium.otf';
import TurbinadoDryRegular from './turbinado_dry.otf';

export default createGlobalStyle`
    @font-face {
        font-family: 'Proxima-Nova-Regular';
        src: url(${ProximanovaRegular});
    }
    @font-face {
        font-family: ProximaNova-Bold;
        src: url(${ProximanovaBold});
    }
    @font-face {
        font-family: ProximaNova-Light;
        src: url(${ProximanovaLight});
    }
    @font-face {
        font-family: ProximaNova-Semibold;
        src: url(${ProximanovaSemibold});
    }
    @font-face {
        font-family: ProximaNova-Medium;
        src: url(${ProximanovaMedium});
    }
    @font-face {
        font-family: TurbinadoDryRegular;
        src: url(${TurbinadoDryRegular});
    }
`;
