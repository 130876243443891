import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const focusedColor = '#FFFFFF';
const defaultColor = '#909599';
const defaultLabelColor = '#060B0F';
const hoverLabelColor = '#060B0F';
const focusedLabelColor = '#060B0F';
const inputHoverColor = '#E3E3E4';
const inputFocusedColor = '#060B0F';
const errorColor = '#FF1C58';
const borderColor = '1px solid #E3E3E4';

const NEInputLight = withStyles({
  root: {
    minHeight: 64,
    marginBottom: '8px',
    '& label.MuiInputLabel-outlined': {
      color: defaultColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 16px) scale(1)',
      color: defaultColor,
      fontSize: '16px',
      fontFamily: 'Proxima-Nova-Regular',
      fontWeight: '500',
      letterSpacing: '0',
      lineHeight: '20px',
    },

    '& label.Mui-focused': {
      color: focusedLabelColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.Mui-focused.Mui-error': {
      color: errorColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: borderColor,
    },
    '& .MuiOutlinedInput-root': {
      // height: 48,
      borderRadius: '6px',
      '& fieldset': {
        border: borderColor,
      },
      '&:hover fieldset': {
        borderColor: inputHoverColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${inputFocusedColor}`,
      },
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        color: '#000',
        padding: '14.5px 14px',
        fontFamily: 'Proxima-Nova-Regular',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      // height: 48,
      borderRadius: '6px',
      '& fieldset': {
        borderColor: errorColor,
      },
      '&:hover fieldset': {
        color: defaultColor,
        borderColor: errorColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${errorColor}`,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '4px',
      '&.Mui-error': {
        color: errorColor,
      },
    },
  },
})(TextField);

const NEInputDark = withStyles({
  root: {
    minHeight: 60,
    width: '100%',
    '& label.MuiInputLabel-outlined': {
      color: defaultColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 16px) scale(1)',
      color: defaultLabelColor,
      fontSize: '16px',
    },

    '& label.Mui-focused': {
      color: defaultLabelColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.Mui-focused.Mui-error': {
      color: errorColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: borderColor,
    },
    '& .MuiOutlinedInput-root': {
      // height: 48,
      borderRadius: '6px',
      '& fieldset': {
        borderColor: borderColor,
      },
      '&:hover fieldset': {
        borderColor: borderColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${borderColor}`,
      },
    },
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        color: focusedColor,
        padding: '14.5px 14px',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      // height: 48,
      borderRadius: '6px',
      '& fieldset': {
        borderColor: errorColor,
      },
      '&:hover fieldset': {
        color: defaultColor,
        borderColor: errorColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${errorColor}`,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '4px',
      '&.Mui-error': {
        color: errorColor,
      },
    },
  },
})(TextField);

export default NEInputDark;

export { NEInputLight };
