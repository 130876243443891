/**
 *
 * ProfileAvatar
 *
 */
import React from 'react';
import { Avatar, Theme, createStyles, makeStyles } from '@material-ui/core';
import useStyles from './styles';
import { SvgProfile } from '../../NeIcons';
import { FooterLink } from '../../styles/Typography';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
// import styled from 'styles/styled-components';

interface Props {
  name?: string;
  uri?: string;
}

function ProfileAvatar(props: Props) {
  const { name = '', uri } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Avatar alt={name} src={uri} className={classes.avatar}>
      {!uri && name ? name.substring(0, 1) : <SvgProfile />}
    </Avatar>
  );
}

export default ProfileAvatar;
