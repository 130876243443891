import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, ErrorMessage } from 'formik';
import { Chip } from '@material-ui/core';

const focusedColor = '#000';
const defaultColor = '#909599';
const defaultLabelColor = '#060B0F';
const hoverLabelColor = '#060B0F';
const focusedLabelColor = '#060B0F';
const inputHoverColor = '#E3E3E4';
const inputFocusedColor = '#060B0F';
const errorColor = '#FF1C58';
const borderColor = '#E3E3E4';

export const AutocompleteInput = withStyles({
  root: {
    marginBottom: 16,
    width: '100%',
    '& .MuiChip-outlined': {
      '& .MuiChip-deleteIcon': {
        color: '#fff !important',
        '&:hover': {
          color: '#fff !important',
        },
      },
    },
    '& label.MuiInputLabel-outlined': {
      color: defaultColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 16px) scale(1)',
      color: defaultColor,
      fontSize: '16px',
      fontFamily: 'Proxima-Nova-Regular',
      fontWeight: '500',
      letterSpacing: '0',
      lineHeight: '20px',
    },

    '& label.Mui-focused': {
      color: focusedLabelColor,
      '&.Mui-error': {
        color: errorColor,
      },
    },
    '& label.Mui-focused.Mui-error': {
      color: errorColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: borderColor,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      padding: '9px 14px',
      '& fieldset': {
        borderColor,
      },
      '&:hover fieldset': {
        borderColor: inputHoverColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${inputFocusedColor}`,
      },
    },
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        color: focusedColor,
        padding: '5.5px 14px',
        fontFamily: 'Proxima-Nova-Regular',
        fontStyle: 'italic',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      borderRadius: '6px',
      '& fieldset': {
        borderColor: errorColor,
      },
      '&:hover fieldset': {
        color: defaultColor,
        borderColor: errorColor,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${errorColor}`,
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '4px',
      '&.Mui-error': {
        color: errorColor,
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
      '& .MuiChip-outlined': {
        '& .MuiChip-deleteIcon': {
          color: '#fff !important',
          '&:hover': {
            color: '#fff !important',
          },
        },
      },
    },
    tag: {
      background: '#3C4042',
      color: '#fff',
      height: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    deleteIcon: {
      color: '#fff',
      width: '18px',
      opacity: 1,
      '&:hover': {
        color: '#fff',
        opacity: '0.5',
      },
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    autocompleteOptions: {
      fontFamily: 'Proxima-Nova-Regular',
    },
    autocompleteInputRoot: {},
    autocompleteRoot: {},
  }),
);

interface FormikAutocompleteItems {
  id: string;
  title: string;
}

interface FormikAutocompleteProps {
  placeholder?: string;
  name: string;
  label?: string;
  defaultValue?: string;
  items: FormikAutocompleteItems[];
  freeSolo?: boolean;
  multiple?: boolean;
  getOptionLabel?: (any) => string;
  onBlur?: any;
}

const FormikAutocomplete: React.FC<FormikAutocompleteProps> = props => {
  const classes = useStyles();
  const {
    items = [],
    placeholder = 'Add',
    label = 'Categories',
    name,
    defaultValue,
    disabled = false,
    freeSolo,
    multiple,
    getOptionLabel = option => option.title || '',
    onBlur,
  } = props;

  const [value, setValue] = useState(true);
  function disableOption(option, value) {
    if (defaultValue && defaultValue.length > 0) {
      return !!defaultValue.find(c => c.id === option.id);
    } else if (value && value.length > 0) {
      return !!value.find(c => c.id === option.id);
    } else {
      return false;
    }
  }

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <div className={classes.root}>
          <Autocomplete
            options={items}
            value={field.value}
            disabled={disabled}
            getOptionDisabled={option => disableOption(option, field.value)}
            classes={{
              root: classes.autocompleteRoot,
              tag: classes.tag,
              option: classes.autocompleteOptions,
              inputRoot: classes.autocompleteInputRoot,
            }}
            onChange={(e, data) => {
              form.setFieldValue(name, data);
              form.setFieldTouched(name, true, false);
            }}
            onInputChange={(e, value) =>
              form.setFieldTouched(name, true, false)
            }
            id={name}
            freeSolo={freeSolo}
            multiple={multiple}
            onBlur={onBlur}
            getOptionLabel={getOptionLabel}
            ChipProps={{
              classes: {
                deleteIcon: classes.deleteIcon,
                label: classes.chipLabel,
              },
            }}
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={(option && option.title) || option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={params => (
              <AutocompleteInput
                {...params}
                variant="outlined"
                label={label}
                placeholder={placeholder}
                onChange={() => form.setFieldTouched(name, true, false)}
                helperText={
                  meta.touched && meta.error && <ErrorMessage name={name} />
                }
                error={Boolean(
                  meta.touched && meta.error && <ErrorMessage name={name} />,
                )}
              />
            )}
          />
        </div>
      )}
    </Field>
  );
};

export default FormikAutocomplete;
