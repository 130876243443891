import * as React from 'react';
import { Field, ErrorMessage } from 'formik';
import ImageUploader from '../ImageUploader';
import { createStyles, withStyles } from '@material-ui/core';
import { addPhoto } from '../../services/s3';
import { BUCKET_NAME, BUCKET_REGION, cloudfrontUrl } from '../../utils/config';
import { ErrorText } from '../Typography';

const styles = () =>
  createStyles({
    wrapper: {
      width: '100%',
    },
  });

enum GraphicsAspectRatio {
  '16x9' = '16x9',
  '32x9' = '32x9',
  '9x16' = '916',
}
interface FormikImageUploaderProps {
  name: string;
  title?: string;
  subTitle?: string;
  aspectRatio: GraphicsAspectRatio;
  defaultValue: string;
  classes: object;
  ispromoValidation?: boolean;
}

const FormikImageUploader: React.FC<FormikImageUploaderProps> = ({
  name,
  defaultValue = '',
  aspectRatio,
  title = '',
  subTitle = '',
  classes,
  ispromoValidation,
}) => {
  React.useEffect(() => {}, [defaultValue]);
  const [isLoading, setLoader] = React.useState(false);
  const [isImageSize, setImageSize] = React.useState(false);

  const imageUploadFunction = async (files, form) => {
    setLoader(true);
    let result = await addPhoto('concept', files);
    let gResult = result.Location.replace(
      'https://' + BUCKET_NAME + '.s3.' + BUCKET_REGION + '.amazonaws.com',
      cloudfrontUrl,
    );
    form.setFieldValue(name, gResult);
    form.setFieldTouched(name, true, false);
    setLoader(false);
  }

  const handleImage = async (e, graphicsobj, form) => {
    try {
      let files = e.target.files;
      if (ispromoValidation) {
        const Img = new Image();
        Img.src = URL.createObjectURL(files[0]);
        Img.onload = async (e: any) => {
          const height = e.path[0].height;
          const width = e.path[0].width;
          console.log(width, height);
          if (width === 1300 && height === 460) {
            setImageSize(false);
            if (files && files.length > 0) {
              imageUploadFunction(files, form);
            }
           
          } else {
            setImageSize(true);
          }
        }
      } else {
        if (files && files.length > 0) {
          setLoader(true);
          let result = await addPhoto('concept', files);
          let gResult = result.Location.replace(
            'https://' + BUCKET_NAME + '.s3.' + BUCKET_REGION + '.amazonaws.com',
            cloudfrontUrl,
          );
          form.setFieldValue(name, gResult);
          form.setFieldTouched(name, true, false);
          setLoader(false);
        }
      }
    } catch (error) {
      console.log('error :', error);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <>
              <ImageUploader
                isLoading={isLoading}
                title={title}
                subTitle={subTitle}
                aspectRatio={aspectRatio}
                value={defaultValue}
                handleChange={(e, g) => {
                  handleImage(e, g, form);
                }}
                id={name}
                key={name}
              />
              {isImageSize && <ErrorText>
                Image size must be 1300 X 460
              </ErrorText>}
              {meta.touched && meta.error && (
                <ErrorText>
                  <ErrorMessage name={name} />
                </ErrorText>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default withStyles(styles)(FormikImageUploader);
