/**
 *
 * Menu
 *
 */

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MenuItem } from '@material-ui/core';
import { SvgChevron } from '../../ProgramDetail/NeIcons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    popover: {
      borderRadius: 6,
      border: '1px solid #35414A',
      boxShadow: '0 0 8px 0 #050606',
    },
    menuItem: {
      background: '#080B0D',
      minHeight: '26px',
      color: '#fff',
      fontFamily: 'Proxima-Nova-Regular, Helvetica, Arial, sans-serif',
      fontSize: '13px',
      lineHeight: '19px',
      width: '120px',
      textTransform: 'capitalize',
      '&:hover': {
        color: '#56FFFE',
        background: '#080B0D',
      },
      '&.Mui-selected': {
        color: '#56FFFE',
        background: '#080B0D',
        fontFamily: 'ProximaNova-Bold, Helvetica, Arial, sans-serif',
      },
    },
    popoverPaper: {
      backgroundColor: '#080B0D',
      padding: '16px',
      // maxHeight: '270px',
      maxWidth: 'none',
      minWidth: 'none',
      justifyContent: 'flex-start',
      border: '1px solid #35414A',
      marginTop: 12,
      overflow: 'visible',
      // width: '100%',
      '&:before': {
        width: '15px',
        height: '15px',
        content: '"  "',
        zIndex: 100000000,
        position: 'absolute',
        left: 'calc(50% - 8px)',
        top: '-8px',
        transform: 'rotate(45deg)',
        background: '#080B0D',
        borderLeft: '1px solid #35414A',
        borderTop: '1px solid #35414A',
      },
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      maxHeight: '360px',
      background: 'transparent',
    },
    menuContainer: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: 44,
    },
    menuValue: {
      color: '#FFF',
      fontFamily: 'Proxima-Nova-Regular, Helvetica, Arial, sans-serif',
      fontSize: 14,
      lineHeight: '18px',
      padding: '0 16px',
      textTransform: 'capitalize',
      height: 18,
      margin: 'auto',
      [theme.breakpoints.up('xl')]: {
        fontSize: 16,
      },
    },
  }),
);

interface Genre {
  id: string;
  title: string;
}

interface IMenuProps {
  item: Genre[];
  onMenuSelect();
}

export default function Menu(props: IMenuProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = item => {
    props.onMenuSelect(item);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { items } = props;
  const defaultValue = items && items.length > 0 ? items[0] : { title: '' };
  const { value = defaultValue } = props;
  return (
    <div className={classes.menuContainer}>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        className={classes.menuValue}
        disableRipple
      >
        {value.title}
        <SvgChevron
          style={{
            transform: open ? 'rotate(270deg)' : 'rotate(90deg)',
            margin: '0 4px',
          }}
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.popoverPaper,
        }}
      >
        <div className={`${classes.menuWrapper} ${props.classes.menuWrap}`}>
          {items.map((item, index) => (
            <MenuItem
              key={item.id}
              dense
              className={classes.menuItem}
              disableRipple
              onClick={() => onMenuItemClick(item)}
              selected={item.title === value.title}
            >
              {item.title}
            </MenuItem>
          ))}
        </div>
      </Popover>
    </div>
  );
}
