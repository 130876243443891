/*
 *
 * BrandVoice constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/BrandVoice/DEFAULT_ACTION',
  REQUEST_BRAND_VOICE = 'REQUEST_BRAND_VOICE',
  GET_BRAND_VOICE_SUCCESS = 'GET_BRAND_VOICE_SUCCESS',
  GET_BRAND_VOICE_FAILURE = 'GET_BRAND_VOICE_FAILURE',
  UPDATE_BRAND_VOICE = 'UPDATE_BRAND_VOICE',
}

export default ActionTypes;
