import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { StyledSpinnerNext } from 'baseui/spinner';
import { Avatar } from '@material-ui/core';
import { addPhoto, deletePhoto } from '../services/s3';
import request from '../shared/lib/request';
import { Field, ErrorMessage } from 'formik';

import { cloudfrontUrl, BUCKET_NAME, BUCKET_REGION } from '../utils/config';
import { ErrorText } from './Typography';

interface IBadgeImage {
  name: string;
  onChange: () => void;
  title: string;
  value: string;
  type: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageBox: {
      height: '252px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: '#12161A',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '17px',
    },
    imageBox1: {
      height: '206px',
      width: '252px',
      borderRadius: '6px',
      backgroundColor: '#12161A',
      display: 'flex',
      flexDirection: 'column',
      //alignItems: 'center',
      justifyContent: 'space-around',
      padding: '17px',
    },
    galleryDrop: {
      height: '142px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: ({ type }) => (type === 'milestones' ? '90px' : '180px'),
      height: ({ type }) => (type === 'milestones' ? '90px' : '180px'),
      borderRadius: '50%',
      textAlign: 'center',
      color: 'white',
    },
    uploadBtn: {
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      textAlign: 'center',
      backgroundColor: '#3c4042',
      textTransform: 'capitalize',
      borderRadius: '20px',
      padding: '0px 10px',
      cursor: 'pointer',
      width: 'max-content',
      marginTop: ({ type }) => (type === 'milestones' ? '0px' : '32px'),
    },
    inputHidden: {
      display: 'none',
    },
    imgTitle: {
      textTransform: 'capitalize',
      //textAlign: 'center',
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
      padding: '3px 19px',
      marginBottom: ({ type }) => (type === 'milestones' ? '13px' : '0px'),
    },
  }),
);

export const BadgeImage: React.FC<IBadgeImage> = ({
  title,
  name,
  onChange = () => {},
  defaultValue,
  type = 'special',
}) => {
  const classes = useStyles({ type });
  const [loader, setLoader] = React.useState(false);
  const [img, setImg] = React.useState('');

  const getBaseUrl = async e => {
    setLoader(true);
    const imgUrl = URL.createObjectURL(e.target.files[0]);

    let result = await addPhoto('achievement', e.target.files);

    let image = {
      imageUrl: result.Location.replace(
        'https://' + BUCKET_NAME + '.s3.' + BUCKET_REGION + '.amazonaws.com',
        cloudfrontUrl,
      ),
    };

    setImg(image.imageUrl);
    onChange(image.imageUrl);
    setLoader(false);
  };

  React.useEffect(() => {
    if (!img) {
      setImg(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className={classes.imageBox}>
      <div className={classes.image}>
        {loader ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              top: '40%',
            }}
          >
            <StyledSpinnerNext style={{ borderTopColor: 'black' }} />
          </div>
        ) : (
          <>
            <div className={classes.imgTitle}> {title}</div>
            <Avatar
              alt="img"
              id={`file_${name}`}
              src={img}
              className={classes.image}
            ></Avatar>
          </>
        )}
      </div>
      {loader ? (
        ''
      ) : (
        <label className={classes.uploadBtn}>
          <input
            accept="image/*"
            className={classes.inputHidden}
            id={`file_${name}`}
            type="file"
            onChange={e => getBaseUrl(e)}
          />
          Update Image
        </label>
      )}
    </div>
  );
};

const FormikBadgeImage = ({ name, defaultValue = '', title, type }) => {
  const handleChange = (imageValue, form) => {
    form.setFieldValue(name, imageValue);
    form.setFieldTouched(name, true, false);
  };

  return (
    <Field>
      {({ field, form, meta }) => {
        return (
          <>
            <BadgeImage
              name={name}
              title={title}
              defaultValue={defaultValue}
              onChange={image => handleChange(image, form)}
              type={type}
            />
          </>
        );
      }}
    </Field>
  );
};

export default FormikBadgeImage;
