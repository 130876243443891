import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectProgramDetail from './selectors';
import reducer from './reducer';
import saga from './saga';
import { isEmpty } from 'lodash';
import {
  requestProgramDetail,
} from './actions';
import {
  requestCollectionList,
  setTabChange,
  setSeachKey,
  getCollectionDetail,
  requestCollectionListPagination,
  resetCollection,
} from './actions';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { SvgPlay, SvgFilter } from '../../components/NeIcons';
import { useStyles } from './style';
import { ContainerState } from './types';
import Loader from '../../components/Loader';
import CollectionSlider from '../../components/CollectionSlider';
import { SliderImage } from '../../components/SliderImage';
import InstructorExpansionPanel from '../../components/InstructorExponentialComponent';
import { H3, H8, H6, H5 } from '../../styles/Typography';
import ClassCard from '../../components/ClassCard';
import NeMenu from '../../containers/ProgramDetail/Menu';
import DayCalenderBtn from '../../containers/ProgramDetail/DayCalenderBtn';
import makeSelectRoot from '../../containers/Root/selectors';
import Cookies from 'universal-cookie';
import ProgramHeader from './ProgramHeader';
import request from '../../shared/lib/request';
import {
  appApiURL
} from '../../utils/config';

const stateSelector = createStructuredSelector({
  programDetail: makeSelectProgramDetail(),
  root: makeSelectRoot(),
});

interface Props { }

export default function ProgramDetailPage(props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'programDetail', reducer: reducer });
  useInjectSaga({ key: 'programDetail', saga: saga });

  let weeks = [];

  const dispatch = useDispatch();
  const classes = useStyles();

  const [week, setWeek] = React.useState();
  const [activeElement, setActiveElement] = React.useState('day_1');
  const [collectionData, setCollections] = React.useState([]);
  const [data, setData] = React.useState({});

  const { programDetail = {} } = useSelector(stateSelector);
  const { data:getData } = programDetail;
  useEffect(() => {
    // Update the document title using the browser API
    if (props && props.match && props.location.pathname) {
      dispatch(requestProgramDetail(props.match.params.id));
    }
  }, [props.match]);

  useEffect(() => {
    async function getDetails() {
      let CollectionUrl = window.location.href.replace(window.location.origin + '/preview/collection', '');
      let { isPublished, isHidden } = getData && getData;
      let requestURL;
      if (isPublished) {
        requestURL = `${appApiURL}/neou/v1/collections?permaLink=${getData.permaLink}&classOffset=0&classLimit=20`;
      }else if (!isPublished && !isHidden) {
        requestURL = `${appApiURL}/neou/v1/collections?permaLink=${getData.permaLink}&classOffset=0&classLimit=20&isPublished=false&isHidden=false`
      }else{
        requestURL = `${appApiURL}/neou/v1/collections?permaLink=${getData.permaLink}&classOffset=0&classLimit=20&isPublished=false&isHidden=true`
      }
      let { result } = await request({
        url: requestURL,
        method: 'GET',
      });
      setData(result && result[0]);
      setCollections(result);
    }
    getDetails();
  },[getData && getData.permaLink]);

  if (!data) {
    return <Loader />;
  } 
  let {
    id = '',
    title = '',
    gallery = [],
    description = '',
    videos,
  } = data || {};
  let currentWeekDays = [];

  if (videos && videos.weeks) {
    weeks = Object.keys(videos.weeks).map((key, index) => ({
      id: index,
      key: key,
      title: key.replace('_', ' '),
    }));
    if (!week) setWeek(weeks.length > 0 && weeks[0]);
    else if (week.key && videos.weeks[week.key]) {
      currentWeekDays = Object.keys(videos.weeks[week.key]);
    }
  }

  //check if the user is logged in or not.
  const cookies = new Cookies();

  const onWeekChange = week => {
    setWeek(week);
    setTimeout(() => {
      refreshScroll();
    }, 100);
  };

  const onScrollEvent = e => {
    let child = document.querySelectorAll('#intersector > div');
    for (var i = 0; i < child.length; i++) {
      let limit = document.getElementById('daySelector').getBoundingClientRect()
        .bottom;
      let elemBottom = child[i].getBoundingClientRect().bottom;

      if (elemBottom - 35 > limit) {
        setActiveElement(child[i].id);
        break;
      }
    }
  };

  const refreshScroll = () => {
    var scrollElement = document.querySelector('#intersector');
    if (scrollElement) {
      scrollElement.scrollTop = 0;
      scrollElement.scrollTop = scrollElement.scrollTop + 1;
      scrollElement.scrollTop = scrollElement.scrollTop - 1;
    }
  };

  const moveToDailyClasses = day_count => {
    try {
      var elementTop = document.getElementById(`${day_count}`).offsetTop;
      var scrollContainer = document.querySelector('#intersector');

      var calenderSelector = document.querySelector('#daySelector');
      if (scrollContainer && calenderSelector) {
        var divTop = scrollContainer.offsetTop;
        scrollContainer.scrollTop = elementTop - divTop;
      }
      setTimeout(() => {
        setActiveElement(day_count);
      }, 1000);
    } catch (e) { }
  };
  let { gallery: vodGallery, videos: videoData, instructors } = collectionData && collectionData.length > 0 && collectionData[0];
  if (isEmpty(collectionData)) return <Loader />;
  return (
    <div
      style={{
        backgroundColor: '#12161A',
        minHeight: 'calc(100vh - 10px)',
        maxHeight: 'calc(100vh - 10px)',
        overflow: 'auto',
      }}
    >
      <div className={classes.readOnly} style={{ background: '#12161b' }}>
        <Helmet>
          <title>NEOU - {title || 'Program detail'}</title>
          <meta name="description" content="Description of ProgramDetail" />
        </Helmet>
        {!data && <Loader />}
        {data && (
          <Grid container direction="column">
            <Grid item xs={12} className={classes.conceptItem}>
              <div className={classes.headerWrap}>
                <ProgramHeader title={title} />
              </div>
            </Grid>

            {/* TODO: once the video is ready need to add button on top of the slider image */}
            <Grid className={classes.concept}>
              <Grid container className={classes.conceptBody}>
                <Grid item xs={12} md={6} className={classes.leftPanel}>
                  <div className={classes.slider}>
                    <CollectionSlider>
                      {vodGallery.map(item => {
                        if (item.type === 'image') {
                          return <SliderImage imgUrl={item.url} />;
                        }
                        return (
                          <div className={classes.videoThumbanil}>
                            <SliderImage imgUrl={item.thumbnail} />
                            <IconButton>
                              <SvgPlay />
                            </IconButton>
                          </div>
                        );
                      })}
                    </CollectionSlider>
                  </div>

                  <div className={classes.AboutConcept}>
                    {/* <InstructorExpansionPanel
                      head={
                        props.match && props.match.url.includes('interest')
                          ? 'About this Collection'
                          : props.match && props.match.url.includes('challenge') ? 'Challenge Overview' : `Program Overview`
                      }
                      body={description}
                      instructors={instructors}
                    /> */}
                    <InstructorExpansionPanel
                      head={
                        props.match && props.match.url.includes('interest')
                          ? 'About this Collection'
                          : props.match && props.match.url.includes('challenge') ? 'Challenge Overview' : `Program Overview`
                      }
                      body={description}
                      instructors={instructors}
                    />
                  </div>
                </Grid>

                {videoData && videoData.videoIds && videoData.videoIds.length > 0 ? (<Grid item xs={12} md={6} className={classes.rightPanel}>
                  <Grid
                    container
                    item
                    // justify={'space-between'}
                    alignItems={'center'}
                    direction="row"
                    className={classes.classHeadWrapper}
                  >
                    <H3 style={{ marginRight: '4px' }}>All Classes</H3>
                    <H8>{videoData.videoIds.length ? `(${videoData.videoIds.length})` : '(0)'}</H8>
                    {props.match && props.match.url.includes('interest') &&
                      <div
                        className={classes.filterWrapper}
                      >
                        <H6 className={classes.filterMargin}>Filter</H6>
                        <span>{<SvgFilter />}</span>
                      </div>}
                  </Grid>
                  <div className={classes.videoScrollWrap}>
                    {videoData &&
                      videoData.videoIds &&
                      videoData.videoIds.length > 0 &&
                      videoData.videoIds.map(item => {
                        return (
                          <ClassCard
                            key={item.id}
                            item={item}
                            seriesId={id}
                            title={title}
                          />
                        );
                      })}
                  </div>
                </Grid>) : (
                  <Grid item xs={12} md={6} className={classes.rightPanel}>
                    <div className={classes.weekSelector}>
                      <NeMenu
                        items={weeks}
                        value={week}
                        onMenuSelect={onWeekChange}
                        classes={{ menuWrap: classes.menuWrap }}
                      ></NeMenu>
                    </div>

                    <div className={classes.daySelector} id="daySelector">
                      {currentWeekDays.map((day, index) => (
                        <DayCalenderBtn
                          index={day.replace('day_', '')}
                          selected={activeElement === day}
                          onClick={() => moveToDailyClasses(day)}
                        ></DayCalenderBtn>
                      ))}
                    </div>

                    <div
                      className={classes.classesScroll}
                      id="intersector"
                      //onLoad={registerScrollEvent}
                    >
                      {combineEmpty(
                        currentWeekDays,
                        videos,
                        week,
                        classes,
                        setActiveElement,
                        title,
                      )}
                    </div>
                  </Grid>)
                }
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </div >
  );
}

const DailyClass = ({
  dayCount,
  videoDetails = [],
  classes,
  setActiveElement,
  active,
  title,
  collectionTitle,
}) => {
  const containerEl = React.useRef();
  const { programDetail = {} } = useSelector(stateSelector);

  const { data } = programDetail;
  return (
    <div
      id={dayCount}
      key={dayCount}
      ref={containerEl}
      style={{ background: active ? 'white' : 'transparent' }}
    >
      <div
        className={`${classes.dayTitleWrapper} ${videoDetails.length === 0 &&
          classes.restDayTitleWrapper}`}
      >
        <h2 className={classes.dayTitle}>{`${dayCount.replace('_', ' ')}`}</h2>
        {videoDetails.length === 0 && (
          <Typography className={classes.restDay}>Rest Day</Typography>
        )}
      </div>

      {videoDetails &&
        videoDetails.map((item, index) => (
          <ClassCard
            key={index}
            item={item}
            seriesId={data && data.id}
            title={title}
          // collectionTitle={collectionTitle}
          />
        ))}
    </div>
  );
};

function combineEmpty(
  currentWeekDays,
  videos,
  week,
  classes,
  setActiveElement,
  title,
) {
  let consDayEmptyFlag = 0;
  let newDayString = '';
  let emptyRender = currentWeekDays.map((dayCount, index) => {
    let videoDetails = videos.weeks[week.key][dayCount];
    let nextVideoLengthCheck =
      currentWeekDays.length - 1 !== index
        ? videos.weeks[week.key][currentWeekDays[index + 1]].length
        : 1;
    if (!videoDetails.length && !nextVideoLengthCheck) {
      if (!consDayEmptyFlag) {
        newDayString = dayCount.split('_')[1];
      }
      consDayEmptyFlag = 1;
      if (!videoDetails.length && nextVideoLengthCheck) {
        return (
          <DailyClass
            key={dayCount}
            dayCount={dayCount}
            videoDetails={videoDetails}
            classes={classes}
            setActiveElement={setActiveElement}
            title={title}
          />
        );
      }
    } else {
      if (consDayEmptyFlag) {
        newDayString += ' - ' + dayCount.split('_')[1];
        let passString = 'Day ' + newDayString;
        consDayEmptyFlag = 0;
        return (
          <DailyClass
            key={dayCount}
            dayCount={passString}
            videoDetails={videoDetails}
            classes={classes}
            setActiveElement={setActiveElement}
            title={title}
          />
        );
      } else {
        return (
          <DailyClass
            key={dayCount}
            dayCount={dayCount}
            videoDetails={videoDetails}
            classes={classes}
            setActiveElement={setActiveElement}
            title={title}
          />
        );
      }
    }
  });

  return emptyRender;
}


