import React, { useState } from 'react';
import SearchVideo from './SearchVideo';
import request from '../shared/lib/request';
import DraggableList from './DraggableList';
import { Divider } from '@material-ui/core';
import { getUniqItems } from '../utils/array';
import {
  ErrorText,
} from '../components/Typography';

interface VideoID {
  [key: string]: string;
}

export interface video {
  id: string;
  title: string;
  videoID: VideoID;
}

interface getSubTitleFunc {
  (): string;
}

interface SearchVideoComponentProps {
  videoList: video[];
  onVideoListChange: (newValue: video[], action: string) => void;
  id?: any;
  subTitle?: string | getSubTitleFunc;
  onChange?: (videoList) => void;
  errorMessage: any;
}

export const SearchVideoWithDnD: React.FC<SearchVideoComponentProps> = ({
  videoList: defaultValue,
  subTitle = () => { },
  onChange = () => { },
  errorMessage = () => { },
}) => {
  const [isSearchResult, setSearchVideo] = useState([]);
  const [videos, setVideos] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [inputRef, setInputRef] = useState(null);
  let timeout;

  const handleSearchInputChange = value => {
    timeout = setTimeout(() => {
      setSearchText('');
      if (inputRef) inputRef.value = '';
    }, 1000);
    if (value) {
      setSearchText(value.title);
      let uniqueList = getUniqItems([value, ...videos], 'id');
      setVideos(uniqueList);
      onChange([value, ...videos]);
    }
  };

  function searchVideos(data) {
    return request({
      url: `/neouadmin/v1/search`,
      method: 'POST',
      data: {
        keyword: data,
        isPublished: true,
        videoType: ['class', 'generic'],
      },
    });
  }
  const onSearchChange = async value => {
    try {
      let result = await searchVideos(value);
      setSearchVideo(result.result.classes.result);
    } catch (error) {
      setSearchVideo([]);
    }
  };

  React.useEffect(() => {
    setVideos(defaultValue);
    return () => {
      clearTimeout(timeout);
    };
  }, [defaultValue]);

  return (
    <div style={{ width: '100%' }}>
      <SearchVideo
        options={isSearchResult || []}
        name="searchVideos"
        inputValue={searchText}
        onChange={(event, value) => handleSearchInputChange(value)}
        limitTags={2}
        onInputChange={(event, value) => onSearchChange(value)}
        getOptionLabel={option => option.title}
        onSearchTextChange={e => setSearchText(e)}
        ref={inputRef}
        setInputRef={ref => setInputRef(ref)}
        blurOnSelect
        clearOnBlur
      />
      <ErrorText>
        <h2>{errorMessage()}</h2>
      </ErrorText>
      <Divider style={{ backgroundColor: '#999FA4', marginBottom: 8 }} />
      <DraggableList
        list={videos}
        onListChange={(list, updateType) => {
          setVideos(list);
          onChange(list);
        }}
        subTitle={subTitle}
      />
    </div>
  );
};
