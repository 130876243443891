import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const cookies = new Cookies();

  const fakeAuthCentralState = {
    isAuthenticated: !!cookies.get('adminToken'),
  };
  return (
    <Route
      {...rest}
      render={props =>
        Component && fakeAuthCentralState.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: fakeAuthCentralState.isAuthenticated
                ? '/neou/videos'
                : '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
