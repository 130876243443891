/*
 *
 * People
 *
 */

import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectPeople from './selectors';
import reducer from './reducer';
import saga from './saga';
import debounce from 'lodash.debounce';
import { Button, Checkbox, InputAdornment } from '@material-ui/core';
import AddDialog from './PeopleTable/AddPeopleDialog';
import AddPersonDialog from './PeopleTable/AddPersonDialog';
import {
  requestPeopleList,
  setTabChange,
  requestPeopleListPagination,
  setInteractivePeopleOnly,
  setSearchKey,
  resetPeople,
} from './actions';
import PeopleListTable from './PeopleTable/PeopleTable';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';
import { useStyles } from './styles';
import {
  SvgIconSearch,
  SvgIconAdd,
  SvgLoaderGif,
} from '../../components/NeIcons';
import SortSelect from './Select';
import Loader from '../../components/Loader';
import NEInput from '../../components/NEInput';
import { Helmet } from 'react-helmet';

const stateSelector = createStructuredSelector({
  people: makeSelectPeople(),
});

interface Props {}

function People(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'people', reducer: reducer });
  useInjectSaga({ key: 'people', saga: saga });

  const { people } = useSelector(stateSelector);
  let {
    peopleList,
    isListFetchingError,
    isLoading,
    total,
    isInstructorLoading,
    isInteractiveFilterOn: isInteractiveFilterOnState,
  } = people;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isAddPeopleOpen, openAddPeopleDialog] = React.useState(false);
  const [isAddPersonOpen, openAddPersonDialog] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [activeTab, setActiveTab] = React.useState(0);
  const [isInteractiveFilterOn, setIsInteractiveFilterOn] = React.useState(
    isInteractiveFilterOnState,
  );

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    if (tabIndex === 0) {
      dispatch(setTabChange('isHidden=false'));
      dispatch(requestPeopleList());
    } else if (tabIndex === 1) {
      dispatch(setTabChange('isPublished=false&isHidden=false'));
      dispatch(requestPeopleList());
    } else if (tabIndex === 2) {
      dispatch(setTabChange('isPublished=true'));
      dispatch(requestPeopleList());
    } else if (tabIndex === 3) {
      dispatch(setTabChange('isHidden=true&isPublished=false'));
      dispatch(requestPeopleList());
    }
    setActiveTab(tabIndex);
  };
  useEffect(() => {
    if (activeTab === 0) {
      dispatch(setTabChange('isHidden=false'));
    }
    // Update the document title using the browser API
    dispatch(requestPeopleList());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetPeople());
    };
  }, []);

  const tabConfig = [
    {
      label: 'ALL',
      component: <PeopleListTable />,
    },
    {
      label: 'DRAFT',
      component: <PeopleListTable />,
    },
    {
      label: 'PUBLISHED',
      component: <PeopleListTable />,
    },
    {
      label: 'HIDDEN',
      component: <PeopleListTable />,
    },
  ];

  const debouncedGet = useCallback(
    debounce(textValue => {
      onSearchClick(textValue);
    }, 1500),
    [], // will be created only once initially
  );

  const handleInput = e => {
    setSearchText(e.target.value);
    debouncedGet(e.target.value);
  };

  const onSearchClick = (text = '') => {
    dispatch(setSearchKey(text ? text : searchText));
    dispatch(requestPeopleList());
  };

  function loadMore() {
    if (peopleList && peopleList.length < total) {
      dispatch(requestPeopleListPagination(peopleList.length));
    }
  }

  function toggleInteractiveOnly() {
    const isInteractiveFilterOnState = !isInteractiveFilterOn;
    setIsInteractiveFilterOn(isInteractiveFilterOnState);
    dispatch(setInteractivePeopleOnly(isInteractiveFilterOnState));
    dispatch(requestPeopleList());
  }

  return (
    <>
      <Helmet>
        <title>Library People</title>
        <meta name="description" content="Neou Fitness People" />
      </Helmet>
      {isLoading && <Loader />}

      <div className={classes.root}>
        <div>
          <AddDialog
            isOpen={isAddPeopleOpen}
            title="ADD INSTRUCTOR"
            handleClose={() => {
              openAddPeopleDialog(false);
            }}
          ></AddDialog>
          <AddPersonDialog
            isOpen={isAddPersonOpen}
            title="ADD PERSON PROFILE"
            handleClose={() => {
              openAddPersonDialog(false);
            }}
          ></AddPersonDialog>
          <div className={classes.headWrap}>
            <div className={classes.head}>Library: People</div>
            <div className={classes.searchWrap}>
              <NEInput
                label="Search"
                variant="outlined"
                id="custom-css-outlined-input"
                fullWidth={true}
                onChange={e => handleInput(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={() => onSearchClick()}>
                        <SvgIconSearch />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.headWrap}>
            <div className={classes.addbtnWrap}>
              <Button
                onClick={() => {
                  openAddPeopleDialog(true);
                }}
                variant="contained"
                color="secondary"
                className={classes.addPeopleBtn}
                disableRipple={true}
              >
                <SvgIconAdd />{' '}
                <div className={classes.newPeople}>Instructor</div>
              </Button>
              <Button
                onClick={() => {
                  openAddPersonDialog(true);
                }}
                variant="contained"
                color="secondary"
                className={classes.addPeopleBtn}
                disableRipple={true}
              >
                <SvgIconAdd /> <div className={classes.newPeople}>Person</div>
              </Button>
            </div>
            <div className={classes.headWrap}>
              <Checkbox
                checked={isInteractiveFilterOn}
                onChange={toggleInteractiveOnly}
                name="checkedB"
                color="primary"
              />
              <div> Interactive Instructors Only </div>
            </div>
            <SortSelect />
          </div>

          <StyledTabs
            value={activeTab}
            onChange={onTabChange}
            aria-label="My account tabs"
            centered
            className={classes.styledTab}
          >
            {tabConfig.map((t, index) => (
              <StyledTab key={index} label={t.label} />
            ))}
          </StyledTabs>
          {isListFetchingError && (peopleList && peopleList.length === 0) ? (
            <div className={classes.noData}>No Data Found</div>
          ) : (
            <PeopleListTable
              list={peopleList}
              isLoading={isLoading}
              isInteractiveFilterOn={isInteractiveFilterOn}
            />
          )}
          {isInstructorLoading && (
            <div style={{ padding: '10px' }}>
              <SvgLoaderGif />
            </div>
          )}
          {!isInstructorLoading &&
            peopleList &&
            peopleList.length > 0 &&
            total !== peopleList.length && (
              <div className={classes.loadMoreBtn}>
                <Button color="secondary" onClick={() => loadMore()}>
                  Load More Instructors
                </Button>
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default People;
