import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the instructorMyClasses state domain
 */

const selectInstructorMyClassesDomain = (state: ApplicationRootState) => {
  return state.instructorMyClasses || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by InstructorMyClasses
 */

const makeSelectInstructorMyClasses = () =>
  createSelector(
    selectInstructorMyClassesDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectInstructorMyClasses;
export { selectInstructorMyClassesDomain };
