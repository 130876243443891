import React from 'react';
import {
  Drawer,
  Checkbox,
  Divider,
  Button,
  MenuItem,
  Typography,
  Grid,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import reducer from '../reducer';
import saga from '../saga';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useDispatch } from 'react-redux';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import CloseIcon from '@material-ui/icons/Close';
import ImageUploader from '../../../components/ImageUploader';
import { green } from '@material-ui/core/colors';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  // saveAttribute,
  setAttributeLoader,
  requestAttributes,
} from '../actions';
import { addPhoto } from '../../../services/s3';
import {
  cloudfrontUrl,
  BUCKET_NAME,
  BUCKET_REGION,
} from '../../../utils/config';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { openSnackBar } from '../../Root/actions';
import request from '../../../shared/lib/request';

export default function AddAttributeDialog({
  classes,
  isOpen,
  closeAddDialog,
}) {
  useInjectReducer({ key: 'attribute', reducer: reducer });
  useInjectSaga({ key: 'attribute', saga: saga });
  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const types = [
    'genre',
    'equipment',
    'style',
    'bodyFocus',
    'streamType',
    'music',
    'impact',
    'duration',
    'difficulty',
    'category',
    'segment',
    'language',
  ];
  const [state, setState] = React.useState({
    right: true,
    // left: false,
    isLoading: true,
    type: '',
    isVisible: false,
    title: '',
    imageUrl: { png: '' },
    isTitleError: false,
    titleError: '',
    isPublished: false,
  });
  const toggleDrawer = (anchor, open) => event => {
    setState({ ...state, [anchor]: open, isLoading: open });
  };
  const handleChange = event => {
    if (!event.target.value) {
      setState({
        ...state,
        title: event.target.value,
        isTitleError: true,
        titleError: 'Title is required',
      });
    } else {
      setState({
        ...state,
        title: event.target.value,
        isTitleError: false,
        titleError: '',
      });
    }
  };

  const handleChange1 = event => {
    setState({ ...state, type: event.target.value });
  };
  const handleChange2 = event => {
    setState({ ...state, isVisible: !state.isVisible });
  };
  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);
  const onHandleSubmit = event => {
    let updateData = {
      title: state.title,
      isVisible: state.isVisible,
      isPublished: false,
      type: state.type,
      isAdd: true,
    };
    if (state.type == 'equipment') {
      updateData['imageUrl'] = { png: state.imageUrl.png } || { png: '' };
    }
    if (!state.title) {
      dispatch(openSnackBar('Title field is required'));
    } else if (!state.type) {
      dispatch(openSnackBar('Type field is required'));
    } else if (
      state.type == 'equipment' &&
      (!state.imageUrl || !state.imageUrl.png)
    ) {
      dispatch(openSnackBar('Image is required for equipment type'));
    } else {
      setState({ ...state, right: false });
      // dispatch(saveAttribute(updateData));
      saveAttribute(updateData);
    }
  };
  const onPublishSubmit = event => {
    let updateData = {
      title: state.title,
      isVisible: state.isVisible,
      isPublished: true,
      type: state.type,
      isAdd: true,
    };
    if (state.type == 'equipment') {
      updateData['imageUrl'] = { png: state.imageUrl.png } || { png: '' };
    }
    if (!state.title) {
      dispatch(openSnackBar('Title field is required'));
    } else if (!state.type) {
      dispatch(openSnackBar('Type field is required'));
    } else if (
      state.type == 'equipment' &&
      (!state.imageUrl || !state.imageUrl.png)
    ) {
      dispatch(openSnackBar('Image is required for equipment type'));
    } else {
      setState({ ...state, right: false });
      // dispatch(saveAttribute(updateData));
      saveAttribute(updateData);
    }
  };

  const saveAttribute = async data => {
    try {
      dispatch(setAttributeLoader(true));

      const { result } = await request({
        url: `/neouadmin/v1/attributes`,
        method: 'POST',
        data: data,
      });

      dispatch(setAttributeLoader(false));
      closeAddDialog();
      dispatch(requestAttributes());
    } catch ({ data }) {
      dispatch(setAttributeLoader(false));
      dispatch(openSnackBar(data.error || data.message));
    }
  };
  const imageChange = async event => {
    try {
      let files = event.target.files;
      if (files && files.length > 0) {
        let result = await addPhoto('equipments', event.target.files);
        setState({
          ...state,
          imageUrl: {
            png: result.Location.replace(
              'https://' +
                BUCKET_NAME +
                '.s3.' +
                BUCKET_REGION +
                '.amazonaws.com',
              cloudfrontUrl,
            ),
          },
        });
      }
    } catch (error) {}
  };

  return (
    <div>
      {/* {['right'].map(anchor => ( */}
      <React.Fragment>
        <Drawer
          anchor={'right'}
          disableBackdropClick={true}
          open={isOpen}
          onClose={() => showAlertToClose(true)}
        >
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the attribute."
            onAgree={closeAddDialog}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>

          <div className={classes.rightContainer}>
            <div className={classes.titleDiv}>
              <div className={classes.title}>ADD ATTRIBUTE</div>
              <Button
                onClick={() => showAlertToClose(true)}
                className={classes.closeBtn}
              >
                <CloseIcon />
              </Button>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.titleWrapper}>
              <NEInput
                id="attributeTitle"
                name="title"
                label="Tag Name"
                defaultValue={state.title}
                onChange={e => handleChange(e)}
                fullWidth
                variant="outlined"
                error={state.isTitleError}
                helperText={state.titleError}
              />
            </div>
            <div className={classes.secondRow}>
              <NEInput
                id="attributeType"
                name="type"
                className={classes.typeInput}
                select
                label="Tag Type"
                defaultValue={state.type}
                onChange={e => handleChange1(e)}
                variant="outlined"
              >
                {types &&
                  types.map(t => (
                    <MenuItem value={t} key={t}>
                      {t}
                    </MenuItem>
                  ))}
              </NEInput>
              <FormControlLabel
                value="top"
                control={
                  <GreenCheckbox
                    checked={state.isVisible}
                    onChange={e => handleChange2(e)}
                  />
                }
                label="Visible"
                labelPlacement="start"
              />
            </div>
            {state.type === 'equipment' && (
              <div>
                <Typography variant="subtitle1" align="center">
                  Manage Images
                </Typography>
                <ImageUploader
                  title=""
                  aspectRatio="3x3"
                  imgSrc={state.imageUrl['png']}
                  value={state.imageUrl['png']}
                  handleChange={imageChange}
                  className={classes.imgContainer}
                />
              </div>
            )}
            <Grid>
              <div className={classes.btnContainer}>
                <Button
                  onClick={event => {
                    onHandleSubmit(event);
                  }}
                  className={classes.newbtn}
                  disableRipple={true}
                >
                  <div className={classes.saveLegalBtn}>SAVE</div>
                </Button>
                <Button
                  onClick={event => {
                    onPublishSubmit(event);
                  }}
                >
                  <div className={classes.saveLegalBtn1}>PUBLISH</div>
                </Button>
              </div>
            </Grid>
          </div>
        </Drawer>
      </React.Fragment>
      {/* ))} */}
    </div>
  );
}
