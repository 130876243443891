import * as React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import styled from 'styled-components';
import logo from '../../images/neou@2x.png';
import { login, reset, storeResetPassEmail } from './action';
import { encrypt } from '../../utils/crypto';
import { KEY_BASE64 } from '../../utils/config';
import PasswordField from '../../components/PasswordField';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useStyles } from './resetPasswordStyle';
import reducer from './reducer';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import NEInput from '../../components/NEInput';
import { emailSchema } from '../../validators/Authentication';
import { formError, customMessage } from '../../utils/validator';

export interface IResetPasswordProps {}

const Image = styled.img`
  color: #ffffff;
`;

export default function ResetPassword(props: IResetPasswordProps) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'authentication', reducer: reducer });
  useInjectSaga({ key: 'authentication', saga: saga });
  const dispatch = useDispatch();
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState({ email: '' });
  const [emailErrorMsg, setEmailerrorMsg] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passErrorMsg, setPasserrorMsg] = React.useState('');
  const [values, setValues] = React.useState<State>({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleInput = e => {
    setEmail(e.target.value);
  };

  const validateForm = () => {
    const { error, value } = emailSchema.validate(
      { email },
      {
        abortEarly: false,
        messages: customMessage,
      },
    );
    return formError(error);
  };

  const onReset = () => {
    let e = validateForm();
    setError(e);
    if (Object.keys(e).length === 0) {
      dispatch(storeResetPassEmail(email));
      dispatch(reset(email));
    }
  };

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={4} className={classes.root}>
          <div className={classes.imgWrapper}>
            <Image src={logo} className={classes.img} />
            <div className={classes.admin}>Admin</div>
          </div>
          <div className={classes.description}>
            We'll email you a link to <br /> reset your password.
          </div>
          <NEInput
            label="Email Address"
            variant="outlined"
            id="custom-css-outlined-input"
            fullWidth={true}
            onChange={e => handleInput(e)}
            error={!!error.email}
            helperText={error['email']}
            className={classes.emailField}
          />
          <Button
            color="primary"
            className={classes.signinBtn}
            onClick={() => onReset()}
          >
            SEND ME A LINK
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
