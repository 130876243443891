/*
 *
 * InteractivePublishedClasses reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  videoList: [],
  calenderList: [],
  sortBy: 'startDate=desc',
  activeTab: 'isUpcoming=true&isPublished=true&isHidden=false',
  isListFetchingError: false,
  isLoading: false,
  filterData: [],
  searchResults: [],
  searchSuggestion: [],
  searchResultsCount: 0,
  durationDetails: [],
  videoTotalCount: 0,
  addVideoDialog: false,
  classDetailData: null,
  isClassLoading: false,
  classPaginationList: [],
  total: 0,
  clients: [],
  clientsJoined: [],
};

function interactivePublishedClassesReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_MY_CLIENT:
      return { ...state };
    case ActionTypes.STORE_CLIENTS:
      return { ...state, clients: action.payload };
    case ActionTypes.CLASS_LIST_TOTAL:
      return {
        ...state,
        total: action.payload,
        isLoading: false,
      };
    case ActionTypes.CLASS_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        classPaginationList: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.PUT_CLASS_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        isListFetchingError: false,
        videoList: [...state.videoList, ...state.classPaginationList],
        isLoading: false,
        isClassLoading: false,
      };
    case ActionTypes.UPDATE_SEARCH_COUNT:
      return {
        ...state,
        videoList: [],
        searchResultsCount: 0,
      };
    case ActionTypes.FILTER_UPDATE_VIDEO_LIST:
      return {
        ...state,
        videoList: state.searchResults ? [...state.searchResults] : [],
        searchResults: [],
        videoTotalCount: state.searchResultsCount,
        // searchResultsCount: 0,
      };
    case ActionTypes.SET_CLASS_PAGINATION_LOADER:
      return { ...state, isClassLoading: action.payload };
    case ActionTypes.REQUEST_INTERACTIVE_VIDEO_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.REQUEST_INTERACTIVE_CALENDER_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.VIDEO_LIST_SUCCESS:
      return {
        ...state,
        videoList: [...action.payload],
        isLoading: false,
        searchResultsCount: 0,
        isListFetchingError: false,
      };
    case ActionTypes.UPCOMING_CALENDER_LIST_SUCCESS:
      return {
        ...state,
        calenderList: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.VIDEO_LIST_TOTAL:
      return {
        ...state,
        videoTotalCount: action.payload,
        isLoading: false,
        searchResultsCount: 0,
      };
    case ActionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case ActionTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case ActionTypes.VIDEO_LIST_FAILED:
      return { ...state, isListFetchingError: true, isLoading: false };
    case ActionTypes.REQUEST_FILTERS:
      return {
        ...state,
        // isSearchResultsFetching: false,
        searchResults: [],
      };
    case ActionTypes.FILTERS_FETCH_SUCCESS:
      let data = action.payload;
      return {
        ...state,
        // isFilterFetching: true,
        filterData: action.payload,
        searchResults: [],
        // isSearchResultsFetching: false,
      };
    case ActionTypes.STORE_CLASS_DETAIL_PREVIEW:
      return {
        ...state,
        classDetailData: action.payload,
      };
    case ActionTypes.VIDEO_LIST_SUCCESS_WITH_FILTERS_INTERACTIVE:
      let { result, total } = action.payload;
      return {
        ...state,
        searchResults: result ? result : [],
        videoList: result ? result : state.videoList,
        searchResultsCount: total ? total : 0,
        isLoading: false,
      };
    case ActionTypes.STORE_JOINED_CLIENTS_LIST:
      return {
        ...state,
        clientsJoined: action.payload,
      };
    default:
      return state;
  }
}

export default interactivePublishedClassesReducer;
