/*
 *
 * CurateGenre reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  isLoading: false,
  browseGenreList: [],
};

function curateGenreReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.REQUEST_BROWSE_GENRE_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.STORE_BROWSE_GENRE_LIST:
      return { ...state, browseGenreList: action.payload, isLoading: false };
    case ActionTypes.SET_LOADER:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export default curateGenreReducer;
