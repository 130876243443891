import { ThemeOptions } from '@material-ui/core/styles/createTheme';

const themeOptions: ThemeOptions = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 320, md: 768, lg: 1024, xl: 1580 },
  },
  overrides: {
    MuiDialogActions: {
      root: {
        '& .MuiButton-textPrimary': {
          background: 'rgba(0,0,0,0.12)',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#12161A',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontSize: '3rem',
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        '& p': {
          fontSize: '0.7rem',
        },
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        border: '14px solid #12161A',
      },
      pointer: {
        backgroundColor: '#12161A',
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: '#12161A',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#12161A',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: 'Proxima-Nova-Regular',
      },
      subtitle1: {
        fontFamily: 'Proxima-Nova-Regular',
      },
      h6: {
        fontFamily: 'ProximaNova-Bold',
        fontSize: '16px',
        lineHeight: '20px',
        textTransform: 'uppercase',
      },
      body2: {
        fontSize: '13px',
        lineHeight: '20px',
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
      },
    },
    MuiLinearProgress: {
      root: {
        height: 4,
        backgroundColor: '#12161A',
        borderRadius: '3px',
        width: '100%',
      },
      barColorPrimary: {
        backgroundColor: '#909599',
        borderRadius: '3px',
      },
      colorPrimary: {
        backgroundColor: '#12161A',
      },
    },
    MuiButton: {
      root: {
        '&$disabled': {
          opacity: '0.3',
          cursor: 'pointer',
        },
      },
      textSizeLarge: {
        minWidth: '240px',
      },
      containedSizeLarge: {
        minWidth: '240px',
      },
      textPrimary: {
        borderRadius: '6px',
        minWidth: '140px',
        minHeight: '40px',
        background: 'linear-gradient(135deg, #2ADCFE 0%, #56FFFE 100%)',
        color: '#12161A',
        fontFamily: 'ProximaNova-Bold',
        fontSize: '15px',
        lineHeight: '20px',
        padding: '0 24px',
        '&:hover, &:focus': {
          background: '#56FFFE',
        },
      },
      textSecondary: {
        borderRadius: '6px',
        fontFamily: 'ProximaNova-Bold',
        minWidth: '140px',
        minHeight: '40px',
        fontSize: '15px',
        lineHeight: '24px',
        backgroundColor: '#D4D8DC',
        padding: '0 24px',
        letterSpacing: '1.15px',
        color: '#12161A',
        textTransform: 'uppercase',
        margin: '0px 4px',
        '&:hover, &:focus': {
          background: '#D4D8DC',
        },
      },
      containedSecondary: {
        borderRadius: '6px',
        fontFamily: 'ProximaNova-Bold',
        minWidth: '140px',
        minHeight: '40px',
        fontSize: '15px',
        lineHeight: '24px',
        backgroundColor: '#12161A',
        padding: '0 24px',
        letterSpacing: '1.15px',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        '&:hover, &:focus': {
          background: '#12161A',
        },
      },
      containedPrimary: {
        borderRadius: '6px',
        fontFamily: 'ProximaNova-Bold',
        minWidth: '140px',
        minHeight: '40px',
        fontSize: '15px',
        lineHeight: '24px',
        backgroundColor: '#D0021B',
        padding: '0 24px',
        letterSpacing: '1.15px',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        '&:hover, &:focus': {
          background: '#D0021B',
        },
      },
      outlined: {
        border: '1px solid #E3E3E4',
        minHeight: '40px',
        '& .MuiButton-label': {
          color: '#5AA700',
          fontFamily: 'ProximaNova-Medium',
          textTransform: 'none',
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
    },
    MuiSwitch: {
      switchBase: {},
      colorPrimary: {
        color: '#fff',
        '&$checked': {
          color: '#86b660',
        },
        '&$checked + $track': {
          backgroundColor: '#86b660',
        },
      },
    },
  },
};

export default themeOptions;
