/**
 *
 * SideNav
 *
 */
import React from 'react';
import {
  Hidden,
  Drawer,
  makeStyles,
  List,
  Icon,
  ListItem,
  ListItemText,
  ButtonBase,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import logo from '../../images/neou@2x.png';
import interactiveLogo from '../../images/NEOUs.png';
import SideNavStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import SideMenu from './SideMenu';
import Avatar from '@material-ui/core/Avatar';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from '../../containers/Authentication/reducer';
import saga from '../../containers/Authentication/saga';
import { useInjectSaga } from '../../utils/injectSaga';
import makeSelectAuthentication from '../../containers/Authentication/selectors';
import { ContainerState as AuthenticationState } from '../../containers/Authentication/types';
import {
  removeUserInfo,
  requestUserInfo,
} from '../../containers/Authentication/action';
import { push } from 'connected-react-router';
import ProfileAvatar from '../CommonFilter/ProfileAvatar';

const useStyles = makeStyles(SideNavStyles);

interface Props {
  open?: boolean;
  handleDrawerToggle?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown'): void;
  }['bivarianceHack'];
  color: string;
  routes?: array;
  externalRoutes?: array;
  history?: any;
  signOutRoute();
}

const stateSelector = createStructuredSelector({
  authentication: makeSelectAuthentication(),
});

const SideNavLink = props => {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  let listItemClasses;
  listItemClasses = classNames({
    [' ' + classes.activeRouteText]: activeRoute(props.path),
  });
  const whiteFontClasses = classNames({
    [' ' + classes.activeRouteText]: activeRoute(props.path),
  });

  return (
    <NavLink
      to={props.path || ''}
      onClick={props.onClick}
      className={classes.item}
      activeClassName="active"
    >
      <ListItem button className={classes.itemLink + listItemClasses}>
        {typeof props.icon === 'string' ? (
          <Icon className={classNames(classes.itemIcon, whiteFontClasses)}>
            {props.icon}
          </Icon>
        ) : (
          <props.icon
            className={classNames(classes.itemIcon, whiteFontClasses)}
          />
        )}

        <ListItemText
          primary={props.name}
          className={classNames(classes.itemText, whiteFontClasses)}
          disableTypography={true}
        />
      </ListItem>
    </NavLink>
  );
};

function SideNav(props: Props) {
  const { routes, externalRoutes, signOutRoute, history } = props;
  useInjectReducer({ key: 'authentication', reducer: reducer });
  useInjectSaga({ key: 'authentication', saga: saga });
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    authentication,
  }: { authentication: AuthenticationState } = useSelector(stateSelector);
  const { userInfo, isUserInfoFetching, resetEmail } = authentication;

  React.useEffect(() => {
    const cookies = new Cookies();

    let isAuthenticated = !!cookies.get('adminToken');
    if (isAuthenticated) {
      dispatch(requestUserInfo());
    }
  }, []);

  const onSignOut = e => {
    e.preventDefault();
    const cookies = new Cookies();

    cookies.remove('adminToken', {
      domain: window.location.hostname,
    });
    cookies.remove('adminToken');
    dispatch(removeUserInfo());
    dispatch(push('/signin'));
    window.location.reload();
  };

  const brand = (
    <div className={userInfo.role === 'instructor' ? classes.InstBrand : classes.brand}>
      <img src={userInfo && userInfo.role === 'instructor' ? interactiveLogo : logo} alt="neou logo" />
    </div>
  );
  
  function getUrl(label){
    return window.location.href.indexOf(label) > -1 ? true : false;
  }

  const UserAvatar = (
    <>
      {userInfo.role === 'instructor' ? 
      (<div className={classes.brandSmall}>
         <ButtonBase style={{display: 'block', margin:'auto'}} onClick={() => {
          history.push('/neou/my-account');
        }}>
          <ProfileAvatar name={userInfo.name} uri={''} /><br />
          <Typography
            style={{
              color: getUrl('/neou/my-account')
                ? '#56FFFE'
                : '#8B949B',
                marginLeft: '0px',
            }}
            className={classes.profileSmallText}
          >
            My Account 
          </Typography>
        </ButtonBase>
      </div>) :
      (<div className={classes.userAvatarWrapper}>
        <div className={classes.userAvatar}>
          <Avatar
            alt={userInfo.email}
            src="/static/images/avatar/1.jpg"
            classes={{
              root: classes.userAvatarRoot,
            }}
          ></Avatar>
          <Typography className={classes.userAvatarName}>
            {userInfo.email}
          </Typography>
        </div>
        <ButtonBase className={classes.signOutText} onClick={onSignOut}>
          Sign Out
        </ButtonBase>
      </div>)}
    </>
  );

  return (
    <div>
      {/* Hide mobile menu below 1024px */}
      <Hidden lgUp implementation="css">
        <Drawer
          variant="persistent"
          anchor="left"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          {UserAvatar}
          <div className={classes.sidebarWrapper}>
            {/* {links} {externalLinks}
            {isLoggedIn && signOutLink} */}
            <SideMenu links={routes} userInfo={userInfo} />
          </div>
          {userInfo.role === 'instructor' && (<ButtonBase className={classes.InstSignOutText} onClick={onSignOut}>
            Sign Out
          </ButtonBase>)}
        </Drawer>
      </Hidden>

      {/* Hide Sidebar menu below 1024px */}
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          {UserAvatar}
          <div className={classes.sidebarWrapper}>
            {userInfo.role !== undefined && (
              <SideMenu links={routes} userInfo={userInfo} />
            )}
            { userInfo.role === 'instructor' && (
            <ButtonBase className={classes.InstSignOutText} onClick={onSignOut}>
              Sign Out
            </ButtonBase>)}
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
}

export default SideNav;
export { SideNavLink };
