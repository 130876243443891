import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    padding: '0px 0px 16px',
    fontFamily: 'Proxima-Nova-Regular',
  },
  head: {
    color: '#12161A',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '26px',
    fontWeight: 500,
    lineHeight: '32px',
  },
  headWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '34px 0px',
    borderBottom: '1px solid #000000',
  },
  container: {
    maxHeight: '100%',
  },
  box: {
    height: '64px',
    width: '64px',
    borderRadius: '6px',
    backgroundColor: '#D4D8DC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '18px',
      height: '24px',
    },
  },
  firstColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'inherit',
  },
  firstCoulmContent: {
    textAlign: 'start',
    paddingLeft: '16px',
  },
  noData: {
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '20px',
    color: '#909599',
    padding: '20px',
  },
  thirdColumn: {
    height: '20px',
    width: '70px',
    color: '#3E4142',
    fontFamily: 'ProximaNova-Bold',
    fontSize: '8px',
    fontWeight: 'bold',
    letterSpacing: '0.57px',
    lineHeight: '10px',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  draft: {
    boxSizing: 'border-box',
    border: '1px solid #E8EBED',
    backgroundColor: '#E8EBED',
  },
  published: {
    boxSizing: 'border-box',
    border: '1px solid #E8EBED',
  },
  conceptTitle: {
    color: '#12161A',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '17px',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  text: {
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '20px',
    color: '#909599',
  },
  editIcon: {
    width: '36px',
    height: '36px',
    color: '#000000',
    padding: '6px',
  },
});
