import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useInjectReducer } from '../../../utils/injectReducer';
import makeSelectAchievement from '../selectors';
import reducer from '../reducer';
import saga from '../saga';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import makeSelectHomePageTray from '../selectors';
import { serSortBy, requestHomePageList } from '../actions';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    display: 'inline',
    fontFamily: 'Proxima-Nova-Regular',
    '& .MuiSelect-root': {
      boxSizing: 'border-box',
      fontFamily: 'Proxima-Nova-Regular',
      width: '241px',
      border: '1px solid #E3E3E4',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
      padding: '0',
      paddingLeft: '8px',
      height: '38px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      borderLeft: '1px solid #E3E3E4',
    },
    '& .MuiFilledInput-root': {
      marginLeft: '25px',
    },
    '& .MuiFilledInput-underline:before': {
      content: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
  },
  menuItem: {
    fontFamily: 'Proxima-Nova-Regular',
  },
}));

const stateSelector = createStructuredSelector({
  homePageTray: makeSelectHomePageTray(),
});

export default function SortSelect(props) {
  const classes = useStyles();
  let { segments, sort, onChange } = props;
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'homePageTray', reducer: reducer });
  useInjectSaga({ key: 'homePageTray', saga: saga });

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        Segment:
        <Select value={sort ? sort : 'No Fitness Profile'} onChange={onChange}>
          {segments &&
            segments.length > 0 &&
            segments.map((item, index) => (
              <MenuItem value={item.title} classes={{ root: classes.menuItem }}>
                {item.title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
