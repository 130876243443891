/*
 *
 * Attributes reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  attributeList: [],
  sortBy: 'updatedAt=desc',
  isListFetchingError: false,
  isLoading: false,
  attributeDetailInfo: {},
};

function attributeReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_ATTRIBUTE_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.ATTRIBUTE_LIST_SUCCESS:
      return {
        ...state,
        attributeList: [...action.payload],
        isLoading: false,
      };

    case ActionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case ActionTypes.ATTRIBUTE_LIST_FAILED:
      return { ...state, isListFetchingError: true };
    case ActionTypes.SAVE_ATTRIBUTE_DETAIL:
      return {
        ...state,
        attributeDetailInfo: action.payload[0],
      };
    case ActionTypes.SET_ATTRIBUTE_LOADER:
      return { ...state, isLoading: action.payload };
    case ActionTypes.FETCH_ATTRIBUTE_BY_ID:
      return {
        ...state,
        attributeDetailInfo: action.payload[0],
        isLoading: true,
      };
    case ActionTypes.FETCH_ATTRIBUTE_BY_ID_SUCCESS:
      return {
        ...state,
        attributeDetailInfo: action.payload[0],
        isLoading: false,
      };
    default:
      return state;
  }
}

export default attributeReducer;
