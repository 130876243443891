/*
 *
 * InstructorMyClasses
 *
 */

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectInstructorMyClasses from './selectors';
import reducer from './reducer';
//import saga from './saga';
import instructorMyClassesSaga from './saga';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import CalenderBtn from '../../components/CalenderBtnClasses';
import { generateDays } from '../../utils/calender';
import { result } from './stub';
import { requestMyClasses } from './actions';
import { Grid } from '@material-ui/core';
import { ClassCalenderList } from '../../components/ClassCalenderList';
import makeSelectAuthentication from '../Authentication/selectors';
import Loader from '../../components/Loader';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '24px 16px 0px',
      margin: 'auto',
      maxWidth: '1200px',
    },
    headWrap: {
      display: 'grid',
      gridTemplateColumns: 'minmax(80px, 16%) 1fr minmax(80px, 16%)',
      justifyItems: 'center',
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'minmax(150px, 16%) 1fr minmax(150px, 16%)',
      },
    },
    dateText: {
      color: '#161D22',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '15px',
      lineHeight: '18px',
    },
    head: {
      color: '#12161A',
      fontFamily: 'TurbinadoDryRegular',
      fontSize: '45px',
      letterSpacing: 0,
      lineHeight: '56px',
      textAlign: 'center',
    },
    daySelector: {
      display: 'grid',
      gridTemplateColumns: 'repeat(14, minmax(36px, 1fr))',
      overflow: 'auto',
      gridGap: '6px',
      padding: '16px 8px',
      maxWidth: '1200px',
      margin: 'auto',
      '& .MuiButtonBase-root': {
        margin: '0px',
        padding: '0px',
        background: '#fff',
        boxShadow: '0 0 2px 0 rgba(0,0,0,0.66)',
        minWidth: '100%',
      },
    },
    interSector: {
      maxHeight: 'calc(100vh - 196px)',
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      overflow: 'auto',
    },
  }),
);

const stateSelector = createStructuredSelector({
  instructorMyClasses: makeSelectInstructorMyClasses(),
  authentication: makeSelectAuthentication(),
});

interface Props {}

function InstructorMyClasses(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'instructorMyClasses', reducer: reducer });
  useInjectSaga({ key: 'instructorMyClasses', saga: instructorMyClassesSaga });
  const styles = useStyles();
  const { instructorMyClasses, authentication } = useSelector(stateSelector);
  const {
    classes,
    weeks,
    activeWeekInfo,
    classCount,
    isLoading,
  } = instructorMyClasses;
  const { userInfo } = authentication;
  const dispatch = useDispatch();
  const [activeElementId, setActiveElementId] = useState<string | null>(null);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const dateCalc = () => {
    return weeks.map((week, key) =>
      Object.keys(classes[week]).map((date, index) => {
        if (index === 0) {
          let m = moment(new Date(date));
          setStartDate(m.format('MMMM D'));
        } else if (index === 13) {
          let m = moment(new Date(date));
          setEndDate(m.format('MMM D'));
        }
      }),
    );
  };

  useEffect(() => {
    setActiveElementId(moment().format('YYYY-MM-DD'));
    //change this to instrId once api is ready

    dispatch(
      requestMyClasses(
        userInfo && userInfo.interactive && userInfo.interactive.instrId,
      ),
    );
    dateCalc();
  }, []);

  useEffect(() => {
    function onScrollEvent(e, w) {
      let scrollElement = e.target;
      if (scrollElement) {
        if (
          scrollElement.scrollTop + scrollElement.clientHeight ===
          scrollElement.scrollHeight
        ) {
        }
      }
      let child = document.querySelectorAll('#intersector > div > div');
      for (var i = 0; i < child.length; i++) {
        let calenderSelector = document.getElementById('calenderSelector');
        if (calenderSelector) {
          let limit = calenderSelector.getBoundingClientRect().bottom;
          let elemBottom = child[i].getBoundingClientRect().bottom;
          if (elemBottom - 30 > limit) {
            setActiveElementId(child[i].id);
            break;
          }
        }
      }
    }

    let mainPanel = document.getElementById('intersector');
    if (mainPanel) {
      mainPanel.addEventListener('scroll', e => onScrollEvent(e, weeks));
      return () => {
        mainPanel.removeEventListener('scroll', e => onScrollEvent(e, weeks));
      };
    }
  }, [dispatch, weeks]);

  const moveToDailyClasses = day_count => {
    try {
      const elementTop = document.getElementById(`${day_count}`); //.offsetTop;

      if (elementTop) {
        elementTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      setTimeout(() => {
        setActiveElementId(day_count);
      }, 600);
    } catch (e) {}
  };

  return (
    <div>
      {isLoading && <Loader />}
      <Helmet>
        <title>InstructorMyClasses</title>
        <meta name="description" content="Description of InstructorMyClasses" />
      </Helmet>
      <div className={styles.root}>
        <div className={styles.headWrap}>
          <div className={styles.dateText}>
            {startDate} - {endDate}
          </div>
          <div className={styles.head}>My Classes</div>
          <div className={styles.dateText}>{classCount} CLASSES</div>
        </div>

        <Grid container>
          <Grid
            item
            xs={12}
            // className={styles.sliderBottom}
            id="calenderSelector"
          >
            {weeks &&
              weeks.map((week, key) => (
                <div key={key}>
                  <div className={styles.daySelector}>
                    {classes &&
                      Object.keys(classes[week]).map((date, index) => {
                        return (
                          <CalenderBtn
                            key={index}
                            dayText={date}
                            selected={activeElementId === date}
                            onClick={() => moveToDailyClasses(date)}
                          />
                        );
                      })}
                  </div>
                </div>
              ))}
          </Grid>

          <Grid item xs={12}>
            <div id="intersector" className={styles.interSector}>
              {activeWeekInfo && activeElementId && (
                <ClassCalenderList
                  classItems={activeWeekInfo}
                  Selected={activeElementId}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default InstructorMyClasses;
