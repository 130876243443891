import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '50px 20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    head: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    headWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px',
      borderBottom: '1px solid #000000',
    },
  }),
);
