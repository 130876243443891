/*
 *
 * Achievement reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  achievementList: [],
  programChallenge: [],
  genres: [],
  sortBy: 'updatedAt=desc',
  activeTab: '',
  isListFetchingError: false,
  isLoading: false,
  specialtyValues: null,
  flagValues: null,
  achievementDetail: null,
  relatedClasses: null,
  //add instructor
  aFlag: null,
  aSpecialty: null,
  isAchievementLoading: false,
  achievementDetailInfo: {
    isLoading: false,
    flag: [],
    instructors: [],
    genres: [],
    categories: [],
    data: {
      categories: [],
      collectionType: '',
      createdAt: new Date(),
      description: '',
      flag: '',
      gallery: [],
      genres: [],
      graphics: {
        '16x9': '',
        '16x9_trans': '',
        '1x1': '',
        '32x9': '',
        '9x16': '',
      },
      id: '',
      instructors: [],
      isHidden: false,
      isPublished: false,
      kicker: '',
      permaLink: '',
      publishedDate: new Date(),
      seriesID: '',
      title: '',
      updatedAt: new Date(),
      videos: {
        videoIds: [],
      },
    },
  },
  total: 0,
  searchKey: '',
};

function achievementReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_ACHIEVEMENT_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.ACHIEVEMENT_DETAIL_SUCCESS:
      return {
        ...state,
        achievementDetail: action.payload,
        flagValues: action.payload && action.payload.flagValues,
        specialtyValues: action.payload && action.payload.specialtyValues,
      };
    case ActionTypes.ACHIEVEMENT_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        achievementList: [...state.achievementList, ...action.payload],
        isLoading: false,
      };
    case ActionTypes.ACHIEVEMENT_LIST_TOTAL:
      return {
        ...state,
        total: action.payload,
        isLoading: false,
      };
    case ActionTypes.ACHIEVEMENT_UPDATED_INFO:
      return {
        ...state,
        achievementDetail: {
          ...initialState.achievementDetail,
          ...action.payload,
        },
      };
    case ActionTypes.ACHIEVEMENT_DELETE_INFO:
      return {
        ...state,
        achievementDetail: {
          ...initialState.achievementDetail,
          ...action.payload,
        },
      };
    case ActionTypes.OTHER_DETAIL_SUCCESS:
      return {
        ...state,
        aFlag: action.payload && action.payload.flagValues,
        aSpecialty: action.payload && action.payload.specialtyValues,
      };
    case ActionTypes.ACHIEVEMENT_LOADING:
      return { ...state, isAchievementLoading: action.payload };
    case ActionTypes.GET_SEARCH_RESULT:
      return { ...state, searchKey: action.payload };
    case ActionTypes.RESET_ACHIEVEMENT:
      return (state = initialState);
    case ActionTypes.PROGRAM_CHALLENGE_LIST:
      return {
        ...state,
        programChallenge: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.GENRES_LIST:
      return {
        ...state,
        genres: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.ACHIEVEMENT_LIST_SUCCESS:
      return {
        ...state,
        achievementList: [...action.payload],
        programChallenge: [...action.payload],
        genres: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.ADD_ACHIEVEMENT:
      return { ...state, addAchievement: action.payload };
    case ActionTypes.STORE_ACHIEVEMENT_DETAIL:
      return { ...state, achievementDetailInfo: action.payload };
    case ActionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case ActionTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case ActionTypes.ACHIEVEMENT_LIST_FAILED:
      return { ...state, isListFetchingError: true };
    case ActionTypes.DELETE_ACHIEVEMENT_INFO_SUCCESS:
      return { ...state, deleteAchievementSuccess: action.payload };
    case ActionTypes.DELETE_ACHIEVEMENT_INFO_FAILED:
      return { ...state, deleteAchievementFailure: action.payload };
    default:
      return state;
  }
}

export default achievementReducer;
