import ChallengeFallbackIcon from '../images/challenge_fallback_icon.png';
import ProgramFallbackIcon from '../images/program_fallback_icon.png';
import InterestFallbackIcon from '../images/interest_fallback_icon.png';

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validatePassword = password => {
  const regex = /^(.{5,15})$/;
  return regex.test(String(password));
};

export const validatePermaLink = (value, check) => {
  return !value || check.test(value);
};

//sorting in alphabetical order
export function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function(a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}

export const getFallbackIcon = collectionType => {
  switch (collectionType) {
    case 'program':
      return ProgramFallbackIcon;
    case 'challenge':
      return ChallengeFallbackIcon;
    case 'interest':
      return InterestFallbackIcon;
    default:
      return ProgramFallbackIcon;
  }
};
