import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectLive from './selectors';
import reducer from './reducer';
import saga from './saga';
import { Button } from '@material-ui/core';
import {
  requestLiveVideosList,
  setTabChange,
  requestFilterParamsList,
  updateFilterParams,
  requestLiveVideoListWithFilters,
  requestVideoListPagination,
  updateSearchCount,
  searchLiveResultsPagination,
  toggleExplicitLangAction,
  liveReupdateVideoList,
} from './actions';
import VideoListTable from '../Video/VideoTable/VideoTable';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';
import { useStyles } from '../Video/styles';
import {
  SvgIconSearch,
  SvgIconAdd,
  SvgLoaderGif,
} from '../../components/NeIcons';
import SortSelect from '../Video/Select';
import Loader from '../../components/Loader';
import CommonFilter from '../../components/CommonFilter';
import FilterSearchLabel from '../../components/CommonFilter/FilterSearchLabel';
import FilterpickerButton from '../../components/CommonFilter/FilterpickerButton';
import FilterSearchResultsButton from '../../components/CommonFilter/FilterSearchResultsButton';
import FilterChipsBoxes from '../../components/CommonFilter/FilterChipsBoxes';
import { Helmet } from 'react-helmet';

const stateSelector = createStructuredSelector({
  live: makeSelectLive(),
});

interface Props {
  classes: StyleType;
}

type StyleType = Record<
  | 'root'
  | 'head'
  | 'upperHeaderSection'
  | 'lowerHeaderSection'
  | 'headWrap'
  | 'addVideoBtn'
  | 'newVideo'
  | 'searchIcon'
  | 'styledTabLive'
  | 'noData'
  | 'divider'
  | 'filterPanel'
  | 'footer'
  | 'title__heading'
  | 'searchHeader'
  | 'searchBar'
  | 'startOver'
  | 'selectedContainer'
  | 'selectedcontained'
  | 'chipBoxes'
  | 'addFilter'
  | 'sortItem'
  | 'dividers'
  | 'liveEvent'
  | 'loadMoreBtn',
  string
>;

function Live(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'live', reducer: reducer });
  useInjectSaga({ key: 'live', saga: saga });

  const { live: video } = useSelector(stateSelector);
  const {
    videoList,
    isListFetchingError,
    isLoading,
    filterData,
    searchResultsCount,
    durationDetails,
    videoTotalCount,
    isExplicitLang,
    isVideoLoading,
  } = video;

  const dispatch = useDispatch();
  const classes: StyleType = useStyles();
  const [isAddVideoOpen, openAddVideoDialog] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [showFilterModal, setShowFilterModal] = React.useState(false);
  const [keyword, setKeyword] = useState('');

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setShowChipBoxes(false);
    intialValues();
    if (tabIndex === 0) {
      dispatch(setTabChange('isHidden=false'));
      dispatch(requestLiveVideosList());
    } else if (tabIndex === 1) {
      dispatch(setTabChange('isUpcoming=true'));
      dispatch(requestLiveVideosList());
    } else if (tabIndex === 2) {
      dispatch(setTabChange('isCompleted=true'));
      dispatch(requestLiveVideosList());
      intialValues();
    }
    setActiveTab(tabIndex);
  };

  let durationLength = React.useMemo(() => {
    return (
      (filterData &&
        filterData.duration &&
        filterData.duration.values &&
        filterData.duration.values.length) ||
      0
    );
  }, [filterData]);

  useEffect(() => {
    // Update the document title using the browser API
    if (activeTab === 0) {
      dispatch(setTabChange('isHidden=false'));
    }
    dispatch(requestLiveVideosList());
    dispatch(requestFilterParamsList());
    // dispatch(getDurationDetails());
  }, []);

  const tabConfig = [
    {
      label: 'ALL',
      component: <VideoListTable />,
    },
    {
      label: 'UPCOMING',
      component: <VideoListTable />,
    },
    {
      label: 'COMPLETED',
      component: <VideoListTable />,
    },
  ];

  const [showFilterOption, setShowFilterOption] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [initialfilterData, setInitialfilterData] = useState(true);
  const [APIRangeValue, setAPIRangeValue] = useState([]);
  const [APIDifficultyValue, setAPIDifficultyValue] = useState([]);
  const [difficult, setDifficult] = useState([]);
  const [showChipBoxes, setShowChipBoxes] = useState(false);
  const [showAddEvent, setShowAddEvent] = useState(false);
  /* Boolean to show/hide the filter options alone */

  /* Boolean to show/hide the Results from the after the API trigger */
  const [showResultOption, setshowResultOption] = useState(false);

  const [selectedAPIData, setSelectedAPIData] = useState({
    keyword: '',
    explicitLang: false,
    duration: APIRangeValue,
    bodyFocus: [],
    difficulty: APIDifficultyValue,
    equipments: [],
    music: [],
    language: [],
    selectedCount: null,
    showFilterOption: showFilterOption,
    showResultOption: showResultOption,
  });

  const [selectedFilterParams, setselectedFilterParams] = useState({
    duration: APIRangeValue,
    bodyFocus: [],
    difficulty: difficult,
    explicitLang: isExplicitLang,
    equipments: [],
    music: [],
    language: [],
  });
  if (initialfilterData && filterData && Object.keys(filterData).length > 0) {
    setAPIDifficultyValue([]);
    setAPIRangeValue([]);
    setDifficult([]);
    const filterObject = Object.keys(filterData);
    filterObject.length &&
      filterObject.map(item => {
        if (item !== 'explicitLang' && filterData) {
          filterData[item] &&
            filterData[item].values &&
            filterData[item].values.map((itm, index) => {
              if (item == 'difficulty') {
                difficult.push(itm);
                APIDifficultyValue.push(itm.id);
              } else if (item === 'duration') {
                APIRangeValue.push(itm.id);
              }
            });
          setAPIRangeValue(APIRangeValue);
        }
        setInitialfilterData(false);
      });
  }

  function intialValues() {
    dispatch(toggleExplicitLangAction(false));
    setAPIDifficultyValue([]);
    setAPIRangeValue([]);
    setDifficult([]);
    let range = [];
    let difficul = [];
    let apiDifficul = [];
    const filterObject = Object.keys(filterData);
    filterObject.map(item => {
      if (item !== 'explicitLang') {
        filterData[item].values.map((itm, index) => {
          if (item == 'difficulty') {
            itm.isSelected = true;
            difficul.push(itm);
            apiDifficul.push(itm.id);
          } else if (item === 'duration') {
            range.push(itm.id);
          } else if (item !== 'difficulty' && item !== 'duration') {
            itm.isSelected = false;
            itm.mainTitle = item;
            itm.index = index;
          }
        });
        setAPIRangeValue(range);
        setDifficult(difficul);
        setAPIDifficultyValue(apiDifficul);
      }
    });
    setselectedFilterParams({
      duration: APIRangeValue,
      bodyFocus: [],
      difficulty: difficul,
      equipments: [],
      music: [],
      language: [],
      explicitLang: false,
    });
    setSelectedAPIData({
      keyword: '',
      explicitLang: false,
      duration: APIRangeValue,
      bodyFocus: [],
      difficulty: apiDifficul,
      equipments: [],
      music: [],
      language: [],
      selectedCount: null,
      showFilterOption: showFilterOption,
      showResultOption: showResultOption,
    });
    dispatch(updateSearchCount());
    setShowChipBoxes(false);
    setFilterCount(0);
  }

  const changedFilterParams = (
    filterParams,
    selectedFilterParams,
    selectedAPIData,
    rangeValue,
    difficultyValue,
  ) => {
    setselectedFilterParams(selectedFilterParams);
    setSelectedAPIData(selectedAPIData);
    setAPIRangeValue(rangeValue);
    setDifficult(difficultyValue);
    dispatch(updateFilterParams(filterParams));
    if (selectedAPIData.keyword) {
      setKeyword(selectedAPIData.keyword);
    }
    filterCountCalculation();
    dispatch(requestLiveVideoListWithFilters(selectedAPIData));
  };

  function filterCountCalculation() {
    let objects = Object.keys(selectedFilterParams);
    let count = 0;
    objects.map(item => {
      if (selectedFilterParams[item] && selectedFilterParams[item].length) {
        if (
          item === 'duration' &&
          selectedFilterParams[item].length !== durationLength
        ) {
          count += 1;
        } else if (
          item === 'difficulty' &&
          selectedFilterParams[item].length !== 4
        ) {
          count += selectedFilterParams[item].length;
        } else if (item !== 'duration' && item !== 'difficulty') {
          count += selectedFilterParams[item].length;
        }
      }
      setFilterCount(count);
    });
    if (selectedAPIData.keyword.length === 0 && !count) {
      intialValues();
      setShowChipBoxes(false);
    } else {
      setShowChipBoxes(true);
    }
  }

  const dispatchSearchAPIcall = () => {
    dispatch(requestLiveVideoListWithFilters(selectedAPIData));
    // dispatch(filterUpdateList());
    setShowFilterModal(false);
    setShowChipBoxes(true);
  };

  const dispatchSearchAPIcallSuccess = () => {
    // dispatch(filterUpdateList());
    setShowFilterModal(false);
    setShowChipBoxes(true);
    setActiveTab(0);
  };

  const onSelected = (id?, mainTitle?, selected?, title?, index?) => {
    if (mainTitle !== 'duration') {
      filterData[mainTitle].values[index].isSelected = !filterData[mainTitle]
        .values[index].isSelected;
      selectedFilterParams[mainTitle] = [];
      selectedAPIData[mainTitle] = [];
      let difficultyResult = filterData[mainTitle].values.filter(itm => {
        if (itm.isSelected) {
          return itm;
        }
      });
      selectedFilterParams[mainTitle] = difficultyResult;
      let APIResults = selectedFilterParams[mainTitle].map(itm => {
        return itm.id;
      });
      selectedAPIData[mainTitle] = APIResults;
    } else {
      if (
        selectedAPIData.bodyFocus.length == 0 &&
        selectedAPIData.difficulty.length == 0 &&
        selectedAPIData.equipments.length == 0 &&
        selectedAPIData.music.length == 0 &&
        selectedAPIData.language.length == 0
      ) {
        setDurationValues();
        selectedAPIData.duration = APIRangeValue;
        selectedFilterParams.duration = APIRangeValue;
        setShowChipBoxes(false);
      } else {
        setDurationValues();
        selectedAPIData.duration = APIRangeValue;
        selectedFilterParams.duration = APIRangeValue;
        setShowChipBoxes(true);
      }
    }
    setselectedFilterParams(selectedFilterParams);

    // setShowFilterOption(!showFilterOption); // show filter section toggle
  };

  const onSelectedKeyword = value => {
    selectedAPIData.keyword = value;
    setSelectedAPIData(selectedAPIData);
    if (value.length <= 2) {
      if (value.length == 0) {
        selectedAPIData.keyword = '';
      }
      setKeyword(value);
      selectedAPIData.selectedCount = 0;
    } else if (value.length > 2) {
      setKeyword(value);
      changedFilterParams(
        filterData,
        selectedFilterParams,
        selectedAPIData,
        APIRangeValue,
        APIDifficultyValue,
      );
    }
  };
  function setDurationValues() {
    setAPIDifficultyValue([]);
    setAPIRangeValue([]);
    setDifficult([]);
    const filterObject = Object.keys(filterData);
    filterObject.map(item => {
      if (item !== 'explicitLang' && filterData) {
        filterData[item] &&
          filterData[item].values &&
          filterData[item].values.map((itm, index) => {
            if (item == 'difficulty') {
              difficult.push(itm);
              APIDifficultyValue.push(itm.id);
            } else if (item === 'duration') {
              APIRangeValue.push(itm.id);
            }
          });
      }
      setInitialfilterData(false);
    });
  }

  const onSelectedChips = (id?, mainTitle?, selected?, title?, index?) => {
    if (mainTitle !== 'duration') {
      filterData[mainTitle].values[index].isSelected = !filterData[mainTitle]
        .values[index].isSelected;
      selectedFilterParams[mainTitle] = [];
      selectedAPIData[mainTitle] = [];
      let difficultyResult = filterData[mainTitle].values.filter(itm => {
        if (itm.isSelected) {
          return itm;
        }
      });
      selectedFilterParams[mainTitle] = difficultyResult;
      let APIResults = selectedFilterParams[mainTitle].map(itm => {
        return itm.id;
      });
      selectedAPIData[mainTitle] = APIResults;

      // dispatch(changedFiltersParams(filterDatafilterData));
      // dispatch(selectedFilterParamsData(selectedFilterParams));
      filterCountCalculation();
      dispatchSearchAPIcall();
    } else {
      if (
        selectedAPIData.bodyFocus.length == 0 &&
        selectedAPIData.difficulty.length == 0 &&
        selectedAPIData.equipments.length == 0 &&
        selectedAPIData.music.length == 0 &&
        selectedAPIData.language.length == 0
      ) {
        setShowChipBoxes(false);
        intialValues();
        selectedAPIData.showFilterOption = true;
        selectedAPIData.showResultOption = false;
      } else {
        selectedAPIData.duration = APIRangeValue;
        selectedFilterParams.duration = APIRangeValue;
        // dispatch(selectedFilterParamsData(selectedFilterParams));
        dispatchSearchAPIcall();
      }
      filterCountCalculation();
    }
  };

  function loadMore() {
    if (
      showFilterModal === false &&
      ((keyword && keyword.length > 0) || filterCount > 0)
    ) {
      dispatch(
        searchLiveResultsPagination({
          values: selectedAPIData,
          count: videoList.length,
        }),
      );
    } else if (
      showFilterModal === false &&
      videoList &&
      videoList.length < videoTotalCount
    ) {
      dispatch(requestVideoListPagination(videoList.length));
    }
  }

  function toggleFilterPanel() {
    setShowFilterModal(!showFilterModal);
    filterCountCalculation();
  }

  return (
    <>
      <Helmet>
        <title>Library Live</title>
        <meta name="description" content="Library Live" />
      </Helmet>
      {isLoading && <Loader />}

      {showFilterModal === false && (
        <div className={classes.root}>
          <div></div>
          <div className={classes.headWrap}>
            <div className={classes.upperHeaderSection}>
              <div className={classes.head}>Library: Live</div>
              <div className={classes.searchIcon} onClick={toggleFilterPanel}>
                <SvgIconSearch />
              </div>
            </div>
          </div>

          {showFilterModal === false && (
            <div className={classes.searchHeader}>
              {((keyword && keyword.length > 0) || filterCount > 0) && (
                <>
                  <div className={classes.searchBar}>
                    <FilterSearchLabel
                      predictions={filterData.searchSuggestions}
                      onChange={val => {
                        onSelectedKeyword(val);
                      }}
                      values={selectedAPIData.keyword}
                      onClick={dispatchSearchAPIcall}
                    />
                  </div>
                  <div className={classes.startOver}>
                    <FilterpickerButton
                      key={1}
                      title={'start over'}
                      startover={true}
                      onSelect={() => {
                        intialValues();
                        // toggleFilterPanel();
                        dispatch(requestLiveVideosList());
                        setKeyword('');
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {showFilterModal === false && showChipBoxes && filterCount > 0 && (
            <div className={classes.selectedContainer}>
              <div className={classes.selectedcontained}>
                <div className={classes.chipBoxes}>
                  <FilterChipsBoxes
                    durationLength={durationLength}
                    value={selectedFilterParams}
                    onSelect={(value: {
                      id: any;
                      mainTitle: any;
                      isSelected: any;
                      title: any;
                      index: any;
                    }) => {
                      onSelectedChips(
                        value.id,
                        value.mainTitle,
                        value.isSelected,
                        value.title,
                        value.index,
                      );
                    }}
                  />
                </div>
                <div className={classes.addFilter} onClick={toggleFilterPanel}>
                  Add Filters
                </div>
              </div>
            </div>
          )}

          <StyledTabs
            value={activeTab}
            onChange={onTabChange}
            aria-label="My account tabs"
            centered
            className={classes.styledTabLive}
          >
            {tabConfig.map((t, index) => (
              <StyledTab key={index} label={t.label} />
            ))}
          </StyledTabs>

          {isListFetchingError ||
          !videoList ||
          (videoList && videoList.length === 0) ? (
            isVideoLoading && !showFilterModal ? (
              <div style={{ padding: '10px' }}>
                <SvgLoaderGif />
              </div>
            ) : (
              <div className={classes.noData}>No Data found</div>
            )
          ) : (
            <VideoListTable list={videoList} isLoading={isLoading} />
          )}
          {videoList &&
            videoList.length > 0 &&
            videoTotalCount > videoList.length && (
              <div className={classes.loadMoreBtn}>
                <Button color="secondary" onClick={() => loadMore()}>
                  Load More Classes
                </Button>
              </div>
            )}
        </div>
      )}
      {showFilterModal && (
        <div className={classes.filterPanel}>
          <CommonFilter
            filterData={filterData}
            selectedParams={selectedFilterParams}
            selectedAPIParams={selectedAPIData}
            rangeValue={APIRangeValue}
            difficultyValue={difficult}
            isExplicitLang={isExplicitLang}
            onChangedValue={(
              filterParams,
              selectedFilterParams,
              selectedAPIData,
              rangeValue,
              difficultyValue,
            ) => {
              changedFilterParams(
                filterParams,
                selectedFilterParams,
                selectedAPIData,
                rangeValue,
                difficultyValue,
              );
            }}
            closeFilterPanel={toggleFilterPanel}
            updateList={() => dispatch(liveReupdateVideoList())}
          />
          <div className={classes.footer}>
            {showFilterModal && (
              <div>
                <FilterpickerButton
                  key={1}
                  title={'Clear All'}
                  clearAll={true}
                  onSelect={() => {
                    intialValues();
                    dispatch(toggleExplicitLangAction(false));
                  }}
                />
                <FilterSearchResultsButton
                  onClick={dispatchSearchAPIcallSuccess}
                  value={
                    selectedAPIData.keyword &&
                    selectedAPIData.keyword.length > 0
                      ? searchResultsCount
                      : searchResultsCount
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Live;
