import SvgIconLibrary from '../components/NeIcons/IconLibrary';
import SvgIconConfigure from '../components/NeIcons/IconConfigure';
import SvgIconMembers from '../components/NeIcons/IconMembers';
import SvgIconPlans, { SvgAddClass } from '../components/NeIcons/IconPlans';
import SvgIconAdmin from '../components/NeIcons/IconAdmin';
import Footer from '../components/Footer';
import Video from '../containers/Video';
import Concept from '../containers/Concept';
import Collection from '../containers/Collections';
import People from '../containers/People';
import Achievements from '../containers/Achievement';
import InstructorLandingPage from '../containers/People/Preview';
import HomePageTray from '../containers/HomePageTray';
import BrowsePageTray from '../containers/BrowsePageTray';
import Attributes from '../containers/Attributes';
import Legal from '../containers/Legal';
import BrandVoice from '../containers/BrandVoice';
import Subscription from '../containers/Subscription';
import Members from '../containers/Members';
import Filters from '../containers/Filters';
import HomeCurate from '../containers/HomeCurate';
import Adminstrator from '../containers/Adminstrator';
import NotFound from '../containers/NotFound';
import InteractivePublishedClasses from '../containers/InteractivePublishedClasses';
import CurateInteractive from '../containers/CurateInteractive';
import InReview from '../containers/InReview';
import InstructorMyClasses from '../containers/InstructorMyClasses';
import {
  SvgHome,
  SvgInteractive,
  SvgMyClass,
  SvgLive,
} from '../components/NeIcons';
import MyClassDetail from '../containers/MyClassDetail';
import MyAccount from '../containers/MyAccount';
import InstructorAddClasses from '../containers/InstructorAddClasses';
import FitnessGoals from '../containers/FitnessGoals';
import BrowseCurate from '../containers/BrowseCurate';
import SignOutHome from '../containers/SignOutHome';
import InteractiveCoupon from '../containers/InteractiveCoupon';
import LiveClass from '../containers/LiveClass';
import CurateGenre from '../containers/CurateGenre';
import AddInteractiveClass from '../containers/AddInteractiveClass';
import InteractiveLegal from '../containers/InteractiveLegal';
import Live from '../containers/Live';

const sideMenuRoutes = [
  {
    nodeId: 1,
    label: 'LIBRARY',
    icon: SvgIconLibrary,
    name: 'library',
    accessibleFor: ['admin', 'superadmin'],
    subMenu: [
      {
        nodeId: 11,
        label: 'Videos',
        path: '/neou/videos',
        component: Video,
      },
      {
        nodeId: 12,
        label: 'Live',
        path: '/neou/live',
        component: Live,
      },
      {
        nodeId: 13,
        label: 'Concepts',
        path: '/neou/concepts',
        component: Concept,
      },
      {
        nodeId: 14,
        label: 'Collections',
        path: '/neou/collections',
        component: Collection,
      },
      {
        nodeId: 15,
        label: 'People',
        path: '/neou/people',
        component: People,
      },
    ],
  },
  {
    nodeId: 13,
    icon: SvgLive,
    accessibleFor: ['admin', 'superadmin'],
    label: 'Live Class Participants',
    path: '/neou/live-class',
    name: 'live-participants-page',
    component: LiveClass,
  },
  {
    nodeId: 2,
    label: 'INTERACTIVE',
    icon: SvgInteractive,
    name: 'interactive',
    accessibleFor: ['admin', 'superadmin'],
    subMenu: [
      {
        nodeId: 14,
        label: 'Add Interactive Class',
        path: '/neou/add-interactive-class',
        component: AddInteractiveClass,
      },
      {
        nodeId: 15,
        label: 'Draft Classes',
        path: '/neou/review',
        component: InReview,
      },
      {
        nodeId: 16,
        label: 'Published Classes',
        path: '/neou/published-classes',
        component: InteractivePublishedClasses,
      },
      {
        nodeId: 17,
        label: 'Promo Codes',
        path: '/neou/promo-code',
        component: InteractiveCoupon,
      },
      // {
      //   nodeId: 18,
      //   label: 'Legal',
      //   path: '/neou/interactive-legal',
      //   component: InteractiveLegal,
      // },
    ],
  },
  {
    nodeId: 3,
    label: 'CURATE',
    icon: SvgHome,
    name: 'curate',
    accessibleFor: ['admin', 'superadmin'],
    subMenu: [
      {
        nodeId: 21,
        label: 'Home Page Trays',
        path: '/neou/home-page-tray',
        component: HomeCurate,
      },
      {
        nodeId: 22,
        label: 'Signed Out Home Page',
        path: '/neou/signed-out-home-page',
        component: SignOutHome,
      },
      {
        nodeId: 23,
        label: 'Browse Page Trays',
        path: '/neou/browse-curate',
        component: BrowseCurate,
      },
      {
        nodeId: 24,
        label: 'Browse Genre',
        path: '/neou/curate-browse-genre',
        component: CurateGenre,
      },
      {
        nodeId: 25,
        label: 'Brand Voice',
        path: '/neou/brand-voice',
        component: BrandVoice,
      },
      // {
      //   nodeId: 26,
      //   label: 'Interactive',
      //   path: '/neou/interactive',
      //   component: CurateInteractive,
      // },
    ],
  },
  {
    nodeId: 4,
    label: 'CONFIGURE',
    icon: SvgIconConfigure,
    name: 'configure',
    accessibleFor: ['admin', 'superadmin'],
    subMenu: [
      {
        nodeId: 29,
        label: 'Fitness Goals',
        path: '/neou/fitness-goals',
        component: FitnessGoals,
      },
      {
        nodeId: 30,
        label: 'Attributes',
        path: '/neou/attributes',
        component: Attributes,
      },
      {
        nodeId: 31,
        label: 'Filters',
        path: '/neou/filters',
        component: Filters,
      },
      {
        nodeId: 32,
        label: 'Achievements',
        path: '/neou/achievements',
        component: Achievements,
      },
      // {
      //   nodeId: 33,
      //   label: 'Credits',
      //   path: '/neou/achievements',
      //   component: Achievements,
      // },
      {
        nodeId: 34,
        label: 'Legal',
        path: '/neou/legal',
        component: Legal,
      },
    ],
  },
  {
    nodeId: 5,
    label: 'MEMBERS',
    icon: SvgIconMembers,
    path: '/neou/members',
    component: Members,
    name: 'members',
    accessibleFor: ['admin', 'superadmin'],
  },
  // {
  //   nodeId: 6,
  //   label: 'PLANS',
  //   icon: SvgIconPlans,
  //   path: '/neou/subscription',
  //   component: Subscription,
  //   name: 'plans',
  //   accessibleFor: ['admin', 'superadmin'],
  // },
  {
    nodeId: 7,
    label: 'ADMIN',
    icon: SvgIconAdmin,
    admin: 'admins',
    name: 'admins',
    accessibleFor: ['superadmin'],
    subMenu: [
      {
        nodeId: 61,
        label: 'Administrators',
        path: '/neou/adminstrator',
        component: Adminstrator,
      },
      // {
      //   nodeId: 62,
      //   label: 'Audit Log',
      //   path: '/neou/adminstrator',
      //   component: Adminstrator,
      // },
    ],
  },
  {
    nodeId: 8,
    label: 'MY CLASSES',
    icon: SvgMyClass,
    path: '/neou/my-classes',
    component: InstructorMyClasses,
    name: 'my-classes',
    accessibleFor: ['instructor'],
  },
  {
    nodeId: 10,
    label: 'ADD CLASSES',
    icon: SvgAddClass,
    path: '/neou/instructor-add-classes',
    component: InstructorAddClasses,
    name: 'add-classes',
    accessibleFor: ['instructor'],
  },
  {
    nodeId: 11,
    icon: SvgIconPlans,
    path: '/neou/instructor-my-class',
    component: MyClassDetail,
    name: 'my-classes',
    isHidden: true,
    accessibleFor: ['instructor'],
  },
  {
    nodeId: 12,
    label: '',
    icon: SvgIconPlans,
    path: '/neou/my-account',
    component: MyAccount,
    name: 'my-account',
    isHidden: true,
    accessibleFor: ['instructor'],
  },
];

export default sideMenuRoutes;
