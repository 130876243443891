/**
 *
 * NeIcon
 *
 */
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import HomeSvg from '../../images/icons/navigation/home.svg';

// import styled from 'styles/styled-components';
// npx @svgr/cli --icon icon.svg

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

interface Props {}

function SvgHome(props: Props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <path
        d="M8 12.496a.25.25 0 01.139.042l3.632 2.42a.495.495 0 00.692-.128.543.543 0 00.09-.46L11.5 9.987a.276.276 0 01.086-.273l3.295-2.833a.548.548 0 00.165-.581.516.516 0 00-.456-.376l-4.292-.335a.26.26 0 01-.22-.168L8.48 1.293a.51.51 0 00-.962 0L5.921 5.421a.26.26 0 01-.22.168l-4.292.335a.516.516 0 00-.456.376.548.548 0 00.165.581l3.295 2.833c.077.066.11.172.086.273L3.447 14.37a.543.543 0 00.09.46c.1.135.256.215.415.215.097 0 .19-.03.277-.087l3.632-2.42A.25.25 0 018 12.496"
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SvgInteractive(props: Props) {
  return (
    <svg width={25} height={15} viewBox="0 0 25 15" {...props}>
      <title>{'4026B09B-23FC-49C2-B59F-F35D4164BEF8'}</title>
      <g
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12.289 7.374C11.156 3.188 8.704 2 6.874 2a5.374 5.374 0 103.747 9.226M12.289 7.374c1.133 4.186 3.585 5.374 5.415 5.374a5.374 5.374 0 10-3.747-9.226" />
        <path d="M16.161 4.046l-2.285-.527.528-2.286M8.413 10.715l2.285.527-.527 2.286" />
      </g>
    </svg>
  );
}

export function SvgMyClass(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 21 19" {...props}>
      <path
        d="M3.654 2.107h13a2.5 2.5 0 012.5 2.5V15.5a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5V4.607a2.5 2.5 0 012.5-2.5zm-2.5 4.967h18M5.825 1v3.094M14.454 1v3.094"
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SvgOutlined(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <defs>
        <path
          id="outlined_svg__a"
          d="M15 2.4L13.6 1 8 6.6 2.4 1 1 2.4 6.6 8 1 13.6 2.4 15 8 9.4l5.6 5.6 1.4-1.4L9.4 8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="outlined_svg__b" fill="#fff">
          <use xlinkHref="#outlined_svg__a" />
        </mask>
        <g mask="url(#outlined_svg__b)" fill="#000" fillOpacity={0.87}>
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </svg>
  );
}

function SvgLive(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M9.654 7.578L6.936 6.006a.36.36 0 00-.397.02.492.492 0 00-.194.402v3.144c0 .164.074.316.194.402a.363.363 0 00.397.02l2.718-1.572A.484.484 0 009.879 8a.484.484 0 00-.225-.422z"
          fill="currentColor"
        />
        <path
          d="M14.223 13.978c.802-1.669 1.27-3.675 1.27-5.834 0-2.273-.519-4.378-1.401-6.097M1.929 2.02C1.038 3.744.513 5.859.513 8.144c0 2.16.468 4.167 1.272 5.836M11.677 12.008c.539-1.119.852-2.464.852-3.911 0-1.525-.348-2.936-.94-4.088M4.42 3.99c-.597 1.156-.948 2.574-.948 4.107 0 1.448.314 2.794.852 3.913"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

function SvgMessages(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M13.02 2.446a1.6 1.6 0 011.6 1.6v6.835a1.6 1.6 0 01-1.6 1.6H8.773L5.03 15.357v-2.876H3.03a1.6 1.6 0 01-1.6-1.6V4.046a1.6 1.6 0 011.6-1.6h9.99z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle fill="#FF1C58" cx={14.2} cy={2.729} r={2.729} />
      </g>
    </svg>
  );
}

function SvgBrowse(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <g
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8 .685L.663 4.282 8 7.879l7.337-3.597zM.663 11.476L8 15.074l7.337-3.598M.663 7.88L8 11.475l7.337-3.597" />
      </g>
    </svg>
  );
}

function SvgInstructorPlaceholder(props) {
  return (
    <svg height="auto" viewBox="0 0 1080 1080" {...props}>
      <defs>
        <path id="instructor-placeholder_svg__a" d="M0 0h1080v1080H0z" />
        <path
          d="M294.07 55.642c48.744 0 88.625 39.88 88.625 88.624 0 48.743-39.88 88.624-88.624 88.624-48.743 0-88.624-39.88-88.624-88.624 0-48.743 39.88-88.624 88.624-88.624zM281.987 381.94c105.14 0 225.857 57.162 233.645 88.624v44.312H48.341v-43.869c7.788-31.905 128.504-89.067 233.645-89.067zM153.078 144.266c0 77.899 63.094 140.993 140.993 140.993s140.993-63.094 140.993-140.993S371.97 3.273 294.07 3.273 153.078 66.367 153.078 144.266zm128.908 185.305C187.873 329.57 0 375.068 0 465.384v101.86h563.972v-101.86c0-90.316-187.873-135.813-281.986-135.813z"
          id="instructor-placeholder_svg__c"
        />
        <radialGradient
          cx="50%"
          cy="50%"
          fx="50%"
          fy="50%"
          r="99.755%"
          id="instructor-placeholder_svg__b"
        >
          <stop stopColor="#35414A" offset="0%" />
          <stop stopColor="#222A31" offset="100%" />
        </radialGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <use
          fill="url(#instructor-placeholder_svg__b)"
          xlinkHref="#instructor-placeholder_svg__a"
        />
        <use
          fill="#3E4850"
          fillRule="nonzero"
          xlinkHref="#instructor-placeholder_svg__c"
          transform="translate(260 256)"
        />
      </g>
    </svg>
  );
}

function SvgSearch(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <g
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={7} cy={7} r={5} />
        <path d="M14 14l-3.467-3.467" />
      </g>
    </svg>
  );
}

function SvgBlog(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 31 16" {...props}>
      <path
        d="M8.603 6.26c-.263.435-.53.847-.8 1.236a1.691 1.691 0 01-.398-.024 1.007 1.007 0 01-.703-.481c-.171-.277-.078-1.395.507-2.259.253-.374.666-.793 1.246-.793.066 0 .136.006.206.018.421.07.5.248.527.307.219.493-.294 1.548-.576 1.982l-.009.014M30.92 9.38c-1.77-2.612-6.243-.646-10.188 1.089-1.28.563-2.49 1.094-3.483 1.412-.763.244-1.008.175-1.055.155-.102-.303.567-1.681 1.92-3.295.027-.034.705-.837 1.329-1.753.989-1.453 1.261-2.299.91-2.828-.404-.606-1.301-.335-1.596-.246-1.643.497-3.395 1.674-4.968 2.982.193-.268.42-.61.6-.94.263-.482.517-1.084.148-1.457-.436-.44-1.156-.164-2.851 1.091-1.033.765-1.853 1.243-2.503 1.529l.227-.37c.138-.211 1.12-1.787.643-2.863-.142-.32-.472-.728-1.235-.855-.925-.153-1.774.265-2.392 1.177-.723 1.069-.964 2.568-.527 3.274.288.466.72.774 1.252.895-1.537 1.95-3.18 3.161-4.825 3.544-.464.107-.854.04-1.1-.188-.24-.224-.33-.6-.254-1.057.405-2.41 4.532-5.785 4.574-5.82a.468.468 0 00.067-.66.474.474 0 00-.664-.066C4.767 4.277.503 7.764.04 10.521c-.13.777.061 1.45.54 1.897.483.45 1.18.598 1.96.416 2.05-.476 3.995-1.973 5.796-4.458 1.051-.198 2.344-.87 3.913-2.033.641-.475 1.066-.744 1.342-.897a8.104 8.104 0 01-.72 1.104c-.667.86-4.138 5.438-4.173 5.484a.467.467 0 00.086.652.474.474 0 00.658-.074c.053-.066 5.36-6.521 9.59-7.8a1.77 1.77 0 01.518-.096c-.017.165-.145.671-.97 1.862a23.353 23.353 0 01-1.192 1.561c-.027.032-.657.785-1.225 1.659-.89 1.37-1.122 2.224-.75 2.77.219.324.57.432.954.432.392 0 .817-.113 1.17-.226 1.04-.333 2.272-.874 3.576-1.447 3.428-1.507 7.694-3.382 9.023-1.422.146.215.44.271.656.127a.467.467 0 00.127-.652"
        fill="currentColor"
        fillRule="evenodd"
        stroke="currentColor"
      />
    </svg>
  );
}

function SvgShop(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#12161A" d="M0 0h16v16H0z" />
        <path
          d="M2.167 3.675h11.666c.336 0 .592.27.61.59l.611 10.583c.018.32-.28.59-.61.59H1.555c-.331 0-.628-.266-.61-.59l.61-10.583a.615.615 0 01.611-.59z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.692 5.738a.773.773 0 110 1.546.773.773 0 010-1.546m-5.42 0a.773.773 0 110 1.547.773.773 0 010-1.547"
          fill="currentColor"
        />
        <path
          d="M10.69 5.997v-2.53C10.69 1.88 9.473.58 7.983.58c-1.49 0-2.708 1.299-2.708 2.887v2.53"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

function SvgProfile(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="none" d="M0 0h16v16H0z" />
        <path
          d="M14.324 15.167V13.61c0-1.718-1.424-3.111-3.181-3.111H4.779c-1.757 0-3.182 1.393-3.182 3.111v1.556"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx={7.961}
          cy={4.111}
          rx={3.182}
          ry={3.111}
        />
      </g>
    </svg>
  );
}

function SvgMenu(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 17 13" {...props}>
      <g
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.833 6.013h16M.833.738h16M.833 11.288h16" />
      </g>
    </svg>
  );
}

function SvgClose(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <path
        d="M2.225 2.225l11.55 11.55m-11.55 0l11.55-11.55"
        stroke="#FFF"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SvgMyClassThin(props) {
  return (
    <svg width={65} height={61} viewBox="0 0 65 61" {...props}>
      <title>{'A5AC7660-2D0C-4185-AA20-7B40B2995ADD'}</title>
      <path
        d="M10.038 3.9h45.5a8.75 8.75 0 018.75 8.75v38.5a8.75 8.75 0 01-8.75 8.75h-45.5a8.75 8.75 0 01-8.75-8.75v-38.5a8.75 8.75 0 018.75-8.75zm-8.75 17.5h63M17.638 0v10.9M47.838 0v10.9"
        stroke="#8B949B"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SvgPlay(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 66 64.5" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle
          stroke="#FFF"
          fill="#050606"
          opacity={0.5}
          cx={32.677}
          cy={32.192}
          r={32.5}
        />
        <path
          d="M43.08 33.338L27.452 43.496a1 1 0 01-1.545-.839V22.343a1 1 0 011.545-.839l15.626 10.158a1 1 0 010 1.676z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export function SvgCancelClass(props) {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" {...props}>
      <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
        <ellipse
          fill="#EA0000"
          fillRule="nonzero"
          cx={8.213}
          cy={8.268}
          rx={8.073}
          ry={8.253}
        />
        <path
          d="M2.225 2.225l11.55 11.55m-11.55 0l11.55-11.55"
          stroke="#FFF"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

function SvgMessagesNone(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path
        d="M13.02 2.446a1.6 1.6 0 011.6 1.6v6.835a1.6 1.6 0 01-1.6 1.6H8.773L5.03 15.357v-2.876H3.03a1.6 1.6 0 01-1.6-1.6V4.046a1.6 1.6 0 011.6-1.6h9.99z"
        stroke="#FFF"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SvgBack(props) {
  return (
    <svg width="16px" height="16px" viewBox="-0.5 0 12.5 10" {...props}>
      <path
        d="M4.332.149a.5.5 0 01.711.702L1.195 4.75H12.5a.5.5 0 01.492.41l.008.09a.5.5 0 01-.5.5H1.196l3.847 3.899a.5.5 0 01.054.638l-.058.069a.5.5 0 01-.707-.005l-4.688-4.75a.509.509 0 01-.133-.244A.498.498 0 01-.5 5.25l.005.074A.503.503 0 01-.5 5.26V5.25a.51.51 0 01.078-.268.499.499 0 01.066-.083L-.4 4.95a.503.503 0 01.035-.041l.01-.011z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

function SvgFavorite(props) {
  return (
    <svg width="19px" height="18px" viewBox="-0.5 0 20 18" {...props}>
      <path
        d="M9.5 17.743l-7.837-7.97C.58 8.672-.027 7.118.001 5.51A5.532 5.532 0 015.526 0C7.005 0 8.427.638 9.5 1.768 10.573.638 11.996 0 13.474 0c3.045 0 5.523 2.473 5.525 5.512.026 1.607-.58 3.16-1.663 4.262L9.5 17.743z"
        stroke={props.color || 'currentColor'}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SvgFavoriteSelected(props) {
  return (
    <svg width="19px" height="18px" viewBox="-0.5 0 20 18" {...props}>
      <path
        d="M9.5 18l-7.837-7.97C.58 8.929-.027 7.375.001 5.767A5.532 5.532 0 015.526.257c1.479 0 2.901.639 3.974 1.768C10.573.895 11.996.257 13.474.257c3.045 0 5.523 2.473 5.525 5.513.026 1.606-.58 3.16-1.663 4.26L9.5 18z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

function SvgShare(props) {
  return (
    <svg width="13px" height="17px" viewBox="0 0 14 17" {...props}>
      <g
        stroke="#FFF"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 8.4v6.08c0 .84.672 1.52 1.5 1.52h9c.828 0 1.5-.68 1.5-1.52V8.4M10 3.84L7 .8 4 3.84M7 .8v9.88" />
      </g>
    </svg>
  );
}

function SvgFilter(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 14" {...props}>
      <g
        stroke="#FFF"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.386 14V8.556M2.386 5.444V0M8.75 14V7M8.75 3.889V0M15.114 14v-3.889M15.114 7V0M0 8.556h4.773M6.364 3.889h4.772M12.727 10.111H17.5" />
      </g>
    </svg>
  );
}

function SvgBookmarkFilled(props) {
  return (
    <svg width="21px" height="16px" viewBox="0 0 16 21" {...props}>
      <path
        d="M15.957 20.656L8.1 15.101.243 20.656V2.878A2.234 2.234 0 012.488.656h11.224c1.24 0 2.245.995 2.245 2.222v17.778z"
        fill="currentColor"
        stroke="#FFF"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SvgBookmark(props) {
  return (
    <svg width="21px" height="16px" viewBox="0 0 16 21" {...props}>
      <path
        d="M15.957 20.656L8.1 15.101.243 20.656V2.878A2.234 2.234 0 012.488.656h11.224c1.24 0 2.245.995 2.245 2.222v17.778z"
        stroke="#FFF"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SvgArrowPager(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 -1.5 5 13" {...props}>
      <path
        d="M0 10l5-5.001L0 0"
        stroke="#0F0F0F"
        strokeWidth={3}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SvgCheckCircle(props) {
  return (
    <svg width={17} height={18} viewBox="0 0 17 18" {...props}>
      <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
        <ellipse
          fill="#12161A"
          fillRule="nonzero"
          cx={8.073}
          cy={8.768}
          rx={8.073}
          ry={8.053}
        />
        <path
          d="M4.762 9.025l2.384 2.378m4.378-4.305L7.136 11.42"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

function SvgLoaderGif(props) {
  return (
    <svg
      style={{
        margin: 'auto',
        background: '#D4D8DC',
      }}
      width="40px"
      height="40px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      display="block"
      {...props}
    >
      <circle
        cx={50}
        cy={50}
        r={32}
        strokeWidth={8}
        stroke="#fff"
        strokeDasharray="50.26548245743669 50.26548245743669"
        fill="none"
        strokeLinecap="round"
        transform="rotate(214.179 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          keyTimes="0;1"
          values="0 50 50;360 50 50"
        />
      </circle>
    </svg>
  );
}

function SvgClock(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17 17" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#12161A" d="M0 0h17v17H0z" />
        <path
          d="M8.5 16.26a7.762 7.762 0 007.76-7.76A7.762 7.762 0 008.5.74 7.762 7.762 0 00.74 8.5a7.762 7.762 0 007.76 7.76z"
          stroke="#FFF"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.5 4.727v3.564l3.026 1.875"
        />
      </g>
    </svg>
  );
}

function SvgLike(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <path
        d="M11.886 7.378V3.883A2.622 2.622 0 009.265 1.26L5.769 9.126v9.613h9.858a1.748 1.748 0 001.747-1.486l1.206-7.865a1.748 1.748 0 00-1.748-2.01h-4.946zM5.77 18.738H3.148A1.748 1.748 0 011.4 16.992v-6.117c0-.965.783-1.748 1.748-1.748h2.621v9.613z"
        stroke="#FFF"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SvgLikeSelected(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <path
        d="M4.42 8.625v10.613H3.148a2.248 2.248 0 01-2.243-2.093L.9 16.99v-6.117c0-1.19.924-2.164 2.094-2.243l.154-.005H4.42zM9.265.761a3.122 3.122 0 013.116 2.945l.005.177v2.995h4.44a2.248 2.248 0 011.453.51l.147.133.122.13c.394.452.589 1.041.546 1.635l-.02.178-1.205 7.864a2.248 2.248 0 01-2.076 1.906l-.166.005H5.42V8.624l.024.001 3.364-7.568a.5.5 0 01.294-.27l.08-.02.083-.007z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

function SvgChevron(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 9 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#12161A" d="M.5 15.5V.5h8v15z" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.25 1.294l6.5 6.77-6.5 6.771"
        />
      </g>
    </svg>
  );
}

function SvgIconSearch(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 18 18" {...props}>
      <g
        transform="translate(1.417 1.417)"
        stroke="#000"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
      >
        <circle
          strokeLinecap="round"
          strokeLinejoin="round"
          cx={6.319}
          cy={6.319}
          r={6.319}
        />
        <path strokeLinecap="square" d="M15.167 15.167l-4.382-4.382" />
      </g>
    </svg>
  );
}

function SvgIconPlayArrow(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 24" {...props}>
      <path
        d="M3.929 6.24L12.963 12l-9.034 5.76V6.24zM.5 0v24l18.857-12L.5 0z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

function SvgIconEdit(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 18 19" {...props}>
      <path
        d="M0 14.25V18h3.75L14.81 6.94l-3.75-3.75L0 14.25zM2.92 16H2v-.92l9.06-9.06.92.92L2.92 16zM17.71 2.63L15.37.29c-.2-.2-.45-.29-.71-.29-.26 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 000-1.41z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

function SvgIconAdd(props) {
  return (
    <svg width="14px" height="14px" viewBox="0 0 15 14" {...props}>
      <path
        d="M6.885 0v6h-6v2h6v6h2V8h6V6h-6V0z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

function SvgIcon2(props) {
  return (
    <svg
      focusable="false"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      aria-hidden="true"
      {...props}
    >
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
    </svg>
  );
}

function SvgIconAlert(props) {
  return (
    <svg width="26px" height="22px" viewBox="0 0 27 24" {...props}>
      <defs>
        <path
          d="M733.2 5.388L742.236 21h-18.072L733.2 5.388zm0-4.788L720 23.4h26.4L733.2.6zm1.2 16.8H732v2.4h2.4v-2.4zm0-7.2H732V15h2.4v-4.8z"
          id="icon-alert_svg__a"
        />
      </defs>
      <use
        fill="#B00020"
        fillRule="nonzero"
        xlinkHref="#icon-alert_svg__a"
        transform="translate(-720)"
      />
    </svg>
  );
}

function SvgDelete(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 17" {...props}>
      <path
        d="M.929 14.707c0 1.011.835 1.838 1.857 1.838h7.428a1.853 1.853 0 001.857-1.838V3.677H.93v11.03zm1.857-9.192h7.428v9.192H2.786V5.515zM9.75.92L8.821 0H4.18L3.25.92H0v1.838h13V.919H9.75z"
        fill="#12161A"
        fillRule="nonzero"
      />
    </svg>
  );
}

function SvgCross(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 17 13" {...props}>
      <g
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.833 6.013h16M.833.738h16M.833" />
      </g>
    </svg>
  );
}

function SvgDownload(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 14 18" {...props}>
      <path
        d="M14 6.176h-4V0H4v6.176H0l7 7.206 7-7.206zm-8 2.06V2.058h2v6.176h1.17L7 10.47 4.83 8.235H6zM0 15.44h14V17.5H0v-2.059z"
        fill="#12161A"
        fillRule="nonzero"
      />
    </svg>
  );
}

function SvgOverflow(props) {
  return (
    <svg width="16px" height="16px" viewBox="0 0 4 16" {...props}>
      <path
        d="M2 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        fill="#12161A"
        fillRule="nonzero"
      />
    </svg>
  );
}

function SvgLeftArrow(props) {
  return (
    <svg width="14px" height="12px" viewBox="0 0 14 12" {...props}>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g transform="translate(-210.000000, -23.000000)" stroke="#FFFFFF">
          <g transform="translate(167.000000, 17.000000)">
            <g transform="translate(44.000000, 7.000000)">
              <g transform="translate(0.000000, 0.500000)">
                <line x1="12.5" y1="4.75" x2="0" y2="4.75"></line>
                <polyline points="4.6875 9.5 0 4.75 4.6875 0"></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

function SvgTwoWayVideo(props) {
  return (
    <svg width="15px" height="10px" viewBox="0 0 15 10" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-376.000000, -331.000000)">
          <g transform="translate(60.000000, 206.000000)">
            <g transform="translate(226.000000, 60.000000)">
              <g transform="translate(91.461897, 66.000000)">
                <path
                  d="M7.61798422,0 C8.31797453,-1.28586135e-16 8.8854288,0.56745427 8.8854288,1.26744459 L8.8854288,6.73255541 C8.8854288,7.43254573 8.31797453,8 7.61798422,8 L1.26744459,8 C0.56745427,8 -8.2947292e-13,7.43254573 -8.29558644e-13,6.73255541 L-8.29558644e-13,1.26744459 C-8.29644368e-13,0.56745427 0.56745427,1.28586135e-16 1.26744459,0 L7.61798422,0 Z M11.9359157,1.23366799 C12.0252,1.31380119 12.0762055,1.42811474 12.0762055,1.54808561 L12.0762055,6.45191439 C12.0762055,6.6852445 11.887054,6.87439592 11.6537239,6.87439592 C11.5337531,6.87439592 11.4194395,6.82339045 11.3393063,6.73410618 L9.13869771,4.28219179 C8.99461249,4.12165228 8.99461249,3.87834772 9.13869771,3.71780821 L11.3393063,1.26589382 C11.4951565,1.09224576 11.7622677,1.07781777 11.9359157,1.23366799 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export {
  SvgHome,
  SvgInteractive,
  SvgPlay,
  SvgLive,
  SvgMessages,
  SvgBrowse,
  SvgSearch,
  SvgBlog,
  SvgShop,
  SvgProfile,
  SvgMenu,
  SvgClose,
  SvgMyClassThin,
  SvgMessagesNone,
  SvgBack,
  SvgArrowPager,
  SvgFavorite,
  SvgFavoriteSelected,
  SvgShare,
  SvgFilter,
  SvgBookmark,
  SvgBookmarkFilled,
  SvgLoaderGif,
  SvgClock,
  SvgLike,
  SvgLikeSelected,
  SvgChevron,
  SvgIcon,
  SvgIconSearch,
  SvgIconPlayArrow,
  SvgIconEdit,
  SvgIconAdd,
  SvgIconAlert,
  SvgOutlined,
  SvgDelete,
  SvgCross,
  SvgDownload,
  SvgOverflow,
  SvgTwoWayVideo,
  SvgLeftArrow,
  SvgInstructorPlaceholder,
};
