/*
 *
 * PEOPLE constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/People/DEFAULT_ACTION',
  REQUEST_PEOPLE_LIST = 'REQUEST_PEOPLE_LIST',
  PEOPLE_LIST_SUCCESS = 'PEOPLE_LIST_SUCCESS',
  PEOPLE_LIST_FAILED = 'PEOPLE_LIST_FAILED',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  GET_SEARCH_RESULT = 'app/People/GET_SEARCH_RESULT',
  REQUEST_PEOPLE_DETAIL = 'REQUEST_PEOPLE_DETAIL',
  PEOPLE_DETAIL_SUCCESS = 'PEOPLE_DETAIL_SUCCESS',
  PEOPLE_UPDATED_INFO = 'PEOPLE_UPDATED_INFO',
  GET_PEOPLE_CLASSES = 'GET_PEOPLE_CLASSES',
  PEOPLE_CLASSES_SUCCESS = 'PEOPLE_CLASSES_SUCCESS',
  PEOPLE_CLASSES_FAILED = 'PEOPLE_CLASSES_FAILED',
  INSTRUCTOR_DETAILS_LOADING = 'app/People/INSTRUCTOR_DETAILS_LOADING',
  PEOPLE_PACKAGE_SUCCESS = 'PEOPLE_PACKAGE_SUCCESS',
  //for add instructor
  OTHER_DETAIL_SUCCESS = 'OTHER_DETAIL_SUCCESS',
  REQUEST_OTHER_DETAILS = 'REQUEST_OTHER_DETAILS',
  POST_ADD_INSTRUCTOR = 'POST_ADD_INSTRUCTOR',
  PERSON_LOADING = 'PERSON_LOADING',
  PEOPLE_LIST_TOTAL = 'PEOPLE_LIST_TOTAL',
  REQUEST_PEOPLE_LIST_PAGINATION = 'REQUEST_PEOPLE_LIST_PAGINATION',
  PEOPLE_LIST_PAGINATION_SUCCESS = 'PEOPLE_LIST_PAGINATION_SUCCESS',
  PUT_PEOPLE_LIST_PAGINATION_SUCCESS = 'PUT_PEOPLE_LIST_PAGINATION_SUCCESS',
  SET_INTERACTIVE_PEOPLE_ONLY = 'SET_INTERACTIVE_PEOPLE_ONLY',
  RESET_PEOPLE = 'RESET_PEOPLE',
}

export default ActionTypes;
