/*
 *
 * Administrator actions
 *
 */

import { action } from 'typesafe-actions';
import { AdministratorList } from './types';

import ActionTypes from './constants';

//Get Attribute list
export const requestAdministrators = () => {
  return action(ActionTypes.REQUEST_ADMINISTRATOR_LIST);
};

export const requestInstructors = () => {
  return action(ActionTypes.REQUEST_INSTRUCTOR);
};
export const storeInstructorName = (data: any[]) =>
  action(ActionTypes.STORE_INSTRUCTOR_NAME, data);

export const administratorListSuccess = (
  administratorList: AdministratorList,
) => {
  return action(ActionTypes.ADMINISTRATOR_LIST_SUCCESS, administratorList);
};

export const administratorListFailed = () => {
  return action(ActionTypes.ADMINISTRATOR_LIST_FAILED);
};

export const setAdminLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_ADMINISTARTOR_LOADER, isLoading);
};

export const setLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_LOADER, isLoading);
};

export const AddAdministrator = administratorDetailInfo => {
  return action(ActionTypes.SAVE_ADMINSTRATOR_DETAIL, administratorDetailInfo);
};

export const passwordRestAction = (email: string) => {
  return action(ActionTypes.PASSWORD_RESET, email);
};

export const openAdminDrawerAdd = flag => {
  return action(ActionTypes.OPEN_ADMIN_DRAWER_ADD, flag);
};

export const getAdministratorDetail = (id: string) => {
  return action(ActionTypes.FETCH_ADMINISTRATOR_BY_ID, id);
};

export const deleteAdministrator = attributeDetailInfo => {
  return action(ActionTypes.REMOVE_ADMINISTRATOR, attributeDetailInfo);
};
