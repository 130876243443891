import * as Yup from 'yup';
import { validatePermaLink } from '../../../utils/commonFunctions';
const { object, string, number, date, boolean, array } = Yup;

Yup.addMethod(Yup.string, 'checkPermalink', function(errorMessage) {
  return this.test(`test-card-type`, errorMessage, function(value) {
    let check = /^\/videos\//;
    return validatePermaLink(value, check);
  });
});

const ClassSchema = object({
  videoStatus: string(),
  title: string().required('Title is required'),
  permaLink: Yup.string()
    .checkPermalink(`Permalink is required and start with '/videos/'`)
    .required(`Permalink is required and start with '/videos/'`),
  thumbnail: string(),
  flag: string().oneOf(['None', 'New', 'Trending', 'Popular']),
  videoType: string()
    .oneOf(['generic', 'class', 'live'])
    .required(),
  description: string().required('Description is required'),
  instructors: array().when('videoType', {
    is: videoType => videoType === 'live' || videoType === 'class',
    then: array()
      .min(1)
      .required(),
    otherwise: array(),
  }),
  streamType: string().required('streamType is required'),
  genres: array().when('videoType', {
    is: videoType => videoType === 'live' || videoType === 'class',
    then: array()
      .min(1)
      .required(),
    otherwise: array(),
  }),
  airedDate: date().when('videoType', {
    is: videoType => videoType === 'live',
    then: date().required(),
    otherwise: date().nullable(),
  }),
  style: string().oneOf(['Coach to Guests', 'Coach to Camera', 'Class']),
  adminTags: array(),
  classPlan: string(),
  likes: number(),
  concept: string().required('Enter a concept').nullable(),
  language: string().required('Enter a language'),
  difficulty: string()
    .oneOf(['Beginner Basics', 'Beginner', 'Intermediate', 'Advanced'])
    .when('videoType', {
      is: videoType => videoType !== 'generic',
      then: string().required(),
      otherwise: string(),
    }),
  liveStreamUrl: string().when('videoType', {
    is: videoType => videoType === 'live',
    then: string().required('Live stream url is required'),
    otherwise: string().nullable(),
  }),
  duration: number().required(),
  bodyFocus: array().when('videoType', {
    is: videoType => videoType === 'live' || videoType === 'class',
    then: array()
      .min(1)
      .required(),
    otherwise: array().nullable(),
  }),
  music: array().when('videoType', {
    is: videoType => videoType === 'live' || videoType === 'class',
    then: array()
      .min(1)
      .required(),
    otherwise: array().nullable(),
  }),
  equipments: object().shape({
    required: array().when('videoType', {
      is: videoType => videoType === 'live' || videoType === 'class',
      then: array()
        .min(1)
        .required(),
      otherwise: array().nullable(),
    }),
    optional: array(),
  }),
  explicitLang: boolean().required(),
  impact: string().when('videoType', {
    is: videoType => videoType !== 'generic',
    then: string()
      .oneOf(['Low', 'High', 'A2'])
      .required(),
    otherwise: string().nullable(),
  }),
  publishedDate: date().nullable(),
  endurance: number().when('videoType', {
    is: videoType => videoType !== 'generic',
    then: number().required(),
    otherwise: number().nullable(),
  }),
  strength: number().when('videoType', {
    is: videoType => videoType !== 'generic',
    then: number().required(),
    otherwise: number().nullable(),
  }),
  recovery: number().when('videoType', {
    is: videoType => videoType !== 'generic',
    then: number().required(),
    otherwise: number().nullable(),
  }),
  isHidden: boolean(),
  isPublished: boolean(),
});

export { ClassSchema };
