import { takeLatest, call, put, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import Cookies from 'universal-cookie';
import { UserInfo } from './types';
const cookies = new Cookies();

// Individual exports for testing
export default function* rootSaga() {
    request()
}
