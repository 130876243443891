import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the Collection state domain
 */

const selectCollectionDomain = (state: ApplicationRootState) => {
  return state.collection || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Collection
 */

const makeSelectCollection = () =>
  createSelector(
    selectCollectionDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectCollection;
export { selectCollectionDomain };
