import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useDispatch } from 'react-redux';
import saga from '.././saga';
import reducer from '.././reducer';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import moment from 'moment';
import { useStyles } from './styles';
import { storeLegalPage } from '.././actions';

export default function LegalListTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { list } = props;
  const getDate = list => {
    if (list.updatedAt) {
      return <div>{`Updated ${moment(list.updatedAt).format(`L`)}`}</div>;
    }
  };

  useInjectReducer({ key: 'legal', reducer: reducer });
  useInjectSaga({ key: 'legal', saga: saga });

  const onHandleSubmit = event => {
    dispatch(
      storeLegalPage({
        pageName: list.pageName,
        htmlContent: document.getElementsByTagName('textarea')[0].value,
      }),
    );
  };

  return (
    <Paper className={classes.root}>
      <TextareaAutosize
        key={list.htmlContent}
        className={classes.legalTxt}
        aria-label="legal text"
        defaultValue={list.htmlContent}
      />
      <div className={classes.text}>{getDate(list)}</div>
      <Button
        onClick={event => {
          onHandleSubmit(event);
        }}
        variant="contained"
        color="secondary"
        className={classes.newLegal}
        disableRipple={true}
      >
        <div className={classes.saveLegalBtn}>SAVE & PUBLISH CHANGES</div>
      </Button>
    </Paper>
  );
}
