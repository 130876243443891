import { ContentType, ComponentType, Mode } from '../types';
import { object, string, array } from 'yup';

export const ComponentFormSchema = object({
  componentTitle: string().required('Enter a valid title'),
  componentType: string().when(['contentType'], {
    is: contentType => contentType !== ContentType.PromoTray,
    then: string()
      .oneOf(['none', ComponentType.Tray, ComponentType.Carousel, ComponentType.ProgramCarousel])
      .required(),
    otherwise: string(),
  }),
  contentType: string()
    .oneOf([
      ContentType.Concept,
      ContentType.Collections,
      ContentType.Videos,
      ContentType.Instructor,
      ContentType.PromoTray,
    ])
    .required(),
  userSegments: array()
    .min(1, 'Enter atleast one Segment')
    .required(),
  content: object({
    mode: string()
      .oneOf(['none', Mode.ManuallyCurated, Mode.Auto])
      .required(),
    data: array().when(['mode'], {
      is: mode => mode === Mode.ManuallyCurated,
      then: array()
        .of(
          object().shape({
            url: string(),
            imageUri: string(),
          }),
        )
        .min(1, 'Enter atleast one item')
        .max(30, 'Enter atmost 30 item')
        .required(),
      otherwise: array(),
    }),
  }),
});

export const BrowseComponentSchema = object({
  componentTitle: string().required('Enter a valid title'),
  componentType: string().required(),
  componentSection: string().required(),
  content: object({
    mode: string()
      .oneOf(['none', Mode.ManuallyCurated, Mode.Auto])
      .required(),
    data: array().when(['mode'], {
      is: mode => mode === Mode.ManuallyCurated,
      then: array()
        .of(
          object().shape({
            url: string(),
            imageUri: string(),
          }),
        )
        .min(1, 'Enter atleast one item')
        .max(30, 'Enter atmost 30 item')
        .required(),
      otherwise: array(),
    }),
  }),
});

export const PromoComponentSchema = object({
  componentTitle: string().required('Enter a valid title'),
  componentType: string().oneOf([ComponentType.Carousel]),
  contentType: string().oneOf([
    ContentType.Concept,
    ContentType.Collections,
    ContentType.Videos,
    ContentType.Instructor,
    ContentType.Promo,
  ]),
  content: object({
    mode: string().oneOf([Mode.ManuallyCurated]),
    data: array()
      .of(
        object().shape({
          // permaLink: string().required(),
          imgUrl: string().required(),
        }),
      )
      .min(1, 'Enter atleast one content item')
      .max(30, 'Enter atmost 30 item')
      .required(),
  }),
});
