/*
 *
 * BrowseCurate
 *
 */

import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectBrowseCurate from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Button,
  Drawer,
} from '@material-ui/core';
import FilterSelect from './components/FilterSelect';
import { TraySection as TopSectionTray } from '../../components/CurrateTopSection/TopSection';
import request from '../../shared/lib/request';
import {
  requestBrowsePageInfo,
  reorderLowerSectionTrays,
  updateBrowseComponent,
} from './actions';
import { LowerSection } from './types';
import {
  ComponentType,
  ContentType,
  Mode,
  ComponentSection,
} from '../HomeCurate/types';
import { SvgIconAdd } from '../../components/NeIcons';
import { DrawerView } from '../HomeCurate/components/DrawerView';
import PromoComponent from '../HomeCurate/components/PromoComponent';
import { ComponentEditor } from '../HomeCurate/components/ComponentEditor';
import PinnedConceptComponent from '../../components/CurrateSection/PinnedConceptComponent';
import Loader from '../../components/Loader';
import ConfirmDialog from '../../components/ConfirmDialog';
import { openSnackBar } from '../Root/actions';

const stateSelector = createStructuredSelector({
  browseCurate: makeSelectBrowseCurate(),
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeCurateRoot: {
      '& .MuiSelect-selectMenu': {
        textTransform: 'capitalize',
      },
      '& .MuiMenuItem-root': {
        textTransform: 'capitalize',
      },
    },
    root: {
      padding: '0px 0px 16px',
      fontFamily: 'Proxima-Nova-Regular',
      maxWidth: '1440px',
      margin: 'auto',
    },
    head: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    headWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '18px 0px',
      borderBottom: '1px solid #000000',
    },
    margin: {
      margin: theme.spacing(1),
      background: '#12161A',
      width: '70px',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      letterSpacing: '0',
      lineHeight: '20.4px',
      textAlign: 'center',
      minWidth: '85px !important',
      height: '24px !important',
      '&:hover': {
        background: '#12161A',
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    // edit component
    list: {
      width: 650,
    },
    fullList: {
      width: 'auto',
    },
    btnText: {
      color: 'white',
      paddingTop: '0',
    },
    title: {
      padding: '16px 24px',
      color: '#12161A',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '16px',
      lineHeight: '20px',
    },
    closeButton: {
      position: 'absolute',
      paddingTop: '16px',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      float: 'right',
      color: '#000',
    },
    drawerBody: {
      height: 'calc(100vh - 176px)',
      overflow: 'auto',
    },
    drawerFooter: {
      padding: '16px',
      display: 'flex',
      '& .MuiButton-root': {
        marginRight: 8,
      },
    },
    sectionTitle: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: '18px',
      lineHeight: '20px',
    },
    section: {
      marginBottom: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    topSection: {
      margin: '16px 0px',
    },
    drawerPaper: {
      overflow: 'hidden',
    },
  }),
);

interface Props {}

const Filters = [
  { label: 'Concept', value: ComponentSection.Concept },
  { label: 'Collections', value: ComponentSection.Collections },
  { label: 'Programs', value: ComponentSection.Programs },
  { label: 'Challenges', value: ComponentSection.Challenges },
];

const CONTENT_TYPES = [ContentType.Concept];
const COMPONENT_TYPES = [ComponentType.Carousel];

const possibleValues = {
  contentTypes: CONTENT_TYPES.map(i => ({ label: i, value: i })),
  componentTypes: COMPONENT_TYPES.map(i => ({ label: i, value: i })),
  modes: [
    { label: 'Manually Curated', value: Mode.ManuallyCurated },
    { label: 'Auto', value: Mode.Auto },
  ],
  segments: [],
};

const initialFormValue: LowerSection = {
  componentTitle: '',
  componentOrder: {},
  componentType: '',
  contentType: '',
  componentSection: '',
  content: {
    data: [],
    mode: '',
  },
  currentComponentOrder: 0,
  userSegments: [],
};

export enum FormType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

function BrowseCurate(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'browseCurate', reducer: reducer });
  useInjectSaga({ key: 'browseCurate', saga: saga });

  const { browseCurate } = useSelector(stateSelector);
  const {
    concepts,
    collectionsV2,
    program,
    challenge,
    segments,
    isLoading,
  } = browseCurate;
  const dispatch = useDispatch();
  const [pageFilter, setPageFilter] = React.useState(Filters[0].value);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);

  const [possibleOptions, setPossibleOptions] = React.useState({
    componentTypes: possibleValues.componentTypes,
    contentTypes: possibleValues.contentTypes,
    modes: possibleValues.modes,
    segments: possibleValues.segments,
  });

  const [componentFormInfo, setComponentFormInfo] = React.useState({
    isOpen: false,
    type: FormType.ADD,
    item: { ...initialFormValue },
    isPromo: false,
    isRemovable: true,
  });
  const classes = useStyles();

  const onFilterChange = e => {
    setPageFilter(e.target.value);
    setPossibleOptions({
      ...possibleOptions,
      contentTypes:
        e.target.value === 'concept'
          ? [{ label: 'concepts', value: 'concept' }]
          : [{ label: e.target.value, value: e.target.value }],
    });
  };

  const _init = () => {
    dispatch(requestBrowsePageInfo());
  };

  React.useEffect(() => {
    _init();
  }, []);

  const handleLowerSectionListChange = (list: LowerSection) => {
    dispatch(reorderLowerSectionTrays(list));
  };

  const hanldeLowerSectionTrayManage = item => {
    let formValue = { ...item };
    formValue.userSegments = possibleOptions.segments.filter(i =>
      formValue.userSegments.includes(i.title),
    );
    setComponentFormInfo({
      isOpen: true,
      item: { ...initialFormValue, ...formValue },
      type: FormType.EDIT,
      isPromo: item.contentType === ContentType.Promo,
      isRemovable: true,
    });
  };
  const hanldeTopSectionTrayManage = item => {
    let formValue = { ...item };
    formValue.userSegments = possibleOptions.segments.filter(i =>
      formValue.userSegments.includes(i.title),
    );
    setComponentFormInfo({
      isOpen: true,
      item: { ...initialFormValue, ...formValue },
      type: FormType.EDIT,
      isRemovable: formValue.componentSection === 'concept' ? false : true,
      isPromo: item.contentType === ContentType.Promo,
    });
  };

  const handleEditDrawerClose = () =>
    setComponentFormInfo({ isOpen: false, item: { ...initialFormValue } });

  const formData = formValues => {
    try {
      let data = [];
      if (formValues.contentType === ContentType.Promo) {
        data = formValues.content.data.map((i, index) => ({
          permaLink: i.permaLink,
          imgUrl: i.imgUrl,
          order: index + 1,
        }));
      } else if (formValues.componentType === ComponentType.Carousel) {
        data = formValues.content.data.map((i, index) => ({
          id: i.id,
          text: i.text,
          order: index,
        }));
      } else {
        data = formValues.content.data.map((i, index) => ({
          id: i.id,
          order: index + 1,
        }));
      }
      let userSegments = formValues.userSegments.map(i => i.title);
      let componentOrder = {};
      userSegments.forEach(element => {
        componentOrder[element] = formValues.currentComponentOrder;
      });
      let requestBody = {
        pageName: 'browse',
        ...formValues,
        content: {
          ...formValues.content,
          data: data,
        },
        contentType: pageFilter === 'concept' ? 'concept' : 'collections',
        userSegments: formValues.userSegments.map(i => i.title),
        componentSection:
          formValues.contentType === 'concept'
            ? 'concept'
            : pageFilter === 'collections'
            ? 'interest'
            : pageFilter,
        componentOrder: componentOrder || formValues.componentOrder,
      };
      delete requestBody.currentComponentOrder;
      return requestBody;
    } catch (error) {
      return formValues;
    }
  };

  const handleEditFormSubmit = (formValues, type) => {
    const data = formData(formValues);
    dispatch(updateBrowseComponent(data, componentFormInfo.type));
    handleEditDrawerClose();
  };

  const deleteComponent = async ({
    componentTitle,
    componentId,
    contentType,
    componentSection,
  }) => {
    let requestBody = {
      pageName: 'browse',
      componentId: componentId,
      componentSection:
        contentType === 'concept' ? 'concept' : componentSection,
      deleteComponent: true,
    };
    try {
      let { result } = await request({
        url: '/neouadmin/v2/pages/',
        method: 'PUT',
        data: requestBody,
      });
      setComponentFormInfo({ isOpen: false, item: initialFormValue });
      _init();
    } catch (error) {
      dispatch(openSnackBar('Failed to save component'));
    }
  };

  React.useEffect(() => {
    let segmentOptions = segments || possibleValues.segments;
    setPossibleOptions({
      ...possibleOptions,
      segments: segmentOptions,
    });
    setComponentFormInfo({
      ...componentFormInfo,
      item: {
        ...componentFormInfo.item,
        userSegments: segmentOptions,
      },
    });
  }, [segments]);

  const handlePromoEdit = async values => {
    try {
      let { result } = await request({
        url: '/neouadmin/v2/pages/',
        method: 'PUT',
        data: formData(values),
      });
      setComponentFormInfo({
        type: '',
        isOpen: false,
        item: initialFormValue,
        isPromo: false,
        isRemovable: false,
      });
    } catch (error) {
      dispatch(openSnackBar('Failed to update component'));
    }
  };

  const getTopTrayItem = pageFilter => {
    switch (pageFilter) {
      case 'program':
        return (
          program &&
          program.topSection.map((item, index) => (
            <TopSectionTray
              key={`top_section_${index}`}
              item={item}
              handleManage={() => hanldeTopSectionTrayManage(item)}
              carouselType={item.componentSection}
            />
          ))
        );
      case 'challenge':
        return (
          challenge &&
          challenge.topSection.map((item, index) => (
            <TopSectionTray
              key={`top_section_${index}`}
              item={item}
              handleManage={() => hanldeTopSectionTrayManage(item)}
              carouselType={item.componentSection}
            />
          ))
        );
      case 'collections':
        return (
          collectionsV2 &&
          collectionsV2.topSection.map((item, index) => (
            <TopSectionTray
              key={`top_section_${index}`}
              item={item}
              handleManage={() => hanldeTopSectionTrayManage(item)}
              carouselType={item.componentSection}
            />
          ))
        );
      case 'concept':
        return (
          concepts &&
          concepts.topSection &&
          concepts.topSection.map((item, index) => (
            <TopSectionTray
              key={`top_section_${index}`}
              item={item}
              handleManage={() => hanldeTopSectionTrayManage(item)}
              carouselType={item.componentSection}
            />
          ))
        );
      default:
        return (
          collectionsV2 &&
          collectionsV2.topSection.map((item, index) => (
            <TopSectionTray
              key={`top_section_${index}`}
              item={item}
              handleManage={() => hanldeTopSectionTrayManage(item)}
              carouselType={item.componentSection}
            />
          ))
        );
    }
  };

  const getLowerTrayItem = pageFilter => {
    switch (pageFilter) {
      case 'program':
        return (
          program &&
          program.lowerSection &&
          program.lowerSection.length > 0 && (
            <PinnedConceptComponent
              title={'PROGRAMS'}
              type={pageFilter}
              pinnedTray={program.lowerSection}
              handleListChange={list => {
                dispatch(requestBrowsePageInfo());
              }}
            />
          )
        );
      case 'challenge':
        return (
          challenge &&
          challenge.lowerSection &&
          challenge.lowerSection.length > 0 && (
            <PinnedConceptComponent
              title={'CHALLENGES'}
              pinnedTray={challenge.lowerSection}
              type={pageFilter}
              handleListChange={list => {
                dispatch(requestBrowsePageInfo());
              }}
            />
          )
        );
      case 'collections':
        return (
          collectionsV2 &&
          collectionsV2.lowerSection &&
          collectionsV2.lowerSection.length > 0 && (
            <PinnedConceptComponent
              title={'COLLECTIONS'}
              pinnedTray={collectionsV2.lowerSection}
              type={pageFilter}
              handleListChange={list => {
                dispatch(requestBrowsePageInfo());
              }}
            />
          )
        );
      case 'concept':
        return (
          concepts &&
          concepts.lowerSection &&
          concepts.lowerSection.length > 0 && (
            <PinnedConceptComponent
              title={'CONCEPTS'}
              pinnedTray={concepts.lowerSection}
              type={pageFilter}
              handleListChange={list => {
                dispatch(requestBrowsePageInfo());
              }}
            />
          )
        );
      default:
        return (
          collectionsV2 &&
          collectionsV2.lowerSection &&
          collectionsV2.lowerSection.length > 0 && (
            <PinnedConceptComponent
              title={'COLLECTIONS'}
              pinnedTray={collectionsV2.lowerSection}
              type={pageFilter}
              handleListChange={list => {
                dispatch(requestBrowsePageInfo());
              }}
            />
          )
        );
    }
  };

  return (
    <div>
      <Helmet>
        <title>Curate Browse</title>
        <meta name="description" content="Description of BrowseCurate" />
      </Helmet>
      {isLoading && <Loader />}
      <Box p={2}>
        <div className={classes.root}>
          <div className={classes.headWrap}>
            <div className={classes.head}>Curate: Browse Page</div>
            <FilterSelect
              label="Section :"
              onChange={onFilterChange}
              value={pageFilter}
              items={Filters}
            />
          </div>

          <div className={classes.topSection}>
            <div className={classes.section}>
              <Box my={2}>
                <Typography
                  classes={{
                    root: classes.sectionTitle,
                  }}
                >
                  TOP SECTION
                </Typography>
              </Box>
              {pageFilter !== 'concept' && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.addBtn}
                  disableRipple={true}
                  startIcon={<SvgIconAdd />}
                  onClick={() => {
                    setComponentFormInfo({
                      isOpen: true,
                      type: FormType.ADD,
                      item: {
                        ...initialFormValue,
                        userSegments: [...possibleOptions.segments],
                      },
                      isRemovable: true,
                      isPromo: false,
                    });
                  }}
                >
                  COMPONENT
                </Button>
              )}
            </div>
            {getTopTrayItem(pageFilter)}
          </div>

          <div>{getLowerTrayItem(pageFilter)}</div>
          <Drawer
            anchor={'right'}
            open={componentFormInfo.isOpen && componentFormInfo.isPromo}
            disableBackdropClick={true}
            onClose={() => showAlertToClose(true)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <ConfirmDialog
              isOpen={isCloseAlertOpen}
              headerText="Are you sure?"
              bodyText="You are about to close the component."
              onAgree={() => handleEditDrawerClose()}
              handleClose={() => showAlertToClose(false)}
              onDisagree={() => showAlertToClose(false)}
            ></ConfirmDialog>

            <DrawerView
              handleClose={showAlertToClose}
              title="EDIT PROMO COMPONENT"
              key={'promoCOmponent'}
              removable={
                componentFormInfo.type === FormType.EDIT &&
                !componentFormInfo.isPromo
              }
            >
              <PromoComponent
                defaultValue={componentFormInfo.item}
                onSubmit={handlePromoEdit}
              />
            </DrawerView>
          </Drawer>

          <Drawer
            anchor={'right'}
            open={componentFormInfo.isOpen && !componentFormInfo.isPromo}
            disableBackdropClick={true}
            onClose={() => showAlertToClose(true)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <ConfirmDialog
              isOpen={isCloseAlertOpen}
              headerText="Are you sure?"
              bodyText="You are about to close the component."
              onAgree={() => handleEditDrawerClose()}
              handleClose={() => showAlertToClose(false)}
              onDisagree={() => showAlertToClose(false)}
            ></ConfirmDialog>

            <DrawerView
              handleClose={showAlertToClose}
              type={componentFormInfo.type}
              removable={
                componentFormInfo.type === FormType.EDIT &&
                !componentFormInfo.isPromo
              }
              title={`${componentFormInfo.type} COMPONENT`}
            >
              <ComponentEditor
                componentFormInfo={componentFormInfo}
                possibleOptions={possibleOptions}
                handleEditFormSubmit={handleEditFormSubmit}
                handleComponentDelete={deleteComponent}
                isBrowse={true}
              />
            </DrawerView>
          </Drawer>
        </div>
      </Box>
    </div>
  );
}

export default memo(BrowseCurate);
