import React, { useState, useEffect } from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { date, number } from 'yup';
import FormikField from '../../../components/Formik/FormikField';
import FormikSelect from '../../../components/Formik/FormikSelect';
import {
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormikAutocomplete from '../../../components/Formik/FormikAutocomplete';
import FormikImageUploader from '../../../components/Formik/FormikImageUploader';
import request from '../../../shared/lib/request';
import { openSnackBar } from '../../Root/actions';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { campaignEvent } from '../../../validators/Authentication';
import { formError, customMessage } from '../../../utils/validator';
import {
  StatusLabel,
  StatusText,
  HistoryText,
} from '../../../components/Typography';
import Loader from '../../../components/Loader';
import moment from 'moment';
import { slugify } from '../../../utils/slugify';
import { ClassSchema } from './ClassSchema';
import {
  requestInteractiveVideoList,
  storeClassDetailPreview,
  requestClientList,
  requestUpcomingCalenderList,
  sendCampaignEmailAction,
  requestClientsJoinedList,
} from '../actions';
import { trackSegmentEvents } from '../../../utils/Analytics';
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';
import makeSelectInteractivePublishedClasses from '../selectors';
import StartDateTime from '../../../components/Formik/StartDateTime';
import { GreenCheckbox } from '../../../components/GreenCheckbox';
import { NEOUS_APP_URL, appApiURL } from '../../../utils/config';
import ShareURL from './shareURL';
import StyledTabs, {
  StyledTab,
} from '../../../components/VideoList/StylesTabs';
import ClientList from '../../MyClassDetail/ClientList';
import { useStyles } from '../../MyClassDetail';

const dialogStyles = () =>
  createStyles({
    clientLists: {
      width: '1060px',
      height: '583px',
    },
    emptyListText: {
      color: '#909599',
      fontSize: '17px',
      fontFamily: 'Proxima-Nova-Regular',
      fontStyle: 'italic',
      paddingTop: '50px',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0',
      textAlign: 'center',
    },
    styledTab: {
      marginBottom: '20px',
      '& .MuiTab-root': {
        maxWidth: '100%',
        width: '33.33%',
      },
      '& .MuiTabs-centered': {
        justifyContent: 'space-around',
        borderTop: '1px solid #000000',
      },
    },
    title: {
      display: 'flex',
      width: '330px',
      justifyContent: 'space-between',
    },
    checkBox: {
      paddingBottom: '15px',
    },
    dialogContainer: {
      borderRadius: 0,
      maxWidth: '1080px',
    },
    videoHeader: {},
    form: {
      // maxWidth: '33.33%',
    },
    dialogContent: {
      padding: '24px 16px 72px',
    },
    dialogActions: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      background: '#fff',
      border: '1px solid #D9D9D9',
      margin: '0px -16px',
      justifyContent: 'space-between',
      display: 'flex',
      zIndex: 140000,
      '& .MuiButton-textSecondary': {
        minWidth: '90px',
        padding: '0 18px',
      },
      '& .MuiButton-containedSecondary': {
        minWidth: '175px',
      },
    },
    wrapGrid: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    wrapButton: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'auto',
    },
    publish: {
      marginRight: '5px',
    },
    cancelButton: {
      background: '#D0021B',
      color: '#FFFFFF',
      minWidth: 'auto',
      '&:hover': {
        background: '#D0021B',
        color: '#FFFFFF',
        minWidth: 'auto',
      },
    },
    privateMessage: {
      color: '#909599',
      fontSize: '14px',
      fontFamily: 'Proxima-Nova-Regular',
      marginBottom: '10px',
    },
    viewPackages: {
      textTransform: 'capitalize',
      color: '#12161A',
      marginTop: '10px',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 10px',
      height: '24px',
    },
    classURLGrid: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    disabled: {
      fontFamily: 'ProximaNova-Bold',
      cursor: 'pointer',
      //background: '#e0e0e0',
      color: '#12161A',
      margin: '0px 4px',
      minHeight: '40px',
      lineHeight: '24px',
      borderRadius: '6px',
      letterSpacing: '1.15px',
      textTransform: 'capitalize',
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
    },
  });

const initialFormValues = {
  videoStatus: 'active',
  instructors: [],
  genres: [],
  bodyFocus: [],
  music: [],
  adminTags: [],
  likes: 0,
  title: '',
  interactive: {
    type: '2-way video',
    spots: number,
    spotsLeft: number,
    isCancelled: false,
    flagged: false,
    inReview: false,
    bannerImg: '',
    dropInPrice: '',
    isPrivate: false,
    restrictedToEmails: '',
    partnerId: '',
  },
  description: '',
  videoType: 'class',
  concept: '',
  classPlan: '',
  thumbnail: '',
  permaLink: '/classes/',
  classURL: `${NEOUS_APP_URL}/neou/neous/classes/classes/`,
  fileName: '',
  liveStreamUrl: '',
  duration: '',
  difficulty: 'Beginner',
  platform: 'zoom',
  equipments: {
    optional: [],
    required: [],
  },
  isNewClassNotification: false,
  enterUrl: '',
  flag: '',
  isPublished: false,
  dataURL: '',
  explicitLang: false,
  isHidden: false,
  impact: '',
  contentId: '',
  startDate: date,
  airedDate: date,
  publishedDate: date,
};

const initialPossibleOptions = {
  concepts: [],
  interactiveInstructors: [],
  genres: [],
  bodyFocus: [],
  equipments: [],
  musics: [],
  flags: [{ label: 'None', value: 'None' }],
  impact: [{ label: 'low', value: 'low' }],
  // streamType: [{ label: 'live', value: 'live' }],
  videoType: [],
  difficulty: [],
  platform: [],
  duration: [],
  numberValues: [],
  interactiveVideoType: [
    { value: 'groupInteractive', label: 'Group Interactive' },
  ],
  airedDate: moment().format('YYYY-MM-DDTH H:mm'),
};

const stateSelector = createStructuredSelector({
  interactiveClass: makeSelectInteractivePublishedClasses(),
});

const GroupInteractive = ({
  id,
  isOpen,
  title,
  classes,
  handleClose,
  isReviewTab,
}) => {
  const [isLoading, setLoader] = React.useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [error, setError] = React.useState({
    campaignId: '',
  });
  const [campaignId, setClassTitle] = React.useState('');
  const [possibleOptions, setPossibleOptions] = useState(
    initialPossibleOptions,
  );
  const [isConfirmPublishOpen, openConfirmPublishDialog] = React.useState(
    false,
  );
  const [
    isNotificationAlertOpen,
    showAlertToCloseNotification,
  ] = React.useState(false);
  const [isEnableNotification, setEnableNotification] = React.useState(false);
  const [isEmail, setEmail] = React.useState(false);
  const [isHideAlertOpen, showAlertToHide] = React.useState(false);
  const [isFlagAlertOpen, showAlertToFlag] = React.useState(false);
  const [isCloneDialogueOpen, setCloneDialogueOpen] = React.useState(false);
  const [isDropInPriceAlertOpen, showAlertToDropInPrice] = React.useState(
    false,
  );
  const [isPrivateClassAlertOpen, showAlertPrivateClass] = React.useState(
    false,
  );
  const [isConfirmSubmit, setConfirmSubmit] = React.useState(false);
  const [isCancelAlertOpen, showAlertToCancel] = React.useState(false);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [savedFormData, setSavedFormData] = React.useState({});
  const [isPublish, setPublish] = React.useState(false);
  const [isFlagged, setFlagged] = React.useState(false);
  const [isCompleted, setCompleted] = React.useState(false);
  const [onReview, setReview] = React.useState(false);
  const [onReviewToPublish, setReviewToPublish] = React.useState(false);
  const { interactiveClass } = useSelector(stateSelector);
  const { clients = [], clientsJoined = [] } = interactiveClass;
  const [activeTab, setActiveTab] = React.useState(0);
  const styles = useStyles();
  const [isStartClass, setStartClass] = React.useState(false);
  const [classId, setClassId] = React.useState('');
  const [isPrivateClass, setisPrivateClass] = React.useState(false);

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const tabConfig = [
    {
      label: 'INTERACTIVE',
      component: activeTab === 0 && <div></div>,
    },
    {
      label: `PARTICIPANTS BOOKED (${clients !== [] ? clients.length : 0})`,
      component: activeTab === 1 && (
        <ClientList styles={styles} clients={clients} />
      ),
    },
    {
      label: `PARTICIPANTS JOINED (${
        clientsJoined !== [] ? clientsJoined.length : 0
      })`,
      component: activeTab === 2 && (
        <ClientList styles={styles} clients={clientsJoined} />
      ),
    },
  ];

  const dispatch = useDispatch();
  const _fetchVideoById = () =>
    request({
      url: `/neouadmin/v1/videos/${id}`,
      method: 'GET',
    });

  const _formInitialValues = (possibleValues, result) => {
    let {
      videoType = [],
      difficulty = [],
      platform = [],
      duration = [],
    } = possibleValues;
    setClassId(result.id);
    let possibleFlags =
      possibleValues.flags && possibleValues.flags.length > 0
        ? possibleValues.flags.map(flag => ({
            label: flag.title,
            value: flag.title,
          }))
        : [];
    let flags = [{ label: 'None', value: 'None' }, ...possibleFlags];
    let possibleOptions = {
      concepts: possibleValues.concepts || [],
      interactiveInstructors: possibleValues.interactiveInstructors
        ? possibleValues.interactiveInstructors.map(i => ({
            id: i.id,
            title: `${i.firstName} ${i.lastName}`,
          }))
        : [],
      genres: possibleValues.genres || [],
      bodyFocus: possibleValues.bodyFocus || [],
      equipments: possibleValues.Equipments || [],
      musics: possibleValues.musics || [],
      flags: flags,
      impact:
        possibleValues.impact && possibleValues.impact.length > 0
          ? possibleValues.impact.map(i => ({ label: i.title, value: i.title }))
          : [],

      videoType: [{ value: 'groupInteractive', label: 'Group Interactive' }],
      difficulty:
        difficulty && difficulty.length > 0
          ? difficulty.map(v => ({ label: v.title, value: v.title }))
          : [],
      platform: [
        { label: 'Default (Zoom)', value: 'zoom' },
        //{ label: 'Signalwire', value: 'signalwire' },
      ],
      duration:
        duration && duration.length > 0
          ? duration.map(d => ({ label: d.title, value: d.title }))
          : [],
      numberValues: [],
    };
    for (let i = 0; i < 6; i++) {
      possibleOptions.numberValues.push({ label: i, value: i });
    }
    let { permaLink, startDate } = result;
    if (startDate) {
      result.airedDate = moment(+startDate).format('YYYY-MM-DDTHH:mm');
    }
    const currentTime = moment(Date.now());
    if (result.isPublished) {
      const startTime = moment(new Date(+parseInt(result.startDate)));
      result.isStarted = startTime.diff(currentTime) <= 30 * 1000 * 60;
      const endTime = moment(new Date(+parseInt(result.startDate))).add(
        result.duration,
        'minutes',
      );
      result.isEnded = endTime.diff(currentTime) <= 1 * 1000 * 60;
    }
    let videoInfo = {
      ...result,
      flag: !result.flag ? 'None' : result.flag,
      interactive: {
        dropInPrice: `$${
          result.interactive && result.interactive.dropInPrice
            ? `${result.interactive.dropInPrice
                .toString()
                .slice(
                  0,
                  -2,
                )}.${result.interactive.dropInPrice.toString().substr(-2)}`
            : `0.00`
        }`,
        spots: result.interactive.spots,
        bannerImg: result.interactive.bannerImg,
        spotsLeft: result.interactive.spotsLeft,
        isCancelled: result.interactive.isCancelled,
        flagged: result.interactive.flagged,
        inReview: result.interactive.inReview,
        isPrivate:
          result.interactive && result.interactive.isPrivate
            ? result.interactive.isPrivate
            : false,
        restrictedToEmails:
          result.interactive && result.interactive.restrictedToEmails
            ? result.interactive.restrictedToEmails
            : '',
        partnerId:
          result.interactive && result.interactive.partnerId
            ? result.interactive.partnerId
            : '',
      },
      platform:
        result.interactive && result.interactive.meetingType
          ? result.interactive.meetingType
          : 'zoom',
      enterUrl: result.interactive && result.interactive.hostURL,
      isPublished: result.isPublished,
      videoType: 'groupInteractive',
      permaLink:
        permaLink && permaLink.includes('http') ? '/classes/' : permaLink,
      classURL:
        permaLink && permaLink.includes('http')
          ? `${NEOUS_APP_URL}/neou/neous/classes/classes/`
          : `${NEOUS_APP_URL}/neou/neous/classes${permaLink}`,
      title: result.title && result.title.includes('http') ? '' : result.title,
      instructors: result.instructors
        ? result.instructors.map(i => ({
            id: i.id,
            title: `${i.firstName} ${i.lastName}`,
          }))
        : [],
      equipments: {
        required:
          result.equipments &&
          result.equipments.required &&
          result.equipments.required.length > 0
            ? result.equipments.required
            : [],
        optional:
          result.equipments &&
          result.equipments.optional &&
          result.equipments.optional.length > 0
            ? result.equipments.optional
            : [],
      },
    };
    if (videoInfo && videoInfo.interactive && videoInfo.interactive.partnerId) {
      setisPrivateClass(true);
    }
    return { possibleOptions, videoInfo };
  };

  const _formRequestBody = (formData, clone?) => {
    if (formData.title != '' && formData.permaLink == '/classes/') {
      formData.permaLink = `/classes/${formData.title}`;
      formData.classURL = `${NEOUS_APP_URL}/neou/neous/classes/classes/${formData.title}`;
    }
    if (formData.airedDate) {
      if (isPublish || formData.isPublished) {
        let currentTime = moment(Date.now());
        let startTime = moment(formData.airedDate);
        let isStarted = startTime.diff(currentTime) >= 30000;
        if (!isStarted && !clone) {
          dispatch(openSnackBar('Interactive class should have future time'));
          return;
        }
      }
      formData.startDate = moment(formData.airedDate)
        .utc()
        .valueOf()
        .toString();
    }

    const data = {
      ...formData,
      contentId: formData.id,
      duration: formData.duration,
      videoType: 'groupInteractive',
      interactive: {
        type: '2-way video',
        spots: parseInt(formData.interactive.spots),
        dropInPrice: parseInt(
          formData.interactive.dropInPrice.replace(/[^0-9 ]/g, ''),
        ),
        isCancelled: formData.interactive.isCancelled,
        flagged: formData.interactive.flagged,
        inReview: formData.interactive.inReview,
        bannerImg: formData.interactive.bannerImg,
        isPrivate: formData.interactive.isPrivate,
        restrictedToEmails: formData.interactive.restrictedToEmails,
        meetingType: formData.platform,
        hostURL: formData.enterUrl,
        partnerId: formData.interactive.partnerId,
      },
      flag: formData.flag === 'None' ? '' : formData.flag,
      instructors: formData.instructors.map(i => i.id),
      genres: formData.genres.map(i => i.id),
      bodyFocus: formData.bodyFocus.map(i => i.id),
      music: formData.music.map(i => i.id),
      concept: (formData.concept && formData.concept.id) || '',
      equipments: {
        required:
          formData.equipments && formData.equipments.required
            ? formData.equipments.required.map(i => i.id)
            : [],
        optional:
          formData.equipments && formData.equipments.optional
            ? formData.equipments.optional.map(i => i.id)
            : [],
      },
    };
    if (data && data.interactive && data.interactive.isPrivate) {
      if (isPrivateClass) {
        delete data.interactive.restrictedToEmails;
      } else if (!isPrivateClass) {
        delete data.interactive.partnerId;
      }
    }
    delete formData.fileName;
    delete formData.style;
    return data;
  };

  const saveVideo = async (formData, msg) => {
    try {
      const data = formData || savedFormData;

      // if (
      //   !isConfirmSubmit &&
      //   data &&
      //   data.interactive &&
      //   data.interactive.dropInPrice === 0
      // ) {
      //   setConfirmSubmit(true);
      //   showAlertToDropInPrice(true);
      //   return;
      // }
      if (isPublish) {
        data.isPublished = true;
        if (isEnableNotification) {
          data.isNewClassNotification = true;
        }
      } else if (onReviewToPublish) {
        data.isPublished = Boolean(true);
        data.interactive.inReview = Boolean(false);
        data.interactive.flagged = Boolean(false);
      }
      delete data.interactive.hostURL;
      delete data.platform;
      delete data.enterUrl;
      delete data.classURL;
      await request({
        url: '/neouadmin/v1/videos',
        method: 'PUT',
        data: data || savedFormData,
      });
      setLoader(false);
      dispatch(
        openSnackBar(msg ? msg : 'The class has been saved successfully!'),
      );
      dispatch(requestUpcomingCalenderList());
      dispatch(requestInteractiveVideoList());
      handleClose();
    } catch ({ data: error }) {
      setLoader(false);
      if (error.error) {
        dispatch(openSnackBar(error.error));
      } else {
        dispatch('Error in updating the class..');
      }
    }
  };

  const _init = async videoId => {
    try {
      let { responseCode, result, ...possibleValues } = await _fetchVideoById(
        videoId,
      );
      const { possibleOptions, videoInfo } = _formInitialValues(
        possibleValues,
        result,
      );
      dispatch(storeClassDetailPreview(result));
      setCompleted(interactiveClass.activeTab.includes('isCompleted=true'));
      setFormValues({ ...initialFormValues, ...videoInfo });
      setPossibleOptions({ ...initialPossibleOptions, ...possibleOptions });
      dispatch(requestClientList(videoId));
      dispatch(requestClientsJoinedList(videoId));
    } catch (error) {
      dispatch(openSnackBar('Error in fetching the video details'));
    }
  };

  //Clone class
  const CloneVideo = values => {
    let body = _formRequestBody(values, true);
    body.isHidden = Boolean(false);
    body.isPublished = Boolean(false);
    body.isError = Boolean(false);
    body.permaLink =
      '/classes/' +
      slugify(values.title) +
      '/' +
      Math.floor(Math.random() * 10000000000).toString();
    body.startDate = moment().valueOf();
    delete body.id;
    delete body.interactive.isCancelled;
    delete body.interactive.inReview;
    delete body.interactive.instrNotJoined;
    delete body.interactive.isCompleted;
    delete body.interactive.spotsLeft;
    delete body.interactive.meetingType;
    delete body.interactive.restrictedToEmails;
    delete body.interactive.hostURL;
    delete body.interactive.meetingURL;
    delete body.interactive.partnerId;

    addClonedClass({ ...body });
  };
  const addClonedClass = async data => {
    try {
      await request({
        url: `/neouadmin/v1/videos`,
        method: 'POST',
        data: data,
      });
      dispatch(openSnackBar('The class has been cloned successfully!'));
      dispatch(requestInteractiveVideoList());
      handleClose();
    } catch ({ data: error }) {
      if (error.error) {
        dispatch(openSnackBar(`Failed to clone class -${error.error}`));
        handleClose();
      } else {
        dispatch(openSnackBar('Failed to clone class'));
        handleClose();
      }
    }
  };

  const _handleSubmit = values => {
    let body = _formRequestBody(values);
    if (isPublish) {
      if (body.isHidden) {
        body.isHidden = Boolean(false);
        body.isPublished = Boolean(true);
        body.interactive.isCancelled = Boolean(false);
        setSavedFormData({ ...body });
        saveVideo(body, 'The class has been published successfully!');
      } else {
        body.isHidden = Boolean(false);
        body.isPublished = Boolean(true);
        body.interactive.isCancelled = Boolean(false);
        setSavedFormData({ ...body });
        showAlertToCloseNotification(true);
      }
    } else if (isFlagged) {
      showAlertToFlag(true);
    } else if (onReview) {
      body.isPublished = Boolean(false);
      body.interactive.inReview = Boolean(true);
      body.interactive.flagged = Boolean(false);
      setSavedFormData({ ...body });
      saveVideo(body, 'The class has been sent to review successfully!');
    } else if (onReviewToPublish) {
      body.isPublished = Boolean(true);
      body.interactive.inReview = Boolean(false);
      body.interactive.flagged = Boolean(false);
      setSavedFormData({ ...body });
      saveVideo(body, 'The class has been published successfully!');
    } else {
      body.isHidden = Boolean(body.isHidden);
      body.interactive.isCancelled = Boolean(body.interactive.isHidden);
      body.interactive.flagged = Boolean(body.interactive.flagged);
      body.interactive.inReview = Boolean(body.interactive.inReview);
      body.isPublished = Boolean(body.isPublished);
      setSavedFormData({ ...body });
      if (values.isPublished) {
        openConfirmPublishDialog(true);
      } else {
        saveVideo(body, 'The class has been saved successfully!');
      }
    }
  };

  const hideVideo = async (
    values,
    {
      submitForm,
      errors,
      isValid,
      validateForm,
      setErrors,
      setFieldValue,
      setTouched,
    },
  ) => {
    let f = _formRequestBody(values);
    f.isHidden = Boolean(true);
    f.isPublished = Boolean(false);
    f.interactive.isCancelled = Boolean(false);
    delete f.style;
    delete savedFormData.style;
    setSavedFormData({ ...f });
    setLoader(true);
    try {
      await request({
        url: '/neouadmin/v1/videos',
        method: 'PUT',
        data: f || savedFormData,
      });
      setLoader(false);
      dispatch(openSnackBar('The Class has been hidden successfully!'));
      dispatch(requestUpcomingCalenderList());
      dispatch(requestInteractiveVideoList());
      handleClose();
    } catch ({ data: error }) {
      setLoader(false);
      dispatch(openSnackBar(`Failed to hide class - ${error.error}`));
    }
  };

  const flagVideo = async (
    values,
    {
      submitForm,
      errors,
      isValid,
      validateForm,
      setErrors,
      setFieldValue,
      setTouched,
    },
  ) => {
    let f = _formRequestBody(values);
    f.isHidden = Boolean(false);
    f.isPublished = Boolean(false);
    f.interactive.isCancelled = Boolean(false);
    f.interactive.flagged = Boolean(true);
    f.interactive.inReview = Boolean(false);
    setSavedFormData({ ...f });
    setLoader(true);
    try {
      await request({
        url: '/neouadmin/v1/videos',
        method: 'PUT',
        data: f || savedFormData,
      });
      setLoader(false);
      dispatch(openSnackBar('The Class has been flagged successfully!'));
      dispatch(requestUpcomingCalenderList());
      dispatch(requestInteractiveVideoList());
      handleClose();
    } catch ({ data: error }) {
      setLoader(false);
      dispatch(openSnackBar(`Failed to flag class - ${error.error}`));
    }
  };

  const cancelClass = async (
    values,
    {
      submitForm,
      errors,
      isValid,
      validateForm,
      setErrors,
      setFieldValue,
      setTouched,
    },
  ) => {
    let f = _formRequestBody(values);
    f.isHidden = Boolean(false);
    f.isPublished = Boolean(false);
    f.interactive.isCancelled = Boolean(true);
    setSavedFormData({ ...f });
    setLoader(true);
    let apiUrl = '';
    if (!isEmail) {
      apiUrl = `/neouadmin/v1/cancel-class?videoId=${id}`;
    } else {
      apiUrl = `/neouadmin/v1/cancel-class?videoId=${id}&sendMailToParticipants=true`;
    }
    try {
      let { message } = await request({
        url: apiUrl,
        method: 'GET',
      });
      setLoader(false);
      dispatch(requestUpcomingCalenderList());
      dispatch(requestInteractiveVideoList());
      handleClose();

      let startDate = moment(parseInt(f.startDate))
        .utc()
        .valueOf();
      let endDate = moment(parseInt(f.startDate))
        .utc()
        .valueOf();

      const end = moment(endDate)
        .utc()
        .add(f.duration, 'minutes')
        .valueOf();

      let classStartTime = moment(new Date(startDate));
      let currentTime = moment(Date.now());
      let canCancel = classStartTime.diff(currentTime, 'hours') >= 4;
      dispatch(openSnackBar('The Class has been cancelled successfully!'));
      // if (message && typeof message === 'string') {
      //   dispatch(openSnackBar(message));
      // } else {
      //   dispatch(openSnackBar('The Class has been cancelled successfully!'));
      // }

      const eventName = 'Interactive Class Cancel';
      const eventObj = {
        class_id: id,
        class_title: f.title,
        instructor_id:
          values.instructors &&
          values.instructors.length > 0 &&
          values.instructors[0].id,
        instructor_name:
          values.instructors &&
          values.instructors.length > 0 &&
          values.instructors[0].title,
        concept_id: values.concept && values.concept.id,
        concept_name: values.concept && values.concept.title,
        start_time_utc: new Date(startDate).toISOString(),
        start_time_for_email: String(
          moment(startDate).format('YYYY-MM-DD HH:mm:ss ZZ'),
        ),
        end_time_utc: new Date(end).toISOString(),
        end_time_for_email: String(
          moment(end).format('YYYY-MM-DD HH:mm:ss ZZ'),
        ),
        booking_timezone: DateTime.local().zoneName,
        duration: f.duration,
        dropInPrice: f.interactive && f.interactive.dropInPrice,
        class_permalink: f.permaLink,
        required_equipment: values.equipments && values.equipments.required,
        optional_equipment: values.equipments && values.equipments.optional,
        user_cancel: false,
        genre: values.genres,
        instructor_cancel: false,
        refundable: Boolean(canCancel),
        admin_cancel: true,
      };
      trackSegmentEvents(eventName, eventObj);
    } catch ({ data: error }) {
      setLoader(false);
      if (error && error.error && error.error !== {}) {
        dispatch(
          openSnackBar(
            error.error.message
              ? error.error.message
              : 'Error in cancelling the class..',
          ),
        );
      } else {
        dispatch(openSnackBar('Error in cancelling the class..'));
      }
    }
  };

  const validateCampaign = () => {
    const { error, value } = campaignEvent.validate(
      { campaignId },
      {
        messages: customMessage,
      },
    );
    return formError(error);
  };

  const onCampaign = () => {
    const e: any = validateCampaign();
    setError(e);
    if (Object.keys(e).length === 0) {
      const data = {
        campaignId: Number(campaignId),
        contentId: id,
      };
      console.log(data);
      dispatch(sendCampaignEmailAction(data));
    }
  };

  const handleJoinClass = values => {
    if (values.platform === 'zoom' && values.enterUrl) {
      window.open(values.enterUrl);
      values.platform === 'zoom' &&
        request({
          url: `${appApiURL}/neou/v1/update-class-started`,
          method: 'POST',
          data: {
            videoId: values.id,
          },
        })
          .then(res => {
            console.log('success');
          })
          .catch(err => {
            console.log('Player: Couldn’t start the class' + err);
          });
      setStartClass(false);
    } else {
      window.open(`${NEOUS_APP_URL}/preview/${classId}`);
      setStartClass(false);
    }
  };

  useEffect(() => {
    _init(id);
  }, [id]);

  const handleInputValue = e => {
    setClassTitle(e.target.value);
  };

  const handleChangePrivateClass = () => {
    setisPrivateClass(!isPrivateClass);
  };

  const BlockSwitch = withStyles({
    switchBase: {
      color: '#fff',
      '&$checked': {
        color: '#86b660',
      },
      '&$checked + $track': {
        backgroundColor: '#86b660',
      },
    },
    checked: {},
    track: {},
  })(Switch);
  return (
    <>
      {isLoading && <Loader fullWidth />}

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick={true}
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
          onSubmitValue={() => onCampaign()}
          handleIDInput={e => handleInputValue(e)}
          campaignId={campaignId}
          isInteractive={true}
          error={error}
        >
          {formValues &&
          formValues.interactive &&
          formValues.interactive.isPrivate ? (
            <div className={classes.title}>
              {title}
              <StatusText>{` - PRIVATE`}</StatusText>
            </div>
          ) : (
            <>{title}</>
          )}
        </DialogTitle>
        <DialogContent dividers classes={{ root: classes.dialogContent }}>
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the classes."
            onAgree={() => handleClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <StyledTabs
            value={activeTab}
            onChange={onTabChange}
            aria-label="My account tabs"
            centered
            className={classes.styledTab}
          >
            {tabConfig.map((t, index) => (
              <StyledTab key={index} label={t.label} />
            ))}
          </StyledTabs>
          {activeTab === 0 ? (
            <Formik
              initialValues={formValues}
              enableReinitialize
              validationSchema={ClassSchema}
              onSubmit={_handleSubmit}
              validator={() => ({})}
            >
              {({
                values,
                setFieldValue,
                submitForm,
                errors,
                isValid,
                setErrors,
                validateForm,
                setTouched,
                handleSubmit,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={3} container>
                        {!values.isPublished && (
                          <Grid item xs container className={classes.checkBox}>
                            <FormControlLabel
                              classes={{
                                root: classes.customStyle,
                              }}
                              control={
                                <GreenCheckbox
                                  name="interactive.isPrivate"
                                  checked={Boolean(
                                    values.interactive.isPrivate,
                                  )}
                                  onChange={() => showAlertPrivateClass(true)}
                                />
                              }
                              label={
                                <Typography
                                  className={classes.formControlLabel}
                                >
                                  Private Class
                                </Typography>
                              }
                              labelPlacement="end"
                            />
                            <div className={classes.privateMessage}>
                              Private classes are not visible on the NEO Us
                              Schedule and only accessible to people who have
                              the link.
                            </div>
                          </Grid>
                        )}

                        <FormikField
                          name="interactive.dropInPrice"
                          label="Drop In Price"
                          required
                          placeholder="Enter drop In Price"
                          disabled={true}
                          onBlur={() => {
                            setFieldValue(
                              'interactive.dropInPrice',
                              '$' +
                                slugify(values.interactive.dropInPrice / 100) +
                                '.00',
                            );
                          }}
                        ></FormikField>

                        <FormikSelect
                          items={possibleOptions.videoType}
                          name="videoType"
                          label="Video Type*"
                        />
                        <StartDateTime
                          type="Edit"
                          date={values.airedDate}
                          selectedValue={setFieldValue}
                        />

                        <FormikSelect
                          items={possibleOptions.duration.sort(
                            (a, b) => a.value - b.value,
                          )}
                          name="duration"
                          label="Duration *"
                        />

                        <FormikSelect
                          items={possibleOptions.difficulty}
                          name="difficulty"
                          label="difficulty"
                        />

                        <FormikField
                          name="interactive.spots"
                          label="Maximum Class Size"
                          required
                          disabled={values.isPublished}
                          placeholder="Enter Maximum Class size"
                        ></FormikField>

                        <FormikImageUploader
                          name="interactive.bannerImg"
                          aspectRatio="16x9"
                          key="16x9"
                          title="16:9 Banner Override"
                          defaultValue={
                            values.interactive && values.interactive.bannerImg
                          }
                        />

                        <Grid item>
                          <Typography variant="h6">HISTORY</Typography>
                          <HistoryText>
                            Added:{' '}
                            {moment(values.createdAt).format('MM/DD/YYYY')}
                          </HistoryText>
                          {values.publishedDate && (
                            <HistoryText>
                              Published:
                              {moment(values.publishedDate).format(
                                'MM/DD/YYYY',
                              )}
                            </HistoryText>
                          )}
                          <HistoryText>
                            Updated:{' '}
                            {moment(values.updatedAt).format('MM/DD/YYYY')}
                          </HistoryText>
                          {values.updatedBy && (
                            <HistoryText>
                              Updated by: {values.updatedBy}
                            </HistoryText>
                          )}
                        </Grid>
                      </Grid>

                      <Grid item xs container>
                        <div className={classes.wrapGrid}>
                          <Grid item xs container>
                            <FormikField
                              name="title"
                              label="Title"
                              required
                              placeholder="Enter a title"
                              onBlur={() => {
                                setFieldValue(
                                  'permaLink',
                                  '/classes/' +
                                    slugify(values.title) +
                                    `/${values.id}`,
                                );
                                setFieldValue(
                                  'classURL',
                                  `${NEOUS_APP_URL}/neou/neous/classes/classes/` +
                                    slugify(values.title) +
                                    `/${values.id}`,
                                );
                              }}
                            ></FormikField>
                          </Grid>
                          <Grid
                            item
                            xs
                            container
                            style={{ marginLeft: '15px' }}
                          >
                            <FormikAutocomplete
                              name="instructors"
                              label="Interactive Instructors *"
                              placeholder="Add"
                              items={possibleOptions.interactiveInstructors}
                              multiple={true}
                              getOptionLabel={option => `${option.title}`}
                            />
                          </Grid>
                        </div>
                        <FormikField
                          name="permaLink"
                          required
                          label="Permalink"
                          placeholder="Interactive URL"
                        />
                        <div className={classes.classURLGrid}>
                          <div style={{ width: '90%' }}>
                            <FormikField
                              name="classURL"
                              required
                              label="Class URL"
                              placeholder="Interactive Class URL"
                            />
                          </div>
                          <ShareURL classURL={values.classURL} />
                        </div>

                        <div className={classes.wrapGrid}>
                          <Grid item xs container>
                            <FormikSelect
                              items={possibleOptions.platform}
                              name="platform"
                              label="Platform*"
                            />
                            {values.platform == 'zoom' && values.enterUrl && (
                              <FormikField
                                name="enterUrl"
                                label="Zoom url (host)"
                                placeholder="Zoom url"
                                disabled={true}
                                multiline
                              />
                            )}

                            <FormikField
                              name="description"
                              label="Description"
                              placeholder="Enter a description"
                              multiline
                              rows="7"
                            />
                          </Grid>
                          <Grid
                            item
                            xs
                            container
                            style={{ marginLeft: '15px' }}
                          >
                            <FormikAutocomplete
                              items={possibleOptions.concepts}
                              name="concept"
                              label="Concept *"
                              multiple={false}
                            />

                            <FormikAutocomplete
                              name="genres"
                              label="Genres *"
                              placeholder="Add"
                              items={possibleOptions.genres}
                              multiple={true}
                              getOptionLabel={option => `${option.title}`}
                            />
                            <FormikAutocomplete
                              name="bodyFocus"
                              label="Body Focus *"
                              placeholder="Add"
                              items={possibleOptions.bodyFocus}
                              multiple={true}
                              getOptionLabel={option => `${option.title}`}
                            />
                            <FormikSelect
                              items={possibleOptions.impact}
                              name="impact"
                              label="Impact"
                            />
                          </Grid>
                        </div>
                        <div className={classes.wrapGrid}>
                          <Grid item xs container>
                            <FormikField
                              name="classPlan"
                              label="Class Plan"
                              placeholder="Enter a class plan"
                              multiline
                            />

                            {values.interactive &&
                              values.interactive.isPrivate && (
                                <>
                                  <FormControlLabel
                                    control={
                                      <BlockSwitch
                                        checked={isPrivateClass}
                                        onChange={() =>
                                          handleChangePrivateClass()
                                        }
                                        name="checked"
                                      />
                                    }
                                    label="Restricted Email Id / Partner ID"
                                  />
                                  <div className={classes.privateMessage}>
                                    Toggle will enable Restricted Email ID or
                                    Allows to enter Partner ID
                                  </div>
                                  {!isPrivateClass && (
                                    <FormikField
                                      name="interactive.restrictedToEmails"
                                      label="Restricted Emails or Domain"
                                      placeholder="ex. @domain.com or john@gmail.com,bob@gmail.com"
                                      multiline
                                    />
                                  )}
                                  {isPrivateClass && (
                                    <FormikField
                                      name="interactive.partnerId"
                                      label="PartnerId"
                                      placeholder="Enter Partner ID"
                                    />
                                  )}
                                </>
                              )}
                          </Grid>
                          <Grid
                            item
                            xs
                            container
                            style={{ marginLeft: '15px' }}
                          >
                            <FormikAutocomplete
                              items={possibleOptions.equipments}
                              name="equipments.required"
                              label="Required Equipment"
                              multiple
                            />
                            <FormikAutocomplete
                              items={possibleOptions.equipments}
                              name="equipments.optional"
                              label="Optional Equipment"
                              multiple
                            />
                            <FormikAutocomplete
                              items={possibleOptions.musics}
                              name="music"
                              label="Music *"
                              multiple
                            />
                            <FormControlLabel
                              classes={{
                                root: classes.customStyle,
                              }}
                              control={
                                <GreenCheckbox
                                  name="explicitLang"
                                  checked={Boolean(values.explicitLang)}
                                  onChange={event =>
                                    setFieldValue(
                                      'explicitLang',
                                      event.target.checked,
                                    )
                                  }
                                />
                              }
                              label={
                                <Typography
                                  className={classes.formControlLabel}
                                >
                                  Explicit Language
                                </Typography>
                              }
                              labelPlacement="end"
                            />
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    {isReviewTab ? (
                      <DialogActions classes={{ root: classes.dialogActions }}>
                        <div className={classes.wrapButton}>
                          <CloneCTA
                            isValid={isValid}
                            values={values}
                            setCloneDialogueOpen={setCloneDialogueOpen}
                            isCloneDialogueOpen={isCloneDialogueOpen}
                            CloneVideo={CloneVideo}
                          />
                          {values.interactive.inReview && (
                            <>
                              <Button
                                color="secondary"
                                onClick={() => {
                                  if (isValid) {
                                    setFlagged(true);
                                  }
                                }}
                                type="submit"
                              >
                                FLAG
                              </Button>

                              <ConfirmDialog
                                isOpen={isFlagAlertOpen}
                                headerText="Are you sure?"
                                bodyText="You are about to flag the classes"
                                onAgree={() => {
                                  if (isValid) {
                                    flagVideo(values, {
                                      submitForm,
                                      errors,
                                      isValid,
                                      setErrors,
                                      validateForm,
                                      setFieldValue,
                                      setTouched,
                                    });
                                  }
                                }}
                                handleClose={() => showAlertToFlag(false)}
                                onDisagree={() => showAlertToFlag(false)}
                              ></ConfirmDialog>
                            </>
                          )}{' '}
                          {values.interactive.flagged &&
                            !values.interactive.inReview && (
                              <Box display="flex" alignItems="center" mr={1}>
                                <StatusLabel>STATUS</StatusLabel>
                                <StatusText>FLAGGED</StatusText>
                              </Box>
                            )}{' '}
                        </div>
                        <div className={classes.wrapButton}>
                          {!values.interactive.inReview && (
                            <>
                              <Button
                                variant="contained"
                                type="submit"
                                color="secondary"
                                size="large"
                                className={classes.publish}
                                onClick={e => {
                                  if (isValid) {
                                    setPublish(false);
                                  }
                                }}
                              >
                                SAVE
                              </Button>
                            </>
                          )}{' '}
                          {!values.interactive.inReview &&
                            !values.interactive.flagged && (
                              <Button
                                variant="contained"
                                type="submit"
                                color="secondary"
                                size="large"
                                className={classes.publish}
                                onClick={e => {
                                  if (isValid) {
                                    setPublish(true);
                                  }
                                }}
                              >
                                PUBLISH
                              </Button>
                            )}{' '}
                          {values.interactive.inReview &&
                            !values.interactive.isCancelled && (
                              <Box display="flex" alignItems="center" mr={1}>
                                <StatusLabel>STATUS</StatusLabel>
                                <StatusText>IN REVIEW</StatusText>
                              </Box>
                            )}
                          {values.interactive.flagged && (
                            <Button
                              variant="contained"
                              type="submit"
                              color="secondary"
                              size="large"
                              className={classes.publish}
                              onClick={e => {
                                if (isValid) {
                                  setReview(true);
                                }
                              }}
                            >
                              SUBMIT FOR REVIEW
                            </Button>
                          )}
                          {values.interactive.inReview && (
                            <Button
                              variant="contained"
                              type="submit"
                              color="secondary"
                              size="large"
                              className={classes.publish}
                              onClick={e => {
                                if (isValid) {
                                  setReviewToPublish(true);
                                }
                              }}
                            >
                              SAVE & PUBLISH
                            </Button>
                          )}
                        </div>
                      </DialogActions>
                    ) : (
                      <DialogActions classes={{ root: classes.dialogActions }}>
                        <div className={classes.wrapButton}>
                          <CloneCTA
                            isValid={isValid}
                            values={values}
                            setCloneDialogueOpen={setCloneDialogueOpen}
                            isCloneDialogueOpen={isCloneDialogueOpen}
                            CloneVideo={CloneVideo}
                          />
                          {!isCompleted &&
                            values.isHidden &&
                            !values.isPublished && (
                              <Box display="flex" alignItems="center" mr={1}>
                                <StatusLabel>STATUS</StatusLabel>
                                <StatusText>HIDDEN</StatusText>
                              </Box>
                            )}{' '}
                          {!isCompleted &&
                            !values.isHidden &&
                            values.isPublished &&
                            !values.interactive.isCancelled && (
                              <>
                                <Button
                                  color="secondary"
                                  onClick={() => showAlertToHide(true)}
                                >
                                  Hide
                                </Button>

                                <ConfirmDialog
                                  isOpen={isHideAlertOpen}
                                  headerText="Are you sure?"
                                  bodyText="You are about to hide the classes"
                                  onAgree={() =>
                                    hideVideo(values, {
                                      submitForm,
                                      errors,
                                      isValid,
                                      setErrors,
                                      validateForm,
                                      setFieldValue,
                                      setTouched,
                                    })
                                  }
                                  handleClose={() => showAlertToHide(false)}
                                  onDisagree={() => showAlertToHide(false)}
                                ></ConfirmDialog>
                              </>
                            )}
                          {!isCompleted &&
                            values.isPublished &&
                            !values.isHidden &&
                            values.interactive &&
                            !values.interactive.isCancelled && (
                              <>
                                <Button
                                  className={classes.cancelButton}
                                  size="large"
                                  color="secondary"
                                  onClick={() => showAlertToCancel(true)}
                                >
                                  Cancel
                                </Button>
                                <ConfirmDialog
                                  isOpen={isCancelAlertOpen}
                                  headerText="Are you sure?"
                                  bodyText={
                                    <>
                                      <Typography
                                        classes={{ root: classes.subHeading }}
                                      >
                                        You are about to cancel the classes
                                      </Typography>
                                      <FormControlLabel
                                        classes={{
                                          root: classes.fontChange,
                                        }}
                                        value="top"
                                        name={`name`}
                                        control={
                                          <GreenCheckbox
                                            checked={isEmail}
                                            onChange={() => {
                                              setEmail(!isEmail);
                                            }}
                                          />
                                        }
                                        label="Send default cancellation mail to participants"
                                        labelPlacement="end"
                                      />
                                    </>
                                  }
                                  onAgree={() =>
                                    cancelClass(values, {
                                      submitForm,
                                      errors,
                                      isValid,
                                      setErrors,
                                      validateForm,
                                      setFieldValue,
                                      setTouched,
                                    })
                                  }
                                  handleClose={() => showAlertToCancel(false)}
                                  onDisagree={() => showAlertToCancel(false)}
                                ></ConfirmDialog>
                              </>
                            )}
                          {values.isStarted && values.isPublished && (
                            <>
                              <Button
                                className={classes.startButton}
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() => {
                                  if (isValid) {
                                    setStartClass(true);
                                  }
                                }}
                              >
                                Admit participants
                              </Button>
                              <ConfirmDialog
                                isOpen={isStartClass}
                                headerText="Are you sure?"
                                bodyText={
                                  values.platform === 'zoom'
                                    ? 'You will be redirected into zoom meeting now'
                                    : 'You are about to enter into preview screen now'
                                }
                                onAgree={e => handleJoinClass(values)}
                                handleClose={() => setStartClass(false)}
                                onDisagree={() => setStartClass(false)}
                              ></ConfirmDialog>
                            </>
                          )}
                        </div>
                        <div className={classes.wrapButton}>
                          {!isCompleted &&
                            !values.isPublished &&
                            !values.interactive.isCancelled && (
                              <>
                                <Button
                                  variant="contained"
                                  type="submit"
                                  color="secondary"
                                  size="large"
                                  className={classes.publish}
                                  onClick={e => {
                                    if (isValid) {
                                      setPublish(true);
                                    }
                                  }}
                                >
                                  Publish
                                </Button>
                              </>
                            )}{' '}
                          {!isCompleted &&
                            values.isPublished &&
                            !values.interactive.isCancelled && (
                              <Box display="flex" alignItems="center" mr={1}>
                                <StatusLabel>STATUS</StatusLabel>
                                <StatusText>PUBLISHED</StatusText>
                              </Box>
                            )}
                          {isCompleted && (
                            <Box
                              display="flex"
                              style={{ padding: '10px' }}
                              alignItems="center"
                              mr={1}
                            >
                              <StatusLabel>STATUS</StatusLabel>
                              <StatusText>COMPLETED</StatusText>
                            </Box>
                          )}
                          {!isCompleted && values.interactive.isCancelled && (
                            <Box
                              display="flex"
                              alignItems="center"
                              padding="15px"
                              mr={1}
                            >
                              <StatusLabel>STATUS</StatusLabel>
                              <StatusText>CANCELLED</StatusText>
                            </Box>
                          )}
                          {!isCompleted && (
                            <Button
                              onClick={e => {
                                e.preventDefault();
                                values &&
                                  values.permaLink &&
                                  dispatch(
                                    push(`/preview/group-class/${values.id}`),
                                  );
                              }}
                              color="secondary"
                            >
                              Preview
                            </Button>
                          )}
                          {!isCompleted && !values.interactive.isCancelled && (
                            <Button
                              variant="contained"
                              type="submit"
                              color="secondary"
                              size="large"
                            >
                              Save & Close
                            </Button>
                          )}
                        </div>
                      </DialogActions>
                    )}
                    <ConfirmDialog
                      isOpen={isPrivateClassAlertOpen}
                      headerText="Hide this class from your schedule?"
                      bodyText="Choose yes, if you do not want to appear on your public schedule. Hidden classes are only accessible to people who have link."
                      handleClose={() => showAlertPrivateClass(false)}
                      onAgree={() => {
                        setFieldValue('interactive.isPrivate', true);
                      }}
                      disagreeBtnText="NO"
                      onDisagree={() => {
                        setFieldValue('interactive.isPrivate', false);
                        showAlertPrivateClass(false);
                      }}
                    />

                    <ConfirmDialog
                      isOpen={isConfirmPublishOpen}
                      headerText="Are you sure?"
                      bodyText="This content is already published. Changes will impact existing behavior"
                      handleClose={() => openConfirmPublishDialog(false)}
                      onAgree={() => {
                        let body = _formRequestBody(values);
                        saveVideo(
                          body,
                          'The class has been updated successfully!',
                        );
                      }}
                      onDisagree={() => openConfirmPublishDialog(false)}
                    />
                    <ConfirmDialog
                      isOpen={isDropInPriceAlertOpen}
                      headerText="Are you sure?"
                      bodyText="The Drop in Price of the class is $0.00"
                      handleClose={() => showAlertToDropInPrice(false)}
                      onAgree={() => {
                        let body = _formRequestBody(values);
                        setConfirmSubmit(true);
                        saveVideo(
                          body,
                          'The class has been updated successfully!',
                        );
                      }}
                      onDisagree={() => {
                        showAlertToDropInPrice(false);
                      }}
                    />

                    <ConfirmDialog
                      isOpen={isNotificationAlertOpen}
                      headerText="Publishing a class for the first time?"
                      bodyText={
                        <FormControlLabel
                          value="top"
                          name={`name`}
                          control={
                            <GreenCheckbox
                              checked={isEnableNotification}
                              onChange={() => {
                                setEnableNotification(!isEnableNotification);
                              }}
                            />
                          }
                          label="Enable new class notification"
                          labelPlacement="end"
                        />
                      }
                      agreeButtonText="OK"
                      isDisAgreeShow={false}
                      onAgree={() => {
                        let body = _formRequestBody(values);
                        saveVideo(
                          body,
                          'The class has been published successfully!',
                        );
                      }}
                      handleClose={() => showAlertToCloseNotification(false)}
                      onDisagree={() => showAlertToCloseNotification(false)}
                    ></ConfirmDialog>
                  </Form>
                );
              }}
            </Formik>
          ) : activeTab === 1 ? (
            <Grid container alignItems="flex-start" spacing={2}>
              <div className={classes.clientLists}>
                {clients && clients.length > 0 ? (
                  <ClientList styles={styles} clients={clients} />
                ) : formValues.isPublished ? (
                  <div className={classes.emptyListText}>
                    No Participant have booked this class
                  </div>
                ) : (
                  <div className={classes.emptyListText}>
                    Participant list is only available for published classes
                  </div>
                )}
              </div>
            </Grid>
          ) : (
            <Grid container alignItems="flex-start" spacing={2}>
              <div className={classes.clientLists}>
                {clientsJoined && clientsJoined.length > 0 ? (
                  <ClientList styles={styles} clients={clientsJoined} />
                ) : formValues.isPublished && clients && clients.length > 0 ? (
                  <div className={classes.emptyListText}>
                    No Participants have joined this class
                  </div>
                ) : (
                  <div className={classes.emptyListText}>
                    Joined class list is available only for published classes
                  </div>
                )}
              </div>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

function CloneCTA(props) {
  let {
    isValid,
    values,
    setCloneDialogueOpen,
    isCloneDialogueOpen,
    CloneVideo,
  } = props;
  return (
    <>
      <Button
        color="secondary"
        onClick={() => {
          if (isValid) {
            setCloneDialogueOpen(true);
          }
        }}
      >
        CLONE
      </Button>

      <ConfirmDialog
        isOpen={isCloneDialogueOpen}
        headerText="Are you sure?"
        bodyText="You are about to clone the class"
        onAgree={() => {
          if (isValid) {
            CloneVideo(values);
          }
        }}
        handleClose={() => setCloneDialogueOpen(false)}
        onDisagree={() => setCloneDialogueOpen(false)}
      ></ConfirmDialog>
    </>
  );
}

export default withStyles(dialogStyles)(GroupInteractive);
