import { createSelector } from 'reselect';
import { RootState } from './types';
import { initialState } from './reducer';

/**
 * Direct selector to the root state domain
 */

const selectRootDomain = (state: RootState) => {
  return state.root || initialState;
};

const selectAgeGroup = (state: RootState) => {
  const { ageGroups } = state.root;
  return ageGroups || initialState.ageGroups;
};

const selectUserInfo = (state: RootState) => {
  const { userInfo } = state.root;
  return userInfo || initialState.userInfo;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Root
 */

const makeSelectRoot = () =>
  createSelector(
    selectRootDomain,
    substate => {
      return substate;
    },
  );

const makeSelectUserInfo = () =>
  createSelector(
    selectUserInfo,
    substate => {
      return substate;
    },
  );
export default makeSelectRoot;
export { selectRootDomain, selectAgeGroup, selectUserInfo, makeSelectUserInfo };
