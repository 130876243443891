import { IconButton, makeStyles, ButtonBase } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import { SvgArrowPager, SvgCheckCircle } from './NeIcons';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { openSnackBar } from '../containers/Root/actions';
import { NEOUS_APP_URL } from '../utils/config';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    margin: 'auto',
    '& .MuiCard-root': {
      backgroundColor: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  classLive: {
    color: '#DF2425',
    fontSize: '15px',
    fontFamily: 'ProximaNova-Bold',
  },
  classTime: {
    fontSize: '15px',
    fontFamily: 'ProximaNova-Medium',
    fontWeight: 500,
    lineHeight: '30px',
    margin: '0px',
    textTransform: 'uppercase',
    '@media(max-width:640px)': {
      paddingBottom: '6px',
      fontSize: '12px',
    },
  },
  bookClass: {
    fontSize: '15px',
    fontFamily: 'ProximaNova-Medium',
    fontWeight: 500,
    lineHeight: '30px',
    margin: '0px',
  },
  classTitle: {
    fontSize: '15px',
    fontFamily: 'ProximaNova-Bold, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    lineHeight: '22px',
    margin: '0px',
  },
  classLevel: {
    color: '#A1A6A9',
    fontSize: '15px',
    fontFamily: 'ProximaNova-SemiBold',
    fontWeight: 500,
    lineHeight: '30px',
    margin: '0px',
  },
  classList: {
    both: 'clear',
    margin: 'auto 0',
  },
  twoway: {
    color: '#56FFFE',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '12px',
    lineHeight: '15px',
  },
  classBtn: {
    both: 'clear',
    float: 'right',
    paddingRight: '16px',
    marginTop: '14px',
  },
  joinBtn: {
    float: 'right',
    padding: '16px',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
    '@media(max-width:640px)': {
      marginBottom: '0',
      marginTop: '14px',
    },

    '& .MuiButton-textPrimary': {
      minWidth: '75px',
      minHeight: '36px',
      padding: '8px 16px',
      textTransform: 'initial',
    },
    '& .MuiButton-textSecondary': {
      minWidth: '75px',
      minHeight: '36px',
      padding: '8px 16px',
      textTransform: 'initial',
      fontSize: '13px',
      fontFamily: 'Proxima-Nova-Regular',
    },
  },
  bookBtn: {
    color: '#080B0D',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '16px',
    background: 'linear-gradient(135deg, #2ADCFE 0%, #56FFFE 100%)',
    border: '0px',
    borderRadius: '6px',
    padding: '7px 10px',
    cursor: 'pointer',

    ' &.MuiButton-root.Mui-disabled': {
      color: '#fff',
      background: '#35414A',
    },
  },
  spot: {
    color: '#CDD3D8',
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '16px',
    fontFamily: 'Proxima-Nova-Regular',
    whiteSpace: 'nowrap',
  },
  classItem: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    fontFamily: 'Proxima-Nova-Regular',
  },
  credit: {
    padding: '15px',
    margin: 'auto',
  },
  tileWrapper: {
    backgroud: 'rgb(29, 36, 41)',
    width: '100%',
    borderBottom: ' 1px solid #E3E3E4',
    borderTop: ' 1px solid #E3E3E4',
    [theme.breakpoints.down('sm')]: {
      '& a': {
        width: '100%',
      },
    },
  },
  creditWrapper: {
    display: 'flex',
    flexBasis: '250px',
    [theme.breakpoints.down('sm')]: {
      // padding: '10px 20px',
      flexDirection: 'column-reverse',
      flexBasis: 'inherit',
    },
  },
  img: {
    width: '17px',
    height: '17px',
    marginLeft: '4px',
  },
  videoIcon: {
    height: '12px',
    marginBottom: '4px',
  },
  viewPackages: {
    textTransform: 'capitalize',
    color: '#12161A',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '15px',
    backgroundColor: 'rgba(212,216,220,0.5)',
    cursor: 'pointer',
    border: '1px solid rgba(151,151,151,0.2)',
    borderRadius: '10.2px',
    boxSizing: 'border-box',
    padding: '0px 10px',
    height: '30px',
  },
  imageWrapper: {
    cursor: 'pointer',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      width: '100%',
      height: '100%',
    },
    '@media(max-width:768px)': {
      padding: '0 15px',
    },
    '@media(min-width:768px)': {
      padding: '0 0px',
    },
  },
  imageWrap: {
    width: '174px',
    height: '98px',
    position: 'relative',
    borderRadius: '5px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      paddingTop: '56.26%',
    },
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      transition: 'all ease-in 500ms',
      borderRadius: '5px',
      '@media(max-width:321px)': {
        //  width: '85px',
      },
    },
  },
  favIConWrapper: {
    position: 'relative',
  },
  gridView: {
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: '35% 1fr',
    gridGap: '8px',
    padding: '16px',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '160px 1fr 150px 40px',
      gridTemplateRows: 'auto',
    },
  },
}));

export interface IClassItemProps {
  classItem: any;
  instructor: any;
  start_time: any;
  end_time: any;
  time_zone: any;
  time: any;
}

export function Upcoming({
  classItem,
  instructor,
  start_time,
  end_time,
  time_zone,
  time,
}: IClassItemProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [copyIdBtnText, setCopyIdBtnText] = React.useState('Share class link');

  const redirect = e => {
    e.preventDefault();
    if (classItem) {
      dispatch(push(`/neou/instructor-my-class/${classItem.id}`));
    }
  };

  const getTextAreaContent = () => {
    let copyTextarea = `${NEOUS_APP_URL}/neou/neous/classes${classItem.permaLink}`;
    navigator.clipboard.writeText(copyTextarea).then(
      function() {
        setCopyIdBtnText('Link copied');
      },
      function(err) {
        setCopyIdBtnText('Share class link');
        dispatch(openSnackBar('Could not copy concept Id!'));
      },
    );
  };

  let currentTime = moment(Date.now());
  let startTime = moment(new Date(+classItem.startDate));
  let isStarted = startTime.diff(currentTime) <= 10 * 1000 * 60;
  let canStart = startTime.diff(currentTime) <= 60 * 1000 * 60;

  let endTime = moment(new Date(+classItem.startDate)).add(
    classItem.duration,
    'minutes',
  );
  let isEnded = endTime.diff(currentTime) <= 1 * 1000 * 60;

  return (
    <>
      <div className={classes.tileWrapper}>
        <div className={classes.gridView}>
          <div className={classes.classList}>
            <p className={classes.classTime}>
              {moment(start_time, 'HH:mm:ss').format('h:mm a')}-{' '}
              {moment(end_time, 'HH:mm:ss').format('h:mm a')} {time_zone}
            </p>
            {classItem && classItem.duration && (
              <p className={classes.classTime}>{classItem.duration} minutes</p>
            )}
            {classItem &&
              classItem.interactive &&
              classItem.interactive.isPrivate && (
                <div className={classes.classLive}>PRIVATE CLASS</div>
              )}
            {isStarted && !isEnded && (
              <div className={classes.classLive}>LIVE NOW</div>
            )}
          </div>
          <div className={classes.classList}>
            <p className={classes.classTitle}>
              {classItem && classItem.title
                ? classItem.title
                : 'interactive class'}
              {classItem.instructors &&
                Array.isArray(classItem.instructors) &&
                classItem.instructors.length > 1 &&
                classItem.instructors[0].firstName &&
                ` with ${classItem.instructors[0].firstName} ${classItem.instructors[0].lastName} and ${classItem.instructors[1].firstName} ${classItem.instructors[1].lastName}`}

              {classItem.instructors &&
                Array.isArray(classItem.instructors) &&
                classItem.instructors.length === 1 &&
                classItem.instructors[0].firstName &&
                ` with ${classItem.instructors[0].firstName} ${classItem.instructors[0].lastName}`}
            </p>
            {classItem && classItem.genres && classItem.genres.length > 1 && (
              <p className={classes.classLevel}>
                {classItem.genres[0].title}, {classItem.genres[1].title}{' '}
                {classItem &&
                  classItem.difficulty &&
                  `• ${classItem.difficulty}`}
              </p>
            )}
            {classItem && classItem.genres && classItem.genres.length === 1 && (
              <p className={classes.classLevel}>
                {classItem.genres[0].title}{' '}
                {classItem &&
                  classItem.difficulty &&
                  `• ${classItem.difficulty}`}
              </p>
            )}
            <div>
              <ButtonBase
                className={classes.viewPackages}
                onClick={getTextAreaContent}
              >
                {copyIdBtnText === 'Link copied' && <CheckIcon></CheckIcon>}{' '}
                {copyIdBtnText}
              </ButtonBase>
            </div>
          </div>
          <div className={classes.classList}>
            <p className={classes.bookClass}>
              {classItem.interactive &&
                classItem.interactive.spots &&
                classItem.interactive.spotsLeft && (
                  <>
                    {classItem.interactive &&
                    classItem.interactive.spots -
                      classItem.interactive.spotsLeft >=
                      0
                      ? classItem.interactive.spots -
                        classItem.interactive.spotsLeft
                      : 0}{' '}
                    booked /{' '}
                    {classItem.interactive && classItem.interactive.spots} max
                  </>
                )}{' '}
            </p>
            {classItem &&
            classItem.interactive &&
            classItem.interactive.dropInPrice ? (
              <p className={classes.classLevel}>
                {`$${Number(classItem.interactive.dropInPrice / 100).toFixed(
                  2,
                )} Drop In Price`}
              </p>
            ) : (
              <p className={classes.classLevel}>{`$0.00 Drop In Price`}</p>
            )}
          </div>
          {isStarted && isEnded ? (
            <div
              className={classes.classList}
              style={{ display: 'flex', justifyContent: 'center' }}
              onClick={redirect}
            >
              <IconButton>
                <SvgCheckCircle />
              </IconButton>
            </div>
          ) : (
            <div
              className={classes.classList}
              style={{ display: 'flex', justifyContent: 'center' }}
              onClick={redirect}
            >
              <IconButton>
                <SvgArrowPager />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
