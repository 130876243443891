import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import {
  collectionListSuccess,
  collectionListFailed,
  storeCollectionDetailInfo,
  addCollectionDetailInfoFailed,
  addCollectionDetailInfoSuccess,
  addCollection,
  setSearchKey,
  searchVideosByTitleOrIDSuccess,
  editCollectionDetailInfoFailed,
  editCollectionDetailInfoSuccess,
  setCollectionLoader,
  putCollectionPagination,
  collectionListCountSuccess,
  setCollectionPaginationLoader,
  collectionListPaginationSuccess,
} from './actions';
import { selectCollectionDomain } from './selectors';
import { AnyAction } from 'redux';
import { initialState } from './reducer';
const SLICE_COUNT_OF_CLASSES = 50;

// Individual exports for testing
export default function* collectionSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_COLLECTION_LIST, getCollectionList);
  yield takeLatest(
    ActionTypes.REQUEST_COLLECTION_LIST_PAGINATION,
    getCollectionListPagination,
  );
  yield takeLatest(ActionTypes.REQUEST_COLLECTION_TYPE, getCollectionListType);
  // yield takeLatest(ActionTypes.GET_SEARCH_RESULT, getSearchResultApi);
  yield takeLatest(ActionTypes.FETCH_COLLECTION_BY_ID, fetchCollectionById);

  yield takeLatest(
    ActionTypes.ADD_COLLECTION_DETAIL_INFO,
    addCollectionDetailInfo,
  );
  yield takeLatest(
    ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID,
    searchVideosByTitleOrID,
  );
  yield takeLatest(
    ActionTypes.EDIT_COLLECTION_DETAIL_INFO,
    editCollectionDetailInfo,
  );
}

export function* getCollectionList() {
  try {
    let { sortBy, activeTab, searchKey, collectionType } = yield select(
      selectCollectionDomain,
    );
    let getUrl;
    if (sortBy === '') {
      sortBy = 'updatedAt=desc';
    }
    const splitResult = sortBy.split('=');

    if (
      collectionType === '' &&
      Array.isArray(splitResult) &&
      splitResult.length === 2
    ) {
      getUrl = `neouadmin/v1/collections?${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&keyword=${searchKey}`;
    } else if (Array.isArray(splitResult) && splitResult.length === 2) {
      getUrl = `neouadmin/v1/collections?${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&keyword=${searchKey}&type=${collectionType}`;
    } else {
      getUrl = `neouadmin/v1/collections?${activeTab}&sortBy=updatedAt&sortOrder=desc&keyword=${searchKey}`;
    }
    let { result, total } = yield call(() =>
      request({
        url: getUrl,
        method: 'GET',
      }),
    );
    yield put(collectionListSuccess(result));
    yield put(collectionListCountSuccess(total));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(collectionListSuccess([]));
    }
    yield put(collectionListSuccess([]));
    yield put(collectionListFailed());
  }
}

export function* getCollectionListType() {
  // yield put(setCollectionPaginationLoader(true));
  yield put(setCollectionLoader(true));
  try {
    let { sortBy, activeTab, searchKey, collectionType } = yield select(
      selectCollectionDomain,
    );
    if (sortBy === '') {
      sortBy = 'updatedAt=desc';
    }
    const splitResult = sortBy.split('=');
    let url = '';
    if (Array.isArray(splitResult) && splitResult.length === 2) {
      url = `neouadmin/v1/collections?${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&keyword=${searchKey}&type=${collectionType}`;
    } else {
      url = `neouadmin/v1/collections?${activeTab}&sortBy=updatedAt&sortOrder=desc&keyword=${searchKey}&type=${collectionType}`;
    }
    let { result, total } = yield call(() =>
      request({
        url: url,
        method: 'GET',
      }),
    );
    yield put(setCollectionLoader(false));
    yield put(collectionListSuccess(result));
    yield put(collectionListCountSuccess(total));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(collectionListSuccess([]));
      yield put(setCollectionLoader(false));
    }
    yield put(collectionListSuccess([]));
    yield put(collectionListFailed());
    yield put(setCollectionLoader(false));
  }
}

export function* getCollectionListPagination(action) {
  yield put(setCollectionPaginationLoader(true));
  try {
    let { sortBy, activeTab, searchKey, total, collectionType } = yield select(
      selectCollectionDomain,
    );
    if (sortBy === '') {
      sortBy = 'updatedAt=desc';
    }
    const splitResult = sortBy.split('=');
    let url = '';
    if (Array.isArray(splitResult) && splitResult.length === 2) {
      url = `neouadmin/v1/collections?${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&keyword=${searchKey}&offset=${action.payload}&limit=${SLICE_COUNT_OF_CLASSES}&type=${collectionType}`;
    } else {
      url = `neouadmin/v1/collections?${activeTab}&sortBy=updatedAt&sortOrder=desc&keyword=${searchKey}&offset=${action.payload}&limit=${SLICE_COUNT_OF_CLASSES}&type=${collectionType}`;
    }
    let { result } = yield call(() =>
      request({
        url: url,
        method: 'GET',
      }),
    );
    yield put(collectionListPaginationSuccess(result));
    yield delay(1000);
    yield put(putCollectionPagination());
    yield put(collectionListPaginationSuccess([]));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(collectionListSuccess([]));
      yield put(setCollectionPaginationLoader(false));
    }
    yield put(collectionListFailed());
    yield put(setCollectionPaginationLoader(false));
  }
}

// export function* fetchCollectionById(action: AnyAction) {
//   let id = action.payload;
//   try {
//     let { result } = yield call(() =>
//       request({
//         url: `/neouadmin/v1/collections-detail`,
//         params: {
//           id,
//         },
//       }),
//     );
//     yield put(storeCollectionDetailInfo(result));
//   } catch (error) {}
// }
export function* fetchCollectionById(action: AnyAction) {
  let id = action.payload;
  let url = {
    url: `/neouadmin/v1/collection-detail`,
  };
  if (id) {
    url['params'] = {
      id,
    };
  }
  yield put(setCollectionLoader(true));
  try {
    let { result } = yield call(() => request(url));
    yield put(setCollectionLoader(false));
    if (!id) {
      let newResult = setDefaultOption(result, false);
      yield put(addCollection(newResult));
    } else {
      let newResult = setDefaultOption(result, true);
      yield put(storeCollectionDetailInfo(newResult));
    }
  } catch (error) {
    yield put(setCollectionLoader(false));
  }
}

export function* addCollectionDetailInfo(action: AnyAction) {
  let data = action.payload;
  let updataData = JSON.parse(JSON.stringify(data));
  dataBuild(updataData);
  //TODO Add Collection remove after manage gallery is finished
  updataData.gallery.push({
    type: 'image',
    url:
      'https://snagfilms-a.akamaihd.net/e92e55d5-a8e5-4267-bcf0-667e2b630d81/images/83/ad/dad1ac764735a07f7714dfc50eb8/1567786724908_1920x1080jumpstart_16x9Images.png',
  });
  updataData.collectionType = 'program';

  yield put(setCollectionLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/collections`,
        method: 'POST',
        data: updataData,
      }),
    );
    yield put(setCollectionLoader(false));
    yield getCollectionList();
    yield put(addCollectionDetailInfoSuccess(result));
  } catch (error) {
    yield put(setCollectionLoader(false));
    yield put(addCollectionDetailInfoFailed(error));
  }
}

export function* editCollectionDetailInfo(action: AnyAction) {
  let data = action.payload;
  let updataData = JSON.parse(JSON.stringify(data));
  dataBuild(updataData);
  yield put(setCollectionLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/collections`,
        method: 'PUT',
        data: updataData,
      }),
    );
    yield put(setCollectionLoader(false));
    yield getCollectionList();
    yield put(editCollectionDetailInfoSuccess(result));
    yield put(storeCollectionDetailInfo(initialState));
  } catch (error) {
    yield put(setCollectionLoader(false));
    yield put(editCollectionDetailInfoFailed(error));
  }
}

export function* searchVideosByTitleOrID(action: AnyAction) {
  let key = action.payload;
  yield put(setCollectionLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/search`,
        method: 'POST',
        data: {
          keyword: key,
        },
      }),
    );
    yield put(setCollectionLoader(false));
    yield put(searchVideosByTitleOrIDSuccess(result));
  } catch (error) {
    yield put(setCollectionLoader(false));
  }
}

function dataBuild(data) {
  objectTostring(data.instructors);
  objectTostring(data.genres);
  objectTostring(data.categories);
  // objectTostring(data.videos.videoIds);
}

function objectTostring(data) {
  for (let i = 0; i < data.length; i++) {
    data[i] = data[i].id ? data[i].id : data[i];
  }
}

export function setDefaultOption(result, isId) {
  let obj = {
    isLoading: false,
    data: {
      categories: [],
      collectionType: '',
      createdAt: new Date(),
      description: '',
      flag: '',
      gallery: [],
      genres: [],
      graphics: {
        '16x9': '',
        '16x9_trans': '',
        '1x1': '',
        '32x9': '',
        '9x16': '',
      },
      instructors: [],
      isHidden: false,
      isPublished: false,
      kicker: '',
      permaLink: '',
      publishedDate: new Date(),
      seriesID: '',
      title: '',
      updatedAt: new Date(),
      videos: {
        weeks: {},
      },
    },
  };
  if (isId) {
    let defaultInstructors = arrayDefaultValue(
      result.instructors,
      result.data.instructors,
      'instructors',
    );
    let defaultGenres = arrayDefaultValue(
      result.genres,
      result.data.genres,
      'genres',
    );
    let defaultCategories = arrayDefaultValue(
      result.categories,
      result.data.categories,
      'categories',
    );
    result = {
      ...result,
      defaultInstructors: defaultInstructors,
      defaultGenres: defaultGenres,
      defaultCategories: defaultCategories,
    };
  } else {
    result = { ...result, ...obj };
  }
  return result;
}

function arrayDefaultValue(mainArray, selecteArray, filed) {
  let arr = [];
  for (let k = 0; k < selecteArray.length; k++) {
    for (let i = 0; i < mainArray.length; i++) {
      if (mainArray[i].id == selecteArray[k]) {
        arr.push(mainArray[i]);
        break;
      }
    }
  }
  return arr;
}
