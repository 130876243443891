import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { string, array, object, boolean, date } from 'yup';
import FormikField from '../../../components/Formik/FormikField';
import FormikSelect from '../../../components/Formik/FormikSelect';
import {
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
  ButtonBase,
} from '@material-ui/core';
import { _formatOptions } from './stub';
import FormikAutocomplete from '../../../components/Formik/FormikAutocomplete';
import FormikGallery from '../../../components/Formik/ManageGallery';
import FormikImageUploader from '../../../components/Formik/FormikImageUploader';
import FormikSearchVideo from '../../../components/Formik/FormikSearchVideo';
import request from '../../../shared/lib/request';
import { openSnackBar } from '../../Root/actions';
import ThreeDotMenu from '../../../components/ThreeDotMenu';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { requestConceptList } from '../actions';
import {
  StatusLabel,
  StatusText,
  HistoryText,
  LabelText,
} from '../../../components/Typography';
import Loader from '../../../components/Loader';
import moment from 'moment';
import { slugify } from '../../../utils/slugify';
import { InteractiveConceptSchema } from '../ConceptTable/InteractiveConceptSchema';
import StyledTabs, {
  StyledTab,
} from '../../../components/VideoList/StylesTabs';
import ViewPackage from '../../../components/ViewPackage/ViewPackage';
import { validatePermaLink } from '../../../utils/commonFunctions';
import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'checkPermalink', function(errorMessage) {
  return this.test(`test-card-type`, errorMessage, function(value) {
    let check = /^\/concepts\//;
    return validatePermaLink(value, check);
  });
});

const ConceptSchema = object({
  title: string().required('Enter a concept title'),
  description: string().required('Enter a description'),
  permaLink: Yup.string()
    .checkPermalink(`Permalink is required and start with '/concepts/'`)
    .required(`Permalink is required and start with '/concepts/'`),
  seriesID: string()
    .required('Enter a series ID')
    .min(3)
    .matches(/[A-Z]{3,}/, 'Enter only uppercase char')
    .max(3),
  instructors: array()
    .min(1)
    .required('Enter instructors'),
  genres: string().required('Enter a concept genres'),
  categories: array().required('Enter categories'),

  gallery: array().min(1,'Enter atleast one gallery image'),
  graphics: object().shape({
    '16x9': string().url(),
    '32x9': string().url(),
    '1x1': string().url(),
    '9x16': string().url(),
    '16x9_trans': string().url(),
  }),
  videos: object().shape({
    videoIds: array()
      .min(1, 'Enter atleast one video item')
      .required('Video list is madantory'),
  }),
});

const dialogStyles = () =>
  createStyles({
    dialogContainer: {
      borderRadius: 0,
      maxWidth: '1080px',
    },
    noData: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#060B0F',
      width: '524px',
      padding: '20px',
      textAlign: 'center',
    },
    packageWrapper: {
      width: '100%',
      padding: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      border: '1px solid rgba(212,216,220,0.5)',
      margin: '5px 0px',
      borderRadius: '5px',
    },
    subInTitle: {
      color: '#000000',
      fontSize: '14px',
      lineHeight: '20px',
    },
    package: {
      width: '100%',
      height: '28px',
    },
    subTitle: {
      color: '#060B0F',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    imageContainer: {
      maxWidth: '252px',
      margin: '0px 16px',
    },
    videoHeader: {},
    classWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    form: {
      // maxWidth: '33.33%',
    },
    dialogContent: {
      padding: '24px 16px 72px',
    },
    mr6: {
      marginRight: '6px',
    },
    ml6: {
      marginLeft: '6px',
    },
    dialogActions: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      background: '#fff',
      border: '1px solid #D9D9D9',
      margin: '0px -16px',
      justifyContent: 'space-between',
      display: 'flex',
      zIndex: 140000,
    },
    videoHeaderContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
    },
    viewPackages: {
      textTransform: 'capitalize',
      color: '#12161A',
      marginTop: '10px',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 10px',
      height: '24px',
    },
    errorText: {
      width: '100%',
      marginTop: '-25px',
    },
    instructorGrid: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    bottomText: {
      width: '100%',
      paddingBottom: '15px',
    },
  });

interface videoId {
  id: string;
  title: string;
  videoID: {
    [key in string]: string;
  };
}
enum CollectionType {
  CONCEPT = 'concept',
}
interface FormValues {
  title: string;
  permaLink: string;
  teaserText: string;
  flag: string;
  seriesID: string;
  description: string;
  instructors: string[];
  interactive: object;
  categories: string[];
  genres: string;
  gallery: any;
  graphics: {
    '16x9': string;
    '32x9': string;
    '1x1': string;
    '9x16': string;
    '16x9_trans': string;
    'promoSpotImg' : string;
  };
  videos: {
    videoIds: string[];
  };
  collectionType: CollectionType;
  isHidden: boolean;
  isPublished: boolean;
  connectId: string;
  id: string;
}

const initialValues: FormValues = {
  title: '',
  permaLink: '',
  teaserText: '',
  flag: 'None',
  seriesID: '',
  description: '',
  interactive: null,
  instructors: [],
  categories: [],
  genres: '',
  gallery: [],
  graphics: {
    '16x9': '',
    '32x9': '',
    '1x1': '',
    '9x16': '',
    '16x9_trans': '',
    'promoSpotImg': '',
  },
  videos: {
    videoIds: [],
  },
  collectionType: CollectionType.CONCEPT,
  isHidden: false,
  isPublished: false,
  connectId: '',
  id: '',
};

export enum FormType {
  ADD = 'add',
  EDIT = 'edit',
}

interface IConceptFormDialog {
  isOpen: boolean;
  onClose: (
    event: React.SyntheticEvent,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  title: string;
  id: string;
  type: FormType;
  classes: Record<
    | 'dialogContainer'
    | 'imageContainer'
    | 'videoHeader'
    | 'classWrapper'
    | 'form'
    | 'dialogContent'
    | 'mr6'
    | 'ml6'
    | 'dialogActions'
    | 'videoHeaderContainer'
    | 'noData'
    | 'styledTab'
    | 'packageWrapper'
    | 'viewPackages'
    | 'errorText'
    | 'subInTitle'
    | 'package'
    | 'subTitle'
    | 'instructorGrid'
    | 'bottomText',
    string
  >;
}

function ConceptFormDialog({
  isOpen,
  onClose,
  title,
  classes,
  id,
  type,
}: IConceptFormDialog) {
  let [possibleOptions, setPossibleOptions] = React.useState({
    flags: [],
    instructors: [],
    categories: [],
    interactiveInstructors: [],
  });
  let [initialFormValues, setInitialFormValues] = React.useState(initialValues);
  let [packagesList, setPackages] = React.useState(null);
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  const [isConfirmPublishOpen, openConfirmPublishDialog] = React.useState(
    false,
  );
  const [isLoading, setLoader] = React.useState(false);
  const [isConnectIdErr, setConnectIdErr] = React.useState(false);
  const [isHideAlertOpen, showAlertToHide] = React.useState(false);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [isPublish, setPublish] = React.useState(false);
  const [savedFormData, setFramedData] = React.useState({});
  const [isInteractiveConcept, setIsInteractiveConcept] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [copyIdBtnText, setCopyIdBtnText] = React.useState('Copy');
  const dispatch = useDispatch();

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const tabConfig = [
    {
      label: 'VOD',
      component: <div></div>,
    },
    {
      label: 'INTERACTIVE',
      component: <div></div>,
    },
  ];

  const _formatOptions = result => {
    let {
      flag,
      instructors,
      categories,
      data,
      genres,
      interactiveInstructors,
    } = result;

    flag = flag.map(f => ({
      label: f.title,
      value: f.title,
    }));
    flag = [{ label: 'None', value: 'None' }, ...flag];

    instructors =
      Array.isArray(instructors) && instructors.length !== 0
        ? instructors.map(i => ({
            id: i.id,
            title: `${i && i.firstName ? i.firstName : ''} ${
              i && i.lastName ? i.lastName : ''
            }`,
          }))
        : [];

    interactiveInstructors =
      Array.isArray(interactiveInstructors) &&
      interactiveInstructors.length !== 0
        ? interactiveInstructors.map(i => ({
            id: i.id,
            title: `${i && i.firstName ? i.firstName : ''} ${
              i && i.lastName ? i.lastName : ''
            }`,
          }))
        : [];

    categories = categories.map(g => ({
      id: g.id,
      title: g.title,
    }));
    let {
      instructors: conceptInstructors = [],
      categories: conceptCategories = [],
      interactive: conceptpInteractive = {},
    } = data;

    data.instructors = instructors.filter(i =>
      conceptInstructors.includes(i.id),
    );
    data.categories = categories.filter(i => conceptCategories.includes(i.id));

    try {
      if (data.videos && !data.videos.videoIds) {
        data.videos = {
          videoIds: [],
        };
      }
      if (conceptpInteractive && conceptpInteractive.categories) {
        data.interactive.categories = categories.filter(i =>
          conceptpInteractive.categories.includes(i.id),
        );
      } else {
        data.interactive.categories = [];
      }

      if (conceptpInteractive && conceptpInteractive.instructors) {
        data.interactive.instructors = instructors.filter(i =>
          conceptpInteractive.instructors.includes(i.id),
        );
      } else {
        data.interactive.instructors = [];
      }
    } catch (error) {}

    return {
      flags: flag,
      instructors,
      categories,
      data,
      genres,
      interactiveInstructors,
    };
  };

  const _init = async () => {
    try {
      let { result: detail } = await request({
        url: '/neouadmin/v1/concept-detail',
        method: 'GET',
        params: { id },
      });
      let { data, ...otherOptions } = _formatOptions(detail);
      setPossibleOptions(otherOptions);
      setInitialFormValues({ ...initialValues, ...data });
      const isInteractive = data.interactive == null ? false : true;
      setIsInteractiveConcept(isInteractive);
      let { result } = await request({
        url: `/neouadmin/v1/get-stripe-packages?conceptId=${id}`,
        method: 'GET',
      });
      setPackages(result);
    } catch (error) {
      dispatch(openSnackBar('Error in fetching the concept info..'));
    }
  };

  React.useEffect(() => {
    _init();
  }, [id]);

  const frameData = (values: any, isInteractiveObject: boolean = true) => {
    let framedData: FormValues = {
      ...values,
      collectionType: CollectionType.CONCEPT,
      instructors: values.instructors ? values.instructors.map(i => i.id) : [],
      categories: values.categories ? values.categories.map(i => i.id) : [],
      videos: {
        videoIds: [],
      },
      flag: values.flag === 'None' ? '' : values.flag,
      interactive:
        values.interactive === null
          ? values.interactive
          : {
              ...values.interactive,
              instructors:
                values.interactive && values.interactive.instructors
                  ? values.interactive.instructors.map(i => i.id)
                  : [],
              categories:
                values.interactive && values.interactive.categories
                  ? values.interactive.categories.map(i => i.id)
                  : [],
              videos: { videoIds: [] },
            },
    };

    if (values.videos && values.videos.videoIds && values.videos.videoIds) {
      framedData.videos.videoIds = values.videos.videoIds.map(
        (v, index) => v.id,
      );
    }

    if (isInteractiveConcept && isInteractiveObject) {
      if (
        values.interactive &&
        values.interactive.videos &&
        values.interactive.videos.videoIds
      ) {
        framedData.interactive.videos.videoIds = values.interactive.videos.videoIds.map(
          v => v.id,
        );
      }
    }

    return framedData;
  };

  const saveConcept = async formData => {
    try {
      if (activeTab === 1 && formData && formData.interactive) {
        formData.interactive.updatedAt = moment().format(
          'YYYY-MM-DDTHH:mm:ssZ',
        );
        if (formData.interactive && formData.interactive.isPublished)
          formData.interactive.publishedDate = moment().format(
            'YYYY-MM-DDTHH:mm:ssZ',
          );
        if (
          !formData.isPublished &&
          formData.interactive &&
          !formData.interactive.isPublished
        ) {
          delete formData.isPublished;
        }
        delete formData.interactive.videos;
      } else if (activeTab === 0 && formData) {
        if (
          !formData.isPublished &&
          formData.interactive &&
          !formData.interactive.isPublished
        ) {
          delete formData.interactive.isPublished;
        }
      }

      let body = formData || savedFormData;
      if (activeTab === 0 && body && body.interactive) {
        delete body.interactive;
      }

      await request({
        url: '/neouadmin/v1/concepts',
        method: type === FormType.ADD ? 'POST' : 'PUT',
        data: body,
      });
      setLoader(false);
      if (isPublish) {
        dispatch(openSnackBar('The concept has been published successfully!'));
      } else {
        dispatch(openSnackBar('The concept has been saved successfully!'));
      }
      dispatch(requestConceptList());
      onClose();
    } catch ({ data: error }) {
      setLoader(false);
      if (error.error) {
        dispatch(openSnackBar(`Failed to save concept - ${error.error}`));
      } else {
        dispatch('Error in saving the concept..');
      }
    }
  };

  const handleSubmit = async (values: FormValues): Promise<any> => {
    // update status as default
    let f = frameData(values);
    if (isPublish) {
      if (activeTab === 1) {
        f.interactive.isHidden = Boolean(false);
        f.interactive.isPublished = Boolean(true);
        f.interactive.updatedAt = moment().format('YYYY-MM-DDTHH:mm:ssZ');
        f.interactive.publishedDate = moment().format('YYYY-MM-DDTHH:mm:ssZ');

        // if (f.interactive && !f.interactive.connectId) {
        //   setConnectIdErr(true);
        //   return;
        // }
      } else {
        f.isHidden = Boolean(false);
        f.isPublished = Boolean(true);
      }
      setFramedData({ ...f });
      saveConcept(f);
    } else {
      if (activeTab === 1) {
        f.interactive.isHidden = Boolean(f.interactive.isHidden);
        f.interactive.isPublished = Boolean(f.interactive.isPublished);
        f.interactive.updatedAt = moment().format('YYYY-MM-DDTHH:mm:ssZ');
        if (f.interactive && f.interactive.isPublished) {
          f.interactive.publishedDate = moment().format('YYYY-MM-DDTHH:mm:ssZ');
          // if (f.interactive && !f.interactive.connectId) {
          //   setConnectIdErr(true);
          //   return;
          // }
        }
      } else {
        f.isHidden = Boolean(f.isHidden);
        f.isPublished = Boolean(f.isPublished);
      }
      setFramedData({ ...f });
      if (f.isPublished) {
        // confirm publish
        openConfirmPublishDialog(true);
      } else if (f.interactive && f.interactive.isPublished) {
        // confirm publish
        openConfirmPublishDialog(true);
      } else {
        saveConcept(f);
      }
    }
  };
  const hideConcept = async (
    values: FormValues,
    { submitForm, errors, setErrors, setFieldValue, setTouched },
  ) => {
    let f = frameData(values);
    if (activeTab === 1) {
      f.interactive.isHidden = Boolean(true);
      f.interactive.isPublished = Boolean(false);
      f.interactive.updatedAt = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    } else {
      f.isHidden = Boolean(true);
      f.isPublished = Boolean(false);
    }
    setFramedData({ ...f });
    // setFieldValue('isHidden', true);
    // setFieldValue('isPublished', false);
    setLoader(true);
    try {
      await request({
        url: '/neouadmin/v1/concepts',
        method: type === FormType.ADD ? 'POST' : 'PUT',
        data: f || savedFormData,
      });
      setLoader(false);
      dispatch(
        openSnackBar('The concept status has been updated successfully!'),
      );
      dispatch(requestConceptList());
      onClose();
    } catch ({ data: error }) {
      setLoader(false);
      dispatch(openSnackBar(`Failed to hide concept - ${error.error}`));
    }
  };

  const onOverflowMenuClick = option => {
    if (option === 'Delete All') {
      // Open a confirmation modal to delete all the videos
      openConfirmDialog(true);
      // on click of OK, delete all the videos
    }
  };

  const previewConcept = tab => {
    window.open(`/concept-preview/${id}/${tab}`);
  };

  const enableForInteractive = async (
    values: FormValues,
    { submitForm, errors, setErrors, setFieldValue, setTouched },
  ) => {
    const framedData = frameData(values, false);
    const payload = {
      id: id,
      interactive: {
        ...framedData,
        isPublished: false,
        isHidden: false,
        createdAt: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        updatedAt: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      },
    };
    saveConcept(payload);
  };
  const getTextAreaContent = () => {
    let copyTextarea = id;
    navigator.clipboard.writeText(copyTextarea).then(
      function() {
        setCopyIdBtnText('Copied');
      },
      function(err) {
        setCopyIdBtnText('Copy');
        dispatch(openSnackBar('Could not copy concept Id!'));
      },
    );
  };

  return (
    <>
      {isLoading && <Loader fullWidth />}

      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick={true}
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers classes={{ root: classes.dialogContent }}>
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the concept."
            onAgree={() => onClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <StyledTabs
            value={activeTab}
            onChange={onTabChange}
            aria-label="My account tabs"
            centered
            className={classes.styledTab}
          >
            {tabConfig.map((t, index) => (
              <StyledTab key={index} label={t.label} />
            ))}
          </StyledTabs>
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            validationSchema={
              activeTab === 1 ? InteractiveConceptSchema : ConceptSchema
            }
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              submitForm,
              errors,
              isValid,
              setErrors,
              validateForm,
              setTouched,
            }) => {
              return (
                <Form noValidate>
                  {activeTab === 1 && !isInteractiveConcept ? (
                    <Grid
                      container
                      alignItems="flex-start"
                      justify={'center'}
                      style={{ minHeight: '500px' }}
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={8}
                        alignItems="flex-start"
                        justify={'center'}
                        container
                      >
                        <div className={classes.noData}>
                          This concept does not currently appear on NEOU
                          Interactive.
                        </div>
                        <Button
                          variant="contained"
                          onClick={() =>
                            enableForInteractive(values, {
                              submitForm,
                              errors,
                              isValid,
                              setErrors,
                              validateForm,
                              setFieldValue,
                              setTouched,
                            })
                          }
                          color="secondary"
                          size="large"
                          disabled={!!id ? false : true}
                        >
                          ENABLE FOR NEOU INTERACTIVE
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      alignItems="flex-start"
                      spacing={2}
                      style={{ marginTop: 8 }}
                    >
                      <Grid item xs={activeTab === 1 ? 6 : 4} container>
                        <FormikField
                          name={activeTab === 0 ? 'title' : 'interactive.title'}
                          label="Title"
                          placeholder="Enter a title"
                          onBlur={() => {
                            setFieldValue(
                              'permaLink',
                              '/concepts/' + slugify(values.title),
                            );
                          }}
                        ></FormikField>

                        <FormikField
                          name={
                            activeTab === 0
                              ? 'permaLink'
                              : 'interactive.permaLink'
                          }
                          label="Permalink"
                          placeholder="Enter a permalink"
                        />
                        {activeTab === 0 && (
                          <FormikField
                            name="teaserText"
                            label="TeaserText"
                            placeholder="Enter a Teaser Text"
                          />
                        )}

                        {activeTab === 0 && (
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormikField
                                name="seriesID"
                                label="ID"
                                placeholder="Enter an ID"
                              />
                            </Grid>

                            <Grid item xs={6}>
                              <FormikSelect
                                name="flag"
                                label="Flag"
                                items={possibleOptions.flags}
                              />
                            </Grid>
                          </Grid>
                        )}

                        <FormikAutocomplete
                          name={
                            activeTab === 0
                              ? 'instructors'
                              : 'interactive.instructors'
                          }
                          label="Instructors"
                          placeholder="Add"
                          items={
                            activeTab === 0
                              ? possibleOptions.instructors
                              : possibleOptions.interactiveInstructors
                          }
                          multiple={true}
                        />

                        <FormikAutocomplete
                          name={
                            activeTab === 0
                              ? 'categories'
                              : 'interactive.categories'
                          }
                          label="Categories"
                          placeholder="Add"
                          items={possibleOptions.categories}
                          multiple={true}
                        />

                        <FormikField
                          name={
                            activeTab === 0 ? 'genres' : 'interactive.genres'
                          }
                          label="Display Genre"
                          placeholder="Enter Genres"
                        />

                        {activeTab === 0 && (
                          <>
                            <FormikField
                              name="description"
                              label="Description"
                              placeholder="Enter a description"
                              multiline
                            />
                          </>
                        )}

                        {isInteractiveConcept && activeTab === 1 && (
                          <>
                            <FormikSelect
                              name="interactive.flag"
                              label="Flag"
                              items={possibleOptions.flags}
                            />
                            <div className={classes.instructorGrid}>
                              <div style={{ width: '80%' }}>
                                <FormikField
                                  name="id"
                                  label="Concept Id"
                                  disabled={true}
                                />
                              </div>
                              <ButtonBase
                                className={classes.viewPackages}
                                onClick={getTextAreaContent}
                              >
                                {copyIdBtnText}
                              </ButtonBase>
                            </div>
                            <>
                              <FormikField
                                name="interactive.connectId"
                                label="Connect Id"
                                placeholder="Enter Connect Id"
                                // onBlur={() => {
                                //   setConnectIdErr(false);
                                // }}
                              />
                              {/* {isConnectIdErr && (
                                <div className={classes.errorText}>
                                  <ErrorText>{`Enter Connect Id`}</ErrorText>
                                </div>
                              )} */}
                            </>

                            {packagesList && packagesList.length > 0 && (
                              <>
                                <div className={classes.package}>
                                  <Typography variant="h6">PACKAGES</Typography>
                                </div>

                                <div>
                                  <Box
                                    component="span"
                                    className={classes.subInTitle}
                                  >
                                    <Grid container>
                                      {packagesList &&
                                        packagesList.map(data => {
                                          return (
                                            <div
                                              className={classes.packageWrapper}
                                            >
                                              {data.nickname}
                                              {data.currency === 'usd'
                                                ? ' $'
                                                : ' $'}
                                              {data.unit_amount / 100}.
                                              {data.unit_amount_decimal
                                                .toString()
                                                .substr(-2)}
                                              <ViewPackage
                                                data={data}
                                                nickname={data.nickname}
                                                description={
                                                  data.metadata.description
                                                }
                                                classes={data.metadata.classes}
                                                price={`${
                                                  data.currency === 'usd'
                                                    ? ' $'
                                                    : ' $'
                                                }${data.unit_amount /
                                                  100}.${data.unit_amount_decimal
                                                  .toString()
                                                  .substr(-2)}`}
                                                id={data.id}
                                              />
                                            </div>
                                          );
                                        })}
                                    </Grid>
                                  </Box>
                                </div>
                              </>
                            )}

                            {isInteractiveConcept &&
                              activeTab === 1 &&
                              (values &&
                                values.interactive &&
                                values.interactive.promocodes &&
                                values.interactive.promocodes.length > 0 && (
                                  <div className={classes.bottomText}>
                                    <Grid item>
                                      <Typography variant="h6">
                                        ACTIVE COUPONS
                                      </Typography>
                                      {values.interactive.promocodes.map(
                                        coupon => {
                                          return (
                                            <div>
                                              <Box
                                                component="span"
                                                className={classes.subInTitle}
                                              >
                                                {coupon.uniqueCode}
                                              </Box>
                                              <HistoryText>
                                                {' '}
                                                {`Valid ${moment(
                                                  coupon.startDate,
                                                ).format(
                                                  'MM/DD/YYYY',
                                                )} - ${moment(
                                                  coupon.endDate,
                                                ).format('MM/DD/YYYY')}`}
                                              </HistoryText>
                                            </div>
                                          );
                                        },
                                      )}
                                    </Grid>
                                  </div>
                                ))}
                          </>
                        )}

                        {type === FormType.EDIT && (
                          <>
                            {isInteractiveConcept && activeTab === 1 ? (
                              <Grid item>
                                <Typography variant="h6">HISTORY</Typography>
                                <HistoryText>
                                  Added:{' '}
                                  {moment(
                                    values.interactive &&
                                      values.interactive.createdAt,
                                  ).format('MM/DD/YYYY')}
                                </HistoryText>
                                {values.interactive &&
                                  values.interactive.isPublished && (
                                    <HistoryText>
                                      Published:
                                      {moment(
                                        values.interactive &&
                                          values.interactive.publishedDate,
                                      ).format('MM/DD/YYYY')}
                                    </HistoryText>
                                  )}
                                <HistoryText>
                                  Updated:{' '}
                                  {moment(
                                    values.interactive &&
                                      values.interactive.updatedAt,
                                  ).format('MM/DD/YYYY')}
                                </HistoryText>
                              </Grid>
                            ) : (
                              <Grid item>
                                <Typography variant="h6">HISTORY</Typography>
                                <HistoryText>
                                  Added:{' '}
                                  {moment(values.createdAt).format(
                                    'MM/DD/YYYY',
                                  )}
                                </HistoryText>
                                {values.isPublished && (
                                  <HistoryText>
                                    Published:
                                    {moment(values.publishedDate).format(
                                      'MM/DD/YYYY',
                                    )}
                                  </HistoryText>
                                )}
                                <HistoryText>
                                  Updated:{' '}
                                  {moment(values.updatedAt).format(
                                    'MM/DD/YYYY',
                                  )}
                                </HistoryText>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>

                      <Grid item xs={activeTab === 1 ? 4 : 4} container>
                        {isInteractiveConcept && activeTab === 1 ? (
                          <>
                            <FormikField
                              name="interactive.description"
                              label="Description"
                              placeholder="Enter a description"
                              multiline
                            />
                            <FormikGallery
                              galleryItems={
                                activeTab === 1
                                  ? values.interactive.gallery
                                  : values.gallery
                              }
                              albumName="concept"
                              title="MANAGE CONCEPT GALLERY"
                              name={
                                activeTab === 1
                                  ? 'interactive.gallery'
                                  : 'gallery'
                              }
                            />

                            <LabelText>Merchandising Graphics</LabelText>
                            <FormikImageUploader
                              name={
                                activeTab === 1
                                  ? 'interactive.graphics.16x9'
                                  : 'graphics.16x9'
                              }
                              aspectRatio="16x9"
                              key="16x9"
                              title="16:9 Banner *"
                              defaultValue={
                                activeTab === 1
                                  ? values.interactive.graphics &&
                                    values.interactive.graphics['16x9']
                                  : values.graphics && values.graphics['16x9']
                              }
                            />
                          </>
                        ) : (
                          <>
                            <FormikGallery
                              galleryItems={values.gallery}
                              albumName="concept"
                              title="MANAGE CONCEPT GALLERY"
                            />

                            <LabelText>Merchandising Graphics</LabelText>
                            <FormikImageUploader
                              name="graphics.16x9"
                              aspectRatio="16x9"
                              key="16x9"
                              title="16:9 Banner *"
                              defaultValue={
                                values.graphics && values.graphics['16x9']
                              }
                            />

                            <FormikImageUploader
                              name="graphics.promoSpotImg"
                              aspectRatio="promoSpotImg"
                              key="promoSpotImg"
                              title="1300x460 Banner *"
                              ispromoValidation
                              defaultValue={
                                values.graphics && values.graphics['promoSpotImg']
                              }
                            />

                            <FormikImageUploader
                              name="graphics.32x9"
                              aspectRatio="32x9"
                              key="32x9"
                              title="32:9 Wide Banner *"
                              defaultValue={
                                values.graphics && values.graphics['32x9']
                              }
                            />

                            <Grid container justify="space-between">
                              <Grid xs className={classes.mr6}>
                                <FormikImageUploader
                                  title="9:16 Poster *"
                                  name="graphics.9x16"
                                  aspectRatio="9x16"
                                  key="9x16"
                                  defaultValue={
                                    values.graphics && values.graphics['9x16']
                                  }
                                />
                              </Grid>
                              <Grid xs className={classes.ml6}>
                                <FormikImageUploader
                                  title="1:1 Tile *"
                                  name="graphics.1x1"
                                  aspectRatio="1x1"
                                  key="1x1"
                                  defaultValue={
                                    values.graphics && values.graphics['1x1']
                                  }
                                />
                              </Grid>
                            </Grid>

                            <FormikImageUploader
                              title="16:9 Transparent Video Overlay *"
                              name="graphics.16x9_trans"
                              aspectRatio="16x9"
                              key="16x9_trans"
                              defaultValue={
                                values.graphics && values.graphics['16x9_trans']
                              }
                            />
                          </>
                        )}
                      </Grid>

                      {activeTab === 0 && (
                        <Grid item xs={4} container>
                          <div className={classes.videoHeaderContainer}>
                            <Typography
                              variant="subtitle1"
                              className={classes.videoHeader}
                            >
                              Videos (
                              {activeTab === 0
                                ? (values.videos &&
                                    values.videos.videoIds &&
                                    values.videos.videoIds.length) ||
                                  0
                                : (values.interactive.videos &&
                                    values.interactive.videos.videoIds &&
                                    values.interactive.videos.videoIds
                                      .length) ||
                                  0}
                              )
                            </Typography>
                            {activeTab === 0
                              ? values.videos &&
                                values.videos.videoIds &&
                                values.videos.videoIds.length > 0 && (
                                  <ThreeDotMenu
                                    options={['Delete All']}
                                    onOptionClick={onOverflowMenuClick}
                                  ></ThreeDotMenu>
                                )
                              : values.interactive.videos &&
                                values.interactive.videos.videoIds &&
                                values.interactive.videos.videoIds.length >
                                  0 && (
                                  <ThreeDotMenu
                                    options={['Delete All']}
                                    onOptionClick={onOverflowMenuClick}
                                  ></ThreeDotMenu>
                                )}
                          </div>

                          <FormikSearchVideo
                            videoList={
                              activeTab === 0
                                ? (values.videos && values.videos.videoIds) ||
                                  []
                                : (values.interactive.videos &&
                                    values.interactive.videos.videoIds) ||
                                  []
                            }
                            name={
                              activeTab === 0
                                ? 'videos.videoIds'
                                : 'interactive.videos.videoIds'
                            }
                            searchingInteractiveClasses={
                              activeTab === 1 ? true : false
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}

                  <ConfirmDialog
                    isOpen={isConfirmDialogOpen}
                    headerText="Are you sure?"
                    bodyText="You are about to delete all the videos."
                    handleClose={() => openConfirmDialog(false)}
                    onAgree={() => {
                      activeTab === 0
                        ? setFieldValue('videos.videoIds', [])
                        : setFieldValue('interactive.videos.videoIds', []);
                    }}
                    onDisagree={() => openConfirmDialog(false)}
                  />
                  <ConfirmDialog
                    isOpen={isConfirmPublishOpen}
                    headerText="Are you sure?"
                    bodyText="This content is already published. Changes will impact existing behavior"
                    handleClose={() => openConfirmPublishDialog(false)}
                    onAgree={() => {
                      saveConcept();
                    }}
                    onDisagree={() => openConfirmPublishDialog(false)}
                  />

                  {activeTab === 1 && !isInteractiveConcept ? (
                    <></>
                  ) : (
                    <DialogActions classes={{ root: classes.dialogActions }}>
                      <div>
                        {type === FormType.EDIT &&
                          ((activeTab === 1
                            ? values.interactive.isHidden &&
                              !values.interactive.isPublished
                            : values.isHidden && !values.isPublished) && (
                            <Box display="flex" alignItems="center" mr={1}>
                              <StatusLabel>STATUS</StatusLabel>
                              <StatusText>HIDDEN</StatusText>
                            </Box>
                          ))}
                        {type === FormType.EDIT &&
                          activeTab === 0 &&
                          values.isPublished && (
                            <>
                              <Button
                                color="secondary"
                                onClick={() => showAlertToHide(true)}
                              >
                                Hide
                              </Button>
                              <ConfirmDialog
                                isOpen={isHideAlertOpen}
                                headerText="Are you sure?"
                                bodyText="You are about to hide the concept"
                                onAgree={() =>
                                  hideConcept(values, {
                                    submitForm,
                                    errors,
                                    isValid,
                                    setErrors,
                                    validateForm,
                                    setFieldValue,
                                    setTouched,
                                  })
                                }
                                handleClose={() => showAlertToHide(false)}
                                onDisagree={() => showAlertToHide(false)}
                              ></ConfirmDialog>
                            </>
                          )}
                        {type === FormType.EDIT &&
                          activeTab === 1 &&
                          (values.interactive &&
                            values.interactive.isPublished) && (
                            <>
                              <Button
                                color="secondary"
                                onClick={() => showAlertToHide(true)}
                              >
                                Hide
                              </Button>
                              <ConfirmDialog
                                isOpen={isHideAlertOpen}
                                headerText="Are you sure?"
                                bodyText="You are about to hide the concept"
                                onAgree={() =>
                                  hideConcept(values, {
                                    submitForm,
                                    errors,
                                    isValid,
                                    setErrors,
                                    validateForm,
                                    setFieldValue,
                                    setTouched,
                                  })
                                }
                                handleClose={() => showAlertToHide(false)}
                                onDisagree={() => showAlertToHide(false)}
                              ></ConfirmDialog>
                            </>
                          )}
                      </div>
                      <div>
                        {type === FormType.EDIT && (
                          <>
                            {(activeTab === 1
                              ? !values.interactive.isPublished
                              : !values.isPublished) && (
                              <Button
                                size="large"
                                color="secondary"
                                type="submit"
                                onClick={e => {
                                  if (isValid) {
                                    setPublish(true);
                                  }
                                }}
                              >
                                Publish
                              </Button>
                            )}
                          </>
                        )}
                        <Button
                          color="secondary"
                          onClick={e => {
                            e.preventDefault();
                            previewConcept(
                              activeTab === 1 ? 'Interactive' : 'VOD',
                            );
                          }}
                        >
                          Preview
                        </Button>
                        {/* )} */}
                        <Button
                          variant="contained"
                          type="submit"
                          color="secondary"
                          size="large"
                        >
                          Save & Close
                        </Button>
                      </div>
                    </DialogActions>
                  )}
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withStyles(dialogStyles)(ConceptFormDialog);
