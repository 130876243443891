import React from 'react';
import { Typography } from '@material-ui/core';

export const HistoryText = ({ children }) => (
  <Typography
    style={{
      color: '#060B0F',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      lineHeight: '20px',
    }}
  >
    {children}
  </Typography>
);

export const StatusLabel = ({ children }) => (
  <Typography
    style={{
      fontFamily: 'ProximaNova-Bold',
      fontSize: '15px',
      lineHeight: '20px',
      color: '#12161A',
      marginRight: '8px',
    }}
  >
    {children}
  </Typography>
);

export const StatusText = ({ children }) => (
  <Typography
    style={{
      fontFamily: 'ProximaNova-Bold',
      fontSize: '15px',
      lineHeight: '20px',
      color: '#EA0000',
    }}
  >
    {children}
  </Typography>
);

export const SectionHeader = ({ children, ...props }) => (
  <Typography
    style={{
      fontFamily: 'ProximaNova-Bold',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#060B0F',
      margin: '0 0 8px 0',
    }}
    {...props}
  >
    {children}
  </Typography>
);
export const LabelText = ({ children, ...props }) => (
  <Typography
    style={{
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#060B0F',
      marginBottom: '4px',
    }}
    {...props}
  >
    {children}
  </Typography>
);

export const ErrorText = ({ children, ...props }) => (
  <Typography
    style={{
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#FF1C58',
    }}
    {...props}
  >
    {children}
  </Typography>
);

export const ItalicInfoText = ({ children, ...props }) => (
  <Typography
    style={{
      fontFamily: 'ProximaNova-Medium',
      fontSize: '15px',
      lineHeight: '20px',
      color: '#909599',
      fontStyle: 'italic',
    }}
    {...props}
  >
    {children}
  </Typography>
);
