import * as React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import saga from '../saga';
import reducer from '../reducer';
import makeSelectVideo from '../selectors';
import LabelInput from '../../../components/LabelInput';
import AddButton from '../../../components/AddButton';
import CancelButton from '../../../components/CancelButton';
import { AudioEvent } from '../../../validators/Authentication';
import { formError, customMessage } from '../../../utils/validator';
import StyledDialog from '../../../components/StyledDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { addMutipleAudioTracks } from '.././actions';
import uploadRequest from '../../../shared/lib/uploadRequest';
import { openSnackBar } from '../../Root/actions';
import request from '../../../shared/lib/request';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },

  common: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const stateSelector = createStructuredSelector({
  video: makeSelectVideo(),
});

export default function ManageAudios(props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'video', reducer: reducer });
  useInjectSaga({ key: 'video', saga: saga });
  let { videoId, runtime } = props;
  const { video } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = React.useState({
    musicTitle: '',
    instructorTitle: '',
    mixedTitle: '',
  });
  const [musicTitle, setMusic] = React.useState(props.audioTracks.music || '');
  const [instructorTitle, setInstructor] = React.useState(
    props.audioTracks.instructor || '',
  );
  const [mixedTitle, setMixed] = React.useState(props.audioTracks.mixed || '');
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [payload, setPayload] = React.useState({});

  function handleTitleInput(e, val) {
    let value = { val: e.target.value };
    switch (val) {
      case 'Music':
        setMusic(e.target.value);
        break;
      case 'Instructor':
        setInstructor(e.target.value);
        break;
      case 'Mixed':
        setMixed(e.target.value);
        break;
    }
  }

  const validateForm = () => {
    const { error, value } = AudioEvent.validate(
      { musicTitle, mixedTitle, instructorTitle },
      {
        abortEarly: false,
        messages: customMessage,
      },
    );
    return formError(error);
  };

  const onAddEvent = () => {
    const e = validateForm();
    setError(e);
    let musicString = musicTitle.toString().substr(-4);
    let instString = instructorTitle.toString().substr(-4);
    let mixedString = mixedTitle.toString().substr(-4);
    if (Object.keys(e).length === 0 && musicTitle && musicString !== '.mp3') {
      setError({ musicTitle: 'Music track must be one of [.mp3] Format' });
    } else if (
      Object.keys(e).length === 0 &&
      instructorTitle &&
      instString !== '.mp3'
    ) {
      setError({
        instructorTitle: 'Instructor track must be one of [.mp3] Format',
      });
    } else if (
      Object.keys(e).length === 0 &&
      mixedTitle &&
      mixedString !== '.mp3'
    ) {
      setError({ mixedTitle: 'Mixed track must be one of [.mp3] Format' });
    } else if (
      Object.keys(e).length === 0 &&
      musicTitle &&
      musicString === '.mp3' &&
      instructorTitle &&
      instString === '.mp3' &&
      mixedTitle &&
      mixedString === '.mp3'
    ) {
      let data = {
        videoId: videoId,
        music: musicTitle,
        instructor: instructorTitle,
        mixed: mixedTitle,
      };
      setPayload(data);
      props.selectedValue('audioTracks', {
        music: musicTitle,
        instructor: instructorTitle,
        mixed: mixedTitle,
      });
      showAlertToClose(true);
    }
  };

  async function addMultipleTracks() {
    // dispatch(addMutipleAudioTracks(payload));
    try {
      await uploadRequest({
        url: '/neou/v1/add-audio-tracks',
        method: 'POST',
        data: payload,
      });
      dispatch(openSnackBar('Multiple audio tracks adding is in-progress...'));
      props.onClearEvent();
      props.isAudioAdded();
      saveVideoForm();
    } catch (error) {
      dispatch(
        openSnackBar(
          `Adding audio tracks failed -  ${error.data && error.data.message}`,
        ),
      );
    }
  }

  const saveVideoForm = async () => {
    try {
      const data = {
        contentId: props.contentId,
        audioTracks: {
          music: musicTitle,
          instructor: instructorTitle,
          mixed: mixedTitle,
        },
      };
      await request({
        url: '/neouadmin/v1/videos',
        method: 'PUT',
        data: data,
      });
    } catch (error) {
      dispatch(openSnackBar(`Adding audio tracks failed`));
    }
  };

  return (
    <div>
      <StyledDialog
        isOpen={props.isOpen}
        title={props.title}
        handleClose={() => {
          props.onClearEvent();
        }}
      >
        <div className={classes.container}>
          <>
            <Grid container justify="center">
              <LabelInput
                label="Music"
                placeholder="Please Enter a Music MP3 URL"
                variant="outlined"
                id="custom-css-outlined-input"
                defaultValue={musicTitle}
                fullWidth={true}
                onChange={e => handleTitleInput(e, 'Music')}
                error={!!error.musicTitle}
                helperText={error['musicTitle']}
              />
              <LabelInput
                label="Instructor"
                placeholder="Please Enter a Instructor MP3 URL"
                variant="outlined"
                id="custom-css-outlined-input"
                fullWidth={true}
                defaultValue={instructorTitle}
                onChange={e => handleTitleInput(e, 'Instructor')}
                error={!!error.instructorTitle}
                helperText={error['instructorTitle']}
              />
              <LabelInput
                label="Mixed"
                placeholder="Please Enter a Mixed MP3 URL"
                variant="outlined"
                id="custom-css-outlined-input"
                fullWidth={true}
                defaultValue={mixedTitle}
                onChange={e => handleTitleInput(e, 'Mixed')}
                error={!!error.mixedTitle}
                helperText={error['mixedTitle']}
              />
              <div className={classes.common}>
                <CancelButton
                  key={1}
                  title={'CANCEL'}
                  clearAll={true}
                  onClick={() => {
                    props.onClearEvent();
                  }}
                />
                <AddButton title={'SAVE '} onClick={e => onAddEvent()} />
              </div>
            </Grid>
            {isCloseAlertOpen && (
              <ConfirmDialog
                isOpen={isCloseAlertOpen}
                headerText="Adding multiple audio tracks. Are you sure?"
                bodyText={`Audio track duration & video duration should matches exactly. Ensure Audio tracks duration is ${runtime ||
                  'also same'}`}
                onAgree={() => addMultipleTracks()}
                handleClose={() => showAlertToClose(false)}
                onDisagree={() => showAlertToClose(false)}
              ></ConfirmDialog>
            )}
          </>
        </div>
      </StyledDialog>
    </div>
  );
}
