/**
 *
 * Loader
 *
 */
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

// import styled from 'styles/styled-components';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ldsDualRing: {
      display: 'flex',
      width: 'calc(100% - 266px)',
      background: '#12161a96',
      height: '100vh',
      position: 'fixed',
      top: '0px',
      left: '266px',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 140000,
      [theme.breakpoints.between('xs', 'md')]: {
        width: '100%',
      },
    },
    fullWidth: {
      width: '100%',
      left: 0,
    },
    ldsDualRingLoader: {
      position: 'absolute',
      right: 'calc(50% - 20px)',
      top: 'calc(50% - 20px)',
      zIndex: '10',
      content: '" "',
      display: 'block',
      width: '40px',
      height: '40px',
      margin: '8px',
      borderRadius: '50%',
      border: '2px solid #000000',
      borderColor: '#000000 transparent #000000 transparent',
      animation: `$effect 1.2s linear infinite`,
    },
    '@keyframes effect': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

function Loader(props: Props) {
  const classes = useStyles();
  let isNestView = window.location.href.includes('neou');
  return (
    <div
      className={`${classes.ldsDualRing} ${
        isNestView && !props.fullWidth ? '' : classes.fullWidth
      }`}
    >
      <div className={classes.ldsDualRingLoader} />
    </div>
  );
}

export default Loader;
