import React, { useEffect, useState } from 'react';
import { SliderImage } from '../../../components/SliderImage';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import InstructorExpansionPanel from '../../../components/InstructorExponentialComponent';
import Grid from '@material-ui/core/Grid';
import {
  SvgMyClassThin,
  SvgPlay,
  SvgShare,
  SvgFilter,
} from '../../../components/NeIcons';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import Loader from '../../../components/Loader';
import CollectionSlider from '../../../components/CollectionSlider';
import { FavoriteBtn } from '../../../components/FavoriteBtn';
import { Box, Avatar, IconButton } from '@material-ui/core';
import InstructorHeader from './InstructorHeader';
import { useDispatch, useSelector } from 'react-redux';
import makeSelectPeople from '../selectors';
import { getConceptDetail } from '../actions';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import reducer from '../reducer';
import saga from '../saga';
import { isEmpty, isNull } from 'lodash';
import Card from '@material-ui/core/Card';
import ClassItem from '../../../components/ClassItem';
import ProgramHeader from '../../ProgramDetail/ProgramHeader';
import { H3, H8, H6, H5 } from '../../../styles/Typography';
import ClassCard from '../../../components/ClassCard';
import request from '../../../shared/lib/request';
import { DateTime } from 'luxon';
import { getTimeWithZone } from '../../../utils/calender';
import { appApiURL } from '../../../utils/config';

export interface IInstructorLandingPageProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slider: {
      [theme.breakpoints.up('md')]: {
        borderBottom: 'none',
      },
      borderBottom: '1px solid #222A31',
      boxSizing: 'border-box',
      padding: '16px 0',
    },
    filterWrapper: {
      marginRight: '8px',
      height: '32px',
      width: '89px',
      borderRadius: '6px',
      backgroundColor: '#222A31',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
    },
    filterMargin: {
      marginRight: '8px',
    },
    containerStyle: {
      maxWidth: '1200px',
      margin: 'auto',
      width: '100%',
    },
    AboutInstructor: {
      [theme.breakpoints.up('md')]: {
        borderBottom: 'none',
      },
      borderBottom: '1px solid #222A31',
      boxSizing: 'border-box',
    },
    classHeadWrapper: {
      boxSizing: 'border-box',
      padding: '13px 0px',
      display: 'block',
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '13px 16px',
      },
    },
    instructorItem: {
      [theme.breakpoints.up('xl')]: {
        marginTop: '26px',
      },
      padding: '16px',
      maxWidth: '1200px',
      margin: 'auto',
    },
    instructorBody: {
      borderBottom: '2px solid #050606',
      margin: 'auto',
      position: 'relative',
      [theme.breakpoints.up('xl')]: {
        bottom: '336px',
      },
      [theme.breakpoints.up('md')]: {
        bottom: '348px',
      },
      bottom: '325px',
    },
    leftPanel: {
      [theme.breakpoints.up('md')]: {
        padding: '16px 12px 16px 0px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '22px 16px 22px 0px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '28px 20px 28px 0px',
      },
    },
    rightPanel: {
      [theme.breakpoints.up('md')]: {
        padding: '16px 24px 16px 12px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '22px 32px 22px 16px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '28px 20px 28px 20px',
      },
    },
    loadMoreBtn: {
      textAlign: 'center',
      paddingTop: '8px',
    },
    youMayLikeWrapper: {
      paddingLeft: '16px',
      paddingTop: '16px',
      maxWidth: '1200px',
      margin: 'auto',
      [theme.breakpoints.between('xs', 'sm')]: {
        paddingTop: '8px',
      },
    },
    load: {
      margin: 'auto',
      display: 'flex',
      padding: '16px 0px 8px 0px;',
      [theme.breakpoints.only('xl')]: {
        padding: '32px 0px 24px 0px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '28px 0px 22px 0px',
      },
      '& img': {
        width: '28px',
        height: '28px',
      },
    },
    borderBtm: {
      width: '100%',
      borderBottom: '1px solid #222A31',
    },
    chipBox: {
      padding: '18px',
    },
    classWrapper: {
      maxHeight: 'calc(100vh - 180px)',
      overflow: 'auto',
    },
    contentSection: {
      padding: '0px 16px',
      maxWidth: '1200px',
      margin: 'auto',
    },
    about: {
      height: '20px',
      color: '#fff',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '18px',
      margin: 0,
      // borderBottom: '3px solid rgba(34,42,49,1)',
    },
    favoriteBtn: {
      color: '#fff',
      backgroundColor: '#222A31',
      borderRadius: '6px',
      cursor: 'pointer',
      zIndex: 4,
      '& svg': {
        color: 'white',
      },
    },
    conceptTitle: {
      color: 'rgba(255,255,255,1)',
      fontSize: '12px',
      fontFamily: 'ProximaNova-Semibold',
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    miniConceptImg: {
      width: '104px',
      height: 'auto',
      borderRadius: '5px',
      backgroundColor: 'transparent',
      marginRight: '8px',
      '& img': {
        width: '100%',
      },
    },
    classSet: {
      display: 'initial',
      backgroundColor: 'transparent',
      boxShadow: '0px',
    },
    date: {
      fontSize: '14px',
      fontFamily: 'ProximaNova-Bold',
      color: '#fff',
      textTransform: 'uppercase',
      padding: '11px',
      backgroundColor: '#080B0D',
      borderBottom: '1px solid #35414A',
      borderTop: '1px solid #35414A',
    },
    subHead: {
      color: '#FFFFFF',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '17px',
      lineHeight: '18px',
      borderBottom: '2px solid #222A31',
      paddingBottom: '25px',
    },
    emptyClass: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '240px',

      color: 'rgba(140,148,155,1)',
      '& svg': {
        width: '67px',
        height: '60px',
        marginBottom: '24px',
      },
      '& p': {
        fontStyle: 'italic',
        textAlign: 'center',
        maxWidth: '280px',
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Proxima-Nova-Regular',
      },
    },
    videoThumbanil: {
      position: 'relative',
      '& .MuiIconButton-root': {
        position: 'absolute',
        top: 'calc(50% - 32px)',
        left: 'calc(50% - 32px)',
        width: '64px',
        height: '64px',
        padding: 0,
        [theme.breakpoints.down('md')]: {
          position: 'absolute',
          top: 'calc(50% - 24px)',
          left: 'calc(50% - 24px)',
          width: '48px',
          height: '48px',
        },
        zIndex: 1,
        cursor: 'pointer',
      },
    },
    videoScrollWrap: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '16px',
      maxHeight: '640px',
      overflowY: 'auto',
      marginBottom: '24px',
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '14px 16px',
        marginBottom: '0',
        marginTop: '0',
      },
    },
    shareLinkBtn: {
      cursor: 'pointer',
      height: '42px',
      width: '42px',
      borderRadius: '6px',
      backgroundColor: '#222A31',
      border: '0 solid transparent',
      outline: 'none !important',
      '&:hover': {
        background: 'transparent',
      },
    },
    classHeader: {
      padding: '13px 0px',
      boxSizing: 'border-box',
      borderBottom: '1px solid #222A31',
    },
    readOnly: {
      pointerEvents: 'none',
    },
  }),
);

const stateSelector = createStructuredSelector({
  concept: makeSelectPeople(),
});

export default function InstructorLandingPage(props) {
  useInjectReducer({ key: 'people', reducer: reducer });
  useInjectSaga({ key: 'people', saga: saga });
  const classes = useStyles();
  const { concept } = useSelector(stateSelector);
  // Warning: Add your key to RootState in types/index.d.ts file
  let { conceptDetailInfo = {}, isLoading } = concept;
  let { data = {} } = conceptDetailInfo;
  let { interactive = null } = data;

  const [conceptDetail, setConceptDetail] = React.useState({});
  const [timeStamb, setTimeStamb] = React.useState({});
  const [isPathType, setPathType] = React.useState('');
  const [conceptData, setConcepts] = React.useState([]);
  const [interactiveData, setClasses] = React.useState(null);

  var time = 0;
  useEffect(() => {
    if (time === 0) {
      var timer = setInterval(() => {
        setTimeStamb(moment());
      }, 30000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [time]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props && props.match && props.location.pathname) {
      setPathType(props.match.params.url);
      dispatch(getConceptDetail(props.match.params.id));
    }
  }, [props.match]);

  useEffect(() => {
    async function getConcepts() {
      let { isPublished, isHidden } = data && data;
      let requestURL;
      if (isPublished) {
        requestURL = `${appApiURL}/neou/v1/concepts?permaLink=${data.permaLink}&classOffset=0&classLimit=20`;
      } else if (!isPublished && !isHidden) {
        requestURL = `${appApiURL}/neou/v1/concepts?permaLink=${data.permaLink}&classOffset=0&classLimit=20&isPublished=false&isHidden=false`;
      } else {
        requestURL = `${appApiURL}/neou/v1/concepts?permaLink=${data.permaLink}&classOffset=0&classLimit=20&isPublished=false&isHidden=true`;
      }
      if (data.permaLink) {
        let { result } = await request({
          url: requestURL,
          method: 'POST',
        });
        setConcepts(result);
      }
    }
    getConcepts();
  }, [data.permaLink]);

  useEffect(() => {
    let timezone = DateTime.local().zoneName;
    async function getClasses() {
      if (data.permaLink) {
        let { result } = await request({
          url: `${appApiURL}/neou/v1/concepts?interactive=true&permaLink=${data.permaLink}&timeZone=${timezone}`,
          method: 'POST',
        });
        setClasses(result[0]);
      }
    }
    getClasses();
  }, [data.permaLink]);

  useEffect(() => {
    if (conceptDetailInfo.data) {
      setConceptDetail(conceptDetailInfo.data);
    }
  }, [conceptDetailInfo]);
  let { gallery: vodGallery, videos, title, description, instructors } =
    conceptData && conceptData.length > 0 && conceptData[0];
  let { website, facebook, twitter, instagram } = interactive || {};
  if (isLoading || interactive === undefined || isEmpty(conceptData))
    return <Loader />;
  return (
    <div>
      <div
        style={{
          backgroundColor: '#12161A',
          minHeight: 'calc(100vh - 10px)',
          maxHeight: 'calc(100vh - 10px)',
          overflow: 'auto',
        }}
      >
        <div
          style={{ borderBottom: '1px solid #35414A' }}
          className={classes.readOnly}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.instructorItem}>
                {isPathType !== 'VOD' ? (
                  <InstructorHeader
                    title={interactive && interactive.title}
                    isFavorited={false}
                  />
                ) : (
                  <ProgramHeader title={title} />
                )}
              </Box>
            </Grid>
            <div className={classes.containerStyle}>
              <Grid container className={classes.contentSection}>
                <Grid item xs={12} md={6} className={classes.leftPanel}>
                  {isPathType !== 'VOD' && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid container spacing={7}>
                        <Grid item sm={7} md={8} lg={9}>
                          <p className={classes.about}>
                            About {interactive && interactive.title}
                          </p>
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <FavoriteBtn classes={classes.favoriteBtn} />
                        </Grid>
                        <Grid item sm={1} md={1} lg={1}>
                          <button className={classes.shareLinkBtn}>
                            <SvgShare />
                          </button>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  <div className={classes.slider}>
                    <CollectionSlider>
                      {isEmpty(vodGallery) ? (
                        <Avatar
                          aria-label="recipe"
                          classes={{
                            root: classes.miniConceptImg,
                          }}
                        >
                          <img
                            alt=""
                            src={require('../../../images/16_9 Image Placeholder.svg')}
                          />
                        </Avatar>
                      ) : (
                        vodGallery.map(item => {
                          if (item.type === 'image') {
                            return <SliderImage imgUrl={item} />;
                          } else {
                            return (
                              <div
                                className={classes.videoThumbanil}
                                key={`video_${item}`}
                              >
                                <SliderImage imgUrl={item.thumbnail} />
                                <IconButton>
                                  <SvgPlay />
                                </IconButton>
                              </div>
                            );
                          }
                        })
                      )}
                    </CollectionSlider>
                  </div>
                  <div className={classes.AboutInstructor}>
                    <InstructorExpansionPanel
                      head={isPathType === 'VOD' && 'About this Concept'}
                      body={description}
                      instructors={instructors}
                      socialInsta={instagram}
                      socialFb={facebook}
                      socialTwitter={twitter}
                      socialWeb={website}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className={classes.rightPanel}>
                  {isPathType !== 'VOD' ? (
                    <Grid
                      container
                      item
                      justify={'flex-start'}
                      alignItems={'flex-start'}
                      direction="column "
                      className={classes.classHeadWrapper}
                    >
                      <div className={classes.subHead}>
                        Upcoming Group Interactives
                      </div>
                        <div className={classes.classWrapper}>
                  {!isNull(interactiveData) && interactiveData.videos &&
                  Object.keys(interactiveData.videos).length > 0 ? (
                    Object.keys(interactiveData.videos)
                      .sort((a, b) => (a > b ? 1 : -1))
                      .map((dateKey, index) => {
                        return (
                          <Card className={classes.classSet} key={index}>
                            <div className={classes.date}>
                              {moment(dateKey).calendar(null, {
                                lastDay: '[Yesterday]',
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                lastWeek: '[last] dddd',
                                nextWeek: 'dddd',
                                sameElse: 'L',
                              })}{' '}
                              {moment(dateKey).format('MMM DD')}
                            </div>
                            {interactiveData.videos[dateKey].map(
                              (classItem, index) => {
                                let timezone = getTimeWithZone(
                                  +classItem.startDate,
                                );
                                return (
                                  <ClassItem
                                    key={index}
                                    classItem={classItem}
                                    time={timeStamb}
                                    instructor={{
                                      firstName: interactiveData.title,
                                    }}
                                    time_zone={timezone}
                                  />
                                );
                              },
                            )}
                          </Card>
                        );
                      })
                  ) : (
                    <div className={classes.emptyClass}>
                      <SvgMyClassThin />
                      <p>
                        Classes coming soon! Favorite this concept to get
                        updates.
                      </p>
                    </div>
                  )}
                </div>
                    </Grid>
                  ) : (
                    <>
                      {videos && videos.videoIds && videos.videoIds.length > 0 && (
                        <>
                          <Grid
                            container
                            item
                            // justify={'space-between'}
                            alignItems={'center'}
                            direction="row"
                            className={classes.classHeader}
                          >
                            <H3 style={{ marginRight: '4px' }}>All Classes</H3>
                            <H8>
                              {videos.videoIds.length
                                ? `(${videos.videoIds.length})`
                                : '(0)'}
                            </H8>
                            <div className={classes.filterWrapper}>
                              <H6 className={classes.filterMargin}>Filter</H6>
                              {/* <span onClick={InititalCall}> */}
                              <span>{<SvgFilter />}</span>
                            </div>
                          </Grid>
                          <div className={classes.videoScrollWrap}>
                            {videos &&
                              videos.videoIds &&
                              videos.videoIds.length > 0 &&
                              videos.videoIds.map(item => {
                                return (
                                  <ClassCard
                                    key={item.id}
                                    item={item}
                                    // seriesId={id}
                                    title={data.title}
                                  />
                                );
                              })}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
}
