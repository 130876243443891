import { createSelector } from 'reselect';

import { initialState } from './reducer';
import { ApplicationRootState } from '../../types';

/**
 * Direct selector to the fitnessGoals state domain
 */

const selectFitnessGoalsDomain = (state: ApplicationRootState) => {
  return state.fitnessGoals || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by FitnessGoals
 */

const makeSelectFitnessGoals = () =>
  createSelector(
    selectFitnessGoalsDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectFitnessGoals;
export { selectFitnessGoalsDomain };
