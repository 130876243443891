import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the brandVoice state domain
 */

const selectBrandVoiceDomain = (state: ApplicationRootState) => {
  return state.brandVoice || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by BrandVoice
 */

const makeSelectBrandVoice = () =>
  createSelector(
    selectBrandVoiceDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectBrandVoice;
export { selectBrandVoiceDomain };
