/**
 *
 * PinnedConcepts
 *
 */
import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import { List, arrayMove, arrayRemove } from 'baseui/dnd-list';
import { PinnedTray } from '../PinnedTray';
import { SearchPinnedComponent } from './SearchPinnedComponent';
import request from '../../shared/lib/request';
import Loader from '../Loader';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { getUniqItems } from '../../utils/array';

interface Props {}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    lower: {
      padding: '26px 0px',
    },
    topHead: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '28px',
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    addBtn: {
      padding: '0px',
      height: '40px',
      width: '162px',
      borderRadius: '6px',
      backgroundColor: '#12161A',
    },
    newComponent: {
      paddingLeft: '10px',
    },
    topWrapper: {
      width: '100%',
      padding: '30px 12px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    subtitleLow: {
      width: '100%',
      fontFamily: 'Proxima-Nova-Regular',
      display: 'flex',
    },
    subtitle1: {
      color: '#12161A',
      fontSize: '17px',
      //fontWeight: 600,
      fontFamily: 'ProximaNova-Semibold',
      letterSpacing: 0,
      lineHeight: '24px',
    },
    body2: {
      color: '#000000',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      fontFamily: 'ProximaNova-Medium',
    },
    typeWrap: {
      width: '240px',
      color: '#909599',
      fontFamily: 'ProximaNova-Medium',
      fontSize: '15px',
      //fontWeight: 500,
      lineHeight: '20px',
      float: 'left',
    },
    conceptTitle: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontWeight: 600,
      fontSize: '17px',
      letterSpacing: '0',
      lineHeight: '24px',
    },
    text: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
    },
    imageWrapper: {
      width: '100px',
      borderRadius: '6px',
      marginRight: '16px',
      '& img': {
        width: '100px !important',
      },
    },
    pinnedHeader: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      height: '47px',
      borderBottom: '1px solid #E3E3E4',
    },
    search: {
      width: '328px',
      marginTop: '-30px',
    },
    iconHolder: {
      width: 64,
      height: 64,
    },
    iconSquare: {
      borderRadius: 6,
    },
    iconImg: {
      border: '1px solid #eee',
      background: '#080b0d',
      borderRadius: 6,
    },
    box: {
      height: '64px',
      width: '64px',
      borderRadius: '6px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: '64px',
        height: 'auto',
      },
    },
  }),
);

export default function PinnedConceptComponent(props) {
  const classes = useStyles();
  let { pinnedTray: lowerSection, list, graphics } = props;
  const {
    id,
    handleListChange = () => {},
  } = props;

  const [conceptList, setConceptList] = useState([]);
  const [pinnedConceptsComponent, setPinnedConceptsComponent] = useState([]);
  const [pinnedConceptList, setPinnedConceptList] = useState([]);
  const [isLoading, setLoader] = useState(false);

  const addConceptToList = selectedConcept => {
    pinnedConceptList.push(selectedConcept);
    updatePinnedConcept(pinnedConceptList);
  };

  const updatePinnedConcept = async pinnedConceptList => {
    pinnedConceptList = getUniqItems(pinnedConceptList, 'id');
    pinnedConceptList.map((concept, index) => {
      concept.order = index + 1;
      return concept;
    });
    setPinnedConceptList(pinnedConceptList);
    let dataSection = props.type;
    if (dataSection === 'collections') {
      dataSection = 'interest';
    }
    let newComponent = {
      pageName: 'browse',
      ...pinnedConceptsComponent,
      componentSection: dataSection,
    };
    newComponent.content = {
      ...newComponent.content,
      data: pinnedConceptList.map((concept, index) => {
        concept.order = index + 1;
        return { id: concept.id, order: concept.order };
      }),
    };
    delete newComponent.currentComponentOrder;
    setLoader(true);
    let url = '/neouadmin/v2/pages/';

    try {
      await request({
        url: url,
        method: 'PUT',
        data: newComponent,
      });
      setLoader(false);
      handleListChange(pinnedConceptList);
    } catch (error) {
      setLoader(false);
    }
  };

  const getCategories = pinnedConceptList => {
    if (
      pinnedConceptList &&
      pinnedConceptList.categories &&
      pinnedConceptList.categories.length > 0
    ) {
      let cat = pinnedConceptList.categories.slice(0, 2);
      let result = pinnedConceptList.categories.map(item => {
        return item.title;
      });
      return result.join(', ');
    }
    return [];
  };

  useEffect(() => {
    if (lowerSection && lowerSection.length > 0) {
      let filteredComponents = [];
      if (props.type === 'collections') {
        filteredComponents = lowerSection.filter(
          e => e.componentTitle === 'pinnedInterests',
        );
      } else if (props.type === 'challenge') {
        filteredComponents = lowerSection.filter(
          e => e.componentTitle === 'pinnedChallenges',
        );
      } else if (props.type === 'program') {
        filteredComponents = lowerSection.filter(
          e => e.componentTitle === 'pinnedPrograms',
        );
      } else {
        filteredComponents = lowerSection.filter(
          e => e.componentTitle === 'pinnedConcepts',
        );
      }
      if (filteredComponents && filteredComponents.length > 0) {
        setPinnedConceptsComponent(filteredComponents[0] || []);
        let { content = {} } = filteredComponents[0];
        setPinnedConceptList(content.data);
      }
    }
    // setClassList(pinnedConceptsBuild());
  }, [props]);

  return (
    <Grid className={classes.lower}>
      {isLoading && <Loader />}
      <div className={classes.pinnedHeader}>
        <div className={classes.topHead}>PINNED {props.title}</div>

        <div className={classes.search}>
          <SearchPinnedComponent
            conceptList={conceptList}
            onConceptSelect={addConceptToList}
            id={id}
            type={props.type}
          />
        </div>
      </div>

      <List
        items={pinnedConceptList}
        removable
        onChange={({ oldIndex, newIndex }) =>
          updatePinnedConcept(
            newIndex === -1
              ? arrayRemove(pinnedConceptList, oldIndex)
              : arrayMove(pinnedConceptList, oldIndex, newIndex),
          )
        }
        overrides={{
          Label: {
            component: ({ $value, $index }) => {
              let { title, text, classCount, graphics, categories } = $value;
              return (
                <PinnedTray
                  classes={classes}
                  item={$value}
                  title={title}
                  graphics={graphics && graphics['1x1']}
                  categories={getCategories(pinnedConceptList[$index])}
                  classCount={`${classCount} videos`}
                />
              );
            },
          },
          List: {
            style: ({ $theme }) => {
              return {
                outline: `none`,
              };
            },
          },
          Item: {
            style: ({ $theme, $isDragged }) => {
              return {
                outline: 'none',
                zIndex: '1 !important',
                boxShadow: '0 2px 2px 0 rgba(0,0,0, 0);',
                borderTopColor: '#E3E3E4!important',
                borderBottomColor: '#E3E3E4!important',
                borderLeftColor: '#E3E3E4!important',
                borderRightColor: '#E3E3E4!important',
                borderRightWidth: '1px !important',
                borderLeftWidth: '1px !important',
                borderTopWidth: '1px !important',
                borderBottomWidth: '1px !important',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '8px',
                paddingBottom: '8px',
                borderTopRadius: '6px',
                borderBottomRadius: '6px',
                borderLeftRadius: '6px',
                borderRightRadius: '6px',
                marginBottom: '12px',
                marginBottom: '12px',
                marginBottom: '12px',
                width: '100%',
                mixBlendMode: 'overlay',
              };
            },
          },
          CloseHandle: {
            component: props => (
              <IconButton {...props}>
                <DeleteOutlinedIcon />
              </IconButton>
            ),
          },
        }}
      />
    </Grid>
  );
}
