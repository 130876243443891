import * as React from 'react';
import Slider from 'react-slick';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(() =>
  createStyles({
    slickSlide: {
      '& .img': {
        margin: 'auto',
      },
    },
    root: {
      '& .MuiButtonBase-root': {},
      '& .MuiPaper-root': {
        background: '#12161A',
        borderRadius: '6px',
        boxShadow: '0 0 4px 0 #050606',
      },
      '& .MuiCardMedia-root': {
        borderRadius: '6px',
        boxShadow: '0 0 4px 0 #050606',
        width: '100%',
        height: 'auto',
      },
      '& .MuiCardContent-root': {
        padding: '8px',
        color: '#FFFFFF',
        fontFamily: 'Proxima-Nova-Regular',
      },
      '& .MuiSvgIcon-root': {
        margin: '8px',
      },
    },
    slider: {
      '& .slick-list': {
        height: 'auto',
        width: '100%',
      },
      '& .slick-dots': {
        position: 'relative',
        bottom: '0px',
        margin: 0,
        lineHeight: '15px',
      },
      '& .slick-dots li': {
        width: '6px',
        height: '6px',
        margin: '0 3px',
        // transition: 'all 0.3s cubic-bezier(0.4, 0, 1, 1)',
      },
      '& li.slick-active': {
        width: '24px',
      },
      '& .slick-dots li button:hover:before': {
        opacity: '1',
      },
      '& .slick-dots li button:focus:before': {
        opacity: '1',
      },
      '& .slick-dots li button:before': {
        color: '#35414A',
        opacity: '1',
        content: "' '",
        height: '6px',
        width: '6px',
        background: '#35414A',
        borderRadius: '5px',
      },
      '& .slick-dots li button': {
        width: 'unset',
        height: 'unset',
        padding: '0px',
      },
      '& .slick-dots li.slick-active button:before': {
        width: '24px',
        height: '6px',
        background: 'linear-gradient(136.07deg, #00BBFF 0%, #56FFFE 100%)',
        borderRadius: '3px',
        content: "' '",
      },
    },
  }),
);

export default function CollectionSlider({ children }) {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    cssEase: 'linear',
    initialSlide: 0,
  };
  const classes = useStyles();
  return (
    <div className={classes.slider}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}

export function ConceptSliderImage({ imgUrl }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card>
        <CardActionArea>
          <CardMedia component="img" alt="" image={imgUrl} title="" />
        </CardActionArea>
      </Card>
    </div>
  );
}
