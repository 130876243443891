import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the live state domain
 */

const selectLiveDomain = (state: ApplicationRootState) => {
  return state.live || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Live
 */

const makeSelectLive = () =>
  createSelector(
    selectLiveDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectLive;
export { selectLiveDomain };
