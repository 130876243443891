import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'rgba(5,6,6,0.7)',
    },
    paper: {
      width: '400px',
      maxWidth: '400px',
      borderRadius: '6px',
      backgroundColor: 'white',
      '& .MuiDialogContent-root': {},
    },
    heading: {
      fontWeight: 'bold',
      letterSpacing: 0,
      color: '#12161A',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '18px',
      lineHeight: '20px',
      textAlign: 'left',
    },
    subHeading: {
      color: '#060B0F',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
    },
    okButton: {
      '& .MuiButton-label': {
        color: '#5AA700',
        fontFamily: 'ProximaNova-Bold',
        fontSize: '15px',
        fontWeight: 'bold',
        letterSpacing: '0.3px',
        lineHeight: '20px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    cancelButton: {
      '& span': {
        color: '#5AA700',
        fontFamily: 'ProximaNova-Bold',
        fontSize: '15px',
        fontWeight: 'bold',
        letterSpacing: '1px',
        lineHeight: '20px',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    btnWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0px 16px 16px',
    },
    textArea: {
      background: '#1D2429',
      color: 'white',
      border: 'none',
      textAlign: 'justify',
      resize: 'none',
      fontSize: '16px',
      '&:focus': {
        outline: 'none',
      },
    },
  }),
);

export default function Alert({ alertData, handleCancel, handleOk }) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={alertData}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ root: classes.root, paper: classes.paper }}
      >
        <DialogContent>
          <DialogContentText className={classes.heading}>
            <div className={classes.heading} style={{ marginBottom: '8px' }}>
              Are you Sure?
            </div>
            <div className={classes.subHeading}>
              You’re about to hide this content.
            </div>
          </DialogContentText>
        </DialogContent>
        <div className={classes.btnWrap}>
          <Button
            onClick={handleCancel}
            className={classes.cancelButton}
            disableRipple={true}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOk}
            className={classes.okButton}
            disableRipple={true}
          >
            Yes
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
