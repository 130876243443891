import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { serSortBy, requestConceptList } from './actions';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectVideo from './selectors';
import reducer from './reducer';
import saga from './saga';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    fontFamily: 'Proxima-Nova-Regular',
    '& .MuiSelect-root': {
      boxSizing: 'border-box',
      fontFamily: 'Proxima-Nova-Regular',
      width: '241px',
      border: '1px solid #E3E3E4',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
      padding: '0',
      paddingLeft: '8px',
      height: '38px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      borderLeft: '1px solid #E3E3E4',
    },
    '& .MuiFilledInput-underline:before': {
      content: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
  },
  menuItem: {
    fontFamily: 'Proxima-Nova-Regular',
  },
}));

const stateSelector = createStructuredSelector({
  video: makeSelectVideo(),
});

export default function SortSelect() {
  const classes = useStyles();
  const [sort, setSort] = React.useState('');
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'video', reducer: reducer });
  useInjectSaga({ key: 'video', saga: saga });
  const dispatch = useDispatch();

  const handleChange = event => {
    setSort(event.target.value);
    dispatch(serSortBy(event.target.value));
    dispatch(requestConceptList());
  };

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl}>
        <Select value={sort} onChange={handleChange} displayEmpty>
          <MenuItem value="" classes={{ root: classes.menuItem }}>
            Most Recently Updated
          </MenuItem>
          <MenuItem
            value={'publishedDate=desc'}
            classes={{ root: classes.menuItem }}
          >
            Most Recently Published
          </MenuItem>
          <MenuItem
            value={'createdAt=desc'}
            classes={{ root: classes.menuItem }}
          >
            Most Recently Added
          </MenuItem>
          <MenuItem
            value={'updatedAt=asc'}
            classes={{ root: classes.menuItem }}
          >
            Oldest Updated
          </MenuItem>

          <MenuItem
            value={'publishedDate=asc'}
            classes={{ root: classes.menuItem }}
          >
            Oldest Published
          </MenuItem>
          <MenuItem
            value={'createdAt=asc'}
            classes={{ root: classes.menuItem }}
          >
            Oldest Added
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
