import {
  Button,
  Divider,
  Grid,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  Box,
} from '@material-ui/core';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React from 'react';
import {
  ErrorText,
  ItalicInfoText,
  StatusLabel,
  StatusText,
} from '../../components/Typography';
import { getUniqItems } from '../../utils/array';
import { object, string, array } from 'yup';
import { SearchGenreComponent } from '../../components/searchGenreComponent';
import FormikField from '../../components/Formik/FormikField';
import FormikImageUploader from '../../components/Formik/FormikImageUploader';
import ConfirmDialog from '../../components/ConfirmDialog';
import { requestBrowseGenreList, setLoader, reorderTrays } from './actions';
import request from '../../shared/lib/request';
import { openSnackBar } from '../Root/actions';
import { useDispatch } from 'react-redux';

export const ComponentFormSchema = object({
  title: string().required(),
  imageURL: string().required(),
  genres: array()
    .min(1, 'Enter atleast one item')
    .max(30, 'Enter atmost 30 item')
    .required(),
});

export const ComponentEditor = ({
  componentFormInfo,
  handleEditFormSubmit,
  onDrawerClose,
}) => {
  const [isDeleteAlertOpen, showAlertToDelete] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const addContentList = (list, setFieldValue) => {
    let uniqItems = getUniqItems(list, 'id');
    setFieldValue('genres', uniqItems);
  };

  const errorMessageValue = () => {
    return (
      <ErrorText>
        <ErrorMessage name="genres" />
      </ErrorText>
    );
  };

  const renderSearchComponent = ({ values, setFieldValue }) => {
    return (
      <>
        <SearchGenreComponent
          genreList={values.genres}
          onChange={list => addContentList(list, setFieldValue)}
          errorMessage={errorMessageValue}
        />
      </>
    );
  };

  const handleFormDelete = async id => {
    try {
      dispatch(setLoader(true));
      await request({
        url: `/neouadmin/v1/delete-genre/${id}`,
        method: 'POST',
      });
      dispatch(requestBrowseGenreList());
      dispatch(openSnackBar('Component has been deleted successfully!'));
      dispatch(setLoader(false));
      onDrawerClose();
      let { result } = await request({
        url: `neouadmin/v1/browse-genre`,
        method: 'GET',
      });
      setTimeout(() => {
        dispatch(reorderTrays(result));
      }, 5000);
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(openSnackBar('Component failed to delete!'));
    }
  };

  return (
    <Formik
      initialValues={componentFormInfo && componentFormInfo.item}
      enableReinitialize
      validationSchema={ComponentFormSchema}
      onSubmit={handleEditFormSubmit}
    >
      {({ values, setFieldValue, isValid, errors }) => {
        return (
          <Form noValidate>
            <ConfirmDialog
              isOpen={isDeleteAlertOpen}
              headerText="Are you sure?"
              bodyText="You are about to delete the component."
              onAgree={() => handleFormDelete(values.id)}
              handleClose={() => showAlertToDelete(false)}
              onDisagree={() => showAlertToDelete(false)}
            ></ConfirmDialog>
            <div className={classes.drawerBody}>
              <Grid
                container
                alignItems="flex-start"
                className={classes.editContainer}
              >
                <FormikField
                  name="title"
                  label={'Master Genre Title *'}
                  placeholder="Enter master genre title"
                ></FormikField>
                <div style={{ width: '100%' }}>
                  <div className={classes.imgURL}>
                    <FormikImageUploader
                      id="imageURL"
                      name="imageURL"
                      aspectRatio="16x9"
                      key="16x9"
                      title="Genre Image *"
                      defaultValue={values.imageURL}
                    />
                  </div>
                </div>
                <Grid item xs>
                  <>
                    <Divider />
                    <div style={{ marginBottom: '10px' }}></div>
                    <ItalicInfoText align="center">
                      Manage Associate Genres
                    </ItalicInfoText>
                  </>

                  <FieldArray
                    name="genres"
                    render={arrayHelpers => (
                      <div>
                        {renderSearchComponent({
                          values,
                          setFieldValue,
                        })}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            </div>

            <Divider />
            <div className={classes.ctaWrap}>
              <div className={classes.drawerFooter}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    if (isValid) {
                      setFieldValue('isPublished', true);
                    }
                  }}
                >
                  Publish
                </Button>
              </div>
              <div className={classes.drawerFooter}>
                <Button
                  type="submit"
                  color="secondary"
                  onClick={() => {
                    if (isValid) {
                      setFieldValue('isPublished', false);
                    }
                  }}
                >
                  SAVE DRAFT
                </Button>
              </div>
              <Box display="flex" alignItems="center" mr={1}>
                <StatusLabel>STATUS</StatusLabel>
                <StatusText>
                  {values.isPublished ? 'PUBLISHED' : 'DRAFT'}
                </StatusText>
              </Box>
              {componentFormInfo && componentFormInfo.type === 'EDIT' && (
                <div className={classes.drawerFooter}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      showAlertToDelete(true);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerBody: {
      height: 'calc(100vh - 144px)',
      overflow: 'auto',
    },
    editContainer: {
      margin: '24px',
      width: '92%',
      zIndex: '5',
    },
    imgURL: {
      width: '70%',
      margin: '0px auto',
      height: 'auto',
    },
    ctaWrap: {
      display: 'flex',
    },
    drawerFooter: {
      padding: '16px 6px',
      display: 'flex',
      '& .MuiButton-root': {
        marginRight: 8,
      },
    },
  }),
);
