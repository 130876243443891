import * as React from 'react';
import { Typography, withStyles, Link } from '@material-ui/core';

export const Greet = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'TurbinadoDryRegular',
    fontSize: '40px',
    lineHeight: '49px',
    [breakpoints.up('md')]: {
      fontSize: '49px',
      lineHeight: '60px',
    },
    [breakpoints.up('lg')]: {
      fontSize: '52px',
      lineHeight: '64px',
    },
    [breakpoints.up('xl')]: {
      fontSize: '80px',
      lineHeight: '100px',
    },
  },
}))(Typography);

export const PageTitle = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'uppercase',
    [breakpoints.up('lg')]: {
      fontSize: '18px',
      lineHeight: '20px',
    },
    [breakpoints.up('xl')]: {
      fontSize: '22px',
      lineHeight: '24px',
    },
  },
}))(Typography);

export const H1 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '32px',
    lineHeight: '36px',
    [breakpoints.up('xl')]: {
      fontSize: '38px',
      lineHeight: '42px',
    },
  },
}))(Typography);

export const H2 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '22px',
    lineHeight: '28px',
    [breakpoints.up('xl')]: {
      fontSize: '26px',
      lineHeight: '34px',
    },
  },
}))(Typography);

export const H3 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#56fffe',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '16px',
    lineHeight: '17px',
    [breakpoints.up('xl')]: {
      fontSize: '20px',
      lineHeight: '22px',
    },
  },
}))(Typography);

export const H4 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '18px',
    lineHeight: '22px',
    [breakpoints.up('xl')]: {
      fontSize: '22px',
      lineHeight: '26px',
    },
  },
}))(Typography);

export const H5 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '16px',
    lineHeight: '20px',
    [breakpoints.up('xl')]: {
      fontSize: '22px',
      lineHeight: '24px',
    },
  },
}))(Typography);

export const H6 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '14px',
    lineHeight: '16px',
    [breakpoints.up('xl')]: {
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
}))(Typography);

export const H7 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    fontWeight: '600',
    [breakpoints.up('xl')]: {
      fontSize: '15px',
      lineHeight: '20px',
    },
  },
}))(Typography);

export const H8 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#CDD3D8',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '13px',
    lineHeight: '24px',
    [breakpoints.up('xl')]: {
      fontSize: '16px',
      lineHeight: '30px',
    },
  },
}))(Typography);

export const Body1 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '13px',
    lineHeight: '18px',
    whiteSpace: 'pre-line',
    [breakpoints.up('xl')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
}))(Typography);

export const Body2 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '12px',
    lineHeight: '15px',
    [breakpoints.up('xl')]: {
      fontSize: '15px',
      lineHeight: '20px',
    },
  },
}))(Typography);

export const Body3 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#CDD3D8',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '12px',
    lineHeight: '17px',
    [breakpoints.up('xl')]: {
      fontSize: '16px',
      lineHeight: '18px',
    },
  },
}))(Typography);

export const Label1 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'ProximaNova-Bold',
    fontSize: '11px',
    lineHeight: '13px',
    fontWeight: '600',
    textTransform: 'uppercase',
    [breakpoints.up('md')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    [breakpoints.up('xl')]: {
      fontSize: '15px',
      lineHeight: '17px',
    },
  },
}))(Typography);

export const Label2 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#FFFFFF',
    fontFamily: 'ProximaNova-Bold',
    fontSize: '11px',
    lineHeight: '13px',
    textTransform: 'uppercase',
    [breakpoints.up('xl')]: {
      fontSize: '14px',
      lineHeight: '15px',
    },
  },
}))(Typography);

export const FooterLink = withStyles(({ breakpoints }) => ({
  root: {
    color: '#8B949B',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '9px',
    lineHeight: '12px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    [breakpoints.up('md')]: {
      fontSize: '10px',
      lineHeight: '13px',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
}))(Typography);

export const LinkText = withStyles(({ breakpoints }) => ({
  root: {
    color: '#56FFFE',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '13px',
    lineHeight: '18px',
    '&:hover': {
      textTransform: 'underline',
    },
  },
}))(Link);

export const Label3 = withStyles(({ breakpoints }) => ({
  root: {
    color: '#1D2429',
    fontFamily: 'Proxima-Nova-Regular',
    textAlign: 'center',
    fontSize: '11px',
    lineHeight: '17px',
    fontWeight: 'bold',
    [breakpoints.up('xl')]: {
      fontSize: '38px',
      lineHeight: '42px',
    },
  },
}))(Typography);
