import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the Achievement state domain
 */

const selectAchievementDomain = (state: ApplicationRootState) => {
  return state.achievement || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Achievement
 */

const makeSelectAchievement = () =>
  createSelector(
    selectAchievementDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectAchievement;
export { selectAchievementDomain };
