import React, { useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Button,
  IconButton,
  Avatar,
  Tooltip,
} from '@material-ui/core';
import {
  SvgTwoWayVideo,
  SvgClock,
  SvgLeftArrow,
} from '../../../components/NeIcons';
import moment from 'moment';
import {
  getEndTimeWithZone,
  getLocalDateTime,
  getClassTiming,
  UnivDate,
} from '../../../utils/calender';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionWrapperHeader: {
      width: '100%',
      padding: '0px 16px',
    },
    headerRowNav: {
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      height: '56px',
    },
    backArrowBtn: {
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: '0 solid transparent',
    },
    linkGroup: {
      marginRight: '32px',
      '& button': {
        cursor: 'pointer',
        marginLeft: '15px',
        backgroundColor: 'transparent',
        border: '0 solid transparent',
      },
    },
    sectionWrapper: {
      width: '100%',
      marginBottom: '15px',
      borderTop: '2px solid #050606',
    },
    mainBodySectionWrapper: {
      alignItems: 'flex-start',
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto 20px auto',
      padding: '0 16px',
      color: '#ffffff',
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    gridItemAlign: {
      flexFlow: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    classDetailName: {
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '22px',
      margin: '8px 0px',
    },
    classSubTitle: {
      color: '#FFFFFF',
      fontSize: '11px',
      '& span': {
        marginLeft: '15px',
        color: '#56FFFE',
        fontFamily: 'ProximaNova-Semibold, Helvetica, Arial,sans-serif',
        fontSize: '12px',
        lineHeight: '15px',
        display: 'inline-flex',
        alignItems: 'center',
        '& svg': {
          marginRight: '3px',
        },
      },
    },
    thumbnail: {
      position: 'relative',
      paddingTop: '56.25%',
      margin: '16px 0',
      backgroundColor: '080b0dc7',
      borderRadius: '5px',
      width: '100%',
      '& img': {
        position: 'absolute',
        top: 0,
      },
    },
    classDescription: {
      margin: '0 0 20px 0',
      color: '#ffffff',
      // fontFamily: 'Proxima Nova',
      fontSize: '13px',
      lineHeight: '18px',
      whiteSpace: 'pre-wrap',
    },
    instructorBlock: {
      width: '100%',
    },
    classTimeActive: {
      fontSize: '16px',
      color: '#56FFFE',
      textTransform: 'uppercase',
      fontFamily: 'ProximaNova-Semibold, Helvetica, Arial,sans-serif',
    },
    classCost: {
      fontSize: '16px',
      color: '#CDD3D8',
    },
    classTime: {
      fontSize: '16px',
      color: '#ffffff',
      textTransform: 'uppercase',
      fontFamily: 'ProximaNova-Semibold, Helvetica, Arial,sans-serif',
    },
    instructorTile: {
      alignItems: 'center',
      justifyItems: 'center',
      cursor: 'pointer',
      '&:not(:nth-of-type(1))': {
        marginLeft: '25px',
      },
    },
    sectionTitle: {
      fontSize: '12px',
      textTransform: 'uppercase',
    },
    instructorWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyItems: 'flex-start',
    },
    metaDataSection: {
      padding: '15px 0',
      borderBottom: '1px solid #35414A',
    },
    metaDataHolder: {
      display: 'flex',
      alignItems: 'center',
    },
    metaTitle: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    metaLabeledText: {
      marginRight: '7px',
      fontSize: '12px',
      color: '#ffffff',
      lineHeight: '20px',
      textTransform: 'uppercase',
      fontFamily: 'ProximaNova-Semibold, Helvetica, Arial,sans-serif',
    },
    connector: {
      marginRight: '7px',
      lineHeight: '19px',
      fontSize: '12px',
    },
    metaLabeledDisc: {
      fontSize: '12px',
      color: '#ffffff',
      display: 'inline-flex',
    },
    clock: {
      width: '18px',
      height: '18px',
      marginRight: '10px',
    },
    classLabeldList: {
      listStyle: 'none',
      margin: '0',
      padding: '0',
    },
    metaSectionTitle: {
      fontSize: '17px',
    },
    requiredWrapper: {
      display: 'flex',
      color: '#ffffff',
      fontSize: '12px',
    },
    required: {
      margin: '0 3px',
      color: '#56FFFE',
      fontSize: '20px',
    },
    classSetUpList: {
      listStyle: 'none',
      margin: '20px 0 0 0',
      padding: '0',
      fontSize: '12px',
      color: '#ffffff',
      '& li': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },
      '& li + li': {
        marginTop: ' 15px',
      },
      '& svg': {
        marginRight: '10px',
      },
    },
    equipWrapper: {
      width: '40px',
      display: 'flex',
      alignItems: 'center',
    },
    equipImage: {
      width: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
      marginRight: '10px',
      '& img': {
        margin: '0 auto',
        width: '100%',
        height: '32px',
        objectFit: 'scale-down',
      },
    },
    equipDetail: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '20px',
    },
    conceptTitle: {
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular, Helvetica, Arial, sans-serif',
      fontSize: '18px',
      lineHeight: '22px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    classPlanList: {
      margin: '10px 0 0 0',
      padding: '0',
      fontSize: '13px',
      color: '#ffffff',
      lineHeight: '18px',
      whiteSpace: 'pre-wrap',
    },
    bookedWrapper: {
      margin: '25px 0',
    },
    bookingBtnWrapper: {
      width: '100%',
      '& button + button': {
        marginLeft: '4%',
      },
    },
    bookNowBtn: {
      width: '100%',
      height: '40px',
      fontFamily: 'ProximaNova-Bold, Helvetica, Arial, sans-serif',
      fontSize: '15px',
      margin: '25px 0',
      letterSpacing: '1px',
    },
    soldOutBtn: {
      width: '100%',
      height: '40px',
      fontSize: '16px',
      color: '#646769',
      borderRadius: '6px',
      border: '0 solid transparent',
      background: '#35414A',
    },
    cancelBookedBtn: {
      width: '100%',
      height: '40px',
      fontSize: '16px',
      color: '#ffffff',
      borderRadius: '6px',
      border: '0 solid transparent',
      background: '#35414A',
    },
    instructorPic: {
      width: '43px',
      marginRight: '10px',
      borderRadius: '50%',
    },
    instructorName: {
      fontSize: '13px',
      color: '#ffffff',
    },
    readonly: {
      pointerEvents: 'none',
    },
  }),
);

const ClassDetail = ({
  classDetail,
  readonly = false,
  handleClassBook,
  handleCancelBooking,
}) => {
  let { data } = classDetail;
  const classes = useStyles();

  const [timeStamb, setTimeStamb] = React.useState({});
  var time = 0;

  useEffect(() => {
    if (time === 0) {
      var timer = setInterval(() => {
        setTimeStamb(moment());
      }, 10000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [time]);

  const isWithinTimeToStart = durationInMinutes => {
    let startDate = classDetail.data.startDate;
    let startTime = moment(new Date(startDate));
    let currentTime = moment(Date.now());
    // let startTime = moment(Date.parse('2020-10-22T22:00')); // TODO : Remove line
    let isStarted =
      startTime.diff(currentTime) <= durationInMinutes * 1000 * 60;

    return isStarted;
  };

  let dataX = classDetail && classDetail.data;
  let isStarted =
    dataX &&
    classDetail.data.interactive &&
    classDetail.data.interactive.isStarted;

  let interactiveImage =
    data &&
    data.interactive &&
    data.interactive.bannerImg &&
    data.interactive.bannerImg;

  const isInstructor = dataX && classDetail.data.isInstructorClass;

  //  Get the status for the class
  const classTiming = getClassTiming(
    moment(UnivDate(data.startDate))
      .utc()
      .format(),
    data.duration,
  );
  return (
    <div
      style={{
        overflowY: 'scroll',
        position: 'absolute',
        width: '100%',
        backgroundColor: '#1D2429',
        height: '100%',
      }}
    >
      <div
        style={{ borderBottom: '1px solid #35414A' }}
        className={`${readonly && classes.readonly} `}
      >
        <div className={classes.sectionWrapperHeader}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.headerRowNav}
          >
            <IconButton
              className={classes.backArrowBtn}
              onClick={() => {
                history.goBack;
              }}
            >
              <SvgLeftArrow />
            </IconButton>

            <span className={classes.conceptTitle}>
              {data && data.concept && data.concept.title}
            </span>

            <div className={classes.linkGroup}></div>
          </Grid>
        </div>
        <div className={classes.sectionWrapper}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={4}
            className={classes.mainBodySectionWrapper}
          >
            <Grid item sm={12} md={8} className={classes.gridItemAlign}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item sm={10}>
                  <h1 className={classes.classDetailName}>
                    {data && data.title}{' '}
                    {Array.isArray(data && data.instructors) &&
                      data.instructors.length > 1 &&
                      `with ${data.instructors[0].firstName} ${
                        data.instructors[0].lastName
                      } and ${data && data.instructors[1].firstName} ${data &&
                        data.instructors[1].lastName}`}
                    {Array.isArray(data.instructors) &&
                      data.instructors.length === 1 &&
                      `with ${data.instructors[0].firstName} ${data.instructors[0].lastName}`}
                  </h1>
                  <p className={classes.classSubTitle}>
                    {data && data.videoType === 'groupInteractive' && (
                      <div>
                        GROUP INTERACTIVE
                        {data && data.interactive.type === '2-way video' && (
                          <span>
                            <SvgTwoWayVideo /> 2-way video
                          </span>
                        )}
                      </div>
                    )}
                  </p>
                </Grid>
                <Grid item sm={1}>
                  {/* commented out as the share button will not be added until V2 */}
                  {/* <button
                className={classes.shareLinkBtn}
                onClick={handleShareLink}
              >
                <SvgShare />
              </button> */}
                </Grid>
              </Grid>
              {/* Video */}
              <Avatar
                src={
                  interactiveImage ||
                  (data && data.thumbnail) ||
                  require('../../../images/16_9 Image Placeholder.svg')
                }
                alt={data && data.title}
                className={classes.thumbnail}
              />
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <span
                    className={
                      classTiming.hasStarted && !classTiming.completed
                        ? classes.classTimeActive
                        : classes.classTime
                    }
                  >
                    {/* {' '}
                    {moment(UnivDate(data.startDate)).utc().format()} {''}•{' '}
                  {moment(data.startDate).format('LT')} {''}•{' '} */}
                    {getLocalDateTime(
                      moment(UnivDate(data.startDate))
                        .utc()
                        .format(),
                      'ddd, MMM Do, h:mm A',
                    )}{' '}
                    {''}•{' '}
                    {getEndTimeWithZone(
                      moment(UnivDate(data.startDate))
                        .utc()
                        .format(),
                      data && data.duration,
                    )}
                  </span>
                </Grid>
                <Grid item>
                  {classTiming.showText ? (
                    <span className={classes.classCost}>
                      {data &&
                        data.interactive &&
                        data.interactive.dropInPrice &&
                        `${Number(data.interactive.dropInPrice / 100).toFixed(
                          2,
                        )} Drop In Price`}

                      {data && data.interactive.spotsLeft
                        ? ` • ${data.interactive.spotsLeft} spots left`
                        : ` • 0 spots left`}
                    </span>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              {
                <BtnContainer
                  classDetail={classDetail}
                  isInstructor={isInstructor}
                  isWithinTimeToStart={isWithinTimeToStart}
                  isStarted={isStarted}
                  data={data}
                  classTiming={classTiming}
                  time={timeStamb}
                  handleClassBook={handleClassBook}
                  handleCancelBooking={handleCancelBooking}
                />
              }
              <p className={classes.classDescription}>
                {data && data.description}
              </p>
              <div className={classes.instructorBlock}>
                <h3 className={classes.sectionTitle}>Instructor</h3>
                <div className={classes.instructorWrapper}>
                  {data && data.instructors.length > 0
                    ? data.instructors.map((teacher, index) => {
                        return (
                          <div
                            className={classes.instructorTile}
                            key={index}
                            onClick={() =>
                              history.push(
                                `/neou/neous/instructors${teacher.permaLink}`,
                              )
                            }
                          >
                            <img
                              className={classes.instructorPic}
                              src={
                                teacher.imageUrl ||
                                require('../../../images/Instructor Image Placeholder.svg')
                              }
                              alt="course instructor"
                            />

                            <span className={classes.instructorName}>
                              {teacher.firstName} {teacher.lastName}
                            </span>
                          </div>
                        );
                      })
                    : '-'}
                </div>
              </div>
            </Grid>

            {/* Right Side */}
            <Grid item sm={12} md={4}>
              <div className={classes.metaDataSection}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item className={classes.metaDataHolder}>
                    {Array.isArray(data && data.genres) &&
                      data.genres.length > 1 && (
                        <span className={classes.metaTitle}>
                          <div className={classes.metaLabeledText}>
                            {data.genres[0].title}
                          </div>
                          <p className={classes.connector}> and </p>
                          <div className={classes.metaLabeledText}>
                            {data.genres[1].title}
                          </div>
                        </span>
                      )}
                    <span className={classes.metaLabeledText}>
                      {Array.isArray(data && data.genres) &&
                        data.genres.length === 1 &&
                        data.genres[0].title}
                    </span>
                  </Grid>
                  <Grid item>
                    <span className={classes.metaLabeledDisc}>
                      {data && data.duration && (
                        <>
                          <SvgClock className={classes.clock} />
                          {data && data.duration} min
                        </>
                      )}
                    </span>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.metaDataSection}>
                <ul className={classes.classLabeldList}>
                  <li>
                    <span className={classes.metaLabeledText}>DIFFICULTY</span>
                    <span className={classes.metaLabeledDisc}>
                      {(data && data.difficulty) || '-'}
                    </span>
                  </li>
                  <li>
                    {data && data.bodyFocus && data.bodyFocus.length > 0 && (
                      <>
                        <span className={classes.metaLabeledText}>
                          BODY FOCUS
                        </span>
                        <span className={classes.metaLabeledDisc}>
                          {data && data.bodyFocus.length > 0
                            ? data.bodyFocus.map((node, index) => {
                                return (
                                  <span
                                    className={classes.metaLabeledDisc}
                                    key={index}
                                  >
                                    {index === 0
                                      ? node.title
                                      : ` , ${node.title}`}
                                  </span>
                                );
                              })
                            : '-'}
                        </span>
                      </>
                    )}
                  </li>
                  <li>
                    <span className={classes.metaLabeledText}>IMPACT</span>
                    <span className={classes.metaLabeledDisc}>
                      {(data && data.impact) || '-'}
                    </span>
                  </li>
                  <li>
                    <span className={classes.metaLabeledText}>MUSIC</span>

                    {data && data.music.length > 0
                      ? data.music.map((node, index) => {
                          return (
                            <span
                              className={classes.metaLabeledDisc}
                              key={index}
                            >
                              {index === 0 ? node.title : ` , ${node.title}`}
                            </span>
                          );
                        })
                      : '-'}
                  </li>
                  <li>
                    <span className={classes.metaLabeledDisc}>
                      {data && data.explicitLang
                        ? 'May contain explicit language'
                        : ' '}
                    </span>
                  </li>
                </ul>
              </div>
              {data &&
                data.equipments &&
                ((data.equipments.required &&
                  data.equipments.required.length > 0) ||
                  (data.equipments.optional &&
                    data.equipments.optional.length > 0)) && (
                  <div className={classes.metaDataSection}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <span className={classes.metaSectionTitle}>Set Up</span>
                      </Grid>
                      <Grid item>
                        <div className={classes.requiredWrapper}>
                          <span className={classes.required}>*</span>{' '}
                          <span>required</span>
                        </div>
                      </Grid>
                    </Grid>
                    <ul className={classes.classSetUpList}>
                      {data &&
                        data.equipments &&
                        data.equipments.required &&
                        data.equipments.required.length > 0 &&
                        data.equipments.required.map(
                          (item, index) =>
                            item.imageUrl && (
                              <li className={classes.equipWrapper}>
                                <div className={classes.equipImage}>
                                  <img
                                    src={
                                      item.imageUrl && item.imageUrl.svg
                                        ? item.imageUrl.svg
                                        : item.imageUrl.png
                                    }
                                    alt={item.title}
                                  />
                                </div>
                                <div className={classes.equipDetail}>
                                  <span>{item.title}</span>
                                  <span className={classes.required}>
                                    &nbsp;*
                                  </span>
                                </div>
                              </li>
                            ),
                        )}
                      {data &&
                        data.equipments &&
                        data.equipments.optional &&
                        data.equipments.optional.length > 0 &&
                        data.equipments.optional.map(
                          (item, index) =>
                            item.imageUrl && (
                              <li className={classes.equipWrapper}>
                                <div className={classes.equipImage}>
                                  <img
                                    src={
                                      item.imageUrl && item.imageUrl.svg
                                        ? item.imageUrl.svg
                                        : item.imageUrl.png
                                    }
                                    alt={item.title}
                                  />
                                </div>
                                <div className={classes.equipDetail}>
                                  <span>{item.title}</span>
                                </div>
                              </li>
                            ),
                        )}
                    </ul>
                  </div>
                )}

              {data && data.classPlan && (
                <div className={classes.metaDataSection}>
                  <span className={classes.metaSectionTitle}>Class Plan</span>
                  <div className={classes.classPlanList}>
                    {data && data.classPlan}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ClassDetail;

export interface IBtnContainer {
  isWithinTimeToStart: any;
  isInstructor: any;
  classDetail: any;
  isStarted: any;
  data: any;
  time: any;
  classTiming: any;
  handleClassBook: any;
  handleCancelBooking: any;
}

export function BtnContainer({
  classDetail,
  isInstructor,
  isWithinTimeToStart,
  isStarted,
  data,
  classTiming,
  time,
  handleClassBook,
  handleCancelBooking,
}: IBtnContainer) {
  const classes = useStyles();

  let startDate = classDetail.data && classDetail.data.startDate;
  let startTime = moment(new Date(startDate));
  let currentTime = moment(Date.now());
  let isClassStarted = currentTime.diff(startTime) > 0;
  let suspendTime = currentTime.diff(startTime, 'minutes') > 5;

  const handleJoinClass = () => {
    if (
      data &&
      data.interactive &&
      data.interactive.meetingType === 'zoom' &&
      data.interactive.hostURL
    ) {
      window.open(data.interactive.hostURL);
    } else {
      window.open(`/preview/${data && data.id}`);
    }
  };

  return (
    <div className={classes.bookedWrapper}>
      {isInstructor &&
        !classTiming.completed &&
        (isClassStarted &&
        suspendTime &&
        !Boolean(data && data.interactive && data.interactive.isStarted) ? (
          //class suspended
          <SuspendedTooltip
            title="As Instructor didn't start the class, it has been suspended."
            arrow
          >
            <span>
              <Button disabled style={{ width: '100%' }} color="primary">
                {' '}
                Class Suspended
              </Button>
            </span>
          </SuspendedTooltip>
        ) : (
          <div className={classes.bookingBtnWrapper}>
            <Button
              color="primary"
              disabled={!isWithinTimeToStart(60)}
              className={classes.bookNowBtn}
              onClick={handleJoinClass}
            >
              Start Class {isStarted ? 'again' : ''}
            </Button>
          </div>
        ))}
      {!isInstructor &&
        Boolean(data && data.interactive.isBooked) === false &&
        classTiming.stillTime &&
        data &&
        data.interactive.spotsLeft > 0 && (
          <Button
            color="primary"
            className={classes.bookNowBtn}
            onClick={handleClassBook}
          >
            Book Now
          </Button>
        )}
      {/*Boolean(data?.interactive.isBooked) === true &&
                  classDetail?.data?.isCompleted && (
                    <div className={classes.bookingBtnWrapper}>
                      <button className={classes.soldOutBtn}>Completed</button>
                    </div>
                  )*/}
      {!isInstructor &&
        Boolean(data && data.interactive.isBooked) === false &&
        ((data && !data.interactive.spotsLeft) ||
          (data && data.interactive.spotsLeft === 0)) && (
          <div className={classes.bookingBtnWrapper}>
            <Button
              color="primary"
              disabled={true}
              className={classes.soldOutBtn}
            >
              Sold Out
            </Button>
          </div>
        )}
      {!isInstructor &&
        data &&
        data.interactive.isBooked === true &&
        classTiming.upcomming && (
          <Button
            color="secondary"
            className={classes.cancelBookedBtn}
            onClick={handleCancelBooking}
          >
            Cancel Booking
          </Button>
        )}
      {!isInstructor &&
        data &&
        data.interactive.isBooked === true &&
        classTiming.hasStarted &&
        !classTiming.completed &&
        isClassStarted &&
        suspendTime &&
        !Boolean(data && data.interactive && data.interactive.isStarted) && (
          //class suspended
          <SuspendedTooltip
            title="As Instructor didn't start the class, it has been suspended."
            arrow
          >
            <span>
              <Button disabled style={{ width: '100%' }} color="primary">
                Class Suspended
              </Button>
            </span>
          </SuspendedTooltip>
        )}
      {!isInstructor &&
        data &&
        data.interactive.isBooked === true &&
        classTiming.hasStarted &&
        !classTiming.completed &&
        Boolean(data && data.interactive && data.interactive.isStarted) && (
          // join class
          <>
            <Button
              color="primary"
              className={classes.bookNowBtn}
              onClick={handleJoinClass}
            >
              Join The Class
            </Button>
          </>
        )}
      {!isInstructor &&
        data &&
        !data.interactive.isBooked &&
        !classTiming.completed &&
        classTiming.active && (
          <div className={classes.bookingBtnWrapper}>
            <button className={classes.soldOutBtn}>In Progress</button>
          </div>
        )}
    </div>
  );
}

const useStyles1 = makeStyles(theme => ({
  arrow: {
    color: 'rgb(49, 49, 49);',
  },
  tooltip: {
    backgroundColor: 'rgb(49, 49, 49);',
    color: theme.palette.common.white,
    fontSize: 13,
  },
}));

function SuspendedTooltip(props) {
  const classes = useStyles1();

  return <Tooltip arrow classes={classes} {...props} />;
}
