import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayText: {
      color: '#12161A',
      fontFamily: 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
      fontSize: '10px',
      lineHeight: '13px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    dayCount: {
      color: '#12161A',
      fontFamily: 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
      fontSize: '20px',
      lineHeight: '24px',
      textAlign: 'center',
    },
    selectedText: {
      color: '#FFF',
    },
    DateStyle: {
      fontSize: '20px',
      fontFamily: 'Proxima-Nova-Regular, Helvetica, Arial, sans-serif',
      '@media(max-width:360px)': {
        // fontSize: '14px',
        letterSpacing: '-0.48px',
      },
    },
    DayStyle: {
      //fontFamily: 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
      fontSize: '11px',
      lineHeight: '13px',
      textAlign: 'center',
      textTransform: 'uppercase',
      padding: '0px 18px',
      '@media(max-width:360px)': {
        fontSize: '9px',
      },
      [theme.breakpoints.only('md')]: {
        padding: '0px 0px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '0px 0px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '0px 0px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0px 0px',
        fontSize: '10px',
        // fontFamily: 'Proxima-Nova-Regular, Helvetica, Arial, sans-serif',
      },
    },
    monthStyle: {
      //fontFamily: 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
      fontSize: '11px',
      lineHeight: '13px',
      textAlign: 'center',
      textTransform: 'uppercase',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        // fontFamily: 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
        padding: '0px 14px',
      },
      [theme.breakpoints.down('md')]: {
        // fontFamily: 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
        padding: '0px 0px',
      },
      [theme.breakpoints.only('lg')]: {
        padding: '0px 14px',
      },
      [theme.breakpoints.only('xl')]: {
        padding: '0px 14px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        // fontFamily: 'Proxima-Nova-Regular, Helvetica, Arial, sans-serif',
      },
      '@media(max-width:360px)': {
        fontSize: '10px',
        padding: '0px 2px',
      },
    },
    selectedBtn: {
      background: '#1C2429',
      '&:hover': {
        background: '#1C2429',
      },
    },
    unSelect: {
      background: '#FFFFFF',
      '&:hover': {
        background: '#FFFFFF',
      },
    },
    line: {
      content: '',
      display: 'block',
      marginTop: '2px',
      border: '0.5px solid #12161A',
      boxSizing: 'border-box',
      backgroundColor: '#12161A',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px 0px',
        border: '0.5px solid #12161A',
      },
      [theme.breakpoints.only('md')]: {
        margin: '0px 0px 0px',
      },
    },
    lineWhite: {
      content: '',
      display: 'block',
      marginTop: '2px',
      border: '0.5px solid #8B949B',
      boxSizing: 'border-box',
      backgroundColor: '#8B949B',
      [theme.breakpoints.down('sm')]: {
        margin: '0px 0px 0px',
        border: '0.5px solid #8B949B',
      },
      [theme.breakpoints.only('md')]: {
        margin: '0px 0px 0px',
      },
    },
  }),
);

export default useStyles;
