/*
 *
 * BrandVoice reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  brandVoiceData: {
    id: null,
    activityTracker: {},
    achievementsTxt: [],
    activityUpdatedDate: null,
    achievementsUpdatedDate: null,
    invitePeopletoNeoU: '',
    inviteDisplayText: '',
  },
  isLoading: false,
  isListFetchingError: false,
};

function brandVoiceReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_BRAND_VOICE:
      return { ...state, isLoading: true };
    case ActionTypes.GET_BRAND_VOICE_SUCCESS:
      return {
        ...state,
        brandVoiceData: action.payload,
        isLoading: false,
      };
    case ActionTypes.GET_BRAND_VOICE_FAILURE:
      return { ...state, isListFetchingError: true };
    case ActionTypes.UPDATE_BRAND_VOICE:
      return { ...state, isListFetchingError: true };
    default:
      return state;
  }
}

export default brandVoiceReducer;
