const Joi = require('@hapi/joi');

const SignInSchema = Joi.object({
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ['com', 'net', 'in', 'co'] },
  }),

  password: Joi.string()
    .min(5)
    .max(15),
});

const updatePasswordSchema = Joi.object({
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ['com', 'net', 'in', 'co'] },
  }),
  newPassword: Joi.string()
    .min(5)
    .max(15),

  oldPassword: Joi.string()
    .min(5)
    .max(15),
});

const campaignEvent = Joi.object({
  campaignId : Joi.number().min(5),
});

const emailSchema = Joi.object({
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ['com', 'net', 'in', 'co'] },
  }),
});

const passwordSchema = Joi.object({
  password: Joi.string().pattern(new RegExp('^(.{5,15})$')),
});

const LiveEvent = Joi.object({
  liveStreamtitle: Joi.string().min(1),
  liveStreamURL: Joi.string().min(1),
  liveStreamDate: Joi.string().min(1),
  liveStreamDuration: Joi.number().min(5),
});

const groupEvent = Joi.object({
  classTitle: Joi.string().min(1),
  interactiveURL: Joi.string().min(1),
  classDate: Joi.string().min(1),
});

const VideoEvent = Joi.object({
  videoTitle: Joi.string().min(10),
  // videoTitle: Joi.string().valid('.mp4', '.mov'),
  // videoTitle: Joi.any({
  //   a: Joi.alternatives().try(
  //     Joi.string().pattern(/^a/),
  //     Joi.string().valid('.mp4', '.mov'),
  //   ),
  // }),
});

const AudioEvent = Joi.object({
  musicTitle: Joi.string().min(10),
  instructorTitle: Joi.string().min(10),
  mixedTitle: Joi.string().min(10),
});

export {
  SignInSchema,
  updatePasswordSchema,
  emailSchema,
  passwordSchema,
  LiveEvent,
  groupEvent,
  VideoEvent,
  AudioEvent,
  campaignEvent,
};
