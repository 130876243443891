import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import PasswordField from '../../../components/PasswordField';
import { Button } from '@material-ui/core';
import { encrypt } from '../../../utils/crypto';
import { KEY_BASE64 } from '../../../utils/config';
import { formError, customMessage } from '../../../utils/validator';
import { updatePasswordSchema } from '../../../validators/Authentication';
import { updatePassword } from '../actions';
import { useDispatch } from 'react-redux';

interface Props {}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '37px',
      width: '100%',
    },
    root: {
      maxWidth: '238px !important',
      '& #outlined-basic': {
        color: 'black',
        padding: '15.5px 14px',
      },
      '& .MuiFormLabel-root': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #49494A !important',
        '&:focus': {
          backgroundColor: 'red',
          border: '1px solid #49494A',
        },
      },
      '& .MuiInputBase-input': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
      },
      '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 16px) scale(1)',
        color: '#909599',
        fontSize: '16px',
        fontFamily: 'Proxima-Nova-Regular',
        fontWeight: '500',
        letterSpacing: '0',
        lineHeight: '20px',
      },
      '& label.Mui-focused': {
        color: '#909599',
      },
    },
    passwordField: {
      marginBottom: '21.5px',
      height: '47px',
      width: '100%',
      '& #outlined-basic': {
        padding: '15.5px 14px',
      },
      '& .MuiFormLabel-root': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #49494A',
        '&:focus': {
          backgroundColor: 'red',
          border: '1px solid #49494A',
        },
      },
      '& .MuiInputBase-input': {
        color: '#909599',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
      },
    },
    title: {
      fontSize: '18px',
      marginBottom: '15px',
      lineHeight: '22px',
      height: '30px',
      color: '#0E1215',
      fontFamily: 'Proxima-Nova-Regular',
      fontWeight: 'bold',
    },
    signinBtn: {
      color: '#FFFFFF',
      background: 'black',
      fontSize: '15px',
      height: '20px',
      width: '100%',
      fontFamily: 'Proxima-Nova-Regular',
      fontWeight: 'bold',
      lineHeight: '20px',
      textAlign: 'center',
      '&:hover': {
        background: 'black',
      },
      '&:focus': {
        background: 'black',
      },
    },
  }),
);

export default function UpdatePassword({ email }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = React.useState({
    newPassword: '',
    oldPassword: '',
  });
  const [newPassword, setPassword] = React.useState('');
  const [oldPassword, setPwd] = React.useState('');

  const handlePassword = e => {
    setPassword(e.target.value);
  };
  const handleOldPassword = e => {
    setPwd(e.target.value);
  };

  const validateForm = () => {
    const { error, value } = updatePasswordSchema.validate(
      { oldPassword, newPassword },
      {
        abortEarly: false,
        messages: customMessage,
      },
    );
    return formError(error);
  };

  const onUpdatePassword = event => {
    if (event) {
      event.preventDefault();
    }
    const e = validateForm();

    setError(e);
    const data = {
      email: email,
      oldPassword: encrypt(oldPassword, KEY_BASE64),
      newPassword: encrypt(newPassword, KEY_BASE64),
    };
    if (Object.keys(e).length === 0) {
      dispatch(updatePassword(data));
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.root}>
          <div className={classes.title}>Change Password</div>
          <form noValidate>
            <PasswordField
              label="Current Password"
              className={classes.passwordField}
              variant="outlined"
              password={oldPassword}
              setPassword={e => handleOldPassword(e)}
              error={!!error.oldPassword}
              helperText={error['oldPassword']}
            />
            <PasswordField
              label="New Password"
              className={classes.passwordField}
              variant="outlined"
              password={newPassword}
              setPassword={e => handlePassword(e)}
              error={!!error.newPassword}
              helperText={error['newPassword']}
            />
            <Button
              type="submit"
              color="primary"
              className={classes.signinBtn}
              onClick={e => onUpdatePassword(e)}
            >
              SAVE PASSWORD
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}
