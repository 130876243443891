/**
 *
 * LowerSection
 *
 */
import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { List, arrayMove, arrayRemove } from 'baseui/dnd-list';
import { TraySection } from '../TraySection';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lower: {
      // padding: '26px 20px',
    },
    topHead: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '28px',
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    addBtn: {
      padding: '0px',
      height: '40px',
      width: '162px',
      borderRadius: '6px',
      backgroundColor: '#12161A',
    },
    newComponent: {
      paddingLeft: '10px',
    },
    topWrapper: {
      width: '100%',
      // padding: '30px 12px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    subtitleLow: {
      width: '285px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    subtitle1: {
      color: '#12161A',
      fontSize: '17px',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '24px',
    },
    body2: {
      color: '#000000',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      fontFamily: 'ProximaNova-Medium',
    },
    typeWrap: {
      width: '240px',
      color: '#909599',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '20px',
      float: 'left',
    },
    classWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    videoTitle: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    videoItemCloseButton: {
      marginLeft: '16px',
      padding: '4px',
    },
    videoItemCloseIcon: {
      color: '#D6D9DC',
      width: '20px',
      height: '20px',
    },
  }),
);

function LowerSection(props) {
  const classes = useStyles();
  let {
    lowerSection,
    headLine,
    handleComponentManage,
    handleAddComponent,
    activeSegment = 'No Fitness Profile',
    handleListChanged = () => {},
    showStatus = false,
  } = props;
  const [trayList, setTrayList] = React.useState([]);

  useEffect(() => {
    // Update the document title using the browser API
    let newList = lowerSection.sort((a, b) => {
      if (a.componentOrder || b.componentOrder) {
        if (!a.componentOrder || !b.componentOrder) return -1;
        return a.componentOrder[activeSegment] > b.componentOrder[activeSegment]
          ? 1
          : -1;
      }
    });
    setTrayList(newList);
  }, [props.lowerSection]);

  return (
    <Grid className={classes.lower}>
      <List
        items={trayList}
        removable
        onChange={({ oldIndex, newIndex }) => {
          let newList =
            newIndex === -1
              ? arrayRemove(trayList, oldIndex)
              : arrayMove(trayList, oldIndex, newIndex);
          newList = newList.map((item, index) => ({
            ...item,
            componentOrder: {
              ...item.componentOrder,
              [activeSegment]: index + 3,
            },
          }));
          setTrayList(newList);
          handleListChanged(newList);
        }}
        overrides={{
          List: {
            style: ({ $theme }) => {
              return {
                outline: `none`,
              };
            },
          },
          Label: {
            component: ({ $value }) => (
              <TraySection
                classes={classes}
                item={$value}
                headLine={headLine}
                title={
                  $value.componentTitle ||
                  $value.genreTitle ||
                  $value.title ||
                  ''
                }
                subTitle={
                  $value.userSegments ? `Segments: ${$value.userSegments}` : ''
                }
                showStatus={
                  showStatus
                    ? `Status: ${
                        $value && $value.isPublished ? 'Published' : 'Draft'
                      } `
                    : ''
                }
                componentType={
                  $value.componentType
                    ? `${$value.componentType} : ${
                        $value.content.data ? $value.content.data.length : 0
                      } ${$value.contentType} ${
                        $value.content.mode == 'auto' ? ' (Auto)' : ''
                      }`
                    : $value.genres
                    ? `Associated Genres : ${
                        $value.genres && $value.genres.length > 0
                          ? `${$value.genres.length}`
                          : 0
                      }`
                    : ''
                }
                lastUpdated={
                  $value.updatedAt
                    ? `Last Updated: ${
                        $value.updatedAt
                          ? moment($value.updatedAt).format('MM/DD/YYYY')
                          : ''
                      }`
                    : ''
                }
                handleManage={() => handleComponentManage($value)}
                contentType={$value.contentType ? $value.contentType : ''}
                content={
                  $value && $value.content && $value.content.mode
                    ? $value.content.mode
                    : ''
                }
              />
            ),
          },
          Item: {
            style: ({ $isDragged, $isSelected }) => {
              return {
                outline: 'none',
                boxShadow:
                  $isSelected || $isDragged
                    ? '0 2px 2px 0 rgba(0,0,0,0.5);'
                    : '0 2px 2px 0 rgba(0,0,0, 0);',
                borderLeftColor: '#E3E3E4!important',
                borderRightColor: '#E3E3E4!important',
                borderTopColor: '#E3E3E4!important',
                borderBottomColor: '#E3E3E4!important',
                borderLeftWidth: '1px!important',
                borderRightWidth: '1px!important',
                borderTopWidth: '1px!important',
                borderBottomWidth: '1px!important',
                paddingRight: '8px',
                paddingBottom: '8px',
                paddingTop: '8px',
                paddingLeft: '8px',
                borderRadius: '6px',
                marginBottom: '12px',
                width: '100%',
                backgroundColor: 'rgba(212,216,220,0.5)',
                mixBlendMode: 'overlay',
              };
            },
          },
          CloseHandle: {
            style: ({ $theme }) => {
              return {
                display: 'none',
              };
            },
          },
        }}
      />
    </Grid>
  );
}

export default LowerSection;
