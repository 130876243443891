/*
 *
 * CurateGenre constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/CurateGenre/DEFAULT_ACTION',
  REQUEST_BROWSE_GENRE_LIST = 'REQUEST_BROWSE_GENRE_LIST',
  STORE_BROWSE_GENRE_LIST = 'STORE_BROWSE_GENRE_LIST',
  SET_LOADER = 'SET_LOADER',
  REORDER_TRAYS = 'REORDER_TRAYS',
}

export default ActionTypes;
