import React, { useState } from 'react';
import SearchConcept from './SearchConcepts';
import request from '../../shared/lib/request';

interface ConceptID {
  [key: string]: string;
}

export interface concept {
  id: string;
  title: string;
  conceptID: ConceptID;
}

interface SearchPinnedComponentProps {
  conceptList: concept[];
  onConceptSelect: (newValue: concept[], action: string) => void;
  id?: any;
  type?: any;
}

export function SearchPinnedComponent({
  conceptList,
  onConceptSelect,
  id,
  type = '',
}: SearchPinnedComponentProps) {
  const [isSearchResult, setSearchConcept] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [inputRef, setInputRef] = useState(null);
  let timeout;
  const searchHandleChange = value => {
    timeout = setTimeout(() => {
      setSearchText('');
      inputRef.value = '';
    }, 1000);
    if (value) {
      setSearchText(value.title);
      onConceptSelect(value, 'new');
    }
  };
  function searchConcepts(data) {
    let url = '';
    if (type === 'concept') {
      url = `search-concepts/${data}?isPublished=true`;
    } else if (type !== '') {
      url = `search-collections/${data}?type=${
        type !== 'collections' ? type : 'interest'
      }&isPublished=true`;
    } else {
      url = `search-collections/${data}&isPublished=true`;
    }
    return request({
      url: `/neouadmin/v1/${url}`,
      method: 'GET',
    });
  }
  const onSearchChange = async value => {
    try {
      let { result } = await searchConcepts(value);
      setSearchConcept(result);
    } catch (error) {
      setSearchConcept([]);
    }
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, [conceptList]);

  return (
    <div>
      <SearchConcept
        options={isSearchResult || []}
        label={`Pin a ${
          type !== 'collections'
            ? type.charAt(0).toUpperCase() + type.slice(1)
            : 'Collection'
        }`}
        name="searchConcepts"
        inputValue={searchText}
        onChange={(event, value) => searchHandleChange(value)}
        limitTags={2}
        onInputChange={(event, value) => onSearchChange(value)}
        getOptionLabel={option => option.title}
        onSearchTextChange={e => setSearchText(e)}
        ref={inputRef}
        setInputRef={ref => setInputRef(ref)}
        blurOnSelect
        clearOnBlur
      />
    </div>
  );
}
