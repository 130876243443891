/*
 *
 * InstructorAddClasses reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  videoList: [],
  sortBy: 'startDate=desc',
  activeTab:
    'isPublished=false&isHidden=false&inreview=false&flagged=false&isCancelled=false',
  isListFetchingError: false,
  isLoading: false,
  filterData: [],
  searchResults: [],
  searchSuggestion: [],
  searchResultsCount: 0,
  durationDetails: [],
  videoTotalCount: 0,
  addVideoDialog: false,
  interactiveOnly: false,
};

function instructorAddClassesReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_INTERACTIVE_VIDEO_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.VIDEO_LIST_SUCCESS:
      return {
        ...state,
        videoList: [...action.payload],
        isLoading: false,
        searchResultsCount: 0,
        isListFetchingError: false,
      };
    case ActionTypes.VIDEO_LIST_TOTAL:
      return {
        ...state,
        videoTotalCount: action.payload,
        isLoading: false,
        searchResultsCount: 0,
      };
    case ActionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case ActionTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload, isLoading: true };
    case ActionTypes.VIDEO_LIST_FAILED:
      return { ...state, isListFetchingError: true, isLoading: false };
    default:
      return state;
  }
}

export default instructorAddClassesReducer;
