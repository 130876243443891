// import { take, call, put, select } from 'redux-saga/effects';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import request from '../../shared/lib/request';
import ActionTypes from './constants';
import { selectInstructorAddClassesDomain } from './selectors';
import {
  VideoListSuccess,
  VideoListCountSuccess,
  videoListFailed,
} from './actions';

// Individual exports for testing
export default function* instructorAddClassesSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(
    ActionTypes.REQUEST_INTERACTIVE_VIDEO_LIST,
    getInteractiveVideoList,
  );
}

export function* getInteractiveVideoList(action) {
  try {
    let { sortBy, activeTab } = yield select(selectInstructorAddClassesDomain);
    if (sortBy === '') {
      sortBy = 'startDate=desc';
    }
    if (activeTab === '') {
      activeTab =
        'isPublished=false&isHidden=false&inreview=false&flagged=false&isCancelled=false';
    }
    const splitResult = sortBy.split('=');
    let data = [];
    if (action && action.payload) {
      data = [action.payload];
    }
    if (data !== []) {
      let { result, total } = yield call(() =>
        request({
          url: `/neouadmin/v1/get-videos?interactive=true&${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}`,
          method: 'POST',
          data: { instructors: data },
        }),
      );
      yield put(VideoListSuccess(result));
      yield put(VideoListCountSuccess(total));
    }
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(videoListFailed());
    }
    yield put(videoListFailed());
    yield put(VideoListSuccess([]));
  }
}
