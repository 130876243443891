/*
 *
 * LiveClass constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/LiveClass/DEFAULT_ACTION',
  REQUEST_LIVE_VIDEO_LIST = 'REQUEST_LIVE_VIDEO_LIST',
  STORE_LIVE_VIDEO_LIST = 'STORE_LIVE_VIDEO_LIST',
  SET_LOADER = 'SET_LOADER',
}

export default ActionTypes;
