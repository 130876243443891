import React, { useEffect } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { dynamicSort } from '../../utils/commonFunctions';

export default function ClientList(props) {
  const { styles, clients } = props;

  const sortedClientsList = clients.sort(dynamicSort('firstname'));

  return (
    <Paper className={styles.classRoot}>
      <TableContainer className={styles.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {sortedClientsList &&
              sortedClientsList.length > 0 &&
              sortedClientsList.map(list => {
                return (
                  <TableRow
                    key={list.displayName}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell align="left">
                      <div className={styles.firstColumn}>
                        <div className={styles.firstCoulmContent}>
                          <div>
                            {list&& list.firstname &&<div className={styles.text}>
                             First Name -  {' '} {list.firstname}
                            </div>}
                            {list&& list.displayName &&<div className={styles.text}>
                            User Name - {' '} {list.displayName} 
                            </div>}
                          </div>

                        </div>
                      </div>
                    </TableCell>

                    <TableCell align="left">
                      <div className={styles.text}>{list.email}</div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={styles.genderText}>
                        {`${list.gender ? list.gender.charAt(0) : 'U'}${list.userGroup !== 'No Fitness Profile'
                            ? `/${list.fitnessAnswer}`
                            : '/ N/A'
                          }`}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={styles.text}>{list.userGroup}</div>
                    </TableCell>
                    <TableCell align="center">
                      <div className={styles.text}>{list.isFirstTimeInteractiveUser ? <span className={styles.blackBg}>First-time</span> : ''}</div>
                    </TableCell>

                    {/* This is a CR update the key once api is ready */}
                    {/* <TableCell align="left">
                      <div className={styles.textText}>
                     {list.text ? list.text : ''}
                      </div>
                    </TableCell>  */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
