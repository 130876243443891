import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import uploadRequest from '../../shared/lib/uploadRequest';
import { openSnackBar, closeSnackBar } from '../Root/actions';
import { SLICE_COUNT_OF_CLASSES } from '../../utils/constants';
import moment from 'moment';

import {
  VideoListSuccess,
  videoListFailed,
  filtersFetchSuccess,
  filtersFetchFailed,
  searchSuggestionsSuccess,
  VideoListSuccessWithFilters,
  videoListFailedWithFilters,
  updateSearchCount,
  durationSuccess,
  durationFailed,
  requestVideoList,
  videoListCountSuccess,
  videoListSuccessPagination,
  searchResultsPaginationSuccess,
  updateVideoList,
  videoAddSuccess,
  filterUpdateList,
  setVideoLoader,
  setSearchVideoLoader,
} from './actions';
import { selectVideoDomain } from './selectors';
import { selectInteractivePublishedClassesDomain } from '../InteractivePublishedClasses/selectors';

// Individual exports for testing
export default function* videoSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_VIDEO_LIST, getVideoList);
  yield takeLatest(ActionTypes.UPDATE_VIDEO_LIST, updateVideo);
  yield takeLatest(
    ActionTypes.REQUEST_VIDEO_LIST_PAGINATION,
    getVideoListPagination,
  );
  yield takeLatest(
    ActionTypes.REQUEST_VIDEOS_FILTER_PARAMS,
    fetchVideosSearchFilter,
  );
  yield takeLatest(ActionTypes.SEARCH_SUGGESTIONS, getsearchSuggestions);
  yield takeLatest(ActionTypes.REQUEST_VIDEO_LIST_WITH_FILTERS, searchResults);
  yield takeLatest(
    ActionTypes.VIDEO_LIST_WITH_FILTERS_PAGINATION,
    searchResultsPagination,
  );
  yield takeLatest(ActionTypes.REUPDATE_VIDEO_LIST, getVideoList);
  yield takeLatest(ActionTypes.GET_DURATION_DETAILS, getDurationDetails);
  yield takeLatest(ActionTypes.ADD_LIVE_VIDEO_EVENT, addLiveEvent);
  yield takeLatest(ActionTypes.ADD_VIDEO_EVENT, addVideoEvent);
  yield takeLatest(ActionTypes.RE_ENCODE_VIDEO, reEncodeVideo);
  yield takeLatest(ActionTypes.ADD_MULTIPLE_AUDIO_TRACKS, addAudio);
}

export function* getVideoList() {
  try {
    let { sortBy, activeTab, interactiveOnly } = yield select(
      selectVideoDomain,
    );
    yield put(setVideoLoader(true));
    if (sortBy === '') {
      sortBy = 'updatedAt=desc';
    }
    if (activeTab === '') {
      activeTab = 'isHidden=false';
    }
    const interactiveOnlyParam = interactiveOnly ? 'interactive=true&' : '';
    const splitResult = sortBy.split('=');
    let { result, total } = yield call(() =>
      request({
        url: `neouadmin/v1/get-videos?${interactiveOnlyParam}${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}`,
        method: 'POST',
        data:{videoType: ["generic", "class", null]} 
      }),
    );
    yield put(VideoListSuccess(result));
    yield put(videoListCountSuccess(total));
    yield put(setVideoLoader(false));
  } catch (error) {
    yield put(setVideoLoader(false));
    if (error && error.data && error.data.responseCode === 404) {
      yield put(videoListFailed());
    }
    yield put(videoListFailed());
    yield put(VideoListSuccess([]));
  }
}

export function* updateVideo(action) {
  const { id, load } = action.payload;
  try {
    let { result, total } = yield call(() =>
      request({
        url: `neouadmin/v1/videos?${id}`,
        method: 'PUT',
        data: load,
      }),
    );
    yield put(requestVideoList());
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(videoListFailed());
    }
    yield put(videoListFailed());
  }
}

export function* getVideoListPagination(action) {
  let offset = action.payload;
  try {
    let { sortBy, activeTab } = yield select(selectVideoDomain);
    if (sortBy === '') {
      sortBy = 'updatedAt=desc';
    }
    const splitResult = sortBy.split('=');
    let { result, total } = yield call(() =>
      request({
        url: `neouadmin/v1/get-videos?${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&offset=${offset}&limit=${SLICE_COUNT_OF_CLASSES}`,
        method: 'POST',
        data:{videoType: ["generic", "class", null]} 
      }),
    );
    yield put(videoListSuccessPagination(result));
    yield put(videoListCountSuccess(total));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(VideoListSuccess([]));
    }
    yield put(videoListFailed());
    yield put(VideoListSuccess([]));
  }
}

export function* fetchVideosSearchFilter(action) {
  try {
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/search`,
        method: 'POST',
      }),
    );
    const newObj = {};
    result.filterParams.map(itm => {
      newObj[itm.name] = itm;
    });
    const filterObject = Object.keys(newObj);
    filterObject.map(item => {
      if (item !== 'explicitLang') {
        newObj[item].values &&
          newObj[item].values.map((itm, index) => {
            if (item == 'difficulty') {
              itm.isSelected = true;
              itm.mainTitle = item;
              itm.index = index;
            } else {
              itm.isSelected = false;
              itm.mainTitle = item;
              itm.index = index;
            }
          });
      }
    });
    yield put(filtersFetchSuccess(newObj));
  } catch (error) {
    yield put(filtersFetchFailed());
  }
}

export function* getsearchSuggestions(searchKeyword) {
  const keyword = searchKeyword.payload;
  let { sortBy, activeTab, interactiveOnly } = yield select(selectVideoDomain);
  const interactiveOnlyParam = interactiveOnly ? '?interactive=true' : '';
  try {
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/search/suggestion/${keyword}`,
        method: 'GET',
      }),
    );
    yield put(searchSuggestionsSuccess(result));
  } catch (error) {
    yield put(filtersFetchFailed());
  }
}

export function* searchResults(action) {
  const values = action.payload;
  const bodyLoad = {};
  const objects = Object.keys(action.payload);
  const { filterData } = yield select(selectVideoDomain);
  let durationLength =
    (filterData &&
      filterData.duration &&
      filterData.duration.values &&
      filterData.duration.values.length) ||
    0;
  objects.map(item => {
    if (item === 'keyword') {
      if (values[item].length > 0) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'explicitLang') {
      if (values[item]) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'difficulty') {
      if (values[item].length !== 4 && values[item].length !== 0) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'duration') {
      if (values[item].length !== durationLength) {
        bodyLoad[item] = values[item];
      }
    } else if (
      item !== 'explicitLang' &&
      item !== 'keyword' &&
      item !== 'difficulty' &&
      item !== 'duration' &&
      values[item] &&
      values[item].length > 0
    ) {
      bodyLoad[item] = values[item];
    }
  });
  try {
    yield put(setSearchVideoLoader(true));
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/search`,
        method: 'POST',
        data: bodyLoad,
      }),
    );
    let resultObj = {
      total: result.classes.total,
      result: result.classes.result,
    };
    yield put(videoListCountSuccess(resultObj.total));
    yield put(VideoListSuccessWithFilters(resultObj));
    yield put(filterUpdateList());
    yield put(setSearchVideoLoader(false));
  } catch (error) {
    yield put(setSearchVideoLoader(false));
    yield put(videoListFailedWithFilters());
  }
}

export function* searchResultsPagination(action) {
  const { values, count } = action.payload;
  const bodyLoad = {};
  const objects = Object.keys(values);
  const { filterData } = yield select(selectInteractivePublishedClassesDomain);
  let durationLength =
    (filterData &&
      filterData.duration &&
      filterData.duration.values &&
      filterData.duration.values.length) ||
    0;
  objects.map(item => {
    if (item === 'keyword') {
      if (values[item].length > 0) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'explicitLang') {
      if (values[item]) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'difficulty') {
      if (values[item].length !== 4 && values[item].length !== 0) {
        bodyLoad[item] = values[item];
      }
    } else if (item === 'duration') {
      if (values[item].length !== durationLength && values[item].length !== 0) {
        bodyLoad[item] = values[item];
      }
    } else if (
      item !== 'explicitLang' &&
      item !== 'keyword' &&
      item !== 'difficulty' &&
      item !== 'duration' &&
      values[item] &&
      values[item].length > 0
    ) {
      bodyLoad[item] = values[item];
    }
  });
  try {
    yield put(setSearchVideoLoader(true));
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/search?offset=${count}&limit=${SLICE_COUNT_OF_CLASSES}`,
        method: 'POST',
        data: bodyLoad,
      }),
    );
    let resultObj = {
      total: result.classes.total,
      result: result.classes.result,
    };
    yield put(videoListSuccessPagination(resultObj && resultObj.result));
    yield delay(2000);
    yield put(videoListSuccessPagination([]));
    yield put(videoListCountSuccess(result.classes.total));
  } catch (error) {
    yield put(videoListFailedWithFilters());
    if (error && error.data && error.data.responseCode === 404) {
      yield put(videoListSuccessPagination([]));
      yield put(setSearchVideoLoader(false));
    }
    yield put(videoListFailed());
    yield put(setSearchVideoLoader(false));
  }
}

export function* getDurationDetails() {
  try {
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/duration`,
        method: 'GET',
      }),
    );
    yield put(durationSuccess(result));
  } catch (error) {
    yield put(durationFailed());
  }
}
export function* addLiveEvent(action) {
  try {
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/videos`,
        method: 'POST',
        data: action.payload,
      }),
    );
    yield put(requestVideoList());
    yield put(openSnackBar('Video Added Successfully'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  } catch (error) {
    yield put(durationFailed());
    if (error && error.error && typeof error.error === 'string') {
      yield put(openSnackBar(`Video Adding Failed - ${error.error}`));
    } else {
      yield put(openSnackBar('Video Adding Failed'));
    }

    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  }
}

export function* addVideoEvent(action) {
  let data = action.payload;
  let currenTimeStamp = moment().valueOf();
  let load = {
    CORRELATION_ID: data.CORRELATION_ID,
    url: data.url,
    videoType: 'class',
    permaLink: `/videos/${currenTimeStamp}`,
    isPublished: false,
    fileName: data.url,
  };
  try {
    const { result } = yield call(() =>
      uploadRequest({
        url: data.endPoint,
        method: 'POST',
        data: load,
      }),
    );
    yield put(openSnackBar(`Video Adding Success`));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
    yield put(requestVideoList());
    yield put(videoAddSuccess(true));
  } catch (error) {
    yield put(durationFailed());
    if (error.data && error.data.message) {
      let str = error.data.message;
      if (str.includes('duplicate')) {
        yield put(openSnackBar(`Video URL/Permalink should be unique`));
      } else {
        yield put(openSnackBar(`Video Adding Failed ${error.data.message}`));
      }
    } else {
      yield put(openSnackBar(`Video Adding Failed ${error}`));
    }
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  }
}

export function* reEncodeVideo(action) {
  let payload = {
    id: action.payload,
  };
  try {
    const { result } = yield call(() =>
      uploadRequest({
        url: `/neou/v1/rencode-video`,
        method: 'POST',
        data: payload,
      }),
    );
    yield put(openSnackBar(`Re-Encoding video is in-progress...`));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
    // yield put(requestVideoList());
  } catch (error) {
    yield put(durationFailed());
    yield put(openSnackBar(`Re-Encoding video Failed ${error}`));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  }
}

export function* addAudio(action) {
  try {
    const { result } = yield call(() =>
      uploadRequest({
        url: `/neou/v1/add-audio-tracks`,
        method: 'POST',
        data: action.payload,
      }),
    );
    yield put(openSnackBar(`Multiple audio tracks adding is in-progress...`));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
    // yield put(requestVideoList());
  } catch (error) {
    yield put(durationFailed());
    yield put(
      openSnackBar(
        `Adding audio tracks failed -  ${error.data && error.data.message}`,
      ),
    );
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  }
}
