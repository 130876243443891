import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
    '& .MuiCircularProgress-colorSecondary': {
      color: 'green',
      width: '30px !important',
      height: '30px !important',
    },
    '& .MuiTableRow-root': {
      'td.MuiTableCell-root.MuiTableCell-body:nth-child(1)': {
        flex: '1',
        width: '40%',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(2)': {
        width: '34%',
        minWidth: '224px',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(3)': {
        minWidth: '200px',
      },
      '& td.MuiTableCell-root.MuiTableCell-body:nth-child(4)': {
        width: '10%',
      },
    },
  },

  container: {
    maxHeight: '100%',
  },
  closeButton: {
    '& .MuiIconButton-label': {
      background: '#EA0000',
      borderRadius: '50%',
      padding: '4px',
      boxSizing: 'unset',
      width: '8px',
      height: '8px',
    },
  },
  box: {
    height: '64px',
    width: '64px',
    borderRadius: '6px',
    backgroundColor: '#D4D8DC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '18px',
      height: '24px',
    },
  },
  firstColumn: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'inherit',
  },
  firstCoulmContent: {
    textAlign: 'start',
    paddingLeft: '16px',
  },
  thirdColumn: {
    height: '20px',
    width: '70px',
    color: '#3E4142',
    fontFamily: 'ProximaNova-Bold',
    fontSize: '8px',
    fontWeight: 'bold',
    letterSpacing: '0.57px',
    lineHeight: '10px',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  draft: {
    boxSizing: 'border-box',
    border: '1px solid #E8EBED',
    backgroundColor: '#E8EBED',
  },
  published: {
    boxSizing: 'border-box',
    border: '1px solid #E8EBED',
  },
  conceptTitle: {
    color: '#12161A',
    fontFamily: 'ProximaNova-Semibold',
    fontSize: '17px',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  text: {
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '20px',
    color: '#909599',
  },
  cancelled: {
    fontStyle: 'italic',
    fontFamily: 'Proxima-Nova-Regular',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '20px',
    color: '#EA0000',
  },
  editIcon: {
    width: '36px',
    height: '36px',
    color: '#000000',
    padding: '6px',
  },
});
