import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { storeLiveClassInfo, setLoader } from './actions';
import request from '../../shared/lib/request';
import ActionTypes from './constants';
import { openSnackBar } from '../Root/actions';

export default function* liveClassSaga() {
  yield takeLatest(ActionTypes.REQUEST_LIVE_VIDEO_LIST, getLiveVideo);
}

export function* getLiveVideo() {
  try {
    yield put(setLoader(true));
    let { result } = yield call(() =>
      request({
        url: `neouadmin/v1/liveclass-participants/`,
        method: 'GET',
      }),
    );
    yield put(storeLiveClassInfo(result));
    yield put(setLoader(false));
  } catch (error) {
    yield put(setLoader(false));
    yield put(storeLiveClassInfo({}));
    yield put(openSnackBar('Error in Fetching data'));
  }
}
