/*
 *
 * MyAccount
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectMyAccount from './selectors';
import reducer from './reducer';
import saga from './saga';
import { push } from 'connected-react-router';
import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { SvgBack } from '../../components/NeIcons';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';
import { requestPastClasses } from './actions';
import ClassListTable from '../InteractivePublishedClasses/components/ClassTable/ClassTable';
import makeSelectAuthentication from '../Authentication/selectors';
import UpdatePassword from './components/UpdatePassword';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stickyHeader: {
      position: 'sticky',
      top: '0px',
      zIndex: 10,
      backgroundColor: '#fff',
    },
    goBackIcon: {
      padding: '0px',
      '& svg': {
        color: 'black',
      },
    },
    styledTab: {
      '& .MuiTab-root': {
        maxWidth: '100%',
        width: '50%',
      },
      '& .MuiTabs-centered': {
        justifyContent: 'space-around',
        borderTop: '1px solid #000000',
      },
    },
    root: {
      width: '100%',
      padding: '8px 20px',
      margin: '0 auto',
      maxWidth: '1200px',
    },
    classTitle: {
      width: '70%',
      textAlign: 'center',
      fontSize: '18px',
      fontFamily: 'ProximaNova-SemiBold, Helvetica, Arial, sans-serif',
      lineHeight: '22px',
    },
    subTitle: {
      textAlign: 'center',
      fontFamily: 'ProximaNova-Medium',
      lineHeight: '22px',
      marginBottom: '22px',
      color: '#A1A6A9',
      fontSize: '15px',
    },
    noData: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      padding: '20px',
    },
    head: {
      color: '#12161A',
      fontFamily: 'TurbinadoDryRegular',
      fontSize: '45px',
      letterSpacing: 0,
      lineHeight: '56px',
      textAlign: 'center',
    },
    headWrap: {
      display: 'grid',
      gridTemplateColumns: 'minmax(80px, 16%) 1fr minmax(80px, 16%)',
      justifyItems: 'center',
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'minmax(150px, 16%) 1fr minmax(150px, 16%)',
      },
    },
  }),
);

const stateSelector = createStructuredSelector({
  myAccount: makeSelectMyAccount(),
  authentication: makeSelectAuthentication(),
});

interface Props {}

function MyAccount(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'myAccount', reducer: reducer });
  useInjectSaga({ key: 'myAccount', saga: saga });

  const [activeTab, setActiveTab] = React.useState(0);
  const { myAccount, authentication } = useSelector(stateSelector);
  const { pastClasses } = myAccount;
  const { userInfo } = authentication;

  const styles = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      requestPastClasses(
        userInfo && userInfo.interactive && userInfo.interactive.instrId,
      ),
    );
  }, []);

  const tabConfig = [
    {
      label: 'PAST CLASSES',
      component: pastClasses && pastClasses.length > 0 && (
        <ClassListTable
          list={pastClasses}
          isLoading={false}
          isPastClass={true}
        />
      ),
    },
    {
      label: 'SETTINGS',
      component: <UpdatePassword email={userInfo && userInfo.email} />,
    },
  ];

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  return (
    <div>
      <Helmet>
        <title>MyAccount</title>
        <meta name="description" content="Description of MyAccount" />
      </Helmet>
      <div className={styles.root}>
        {
          <>
            <div className={styles.stickyHeader}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '90px',
                }}
              >
                <div className={styles.headWrap}>
                  <IconButton
                    className={styles.goBackIcon}
                    onClick={() => dispatch(push('/neou/my-classes'))}
                  >
                    <SvgBack />
                  </IconButton>
                  <div className={styles.head}>My Account</div>
                  <div className={styles.head}></div>
                </div>
              </div>
              <div>
                <StyledTabs
                  value={activeTab}
                  onChange={onTabChange}
                  aria-label="My account tabs"
                  centered
                  className={styles.styledTab}
                >
                  {tabConfig.map((t, index) => (
                    <StyledTab key={index} label={t.label} />
                  ))}
                </StyledTabs>
                {activeTab === 0 &&
                  (pastClasses && pastClasses.length === 0 ? (
                    <div className={styles.noData}>No Data Found</div>
                  ) : (
                    <ClassListTable
                      list={pastClasses}
                      isLoading={false}
                      isPastClass={true}
                    />
                  ))}
                {activeTab === 1 && (
                  <UpdatePassword email={userInfo && userInfo.email} />
                )}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default MyAccount;
