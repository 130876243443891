import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RangeSlider from './FilterRangeSlider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // background: '',
      fontSize: '16px',
      fontFamily: 'Proxima-Nova-Regular',

      '& .MuiExpansionPanelSummary-content': {
        content: '',
        clear: 'both',
        display: 'table',
      },
      '& .MuiExpansionPanelSummary-root': {
        [theme.breakpoints.only('sm')]: {
          padding: '0px 8px',
        },

        padding: '0px',
      },
      '& .MuiExpansionPanelDetails-root': {
        [theme.breakpoints.only('sm')]: {
          padding: '0px 8px',
        },
        padding: '0px',
      },
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: '#FFFFFF',
    },
    basecolor: {
      color: '#12161A',
    },
    title: {
      float: 'left',
      fontSize: '16px',
      fontFamily: 'ProximaNova-Semibold',
      color: '#12161A',
      textTransform: 'capitalize',
    },
    titlevalue: {
      float: 'right',
      fontSize: '12px',
      lineHeight: '25px',
      fontFamily: 'Proxima-Nova-Regular',
      color: '#12161A',
      // whiteSpace: 'nowrap',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
    },
    line: {
      borderBottom: '1px solid #E3E3E4',
    },
  }),
);

export default function FilterAccordion(props) {
  const classes = useStyles();
  const { selectedValue, value } = props;
  const showValue = [];
  if (selectedValue) {
    selectedValue.map(itm => {
      showValue.push(itm.title);
    });
  }
  if (props.title && selectedValue) {
    return (
      <div className={classes.root}>
        <ExpansionPanel className={classes.basecolor}>
          <ExpansionPanelSummary
            expandIcon={<ArrowDropDownIcon className={classes.basecolor} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={classes.title}>{props.title}</div>

            {props.title === 'Duration' &&
              selectedValue.length > 1 &&
              !(
                selectedValue[0] === value[0].title &&
                selectedValue[selectedValue.length - 1] ===
                  value[value.length - 1].title
              ) && (
                <div className={classes.titlevalue}>
                  {selectedValue &&
                    selectedValue.length > 0 &&
                    selectedValue[0]}{' '}
                  {'to '}
                  {selectedValue &&
                    selectedValue.length > 1 &&
                    selectedValue[selectedValue.length - 1]}{' '}
                  {'Min'}
                </div>
              )}
            {props.title === 'Difficulty' && selectedValue.length !== 4 && (
              <div className={classes.titlevalue}>
                {selectedValue && showValue && showValue.join(', ')}
              </div>
            )}
            {props.title !== 'Duration' && props.title !== 'Difficulty' && (
              <div className={classes.titlevalue}>
                {selectedValue && showValue && showValue.join(', ')}
              </div>
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>{props.children}</ExpansionPanelDetails>
        </ExpansionPanel>
        <div className={classes.line} />
      </div>
    );
  } else {
    return null;
  }
}
