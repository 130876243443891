import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
// import other assets
import iconU from '../../../images/icon-U.svg';
import { Grid } from '@material-ui/core';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import { genres } from './stub';
import Autocomplete from '../../../components/Autocomplete';
import Box from '@material-ui/core/Box';
import { useStyles } from '../styles';
import SelectMenu from './SelectMenu';
import Avatar from '@material-ui/core/Avatar';
import {
  requestOtherDetails,
  updatePeopleInfo,
  requestPeopleList,
  personLoader,
} from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import makeSelectPeople from '../selectors';
import moment from 'moment';
import { push } from 'connected-react-router';
import GalleryDialog from '../../../components/Gallery/Gallery';
import { openAlertModal, openSnackBar } from '../../Root/actions';
import { SvgProfile } from '../../../components/NeIcons';
import { addPhoto, deletePhoto, addVideo } from '../../../services/s3';
import Loader from '../../../components/Loader';
import {
  cloudfrontUrl,
  BUCKET_NAME,
  BUCKET_REGION,
} from '../../../utils/config';
import Chip from '@material-ui/core/Chip';
import { addInstructorDetails } from '../actions';
import MenuItem from '@material-ui/core/MenuItem';
import { StyledSpinnerNext } from 'baseui/spinner';
import request from '../../../shared/lib/request';
import ConfirmDialog from '../../../components/ConfirmDialog';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#000',
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <img src={iconU} className={classes.iconU} />
      <Typography variant="h6">{children}</Typography>
      <div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dividers: {
    borderTop: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(MuiDialogActions);

const useStylesLocal = makeStyles((theme: Theme) =>
  createStyles({
    tag: {
      background: '#3C4042',
      color: '#fff',
      height: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    deleteIcon: {
      color: '#fff',
      width: '18px',
      opacity: 1,
      '&:hover': {
        color: '#fff',
        opacity: '0.5',
      },
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    dialogContainer: {
      borderRadius: 0,
      minWidth: '1032px',
    },
    spanHeader: {
      fontSize: '16px',
      fontFamily: 'Proxima-Nova-Regular',
      color: '#060B0F',
    },
    inputWrap: {
      '& .MuiInputBase-root': {
        '& .MuiInputBase-input': {
          color: '#060B0F !important',
          fontFamily: 'Proxima-Nova-Regular',
          fontSize: '16px',
          fontWeight: 500,
        },
      },
      '& .MuiOutlinedInput-inputMultiline': {
        padding: '0px',
      },
      '& .MuiAvatar-colorDefault': {
        backgroundColor: '#D4D8DC',
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'Proxima-Nova-Regular',
      },
    },
    imageBox: {
      height: '256px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: '#12161A',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '17px',
    },
    galleryDrop: {
      height: '142px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '180px',
      height: '180px',
      borderRadius: '50%',
      textAlign: 'center',
      color: 'white',
    },
    uploadBtn: {
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      textAlign: 'center',
      backgroundColor: '#3c4042',
      textTransform: 'capitalize',
      borderRadius: '20px',
      padding: '0px 10px',
      cursor: 'pointer',
    },
    flag: {
      marginBottom: '20px',
    },
    subTitle: {
      color: '#060B0F',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    subInTitle: {
      color: '#000000',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      lineHeight: '20px',
    },
    marginTitle: {
      marginTop: '20px',
    },
    inputHidden: {
      display: 'none',
    },
    manageGallery: {
      textTransform: 'capitalize',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: '#12161A',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 22px',
      position: 'relative',
      bottom: '103px',
    },
    galleryDiv: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    hiddenStatus: {
      color: '#000000',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      lineHeight: '20px',
    },
    spanError: {
      color: '#FF1C58',
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
    },
  }),
);

const stateSelector = createStructuredSelector({
  people: makeSelectPeople(),
});

export default function AddPersonDialog({ isOpen, handleClose, title }) {
  const classes = useStylesLocal();
  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  //get data from redux state
  const { people } = useSelector(stateSelector);
  let { aSpecialty, aFlag } = people;

  //all local states
  const [values, setValues] = useState({});
  const [img, setImg] = useState('');
  const [error, setError] = useState({
    firstName: '',
    description: '',
    isImage: '',
  });
  const [loader, setLoader] = useState(false);
  const [errImg, setErrImg] = useState(false);
  const [initialName, setInitialName] = useState('');
  const [initialLast, setInitialLast] = useState('');
  const [getPermaLink, setPermaLink] = useState('');

  useEffect(() => {
    if (isOpen) {
      dispatch(requestOtherDetails(true));
      //resetting local state
      setImg('');
      setError({});
      setValues({});
      setErrImg(false);
      setInitialName('');
      setInitialLast('');
      setPermaLink('');
    }
  }, [isOpen]);

  const handleInput = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  //profile image upload - s3
  const getBaseUrl = async e => {
    setLoader(true);
    const imgUrl = URL.createObjectURL(e.target.files[0]);

    //s3 add function
    let result = await addPhoto('person', e.target.files);

    let image = {
      imageUrl: result.Location.replace(
        'https://' + BUCKET_NAME + '.s3.' + BUCKET_REGION + '.amazonaws.com',
        cloudfrontUrl,
      ),
    };
    setErrImg(true);
    setImg(image.imageUrl);
    setValues({ ...values, imageUrl: image.imageUrl });
    setLoader(false);
  };

  //permaLink generator
  const handlePermaGenerator = e => {
    setInitialName(e.target.value);
  };
  const handlePermaGeneratorLast = e => {
    setInitialLast(e.target.value);
  };

  useEffect(() => {
    if (initialLast) {
      setPermaLink(
        '/person/' +
          initialName.replace(/\s+/g, '-') +
          '-' +
          initialLast.replace(/\s+/g, '-'),
      );
    } else {
      setPermaLink('/person/' + initialName.replace(/\s+/g, '-'));
    }
    //set updated values
    setValues({ ...values, permaLink: getPermaLink });
  }, [initialName, initialLast, getPermaLink]);

  //handle save & publish with validations
  const handleFormSubmit = btnVal => {
    let firstNameErr = document.getElementById('firstName').value;
    let descriptionErr = document.getElementById('description').value;
    let imageErr = errImg;

    const formError = validateFields({
      firstNameErr,
      descriptionErr,
    });

    if (!firstNameErr || !descriptionErr) {
      setError(formError);
      return null;
    } else {
      setError('');
      let finalData = {
        ...values,
        lastName: values.lastName ? values.lastName : '',
        gallery: values.gallery || [],
        isPublished: btnVal === 'submit' ? false : true,
        isHidden: false,
        type: 'person',
      };
      // dispatch(addInstructorDetails(finalData));
      addPersonApi(finalData);
    }
  };

  const addPersonApi = async data => {
    dispatch(personLoader(true));
    try {
      let { result } = await request({
        url: `/neouadmin/v1/people`,
        method: 'POST',
        data: data,
      });
      dispatch(personLoader(false));
      dispatch(requestPeopleList());
      handleClose();
    } catch ({ data: error }) {
      dispatch(personLoader(false));
      if (error.error) {
        dispatch(openSnackBar(`Failed to add person - ${error.error}`));
      } else {
        dispatch('Error in saving the Instructor..');
      }
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick={true}
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
        >
          {title}
          <div></div>
        </DialogTitle>
        <DialogContent dividers>
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the person profile."
            onAgree={() => handleClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Box component="span" className={classes.spanHeader}>
                Profile Image*
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box component="span" className={classes.spanHeader}>
                Profile Information
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className={classes.imageBox}>
                <div className={classes.image}>
                  {loader ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        top: '40%',
                      }}
                    >
                      <StyledSpinnerNext style={{ borderTopColor: 'black' }} />
                    </div>
                  ) : (
                    <Avatar
                      alt="img"
                      src={img}
                      className={classes.image}
                    ></Avatar>
                  )}
                </div>
                {loader ? (
                  ''
                ) : (
                  <label className={classes.uploadBtn}>
                    <input
                      accept="image/*"
                      className={classes.inputHidden}
                      id="icon-button-file"
                      type="file"
                      onChange={e => getBaseUrl(e)}
                    />
                    Upload Image
                  </label>
                )}
              </div>

              {error && error.isImage ? (
                <div>
                  <Box component="span" className={classes.spanError}>
                    {error.isImage}
                  </Box>
                </div>
              ) : (
                ''
              )}
            </Grid>

            <Grid item xs={4}>
              <NEInput
                label="First Name"
                variant="outlined"
                fullWidth={true}
                required
                className={classes.inputWrap}
                name="firstName"
                id="firstName"
                onChange={e => handleInput(e)}
                error={!!error.firstName || ''}
                helperText={error.firstName || ''}
                onBlur={e => handlePermaGenerator(e)}
              />
              <NEInput
                label="Last Name"
                variant="outlined"
                fullWidth={true}
                className={classes.inputWrap}
                name="lastName"
                onChange={e => handleInput(e)}
                onBlur={e => handlePermaGeneratorLast(e)}
              />
              <NEInput
                id="description"
                label="Description"
                variant="outlined"
                fullWidth={true}
                className={classes.inputWrap}
                name="description"
                multiline
                rows="4"
                required
                onChange={e => handleInput(e)}
                error={!!error.description || ''}
                helperText={error.description || ''}
              />
              <Autocomplete
                multiple
                options={aSpecialty || ''}
                label="Specialty"
                required
                name="specialty"
                id="specialty"
                onChange={(e, data) => {
                  setValues({
                    ...values,
                    specialty: data.map(item => item.id),
                  });
                }}
              />
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                label="Admin Tags"
                required
                popupIcon={<AddIcon />}
                forcePopupIcon={true}
                disableClearable={true}
                name="adminTags"
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      classes={{
                        label: classes.chipLabel,
                        deleteIcon: classes.deleteIcon,
                      }}
                    />
                  ))
                }
                onChange={(e, data) => {
                  setValues({ ...values, adminTags: data });
                }}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <div>
            {/* <Button
              color="secondary"
              size="large"
              onClick={() => {
                let btnVal = 'preview';
                handleFormSubmit(btnVal);
              }}
            >
              Publish
            </Button> */}

            <Button
              variant="contained"
              onClick={() => {
                let btnVal = 'submit';
                handleFormSubmit(btnVal);
              }}
              color="secondary"
              size="large"
            >
              Save & Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function validateFields({ firstNameErr, descriptionErr }) {
  let formError = {};
  if (!firstNameErr) {
    formError = { ...formError, firstName: 'Please enter First Name' };
  }
  if (!descriptionErr) {
    formError = { ...formError, description: 'Please enter Description' };
  }
  // if (!imageErr) {
  //   formError = { ...formError, isImage: 'Please upload a Profile Image' };
  // }
  return formError;
}
