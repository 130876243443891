import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '30px 20px',
    },
    errorMsg: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '13px',
      lineHeight: '1.66',
      color: '#FF1C58',
      marginTop: '-15px',
      letterSpacing: '0.03333em',
    },
    message: {
      fontSize: '16px',
      fontStyle: 'italic',
      color: '#A1A6A9',
      textAlign: 'center',
      background: '#F8F9FA',
      padding: '15px 0px',
      width: '100%',
    },
    loadMoreBtn: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px',
    },
    searchWrap: {
      '& .MuiFormControl-root': {
        minWidth: '240px',
        fontFamily: 'Proxima-Nova-Regular',
        minHeight: '38px',
        height: '38px',
      },
      '& .MuiInputBase-root': {
        maxWidth: '240px',
        width: '241px',
        boxSizing: 'border-box',
        fontFamily: 'Proxima-Nova-Regular',
        paddingLeft: '8px',
        height: '38px',
        '& .MuiInputBase-input': {
          color: '#060B0F !important',
          fontFamily: 'Proxima-Nova-Regular',
          fontSize: '16px',
          fontWeight: 500,
          letterSpacing: 0,
          lineHeight: '20px',
          padding: '0px !important',
        },
      },
      '& .MuiOutlinedInput-root fieldset': {
        border: '1px solid #E3E3E4',
        borderColor: '#E3E3E4',
      },
      '& label.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 12px) scale(1) !important',
      },
    },
    drawerBody: {
      height: 'calc(100vh - 144px)',
      overflow: 'auto',
    },
    head: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    subHead: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '32px',
      textAlign: 'center',
      width: '100%',
    },
    lowerHeaderSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 0px 0px 0px',
    },
    addVideoBtn: {
      padding: '0px',
      height: '40px',
      width: '162px',
      borderRadius: '6px',
      backgroundColor: '#12161A',
    },
    newVideo: {
      paddingLeft: '10px',
    },
    headWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '25px',
    },
    subHeadWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 0px',
    },
    noData: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      padding: '20px',
    },
    firstColumn: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'inherit',
    },
    firstCoulmContent: {
      textAlign: 'start',
      paddingLeft: '16px',
    },
    thirdColumn: {
      height: '20px',
      width: '70px',
      color: '#3E4142',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '8px',
      fontWeight: 'bold',
      letterSpacing: '0.57px',
      lineHeight: '10px',
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    draft: {
      boxSizing: 'border-box',
      border: '1px solid #E8EBED',
      backgroundColor: '#E8EBED',
    },
    published: {
      boxSizing: 'border-box',
      border: '1px solid #E8EBED',
    },
    container: {
      maxHeight: '100%',
    },
    attributeTitle: {
      color: '#12161A',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '17px',
      letterSpacing: '0',
      lineHeight: '24px',
    },
    text: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
    },
    editIcon: {
      width: '36px',
      height: '36px',
      color: '#000000',
      padding: '6px',
    },
    drawerPaper: {
      overflow: 'hidden',
    },
    saveBtn: {
      display: 'flex',
      position: 'fixed',
      bottom: '0',
      background: '#fff',
      width: '630px',
      padding: '16px',
    },
    subTitle: {
      color: '#12161A',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '17px',
      letterSpacing: '0',
      lineHeight: '24px',
    },
    btnContainer: {
      width: '100%',
      padding: '20px',
      marginLeft: '-16px',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      position: 'fixed',
      bottom: '0',
      background: '#fff',
      display: 'flex',
    },
  }),
);
