import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the Attribute state domain
 */

const selectAdministratorDomain = (state: ApplicationRootState) => {
  return state.administrator || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Attribute
 */

const makeSelectAdministrator = () =>
  createSelector(
    selectAdministratorDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectAdministrator;
export { selectAdministratorDomain };
