/*
 *
 * Filters
 *
 */

import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles, createStyles, Theme, Switch } from '@material-ui/core';
import Accordian from '../../components/Accordian';
import { useEffect } from 'react';
import request from '../../shared/lib/request';
import { useState } from 'react';
import DraggableList from '../../components/DraggableList';
import Loader from '../../components/Loader';
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../Root/actions';

interface Props {}

function Filters(props: Props) {
  const [filters, setFilters] = useState({});
  const [selectedFilter, setSelectedFilter] = useState('');
  const [isLoading, setLoader] = useState(false);
  let dispatch = useDispatch();

  const classes = useStyles();

  const _init = async () => {
    try {
      setLoader(true);
      let { result } = await request({
        url: `/neouadmin/v1/filters/`,
        method: 'GET',
      });
      setLoader(false);
      setFilters(result);
      let keys = Object.keys(result);
      keys.map((key, index) => {
        result[key] = result[key].sort((a, b) =>
          a.filterOrder > b.filterOrder ? 1 : -1,
        );
      });
      if (keys && keys.length > 0) {
        setSelectedFilter(keys[0]);
      }
    } catch (error) {
      dispatch(openSnackBar('Error in fetching the info..'));
      setLoader(false);
      setFilters([]);
    }
  };

  const _reorderFilters = async (filters, filterKey) => {
    // ToDo: Update the filter
    let data = { filters: [] };
    let type = '';
    if (filterKey) {
      data.filters = filters[filterKey].map((f, index) => ({
        id: f.id,
        type: f.type === 'bodyfocus' ? 'bodyFocus' : f.type,
        filterOrder: index + 1,
      }));
    }
    try {
      setLoader(true);
      setFilters(filters);
      let { result } = await request({
        url: `/neouadmin/v1/reorder-filters`,
        method: 'POST',
        data,
      });
      dispatch(openSnackBar('Reordering the list..'));
      setLoader(false);
    } catch (error) {
      dispatch(openSnackBar('Error in reordering the list.'));
      setLoader(false);
      // setFilters([]);
    }
  };
  const _updateFilter = async (filterValue, filterKey) => {
    // ToDo: Update the filter
    try {
      setLoader(true);
      setFilters(filters);
      let { result } = await request({
        url: `/neouadmin/v1/attributes`,
        method: 'PUT',
        data: {
          id: filterValue.id,
          type:
            filterValue.type === 'bodyfocus' ? 'bodyFocus' : filterValue.type,
          isFilter: filterValue.isFilter,
        },
      });
      setLoader(false);
    } catch (error) {
      dispatch(openSnackBar('Error in updating the filter.'));
      setLoader(false);
      // setFilters([]);
    }
  };

  const _destroy = () => {};

  const _getFilterLabel = key => {
    switch (key) {
      case 'bodyFocus':
        return 'Body Focus';
      case 'equipmentsDoc':
        return 'Equipments';
      case 'musicDoc':
        return 'Music';
      case 'genreDoc':
        return 'Genre';
      default:
        return key;
    }
  };

  useEffect(() => {
    _init();

    return _destroy;
  }, []);

  const handleChange = (filterKey: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean,
  ) => {
    setSelectedFilter(newExpanded ? filterKey : '');
  };

  return (
    <div>
      <Helmet>
        <title>Configure Filters</title>
        <meta name="description" content="Description of Filters" />
      </Helmet>
      {isLoading && <Loader />}
      <div className={classes.root}>
        <div className={classes.headWrap}>
          <div className={classes.upperHeaderSection}>
            <div className={classes.head}>Configure: Filters</div>
          </div>
        </div>

        <div className={classes.lowerHeaderSection}>
          {Object.keys(filters).map(filterKey => {
            let filterInfo = filters[filterKey];
            return (
              <Accordian
                title={_getFilterLabel(filterKey)}
                key={filterKey}
                expanded={selectedFilter === filterKey}
                name={filterKey}
                handleChange={handleChange}
              >
                <DraggableList
                  height={'400px'}
                  minHeight={'200px'}
                  list={filterInfo}
                  onListChange={uniqItems => {
                    let newFilters = { ...filters };
                    newFilters[filterKey] = [...uniqItems];
                    _reorderFilters(newFilters, filterKey);
                  }}
                  subTitle={($value, $index) => (
                    <Switch
                      color="primary"
                      checked={$value.isFilter}
                      onChange={() => {
                        let newFilters = { ...filters };
                        newFilters[filterKey][$index].isFilter = !newFilters[
                          filterKey
                        ][$index].isFilter;
                        _updateFilter($value, filterKey);
                      }}
                    />
                  )}
                  removable={false}
                />
              </Accordian>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      padding: '50px 20px',
    },
    headWrap: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 0px 12px 0px',
      width: '100%',
      flexWrap: 'wrap',
      borderBottom: '1px solid #000000',
    },
    upperHeaderSection: {
      width: '100%',
      justifyContent: 'space-between',
      display: 'flex',
    },
    head: {
      color: '#12161A',
      fontFamily: 'ProximaNova-SemiBold',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    lowerHeaderSection: {
      width: '100%',
      // display: 'flex',
      // justifyContent: 'space-between',
      padding: '16px 0px 0px 0px',
    },
  });
});
export default memo(Filters);
