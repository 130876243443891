import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { useStyles } from './styles';
import EditDialog from './EditDialog';
import { FitnessProfileForm } from '../../FitnessGoals/components/FitnessProfileForm';

export default function AttributeListTable(props) {
  const classes = useStyles();

  const { list, isLoading, isFitnessGoal } = props;

  const getDate = list => {
    if (list.isVisible) {
      return <div>{`Updated ${moment(list.updatedAt).format(`L`)}`}</div>;
    }
    return `Created ${moment(list.updatedAt).format(`L`)}`;
  };
  const handleManage = () => {};
  const renderCTA = ({
    isPublished = false,
    isHidden = false,
    isError = false,
    isVisible = false,
  }) => {
    if (isError) {
      return <div></div>;
    }
    if (isFitnessGoal) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'PUBLISHED'}
        </div>
      );
    }
    if (isHidden) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'HIDDEN'}
        </div>
      );
    }
    if (isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'PUBLISHED'}
        </div>
      );
    }
    if (!isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.draft}`}>
          {'DRAFT'}
        </div>
      );
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead></TableHead>
          <TableBody>
            <TableRow key={list.id} tabIndex={-1}>
              <TableCell align="left" style={{ paddingLeft: '30px' }}>
                <div className={classes.attributeTitle}>Name</div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.attributeTitle}>
                  {' '}
                  {isFitnessGoal ? '' : 'Type'}
                </div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.attributeTitle}>Last Updated</div>
              </TableCell>
              <TableCell align="left">
                <div className={classes.attributeTitle}>Status</div>
              </TableCell>
              <TableCell align="center">
                <div className={classes.attributeTitle}></div>
              </TableCell>
            </TableRow>
            {list &&
              list.length > 0 &&
              list.map(list => (
                <TableRow key={list.id} hover role="checkbox" tabIndex={-1}>
                  <TableCell align="left">
                    <div className={classes.firstColumn}>
                      <div className={classes.firstCoulmContent}>
                        <div className={classes.attributeTitle}>
                          {list.title}
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.text}>
                      {list && list.type && list.type.toUpperCase()}
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.text}>{getDate(list)}</div>
                  </TableCell>
                  <TableCell align="center">{renderCTA(list)}</TableCell>

                  <TableCell align="right">
                    <EditDialog
                      id={isFitnessGoal ? null : list.id}
                      classes={classes}
                      title={
                        isFitnessGoal ? 'EDIT FITNESS GOAL' : 'EDIT ATTRIBUTE'
                      }
                    >
                      {isFitnessGoal && <FitnessProfileForm id={list.id} />}
                    </EditDialog>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
