/*
 *
 * HomeCurate
 *
 */

import { Box, Button, Drawer, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LowerSectionTrayList from '../../components/CurrateTopSection/LowerSection';
import { TraySection as TopSectionTray } from '../../components/CurrateTopSection/TopSection';
import { SvgIconAdd } from '../../components/NeIcons';
import request from '../../shared/lib/request';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import SortSelect from './components/SortSelect';
import {
  reorderTrays,
  requestHomeCurateInfo,
  updateHomeComponent,
  setLoader,
} from './actions';
import PromoComponent from './components/PromoComponent';
import reducer from './reducer';
import saga from './saga';
import makeSelectHomeCurate from './selectors';
import { ComponentType, ContentType, LowerSection, Mode } from './types';
import { ComponentEditor } from './components/ComponentEditor';
import { DrawerView } from './components/DrawerView';
import Loader from '../../components/Loader';
import { openSnackBar } from '../Root/actions';
import ConfirmDialog from '../../components/ConfirmDialog';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeCurateRoot: {
      '& .MuiSelect-selectMenu': {
        textTransform: 'capitalize',
      },
      '& .MuiMenuItem-root': {
        textTransform: 'capitalize',
      },
    },
    root: {
      padding: '0px 0px 16px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    head: {
      color: '#12161A',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '26px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    headWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '18px 0px',
      borderBottom: '1px solid #000000',
    },
    margin: {
      margin: theme.spacing(1),
      background: '#12161A',
      width: '70px',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      letterSpacing: '0',
      lineHeight: '20.4px',
      textAlign: 'center',
      minWidth: '85px !important',
      height: '24px !important',
      '&:hover': {
        background: '#12161A',
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    drawerPaper: {
      overflow: 'hidden',
    },
    // edit component
    list: {
      width: 650,
    },
    fullList: {
      width: 'auto',
    },
    btnText: {
      color: 'white',
      paddingTop: '0',
    },
    title: {
      padding: '16px 24px',
      color: '#12161A',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '16px',
      lineHeight: '20px',
    },
    closeButton: {
      position: 'absolute',
      paddingTop: '16px',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      float: 'right',
      color: '#000',
    },
    drawerBody: {
      height: 'calc(100vh - 176px)',
      overflow: 'auto',
    },
    drawerFooter: {
      padding: '16px',
      display: 'flex',
      '& .MuiButton-root': {
        marginRight: 8,
      },
    },
    sectionTitle: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: '18px',
      lineHeight: '20px',
    },
    section: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

const stateSelector = createStructuredSelector({
  homeCurate: makeSelectHomeCurate(),
});

interface Props {}

const CONTENT_TYPES = [
  ContentType.Collections,
  ContentType.Concept,
  ContentType.Videos,
  ContentType.PromoTray,
];
const COMPONENT_TYPES = [
  ComponentType.Tray,
  ComponentType.Carousel,
  ComponentType.ProgramCarousel,
];

const possibleValues = {
  contentTypes: [
    { label: 'Promo Tray', value: ContentType.PromoTray },
    { label: 'Concepts/Collections', value: ContentType.Collections },
    { label: ContentType.Videos, value: ContentType.Videos },
    //{ label: ContentType.Instructor, value: ContentType.Instructor },
  ],
  componentTypes: COMPONENT_TYPES.map(i => ({ label: i, value: i })),
  modes: [
    { label: 'Manually Curated', value: Mode.ManuallyCurated },
    { label: 'Auto', value: Mode.Auto },
  ],
  segments: [],
};

const initialFormValue: LowerSection = {
  componentTitle: '',
  componentOrder: {},
  componentType: '',
  contentType: '',
  content: {
    data: [],
    mode: '',
  },
  currentComponentOrder: 0,
  userSegments: [],
};

export enum FormType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

function HomeCurate(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'homeCurate', reducer: reducer });
  useInjectSaga({ key: 'homeCurate', saga: saga });
  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(requestHomeCurateInfo(segmentFilter));
  };

  const { homeCurate } = useSelector(stateSelector);
  const { topSection, lowerSection, segments, isLoading } = homeCurate;
  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);

  const [possibleOptions, setPossibleOptions] = React.useState({
    componentTypes: possibleValues.componentTypes,
    contentTypes: possibleValues.contentTypes,
    modes: possibleValues.modes,
    segments: possibleValues.segments,
  });

  const [componentFormInfo, setComponentFormInfo] = React.useState({
    isOpen: false,
    type: FormType.ADD,
    item: { ...initialFormValue },
    isPromo: false,
    isRemovable: false,
  });

  const [segmentFilter, setSegmentFilter] = React.useState();

  const handleLowerSectionListChange = (list: LowerSection) => {
    dispatch(reorderTrays(list));
  };

  const hanldeLowerSectionTrayManage = item => {
    let formValue = { ...item };
    formValue.userSegments = possibleOptions.segments.filter(i =>
      formValue.userSegments.includes(i.title),
    );
    setComponentFormInfo({
      isOpen: true,
      item: { ...initialFormValue, ...formValue },
      type: FormType.EDIT,
      isPromo: item.contentType === ContentType.Promo,
      isRemovable: true,
    });
  };

  const hanldeTopSectionTrayManage = item => {
    let formValue = { ...item };
    formValue.userSegments = possibleOptions.segments.filter(i =>
      formValue.userSegments.includes(i.title),
    );
    setComponentFormInfo({
      isOpen: true,
      item: { ...initialFormValue, ...formValue },
      type: FormType.EDIT,
      isPromo: item.contentType === ContentType.Promo,
      isRemovable: false,
    });
  };

  const handleEditDrawerClose = () =>
    setComponentFormInfo({ isOpen: false, item: { ...initialFormValue } });

  const formData = formValues => {
    let data = [];

    if (componentFormInfo.isPromo) {
      data = formValues.content.data.map((i, index) => ({
        id: i.id,
        imgUrl: i.imgUrl,
        order: index + 1,
        contentType:
          i.contentType ||
          (i.collectionType
            ? 'collections'
            : i.videoType === ('class' || 'generic')
            ? 'videos'
            : i.firstName
            ? 'instructors'
            : 'collections'),
      }));
      formValues.contentType = ContentType.Promo;
    } else if (
      formValues.componentType === ComponentType.Carousel &&
      formValues.contentType !== ContentType.PromoTray
    ) {
      data = formValues.content.data.map((i, index) => ({
        id: i.id,
        text: i.text,
        order: index,
      }));
    } else if (formValues.contentType === ContentType.PromoTray) {
      data = formValues.content.data.map((i, index) => ({
        imageUri: i.imageUri,
        url: i.url,
        order: index + 1,
        isExternal: i.isExternal || false,
      }));
      formValues.componentType = ComponentType.Carousel;
    } else {
      data = formValues.content.data.map((i, index) => ({
        id: i.id,
        order: index + 1,
      }));
    }

    var selectedSegmentList = formValues.userSegments.map(u => u.title);

    var co = {};
    var count = 3;
    if (componentFormInfo.type === FormType.ADD) {
      selectedSegmentList.forEach(segment => {
        if (
          formValues &&
          formValues.componentOrder &&
          formValues.componentOrder[segment]
        ) {
          co[segment] = formValues.componentOrder[segment];
        } else {
          co[segment] =
            lowerSection && lowerSection.length > 0
              ? lowerSection.length + 3
              : 3;
        }
      });
    } else {
      selectedSegmentList.forEach(segment => {
        if (
          formValues &&
          formValues.componentOrder &&
          formValues.componentOrder[segment]
        ) {
          co[segment] = formValues.componentOrder[segment];
        } else {
          co[segment] = count + 1;
        }
      });
    }

    let requestBody = {
      pageName: 'home',
      ...formValues,
      updatedAt: new Date().getTime(),
      content: {
        ...formValues.content,
        data: data,
      },
      componentOrder: co,
      userSegments: selectedSegmentList,
      componentSection: String(formValues.contentType).includes('videos')
        ? 'videos'
        : 'collections',
    };
    delete requestBody.currentComponentOrder;
    if (componentFormInfo.isPromo) {
      requestBody.contentType = ContentType.Promo;
      delete requestBody.componentSection;
    }
    return requestBody;
  };

  const handleEditFormSubmit = async (formValues, type) => {
    let componentInfo = formData(formValues);
    try {
      dispatch(setLoader(true));
      let { result } = await request({
        url: 'neouadmin/v2/pages/',
        method: type === 'ADD' ? 'POST' : 'PUT',
        data: componentInfo,
      });
      dispatch(setLoader(false));
      dispatch(openSnackBar('Component has been updated successfully!'));
      dispatch(requestHomeCurateInfo(segmentFilter));
      setComponentFormInfo({
        isOpen: false,
        type: FormType.ADD,
        item: { ...initialFormValue },
        isPromo: false,
        isRemovable: false,
      });
    } catch ({ data: error }) {
      dispatch(setLoader(false));
      let errorMsg = "Couldn't save the information. Try again later!";
      if (error && error.error) {
        if (
          error.error === `contentType is required -- allowed value -> "promo"`
        ) {
          errorMsg = `Cannot include "promo" as Component Title`;
        } else errorMsg = error.error;
      }
      dispatch(openSnackBar(errorMsg));
    }
  };

  const deleteComponent = async ({ componentTitle, componentId }) => {
    let requestBody = {
      pageName: 'home',
      componentId,
      deleteComponent: true,
    };
    try {
      let { result } = await request({
        url: '/neouadmin/v2/pages/',
        method: 'PUT',
        data: requestBody,
      });
      setComponentFormInfo({ isOpen: false, item: initialFormValue });
      dispatch(requestHomeCurateInfo(segmentFilter));
    } catch (error) {}
  };

  const classes = useStyles();

  React.useEffect(() => {
    setPossibleOptions({
      ...possibleOptions,
      segments: segments || possibleValues.segments,
    });
  }, [segments]);

  const handleSegmentChange = event => {
    setSegmentFilter(event.target.value);
    dispatch(requestHomeCurateInfo(event.target.value));
  };

  const handlePromoEdit = async values => {
    try {
      let { result } = await request({
        url: '/neouadmin/v2/pages/',
        method: 'PUT',
        data: formData(values),
      });
      setComponentFormInfo({
        type: '',
        isOpen: false,
        item: initialFormValue,
        isPromo: false,
        isRemovable: false,
      });
      dispatch(openSnackBar('Promo content updated successfully'));
      dispatch(requestHomeCurateInfo(segmentFilter));
    } catch (error) {
      if (error.data && error.data.error) {
        dispatch(openSnackBar(error.data.error));
      }
    }
  };

  return (
    <div className={classes.homeCurateRoot}>
      {isLoading && <Loader />}
      <Helmet>
        <title>Curate Home</title>
        <meta name="description" content="Description of HomeCurate" />
      </Helmet>
      <Box p={2}>
        <div className={classes.root}>
          <div>
            <div className={classes.headWrap}>
              <div className={classes.head}>Curate: Home Page</div>
              <SortSelect
                onChange={handleSegmentChange}
                sort={segmentFilter}
                segments={segments}
              />
            </div>
          </div>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              classes={{
                root: classes.sectionTitle,
              }}
            >
              TOP SECTION
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {topSection.map((item, index) => (
              <TopSectionTray
                key={`top_section_${index}`}
                item={item}
                handleManage={() => hanldeTopSectionTrayManage(item)}
                carouselType={item.contentType}
              />
            ))}
          </Grid>

          <Grid item xs={12}>
            <div className={classes.section}>
              <Typography
                classes={{
                  root: classes.sectionTitle,
                }}
              >
                LOWER SECTION
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                className={classes.addBtn}
                disableRipple={true}
                startIcon={<SvgIconAdd />}
                onClick={() => {
                  setComponentFormInfo({
                    isOpen: true,
                    type: FormType.ADD,
                    item: { ...initialFormValue },
                    isRemovable: true,
                  });
                }}
              >
                COMPONENT
              </Button>
            </div>
            <LowerSectionTrayList
              lowerSection={lowerSection}
              handleComponentManage={hanldeLowerSectionTrayManage}
              handleListChanged={handleLowerSectionListChange}
              activeSegment={segmentFilter}
            />
          </Grid>

          <Drawer
            anchor={'right'}
            open={componentFormInfo.isOpen && componentFormInfo.isPromo}
            onClose={() => showAlertToClose(true)}
            disableBackdropClick={true}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <ConfirmDialog
              isOpen={isCloseAlertOpen}
              headerText="Are you sure?"
              bodyText="You are about to close the promo component."
              onAgree={() => handleEditDrawerClose()}
              handleClose={() => showAlertToClose(false)}
              onDisagree={() => showAlertToClose(false)}
            ></ConfirmDialog>

            <DrawerView
              handleClose={showAlertToClose}
              title="EDIT PROMO COMPONENT"
              key={'promoCOmponent'}
              removable={
                componentFormInfo.type === FormType.EDIT &&
                !componentFormInfo.isPromo
              }
            >
              <PromoComponent
                defaultValue={componentFormInfo.item}
                onSubmit={handlePromoEdit}
                possibleOptions={possibleOptions}
              />
            </DrawerView>
          </Drawer>

          <Drawer
            anchor={'right'}
            open={componentFormInfo.isOpen && !componentFormInfo.isPromo}
            disableBackdropClick={true}
            onClose={() => showAlertToClose(true)}
          >
            <ConfirmDialog
              isOpen={isCloseAlertOpen}
              headerText="Are you sure?"
              bodyText="You are about to close the component."
              onAgree={() => handleEditDrawerClose()}
              handleClose={() => showAlertToClose(false)}
              onDisagree={() => showAlertToClose(false)}
            ></ConfirmDialog>

            <DrawerView
              handleClose={showAlertToClose}
              type={componentFormInfo.type}
              removable={
                componentFormInfo.type === FormType.EDIT &&
                !componentFormInfo.isPromo
              }
              title={`${componentFormInfo.type} COMPONENT`}
            >
              <ComponentEditor
                lowerSection={lowerSection}
                componentFormInfo={componentFormInfo}
                possibleOptions={possibleOptions}
                handleEditFormSubmit={data =>
                  handleEditFormSubmit(data, componentFormInfo.type)
                }
                handleComponentDelete={deleteComponent}
              />
            </DrawerView>
          </Drawer>
        </Grid>
      </Box>
    </div>
  );
}

export default memo(HomeCurate);
