import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  Typography,
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { List, arrayMove, arrayRemove } from 'baseui/dnd-list';
import { IconButton } from '@material-ui/core';
import { getUniqItems } from '../utils/array';
import { getTimeAfterMinutes } from '../utils/calender';
import moment from 'moment';

const styles = (theme: Theme) =>
  createStyles({
    classWrapper: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    videoDate: {
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    videoTitle: {
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 700,
    },
    videoItemCloseButton: {
      marginLeft: '16px',
      padding: '4px',
    },
    videoItemCloseIcon: {
      color: '#D6D9DC',
      width: '20px',
      height: '20px',
    },
    dragWrap: {
      marginBottom: '25px',
      width: '450px',
      background: 'none',
    },
    manageContent: {
      width: '100%',
      color: '#909599',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontStyle: 'italic',
      fontWeight: '500',
      letterSpacing: '0',
      lineHeight: '20px',
      textAlign: 'center',
      borderTop: '1px solid #E3E3E4',
      paddingTop: '15px',
    },
    divider: {
      width: '100%',
      position: 'fixed',
      bottom: '0',
      zIndex: '10',
      background: '#fff',
    },
    fullWidth: {
      width: '97%',
    },
    search: {
      width: '100%',
    },
  });

interface getSubTitleFunc {
  (): string;
}

interface getStartDateFunc {
  (): string;
}

interface DraggableListProps extends WithStyles<typeof styles> {
  list: any[];
  onListChange: (value1: any, value2: any) => void;
  classes: any;
  subTitle: getSubTitleFunc | string;
  startDate: getStartDateFunc | string;
  removable: boolean;
}

export function DraggableListInstructor({
  list,
  onListChange,
  classes,
  startDate,
  subTitle,
  removable = true,
}: DraggableListProps) {
  return (
    <List
      items={list}
      removable={removable}
      onChange={({ oldIndex, newIndex }) => {
        let newList =
          newIndex === -1
            ? arrayRemove(list, oldIndex)
            : arrayMove(list, oldIndex, newIndex);
        let uniqueList = getUniqItems(newList, 'id');
        onListChange(uniqueList);
      }}
      overrides={{
        Label: {
          component: ({ $value, $index }) => (
            <div
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
              key={$index}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {$value && $value.startDate && (
                  <>
                    {moment(parseInt($value.startDate)).format('ddd, MMM D')}{' '}
                    {''}• {moment(+parseInt($value.startDate)).format('LT')}
                    {$value.duration && (
                      <>
                        -{' '}
                        {getTimeAfterMinutes($value.startDate, $value.duration)}
                      </>
                    )}
                  </>
                )}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 700,
                }}
              >
                {$value &&
                $value.interactive &&
                $value.interactive.firstName ? (
                  <>
                    {' '}
                    {$value.interactive.firstName}
                    {'  '}
                    {$value.interactive.lastName}{' '}
                  </>
                ) : (
                  <>
                    {$value && $value.firstName} {$value && $value.lastName}{' '}
                  </>
                )}
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {$value &&
                  $value.genres &&
                  $value.genres.length > 0 &&
                  $value.genres[0].title}
                {$value && $value.difficulty && (
                  <>
                    {''}• {$value.difficulty}
                  </>
                )}
              </Typography>
            </div>
          ),
        },
        List: {
          style: ({ $theme }) => {
            return {
              outline: `none`,
              marginTop: '0',
            };
          },
        },
        Item: {
          style: ({ $theme, $isSelected, $isDragged }) => {
            return {
              outline: 'none',
              zIndex: '100000!important',
              borderBottomColor: '#E3E3E4!important',
              boxShadow:
                $isSelected || $isDragged
                  ? '0 2px 2px 0 rgba(0,0,0,0.5);'
                  : '0 2px 2px 0 rgba(0,0,0, 0);',
              borderTopColor: '#E3E3E4!important',
              borderLeftColor: '#E3E3E4!important',
              borderRightColor: '#E3E3E4!important',
              borderTopWidth: '1px !important',
              borderLeftWidth: '1px !important',
              borderRightWidth: '1px !important',
              borderBottomWidth: '1px !important',
              paddingLeft: '8px',
              paddingRight: '8px',
              paddingTop: '8px',
              paddingBottom: '8px',
              borderRadius: '6px',
              marginBottom: '6px',
              width: '100%',
            };
          },
        },
        CloseHandle: {
          component: props => (
            <IconButton
              {...props}
              style={{ marginLeft: '16px', padding: '4px' }}
            >
              <CancelIcon
                style={{ color: '#D6D9DC', width: '20px', height: '20px' }}
              />
            </IconButton>
          ),
        },
        DragHandle: {
          style: ({ $theme, $isSelected, $isDragged }) => {
            return {
              '& > svg': {
                color: 'rgba(0,0,0,0.54)!important',
              },
            };
          },
        },
      }}
    />
  );
}

export default withStyles(styles)(DraggableListInstructor);
