import React from 'react';
import {
  createStyles,
  makeStyles,
  Avatar,
  Theme,
  IconButton,
} from '@material-ui/core';
import { getFallbackIcon } from '../../../utils/commonFunctions';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridGap: '8px',
      gridTemplateColumns: '64px 1fr',
      alignItems: 'center',
      width: '100%',
      margin: '8px 0',
      '& p': {
        margin: 0,
      },
    },
    avatar: {
      width: '64px',
      height: '64px',
      borderRadius: '5px',
    },
    conceptTitle: {
      color: '#12161A',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '17px',
      letterSpacing: '0',
      lineHeight: '24px',
    },
    text: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
    },
    videoItemCloseButton: {
      marginLeft: '16px',
      padding: '4px',
    },
  }),
);

export function RecommendedCollection({
  $value,
  $index,
}: {
  $value: any;
  $index: any;
}) {
  const classes = useStyles();

  return (
    <>
      {$value && (
        <div className={classes.wrapper} key={$index}>
          <Avatar
            variant="square"
            src={$value && $value.graphics && $value.graphics['1x1']}
            classes={{
              root: classes.avatar,
            }}
          >
            <img src={getFallbackIcon($value && $value.collectionType)} />
          </Avatar>

          <div>
            <p className={classes.conceptTitle}>{$value && $value.title}</p>
            {$value && $value.kicker && (
              <p className={classes.text}>{$value.kicker}</p>
            )}
            {$value && $value.classCount && (
              <p className={classes.text}>{`${$value.classCount} videos`}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function DeleteIcon(props: any) {
  const classes = useStyles();

  return (
    <>
      <IconButton {...props} classes={{ root: classes.videoItemCloseButton }}>
        <DeleteOutlinedIcon />
      </IconButton>
    </>
  );
}
