/*
 *
 * People actions
 *
 */

import { action } from 'typesafe-actions';
import { PeopleList } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

//Get People list
export const requestPeopleList = () => {
  return action(ActionTypes.REQUEST_PEOPLE_LIST);
};

export const peopleListSuccess = (peopleList: PeopleList) => {
  return action(ActionTypes.PEOPLE_LIST_SUCCESS, peopleList);
};

export const peopleListFailed = () => {
  return action(ActionTypes.PEOPLE_LIST_FAILED);
};

export const resetPeople = () => {
  return action(ActionTypes.RESET_PEOPLE);
};

export const serSortBy = sortBy => {
  return action(ActionTypes.SET_SORT_BY, sortBy);
};

export const setTabChange = tab => {
  return action(ActionTypes.SET_ACTIVE_TAB, tab);
};

export const setSearchKey = key => {
  return action(ActionTypes.GET_SEARCH_RESULT, key);
};

//get one instrcutor details
export const requestPeopleDetail = id => {
  return action(ActionTypes.REQUEST_PEOPLE_DETAIL, id);
};

export const peopleDetailSuccess = result => {
  return action(ActionTypes.PEOPLE_DETAIL_SUCCESS, result);
};

export const peoplePackageSuccess = result => {
  return action(ActionTypes.PEOPLE_PACKAGE_SUCCESS, result);
};

export const updatePeopleInfo = updatedInfo => {
  return action(ActionTypes.PEOPLE_UPDATED_INFO, updatedInfo);
};

export const getPeopleClasses = id => {
  return action(ActionTypes.GET_PEOPLE_CLASSES, id);
};

export const peopleClassesSuccess = result => {
  return action(ActionTypes.PEOPLE_CLASSES_SUCCESS, result);
};

export const peopleClassesFailed = () => {
  return action(ActionTypes.PEOPLE_CLASSES_FAILED);
};

export const setLoader = (isLoading: boolean) =>
  action(ActionTypes.INSTRUCTOR_DETAILS_LOADING, isLoading);

//For flags & selectedvalues
export const otherDetailSuccess = result => {
  return action(ActionTypes.OTHER_DETAIL_SUCCESS, result);
};

export const requestOtherDetails = id => {
  return action(ActionTypes.REQUEST_OTHER_DETAILS, id);
};

export const addInstructorDetails = value => {
  return action(ActionTypes.POST_ADD_INSTRUCTOR, value);
};

export const personLoader = (isPersonLoading: boolean) =>
  action(ActionTypes.PERSON_LOADING, isPersonLoading);

export const peopleListCountSuccess = (total: number) => {
  return action(ActionTypes.PEOPLE_LIST_TOTAL, total);
};

export const peopleListPaginationSuccess = payload => {
  return action(ActionTypes.PEOPLE_LIST_PAGINATION_SUCCESS, payload);
};
export const requestPeopleListPagination = payload => {
  return action(ActionTypes.REQUEST_PEOPLE_LIST_PAGINATION, payload);
};

export const setInteractivePeopleOnly = payload => {
  return action(ActionTypes.SET_INTERACTIVE_PEOPLE_ONLY, payload);
};

export const putPeoplePagination = () => {
  return action(ActionTypes.PUT_PEOPLE_LIST_PAGINATION_SUCCESS);
};
