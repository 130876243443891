/*
 *
 * LiveClass reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  currentLiveClass: {},
  isLoading: false,
};

function liveClassReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_LIVE_VIDEO_LIST:
      return { ...state };
    case ActionTypes.STORE_LIVE_VIDEO_LIST:
      return {
        ...state,
        currentLiveClass: action.payload,
      };
    case ActionTypes.SET_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

export default liveClassReducer;
