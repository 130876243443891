export const KEY_BASE64 = process.env.REACT_APP_KEY_BASE64;
export const IV_BASE64 = process.env.REACT_APP_IV_BASE64;

export let baseUrl = process.env.REACT_APP_ADMIN_API_SERVICE;
export const appBaseUrl = process.env.REACT_APP_APP_API_SERVICE;
export const appApiURL = process.env.REACT_APP_WEBAPP_BASE_URL;

export const uploadBaseUrl = process.env.REACT_APP_TRANSCODING_SERVICE;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_KEY = process.env.REACT_APP_A_S_KEY;
export const BUCKET_NAME = process.env.REACT_APP_B_NAME;
export const BUCKET_REGION = process.env.REACT_APP_B_REGION;

export const API_HASH_USER = process.env.REACT_APP_API_HASH_USER;
export const API_HASH = process.env.REACT_APP_API_HASH;
export let ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY;

export const cloudfrontUrl = 'https://dbt240e7wcko5.cloudfront.net';

export const NEOUS_APP_URL =
  process.env.REACT_APP_NEOUS_APP_URL || 'https://neou3-dev.neoufitness.com';
