import axios, {
  AxiosProxyConfig,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import Cookies from 'universal-cookie';
import { encrypt, decrypt } from '../../utils/crypto';
import {
  KEY_BASE64,
  appBaseUrl,
  API_HASH_USER,
  API_HASH,
} from '../../utils/config';

// import constants from 'shared/constants'

/**
 * Create an Axios Client with defaults
 */
const client: AxiosInstance = axios.create({
  baseURL: appBaseUrl,
});

const fetchAPIToken = () => {
  return fetch(`${appBaseUrl}/neou/v1/api/getToken`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: API_HASH_USER,
      password: API_HASH,
    }),
  });
};
//
client.interceptors.request.use(
  async config => {
    const cookies = new Cookies();
    let token = cookies.get('apiToken');
    if (!token) {
      const response = await fetchAPIToken();
      const result = await response.json();
      token = result.token;
      cookies.set('apiToken', token);
    }
    config.headers['authorization'] = 'JWT ' + token;
    config.headers['token'] = cookies.get('adminToken');
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

/**
 * Request Wrapper with default success/error actions
 */
const appRequest = function(options: AxiosRequestConfig) {
  const onSuccess = function(response) {
    return response.data;
  };

  const onError = function(error) {
    console.error('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export default appRequest;
