/*
 *
 * InstructorAddClasses actions
 *
 */

import { action } from 'typesafe-actions';
import { VideoList } from './types';

import ActionTypes from './constants';

export const requestInteractiveVideoList = (instrId: string) => {
  return action(ActionTypes.REQUEST_INTERACTIVE_VIDEO_LIST, instrId);
};

export const VideoListSuccess = videoList => {
  return action(ActionTypes.VIDEO_LIST_SUCCESS, videoList);
};

export const VideoListCountSuccess = (total: number) => {
  return action(ActionTypes.VIDEO_LIST_TOTAL, total);
};

export const serSortBy = sortBy => {
  return action(ActionTypes.SET_SORT_BY, sortBy);
};

export const setTabChange = tab => {
  return action(ActionTypes.SET_ACTIVE_TAB, tab);
};

export const videoListFailed = () => {
  return action(ActionTypes.VIDEO_LIST_FAILED);
};

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
