/*
 *
 * SignOutHome
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import makeSelectSignOutHome from './selectors';
import reducer from './reducer';
import saga from './saga';
import { Box, Grid, Typography, Button, Drawer } from '@material-ui/core';
import { useStyles } from '../HomeCurate';
import { requestSignOutPage, editSignOut } from './actions';
import { TraySection as TopSectionTray } from '../../components/CurrateTopSection/TopSection';
import LowerSectionTrayList from '../../components/CurrateTopSection/LowerSection';
import { SvgIconAdd } from '../../components/NeIcons';
import ConfirmDialog from '../../components/ConfirmDialog';
import { DrawerView } from '../HomeCurate/components/DrawerView';
import { ComponentEditor } from '../HomeCurate/components/ComponentEditor';
import { ProgramEditor, CarouselItem } from './components/ComponentEditor';
import { CarouselEditor } from './components/ComponentEditor';
import { object, string, array } from 'yup';
import Loader from '../../components/Loader';
import { ErrorText } from '../../components/Typography';

const stateSelector = createStructuredSelector({
  signOutHome: makeSelectSignOutHome(),
});

interface Props {}

export enum FormType {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

function SignOutHome(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'signOutHome', reducer: reducer });
  useInjectSaga({ key: 'signOutHome', saga: saga });
  const { signOutHome } = useSelector(stateSelector);
  const { components = [], isLoading } = signOutHome;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [isError, setError] = React.useState(false);

  const initialFormValue = {
    componentTitle: '',
    componentOrder: {},
    componentType: '',
    contentType: '',
    content: {
      data: [],
      mode: '',
    },
    currentComponentOrder: 0,
    userSegments: 'No Fitness Profile',
  };

  const addCarousel = {
    imgURL: '',
    smallImgUrl: '',
    buttonTxt: '',
    title: '',
    genresName: '',
    videoUrl: '',
    trailerTxt: '',
    thumbNail: '',
  };

  const [componentFormInfo, setComponentFormInfo] = React.useState({
    isConcept: false,
    isProgram: false,
    isCarousel: false,
    isOpen: false,
    type: FormType.ADD,
    item: { ...initialFormValue },
    isPromo: false,
    isRemovable: true,
  });

  // topCarousel
  let topCarousel = components;
  topCarousel =
    components &&
    components.length > 0 &&
    components.filter(
      item => item && item.componentType === 'carouselWithButton',
    );

  topCarousel = topCarousel && topCarousel.length > 0 && topCarousel[0];

  // conceptTray
  let conceptTray = components;
  conceptTray =
    components &&
    components.length > 0 &&
    components.filter(item => item && item.contentType === 'concept');

  conceptTray = conceptTray && conceptTray.length > 0 && conceptTray[0];

  // collectionTray
  let collectionTray = components;
  collectionTray =
    components &&
    components.length > 0 &&
    components.filter(item => item && item.contentType === 'collections');

  collectionTray =
    collectionTray && collectionTray.length > 0 && collectionTray[0];

  useEffect(() => {
    _init();
  }, []);

  const _init = () => {
    dispatch(requestSignOutPage());
  };

  const hanldeTopSectionTrayManage = item => {
    let formValue = { ...item };
    formValue.userSegments = 'No Fitness Profile';
    setComponentFormInfo({
      isOpen: true,
      isCarousel: true,
      isConcept: false,
      isProgram: false,
      item: { ...initialFormValue, ...formValue },
      type: FormType.EDIT,
      isPromo: false,
      isRemovable: false,
    });
  };
  const handleCarouselSubmit = (formValues, type) => {
    if (componentFormInfo.type === 'ADD') {
      let contentData = topCarousel.content.data;
      contentData.push({
        imgURL: formValues.imgURL || '',
        smallImgUrl: formValues.smallImgUrl || '',
        order: topCarousel.content.data.length + 1,
        buttonTxt: formValues.buttonTxt || '',
        title: formValues.title || '',
        genresName: formValues.genresName || '',
        videoUrl: formValues.videoUrl || '',
        trailerTxt: formValues.trailerTxt || '',
        thumbNail: formValues.thumbNail || '',
      });
      let finalData = {
        pageName: 'signout',
        ...topCarousel,
        content: {
          data: contentData,
          mode: 'manuallyCurated',
        },
        updatedAt: new Date().getTime(),
      };
      delete finalData.currentComponentOrder;
      dispatch(editSignOut(finalData));
      handleEditDrawerClose();
    } else {
      if (formValues && formValues.order) {
        let contentData = [];
        topCarousel.content.data.map((u, index) => {
          if (index + 1 === formValues.order) {
            contentData.push({ ...formValues });
          } else if (formValues.order !== u.order) {
            contentData.push({
              imgURL: u.imgURL,
              smallImgUrl: u.smallImgUrl,
              order: u.order,
              buttonTxt: u.buttonTxt,
              title: u.title,
              genresName: u.genresName,
              videoUrl: u.videoUrl,
              trailerTxt: u.trailerTxt,
              thumbNail: u.thumbNail,
            });
          }
        });
        let finalData = {
          pageName: 'signout',
          ...topCarousel,
          componentTitle: formValues.componentTitle,
          content: {
            data: [...contentData],
            mode: 'manuallyCurated',
          },
          updatedAt: new Date().getTime(),
        };
        delete finalData.currentComponentOrder;
        dispatch(editSignOut(finalData));
        handleEditDrawerClose();
      } else {
        let contentData = [];

        if (
          formValues &&
          formValues.content &&
          formValues.content.data &&
          formValues.content.data.length > 0
        ) {
          formValues.content.data.map((u, index) => {
            contentData.push({
              imgURL: u.imgURL,
              smallImgUrl: u.smallImgUrl,
              order: index + 1,
              buttonTxt: u.buttonTxt,
              title: u.title,
              genresName: u.genresName,
              videoUrl: u.videoUrl,
              trailerTxt: u.trailerTxt,
              thumbNail: u.thumbNail || '',
            });
          });

          let finalData = {
            pageName: 'signout',
            ...topCarousel,
            componentTitle: formValues.componentTitle,

            content: {
              data: contentData,
              mode: 'manuallyCurated',
            },
            updatedAt: new Date().getTime(),
          };
          delete finalData.currentComponentOrder;
          dispatch(editSignOut(finalData));
          handleEditDrawerClose();
        } else {
          setError(true);
        }
      }
    }
  };

  const hanldeProgramSectionTrayManage = item => {
    let formValue = { ...item };
    let gallery = [];
    gallery =
      item &&
      item.content &&
      item.content.data &&
      item.content.data.map((i, index) => ({
        type: 'video',
        url: i.videoUrl,
        thumbnail: i.thumbNail,
      }));
    formValue.userSegments = 'No Fitness Profile';
    setComponentFormInfo({
      isOpen: true,
      isConcept: false,
      isProgram: true,
      isCarousel: false,
      item: { ...initialFormValue, ...formValue, gallery },
      type: FormType.EDIT,
      isPromo: false,
      isRemovable: false,
    });
  };

  const handleProgramSubmit = (formValues, type) => {
    let gallery = [];
    if (formValues && formValues.gallery) {
      formValues.gallery.map((u, index) => {
        gallery.push({
          videoUrl: u.url,
          thumbNail: u.thumbnail,
          order: index + 1,
        });
      });

      let finalData = {
        pageName: 'signout',
        ...collectionTray,
        componentTitle: formValues.componentTitle,
        buttonTxt: formValues.buttonTxt,
        subTitle: formValues.subTitle,
        content: {
          data: gallery,
          mode: 'manuallyCurated',
        },
        updatedAt: new Date().getTime(),
      };
      delete finalData.currentComponentOrder;
      dispatch(editSignOut(finalData));
    }
    handleEditDrawerClose();
  };

  const hanldeLowerSectionTrayManage = item => {
    let formValue = {
      componentTitle: item.genreTitle,
      content: {
        data:
          item &&
          item.conceptIds &&
          item.conceptIds.map((i, index) => ({
            id: i && i.id ? i.id : i,
            title: i && i.title ? i.title : '',
          })),
        mode: 'manuallyCurated',
        order: item.order,
      },
    };
    formValue.userSegments = 'No Fitness Profile';
    setComponentFormInfo({
      isConcept: true,
      isProgram: false,
      isCarousel: false,
      isOpen: true,
      item: { ...initialFormValue, ...formValue },
      type: FormType.EDIT,
      isPromo: false,
      isRemovable: true,
    });
  };

  const handleLowerSectionListChange = list => {
    let data = [];
    if (list && list.length > 0) {
      data = list.map((i, index) => ({
        genreTitle: i.genreTitle,
        conceptIds: i.conceptIds.map(i => i.id),
        order: index + 1,
      }));
    }
    let finalData = {
      pageName: 'signout',
      ...conceptTray,
      content: {
        data: data,
        mode: 'manuallyCurated',
      },
      updatedAt: new Date().getTime(),
    };
    delete finalData.currentComponentOrder;
    dispatch(editSignOut(finalData));
  };

  const handleEditDrawerClose = () =>
    setComponentFormInfo({
      isOpen: false,
      isConcept: false,
      isProgram: false,
      isCarousel: false,
      item: { ...initialFormValue },
    });

  //concept
  const handleEditFormSubmit = (formValues, type) => {
    let conceptData = [];
    conceptTray &&
      conceptTray.content &&
      conceptTray.content.data &&
      conceptTray.content.data.length > 0 &&
      conceptTray.content.data.map(u => {
        conceptData.push({
          genreTitle: u.genreTitle,
          conceptIds: u.conceptIds.map(i => i.id),
          order: u.order,
        });
      });
    if (
      formValues &&
      formValues.content &&
      formValues.content.data &&
      formValues.content.data.length > 0
    ) {
      if (type == 'ADD') {
        conceptData.push({
          genreTitle: formValues.componentTitle,
          conceptIds: formValues.content.data.map(u => u.id),
          order:
            conceptData && conceptData.length > 0 ? conceptData.length + 1 : 1,
        });
        let finalData = {
          pageName: 'signout',
          ...conceptTray,
          content: {
            data: [...conceptData],
            mode: 'manuallyCurated',
          },
          updatedAt: new Date().getTime(),
        };
        delete finalData.currentComponentOrder;
        dispatch(editSignOut(finalData));
      } else {
        let conceptData = [];
        conceptTray &&
          conceptTray.content &&
          conceptTray.content.data &&
          conceptTray.content.data.length > 0 &&
          conceptTray.content.data.map((u, index) => {
            if (u && u.order === formValues.content.order) {
              conceptData.push({
                genreTitle: formValues.componentTitle,
                conceptIds: formValues.content.data.map(u => u.id),
                order: formValues.content && formValues.content.order,
              });
            } else {
              conceptData.push({
                genreTitle: u.genreTitle,
                conceptIds: u.conceptIds.map(i => i.id),
                order: u.order,
              });
            }
          });

        let finalData = {
          pageName: 'signout',
          ...conceptTray,
          content: {
            data: conceptData,
            mode: 'manuallyCurated',
          },
          updatedAt: new Date().getTime(),
        };
        delete finalData.currentComponentOrder;
        dispatch(editSignOut(finalData));
      }
    }
    handleEditDrawerClose();
  };

  const deleteComponent = formValues => {
    let list = [];

    conceptTray &&
      conceptTray.content &&
      conceptTray.content.data &&
      conceptTray.content.data.length > 0 &&
      conceptTray.content.data.map(u => {
        list.push({
          genreTitle: u.genreTitle,
          conceptIds: u.conceptIds.map(u => u.id),
          order: u.order,
        });
      });
    var conceptData = list.filter(el => el.order !== formValues.content.order);
    if (conceptData && conceptData.length > 0) {
      conceptData = conceptData.map((i, index) => ({
        genreTitle: i.genreTitle,
        conceptIds: i.conceptIds,
        order: index + 1,
      }));
    }
    let finalData = {
      pageName: 'signout',
      ...conceptTray,
      content: {
        data: conceptData,
        mode: 'manuallyCurated',
      },
      updatedAt: new Date().getTime(),
    };
    delete finalData.currentComponentOrder;
    dispatch(editSignOut(finalData));
    handleEditDrawerClose();
  };

  return (
    <div>
      {isLoading && <Loader />}
      <Helmet>
        <title>Curate Signed Out Home</title>
        <meta name="description" content="Description of SignOutHome" />
      </Helmet>
      <Box p={2}>
        <div className={classes.root}>
          <div>
            <div className={classes.headWrap}>
              <div className={classes.head}>Curate: Signed Out Home Page</div>
            </div>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              classes={{
                root: classes.sectionTitle,
              }}
            >
              TOP SECTION
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TopSectionTray
              key={`top_section`}
              item={{
                ...topCarousel,
                componentTitle: 'Feature Carousel',
                userSegments: null,
              }}
              carouselType={'gallery'}
              handleManage={() => hanldeTopSectionTrayManage(topCarousel)}
              handleAdd={() => {
                setComponentFormInfo({
                  isConcept: false,
                  isProgram: false,
                  isCarousel: true,
                  isOpen: true,
                  type: FormType.ADD,
                  item: { ...initialFormValue, ...addCarousel },
                  isRemovable: true,
                  isPromo: false,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.section}>
              <Typography
                classes={{
                  root: classes.sectionTitle,
                }}
              >
                GENRE TRAYS
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                disableRipple={true}
                startIcon={<SvgIconAdd />}
                onClick={() => {
                  setComponentFormInfo({
                    isConcept: true,
                    isProgram: false,
                    isCarousel: false,
                    isOpen: true,
                    type: FormType.ADD,
                    item: {
                      ...initialFormValue,
                    },
                    isRemovable: true,
                    isPromo: false,
                  });
                }}
              >
                COMPONENT
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            {conceptTray && (
              <LowerSectionTrayList
                lowerSection={
                  conceptTray && conceptTray.content && conceptTray.content.data
                }
                headLine="Genre"
                handleComponentManage={hanldeLowerSectionTrayManage}
                handleListChanged={handleLowerSectionListChange}
                activeSegment={'No Fitness Profile'}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              classes={{
                root: classes.sectionTitle,
              }}
            >
              PROGRAM SECTION
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TopSectionTray
              key={`top_section_2`}
              item={{ ...collectionTray, userSegments: null }}
              handleManage={() =>
                hanldeProgramSectionTrayManage(collectionTray)
              }
              carouselType={'gallery'}
            />
          </Grid>
        </Grid>
        <Drawer
          anchor={'right'}
          open={componentFormInfo.isOpen && !componentFormInfo.isPromo}
          disableBackdropClick={true}
          onClose={() => showAlertToClose(true)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the component."
            onAgree={() => handleEditDrawerClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>

          <DrawerView
            handleClose={showAlertToClose}
            type={componentFormInfo.type}
            removable={
              componentFormInfo.type === FormType.EDIT &&
              !componentFormInfo.isPromo
            }
            title={`${componentFormInfo.type} ${
              componentFormInfo.isCarousel
                ? 'FEATURE'
                : `${
                    componentFormInfo.isProgram ? 'PROGRAM' : 'GENRE'
                  } COMPONENT`
            }`}
          >
            {' '}
            {componentFormInfo.isProgram && (
              <ProgramEditor
                componentFormInfo={componentFormInfo}
                ComponentSchema={ProgramFormSchema}
                handleEditFormSubmit={data =>
                  handleProgramSubmit(data, componentFormInfo.type)
                }
              />
            )}
            {isError && componentFormInfo.type === 'EDIT' && (
              <div style={{ margin: '20px' }}>
                <ErrorText>Carousel Images are required</ErrorText>
              </div>
            )}
            {componentFormInfo.isCarousel &&
              componentFormInfo.type === 'EDIT' && (
                <CarouselEditor
                  componentFormInfo={componentFormInfo}
                  ComponentSchema={CarouselComponentSchema}
                  CarouselItemSchema={CarouselItemSchema}
                  handleEditFormSubmit={data =>
                    handleCarouselSubmit(data, componentFormInfo.type)
                  }
                />
              )}
            {componentFormInfo.isConcept && (
              <ComponentEditor
                lowerSection={conceptTray}
                componentFormInfo={componentFormInfo}
                possibleOptions={[]}
                ComponentSchema={ConceptFormSchema}
                handleEditFormSubmit={data =>
                  handleEditFormSubmit(data, componentFormInfo.type)
                }
                handleComponentDelete={deleteComponent}
              />
            )}
            {componentFormInfo.isCarousel &&
              componentFormInfo.type === 'ADD' && (
                <CarouselItem
                  componentFormInfo={componentFormInfo}
                  ComponentSchema={CarouselItemSchema}
                  handleEditFormSubmit={data =>
                    handleCarouselSubmit(data, componentFormInfo.type)
                  }
                />
              )}
          </DrawerView>
        </Drawer>
      </Box>
    </div>
  );
}

export default SignOutHome;

export const ConceptFormSchema = object({
  componentTitle: string().required('Enter a valid title'),
  content: object({
    data: array()
      .min(1, 'Enter atleast one item')
      .max(30, 'Enter atmost 30 item')
      .required(),
  }),
});

export const ProgramFormSchema = object({
  componentTitle: string().required('Enter a valid title'),
  subTitle: string().required('Enter description'),
  buttonTxt: string().required('Enter a button text'),
  gallery: array().required('Enter atleast one gallery item'),
});

export const CarouselComponentSchema = object({});

export const CarouselItemSchema = object({
  imgURL: string().required('Enter a valid imgURL'),
  smallImgUrl: string().required('Enter a valid smallImgUrl'),
  buttonTxt: string().required('Enter a buttonTxt'),
  title: string().required('Enter a valid title'),
  genresName: string().required('Enter a valid genre name'),
  videoUrl: string().required('Enter a valid videoUrl'),
  trailerTxt: string().required('Enter a  trailer text'),
});
