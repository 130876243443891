/*
 *
 * Attributes
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectAttribute from './selectors';
import reducer from './reducer';
import saga from './saga';
import { Button, InputAdornment } from '@material-ui/core';
import AddAttributeDialog from './AttributeTable/AddAttributeDialog';
import { requestAttributes, getSeachResult } from './actions';
import AttributeListTable from './AttributeTable/AttributeTable';
import { useStyles } from './styles';
import { SvgIconSearch, SvgIconAdd } from '../../components/NeIcons';
import SortSelect from './Select';
import Loader from '../../components/Loader';
import NEInput from '../../components/NEInput';
import { useStyles as useStyles1 } from './AttributeTable/styles';

const stateSelector = createStructuredSelector({
  attributes: makeSelectAttribute(),
});

interface Props {}

function Attributes(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'attribute', reducer: reducer });
  useInjectSaga({ key: 'attribute', saga: saga });

  const { attributes } = useSelector(stateSelector);
  let { attributeList, isListFetchingError, isLoading } = attributes;

  const dispatch = useDispatch();
  const classes = useStyles();
  const classes1 = useStyles1();
  const [searchText, setSearchText] = React.useState('');
  const [state, setState] = React.useState({
    isAddClick: false,
  });
  useEffect(() => {
    // Update the document title using the browser API
    dispatch(requestAttributes());
  }, []);

  const handleInput = e => {
    setSearchText(e.target.value);
  };
  const openAddAttributeDialog = () => {
    setState({ ...state, isAddClick: true });
  };
  const onSearchClick = () => {
    dispatch(getSeachResult(searchText));
  };

  const closeAddDialog = () => {
    setState({ ...state, isAddClick: false });
  };

  return (
    <>
      <Helmet>
        <title>Configure Attributes</title>
        <meta name="description" content="Tags configuration" />
      </Helmet>
      {isLoading && <Loader />}

      <div className={classes.root}>
        <div>
          <div className={classes.headWrap}>
            <div className={classes.head}>Configure: Attributes</div>
            <div className={classes.searchWrap}>
              <NEInput
                label="Search"
                variant="outlined"
                id="custom-css-outlined-input"
                fullWidth={true}
                onChange={e => handleInput(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={() => onSearchClick()}>
                        <SvgIconSearch />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.headWrap}>
            <Button
              onClick={() => {
                openAddAttributeDialog();
              }}
              variant="contained"
              color="secondary"
              className={classes.addAttributeBtn}
              disableRipple={true}
            >
              <SvgIconAdd />{' '}
              <div className={classes.newAttribute}>Attributes</div>
            </Button>
            <SortSelect />
          </div>

          {isListFetchingError &&
          (attributeList && attributeList.length === 0) ? (
            <div className={classes.noData}>No Data Found</div>
          ) : (
            <AttributeListTable list={attributeList} isLoading={isLoading} />
          )}
        </div>
      </div>
      {state.isAddClick && (
        <AddAttributeDialog
          classes={classes1}
          isOpen={state.isAddClick}
          closeAddDialog={closeAddDialog}
        />
      )}
    </>
  );
}

export default Attributes;
