import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SvgIconEdit, SvgIconAlert } from '../../../components/NeIcons';
import moment from 'moment';
import { useStyles } from './styles';
import { IconButton, Avatar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ConceptFallbackIcon from '../../../images/concept_fallback_icon.png';
import ConfirmDialog from '../../../components/ConfirmDialog';
import ConceptFormDialog, { FormType } from '../components/ConceptFormDialog';

export default function ConceptListTable(props) {
  const classes = useStyles();

  let { list, isLoading, isInteractiveFilterOn } = props;
  const [isEditDialogOpen, openEditDialog] = React.useState(false);
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  const [activeConceptId, setActiveConceptId] = React.useState('');
  const [conceptDialogData, setConceptDialogData] = React.useState({
    isOpen: false,
    id: '',
  });

  const dispatch = useDispatch();
  const getDate = list => {
    if (list.isPublished) {
      return (
        <div>
          {`Updated ${moment(list.updatedAt).format(`L`)}`} <br /> Published{' '}
          {`${moment(list.publishedDate).format(`L`)}`}
        </div>
      );
    } else {
      return `Saved ${moment(list.updatedAt).format(`L`)}`;
    }
  };

  const renderCTA = list => {
    let isPublished = false;
    let isHidden = false;
    let isError = false;
    if (isInteractiveFilterOn) {
      isPublished = list.interactive && list.interactive.isPublished;
      isHidden = list.interactive && list.interactive.isHidden;
      isError = list.interactive && list.interactive.isError;
    } else {
      isPublished = list.isPublished;
      isHidden = list.isHidden;
      isError = list.isError;
    }
    if (isHidden) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'HIDDEN'}
        </div>
      );
    } else if (isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'PUBLISHED'}
        </div>
      );
    } else if (!isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.draft}`}>
          {'DRAFT'}
        </div>
      );
    }
  };

  const getCategories = list => {
    if (isInteractiveFilterOn) {
      if (
        list.interactive &&
        list.interactive.categories &&
        list.interactive.categories.length > 0
      ) {
        let result = list.interactive.categories.map(item => {
          return item.title;
        });
        return result.join(', ');
      }
    } else {
      if (list && list.categories && list.categories.length > 0) {
        let result = list.categories.map(item => {
          return item.title;
        });
        return result.join(', ');
      }
      return [];
    }
  };

  const getInteractiveDate = list => {
    if (
      list.interactive &&
      list.interactive.isPublished &&
      list.interactive.updatedAt
    ) {
      return (
        <div>
          {`Updated ${moment(list.interactive.updatedAt).format(`L`)}`} <br />{' '}
          {list.interactive &&
            list.interactive.publishedDate &&
            ` Published     ${moment(list.interactive.publishedDate).format(
              `L`,
            )}`}
        </div>
      );
    } else {
      return (
        list.interactive &&
        list.interactive.updatedAt &&
        `Saved ${moment(list.interactive.updatedAt).format(`L`)}`
      );
    }
  };

  const onConceptEdit = id => {
    // openEditDialog(true);
    // dispatch(getConceptDetail(id));
    setConceptDialogData({
      isOpen: true,
      id: id,
    });
  };

  const onAgree = () => {
    openEditDialog(false);
  };

  const onDisAgree = () => {
    openEditDialog(true);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead></TableHead>
          <TableBody>
            {list &&
              list.map(list => {
                return (
                  <TableRow key={list.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell align="left">
                      <div className={classes.firstColumn}>
                        <div className={classes.box}>
                          <Avatar style={{width:'64px', height: '64px',  borderRadius: 6}}
                            variant="square"
                            src={
                              (list.graphics && list.graphics['1x1']) || (
                                <ConceptFallbackIcon />
                              )
                            }
                            classes={{
                              root: classes.iconHolder,
                              square: classes.iconSquare,
                              img: classes.iconImg,
                            }}
                          >
                            <img src={ConceptFallbackIcon} />
                          </Avatar>
                        </div>
                        <div className={classes.firstCoulmContent}>
                          <div className={classes.conceptTitle}>
                            {isInteractiveFilterOn
                              ? list.title &&
                                list.interactive &&
                                list.interactive.title
                              : list.title}
                          </div>
                          <div className={classes.text}>
                            {list.categories && list.categories.length === 0 ? (
                              <div>{'Categories undefined'}</div>
                            ) : (
                              <span>{getCategories(list)}</span>
                            )}
                          </div>
                          <div className={classes.text}>
                            {list.classCount + ' videos'}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={classes.text}>
                        {!isInteractiveFilterOn && getDate(list)}
                        {isInteractiveFilterOn && getInteractiveDate(list)}
                      </div>
                    </TableCell>
                    <TableCell align="center">{renderCTA(list)}</TableCell>
                    <TableCell align="right">
                      {list.isError ? (
                        <div>
                          <SvgIconAlert />
                        </div>
                      ) : (
                        <IconButton
                          onClick={() => onConceptEdit(list.id)}
                          classes={{ root: classes.editIcon }}
                        >
                          <SvgIconEdit />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {conceptDialogData && conceptDialogData.isOpen && (
        <ConceptFormDialog
          type={FormType.EDIT}
          isOpen={conceptDialogData.isOpen}
          id={conceptDialogData.id}
          title="EDIT CONCEPT"
          onClose={() => setConceptDialogData({ isOpen: false, id: '' })}
        />
      )}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        handleClose={openConfirmDialog}
        onAgree={onAgree}
        onDisagree={onDisAgree}
        agreeButtonText="Yes"
        disagreeBtnText="Cancel"
        headerText="Are you sure?"
        bodyText="You’re about to close this Edit Concept dialog box."
      ></ConfirmDialog>
    </Paper>
  );
}
