/*
 *
 * InReview
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';

import reducer from '../InteractivePublishedClasses/reducer';
import saga from '../InteractivePublishedClasses/saga';
import {
  SvgIconSearch,
  SvgIconAdd,
  SvgLoaderGif,
} from '../../components/NeIcons';
import { useStyles } from '../Video/styles';
import Loader from '../../components/Loader';
import StyledDialog from '../../components/StyledDialog';
import AddClasses from '../InteractivePublishedClasses/components/AddClasses';
import Button from '@material-ui/core/Button';
import SortSelect from '../InteractivePublishedClasses/Select';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';
import ClassListTable from '../InteractivePublishedClasses/components/ClassTable/ClassTable';
import FilterpickerButton from '../../components/CommonFilter/FilterpickerButton';
import FilterSearchResultsButton from '../../components/CommonFilter/FilterSearchResultsButton';
import {
  setTabChange,
  requestInteractiveVideoList,
  requestFilters,
  requestFilterParamsList,
  requestVideoListWithFiltersInteractive,
  updateSearchCount,
  requestClassListPagination,
  searchResultsPagination,
} from '../InteractivePublishedClasses/actions';
import { setInteractiveOnlyAction } from '../Video/actions';
import makeSelectInteractivePublishedClasses from '../InteractivePublishedClasses/selectors';
import CommonFilterInteractive from '../../components/CommonFilterInteractive';
import moment from 'moment';
import FilterChipsBoxes from '../../components/CommonFilter/FilterChipsBoxes';
import FilterSearchLabel from '../../components/CommonFilter/FilterSearchLabel';

const stateSelector = createStructuredSelector({
  interactiveClass: makeSelectInteractivePublishedClasses(),
});

interface Props {
  classes: StyleType;
}

type StyleType = Record<
  | 'root'
  | 'head'
  | 'subHead'
  | 'upperHeaderSection'
  | 'lowerHeaderSection'
  | 'headWrap'
  | 'addVideoBtn'
  | 'newVideo'
  | 'searchIcon'
  | 'styledTab'
  | 'noData'
  | 'divider'
  | 'filterPanel'
  | 'footer'
  | 'title__heading'
  | 'searchHeader'
  | 'searchBar'
  | 'startOver'
  | 'selectedContainer'
  | 'selectedcontained'
  | 'chipBoxes'
  | 'addFilter'
  | 'sortItem'
  | 'dividers'
  | 'liveEvent'
  | 'loadMoreBtn',
  string
>;

export enum FormType {
  ADD = 'add',
  EDIT = 'edit',
}

function InReview(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file

  useInjectReducer({ key: 'interactiveClass', reducer: reducer });
  useInjectSaga({ key: 'interactiveClass', saga: saga });

  const { interactiveClass } = useSelector(stateSelector);
  const [activeTab, setActiveTab] = React.useState(0);
  const [APIRangeValue, setAPIRangeValue] = React.useState([]);
  const [APIDifficultyValue, setAPIDifficultyValue] = React.useState([]);
  const [difficult, setDifficult] = React.useState([]);
  const [showFilterOption, setShowFilterOption] = React.useState(false);
  const [showChipBoxes, setShowChipBoxes] = React.useState(false);
  /* Boolean to show/hide the Results from the after the API trigger */
  const [showResultOption, setshowResultOption] = React.useState(false);
  const [selectedFilterParams, setselectedFilterParams] = React.useState({
    duration: [5, 90],
    bodyFocus: APIRangeValue,
    difficulty: difficult,
    equipments: [],
    music: [],
    language: [],
  });

  const [selectedAPIData, setSelectedAPIData] = React.useState({
    keyword: '',
    explicitLang: false,
    duration: APIRangeValue,
    bodyFocus: [],
    difficulty: APIDifficultyValue,
    equipments: [],
    music: [],
    language: [],
    selectedCount: null,
    showFilterOption: showFilterOption,
    showResultOption: showResultOption,
  });

  const {
    videoList,
    isListFetchingError,
    isLoading,
    filterData,
    searchResultsCount,
    durationDetails,
    videoTotalCount,
    isClassLoading,
    total,
  } = interactiveClass;

  const dispatch = useDispatch();
  const classes: StyleType = useStyles();
  const [addClassOpen, setAddClassData] = React.useState({
    isOpen: false,
  });
  const [showFilterModal, setShowFilterModal] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  const [filterCount, setFilterCount] = React.useState(0);
  const [callParams, setParams] = React.useState(true);

  let durationLength = React.useMemo(() => {
    return (
      (filterData &&
        filterData.duration &&
        filterData.duration.values &&
        filterData.duration.values.length) ||
      0
    );
  }, [filterData]);

  const tabConfig = [
    {
      label: 'DRAFTS',
      component: <ClassListTable />,
    },
    {
      label: 'READY FOR REVIEW',
      component: <ClassListTable />,
    },
    {
      label: 'FLAGGED',
      component: <ClassListTable />,
    },
  ];

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setShowChipBoxes(false);
    intialValues();
    if (tabIndex === 0) {
      dispatch(
        setTabChange(
          'flagged=false&inreview=false&isPublished=false&isHidden=false&isCancelled=false',
        ),
      );
      dispatch(requestInteractiveVideoList());
    } else if (tabIndex === 1) {
      dispatch(
        setTabChange(
          'inreview=true&isPublished=false&isHidden=false&isCancelled=false',
        ),
      );
      dispatch(requestInteractiveVideoList());
    } else if (tabIndex === 2) {
      dispatch(setTabChange('flagged=true&isHidden=false&isCancelled=false'));
      dispatch(requestInteractiveVideoList());
    }
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (activeTab === 0) {
      dispatch(
        setTabChange(
          'flagged=false&inreview=false&isPublished=false&isHidden=false&isCancelled=false',
        ),
      );
    }
    dispatch(requestFilters(''));
    dispatch(requestInteractiveVideoList());
    return () => dispatch(setInteractiveOnlyAction(false));
  }, []);

  let videolistFiltered = videoList;
  if (activeTab === 2) {
    videolistFiltered = videolistFiltered.filter(
      video => video.isPublished === false,
    );
  } else if (activeTab === 3) {
    videolistFiltered = videolistFiltered.filter(
      video => video.interactive && video.interactive.isCancelled === true,
    );
  } else if (activeTab === 4) {
    videolistFiltered = videolistFiltered.filter(
      video => video.isHidden === true,
    );
  }

  const dispatchSearchAPIcall = () => {
    dispatch(requestVideoListWithFiltersInteractive(selectedAPIData));
    // dispatch(filterUpdateList());
    setShowFilterModal(false);
    setShowChipBoxes(true);
  };

  function toggleFilterPanel() {
    if (callParams) {
      dispatch(requestFilterParamsList());
      setParams(false);
    }
    setShowFilterModal(!showFilterModal);
  }

  const dispatchSearchAPIcallSuccess = () => {
    // dispatch(filterUpdateList());
    setShowFilterModal(false);
    setShowChipBoxes(true);
    setActiveTab(0);
  };

  const changedFilterParams = (
    filterParams,
    selectedFilterParams,
    selectedAPIData,
    rangeValue,
    difficultyValue,
  ) => {
    setselectedFilterParams(selectedFilterParams);
    setSelectedAPIData(selectedAPIData);
    setAPIRangeValue(rangeValue);
    setDifficult(difficultyValue);
    // dispatch(updateFilterParams(filterParams));
    if (selectedAPIData.keyword) {
      setKeyword(selectedAPIData.keyword);
    }
    filterCountCalculation();
    dispatch(requestVideoListWithFiltersInteractive(selectedAPIData));
  };

  function filterCountCalculation() {
    let objects = Object.keys(selectedFilterParams);
    let count = 0;
    objects.map(item => {
      if (selectedFilterParams[item] && selectedFilterParams[item].length) {
        if (
          item === 'duration' &&
          selectedFilterParams[item].length !== durationLength
        ) {
          count += 1;
        } else if (
          item === 'difficulty' &&
          selectedFilterParams[item].length !== 4
        ) {
          count += selectedFilterParams[item].length;
        } else if (item !== 'duration' && item !== 'difficulty') {
          count += selectedFilterParams[item].length;
        }
      }
      setFilterCount(count);
    });
    if (selectedAPIData.keyword.length === 0 && !count) {
      intialValues();
      // setShowChipBoxes(false);
    } else {
      // setShowChipBoxes(true);
    }
  }

  function intialValues() {
    setAPIDifficultyValue([]);
    setAPIRangeValue([]);
    setDifficult([]);
    let range = [];
    let difficul = [];
    let apiDifficul = [];
    const filterObject = Object.keys(filterData);
    filterObject.map(item => {
      if (item !== 'explicitLang') {
        filterData[item].values.map((itm, index) => {
          if (item == 'difficulty') {
            itm.isSelected = true;
            difficul.push(itm);
            apiDifficul.push(itm.id);
          } else if (item === 'duration') {
            range.push(itm.id);
          } else if (item !== 'difficulty' && item !== 'duration') {
            itm.isSelected = false;
            itm.mainTitle = item;
            itm.index = index;
          }
        });
        setAPIRangeValue(range);
        setDifficult(difficul);
        setAPIDifficultyValue(apiDifficul);
      }
    });
    setselectedFilterParams({
      duration: APIRangeValue,
      bodyFocus: [],
      difficulty: difficul,
      equipments: [],
      music: [],
      language: [],
    });
    setSelectedAPIData({
      keyword: '',
      explicitLang: false,
      duration: APIRangeValue,
      bodyFocus: [],
      difficulty: apiDifficul,
      equipments: [],
      music: [],
      language: [],
      selectedCount: null,
      showFilterOption: showFilterOption,
      showResultOption: showResultOption,
    });
    dispatch(updateSearchCount());
    setShowChipBoxes(false);
    setFilterCount(0);
  }

  const onSelectedKeyword = value => {
    selectedAPIData.keyword = value;
    setSelectedAPIData(selectedAPIData);
    if (value.length <= 2) {
      if (value.length == 0) {
        selectedAPIData.keyword = '';
      }
      setKeyword(value);
      selectedAPIData.selectedCount = 0;
    } else if (value.length > 2) {
      setKeyword(value);
      changedFilterParams(
        filterData,
        selectedFilterParams,
        selectedAPIData,
        APIRangeValue,
        APIDifficultyValue,
      );
    }
  };

  const onSelectedChips = (id?, mainTitle?, selected?, title?, index?) => {
    if (mainTitle !== 'duration') {
      filterData[mainTitle].values[index].isSelected = !filterData[mainTitle]
        .values[index].isSelected;
      selectedFilterParams[mainTitle] = [];
      selectedAPIData[mainTitle] = [];
      let difficultyResult = filterData[mainTitle].values.filter(itm => {
        if (itm.isSelected) {
          return itm;
        }
      });
      selectedFilterParams[mainTitle] = difficultyResult;
      let APIResults = selectedFilterParams[mainTitle].map(itm => {
        return itm.id;
      });
      selectedAPIData[mainTitle] = APIResults;

      // dispatch(changedFiltersParams(filterDatafilterData));
      // dispatch(selectedFilterParamsData(selectedFilterParams));
      filterCountCalculation();
      dispatchSearchAPIcall();
    } else {
      if (
        selectedAPIData.bodyFocus.length == 0 &&
        selectedAPIData.difficulty.length == 0 &&
        selectedAPIData.equipments.length == 0 &&
        selectedAPIData.music.length == 0 &&
        selectedAPIData.language.length == 0
      ) {
        setShowChipBoxes(false);
        intialValues();
        selectedAPIData.showFilterOption = true;
        selectedAPIData.showResultOption = false;
      } else {
        selectedAPIData.duration = APIRangeValue;
        selectedFilterParams.duration = APIRangeValue;
        // dispatch(selectedFilterParamsData(selectedFilterParams));
        dispatchSearchAPIcall();
      }
      filterCountCalculation();
    }
  };
  function loadMore() {
    if (
      showFilterModal === false &&
      ((keyword && keyword.length > 0) || filterCount > 0)
    ) {
      dispatch(
        searchResultsPagination({
          values: selectedAPIData,
          count: videoList.length,
        }),
      );
    } else if (videoList && videoList.length < total) {
      dispatch(requestClassListPagination(videoList.length));
    }
  }

  function getLastUpdateTime() {
    if (videoList.length === 0) return 'N/A';
    const times = videoList.map(v => v.updatedAt);
    const timesSorted = times.sort((a, b) => moment(b).diff(moment(a)));
    var momentTZ = require('moment-timezone');
    var zone = momentTZ.tz.guess();
    const lastUpdateTime =
      moment(timesSorted[0]).format('L') +
      ' ' +
      moment(timesSorted[0]).format('LT') +
      ' ' +
      momentTZ.tz(zone).format('z');
    return lastUpdateTime;
  }

  return (
    <div>
      {isLoading && <Loader />}
      <Helmet>
        <title>Interactive Draft Classes</title>
        <meta name="description" content="Description of InReview" />
      </Helmet>

      {addClassOpen.isOpen && (
        <StyledDialog
          isOpen={addClassOpen.isOpen || false}
          title="Add Group Interactive Classes"
          handleClose={() => {
            setAddClassData({ isOpen: false });
          }}
        >
          <AddClasses
            onClearEvent={() => {
              setAddClassData({ isOpen: false });
            }}
          />
        </StyledDialog>
      )}

      {showFilterModal === false && (
        <div className={classes.root}>
          <div className={classes.headWrap}>
            <div className={classes.upperHeaderSection}>
              <div className={classes.head}>Interactive: Draft</div>
              <div className={classes.searchIcon} onClick={toggleFilterPanel}>
                <SvgIconSearch />
              </div>
            </div>

            <div className={classes.lowerHeaderSection}>
              <Button
                onClick={() => {
                  setAddClassData({ isOpen: true });
                }}
                variant="contained"
                color="secondary"
                className={classes.addVideoBtn}
                style={{ width: '200px' }}
                disableRipple={true}
              >
                <SvgIconAdd />{' '}
                <div className={classes.newVideo}>NEW INTERACTIVE</div>
              </Button>
              <SortSelect />
            </div>
            {showFilterModal === false && (
              <div className={classes.searchHeader}>
                {((keyword && keyword.length > 0) || filterCount > 0) && (
                  <>
                    <div className={classes.searchBar}>
                      <FilterSearchLabel
                        predictions={filterData.searchSuggestions}
                        onChange={val => {
                          onSelectedKeyword(val);
                        }}
                        values={selectedAPIData.keyword}
                        onClick={dispatchSearchAPIcall}
                      />
                    </div>
                    <div className={classes.startOver}>
                      <FilterpickerButton
                        key={1}
                        title={'start over'}
                        startover={true}
                        onSelect={() => {
                          intialValues();
                          // toggleFilterPanel();
                          dispatch(requestInteractiveVideoList());
                          setKeyword('');
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            {showFilterModal === false && showChipBoxes && filterCount > 0 && (
              <div className={classes.selectedContainer}>
                <div className={classes.selectedcontained}>
                  <div className={classes.chipBoxes}>
                    <FilterChipsBoxes
                      durationLength={durationLength}
                      value={selectedFilterParams}
                      onSelect={(value: {
                        id: any;
                        mainTitle: any;
                        isSelected: any;
                        title: any;
                        index: any;
                      }) => {
                        onSelectedChips(
                          value.id,
                          value.mainTitle,
                          value.isSelected,
                          value.title,
                          value.index,
                        );
                      }}
                    />
                  </div>
                  <div
                    className={classes.addFilter}
                    onClick={toggleFilterPanel}
                  >
                    Add Filters
                  </div>
                </div>
              </div>
            )}
            <div style={{ marginTop: '40px', width: '100%' }}>
              <StyledTabs
                value={activeTab}
                onChange={onTabChange}
                aria-label="My account tabs"
                centered
                className={classes.styledTab}
              >
                {tabConfig.map((t, index) => (
                  <StyledTab key={index} label={t.label} />
                ))}
              </StyledTabs>
              {isListFetchingError ||
              !videolistFiltered ||
              (videolistFiltered && videolistFiltered.length === 0) ? (
                <div className={classes.noData}>No Data found</div>
              ) : (
                <ClassListTable
                  list={videolistFiltered}
                  isLoading={isLoading}
                  isReviewTab={true}
                />
              )}
              {isClassLoading && (
                <div style={{ padding: '10px' }}>
                  <SvgLoaderGif />
                </div>
              )}
              {!isClassLoading &&
                videoList &&
                videoList.length > 0 &&
                total !== videoList.length && (
                  <div className={classes.loadMoreBtn}>
                    <Button color="secondary" onClick={() => loadMore()}>
                      Load More Clasess
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      {showFilterModal && (
        <div className={classes.filterPanel}>
          <CommonFilterInteractive
            filterData={filterData}
            selectedParams={selectedFilterParams}
            selectedAPIParams={selectedAPIData}
            rangeValue={APIRangeValue}
            difficultyValue={difficult}
            onChangedValue={(
              filterParams,
              selectedFilterParams,
              selectedAPIData,
              rangeValue,
              difficultyValue,
            ) => {
              changedFilterParams(
                filterParams,
                selectedFilterParams,
                selectedAPIData,
                rangeValue,
                difficultyValue,
              );
            }}
            closeFilterPanel={toggleFilterPanel}
            interactiveOnly={true}
          />
          <div className={classes.footer}>
            {showFilterModal && (
              <div>
                <FilterpickerButton
                  key={1}
                  title={'Clear All'}
                  clearAll={true}
                  onSelect={() => {
                    intialValues();
                  }}
                />
                <FilterSearchResultsButton
                  onClick={dispatchSearchAPIcallSuccess}
                  value={
                    selectedAPIData.keyword &&
                    selectedAPIData.keyword.length > 0
                      ? searchResultsCount
                      : searchResultsCount
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default InReview;
