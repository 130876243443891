import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, Typography, Drawer } from '@material-ui/core';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { ComponentEditor } from './EmailInviteEditor';
import { DrawerView } from '../../HomeCurate/components/DrawerView';
import { TraySection as TopSectionTray } from '../../../components/CurrateTopSection/TopSection';
import { updateBrandVoice } from '../../BrandVoice/actions';

const initialFormValue = {
  componentTitle: 'Email Message',
  inviteDisplayText:
    'Hi! Check out NEOU so we can work out together with LIVE interactive, two-way video (we can see each other!) and on demand classes. Your first 14 days are free with promo code FRIEND!',
  invitePeopletoNeoU: `Hi! Check out NEOU so we can work out together with LIVE interactive, two-way video (we can see each other!) and on demand classes. Your first 14 days are free with promo code FRIEND!`,
};

export const InviteMessage = props => {
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [componentFormInfo, setComponentFormInfo] = React.useState({
    isOpen: false,
    type: 'EDIT',
    item: { ...initialFormValue },
  });

  const hanldeTopSectionTrayManage = item => {
    let formValue = { ...item };
    setComponentFormInfo({
      isOpen: true,
      item: { ...initialFormValue, ...formValue },
      type: 'EDIT',
    });
  };

  const handleEditDrawerClose = () => {
    setComponentFormInfo({
      isOpen: false,
      item: { ...initialFormValue },
      type: 'EDIT',
    });
  };

  const handleEditFormSubmit = (formValues, type) => {
    dispatch(
      updateBrandVoice({
        invitePeopletoNeoU: formValues.invitePeopletoNeoU,
        inviteDisplayText: formValues.inviteDisplayText,
      }),
    );
    setComponentFormInfo({
      isOpen: false,
      type: 'EDIT',
      item: { ...initialFormValue },
    });
  };

  return (
    <div>
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              classes={{
                root: classes.sectionTitle,
              }}
            >
              INVITE FRIENDS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TopSectionTray
              key={`top_section_inviteFriends`}
              item={componentFormInfo.item}
              handleManage={() =>
                hanldeTopSectionTrayManage(props.componentData)
              }
              hideRightInfo={true}
            />
          </Grid>

          <Drawer
            anchor={'right'}
            open={componentFormInfo.isOpen}
            disableBackdropClick={true}
            onClose={() => showAlertToClose(true)}
          >
            <ConfirmDialog
              isOpen={isCloseAlertOpen}
              headerText="Are you sure?"
              bodyText="You are about to close the component."
              onAgree={() => handleEditDrawerClose()}
              handleClose={() => showAlertToClose(false)}
              onDisagree={() => showAlertToClose(false)}
            ></ConfirmDialog>

            <DrawerView
              handleClose={showAlertToClose}
              type={componentFormInfo.type}
              title={`${componentFormInfo.type} COMPONENT`}
            >
              <ComponentEditor
                componentFormInfo={componentFormInfo}
                handleEditFormSubmit={data =>
                  handleEditFormSubmit(data, componentFormInfo.type)
                }
              />
            </DrawerView>
          </Drawer>
        </Grid>
      </Box>
    </div>
  );
};

export default InviteMessage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      fontFamily: 'ProximaNova-Bold',
      fontSize: '18px',
      lineHeight: '20px',
    },
  }),
);
