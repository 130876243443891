/*
 *
 * Root reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  apiToken: null,
  ageGroups: [],
  isAgeGroupsFetching: false,
  userInfo: {
    email: '',
  },
  isAuthModalOpen: false,
  username: '',
  isSnackBarOpen: false,
  snackBarMsg: '',
  alertModalOpen: false,
};

function rootReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.REQUEST_AGE_GROUP:
      return { ...state, isAgeGroupsFetching: true };
    case ActionTypes.FETCH_AGE_GROUP_SUCCESS:
      return { ...state, ageGroups: action.payload };
    case ActionTypes.TOGGLE_AUTH_MODAL:
      return { ...state, isAuthModalOpen: !state.isAuthModalOpen };
    // open and close snackbar actions
    case ActionTypes.OPEN_SNACK_BAR:
      return { ...state, isSnackBarOpen: true, snackBarMsg: action.payload };
    case ActionTypes.CLOSE_SNACK_BAR:
      return { ...state, isSnackBarOpen: false };
    // alert model
    case ActionTypes.OPEN_ALERT_MODAL:
      return { ...state, alertModalOpen: true };
    case ActionTypes.CLOSE_ALERT_MODAL:
      return { ...state, alertModalOpen: false };
    default:
      return state;
  }
}

export default rootReducer;
