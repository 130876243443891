import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import NEInput from './NEInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    passwordHide: {
      borderRadius: '10px',
      backgroundColor: '#35414A',
      height: '22px',
      marginBottom: '2px',
      minWidth: '44px',
      textTransform: 'capitalize',
      '& span': {
        marginTop: '-1px',
      },
      '&.MuiButton-root:hover': {
        backgroundColor: '#505B63',
      },
      '& .MuiButton-label': {
        height: '12px',
        width: '24px',
        color: '#FFFFFF',
        fontFamily: 'Proxima-Nova-Regular',
        fontSize: '10px',
        lineHeight: '10px',
        textAlign: 'center',
      },
    },
    margin: {
      marginBottom: theme.spacing(3),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '100%',
    },
  }),
);

interface State {
  amount: string;
  weight: string;
  weightRange: string;
  showPassword: boolean;
}

export default function PasswordField({
  password,
  setPassword,
  errorMsg,
  className,
  error,
  helperText,
  label,
}) {
  const classes = useStyles();
  const [values, setValues] = React.useState<State>({
    amount: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === 'password') {
      setPassword(event);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <NEInput
      className={className.root}
      // id="custom-css-standard-input"
      label={label || 'Password'}
      name="password"
      id="outlined-basic"
      variant="outlined"
      type={values.showPassword ? 'text' : 'password'}
      onChange={handleChange('password')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              className={classes.passwordHide}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? 'Hide' : 'Show'}
            </Button>
          </InputAdornment>
        ),
      }}
      error={!!error}
      defaultValue={password}
      helperText={helperText}
    />
  );
}
