/*
 *
 * FitnessGoals actions
 *
 */

import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const getFitness = () => action(ActionTypes.REQUEST_FITNESS_LIST);

export const requestFitnessGoals = data =>
  action(ActionTypes.GET_FITNESS_LIST, data);

export const getFitnessDetail = data => {
  return action(ActionTypes.FETCH_FITNESS_DETAIL_INFO, data);
};

export const fetchFitnessDetail = (id: string) => {
  return action(ActionTypes.FETCH_FITNESS_BY_ID, id);
};
