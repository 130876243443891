import React, { useState } from 'react';
import SearchField from './SearchVideo';
import request from '../shared/lib/request';
import DraggableList from './DraggableList';
import { Divider } from '@material-ui/core';
import { ErrorText } from '../components/Typography';

export interface content {
  id: string;
  title: string;
}

interface getSubTitleFunc {
  (): string;
}

interface SearchCollectionComponentProps {
  contentList: content[];
  onContentListChange: (newValue: content[], action: string) => void;
  id?: any;
  contentType?: any;
  searchUrl?: string;
  subTitle?: string | getSubTitleFunc;
  onChange?: (contentList) => void;
  listItem?: any;
  type?: any;
  errorMessage?: any;
}

export const SearchCarouselWithDnD: React.FC<
  SearchCollectionComponentProps
> = ({
  contentList: defaultValue,
  searchUrl = 'search-concept-collections',
  subTitle = () => {},
  onChange = () => {},
  errorMessage = () => {},
  listItem,
  contentType,
  type,
}) => {
  const [isSearchResult, setSearchContent] = useState([]);
  const [contents, setContents] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [inputRef, setInputRef] = useState(null);
  let timeout;

  const handleSearchInputChange = value => {
    timeout = setTimeout(() => {
      setSearchText('');
      if (inputRef) inputRef.value = '';
    }, 1000);
    if (value) {
      setSearchText(value.title);
      setContents([value, ...contents]);
      onChange([value, ...contents]);
    }
  };

  function searchContents(data) {
    if (contentType === 'instructor') {
      return request({
        url: `/neouadmin/v1/people?keyword=${data}`,
        method: 'GET',
      });
    } else if (contentType === 'videos') {
      return request({
        url: `/neouadmin/v1/search`,
        method: 'POST',
        data: {
          keyword: data,
          videoType: ['class', 'generic'],
        },
      });
    } else if (contentType === 'collections' && type === contentType) {
      return request({
        url: `/neouadmin/v1/${
          searchUrl ? searchUrl : 'search-collections'
        }/${data}`,
        method: 'GET',
        params: {
          isPublished: true,
        },
      });
    } else if (contentType === 'concepts' && type === contentType) {
      return request({
        url: `/neouadmin/v1/search-concepts/${data}`,
        method: 'GET',
        params: {
          isPublished: true,
        },
      });
    } else if (contentType === 'collections' && type) {
      return request({
        url: `/neouadmin/v1/${searchUrl}/${data}?type=${
          type === 'collections' ? 'interest' : type
        }`,
        method: 'GET',
        params: {
          isPublished: true,
        },
      });
    } else {
      return request({
        url: `/neouadmin/v1/${searchUrl}${data !== '' ? `/${data}` : ''}`,
        method: 'GET',
        params: {
          isPublished: true,
        },
      });
    }
  }

  const onSearchChange = async value => {
    try {
      let { result } = await searchContents(value);
      if (contentType == 'videos') {
        setSearchContent(result && result.classes && result.classes.result);
      } else {
        setSearchContent(result);
      }
    } catch (error) {
      setSearchContent([]);
    }
  };

  React.useEffect(() => {
    setContents(defaultValue);
    return () => {
      clearTimeout(timeout);
    };
  }, [defaultValue]);

  return (
    <div style={{ width: '100%' }}>
      <SearchField
        options={isSearchResult || []}
        name="searchContents"
        inputValue={searchText}
        onChange={(event, value) => handleSearchInputChange(value)}
        limitTags={2}
        onInputChange={(event, value) => onSearchChange(value)}
        getOptionLabel={option =>
          option.title ||
          `${option.firstName} ${option.lastName && option.lastName}`
        }
        onSearchTextChange={e => setSearchText(e)}
        ref={inputRef}
        setInputRef={ref => setInputRef(ref)}
        blurOnSelect
        clearOnBlur
      />
      <ErrorText>
        <h2>{errorMessage()}</h2>
      </ErrorText>
      <Divider style={{ backgroundColor: '#999FA4', marginBottom: 8 }} />
      <DraggableList
        list={contents}
        onListChange={(list, updateType) => {
          setContents(list);
          onChange(list);
        }}
        listItem={listItem}
        subTitle={subTitle}
      />
    </div>
  );
};
