/*
 *
 * Legal reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  legalList: {},
  activeTab: 'privacypolicy',
  isListFetchingError: false,
  isLoading: false,
};

function legalReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.REQUEST_LEGAL_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.LEGAL_LIST_SUCCESS:
      return {
        ...state,
        legalList: action.payload,
        isLoading: false,
      };
    case ActionTypes.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case ActionTypes.LEGAL_LIST_FAILED:
      return { ...state, isListFetchingError: true, isLoading: false };
    case ActionTypes.STORE_LEGAL_PAGE:
      return { ...state };
    default:
      return state;
  }
}

export default legalReducer;
