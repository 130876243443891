/*
 *
 * InteractiveCoupon
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectInteractiveCoupon from './selectors';
import reducer from './reducer';
import saga from './saga';
import { useStyles } from './styles';
import CouponListTable from './components/CouponTable';
import { useEffect } from 'react';
import {
  requestPromoList,
  requestPackagesList,
  setSearchKey,
  requestCouponListPagination,
} from './actions';
import Loader from '../../components/Loader';
import { SvgLoaderGif } from '../../components/NeIcons';
import { Button } from '@material-ui/core';

const stateSelector = createStructuredSelector({
  interactiveCoupon: makeSelectInteractiveCoupon(),
});

interface Props {}

function InteractiveCoupon(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'interactiveCoupon', reducer: reducer });
  useInjectSaga({ key: 'interactiveCoupon', saga: saga });
  const classes = useStyles();
  const { interactiveCoupon } = useSelector(stateSelector);
  let {
    isLoading,
    couponList = [],
    packagesList = [],
    isCouponLoading,
    total = 50,
  } = interactiveCoupon;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchKey(''));
    dispatch(requestPromoList());
    dispatch(requestPackagesList());
  }, []);

  function loadMore() {
    if (couponList && couponList.length < total) {
      dispatch(requestCouponListPagination(couponList.length));
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Helmet>
          <title>Interactive Promo Code</title>
          <meta name="description" content="Description of InteractiveCoupon" />
        </Helmet>
        <div className={classes.root}>
          <div>
            <div
              className={classes.headWrap}
              style={{ borderBottom: '1px solid #000000' }}
            >
              <div className={classes.head}>Promo Codes</div>
            </div>

            <div
              className={classes.subHeadWrap}
              style={{ borderBottom: '2px solid #000000' }}
            >
              <div className={classes.subHead}>INTERACTIVE COUPONS</div>
            </div>

            <CouponListTable
              couponList={couponList}
              packagesList={packagesList}
              isLoading={false}
            />

            {isCouponLoading && (
              <div style={{ padding: '10px' }}>
                <SvgLoaderGif />
              </div>
            )}
            {!isCouponLoading &&
              couponList &&
              couponList.length > 0 &&
              total !== couponList.length && (
                <div className={classes.loadMoreBtn}>
                  <Button color="secondary" onClick={() => loadMore()}>
                    Load More Coupons
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InteractiveCoupon;
