/*
 *
 * Root actions
 *
 */

import { action } from 'typesafe-actions';
import { ageGroup, UserInfo } from './types';

import ActionTypes from './constants';

export const requestAgeGroup = () => action(ActionTypes.REQUEST_AGE_GROUP);
export const storeAgeGroups = (ageGroups: ageGroup[]) =>
  action(ActionTypes.FETCH_AGE_GROUP_SUCCESS, ageGroups);

export const fetApiToken = () => action(ActionTypes.FETCH_API_TOKEN);

// Auth modal
export const toggleAuthModalVisibility = () =>
  action(ActionTypes.TOGGLE_AUTH_MODAL);

// Snackbar modal
export const openSnackBar = msg => {
  return action(ActionTypes.OPEN_SNACK_BAR, msg);
};
export const closeSnackBar = () => action(ActionTypes.CLOSE_SNACK_BAR);

//Simple alert modal
export const openAlertModal = () => {
  return action(ActionTypes.OPEN_ALERT_MODAL);
};

export const closeAlertModal = () => action(ActionTypes.CLOSE_ALERT_MODAL);
