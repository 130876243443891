import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tag from '../components/Tag';
import { Label1, H5, Body3, Body2 } from '../styles/Typography';
import { SvgBookmark, SvgBookmarkFilled } from '../components/NeIcons/index';
import { IconButton, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { getTimeAfterMinutes } from '../utils/calender';
import moment from 'moment';

export interface IClassCardProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    classItem: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      borderBottom: '2px solid rgba(34,42,49,1)',
      padding: '12px 0px',
      fontFamily: 'ProximaNova-Semibold',
      '& .MuiButton-textPrimary': {
        minWidth: '75px',
        minHeight: '36px',
        padding: '8px 16px',
        marginBottom: '8px',
        textTransform: 'initial',
      },
      '& .MuiButton-textSecondary': {
        minWidth: '75px',
        minHeight: '36px',
        padding: '8px 16px',
        marginBottom: '8px',
        textTransform: 'initial',
        fontSize: '14px',
      },
    },
    classList: {
      both: 'clear',
      float: 'left',
      paddingLeft: '10px',
      marginTop: '14px',
      cursor: 'pointer',
    },
    classTime: {
      color: 'rgba(255,255,255,1)',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      paddingBottom: '5px',
      margin: '0px',
    },
    classTitle: {
      both: 'clear',
      color: '#fff',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '14px',
      lineHeight: '18px',
      paddingBottom: '6px',
      margin: '0',
    },
    classLevel: {
      color: '#CDD3D8',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '12px',
      lineHeight: '16px',
      paddingBottom: '0',
      margin: '0',
    },
    twoway: {
      color: '#56FFFE',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '12px',
      lineHeight: '15px',
    },
    classBtn: {
      both: 'clear',
      float: 'right',
      paddingRight: '16px',
      marginTop: '14px',
      // minWidth: '120px',
    },
    bookBtn: {
      color: '#080B0D',
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '16px',
      background: 'linear-gradient(135deg, #2ADCFE 0%, #56FFFE 100%)',
      border: '0px',
      borderRadius: '6px',
      padding: '7px 20px',
      marginBottom: '14px',
      cursor: 'pointer',
    },
    credit: {
      padding: '15px',
      margin: 'auto',
    },
    spot: {
      color: '#CDD3D8',
      textAlign: 'center',
      fontSize: '12px',
      lineHeight: '16px',
      fontFamily: 'Proxima-Nova-Regular',
      whiteSpace: 'nowrap',
    },
  }),
);

export interface IClassItemProps {
  classItem: any;
  time_zone: any;
  time: any;
}

export default function ClassItem({
  classItem,
  time_zone,
  time,
}: IClassItemProps) {
  const classes = useStyles();

  return (
    <div className={classes.classItem}>
      <div className={classes.classList}>
        <p className={classes.classTime}>
          {moment(+classItem.startDate).format('LT')} -{' '}
          {getTimeAfterMinutes(classItem.startDate, classItem.duration)}{' '}
          {time_zone}
        </p>
        <p className={classes.classTitle}>
          {classItem.title}
          {Array.isArray(classItem.instructors) &&
            classItem.instructors.length > 1 &&
            ` with ${classItem.instructors[0].firstName} ${classItem.instructors[0].lastName} and ${classItem.instructors[1].firstName} ${classItem.instructors[1].lastName}`}
          {Array.isArray(classItem.instructors) &&
          classItem.instructors.length === 1
            ? ` with ${classItem.instructors[0].firstName} ${classItem.instructors[0].lastName}`
            : ''}
        </p>
        <p className={classes.classLevel}>
          {classItem.genres &&
            classItem.genres.length > 1 &&
            ` ${classItem.genres[0].title}, ${classItem.genres[1].title}`}
          {classItem.genres &&
            classItem.genres.length === 1 &&
            classItem.genres[0].title}{' '}
          {classItem.difficulty && ` • ${classItem.difficulty}`}
        </p>
        {classItem.interactive && classItem.interactive.type === '2-way video' && (
          <p className={classes.twoway}>
            <img
              alt="videoIcon"
              aria-label="recipe"
              src={require('../images/2-way-video.svg')}
              className={classes.videoIcon}
            />{' '}
            {classItem.interactive && classItem.interactive.type}
          </p>
        )}
      </div>
      <div className={classes.classBtn}>
        <Button color="primary">Book now</Button>
        <div className={classes.credit}>
          <p className={classes.spot}>
            {`${classItem.interactive &&
              classItem.interactive.credits} credits`}
          </p>{' '}
          <p className={classes.spot}>
            {' '}
            {classItem.interactive &&
            classItem.interactive.spotsLeft &&
            classItem.interactive.spotsLeft <= 10
              ? `${classItem.interactive.spotsLeft} spots left`
              : null}
          </p>
        </div>
      </div>
    </div>
  );
}
