/*
 *
 * SignOutHome constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/SignOutHome/DEFAULT_ACTION',
  REQUEST_SIGN_OUT_HOME = 'REQUEST_SIGN_OUT_HOME',
  STORE_SIGN_OUT_INFO = 'STORE_SIGN_OUT_INFO',
  EDIT_SIGN_OUT = 'EDIT_SIGN_OUT',
  SET_LOADER = 'SET_LOADER',
}

export default ActionTypes;
