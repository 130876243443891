import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the signOutHome state domain
 */

const selectSignOutHomeDomain = (state: ApplicationRootState) => {
  return state.signOutHome || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by SignOutHome
 */

const makeSelectSignOutHome = () =>
  createSelector(
    selectSignOutHomeDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectSignOutHome;
export { selectSignOutHomeDomain };
