import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import { legalListSuccess, legalListFailed, requestLegal } from './actions';
import { selectLegalDomain } from './selectors';
import { AnyAction } from 'redux';

// Individual exports for testing
export default function* legalSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_LEGAL_LIST, getLegalList);
  yield takeLatest(ActionTypes.STORE_LEGAL_PAGE, storeLegalPage);
}

export function* getLegalList() {
  try {
    let { activeTab } = yield select(selectLegalDomain);
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v2/pages/${activeTab}`,
        method: 'GET',
      }),
    );
    yield put(legalListSuccess(result));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(legalListSuccess({}));
    }
    yield put(legalListFailed());
  }
}

export function* storeLegalPage(action) {
  try {
    const { result } = yield call(() =>
      request({
        url: `/neouadmin/v2/pages`,
        method: 'PUT',
        data: action.payload,
      }),
    );
    yield put(requestLegal());
  } catch (error) {
    yield put(legalListFailed());
  }
}
