import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { PageTitle } from '../../../styles/Typography';
import { SvgBack } from '../../../components/NeIcons';

import { useStyles } from '../../../components/Header';
import { withRouter } from 'react-router-dom';

export interface IHeaderProps {
  history: any;
  title: any;
  headerTitle: string;
}
const InstructorHeader: React.FunctionComponent<IHeaderProps> = ({
  history,
  title,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container alignItems={'center'} className={classes.containerStyle}>
        <Grid item xs={3} md={2}>
          <IconButton className={classes.goBackIcon} onClick={history.goBack}>
            <SvgBack />
          </IconButton>
        </Grid>

        <Grid className={classes.title} item xs={6} md={8}>
          <PageTitle style={{ textTransform: 'capitalize' }}>
            {title && title}
          </PageTitle>
        </Grid>

        <Grid item className={classes.iconWrapper} xs={3} md={2}></Grid>
      </Grid>
    </div>
  );
};

export default withRouter(InstructorHeader);
