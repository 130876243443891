import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
// import other assets
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { push } from 'connected-react-router';
import Chip from '@material-ui/core/Chip';
import iconU from '../../../images/icon-U.svg';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import { genres } from './stub';
import Autocomplete from '../../../components/Autocomplete';
import { useStyles } from '../styles';
import SelectMenu from './SelectMenu';
import {
  requestOtherDetails,
  updatePeopleInfo,
  requestPeopleList,
  addInstructorDetails,
} from '../actions';
import makeSelectPeople from '../selectors';
import GalleryDialog from '../../../components/Gallery/Gallery';
import { openAlertModal, openSnackBar } from '../../Root/actions';
import { SvgProfile } from '../../../components/NeIcons';
import { addPhoto, deletePhoto, addVideo } from '../../../services/s3';
import Loader from '../../../components/Loader';
import {
  cloudfrontUrl,
  BUCKET_NAME,
  BUCKET_REGION,
} from '../../../utils/config';

import MenuItem from '@material-ui/core/MenuItem';
import { StyledSpinnerNext } from 'baseui/spinner';
import request from '../../../shared/lib/request';

import ConfirmDialog from '../../../components/ConfirmDialog';
import { slugify } from '../../../utils/slugify';
import StyledTabs, {
  StyledTab,
} from '../../../components/VideoList/StylesTabs';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#000',
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <img src={iconU} className={classes.iconU} />
      <Typography variant="h6">{children}</Typography>
      <div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dividers: {
    borderTop: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(MuiDialogActions);

const useStylesLocal = makeStyles((theme: Theme) =>
  createStyles({
    tag: {
      background: '#3C4042',
      color: '#fff',
      height: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    deleteIcon: {
      color: '#fff',
      width: '18px',
      opacity: 1,
      '&:hover': {
        color: '#fff',
        opacity: '0.5',
      },
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    dialogContainer: {
      borderRadius: 0,
      minWidth: '1032px',
    },
    styledTab: {
      width: '100%',
    },
    noData: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#060B0F',
      width: '524px',
      padding: '20px',
      textAlign: 'center',
    },
    spanHeader: {
      fontSize: '16px',
      fontFamily: 'Proxima-Nova-Regular',
      color: '#060B0F',
    },
    inputWrap: {
      '& .MuiInputBase-root': {
        '& .MuiInputBase-input': {
          color: '#060B0F !important',
          fontFamily: 'Proxima-Nova-Regular',
          fontSize: '16px',
          fontWeight: 500,
        },
      },
      '& .MuiOutlinedInput-inputMultiline': {
        padding: '0px',
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'Proxima-Nova-Regular',
      },
    },
    imageBox: {
      height: '252px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: '#12161A',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '17px',
    },
    galleryDrop: {
      height: '142px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '180px',
      height: '180px',
      borderRadius: '50%',
      textAlign: 'center',
      color: 'white',
    },
    uploadBtn: {
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      textAlign: 'center',
      backgroundColor: '#3c4042',
      textTransform: 'capitalize',
      borderRadius: '20px',
      padding: '0px 10px',
      cursor: 'pointer',
    },
    flag: {
      marginBottom: '20px',
    },
    subTitle: {
      color: '#060B0F',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    subInTitle: {
      color: '#000000',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      lineHeight: '20px',
    },
    marginTitle: {
      marginTop: '20px',
    },
    inputHidden: {
      display: 'none',
    },
    manageGallery: {
      textTransform: 'capitalize',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: '#12161A',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 22px',
      position: 'relative',
      bottom: '103px',
    },
    galleryDiv: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    hiddenStatus: {
      color: '#000000',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      lineHeight: '20px',
    },
    spanError: {
      color: '#FF1C58',
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    spanErrorSpecialty: {
      color: '#FF1C58',
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
      position: 'relative',
      bottom: '16px',
      left: '2px',
    },
  }),
);

const stateSelector = createStructuredSelector({
  people: makeSelectPeople(),
});

const SLUG_PREFIX = '/person/';

export default function AddDialog({ isOpen, handleClose, title, id }) {
  const classes = useStylesLocal();

  // Local states
  const [isGalleryDialogOpen, openGalleryDialog] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState({});
  const [error, setError] = React.useState({});
  const [img, setImg] = useState('');
  const [imgObj, setImgObj] = useState({});
  const [galleryImg, setGalleryImg] = useState({});
  const [delData, setDelData] = useState({ key: '', isTrue: Boolean });
  const [errImg, setErrImg] = useState(false);
  const [errGallery, setErrGallery] = useState(false);
  const [errSpecialty, setErrSpecialty] = useState(false);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);

  const dispatch = useDispatch();

  const { people } = useSelector(stateSelector);
  const { aSpecialty, aFlag } = people;

  useEffect(() => {
    if (isOpen) {
      dispatch(requestOtherDetails(true));
      // resetting all local state
      setValues({
        permaLink: SLUG_PREFIX,
      });
      setImg('');
      setImgObj([]);
      setGalleryImg([]);
      setError('');
      // setErrImg(false);
      // setErrGallery(false);
      setErrSpecialty(false);
    }
  }, [isOpen]);

  const handleInput = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // save or publish instructor
  const handleFormSubmit = btnVal => {
    const firstNameErr = document.getElementById('firstName').value;
    // let permaLinkErr = document.getElementById('permaLink').value;
    const descriptionErr = document.getElementById('description').value;
    // let imageErr = errImg;
    // let galleryErr = errGallery;
    const specialtyErr = errSpecialty;

    const formError = validateFields({
      firstNameErr,
      // permaLinkErr,
      descriptionErr,
      // imageErr,
      // galleryErr,
      // specialtyErr,
    });

    if (
      !firstNameErr ||
      // !permaLinkErr ||
      !descriptionErr
      // !imageErr ||
      // !galleryErr ||
      // !specialtyErr
    ) {
      setError(formError);
      return null;
    }
    setError('');
    const finalData = {
      ...values,
      firstName: values.firstName,
      lastName: values.lastName || '',
      specialty: values.specialty,
      imageUrl: values.imageUrl || '',
      gallery: values.gallery || [],
      isPublished: btnVal !== 'submit',
      isHidden: false,
      type: 'instructor',
      permaLink:
        values.permaLink ||
        (values.lastName
          ? `${SLUG_PREFIX}${values.firstName}-${values.lastName}`
          : `${SLUG_PREFIX}${values.firstName}`),
      description: values.description || '',
    };
    // dispatch(addInstructorDetails(finalData));
    saveConcept(finalData);
  };

  const saveConcept = async finalData => {
    try {
      await request({
        url: `/neouadmin/v1/people`,
        method: 'POST',
        data: finalData,
      });
      setLoader(false);
      dispatch(openSnackBar('The instructor has been saved successfully!'));
      dispatch(requestPeopleList());
      handleClose();
    } catch ({ data: error }) {
      setLoader(false);
      if (error.error && typeof error.error === 'string') {
        dispatch(openSnackBar(`Failed to add instructor - ${error.error}`));
      } else {
        dispatch('Error in saving the Instructor..');
      }
    }
  };
  const getBaseUrl = async e => {
    setLoader(true);
    const imgUrl = URL.createObjectURL(e.target.files[0]);
    const result = await addPhoto('instructor', e.target.files);

    const image = {
      imageUrl: result.Location.replace(
        `https://${BUCKET_NAME}.s3.${BUCKET_REGION}.amazonaws.com`,
        cloudfrontUrl,
      ),
    };
    // setErrImg(true);
    setImg(image.imageUrl);
    setValues({ ...values, imageUrl: image.imageUrl });
    setLoader(false);
  };

  const galleryData = images => {
    setImgObj(images);
  };

  // for delete functionality -s3
  const isDelete = (delImageKey, fromDelete) => {
    if (fromDelete) {
      const delValues = {
        key: delImageKey,
        isTrue: fromDelete,
      };
      setDelData(delValues);
      const delResult = deletePhoto('instructor', delImageKey);
    }
  };

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setActiveTab(tabIndex);
  };
  const tabConfig = [
    {
      label: 'VOD',
      component: <div></div>,
    },
    {
      label: 'INTERACTIVE',
      component: <div></div>,
    },
  ];

  const handleSaveBtn = () => {
    openGalleryDialog(false);
    // setErrGallery(true);
    setValues({ ...values, gallery: imgObj });
    setGalleryImg(imgObj);
  };

  const generatePermalink = () => {
    const firstName = values.firstName || '';
    const lastName = values.lastName || '';

    setValues({
      ...values,
      permaLink: `${SLUG_PREFIX}${slugify(`${firstName} ${lastName}`)}`,
    });
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
        >
          {title}
          <div></div>
        </DialogTitle>
        <DialogContent dividers>
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the instructor profile."
            onAgree={() => handleClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <StyledTabs
            value={activeTab}
            onChange={onTabChange}
            aria-label="My account tabs"
            centered
          >
            {tabConfig.map((t, index) => (
              <StyledTab
                className={classes.styledTab}
                key={index}
                label={t.label}
              />
            ))}
          </StyledTabs>
          {activeTab === 1 && (
            <Grid
              container
              alignItems="flex-start"
              justify="center"
              style={{ minHeight: '500px', marginTop: '50px' }}
              spacing={2}
            >
              <Grid
                item
                xs={8}
                alignItems="flex-start"
                justify="center"
                container
              >
                <div className={classes.noData}>
                  This instructor does not currently appear on NEOU Interactive.
                </div>
                <Button
                  variant="contained"
                  // onClick={() => enableForInteractive(values)}
                  color="secondary"
                  size="large"
                  disabled={!id}
                >
                  ENABLE FOR NEOU INTERACTIVE
                </Button>
              </Grid>
            </Grid>
          )}
          {activeTab === 0 && (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Box component="span" className={classes.spanHeader}>
                    Profile Image
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box component="span" className={classes.spanHeader}>
                    Profile Information
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div className={classes.imageBox}>
                    <div className={classes.image}>
                      {loader ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            top: '40%',
                          }}
                        >
                          <StyledSpinnerNext
                            style={{ borderTopColor: 'black' }}
                          />
                        </div>
                      ) : (
                        <Avatar
                          alt="img"
                          src={img}
                          className={classes.image}
                        ></Avatar>
                      )}
                    </div>
                    {loader ? (
                      ''
                    ) : (
                      <label className={classes.uploadBtn}>
                        <input
                          accept="image/*"
                          className={classes.inputHidden}
                          id="icon-button-file"
                          type="file"
                          onChange={e => getBaseUrl(e)}
                        />
                        Upload Image
                      </label>
                    )}
                  </div>
                  {/* {error && error.isImage ? (
                <div>
                  <Box component="span" className={classes.spanError}>
                    {error.isImage}
                  </Box>
                </div>
              ) : (
                ''
              )} */}
                </Grid>

                <Grid item xs={4}>
                  <NEInput
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    className={classes.inputWrap}
                    name="firstName"
                    id="firstName"
                    onChange={e => handleInput(e)}
                    onBlur={generatePermalink}
                    error={!!error.firstName || ''}
                    helperText={error.firstName || ''}
                  />
                  <NEInput
                    label="Permalink"
                    variant="outlined"
                    fullWidth
                    className={classes.inputWrap}
                    name="permaLink"
                    id="permaLink"
                    value={values.permaLink}
                    // required
                    onBlur={
                      values &&
                      values.permaLink &&
                      values.permaLink.includes(SLUG_PREFIX)
                        ? null
                        : generatePermalink()
                    }
                    onChange={e => handleInput(e)}
                    // error={!!error.permaLink || ''}
                    // helperText={error.permaLink || ''}
                  />
                  <NEInput
                    label="Description"
                    variant="outlined"
                    fullWidth
                    className={classes.inputWrap}
                    required
                    multiline
                    rows="4"
                    name="description"
                    id="description"
                    onChange={e => handleInput(e)}
                    error={!!error.description || ''}
                    helperText={error.description || ''}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NEInput
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    className={classes.inputWrap}
                    name="lastName"
                    onChange={e => handleInput(e)}
                    onBlur={generatePermalink}
                  />
                  <Autocomplete
                    multiple
                    options={aSpecialty || ''}
                    label="Specialty"
                    name="specialty"
                    id="specialty"
                    onChange={(e, data) => {
                      setValues({
                        ...values,
                        specialty: data.map(item => item.id),
                      });
                      setErrSpecialty(true);
                    }}
                  />
                  {error && error.isNoSpecialty ? (
                    <Box
                      component="span"
                      className={classes.spanErrorSpecialty}
                    >
                      {error.isNoSpecialty}
                    </Box>
                  ) : (
                    ''
                  )}
                </Grid>

                <Grid item xs={3}>
                  <Box component="span" className={classes.spanHeader}>
                    Gallery ( {(galleryImg && galleryImg.length) || '0'} items)
                  </Box>
                  {/* {error && error.isNoGallery ? (
                <div>
                  <Box component="span" className={classes.spanError}>
                    {error.isNoGallery}
                  </Box>
                </div>
              ) : (
                ''
              )} */}

                  <div className={classes.galleryDiv}>
                    <div>
                      <Avatar
                        alt="img"
                        src={
                          (galleryImg &&
                            galleryImg.length > 0 &&
                            galleryImg[0].url) ||
                          ''
                        }
                        className={classes.galleryDrop}
                      ></Avatar>
                    </div>

                    <div
                      className={classes.manageGallery}
                      onClick={() => {
                        setTimeout(() => {
                          openGalleryDialog(true);
                        });
                      }}
                    >
                      Manage Gallery
                    </div>
                  </div>
                  <div className={classes.flag}>
                    <NEInput
                      id="flag"
                      label="Flag"
                      defaultValue="None"
                      select
                      fullWidth
                      variant="outlined"
                      onChange={e => {
                        setValues({ ...values, flag: e.target.value });
                      }}
                    >
                      <MenuItem value="None">None</MenuItem>
                      {aFlag &&
                        aFlag.map(item => (
                          <MenuItem value={item && item.title}>
                            {item && item.title}
                          </MenuItem>
                        ))}
                    </NEInput>
                  </div>
                  <div>
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      label="Admin Tags"
                      popupIcon={<AddIcon />}
                      forcePopupIcon={true}
                      disableClearable={true}
                      name="adminTags"
                      renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            classes={{
                              label: classes.chipLabel,
                              deleteIcon: classes.deleteIcon,
                            }}
                          />
                        ))
                      }
                      onChange={(e, data) => {
                        setValues({ ...values, adminTags: data });
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <NEInput
                    label="Instagram"
                    variant="outlined"
                    fullWidth
                    className={classes.inputWrap}
                    name="instagram"
                    onChange={e => handleInput(e)}
                  />
                  <NEInput
                    label="Facebook"
                    variant="outlined"
                    fullWidth
                    className={classes.inputWrap}
                    name="facebook"
                    onChange={e => handleInput(e)}
                  />
                  <NEInput
                    label="Twitter URL"
                    variant="outlined"
                    fullWidth
                    className={classes.inputWrap}
                    name="twitter"
                    onChange={e => handleInput(e)}
                  />
                  <NEInput
                    label="Web URL"
                    variant="outlined"
                    fullWidth
                    className={classes.inputWrap}
                    name="website"
                    onChange={e => handleInput(e)}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <div>
            {/* <Button
              onClick={() => {
                let btnVal = 'preview';
                handleFormSubmit(btnVal);
              }}
              color="secondary"
              size="large"
            >
              Publish
            </Button> */}
            {activeTab === 0 && (
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  const btnVal = 'submit';
                  handleFormSubmit(btnVal);
                }}
                color="secondary"
              >
                Save & Close
              </Button>
            )}
          </div>
        </DialogActions>

        <GalleryDialog
          isOpen={isGalleryDialogOpen}
          id="1"
          title="MANAGE INSTRUCTOR GALLERY"
          handleSaveBtn={handleSaveBtn}
          isDelete={isDelete}
          type="people"
          handleClose={() => {
            openGalleryDialog(false);
          }}
          galleryData={galleryData}
        ></GalleryDialog>
      </Dialog>
    </div>
  );
}

function validateFields({
  firstNameErr,
  // permaLinkErr,
  descriptionErr,
  // imageErr,
  // galleryErr,
  // specialtyErr,
}) {
  let formError = {};
  if (!firstNameErr) {
    formError = { ...formError, firstName: 'Please enter First Name' };
  }
  // if (!permaLinkErr) {
  //   formError = { ...formError, permaLink: 'Please enter Permalink' };
  // }
  if (!descriptionErr) {
    formError = { ...formError, description: 'Please enter Description' };
  }
  // if (!imageErr) {
  //   formError = { ...formError, isImage: 'Please upload a Profile Image' };
  // }
  // if (!galleryErr) {
  //   formError = { ...formError, isNoGallery: 'Please upload a Gallery Images' };
  // }
  // if (!specialtyErr) {
  //   formError = { ...formError, isNoSpecialty: 'Please Choose Specialty' };
  // }

  return formError;
}
