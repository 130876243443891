import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import request from '../../shared/lib/request';
import { DateTime } from 'luxon';
import ActionTypes from './constants';
import { storePastClasses } from './actions';
import { AnyAction } from 'redux';
import { openSnackBar, closeSnackBar } from '../Root/actions';

// Individual exports for testing
export default function* myAccountSaga() {
  yield takeLatest(ActionTypes.REQUEST_PAST_CLASSES, getPastClasses);
  yield takeLatest(ActionTypes.UPDATE_PASSWORD, updateUserPassword);
}

export function* updateUserPassword(action: AnyAction) {
  const { email, newPassword, oldPassword } = action.payload;
  const data = {
    email: email,
    newPassword: newPassword,
    oldPassword: oldPassword,
  };
  try {
    const { token } = yield call(() =>
      request({
        url: `/neouadmin/v1/user/update-pwd`,
        method: 'POST',
        data: data,
      }),
    );
    yield put(openSnackBar('Password Updated Successfully'));
  } catch (error) {
    if (error.error === 'INVALID_PASSWORD') {
      yield put(openSnackBar('Current Password is invalid'));
    } else yield put(openSnackBar('Update password failed'));
    setTimeout(() => {
      put(closeSnackBar());
    }, 1000);
  }
}

export function* getPastClasses(action: AnyAction) {
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/get-videos`,
        method: 'POST',
        data: {
          instructors: [action.payload],
          interactive: true,
          isCompleted: true,
          isPublished: true,
        },
      }),
    );
    yield put(storePastClasses(result));
  } catch (error) {
    if (error) {
      // yield put(
      //   rootAction.displayToaster('Error in fetching the booked classes.'),
      // );
    }
  }
}
