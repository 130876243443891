import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      display: 'flex',
      flexDirection: 'row',
    },
    progressBox: {
      width: '155px',
      border: '1px solid #000',
      height: '7px',
      position: 'relative',
      marginTop: '7px',
      borderRadius: '3px',
    },
    progressBar: {
      background: '#000',
      color: '#fff',
      height: '5px',
      position: 'absolute',
    },
    percentText: {
      color: '#000',
      fontSize: '15px',
    },
  }),
);

export default function GetProgress() {
  const classes = useStyles();

  return (
    <>
      <div id="myProgress" style={{ display: 'none' }}></div>
      <div className={classes.progress}>
        <div className={classes.progressBox}>
          <div className={classes.progressBar} id="myBar"></div>
        </div>
        <div id="percentText" className={classes.percentText}>
          0%
        </div>
      </div>
    </>
  );
}

export { GetProgress };
