import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { reEncodeVideo } from '../../../Video/actions';
import GroupInteractive from '../GroupInteractive';
import ClassTable from '../../../../components/ClassListTable/ClassTable';

export default function ClassListTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { list, isReviewTab, isPastClass } = props;
  const [editVideoInfo, setEditVideoInfo] = React.useState({
    isOpen: false,
    id: '',
  });
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [payload, setPayload] = React.useState('');

  function reEncode(list) {
    setPayload(list.id);
    showAlertToClose(true);
  }

  return (
    <Paper className={classes.root}>
      {list && list.length > 0 && (
        <ClassTable
          list={list}
          reEncode={reEncode}
          setEditVideoInfo={setEditVideoInfo}
          isPastClass={isPastClass}
        />
      )}
      {editVideoInfo.isOpen && (
        <GroupInteractive
          id={editVideoInfo && editVideoInfo.id}
          title="Edit Group Interactive Class"
          isOpen={editVideoInfo.isOpen}
          handleClose={() => setEditVideoInfo({ isOpen: false, id: '' })}
          isReviewTab={isReviewTab}
        />
      )}

      {isCloseAlertOpen && (
        <ConfirmDialog
          isOpen={isCloseAlertOpen}
          headerText="Are you sure?"
          bodyText="You are about to Re-Encode the Video Again."
          onAgree={() => dispatch(reEncodeVideo(payload))}
          handleClose={() => showAlertToClose(false)}
          onDisagree={() => showAlertToClose(false)}
        ></ConfirmDialog>
      )}
    </Paper>
  );
}
