/*
 *
 * ProgramDetail actions
 *
 */

import { action } from 'typesafe-actions';
import { Program } from './types';

import ActionTypes from './constants';

export const requestProgramDetail = (id: string) =>
  action(ActionTypes.REQUEST_PROGRAM_DETAIL, id);
export const storeProgramDetail = (programDetail: Program) =>
  action(ActionTypes.STORE_PROGRAM_DETAIL, programDetail);
export const setProgramFetchFailed = () =>
  action(ActionTypes.PROGRAM_DETAIL_FAILED);
export const setProgramFetchLoading = () =>
  action(ActionTypes.PROGRAM_DETAIL_LOADING);
export const setProgramFetchUpdating = isUpdating =>
  action(ActionTypes.PROGRAM_DETAIL_UPDATING, isUpdating);

export const requestStartProgram = ({
  id,
  permaLink,
  title,
}: {
  id: string;
  permaLink: string;
  title: string;
}) => action(ActionTypes.START_PROGRAM, { id, permaLink, title });

export const requestStopProgram = ({
  id,
  permaLink,
  title,
}: {
  id: string;
  permaLink: string;
  title: string;
}) => action(ActionTypes.STOP_PROGRAM, { id, permaLink, title });

export const removeClassList = () => {
  return action(ActionTypes.REMOVE_CLASS_LIST);
};
