/*
 *
 * MyClassDetail
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectMyClassDetail from './selectors';
import reducer from './reducer';
import saga from './saga';
import { requestClientList } from './actions';
import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Grid,
} from '@material-ui/core';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';
import ClientList from './ClientList';
import ClassDetailForm from './ClassDetailForm';
import { SvgBack } from '../../components/NeIcons';
import { push } from 'connected-react-router';
import request from '../../shared/lib/request';
import { openSnackBar } from '../Root/actions';
import Loader from '../../components/Loader';
import { StatusText } from '../../components/Typography';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      width: '330px',
      justifyContent: 'space-between',
    },
    stickyHeader: {
      position: 'sticky',
      top: '0px',
      zIndex: 10,
      backgroundColor: '#fff',
    },
    goBackIcon: {
      padding: '0px',
      '& svg': {
        color: 'black',
      },
    },
    styledTab: {
      '& .MuiTab-root': {
        maxWidth: '100%',
        width: '50%',
      },
      '& .MuiTabs-centered': {
        justifyContent: 'space-around',
        borderTop: '1px solid #000000',
      },
    },
    classRoot: {
      width: '100%',
      boxShadow: 'none',
      '& .MuiTableRow-root': {
        'td.MuiTableCell-root.MuiTableCell-body:nth-child(1)': {
          flex: '1',
          width: '34%',
        },
        '& td.MuiTableCell-root.MuiTableCell-body:nth-child(2)': {
          width: '20%',
          minWidth: '224px',
        },
        '& td.MuiTableCell-root.MuiTableCell-body:nth-child(3)': {
          minWidth: '200px',
          width: '20%',
        },
        '& td.MuiTableCell-root.MuiTableCell-body:nth-child(4)': {
          width: '20%',
        },
      },
    },
    root: {
      width: '100%',
      padding: '8px 20px',
      margin: '0 auto',
      maxWidth: '1200px',
    },
    classTitleCont: {
      display: 'flex',
      alignItems: 'center',
      height: '48px',
    },
    classTitleBorder: {
      position: 'sticky',
      top: '0px',
      zIndex: 10,
      backgroundColor: '#fff',
      borderBottom: '1px solid #000',
      margin: '-10px 0 30px 0',
    },
    classTitle: {
      width: '70%',
      textAlign: 'center',
      fontSize: '18px',
      fontFamily: 'ProximaNova-SemiBold, Helvetica, Arial, sans-serif',
      lineHeight: '22px',
    },
    subTitle: {
      textAlign: 'center',
      fontFamily: 'ProximaNova-Medium',
      lineHeight: '22px',
      marginBottom: '22px',
      color: '#A1A6A9',
      fontSize: '15px',
    },
    container: {
      maxHeight: '100%',
    },
    firstColumn: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'inherit',
    },
    firstCoulmContent: {
      textAlign: 'start',
      paddingLeft: '16px',
    },
    conceptTitle: {
      color: '#12161A',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '17px',
      letterSpacing: '0',
      lineHeight: '24px',
    },
    noData: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      padding: '20px',
    },
    text: {
      display: 'flex',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
    },
    genderText: {
      textTransform: 'capitalize',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
    },
    textText: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#909599',
      fontStyle: 'italic',
    },
    blackBg:{
      background:'#12161A',
      color:'#fafafa',
      padding:'4px 10px',
      borderRadius:'8.5px',
      fontFamily: 'ProximaNova-Semibold',
      fontSize: '12px',
      lineHeight:'18px',
    }
  }),
);

const stateSelector = createStructuredSelector({
  myClassDetail: makeSelectMyClassDetail(),
});

interface Props {}

function MyClassDetail(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'myClassDetail', reducer: reducer });
  useInjectSaga({ key: 'myClassDetail', saga: saga });
  const styles = useStyles();
  const { myClassDetail } = useSelector(stateSelector);
  const { clients = [], isLoading } = myClassDetail;
  const [activeTab, setActiveTab] = React.useState(0);
  const [videoDetail, setVideoDetail] = React.useState({});
  const [isPublish, setIsPublished] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  // const classes = classInfo && classInfo.classItem;

  const [classes, setClasses] = React.useState<null | any>({});
  // console.log(classInfo);
  const dispatch = useDispatch();

  const tabConfig = [
    {
      label: 'CLASS DETAIL',
      component: !isLoading && activeTab === 0 && classes && classes !== {} && (
        <ClassDetailForm
          styles={styles}
          videoDetail={videoDetail}
          isLoading={isLoading}
        />
      ),
    },
    {
      label: `CLIENT LIST (${clients !== [] ? clients.length : 0})`,
      component: <ClientList styles={styles} clients={clients} />,
    },
  ];

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const _init = async () => {
    try {
      let videoId = window.location.pathname.replace(
        '/neou/instructor-my-class/',
        '',
      );
      setLoader(true);
      if (videoId) {
        let videoDetail = await _fetchVideoById(videoId);
        setVideoDetail(videoDetail);
        setClasses(videoDetail.result);
        let { isPublished } = videoDetail.result;
        setIsPublished(isPublished);
        if (isPublished) dispatch(requestClientList(videoId));
      } else {
        dispatch(push('/neou/my-classes'));
      }
      setLoader(false);
    } catch (error) {
      dispatch(openSnackBar('Error in fetching the video details'));
      setLoader(false);
    }
  };

  const _fetchVideoById = id =>
    request({
      url: `/neouadmin/v1/videos/${id}`,
      method: 'GET',
    });

  useEffect(() => {
    _init();
  }, []);

  return (
    <div>
      {(isLoading || loader) && <Loader />}
      <Helmet>
        <title>MyClassDetail</title>
        <meta name="description" content="Description of MyClassDetail" />
      </Helmet>

      <div className={styles.root}>
        {classes && (
          <>
            <div
              className={
                classes.isPublished
                  ? styles.stickyHeader
                  : styles.classTitleBorder
              }
            >
              <div
                className={styles.classTitleCont}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '48px',
                }}
              >
                <Grid item xs={3} md={2}>
                  <IconButton
                    className={styles.goBackIcon}
                    onClick={() =>
                      isPublish
                        ? dispatch(push('/neou/my-classes'))
                        : dispatch(push('/neou/instructor-add-classes'))
                    }
                  >
                    <SvgBack />
                  </IconButton>
                </Grid>

                <div className={styles.classTitle}>
                  {classes && classes.title ? `${classes.title}` : ''}{' '}
                  {classes.instructors &&
                    Array.isArray(classes.instructors) &&
                    classes.instructors.length > 1 &&
                    classes.instructors[0].firstName &&
                    ` with ${classes.instructors[0].firstName} ${classes.instructors[0].lastName} and ${classes.instructors[1].firstName} ${classes.instructors[1].lastName}`}
                  {classes.instructors &&
                    Array.isArray(classes.instructors) &&
                    classes.instructors.length === 1 &&
                    classes.instructors[0].firstName &&
                    ` with ${classes.instructors[0].firstName} ${classes.instructors[0].lastName}`}
                </div>
              </div>
              {classes && classes.genres && classes.genres.length > 1 && (
                <div className={styles.subTitle}>
                  {classes.genres[0].title}, {classes.genres[1].title}{' '}
                  {classes && classes.difficulty && `• ${classes.difficulty}`}
                </div>
              )}

              {classes && classes.genres && classes.genres.length === 1 && (
                <div className={styles.subTitle}>
                  {classes.genres[0].title}
                  {classes && classes.difficulty && `• ${classes.difficulty}`}
                </div>
              )}
              {classes && classes.interactive && classes.interactive.isPrivate && (
                <div className={styles.subTitle}>
                  <StatusText>{`PRIVATE INTERACTIVE CLASS`}</StatusText>
                </div>
              )}
              {classes && classes.isPublished && (
                <StyledTabs
                  value={activeTab}
                  onChange={onTabChange}
                  aria-label="My account tabs"
                  centered
                  className={styles.styledTab}
                >
                  {tabConfig.map((t, index) => (
                    <StyledTab key={index} label={t.label} />
                  ))}
                </StyledTabs>
              )}
            </div>
          </>
        )}

        {!isLoading && activeTab === 0 && classes && classes !== {} ? (
          <ClassDetailForm
            styles={styles}
            isLoading={isLoading}
            videoDetail={videoDetail}
            clients={clients}
          />
        ) : (
          <ClientList styles={styles} clients={clients} />
        )}
      </div>
    </div>
  );
}

export default MyClassDetail;
