import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  SvgIconPlayArrow,
  SvgIconEdit,
  SvgIconAlert,
} from '../../../components/NeIcons';
import moment from 'moment';
import { useStyles } from './styles';
import Loader from '../../../components/Loader';
import ProfileAvatar from '../../../components/CommonFilter/ProfileAvatar';
import EditDialog from './EditPeopleDialog';
import EditPersonDialog from './EditPersonDialog';
import { IconButton } from '@material-ui/core';
import { requestPeopleList } from '../actions';
import { useDispatch } from 'react-redux';

export default function PeopleListTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditDialogOpen, openEditDialog] = React.useState(false);
  const [isEditPersonDialogOpen, openEditPersonDialog] = React.useState(false);
  const [id, setId] = React.useState('');
  let { list, isLoading, isInteractiveFilterOn } = props;
  const getDate = list => {
    if (list && list.isPublished) {
      return (
        <div>
          {`Updated ${moment(list && list.updatedAt).format(`L`)}`} <br />{' '}
          Published {`${moment(list && list.publishedDate).format(`L`)}`}
        </div>
      );
    } else {
      return `Saved ${moment(list && list.updatedAt).format(`L`)}`;
    }
  };

  const renderCTA = list => {
    let isPublished = false;
    let isHidden = false;
    let isError = false;
    if (isInteractiveFilterOn) {
      isPublished = list.interactive && list.interactive.isPublished;
      isHidden = list.interactive && list.interactive.isHidden;
      isError = list.interactive && list.interactive.isError;
    } else {
      isPublished = list.isPublished;
      isHidden = list.isHidden;
      isError = list.isError;
    }
    if (isHidden) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'HIDDEN'}
        </div>
      );
    } else if (isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'PUBLISHED'}
        </div>
      );
    } else if (!isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.draft}`}>
          {'DRAFT'}
        </div>
      );
    }
  };
  const specialty = list => {
    if (list.specialty.length > 0) {
      let specialty = list.specialty.slice(0, 2);
      let result = specialty.map(item => {
        return item.title;
      });
      return result.join(', ');
    }
    return [];
  };

  const interactiveSpecialty = list => {
    if (
      list.interactive &&
      list.interactive.specialty &&
      list.interactive.specialty.length > 0
    ) {
      let specialty =
        list.interactive && list.interactive.specialty.slice(0, 2);
      let result = specialty.map(item => {
        return item.title;
      });
      return result.join(', ');
    }
    return [];
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead></TableHead>
          <TableBody>
            {list &&
              list.map(list => {
                return (
                  <TableRow key={list.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell align="left">
                      <div className={classes.firstColumn}>
                        <div className={classes.avatar}>
                          {!isInteractiveFilterOn ? (
                            <ProfileAvatar
                              name={`${list.firstName} ${list.lastName || ''}`}
                              uri={list.imageUrl}
                            />
                          ) : (
                            <ProfileAvatar
                              name={`${(list.interactive &&
                                list.interactive.firstName) ||
                                ''} ${(list.interactive &&
                                list.interactive.lastName) ||
                                ''}`}
                              uri={
                                list.interactive && list.interactive.imageUrl
                              }
                            />
                          )}
                        </div>
                        <div className={classes.firstCoulmContent}>
                          <div className={classes.conceptTitle}>
                            {!isInteractiveFilterOn && (
                              <>{`${list.firstName} ${list.lastName || ''}`}</>
                            )}
                            {isInteractiveFilterOn &&
                              list.interactive &&
                              list.interactive.firstName && (
                                <>
                                  {`${list.interactive.firstName} ${
                                    list.interactive &&
                                    list.interactive.lastName
                                      ? list.interactive.lastName
                                      : ''
                                  }`}
                                </>
                              )}
                          </div>
                          <div className={classes.text}>
                            {!isInteractiveFilterOn && (
                              <span>{specialty(list)}</span>
                            )}
                            {isInteractiveFilterOn &&
                              list.interactive &&
                              list.interactive.specialty && (
                                <span>{interactiveSpecialty(list)}</span>
                              )}
                          </div>
                          <div className={classes.type}>{list.type}</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={classes.text}>
                        {!isInteractiveFilterOn && getDate(list)}
                        {isInteractiveFilterOn &&
                          getDate(list && list.interactive)}
                      </div>
                    </TableCell>
                    <TableCell align="center">{renderCTA(list)}</TableCell>
                    {list.type === 'person' ? (
                      <TableCell align="right">
                        {list.isError ? (
                          <div>
                            <SvgIconAlert />
                          </div>
                        ) : (
                          <div>
                            <IconButton
                              onClick={() => {
                                setId(list.id);
                                setTimeout(() => {
                                  openEditPersonDialog(true);
                                });
                              }}
                              classes={{ root: classes.editIcon }}
                            >
                              <SvgIconEdit />
                            </IconButton>
                          </div>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell align="right">
                        {list.isError ? (
                          <div>
                            <SvgIconAlert />
                          </div>
                        ) : (
                          <div>
                            <IconButton
                              onClick={() => {
                                setId(list.id);
                                setTimeout(() => {
                                  openEditDialog(true);
                                });
                              }}
                              classes={{ root: classes.editIcon }}
                            >
                              <SvgIconEdit />
                            </IconButton>
                          </div>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {isEditDialogOpen && (
        <EditDialog
          isOpen={isEditDialogOpen}
          id={id}
          title="EDIT INSTRUCTOR PROFILE"
          handleClose={() => {
            openEditDialog(false);
          }}
          isInteractiveFilterOn={isInteractiveFilterOn}
        ></EditDialog>
      )}
      {isEditPersonDialogOpen && (
        <EditPersonDialog
          isOpen={isEditPersonDialogOpen}
          id={id}
          title="EDIT PERSON PROFILE"
          handleClose={() => {
            openEditPersonDialog(false);
          }}
        ></EditPersonDialog>
      )}
    </Paper>
  );
}
