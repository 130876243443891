import * as React from 'react';
import { withStyles, Button, Typography } from '@material-ui/core';
import useStyles from './style';
import moment from 'moment';

export interface DayCalenderProps {
  dayText: string;
  selected: boolean;
  onClick: () => void;
}

const DayCalenderBtn = withStyles(theme => ({
  root: {
    borderRadius: '4px',
    color: 'rgba(53,65,74,0.5)',
    transition: 'none',
    height: 57,
    flexWrap: 'wrap',

    '&:not(:last-child)': {
      marginRight: 8,
      [theme.breakpoints.down('sm')]: {
        marginRight: '1px',
      },
    },
    '& .MuiButton-label': {
      [theme.breakpoints.only('lg')]: {
        width: '105px',
      },
      [theme.breakpoints.up('xl')]: {
        width: '159px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: '81px',
      minWidth: '42px',
    },
    [theme.breakpoints.only('md')]: {
      height: '80px',
      width: '96px',
      borderRadius: '6px',
    },
    [theme.breakpoints.up('md')]: {
      height: '80px',
    },
    [theme.breakpoints.only('xl')]: {
      height: '96px',
      // width: '159.41px',
      borderRadius: '7.2px',
    },
    '&:hover': {
      // background: '#1C2429',
      '& .MuiTypography-root': {
        //color: '#fff!important',
      },
    },
    '&$focusVisible': {
      background: '#FFF',
    },
    '&:active': {
      background: '#000',
    },
    '&$disabled': {
      color: '#12161A',
      opacity: '0.3',
    },
  },
  label: {
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'column',
    color: '#12161A',
  },
}))(Button);

const CalenderBtn = (props: DayCalenderProps) => {
  const classes = useStyles();
  const { dayText = 'Day', selected } = props;

  return (
    <DayCalenderBtn
      style={{
        background: selected ? '#1C2429' : '#FFFFFF',
      }}
      className={`${selected ? classes.selectedBtn : classes.unSelect}`}
      onClick={props.onClick}
    >
      {window.innerWidth <= 768 ? (
        <Typography className={`${selected ? classes.selectedText : ''}`}>
          <span
            className={classes.monthStyle}
            style={{
              fontFamily: selected
                ? 'ProximaNova-Bold, Helvetica, Arial, sans-serif'
                : 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
            }}
          >
            {moment(dayText).format(`MMM`)}
          </span>
          <span className={classes.DateStyle}>
            {moment(dayText).format(`D`)}
          </span>
          {selected ? (
            <span className={classes.line} />
          ) : (
            <span className={classes.lineWhite} />
          )}

          <span
            className={classes.DayStyle}
            style={{
              fontFamily: selected
                ? 'ProximaNova-Bold, Helvetica, Arial, sans-serif'
                : 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
            }}
          >
            {moment(dayText).format(`ddd`)}
          </span>
        </Typography>
      ) : (
        <Typography className={`${selected ? classes.selectedText : ''}`}>
          <span
            className={classes.monthStyle}
            style={{
              fontFamily: selected
                ? 'ProximaNova-Bold, Helvetica, Arial, sans-serif'
                : 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
            }}
          >
            {moment(dayText).format(`MMM`)}
          </span>
          <span className={classes.DateStyle}>
            {moment(dayText).format(`D`)}
          </span>
          {selected ? (
            <span className={classes.line} />
          ) : (
            <span className={classes.lineWhite} />
          )}
          <span
            className={classes.DayStyle}
            style={{
              fontFamily: selected
                ? 'ProximaNova-Bold, Helvetica, Arial, sans-serif'
                : 'ProximaNova-Semibold, Helvetica, Arial, sans-serif',
            }}
          >
            {moment(dayText).format(`ddd`)}
          </span>
        </Typography>
      )}
    </DayCalenderBtn>
  );
};

export default CalenderBtn;
