import { makeStyles } from '@material-ui/core';
import * as React from 'react';

function SvgIconPlans(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <path
        d="M19.41 9.58l-9-9C10.05.22 9.55 0 9 0H2C.9 0 0 .9 0 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM11 18.01L2 9V2h7v-.01l9 9-7 7.02zM4.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

const useStyles = makeStyles({
  root: {
    borderRadius: "50%",
    height: "18px",
    width: "18px",
    marginRight: "16px",
    position: "relative",
    border: "1px solid",
    color: "inherit",
    "& svg":{
      position: "absolute",
      top: 0,
      padding: "3px",
      transform: "rotate(45deg)"
    }
  },
});

export function SvgAddClass(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <svg width="100%" height="100%" viewBox="0 0 16 15">
        <ellipse
          fill="#12161A"
          fillRule="nonzero"
          cx={8.073}
          cy={8.768}
          rx={8.073}
          ry={8.053}
        />
        <path d="M2.225 2.225l11.55 11.55m-11.55 0l11.55-11.55" stroke="currentColor" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
    </div>
  );
}

export default SvgIconPlans;
