import AWS from 'aws-sdk';
import { AWS_ACCESS_KEY_ID } from '../utils/config';
import { BUCKET_NAME } from '../utils/config';
import { BUCKET_REGION } from '../utils/config';
import { AWS_SECRET_KEY } from '../utils/config';
import { openSnackBar } from '../containers/Root/actions';
import { Buffer } from 'buffer';

var albumBucketName = BUCKET_NAME;
var bucketRegion = BUCKET_REGION;
var IdentityPoolId = 'IDENTITY_POOL_ID';

AWS.config.update({
  region: bucketRegion,
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_KEY,
});

var s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: albumBucketName },
});

function listAlbums() {
  s3.listObjects({ Delimiter: '/' }, function(err, data) {
    if (err) {
      return alert('There was an error listing your albums: ' + err.message);
    } else {
      var albums = data.CommonPrefixes.map(function(commonPrefix) {
        var prefix = commonPrefix.Prefix;
        var albumName = decodeURIComponent(prefix.replace('/', ''));
        return getHtml([
          '<li>',
          '<span onclick="deleteAlbum(\'' + albumName + '\')">X</span>',
          '<span onclick="viewAlbum(\'' + albumName + '\')">',
          albumName,
          '</span>',
          '</li>',
        ]);
      });
      var message = albums.length
        ? getHtml([
            '<p>Click on an album name to view it.</p>',
            '<p>Click on the X to delete the album.</p>',
          ])
        : '<p>You do not have any albums. Please Create album.';
      var htmlTemplate = [
        '<h2>Albums</h2>',
        message,
        '<ul>',
        getHtml(albums),
        '</ul>',
        '<button onclick="createAlbum(prompt(\'Enter Album Name:\'))">',
        'Create New Album',
        '</button>',
      ];
      document.getElementById('app').innerHTML = getHtml(htmlTemplate);
    }
  });
}

function createAlbum(albumName) {
  albumName = albumName.trim();
  if (!albumName) {
    return alert('Album names must contain at least one non-space character.');
  }
  if (albumName.indexOf('/') !== -1) {
    return alert('Album names cannot contain slashes.');
  }
  var albumKey = encodeURIComponent(albumName) + '/';
  s3.headObject({ Key: albumKey }, function(err, data) {
    if (!err) {
      return alert('Album already exists.');
    }
    if (err.code !== 'NotFound') {
      return alert('There was an error creating your album: ' + err.message);
    }
    s3.putObject({ Key: albumKey }, function(err, data) {
      if (err) {
        return alert('There was an error creating your album: ' + err.message);
      }
      alert('Successfully created album.');
      viewAlbum(albumName);
    });
  });
}

function viewAlbum(albumName) {
  var albumPhotosKey = encodeURIComponent(albumName) + '//';
  s3.listObjects({ Prefix: albumPhotosKey }, function(err, data) {
    if (err) {
      return alert('There was an error viewing your album: ' + err.message);
    }
    // 'this' references the AWS.Response instance that represents the response
    var href = this.request.httpRequest.endpoint.href;
    var bucketUrl = href + albumBucketName + '/';

    var photos = data.Contents.map(function(photo) {
      var photoKey = photo.Key;
      var photoUrl = bucketUrl + encodeURIComponent(photoKey);
      return getHtml([
        '<span>',
        '<div>',
        '<img style="width:128px;height:128px;" src="' + photoUrl + '"/>',
        '</div>',
        '<div>',
        '<span onclick="deletePhoto(\'' +
          albumName +
          "','" +
          photoKey +
          '\')">',
        'X',
        '</span>',
        '<span>',
        photoKey.replace(albumPhotosKey, ''),
        '</span>',
        '</div>',
        '</span>',
      ]);
    });
    var message = photos.length
      ? '<p>Click on the X to delete the photo</p>'
      : '<p>You do not have any photos in this album. Please add photos.</p>';
    var htmlTemplate = [
      '<h2>',
      'Album: ' + albumName,
      '</h2>',
      message,
      '<div>',
      getHtml(photos),
      '</div>',
      '<input id="photoupload" type="file" accept="image/*">',
      '<button id="addphoto" onclick="addPhoto(\'' + albumName + '\')">',
      'Add Photo',
      '</button>',
      '<button onclick="listAlbums()">',
      'Back To Albums',
      '</button>',
    ];
    document.getElementById('app').innerHTML = getHtml(htmlTemplate);
  });
}

function addPhoto(albumName, files) {
  if (!files.length) {
    return null;
  }
  var file = files[0];
  var ftype = files[0].type;
  var fileName = file.name.replace(/\s/g, '_');
  fileName = fileName.replace(/[&\/\\#,+()$~%'":*?<>{}!@^|"]/g, '');
  var newFile = new File([file], fileName, { type: ftype || 'image/jpeg' });
  var albumPhotosKey = encodeURIComponent(albumName) + '/';

  var photoKey = albumPhotosKey + fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: newFile,
      ACL: 'public-read',
    },
  });
  var promise = upload.promise();

  return promise;
}

/** function multipart(albumName, files) {
  if (!files.length) {
    return null;
  }
  var file = files[0];
  var fileName = file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + '/';
  // var thumbnailImg = thumbnailImg;

  var photoKey = albumPhotosKey + fileName;

  s3.createMultipartUpload({ Key: photoKey, Bucket: albumBucketName , ACL: 'public-read', }, function(err, data) {
    if (err) {
      return null;
      // return alert('There was an error deleting your photo: ', err.message);
    } 
    var uploadId = data.UploadId;
    console.log(uploadId);
  });
}

function getUrl(files, uploadId:string , partNumber:Number, albumName ) {
  if (!files.length) {
    return null;
  }
  var file = files[0];
  //var file = files[];
  var fileName = files.name;
  var albumPhotosKey = encodeURIComponent(albumName) + '/';
  // var thumbnailImg = thumbnailImg;

  var photoKey = albumPhotosKey + fileName;

  let params = {
    Bucket: albumBucketName, 
    key: photoKey, 
    partNumber: partNumber,
    UploadId: uploadId,
  }
  
  s3.getSignedUrl({'uploadPart':String , params } , function(err, preSignedUrl) {
    if (err) {
      return null;
    }
    let presignedUrl = preSignedUrl.data
  });
} 
*/
function addVideo(albumName, files, thumbnailImg) {
  if (!files.length) {
    return null;
  }
  var file = files[0];
  var ftype = files[0].type;
  var fileName = file.name.replace(/\s/g, '_');
  fileName = fileName.replace(/[&\/\\#,+()$~%'":*?<>{}!@^|"]/g, '');
  var newFile = new File([file], fileName, { type: ftype || 'video/mp4' });
  var albumPhotosKey = encodeURIComponent(albumName) + '/';
  // var thumbnailImg = thumbnailImg;

  var photoKey = albumPhotosKey + fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: newFile,
      ACL: 'public-read',
    },
    tags: [{ Key: photoKey, Value: thumbnailImg }],
  });
  var promise = upload.promise();

  return promise;
}

function progress(progress) {
  var progressDiv = document.getElementById("myProgress");
  progressDiv.style.display="block";
  var progressBar = document.getElementById("myBar");
    let progressPercentage = Math.round(progress.loaded / progress.total * 100);
    progressBar.style.width = progressPercentage + "%";
   let percentText = document.getElementById("percentText").innerHTML = progressPercentage + "%";
  }

function multipart(albumName, files, thumbnailImg) {
  if (!files.length) {
    return null;
  }
  var file = files[0];
  var ftype = files[0].type;
  var fileName = file.name;
  fileName = fileName.replace(/\s+/g, '_');
  fileName = fileName.replace(/[&\/\\#,+()$~%'":*?<>{}!@^|"]/g, '');
  var newFile = new File([file], fileName, { type: ftype || 'video/mp4' });
  console.log(newFile);
  var albumPhotosKey = encodeURIComponent(albumName) + '/';
  // var thumbnailImg = thumbnailImg;

  var photoKey = albumPhotosKey + fileName;
  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    partSize: 10 * 1024 * 1024,
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      ContentType: file.type,
      Body: newFile,
      ACL: 'public-read',
    },
    tags: [{ Key: photoKey, Value: thumbnailImg }],
  }).on('httpUploadProgress', progress)
  var promise = upload.promise();
  return promise;

  // upload.send(function(err, data) {
  //  console.log(err, data);
  //});
  /**  var para = {
    Bucket: albumBucketName,
    Key: photoKey,
    Location: promise.Location,
    ETag: promise.ETag,
  }
  promise.then(function(data){
    params: para
  }, function(err) { console.log(err) }); */
}

function deletePhoto(albumName, photoKey) {
  s3.deleteObject({ Key: photoKey }, function(err, data) {
    if (err) {
      return null;
      // return alert('There was an error deleting your photo: ', err.message);
    }

    openSnackBar('Image successfully deleted');
  });
}

function deleteAlbum(albumName) {
  var albumKey = encodeURIComponent(albumName) + '/';
  s3.listObjects({ Prefix: albumKey }, function(err, data) {
    if (err) {
      return alert('There was an error deleting your album: ', err.message);
    }
    var objects = data.Contents.map(function(object) {
      return { Key: object.Key };
    });
    s3.deleteObjects(
      {
        Delete: { Objects: objects, Quiet: true },
      },
      function(err, data) {
        if (err) {
          return alert('There was an error deleting your album: ', err.message);
        }
        alert('Successfully deleted album.');
        listAlbums();
      },
    );
  });
}

export { multipart, addPhoto, deletePhoto, addVideo };
