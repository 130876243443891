import * as React from 'react';
import { makeStyles, ButtonBase } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import clsx from 'clsx';
import styled from 'styled-components';

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';

import styles from './ProtectedStyle';
import SideNav from '../components/SideNav';
import Footer from '../components/Footer';
import MainModal from '../components/MainModal';

import { SvgMenu, SvgClose } from '../components/NeIcons';
import logo from '../images/neou@2x.png';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';

import { useInjectReducer } from '../utils/injectReducer';
import reducer from '../containers/Authentication/reducer';
import saga from '../containers/Authentication/saga';
import { useInjectSaga } from '../utils/injectSaga';
import makeSelectAuthentication from '../containers/Authentication/selectors';
import { requestUserInfo } from '../containers/Authentication/action';

import sideMenuRoutes from './routes';
import { createStructuredSelector } from 'reselect';
import makeSelectRoot from '../containers/Root/selectors';
import { toggleAuthModalVisibility } from '../containers/Root/actions';
import ProtectedRoute from '../components/Protected';
import isEmpty from 'lodash/isEmpty';
const useStyles = makeStyles(styles);

import MyAccount from '../components/Account';
interface IProtectedProps {}
let ps;
const stateSelector = createStructuredSelector({
  root: makeSelectRoot(),
});
const stateSelector1 = createStructuredSelector({
  authentication: makeSelectAuthentication(),
});
export function Protected({ ...rest }: IProtectedProps) {
  const [state, setstate] = React.useState();
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [color, setColor] = React.useState('blue');
  const [fixedClasses, setFixedClasses] = React.useState('dropdown show');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [firstRoute, setFirstRoute] = React.useState('/signin');
  // const [isAuthModalOpen, openAuthModal] = React.useState(false);
  const { root } = useSelector(stateSelector);
  const { isAuthModalOpen, userInfo2 } = root;
  const isAuthenticated = true;
  const P_URL = window.location.href ? window.location.href : '';
  const dispatch = useDispatch();
  const {
    authentication,
  }: { authentication: AuthenticationState } = useSelector(stateSelector1);

  const { userInfo } = authentication;

  const permissions = userInfo.permissions;
  //remove this once my-classes added to permissions
  // if (userInfo.role === 'instructor') {
  //   permissions = ['my-classes'];
  // } else {
  //   permissions = userInfo.permissions;
  // }

  let Sidemenus = [];
  if (!isEmpty(permissions)) {
    // permistion array
    const filteredArray = sideMenuRoutes.filter(el => {
      return permissions.indexOf(el.name) >= 0;
    });

    // getadmin
    const admins = sideMenuRoutes.filter(el => el.name === 'admins');
    // merge admins
    Sidemenus = filteredArray.concat(admins);
    // if (userInfo.role === 'instructor') {
    //   const myclass1 = sideMenuRoutes.filter(el => el.name === 'class-detail');
    //   Sidemenus = Sidemenus.concat(myclass1);
    // }
    //getinstructor
    const instructor = sideMenuRoutes.filter(el => el.name === 'my-account');
    //merge instructor
    Sidemenus = Sidemenus.concat(instructor);
  }

  const toggleAuthModal = () => {
    dispatch(toggleAuthModalVisibility());
  };

  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === 'dropdown') {
      setFixedClasses('dropdown show');
    } else {
      setFixedClasses('dropdown');
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== '/admin/maps';
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);

  const NoMatch = ({ location }) => (
    <div className={classes.noMatch}>
      <h3>You have no permissions for this page</h3>
    </div>
  );
  const getRedirectRoute = () => {
    const cookies = new Cookies();
    const isAuthenticated = !!cookies.get('adminToken');
    const firstArr = permissions[0];
    let urlPage;
    if (isAuthenticated) {
      if (!isEmpty(permissions)) {
        if (window.location.pathname.includes('/preview/')) {
          return;
        } else {
          switch (firstArr) {
            case 'configure':
              urlPage = `/neou/attributes`;
              break;
            case 'library':
              urlPage = `/neou/videos`;
              break;
            case 'live-participants-page':
              urlPage = `/neou/live-class`;
              break;
            case 'interactive':
              urlPage = `/neou/published-classes`;
              break;
            case 'curate':
              urlPage = `/neou/home-page-tray`;
              break;
            case 'members':
              urlPage = `/neou/members`;
              break;
            case 'plans':
              urlPage = `/neou/subscription`;
              break;
            case 'my-classes':
              urlPage = `/neou/my-classes`;
              break;
            case 'my-account':
              urlPage = `/neou/my-account`;
              break;
            case 'add-classes':
              urlPage = `/neou/instructor-add-classes`;
              break;
            default:
              urlPage = `/neou/subscription`;
              break;
          }
        }
      }
      if (!isEmpty(Sidemenus)) {
        return Sidemenus.length > 0 && urlPage;
      }
    } else {
      return '/signin';
    }
  };
  return (
    <div className={classes.wrapper}>
      { !P_URL.includes("signin") &&
      <SideNav
        routes={Sidemenus.filter(el => el.isHidden !== true)}
        isLoggedIn={isAuthenticated}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        openAuthModal={toggleAuthModal}
        {...rest}
      />}
      <div
        className={clsx(classes.mainPanel, {
          [classes.contentShift]: mobileOpen,
        })}
        ref={mainPanel}
      >
        <Hidden lgUp implementation="css">
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: mobileOpen,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                className={clsx(classes.menuButton)}
              >
                {mobileOpen ? <SvgClose /> : <SvgMenu />}
              </IconButton>

              <LogoWrapper>
                <LogoImage src={logo} />
              </LogoWrapper>
              <ButtonBase
                onClick={() => !isAuthenticated && toggleAuthModal()}
              />
            </Toolbar>
          </AppBar>
        </Hidden>
        <div className={classes.main}>
          <Switch>
            {Sidemenus.map((route, index) => {
              if (route.component) {
                // if (index === 0) {
                //   setFirstRoute(route.path);
                // }
                return (
                  <ProtectedRoute
                    key={index}
                    path={route.path}
                    component={route.component}
                  />
                );
              } else if (route.subMenu) {
                return route.subMenu.map((menu, sIndex) => (
                  <ProtectedRoute
                    key={sIndex}
                    path={menu.path}
                    component={menu.component}
                  />
                ));
              }
            })}
            <Route path="/neou/my-account" component={MyAccount} />
            <Route
              render={() => (
                <Redirect
                  to={{
                    pathname: getRedirectRoute(),
                  }}
                />
              )}
            ></Route>
            {/* <Route component={NoMatch} /> */}
          </Switch>
        </div>
      </div>
    </div>
  );
}

const LogoImage = styled.img`
  max-width: 80px;
`;

const Main = styled.div`
  width: 100%;
  flex: 1;
`;

const LogoWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;
