import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the Concept state domain
 */

const selectConceptDomain = (state: ApplicationRootState) => {
  return state.concept || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Concept
 */

const makeSelectConcept = () =>
  createSelector(
    selectConceptDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectConcept;
export { selectConceptDomain };
