/*
 *
 * FitnessGoals constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/FitnessGoals/DEFAULT_ACTION',
  REQUEST_FITNESS_LIST = 'REQUEST_FITNESS_LIST',
  GET_FITNESS_LIST = 'GET_FITNESS_LIST',
  FETCH_FITNESS_BY_ID = 'FETCH_FITNESS_BY_ID',
  FETCH_FITNESS_DETAIL_INFO = 'FETCH_FITNESS_DETAIL_INFO',
}

export default ActionTypes;
