import React from 'react';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectInteractivePublishedClasses from './selectors';
import reducer from './reducer';
import saga from './saga';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import ClassDetail from './components/ClassDetail';
import request from '../../shared/lib/request';
import { openSnackBar } from '../Root/actions';
import { storeClassDetailPreview } from './actions';

const stateSelector = createStructuredSelector({
    interactiveClass: makeSelectInteractivePublishedClasses(),
  });

const ClassPreview = (props) => {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'interactiveClass', reducer: reducer });
  useInjectSaga({ key: 'interactiveClass', saga: saga });
  
  const { interactiveClass } = useSelector(stateSelector);

  const { classDetailData } = interactiveClass;
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function getClassDetail(id) {
      try {
        let { result } = await request({
          url: `/neouadmin/v1/videos/${id}`,
          method: 'GET',
        });
        dispatch(storeClassDetailPreview(result));
      } catch (error) {
        dispatch(openSnackBar('Error in fetching the video details'));
      }
    };
    getClassDetail(window.location.pathname.replace('/preview/group-class/', ''));
  },[])

  return (
    <>
      {classDetailData && (
      <ClassDetail 
        classDetail={{data: classDetailData}} 
        readonly={true} 
        handleCancelBooking={() => {}} 
        handleClassBook={() => {}} 
      />)}
    </>
  );
}

export default ClassPreview;