import * as React from 'react';
import { createStyles, withStyles, MenuItem } from '@material-ui/core';
import { NEInputLight } from '../NEInput';
import { Field, ErrorMessage } from 'formik';

interface FormikSelectProps {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  helperText?: string;
  items: FormikSelectItems[];
}

interface FormikSelectItems {
  label: string;
  value: string;
}

const styles = () =>
  createStyles({
    wrapper: {
      width: '100%',
    },
  });

const FormikSelect: React.FC<FormikSelectProps> = ({
  classes,
  label,
  name,
  placeholder,
  items = [],
  onBlur,
  handleChange,
  disabled = false,
}) => {
  return (
    <div className={classes.wrapper}>
      <Field name={name}>
        {({ field, form, meta }) => (
          <NEInputLight
            id={name}
            label={label}
            select
            name={name}
            disabled={disabled}
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            helperText={meta.touched && meta.error}
            error={Boolean(meta.touched && meta.error)}
            inputProps={{
              ...field,
            }}
            onBlur={onBlur}
            onChange={handleChange}
          >
            {items.map((item, index) => (
              <MenuItem value={item.value} key={`${item.value}${index}`}>
                {item.label}
              </MenuItem>
            ))}
          </NEInputLight>
        )}
      </Field>
    </div>
  );
};

export default withStyles(styles)(FormikSelect);
