import React from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import { useDispatch } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import { string, array, object, number } from 'yup';
import FormikField from '../../../components/Formik/FormikField';
import FormikSelect from '../../../components/Formik/FormikSelect';
import {
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import FormikAutocomplete from '../../../components/Formik/FormikAutocomplete';
import FormikGallery from '../../../components/Formik/ManageGallery';
import FormikImageUploader from '../../../components/Formik/FormikImageUploader';
import FormikSearchVideo from '../../../components/Formik/FormikSearchVideo';
import request from '../../../shared/lib/request';
import { openSnackBar } from '../../Root/actions';
import ThreeDotMenu from '../../../components/ThreeDotMenu';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { requestCollectionList } from '../actions';
import {
  StatusLabel,
  StatusText,
  HistoryText,
  LabelText,
  ErrorText,
} from '../../../components/Typography';
import Loader from '../../../components/Loader';
import moment from 'moment';
import CollectionWeek from './CollectionWeeks';
import { slugify } from '../../../utils/slugify';
import { validatePermaLink } from '../../../utils/commonFunctions';
import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'checkPermalink', function(errorMessage) {
  return this.test(`test-card-type`, errorMessage, function(value) {
    let check = /^\/(programs|interests|challenges)\//;
    return validatePermaLink(value, check);
  });
});

const CollectionSchema = object({
  collectionType: string()
    .oneOf(['concept', 'program', 'interest', 'challenge'])
    .required(),
  title: string().required('Enter a concept title'),
  description: string().required('Enter a description'),
  permaLink: Yup.string()
    .checkPermalink(
      `Permalink is required and start with '/programs|interests|challenges/'`,
    )
    .required(
      `Permalink is required and start with '/programs|interests|challenges/'`,
    ),
  kicker: string().required('Enter a valid kicker'),
  flag: string().oneOf(['None', 'New', 'Trending', 'Popular']),
  seriesID: string()
    .required('Enter a series ID')
    .min(3)
    .matches(/[A-Z]{3,}/, 'Enter only uppercase char')
    .max(3),
  level: string().required('Enter a level'),
  instructors: array()
    .min(1)
    .required('Enter instructors'),
  categories: array().required('Enter categories'),

  gallery: array().required('Enter atleast one gallery image'),
  graphics: object().shape({
    '16x9': string().url(),
    '32x9': string().url(),
    '1x1': string().url(),
    '9x16': string().url(),
    '16x9_trans': string().url(),
  }),
  videos: object().shape({
    videoIds: array(),
  }),
  classCount: number().min(1, 'Collection contain atleast one video'),
});

const dialogStyles = () =>
  createStyles({
    dialogContainer: {
      borderRadius: 0,
      maxWidth: '1080px',
    },
    imageContainer: {
      maxWidth: '252px',
      margin: '0px 16px',
    },
    videoHeader: {},
    classWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    form: {
      // maxWidth: '33.33%',
    },
    dialogContent: {
      padding: '24px 16px 72px',
    },
    mr6: {
      marginRight: '6px',
    },
    ml6: {
      marginLeft: '6px',
    },
    dialogActions: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      background: '#fff',
      border: '1px solid #D9D9D9',
      margin: '0px -16px',
      justifyContent: 'space-between',
      display: 'flex',
      zIndex: 140000,
    },
    videoHeaderContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
    },
  });

interface videoId {
  id: string;
  title: string;
  videoID: {
    [key in string]: string;
  };
}
enum CollectionType {
  CONCEPT = 'concept',
  PROGRAM = 'program',
  INTEREST = 'interest',
  CHALLENGE = 'challenge',
}

interface FormValues {
  kicker: string;
  title: string;
  permaLink: string;
  teaserText: string;
  flag: string;
  seriesID: string;
  description: string;
  instructors: string[];
  categories: string[];
  gallery: any;
  graphics: {
    '16x9': string;
    '32x9': string;
    '1x1': string;
    '9x16': string;
    '16x9_trans': string;
    'promoSpotImg' : string;
  };
  videos: {
    videoIds: string[];
    weeks: {};
  };
  collectionType: CollectionType;
  isHidden: boolean;
  isPublished: boolean;
  classCount: number;
}

const initialValues: FormValues = {
  collectionType: '',
  title: '',
  kicker: '',
  permaLink: '',
  teaserText: '',
  flag: 'None',
  seriesID: '',
  description: '',
  instructors: [],
  categories: [],
  genres: [],
  gallery: [],
  graphics: {
    '16x9': '',
    '32x9': '',
    '9x16': '',
    '1x1': '',
    '16x9_trans': '',
    'promoSpotImg' : '',
  },
  level: '',
  videos: {
    videoIds: [],
    weeks: {},
  },
  isHidden: false,
  isPublished: false,
  classCount: 0,
};

const CollectionTypes = [
  CollectionType.PROGRAM,
  CollectionType.CHALLENGE,
  CollectionType.INTEREST,
];

export enum FormType {
  ADD = 'add',
  EDIT = 'edit',
}

export enum ChallengeSwitchType {
  VIDEOS = 'VIDEOS',
  WEEKS = 'WEEKS',
}

interface IConceptFormDialog {
  isOpen: boolean;
  onClose: (
    event: React.SyntheticEvent,
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => void;
  title: string;
  id: string;
  type: FormType;
  classes: Record<
    | 'dialogContainer'
    | 'imageContainer'
    | 'videoHeader'
    | 'classWrapper'
    | 'form'
    | 'dialogContent'
    | 'mr6'
    | 'ml6'
    | 'dialogActions'
    | 'videoHeaderContainer',
    string
  >;
}

function CollectionFormDialog({
  isOpen,
  onClose,
  title,
  classes,
  id,
  type,
}: IConceptFormDialog) {
  let [possibleOptions, setPossibleOptions] = React.useState({
    flags: [],
    instructors: [],
    genres: [],
    categories: [],
    collectionTypes: [],
    levels: [],
  });

  let [initialFormValues, setInitialFormValues] = React.useState(initialValues);
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  const [isConfirmPublishDialogOpen, openConfirmPublishDialog] = React.useState(
    false,
  );
  const [isLoading, setLoader] = React.useState(false);
  const [isHideAlertOpen, showAlertToHide] = React.useState(false);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [isSwitchDialogOpen, openSwitchDialog] = React.useState(false);
  const [isPublish, setPublish] = React.useState(false);
  const [switchType, setSwitchType] = React.useState(ChallengeSwitchType.WEEKS);
  const [savedFormData, setSavedFormData] = React.useState({});
  const dispatch = useDispatch();

  const _formatOptions = result => {
    let {
      flag,
      instructors,
      genres,
      categories,
      data,
      collectionTypes = [],
      levels = [],
    } = result;

    flag = flag.map(f => ({
      label: f.title,
      value: f.title,
    }));
    flag = [{ label: 'None', value: 'None' }, ...flag];

    collectionTypes = CollectionTypes.map(c => ({ label: c, value: c }));
    levels = [
      { label: 'All Levels', value: 'AL' },
      { label: 'Beginner', value: 'B' },
      { label: 'Beginner To Intermediate', value: 'BI' },
      { label: 'Intermediate', value: 'I' },
      { label: 'Intermediate To Advanced', value: 'IA' },
      { label: 'Advanced', value: 'A' },
    ];

    instructors = instructors.map(i => ({
      id: i.id,
      title: `${i.firstName} ${i.lastName ? i.lastName : ''}`,
    }));

    genres = genres.map(g => ({
      id: g.id,
      title: g.title,
    }));

    categories = categories.map(g => ({
      id: g.id,
      title: g.title,
    }));

    let {
      instructors: conceptInstructors = [],
      categories: conceptCategories = [],
      videos = {
        weeks: {},
        videoIds: [],
      },
    } = data;
    data.instructors = instructors.filter(i =>
      conceptInstructors.includes(i.id),
    );
    data.categories = categories.filter(i => conceptCategories.includes(i.id));
    data.classCount = 0;

    if (
      (data.collectionType === CollectionType.CHALLENGE &&
        (videos && videos.videoIds)) ||
      (data.collectionType === CollectionType.PROGRAM &&
        (videos && videos.videoIds)) ||
      data.collectionType === CollectionType.INTEREST
    ) {
      setSwitchType(ChallengeSwitchType.VIDEOS);
      data.classCount = videos.videoIds && videos.videoIds.length;
    } else if (videos && videos.weeks) {
      Object.keys(videos.weeks).map(w => {
        let days = Object.keys(videos.weeks[w]);

        days.forEach(d => {
          data.classCount += videos.weeks[w][d].length;
        });
      });
      setSwitchType(ChallengeSwitchType.WEEKS);
    }

    return {
      flags: flag,
      instructors,
      genres,
      categories,
      data,
      collectionTypes,
      levels,
    };
  };

  const _init = async () => {
    try {
      setLoader(true);
      let { result } = await request({
        url: '/neouadmin/v1/collection-detail',
        method: 'GET',
        params: { id },
      });
      setLoader(false);
      let { data, ...otherOptions } = _formatOptions(result);
      setPossibleOptions({
        categories: otherOptions.categories,
        flags: otherOptions.flags,
        genres: otherOptions.genres,
        instructors: otherOptions.instructors,
        collectionTypes: otherOptions.collectionTypes,
        levels: otherOptions.levels,
      });
      setInitialFormValues({ ...initialValues, ...data });
    } catch (error) {
      console.log(error);
      setLoader(false);
      dispatch(openSnackBar('Error in fetching the concept info..'));
    }
  };

  React.useEffect(() => {
    _init();
  }, [id]);

  const frameData = (values: any) => {
    let framedData: FormValues = {
      ...values,
      instructors: values.instructors ? values.instructors.map(i => i.id) : [],
      categories: values.categories ? values.categories.map(i => i.id) : [],
      videos: {
        weeks: {},
      },
      isHidden: values.isHidden,
      isPublished: values.isPublished,
      flag: values.flag === 'None' ? '' : values.flag,
    };
    if (values.videos && values.videos.weeks) {
      Object.keys(values.videos.weeks).map(weekGroupKey => {
        framedData.videos.weeks[weekGroupKey] = {};
        Object.keys(values.videos.weeks[weekGroupKey]).map(weekKey => {
          framedData.videos.weeks[weekGroupKey][weekKey] = values.videos.weeks[
            weekGroupKey
          ][weekKey].map(d => d.id);
        });
      });
    }

    if (
      (values.collectionType == 'interest' && values.videos) ||
      switchType === ChallengeSwitchType.VIDEOS
    ) {
      delete values.videos.weeks;
      if (values.videos && values.videos.videoIds && values.videos.videoIds) {
        framedData.videos = {
          videoIds: [],
        };
        framedData.videos.videoIds = values.videos.videoIds.map(
          (v, index) => v.id,
        );
      }
    } else {
      delete values.videos.videoIds;
    }

    return framedData;
  };

  const handleSubmit = async (values: FormValues): Promise<any> => {
    let formData = frameData(values);
    // update status as default
    if (isPublish) {
      formData.isHidden = Boolean(false);
      formData.isPublished = Boolean(true);
      setSavedFormData({ ...formData });
      saveCollection(formData);
    } else {
      formData.isHidden = Boolean(formData.isHidden);
      formData.isPublished = Boolean(formData.isPublished);
      setSavedFormData({ ...formData });
      if (formData.isPublished) {
        openConfirmPublishDialog(true);
      } else {
        saveCollection(formData);
      }
    }
  };

  const hideConcept = async (
    values: FormValues,
    { submitForm, errors, setErrors, setFieldValue, setTouched },
  ) => {
    let f = frameData(values);
    f.isHidden = Boolean(true);
    f.isPublished = Boolean(false);
    setSavedFormData({ ...f });
    setLoader(true);
    try {
      await request({
        url: '/neouadmin/v1/collections',
        method: type === FormType.ADD ? 'POST' : 'PUT',
        data: f || savedFormData,
      });
      // setFieldValue('isHidden', true);
      // setFieldValue('isPublished', false);
      setLoader(false);
      dispatch(
        openSnackBar('The Collection status has been updated successfully!'),
      );
      dispatch(requestCollectionList());
      onClose();
    } catch ({ data: error }) {
      setLoader(false);
      dispatch(openSnackBar(error.error));
    }
  };

  const saveCollection = async formData => {
    setLoader(true);
    try {
      await request({
        url: '/neouadmin/v1/collections',
        method: type === FormType.ADD ? 'POST' : 'PUT',
        data: formData || { ...savedFormData },
      });
      setLoader(false);
      if (isPublish) {
        dispatch(
          openSnackBar('The Collection has been published successfully!'),
        );
      } else {
        dispatch(openSnackBar('The Collection has been saved successfully!'));
      }
      dispatch(requestCollectionList());
      onClose();
    } catch ({ data: error }) {
      setLoader(false);
      if (error.error) {
        dispatch(openSnackBar(`Failed to save collection - ${error.error}`));
      } else {
        dispatch('Error in saving the collection..');
      }
    }
  };

  const onOverflowMenuClick = option => {
    if (option === 'Delete All') {
      // Open a confirmation modal to delete all the videos
      openConfirmDialog(true);
      // on click of OK, delete all the videos
    }
  };

  const previewCollection = values => {
    window.open(`${window.origin}/preview/collection/${id}${values.permaLink}`);
  };

  const onCollectionTypeChange = ({ values, setFieldValue }) => {
    let { videoIds = [], weeks = {} } = values.videos;
    if (
      values.collectionType === CollectionType.INTEREST ||
      (values.collectionType === CollectionType.CHALLENGE &&
        switchType === ChallengeSwitchType.VIDEOS) ||
      (values.collectionType === CollectionType.PROGRAM &&
        switchType === ChallengeSwitchType.VIDEOS)
    ) {
      setFieldValue('classCount', videoIds.length);
    } else {
      let count = calculateClassCount(weeks);
      setFieldValue('classCount', weeks ? calculateClassCount(weeks) : 0);
    }
  };

  return (
    <>
      {isLoading && <Loader fullWidth />}

      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick={true}
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers classes={{ root: classes.dialogContent }}>
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the collection."
            onAgree={() => onClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            validationSchema={CollectionSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              submitForm,
              errors,
              isValid,
              setErrors,
              validateForm,
              setTouched,
            }) => {
              return (
                <Form noValidate>
                  <ConfirmDialog
                    isOpen={isSwitchDialogOpen}
                    handleClose={() => openSwitchDialog(false)}
                    headerText="Are you sure?"
                    bodyText={`Switching to only ${
                      switchType === ChallengeSwitchType.WEEKS
                        ? ChallengeSwitchType.VIDEOS
                        : ChallengeSwitchType.WEEKS
                    }  will delete the current data.`}
                    onAgree={() => {
                      setFieldValue('classCount', 0);
                      setFieldValue('videos.weeks', {});
                      let type =
                        String(switchType) === String(ChallengeSwitchType.WEEKS)
                          ? ChallengeSwitchType.VIDEOS
                          : ChallengeSwitchType.WEEKS;
                      setSwitchType(type);
                    }}
                    onDisagree={() => {
                      openSwitchDialog(false);
                    }}
                  />
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs container>
                      <FormikSelect
                        name="collectionType"
                        label="Collection Type"
                        items={possibleOptions.collectionTypes}
                        onBlur={() => {
                          // let str = values.title.replace(/\s+/g, '-');
                          setFieldValue(
                            'permaLink',
                            `/${values.collectionType}s/` +
                              slugify(values.title),
                          );
                          onCollectionTypeChange({ values, setFieldValue });
                        }}
                        // handleChange={() => {
                        //   setTimeout(() => {
                        //     onCollectionTypeChange({ values, setFieldValue });
                        //   }, 1500);
                        // }}
                      />
                      <FormikField
                        name="title"
                        label="Title"
                        placeholder="Enter a title"
                        onBlur={() => {
                          setFieldValue(
                            'permaLink',
                            `/${values.collectionType}s/` +
                              slugify(values.title),
                          );
                        }}
                      ></FormikField>

                      <FormikField
                        name="kicker"
                        label="Kicker"
                        placeholder="Enter a kicker"
                      />

                      <FormikField
                        name="permaLink"
                        label="Permalink"
                        placeholder="Enter a permalink"
                      />
                      <FormikField
                        name="teaserText"
                        label="TeaserText"
                        placeholder="Enter a Teaser Text"
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormikField
                            name="seriesID"
                            label="ID"
                            placeholder="Enter an ID"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormikSelect
                            name="flag"
                            label="Flag"
                            items={possibleOptions.flags}
                          />
                        </Grid>
                      </Grid>
                      <FormikAutocomplete
                        name="instructors"
                        label="Instructors"
                        placeholder="Add"
                        items={possibleOptions.instructors}
                        multiple={true}
                      />
                      <FormikSelect
                        name="level"
                        label="Level"
                        items={possibleOptions.levels}
                      />
                      <FormikAutocomplete
                        name="categories"
                        label="Categories"
                        placeholder="Add"
                        items={possibleOptions.categories}
                        multiple={true}
                      />
                      <FormikField
                        name="description"
                        label="Description"
                        placeholder="Enter a description"
                        multiline
                      />
                      {type === FormType.EDIT && (
                        <Grid item>
                          <Typography variant="h6">HISTORY</Typography>
                          <HistoryText>
                            Added:{' '}
                            {moment(values.createdAt).format('MM/DD/YYYY')}
                          </HistoryText>
                          {values.isPublished && (
                            <HistoryText>
                              Published:
                              {moment(values.publishedDate).format(
                                'MM/DD/YYYY',
                              )}
                            </HistoryText>
                          )}
                          <HistoryText>
                            Updated:{' '}
                            {moment(values.updatedAt).format('MM/DD/YYYY')}
                          </HistoryText>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={3} container>
                      <FormikGallery
                        galleryItems={values.gallery}
                        albumName="concept"
                        title="MANAGE COLLECTION GALLERY"
                      />

                      <LabelText>Merchandising Graphics</LabelText>
                      <FormikImageUploader
                        name="graphics.16x9"
                        aspectRatio="16x9"
                        key="16x9"
                        title="16:9 Banner *"
                        defaultValue={
                          values.graphics && values.graphics['16x9']
                        }
                      />

                      <FormikImageUploader
                        name="graphics.promoSpotImg"
                        aspectRatio="promoSpotImg"
                        key="promoSpotImg"
                        title="1300x460 Banner *"
                        ispromoValidation
                        defaultValue={
                          values.graphics && values.graphics['promoSpotImg']
                        }
                      />

                      <FormikImageUploader
                        name="graphics.32x9"
                        aspectRatio="32x9"
                        key="32x9"
                        title="32:9 Wide Banner *"
                        defaultValue={
                          values.graphics && values.graphics['32x9']
                        }
                      />

                      {values.collectionType === CollectionType.INTEREST && (
                        <FormikImageUploader
                          name="graphics.9x16"
                          aspectRatio="9x16"
                          key="9x16"
                          title="9:16 Title *"
                          defaultValue={
                            values.graphics && values.graphics['9x16']
                          }
                        />
                      )}

                      <FormikImageUploader
                        title="1:1 Tile *"
                        name="graphics.1x1"
                        aspectRatio="1x1"
                        key="1x1"
                        defaultValue={values.graphics && values.graphics['1x1']}
                      />
                    </Grid>

                    <Grid item xs container>
                      <div className={classes.videoHeaderContainer}>
                        <Typography
                          variant="subtitle1"
                          className={classes.videoHeader}
                        >
                          Videos ({values.classCount})
                        </Typography>
                        <ErrorText>
                          {errors.classCount && (
                            <ErrorMessage name="classCount" />
                          )}
                        </ErrorText>
                        {values.classCount !== 0 &&
                          values.videos &&
                          values.videos.videoIds &&
                          values.videos.videoIds.length > 0 && (
                            <ThreeDotMenu
                              options={['Delete All']}
                              onOptionClick={onOverflowMenuClick}
                            ></ThreeDotMenu>
                          )}
                      </div>
                      <Grid container>
                        <Grid item xs>
                          {values.collectionType === CollectionType.INTEREST ||
                          (values.collectionType === CollectionType.CHALLENGE &&
                            switchType === ChallengeSwitchType.VIDEOS) ||
                          (values.collectionType === CollectionType.PROGRAM &&
                            switchType === ChallengeSwitchType.VIDEOS) ? (
                            <FormikSearchVideo
                              videoList={
                                (values.classCount !== 0 &&
                                  values.videos &&
                                  values.videos.videoIds) ||
                                []
                              }
                              handleChange={list => {
                                setFieldValue('classCount', list.length || 0);
                              }}
                              name="videos.videoIds"
                            />
                          ) : (
                            <CollectionWeek
                              weeks={values.videos.weeks || {}}
                              onChange={weekGroup => {
                                setFieldValue('videos.weeks', weekGroup);
                                let count = calculateClassCount(weekGroup);

                                setFieldValue('classCount', count);
                              }}
                            ></CollectionWeek>
                          )}
                          {(values.collectionType ===
                            CollectionType.CHALLENGE ||
                            values.collectionType ===
                              CollectionType.PROGRAM) && (
                            <Box mt={1}>
                              <Button
                                variant="outlined"
                                onClick={() => openSwitchDialog(true)}
                                fullWidth
                              >
                                Switch to{' '}
                                {switchType === ChallengeSwitchType.VIDEOS
                                  ? 'weeks'
                                  : 'videos'}
                              </Button>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <ConfirmDialog
                    isOpen={isConfirmDialogOpen}
                    headerText="Are you sure?"
                    bodyText="You are about to delete all the videos."
                    handleClose={() => openConfirmDialog(false)}
                    onAgree={() => {
                      setFieldValue('videos.videoIds', []);
                      setFieldValue('classCount', 0);
                    }}
                    onDisagree={() => openConfirmDialog(false)}
                  ></ConfirmDialog>
                  <ConfirmDialog
                    isOpen={isConfirmPublishDialogOpen}
                    headerText="Are you sure?"
                    bodyText="This content is already published. Changes will impact existing behavior"
                    handleClose={() => openConfirmPublishDialog(false)}
                    onAgree={() => {
                      saveCollection();
                    }}
                    onDisagree={() => openConfirmPublishDialog(false)}
                  />

                  <DialogActions classes={{ root: classes.dialogActions }}>
                    <div>
                      {type === FormType.EDIT &&
                        (values.isHidden ? (
                          <Box display="flex" alignItems="center" mr={1}>
                            <StatusLabel>STATUS</StatusLabel>
                            <StatusText>HIDDEN</StatusText>
                          </Box>
                        ) : (
                          <>
                            <Button
                              color="secondary"
                              onClick={() => showAlertToHide(true)}
                            >
                              Hide
                            </Button>
                            <ConfirmDialog
                              isOpen={isHideAlertOpen}
                              headerText="Are you sure?"
                              bodyText="You are about to hide the collection"
                              onAgree={() =>
                                hideConcept(values, {
                                  submitForm,
                                  errors,
                                  isValid,
                                  setErrors,
                                  validateForm,
                                  setFieldValue,
                                  setTouched,
                                })
                              }
                              handleClose={() => showAlertToHide(false)}
                              onDisagree={() => showAlertToHide(false)}
                            ></ConfirmDialog>
                          </>
                        ))}
                    </div>
                    <div>
                      {type === FormType.EDIT && (
                        <>
                          {!values.isPublished ? (
                            <Button
                              size="large"
                              color="secondary"
                              type="submit"
                              onClick={e => {
                                if (isValid) {
                                  setPublish(true);
                                }
                              }}
                            >
                              Publish
                            </Button>
                          ) : null}

                          <Button
                            size="large"
                            color="secondary"
                            type="button"
                            onClick={() => previewCollection(values)}
                          >
                            Preview
                          </Button>
                        </>
                      )}
                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        size="large"
                      >
                        Save & Close
                      </Button>
                    </div>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );

  function calculateClassCount(weekGroup: any) {
    let count = 0;
    Object.keys(weekGroup).map(weekGroupKey =>
      Object.keys(weekGroup[weekGroupKey]).map(dayKey => {
        count += weekGroup[weekGroupKey][dayKey].length;
      }),
    );
    return count;
  }
}

export default withStyles(dialogStyles)(CollectionFormDialog);
