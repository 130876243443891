/*
 *
 * Attribute actions
 *
 */

import { action } from 'typesafe-actions';
import { AttributeList, AttributeDetailInfo } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

//Get Attribute list
export const requestAttributes = () => {
  return action(ActionTypes.REQUEST_ATTRIBUTE_LIST);
};

export const attributetListSuccess = (attributeList: AttributeList) => {
  return action(ActionTypes.ATTRIBUTE_LIST_SUCCESS, attributeList);
};

export const attributeListFailed = () => {
  return action(ActionTypes.ATTRIBUTE_LIST_FAILED);
};

export const serSortBy = sortBy => {
  return action(ActionTypes.SET_SORT_BY, sortBy);
};

export const getSeachResult = key => {
  return action(ActionTypes.GET_SEARCH_RESULT, key);
};

export const getAttributeDetail = (id: string) => {
  return action(ActionTypes.FETCH_ATTRIBUTE_BY_ID, id);
};

export const getAttributeDetailSuccess = attributeDetailInfo => {
  return action(ActionTypes.FETCH_ATTRIBUTE_BY_ID_SUCCESS, attributeDetailInfo);
};

export const setAttributeLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_ATTRIBUTE_LOADER, isLoading);
};

export const saveAttribute = attributeDetailInfo => {
  return action(ActionTypes.SAVE_ATTRIBUTE_DETAIL, attributeDetailInfo);
};

export const removeAttribute = attributeDetailInfo => {
  return action(ActionTypes.REMOVE_ATTRIBUTE_DETAIL, attributeDetailInfo);
};
