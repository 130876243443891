import * as React from 'react';
import {
  Button,
  makeStyles,
  Theme,
  createStyles,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
const pickerBtnStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '0px 8px',
      fontFamily: 'Proxima-Nova-Regular',
      '& .MuiGrid-item': {
        padding: '1px',
        cursor: 'pointer',
      },
    },
    box_1: {
      padding: '3px',
      width: '100%',
      borderRadius: '3px 0px 0px 3px;',
      backgroundColor: '#D4D8DC',
      '&:hover': {
        background: '#3E4142',
        color: 'white',
      },
    },
    box_2: {
      padding: '3px',
      width: '100%',
      // borderRadius: '3px 0px 0px 3px;',
      borderRadius: '0',
      backgroundColor: '#D4D8DC',
      '&:hover': {
        background: '#3E4142',
        color: 'white',
      },
    },
    box_3: {
      padding: '3px',
      width: '100%',
      borderRadius: '0px 3px 3px 0px;',
      backgroundColor: '#D4D8DC',
      '&:hover': {
        background: '#505B63',
        color: 'white',
      },
    },
    spacing: {
      textAlign: 'center',
      padding: '16px 0px',
      height: '14px',
      color: '#3E4142',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '12px',
      lineHeight: '14px',
    },
    selected: {
      background: '#3E4142',
      color: '#000',
    },
  }),
);

export default function FilterDifficultySelector(props) {
  const { value } = props;
  const classes = pickerBtnStyles();

  if (value && value.length) {
    return (
      <div className={classes.root}>
        <Grid container justify="center" spacing={2}>
          {value &&
            value.length > 0 &&
            value.map(data => {
              return (
                <Grid item sm={3} md={3} lg={3} xl={3}>
                  <Button
                    className={clsx(classes.box_1, {
                      [classes.selected]: data.isSelected,
                    })}
                    onClick={() =>
                      props.onSelect(
                        data.id,
                        data.mainTitle,
                        !data.isSelected,
                        data.title,
                        data.index,
                      )
                    }
                  >
                    {' '}
                  </Button>
                  <div className={classes.spacing}>{data.title}</div>
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  } else {
    return null;
  }
}
