/*
 *
 * Concept actions
 *
 */

import { action } from 'typesafe-actions';
import { ConceptList } from './types';

import ActionTypes from './constants';

//Get CONCEPT list
export const requestConceptList = () => {
  return action(ActionTypes.REQUEST_CONCEPT_LIST);
};

export const resetConcept = () => {
  return action(ActionTypes.RESET_CONCEPT);
};

export const conceptListSuccess = (conceptList: ConceptList) => {
  return action(ActionTypes.CONCEPT_LIST_SUCCESS, conceptList);
};

export const conceptListFailed = () => {
  return action(ActionTypes.CONCEPT_LIST_FAILED);
};

export const serSortBy = sortBy => {
  return action(ActionTypes.SET_SORT_BY, sortBy);
};

export const setTabChange = tab => {
  return action(ActionTypes.SET_ACTIVE_TAB, tab);
};

export const setSearchKey = key => {
  return action(ActionTypes.GET_SEARCH_RESULT, key);
};

export const getConceptDetail = (id: string) => {
  return action(ActionTypes.FETCH_CONCEPT_BY_ID, id);
};

export const storeConceptDetailInfo = conceptDetailInfo => {
  return action(ActionTypes.STORE_CONCEPT_DETAIL, conceptDetailInfo);
};
export const setConceptPaginationLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_CONCEPT_PAGINATION_LOADER, isLoading);
};
export const setConceptLoader = (isLoading: boolean) => {
  return action(ActionTypes.SET_CONCEPT_LOADER, isLoading);
};

export const addConceptDetailInfo = data => {
  return action(ActionTypes.ADD_CONCEPT_DETAIL_INFO, data);
};
export const addConceptDetailInfoSuccess = result =>
  action(ActionTypes.ADD_TO_CONCEPT_DETAIL_INFO_SUCCESS, result);
export const addConceptDetailInfoFailed = error =>
  action(ActionTypes.ADD_TO_CONCEPT_DETAIL_INFO_FAILED, error);

export const editConceptDetailInfo = data => {
  return action(ActionTypes.EDIT_CONCEPT_DETAIL_INFO, data);
};
export const editConceptDetailInfoSuccess = result =>
  action(ActionTypes.EDIT_TO_CONCEPT_DETAIL_INFO_SUCCESS, result);
export const editConceptDetailInfoFailed = error =>
  action(ActionTypes.EDIT_TO_CONCEPT_DETAIL_INFO_FAILED, error);

export const addConcept = data => {
  return action(ActionTypes.ADD_CONCEPT, data);
};

export const searchVideosByTitleOrID = (value: string) => {
  return action(ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID, value);
};

export const searchVideosByTitleOrIDSuccess = videoList => {
  return action(ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID_SUCCESS, videoList);
};

export const searchVideosByTitleOrIDFailed = error => {
  return action(ActionTypes.SEARCH_VIDEO_BY_TITLE_OR_ID_FAILED, error);
};

export const conceptListCountSuccess = (total: number) => {
  return action(ActionTypes.CONCEPT_LIST_TOTAL, total);
};

export const conceptListPaginationSuccess = payload => {
  return action(ActionTypes.CONCEPT_LIST_PAGINATION_SUCCESS, payload);
};
export const requestConceptListPagination = payload => {
  return action(ActionTypes.REQUEST_CONCEPT_LIST_PAGINATION, payload);
};
export const setInteractiveClassesOnly = payload => {
  return action(ActionTypes.SET_INTERACTIVE_CLASSES_ONLY, payload);
};

export const putConceptPagination = () => {
  return action(ActionTypes.PUT_CONCEPT_LIST_PAGINATION_SUCCESS);
};
