import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import appRequest from '../../shared/lib/appRequest';
import {
  peopleListSuccess,
  peopleListFailed,
  peopleDetailSuccess,
  peopleClassesSuccess,
  peopleClassesFailed,
  otherDetailSuccess,
  personLoader,
  peopleListPaginationSuccess,
  peopleListCountSuccess,
  putPeoplePagination,
  peoplePackageSuccess,
} from './actions';
import { selectPeopleDomain } from './selectors';
import { AnyAction } from 'redux';
import { openSnackBar } from '../Root/actions';
import { setLoader } from './actions';
const SLICE_COUNT_OF_CLASSES = 50;

// Individual exports for testing
export default function* peopleSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_PEOPLE_LIST, getPeopleVideoList);
  yield takeLatest(
    ActionTypes.REQUEST_PEOPLE_LIST_PAGINATION,
    getPeopleListPagination,
  );
  yield takeLatest(ActionTypes.REQUEST_PEOPLE_DETAIL, getEditPeopleAPI);
  yield takeLatest(ActionTypes.GET_PEOPLE_CLASSES, getClassesAPI);
  yield takeLatest(ActionTypes.PEOPLE_UPDATED_INFO, updatePeopleAPI);
  //add instructor
  yield takeLatest(ActionTypes.REQUEST_OTHER_DETAILS, getOtherDetails);
  yield takeLatest(ActionTypes.POST_ADD_INSTRUCTOR, addInstructorApi);
}

export function* getPeopleVideoList() {
  try {
    const interactivePeople = yield select(selectPeopleDomain);
    if (interactivePeople.sortBy === '') {
      interactivePeople.sortBy = 'updatedAt=desc';
    }
    const splitResult = interactivePeople.sortBy.split('=');
    const interactiveClasses = interactivePeople.isInteractiveFilterOn
      ? 'interactive=true&'
      : '';
    let url = `neouadmin/v1/people?${interactiveClasses}${interactivePeople.activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&keyword=${interactivePeople.searchKey}`;
    let { result, total } = yield call(() =>
      request({
        url: url,
        method: 'GET',
      }),
    );
    yield put(peopleListSuccess(result));
    yield put(peopleListCountSuccess(total));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(peopleListSuccess([]));
    }
    yield put(peopleListSuccess([]));
    yield put(peopleListFailed());
  }
}
export function* getPeopleListPagination(action) {
  yield put(setLoader(true));
  try {
    let { sortBy, activeTab } = yield select(selectPeopleDomain);
    if (sortBy === '') {
      sortBy = 'updatedAt=desc';
    }
    const splitResult = sortBy.split('=');
    let { result } = yield call(() =>
      request({
        url: `neouadmin/v1/people?${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&offset=${action.payload}&limit=${SLICE_COUNT_OF_CLASSES}`,
        method: 'GET',
      }),
    );
    yield put(peopleListPaginationSuccess(result));
    yield delay(2000);
    yield put(putPeoplePagination());
    yield put(peopleListPaginationSuccess([]));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(peopleListPaginationSuccess([]));
      yield put(setLoader(false));
    }
    yield put(peopleListFailed());
    yield put(setLoader(false));
  }
}

export function* getEditPeopleAPI(action: AnyAction) {
  let id = action.payload;
  yield put(setLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/people?id=${id}`,
        method: 'GET',
      }),
    );
    yield put(peopleDetailSuccess(result));
    let { result: data } = yield call(() =>
      request({
        url: `neouadmin/v1/instructor/get-packages?instructorId=${id}`,
        method: 'GET',
      }),
    );
    yield put(peoplePackageSuccess(data));
    yield put(setLoader(false));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(peopleListSuccess([]));
    }
    yield put(peopleListFailed());
  }
}

export function* getClassesAPI(action: AnyAction) {
  let id = action.payload;

  try {
    let { result } = yield call(() =>
      appRequest({
        url: `/neou/v1/instructor-detail?instrId=${id}`,
        method: 'POST',
        data: { bodyFocus: '5e35966ef512b563cce0ee93' },
      }),
    );
    yield put(peopleClassesSuccess(result));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(peopleClassesSuccess([]));
    }
    yield put(peopleClassesFailed());
  }
}

export function* updatePeopleAPI({ payload }: AnyAction) {
  let updateInfo = payload && payload.updateInfo;
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/people`,
        method: 'PUT',
        data: updateInfo,
      }),
    );
    if (payload && payload.isPublish) {
      yield put(openSnackBar('The people has been published successfully!'));
    } else {
      yield put(openSnackBar('The people has been saved successfully!'));
    }
  } catch ({ data: error }) {
    setLoader(false);
    if (error.error) {
      yield put(openSnackBar(`Failed to save people - ${error.error}`));
    } else {
      yield put(openSnackBar('Error in saving the People..'));
    }
  }
}

//get other detail values
export function* getOtherDetails(action: AnyAction) {
  let id = action.payload;
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/people?isEmpty=${id}`,
        method: 'GET',
      }),
    );

    yield put(otherDetailSuccess(result));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(peopleListSuccess([]));
    }
    yield put(peopleListFailed());
  }
}
//add instructor
export function* addInstructorApi(action: AnyAction) {
  yield put(personLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/people`,
        method: 'POST',
        data: action.payload,
      }),
    );
    yield put(personLoader(false));
  } catch (error) {
    yield put(personLoader(false));
  }
}
