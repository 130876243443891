import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the interactivePublishedClasses state domain
 */

const selectInteractivePublishedClassesDomain = (
  state: ApplicationRootState,
) => {
  return state.interactiveClass || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by InteractivePublishedClasses
 */

const makeSelectInteractivePublishedClasses = () =>
  createSelector(
    selectInteractivePublishedClassesDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectInteractivePublishedClasses;
export { selectInteractivePublishedClassesDomain };
