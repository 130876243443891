import React from 'react';
import { Drawer, IconButton, Divider, Button } from '@material-ui/core';
import reducer from '../reducer';
import saga from '../saga';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useInjectReducer } from '../../../utils/injectReducer';
import { createStructuredSelector } from 'reselect';
import makeSelectAdministrator from '../selectors';
import { useSelector, useDispatch } from 'react-redux';
import EditAdministratorForm from './EditAdministratorForm';
import { SvgIconEdit } from '../../../components/NeIcons';
import CloseIcon from '@material-ui/icons/Close';
import Loader from '../../../components/Loader';
import ConfirmDialog from '../../../components/ConfirmDialog';

export default function EditDialog({ id, classes, administratorDetail }) {
  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
    // left: false,
    isLoading: true,
  });
  const toggleDrawer = (anchor, open) => event => {
    setState({ ...state, [anchor]: open, isLoading: open });
  };

  return (
    <div>
      <React.Fragment>
        <IconButton
          onClick={toggleDrawer('right', true)}
          className={classes.editIcon}
          id={id}
        >
          <SvgIconEdit />
        </IconButton>

        <Drawer
          anchor={'right'}
          disableBackdropClick={true}
          open={state['right']}
          onClose={() => showAlertToClose(true)}
        >
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the admin."
            onAgree={toggleDrawer('right', false)}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <div className={classes.rightContainer}>
            {!administratorDetail && <Loader />}
            <div className={classes.titleDiv}>
              <div className={classes.title}>EDIT ADMINISTRATOR</div>
              <Button
                onClick={() => showAlertToClose(true)}
                className={classes.closeBtn}
              >
                <CloseIcon />
              </Button>
            </div>
            <Divider className={classes.divider} />

            {administratorDetail && (
              <EditAdministratorForm
                administratorDetail={administratorDetail}
                classes={classes}
                toggleFn={toggleDrawer('right', false)}
              ></EditAdministratorForm>
            )}
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
