import { uniqBy } from 'lodash';

export const getUniqItems = (array, key) => {
  let mymap = new Map();
  let unique = [];
  unique = array.filter(el => {
    const val = mymap.get(el && el.name);
    if (val) {
      if (el[key] < val) {
        mymap.delete(el.name);
        mymap.set(el.name, el[key]);
        return true;
      } else {
        return false;
      }
    }
    mymap.set(el.name, el[key]);
    return true;
  });
  //   return unique;
  return uniqBy(array, function(e) {
    return e[key];
  });
};
