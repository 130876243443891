import moment from 'moment';
import { DateTime } from 'luxon';

var generateDays = (week = 2, startDate = new Date()) => {
  var dateGroup = {};
  for (let i = 0; i < week * 7; i++) {
    var next_date = new Date(startDate);
    next_date.setDate(next_date.getDate() + i);
    //console.log(moment(next_date).format('YYYY-MM-DD'));

    dateGroup[moment(next_date).format('YYYY-MM-DD')] = [];
  }

  return dateGroup;
};

var generateDaysBetween = (startDate = new Date(), endDate = new Date()) => {
  var a = moment(new Date(startDate));
  var b = moment(new Date(endDate));
  let diff = Math.abs(a.diff(b, 'days'));
  let weeksCount = Math.ceil(diff / 7);
  return generateDays(weeksCount, startDate);
};

var groupItemsByDate = (classes, dateKey = 'startDate') => {
  var classGroup = {};

  classes.forEach(c => {
    let key = new Date(c[String(dateKey)]);
    if (String(key) === 'Invalid Date') {
      key = new Date();
    }
    let dateString = moment(key).format('YYYY-MM-DD');
    if (!classGroup[dateString]) {
      classGroup[dateString] = [];
    }
    classGroup[dateString].push(c);
  });

  return classGroup;
};

const getTimeAfterMinutes = (date, minutes = 0) => {
  if (moment(date).format() === 'Invalid date') {
    return moment(+date)
      .utc()
      .add(minutes, 'minutes')
      .format('LT');
  } else {
    return moment(date)
      .utc()
      .add(minutes, 'minutes')
      .format('LT');
  }
};
const getTimeWithZone = date => {
  let moment = require('moment-timezone');
  let dt: any = DateTime.local();
  dt = dt.zoneName;
  moment.tz.setDefault(dt);
  let tzTime = moment(new Date(date));

  return moment(tzTime, dt).format('z');
};

const UnivDate = date => {
  if (moment(date).format() === 'Invalid date') {
    return +date;
  } else {
    return date;
  }
};
const getTimeAfterMinutesCD = (date, minutes = 0) => {
  return moment(date)
    .add(minutes, 'minutes')
    .utc()
    .format('LT');
};
// moment(current).isAfter(classCutOff)
const classStart = (date, activeMin, passMin) => {
  let scheduled = moment(new Date(date));
  let classCutOff = moment(new Date(date)).add(activeMin, 'minutes');
  let passed = moment(new Date(date)).add(passMin, 'minutes');
  let current = moment(new Date());

  //console.log(moment(current).isAfter(classCutOff)); //true
  //console.log(moment(current).isBefore(classCutOff)); //false
  if (moment(current).isBefore(scheduled)) {
    return {
      before: true,
      join: false,
      inprogress: false,
      passed: false,
      textActive: false,
    };
  } else if (
    moment(current).isAfter(scheduled) &&
    moment(current).isBefore(classCutOff)
  ) {
    return {
      before: false,
      join: true,
      inprogress: false,
      passed: false,
      textActive: true,
    };
  } else if (
    moment(current).isAfter(scheduled) &&
    moment(current).isBefore(passed)
  ) {
    return {
      before: false,
      join: true,
      inprogress: false,
      passed: false,
      textActive: true,
    };
  } else if (moment(current).isAfter(passed)) {
    return {
      before: false,
      join: false,
      inprogress: false,
      passed: true,
      textActive: false,
    };
  } else {
    return {
      before: false,
      join: false,
      inprogress: false,
      passed: true,
      textActive: false,
    };
  }
};

const getLocalDateTime = (date, format) => {
  let moment = require('moment-timezone');
  let dt = moment.tz.guess();
  return moment(date)
    .tz(dt)
    .format(format);
};

// Reference for formating - https://momentjs.com/docs/#/displaying/format/
const getEndTimeWithZone = (date, minutes = 0) => {
  let moment = require('moment-timezone');
  let dt = moment.tz.guess();
  // let dt = DateTime.local(date);
  return moment(date)
    .add(minutes, 'minutes')
    .tz(dt)
    .format('h:mm A z');
};

const isWithinTimeToStart = (date, durationInMinutes) => {
  let currentTime = moment(Date.now());
  // let startTime = moment(Date.parse('2020-10-21T16:25'));
  let classStartTime = moment(new Date(date));
  let isStarted =
    classStartTime.diff(currentTime) <= durationInMinutes * 1000 * 60;

  return isStarted;
};

const getClassTiming = (date, duration) => {
  let currentTime = moment(Date.now());
  let classStartTime = moment(new Date(date));
  let bufferTime = moment(new Date(date)).add(5, 'minutes');
  let classEndTime = moment(new Date(date)).add(duration, 'minutes');
  // let notStarted = currentTime.isBefore(classStartTime);
  // let isStarted = currentTime.isAfter(classStartTime);
  let isStarted = isWithinTimeToStart(date, 10);
  let notStarted = !isStarted;

  let isInTime = currentTime.isBefore(classStartTime.add(5, 'minutes'));
  let inProgress = currentTime.isBetween(
    bufferTime,
    classStartTime.add(duration, 'minutes'),
  );
  let canCancel = classStartTime.diff(currentTime, 'hours') >= 4;
  let ended = currentTime.isAfter(classEndTime);
  let activeText = currentTime.isBefore(bufferTime);

  return {
    upcomming: notStarted,
    hasStarted: isStarted,
    stillTime: isInTime,
    active: inProgress,
    canCancel: canCancel,
    completed: ended,
    showText: activeText,
  };
};

export {
  generateDays,
  groupItemsByDate,
  generateDaysBetween,
  getTimeAfterMinutes,
  getTimeAfterMinutesCD,
  getTimeWithZone,
  classStart,
  UnivDate,
  getClassTiming,
  getEndTimeWithZone,
  getLocalDateTime,
};
