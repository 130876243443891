import React, { useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { useStyles } from '../styles';
import {
  SvgIconEdit,
  SvgIconAdd,
  SvgIconSearch,
} from '../../../components/NeIcons';
import {
  IconButton,
  Drawer,
  Button,
  Box,
  InputAdornment,
} from '@material-ui/core';
import EditCouponForm from './EditCouponForm';
import { DrawerView } from '../../HomeCurate/components/DrawerView';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { openSnackBar } from '../../Root/actions';
import { useDispatch } from 'react-redux';
import request from '../../../shared/lib/request';
import { requestPromoList, setSearchKey } from '../actions';
import NEInput from '../../../components/NEInput';
import debounce from 'lodash.debounce';
import { isUndefined } from 'lodash';

export default function CouponListTable(props) {
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');
  const { couponList, isLoading } = props;
  const [couponDialogData, setCouponDialogData] = React.useState({
    isOpen: false,
    formType: 'ADD',
    couponData: {},
  });
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);

  const getDate = list => {
    let currentTime = moment(Date.now());
    let startTime = moment(list.startDate);
    let endTime = moment(list.endDate);
    let isEnded = endTime.diff(currentTime) >= 30000;
    var diff = moment.duration(endTime.diff(startTime));
    let months = diff.months();
    let weeks = diff.weeks();
    let days = diff.days() % 7;

    return (
      <div>
        <div>{`Redemption: ${
          list.availableSlot === 'unlimited'
            ? 'unlimited'
            : list.maxSlot - list.availableSlot
        }`}</div>
        <div>{`Duration: ${
          months !== 0 ? `${months} ${months === 1 ? 'month' : 'months'}` : ''
        }${
          weeks !== 0
            ? `${
                months !== 0
                  ? `${days !== 0 ? `,` : ` &`} ${weeks} ${
                      weeks === 1 ? ' week' : ' weeks'
                    }`
                  : `${weeks} ${weeks === 1 ? ' week' : ' weeks'}`
              }`
            : ''
        } ${
          days !== 0
            ? `${
                weeks !== 0 || months !== 0
                  ? ` & ${days} ${days === 1 ? ' day' : ' days'}`
                  : `${days} ${days === 1 ? ' day' : ' days'}`
              }`
            : `${weeks === 0 && months === 0 && days === 0 ? '0 day' : ''}`
        }`}</div>
        <div>{`Valid: ${isEnded ? 'Yes' : 'No'}`}</div>
      </div>
    );
  };
  const renderCTA = ({
    isPublished = false,
    isHidden = false,
    isError = false,
  }) => {
    if (isError) {
      return <div></div>;
    }

    if (isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.published}`}>
          {'PUBLISHED'}
        </div>
      );
    }
    if (!isPublished) {
      return (
        <div className={`${classes.thirdColumn} ${classes.draft}`}>
          {'DRAFT'}
        </div>
      );
    }
  };

  const onCouponEdit = (isOpen, formType, couponData) => {
    setCouponDialogData({
      isOpen: isOpen,
      formType: formType,
      couponData: couponData || {},
    });
  };

  const handleEditDrawerClose = () =>
    setCouponDialogData({
      isOpen: false,
      formType: 'ADD',
      couponData: {},
    });

  const dispatch = useDispatch();

  // highlight-starts
  const debouncedGet = useCallback(
    debounce(textValue => {
      onSearchClick(textValue);
    }, 1500),
    [], // will be created only once initially
  );

  const handleInput = e => {
    setSearchText(e.target.value);
    debouncedGet(e.target.value);
  };

  const onSearchClick = (text = '') => {
    dispatch(setSearchKey(text ? text : searchText));
    dispatch(requestPromoList());
  };

  return (
    <>
      <div className={classes.lowerHeaderSection}>
        <Button
          onClick={() => {
            onCouponEdit(true, 'ADD', {});
          }}
          variant="contained"
          color="secondary"
          className={classes.addVideoBtn}
          disableRipple={true}
        >
          <SvgIconAdd /> <div className={classes.newVideo}>Coupon</div>
        </Button>
        <div className={classes.searchWrap}>
          {couponList && couponList.length > 0 && (
            <NEInput
              label="Search"
              variant="outlined"
              id="custom-css-outlined-input"
              fullWidth={true}
              onChange={e => handleInput(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={() => onSearchClick()} >
                      <SvgIconSearch />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>
      </div>

      <Paper className={classes.root}>
        {couponList && couponList.length === 0 ? (
          <div className={classes.noData}>No Data Found</div>
        ) : (
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead></TableHead>
              <TableBody>
                <TableRow key={couponList.id} tabIndex={-1}>
                  <TableCell align="left" style={{ paddingLeft: '30px' }}>
                    <div className={classes.attributeTitle}>Name</div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.attributeTitle}></div>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.attributeTitle}>Validity</div>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.attributeTitle}>Status</div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.attributeTitle}></div>
                  </TableCell>
                </TableRow>
                {couponList &&
                  couponList.length > 0 &&
                  couponList.map(list => (
                    <TableRow key={list.id} hover role="checkbox" tabIndex={-1}>
                      <TableCell align="left">
                        <div className={classes.firstColumn}>
                          <div className={classes.firstCoulmContent}>
                            <div className={classes.subTitle}>
                              Unique Code: {list.uniqueCode}
                            </div>

                            <div className={classes.text}>
                              <span>
                                Percentage Discount: {list.offerPercentage}%
                              </span>
                            </div>
                            <div className={classes.text}>
                              <span>
                                Valid For:{' '}
                                {list &&
                                list.referenceIdDetails &&
                                list.referenceIdDetails.interactive &&
                                list.referenceIdDetails.interactive.title
                                  ? list.referenceIdDetails.interactive.title
                                  : list &&
                                    list.referenceIdDetails &&
                                    list.referenceIdDetails.firstName
                                  ? `${
                                      list.referenceIdDetails.firstName
                                    } ${list.referenceIdDetails &&
                                      list.referenceIdDetails.lastName}`
                                  : list &&
                                    list.referenceIdDetails &&
                                    list.referenceIdDetails.nickname
                                  ? list.referenceIdDetails.nickname
                                  : list.mappedTo === 'class' ||
                                    list.mappedTo === 'global'
                                  ? list.mappedTo &&
                                    list.mappedTo.charAt(0).toUpperCase() +
                                      `${list.mappedTo.slice(1)} Drop In`
                                  : list.mappedTo &&
                                    list.mappedTo === 'firstclass'
                                  ? 'First-time class only (Drop In)'
                                  : list.mappedTo}
                              </span>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className={classes.text}>
                          {list && list.type && list.type.toUpperCase()}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className={classes.text}>{getDate(list)}</div>
                      </TableCell>
                      <TableCell align="center">{renderCTA(list)}</TableCell>

                      <TableCell align="right">
                        <IconButton
                          onClick={() => onCouponEdit(true, 'EDIT', list)}
                          classes={{ root: classes.editIcon }}
                        >
                          <SvgIconEdit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {couponDialogData && couponDialogData.isOpen && (
          <Box p={2}>
            <Drawer
              anchor={'right'}
              open={couponDialogData.isOpen}
              disableBackdropClick={true}
              onClose={() => showAlertToClose(true)}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <ConfirmDialog
                isOpen={isCloseAlertOpen}
                headerText="Are you sure?"
                bodyText="You are about to close the component."
                onAgree={() => handleEditDrawerClose()}
                handleClose={() => showAlertToClose(false)}
                onDisagree={() => showAlertToClose(false)}
              ></ConfirmDialog>

              <DrawerView
                handleClose={showAlertToClose}
                removable={true}
                title={`${couponDialogData.formType} COUPON`}
              >
                <EditCouponForm
                  id={
                    couponDialogData.couponData &&
                    couponDialogData.couponData.id
                  }
                  formType={couponDialogData.formType || 'ADD'}
                  couponData={couponDialogData.couponData}
                  packagesList={props.packagesList}
                  isOpen={couponDialogData.isOpen}
                  handleEditDrawerClose={() => handleEditDrawerClose()}
                />
              </DrawerView>
            </Drawer>
          </Box>
        )}
      </Paper>
    </>
  );
}
