import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import sideMenu from '../../layouts/routes';
import SvgIconLibrary from '../NeIcons/IconLibrary';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { el } from 'date-fns/locale';

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  path: string;
  userInfo?: any;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        // backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: '#fff',
      },
      '&$selected > $content > label': {
        backgroundColor: 'transparent!important',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        color: '#fff',
      },
    },
    content: {
      color: '#909599',
      padding: `12px 8px`,
      display: 'flex',
      flexDirection: 'row-reverse',
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
      '& > group': {
        backgroundColor: 'red',
      },
    },
    border: {
      borderBottom: '1px solid #272B2E',
    },
    group: {
      marginLeft: 0,
      borderBottom: '1px solid #272B2E',
      '& $content': {
        paddingLeft: theme.spacing(5),
        border: 'none',
        padding: '8px 4px 8px 40px',
        '& $labelText': {
          fontSize: '15px',
          lineHeight: '20px',
        },
      },
    },
    expanded: {
      '& > $iconContainer': {
        transform: 'rotate(0deg)',
      },
    },
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
      backgroundColor: 'transparent !important',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    labelIcon: {
      marginRight: theme.spacing(2),
      width: 18,
      height: 18,
      color: '#fff',
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      fontSize: '18px',
      lineHeight: '24px',
    },
    InstLabelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      fontSize: '12px',
      lineHeight: '14px',
    },
    iconContainer: {
      fontSize: '24px',
      color: '#fff',
    },
    activeRoute: {
      color: '#FFF',
    },
  }),
);

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 'auto',
      flexGrow: 1,
      maxWidth: 400,
    },
    activeRoute: {
      color: '#56FFFE !important',
      '& .MuiTypography-root': {
        color: '#56FFFE !important',
      },
      '& svg': {
        color: '#56FFFE !important',
      },
    },
    Border: {
      borderBottom: '1px solid #272B2E',
    },
    InstContent: {
      paddingLeft: '35px',
      ['@media (max-width:768px)']: {
        paddingLeft: '55px',
      },
    },
  }),
);

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    userInfo,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon className={classes.labelIcon} />
          <Typography
            variant="body2"
            className={
              userInfo.role === 'instructor'
                ? classes.InstLabelText
                : classes.labelText
            }
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer,
      }}
      {...other}
    />
  );
}

function SubMenuItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { labelText, labelInfo, color, bgColor, userInfo, ...other } = props;

  //var admins = sideMenu.filter(el => el.subMenu[0].path as string);
  function getUrl(label) {
    return window.location.href.indexOf(label) > -1 ? true : false;
  }

  let listItemClasses;
  listItemClasses = classNames({
    [' ' + classes.activeRoute]: getUrl(props.path),
  });
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <NavLink
            to={props.path}
            // onClick={props.onClick}
            style={{ textDecoration: 'none' }}
            activeClassName="active"
          >
            <Typography
              variant="body2"
              className={classes.labelText + listItemClasses}
            >
              {labelText}
            </Typography>
          </NavLink>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classNames(classes.content, classes.border),
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

export default function SideMenu({ links, userInfo }) {
  const classes = useStyles();
  const icon = SvgIconLibrary;
  const dispatch = useDispatch();
  const permissions = userInfo && userInfo.permissions;
  // permistion array
  const filteredArray = sideMenu.filter(el => {
    return permissions && permissions.indexOf(el.name) >= 0;
  });
  // getadmin
  var admins = sideMenu.filter(el => el.name === 'admins');
  // merge admins
  var Sidemenus = filteredArray.concat(admins);

  // getinteractive
  var interactive = sideMenu.filter(el => el.name === 'interactive');
  // merge admins
  var Sidemenus = filteredArray.concat(interactive);

  const _redirectTo = ({ path }: { path: string }) => {
    if(path === '/neou/members'){
      dispatch(push(path));
      window.location.reload();
    }else{
      dispatch(push(path));
    }
  };

  function getUrl(label) {
    return window.location.href.indexOf(label) > -1 ? true : false;
  }

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {links &&
        links.map(
          (menu, index) =>
            menu.accessibleFor.indexOf(userInfo && userInfo.role) !== -1 &&
            !menu.isHidden && (
              <StyledTreeItem
                nodeId={`${menu.nodeId}`}
                labelText={menu.label}
                labelIcon={menu.icon}
                className={
                  userInfo.role === 'instructor'
                    ? classNames(
                        {
                          [' ' + classes.activeRoute]: getUrl(menu.path),
                        },
                        classes.InstContent,
                      )
                    : classes.Border
                }
                key={index}
                onClick={() => _redirectTo(menu)}
                userInfo={userInfo}
              >
                {menu &&
                  menu.subMenu &&
                  menu.subMenu.map((subMenu, subMenuIndex) => (
                    <SubMenuItem
                      nodeId={`${index}${subMenuIndex}`}
                      labelText={subMenu.label}
                      path={subMenu.path}
                      key={`${index}${subMenuIndex}`}
                      //   labelInfo="90"
                      color="#fff"
                      bgColor="#e8f0fe"
                      onClick={() => {
                        dispatch(push(subMenu.path));
                      }}
                      userInfo={userInfo}
                    />
                  ))}
              </StyledTreeItem>
            ),
        )}
    </TreeView>
  );
}
