import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the browseCurate state domain
 */

const selectBrowseCurateDomain = (state: ApplicationRootState) => {
  return state.browseCurate || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by BrowseCurate
 */

const makeSelectBrowseCurate = () =>
  createSelector(
    selectBrowseCurateDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectBrowseCurate;
export { selectBrowseCurateDomain };
