import React, { useState } from 'react';
import SearchField from './SearchVideo';
import request from '../shared/lib/request';
import { Divider } from '@material-ui/core';
import { DraggableListInstructor } from './DraggableInteractiveInstructor';
import { ErrorText } from '../components/Typography';

export interface content {
  id: string;
  title: string;
  text: string;
  firstName: string;
  lastName: string;
}

interface getSubTitleFunc {
  (): string;
}

interface SearchCollectionComponentProps {
  contentList: content[];
  onContentListChange: (newValue: content[], action: string) => void;
  id?: any;
  subTitle?: string | getSubTitleFunc;
  onChange?: (contentList) => void;
  errorMessage?: any;
}

export const SearchInteractiveInstructor: React.FC<
  SearchCollectionComponentProps
> = ({
  contentList: defaultValue,
  subTitle = () => {},
  onChange = () => {},
  errorMessage = () => {},
}) => {
  const [isSearchResult, setSearchContent] = useState([]);
  const [contents, setContents] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [inputRef, setInputRef] = useState(null);
  let timeout;

  const handleSearchInputChange = value => {
    timeout = setTimeout(() => {
      setSearchText('');
      if (inputRef) inputRef.value = '';
    }, 1000);
    if (value) {
      setSearchText(
        value.interactive && value.interactive.firstName
          ? value.interactive.firstName
          : value.firstName,
      );
      setContents([value, ...contents]);
      onChange([value, ...contents]);
    }
  };

  function searchContents(data) {
    return request({
      url: `/neouadmin/v1/people?keyword=${data}&interactive=true&isPublished=true&isHidden=false`,
      method: 'GET',
    });
  }
  const onSearchChange = async value => {
    try {
      let { result } = await searchContents(value);
      setSearchContent(result);
    } catch (error) {
      setSearchContent([]);
    }
  };

  React.useEffect(() => {
    setContents(defaultValue);
    return () => {
      clearTimeout(timeout);
    };
  }, [defaultValue]);

  return (
    <div style={{ width: '100%' }}>
      <SearchField
        options={isSearchResult || []}
        name="searchContents"
        inputValue={searchText}
        labelText="Add an Item: Search for Instructor"
        onChange={(event, value) => handleSearchInputChange(value)}
        limitTags={2}
        onInputChange={(event, value) => onSearchChange(value)}
        getOptionLabel={option =>
          option.interactive && option.interactive.firstName
            ? `${option.interactive.firstName} ${option.interactive.lastName &&
                option.lastName}`
            : `${option.firstName} ${option.lastName && option.lastName}`
        }
        onSearchTextChange={e => setSearchText(e)}
        ref={inputRef}
        setInputRef={ref => setInputRef(ref)}
        blurOnSelect
        clearOnBlur
      />
      <ErrorText>
        <h2>{errorMessage()}</h2>
      </ErrorText>
      <Divider style={{ backgroundColor: '#999FA4', marginBottom: 8 }} />
      <DraggableListInstructor
        list={contents}
        onListChange={(list, updateType) => {
          setContents(list);
          onChange(list);
        }}
        startDate={''}
        subTitle={subTitle}
      />
    </div>
  );
};
