import { takeLatest, call, put, select } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import { Convert, HomeCurate } from './types';
import {
  storeHomeCurateInfo,
  requestHomeCurateInfo,
  setLoader,
} from './actions';
import { AnyAction } from 'redux';
import { openSnackBar } from '../Root/actions';
import makeSelectHomeCurate from './selectors';

// import { take, call, put, select } from 'redux-saga/effects';

// Individual exports for testing
export default function* homeCurateSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_HOME_PAGE_INFO, fetchHomePageInfo);
  yield takeLatest(ActionTypes.REORDER_TRAYS, reorderLowerSectionTray);
  yield takeLatest(ActionTypes.UPDATE_HOME_COMPONENT, updateHomeComponent);
  yield takeLatest(ActionTypes.DELETE_HOME_COMPONENT, deleteHomeComponent);
}

export function* fetchHomePageInfo(action: AnyAction) {
  let segmentFilter = action.payload || 'No Fitness Profile';
  let { segments } = yield select(makeSelectHomeCurate());
  let selectedFilter = segments.filter(s => s.title === segmentFilter);
  let params = {};
  if (selectedFilter.length > 0) {
    params.segment = selectedFilter[0].id;
  }
  try {
    let { result } = yield call(request, {
      url: `neouadmin/v2/pages/home`,
      method: 'GET',
      params: params,
    });
    // let homeCurateInfo: HomeCurate = Convert.toHomeCurate(result);
    let homeCurateInfo: HomeCurate = result;
    yield put(storeHomeCurateInfo(homeCurateInfo));
  } catch (error) {
    yield put(setLoader(false));
    if (error && error.data && error.data.responseCode === 404) {
    }
  }
}

export function* reorderLowerSectionTray({ payload }: AnyAction) {
  let { list } = payload;
  let requestbody = {
    components: list.map(item => ({
      componentTitle: item.componentTitle,
      componentOrder: item.componentOrder,
      componentId: item.componentId,
    })),
    pageName: 'home',
  };

  try {
    let { result } = yield call(request, {
      url: `neouadmin/v2/reorder-components/`,
      method: 'POST',
      data: requestbody,
    });
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
    }
  }
}

export function* updateHomeComponent({ payload }: AnyAction) {
  let { componentInfo, type } = payload;

  try {
    yield put(setLoader(true));
    let { result } = yield call(request, {
      url: 'neouadmin/v2/pages/',
      method: type === 'ADD' ? 'POST' : 'PUT',
      data: componentInfo,
    });
    yield put(setLoader(false));
    yield put(openSnackBar('Component has been updated successfully!'));
    yield put(requestHomeCurateInfo());
  } catch ({ data: error }) {
    let errorMsg = "Couldn't save the information. Try again later!";
    if (error && error.error) {
      errorMsg = error.error;
    }
    yield put(openSnackBar(errorMsg));
  }
}

export function* deleteHomeComponent({ payload }: AnyAction) {
  let { componentInfo } = payload;
  try {
    let { result } = yield call(request, {
      url: '/neouadmin/v2/pages/',
      method: 'PUT',
      data: componentInfo,
    });
    yield put(openSnackBar('Component has been deleted successfully!'));
    yield put(requestHomeCurateInfo());
  } catch (error) {
    yield put(openSnackBar("Couldn't delete the component. Try again later!"));
  }
}
