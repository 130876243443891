/*
 *
 * BrowseCurate reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  concepts: {
    topSection: [],
    lowerSection: [],
  },
  collections: {
    topSection: [],
    lowerSection: [],
  },
  collectionsV2: {
    topSection: [],
    lowerSection: [],
  },
  program: {
    topSection: [],
    lowerSection: [],
  },
  challenge: {
    topSection: [],
    lowerSection: [],
  },
  segments: [],
  isLoading: false,
};

function browseCurateReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.STORE_BROWSE_PAGE:
      let {
        concepts,
        collections,
        collectionsV2,
        program,
        challenge,
        segments,
      } = action.payload;
      return {
        ...state,
        concepts: {
          topSection: concepts.topSection || [],
          lowerSection: concepts.lowerSection || [],
        },
        collections: {
          topSection: collections.topSection || [],
          lowerSection: collections.lowerSection || [],
        },
        collectionsV2: {
          topSection: collectionsV2.topSection || [],
          lowerSection: collectionsV2.lowerSection || [],
        },
        program: {
          topSection: program.topSection || [],
          lowerSection: program.lowerSection || [],
        },
        challenge: {
          topSection: challenge.topSection || [],
          lowerSection: challenge.lowerSection || [],
        },
        segments,
      };
    case ActionTypes.SET_LOADER:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export default browseCurateReducer;
