import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import {
  attributetListSuccess,
  attributeListFailed,
  getAttributeDetail,
  getAttributeDetailSuccess,
  setAttributeLoader,
  saveAttribute,
  removeAttribute,
  requestAttributes,
} from './actions';
import { selectAttributeDomain } from './selectors';
import { AnyAction } from 'redux';
import { openSnackBar } from '../Root/actions';

//{{domain}}/neouadmin/v1/attributes?sortBy=createdAt&sortOrder=asc

// Individual exports for testing
export default function* attributeSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_ATTRIBUTE_LIST, getAttributeList);
  yield takeLatest(ActionTypes.GET_SEARCH_RESULT, getSearchResultApi);
  yield takeLatest(ActionTypes.FETCH_ATTRIBUTE_BY_ID, fetchAttributeById);
  yield takeLatest(ActionTypes.SAVE_ATTRIBUTE_DETAIL, editSaveAttribute);
  yield takeLatest(ActionTypes.REMOVE_ATTRIBUTE_DETAIL, deleteAttribute);
}

export function* getAttributeList() {
  try {
    let { sortBy } = yield select(selectAttributeDomain);
    if (sortBy === '') {
      sortBy = 'updatedAt=desc';
    }
    const splitResult = sortBy.split('=');

    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/attributes?sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}`,
        method: 'GET',
      }),
    );
    yield put(attributetListSuccess(result));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(attributetListSuccess([]));
    }
    yield put(attributeListFailed());
  }
}

export function* getSearchResultApi(action: AnyAction) {
  let { sortBy } = yield select(selectAttributeDomain);
  if (sortBy === '') {
    sortBy = 'updatedAt=desc';
  }
  const splitResult = sortBy.split('=');
  let key = action.payload;
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/attributes?keyword=${key}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}`,
        method: 'GET',
      }),
    );
    yield put(attributetListSuccess(result));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(attributetListSuccess([]));
    }
    yield put(attributeListFailed());
  }
}

export function* fetchAttributeById(action: AnyAction) {
  let id = action.payload;
  yield put(setAttributeLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/attributes`,
        params: {
          id,
        },
        method: 'GET',
      }),
    );

    yield put(setAttributeLoader(false));
    yield put(getAttributeDetailSuccess(result));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(attributetListSuccess([]));
    }
    yield put(attributeListFailed());
  }
}

export function* editSaveAttribute(action: AnyAction) {
  try {
    const {
      id,
      title,
      type,
      isVisible,
      isPublished,
      imageUrl,
    } = action.payload;
    if (action.payload.isAdd) {
      const { result } = yield call(() =>
        request({
          url: `/neouadmin/v1/attributes`,
          method: 'POST',
          data: { title, type, isVisible, isPublished, imageUrl },
        }),
      );

      yield put(setAttributeLoader(false));
      yield put(requestAttributes());
    } else {
      const { result } = yield call(() =>
        request({
          url: `/neouadmin/v1/attributes`,
          method: 'PUT',
          data: { id, title, type, isVisible, isPublished, imageUrl },
        }),
      );

      yield put(setAttributeLoader(false));
      yield put(requestAttributes());
      yield put(getAttributeDetail(id));
    }
  } catch (error) {
    yield put(setAttributeLoader(false));
    yield put(attributeListFailed());
  }
}

export function* deleteAttribute(action) {
  let { id, type } = action.payload;

  yield put(setAttributeLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/delete-attributes`,
        data: { id, type },
        method: 'POST',
      }),
    );
    yield put(setAttributeLoader(false));
    yield put(requestAttributes());
  } catch (error) {
    yield put(setAttributeLoader(false));
    yield put(openSnackBar('Failed to delete attribute'));
  }
}
