import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the People state domain
 */

const selectPeopleDomain = (state: ApplicationRootState) => {
  return state.people || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by People
 */

const makeSelectPeople = () =>
  createSelector(
    selectPeopleDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectPeople;
export { selectPeopleDomain };
