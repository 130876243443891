import { createSelector } from 'reselect';
import { ApplicationRootState } from '../../types';
import { initialState } from './reducer';

/**
 * Direct selector to the Attribute state domain
 */

const selectAttributeDomain = (state: ApplicationRootState) => {
  return state.attribute || initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Attribute
 */

const makeSelectAttribute = () =>
  createSelector(
    selectAttributeDomain,
    substate => {
      return substate;
    },
  );

export default makeSelectAttribute;
export { selectAttributeDomain };
