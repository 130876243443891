/*
 *
 * BrandVoice actions
 *
 */

import { action } from 'typesafe-actions';
import { BrandVoice } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const requestbrandVoice = () => {
  return action(ActionTypes.REQUEST_BRAND_VOICE);
};
export const brandVoiceListSuccess = (brandVoiceData: BrandVoice) => {
  return action(ActionTypes.GET_BRAND_VOICE_SUCCESS, brandVoiceData);
};
export const brandVoiceListFailure = () => {
  return action(ActionTypes.GET_BRAND_VOICE_FAILURE);
};

export const updateBrandVoice = brandVoiceData => {
  return action(ActionTypes.UPDATE_BRAND_VOICE, brandVoiceData);
};
