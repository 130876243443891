/*
 *
 * BrowseCurate actions
 *
 */

import { action } from 'typesafe-actions';
import { LowerSection } from './types';

import ActionTypes from './constants';

export const setLoader = (isLoading: boolean) =>
  action(ActionTypes.SET_LOADER, isLoading);

export const requestBrowsePageInfo = () =>
  action(ActionTypes.REQUEST_BROWSE_PAGE_INFO);

export const storeBrowsePage = ({
  concepts,
  collections,
  collectionsV2,
  program,
  challenge,
  segments = [],
}) =>
  action(ActionTypes.STORE_BROWSE_PAGE, {
    concepts,
    collections,
    collectionsV2,
    program,
    challenge,
    segments,
  });

export const reorderLowerSectionTrays = (list: LowerSection[]) =>
  action(ActionTypes.REORDER_LOWER_SECTION_TRAYS, list);

export const updateBrowseComponent = (component: LowerSection, type) =>
  action(ActionTypes.UPDATE_BROWSE_COMPONENT, { component, type });
