/*
 *
 * FitnessGoals reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  default: null,
  fitnessList: [],
  isLoading: false,
  fitnessDetailnfo: {},
};

function fitnessGoalsReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.REQUEST_FITNESS_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.GET_FITNESS_LIST:
      return {
        ...state,
        fitnessList: action.payload,
        isLoading: false,
      };
    case ActionTypes.FETCH_FITNESS_BY_ID:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_FITNESS_DETAIL_INFO:
      return {
        ...state,
        fitnessDetailnfo: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default fitnessGoalsReducer;
