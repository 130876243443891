/*
 *
 * Root constants
 *
 */

enum ActionTypes {
  FETCH_API_TOKEN = 'app/Root/FETCH_API_TOKEN',
  REQUEST_AGE_GROUP = 'app/Root/REQUEST_AGE_GROUP',
  FETCH_AGE_GROUP_SUCCESS = 'app/Root/FETCH_AGE_GROUP_SUCCESS',
  STORE_USER_INFO = 'app/Root/STORE_USER_INFO',
  REMOVE_USER_INFO = 'app/Root/REMOVE_USER_INFO',
  TOGGLE_AUTH_MODAL = 'app/Root/TOGGLE_AUTH_MODAL',
  STORE_USER_NAME = 'STORE_USER_NAME',
  OPEN_SNACK_BAR = 'OPEN_SNACK_BAR',
  CLOSE_SNACK_BAR = 'CLOSE_SNACK_BAR',
  OPEN_ALERT_MODAL = 'OPEN_ALERT_MODAL',
  CLOSE_ALERT_MODAL = 'CLOSE_ALERT_MODAL',
}

export default ActionTypes;
