import * as React from 'react';
import {
  createStyles,
  withStyles,
  TextFieldProps,
  OutlinedTextFieldProps,
} from '@material-ui/core';
import { Field, ErrorMessage } from 'formik';
import { NEInputLight } from '../NEInput';

interface FormikFieldProps extends TextFieldProps<OutlinedTextFieldProps> {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  helperText?: string;
  multiline?: boolean;
  required?: boolean;
  rows?: any;
}

const styles = () =>
  createStyles({
    wrapper: {
      width: '100%',
      marginBottom: '8px',
    },
  });

const FormikField: React.FC<FormikFieldProps> = ({
  classes,
  label,
  name,
  placeholder,
  type = 'text',
  multiline,
  required,
  focused,
  onBlur,
  disabled,
  rows = 4,
}) => {
  const errorText = <ErrorMessage name={name} />;
  return (
    <div className={classes.wrapper}>
      <Field name={name}>
        {({ field, form, meta }) => (
          <NEInputLight
            autoComplete="off"
            label={label}
            rows={rows}
            placeholder={placeholder}
            type={type}
            variant="outlined"
            fullWidth
            multiline={multiline}
            required={required}
            focused={focused}
            disabled={disabled}
            {...field}
            error={Boolean(
              meta.touched && meta.error && <ErrorMessage name={name} />,
            )}
            helperText={
              meta.touched && meta.error && <ErrorMessage name={name} />
            }
            onBlur={onBlur}
          />
        )}
      </Field>
    </div>
  );
};

export default withStyles(styles)(FormikField);
