import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
  Box,
  createStyles,
  makeStyles,
  Theme,
  FormControlLabel,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { number } from 'prop-types';
import { date } from 'yup';
import { push } from 'connected-react-router';
import { ClassSchema } from '../InteractivePublishedClasses/components/ClassSchema';
import FormikField from '../../components/Formik/FormikField';
import FormikSelect from '../../components/Formik/FormikSelect';
import FormikImageUploader from '../../components/Formik/FormikImageUploader';
import { HistoryText } from '../../components/Typography';
import { slugify } from '../../utils/slugify';
import FormikAutocomplete from '../../components/Formik/FormikAutocomplete';
import ConfirmDialog from '../../components/ConfirmDialog';
import { openSnackBar } from '../Root/actions';
import request from '../../shared/lib/request';
import Loader from '../../components/Loader';
import { NEOUS_APP_URL, appApiURL } from '../../utils/config';
import StartDateTime from '../../components/Formik/StartDateTime';
import ShareURL from '../InteractivePublishedClasses/components/shareURL';
import { GreenCheckbox } from '../../components/GreenCheckbox';

const dialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkBox: {
      paddingBottom: '15px',
    },
    viewPackages: {
      textTransform: 'capitalize',
      color: '#12161A',
      marginTop: '10px',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 10px',
      height: '24px',
    },
    privateMessage: {
      color: '#909599',
      letterSpacing: '1px',
      fontSize: '14px',
      fontFamily: 'Proxima-Nova-Regular',
      marginBottom: '10px',
    },
    classURLGrid: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    message: {
      fontSize: '16px',
      fontStyle: 'italic',
      color: '#A1A6A9',
      textAlign: 'center',
      background: '#F8F9FA',
      padding: '25px 0px',
    },
    startMessage: {
      fontSize: '16px',
      fontStyle: 'italic',
      color: '#10171B',
      textAlign: 'center',
      background: '#DDFDFE',
      padding: '25px 0px',
    },
    btnWrapper: {
      marginBottom: '-8px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '16px',
      paddingBottom: '26px',
      borderTop: '1px solid #E3E3E4',
      position: 'sticky',
      bottom: '0',
      background: '#fff',
      zIndex: 1,
      '&:before': {
        position: 'absolute',
        content: ' ',
        height: '1px',
        background: '#D9D9D9',
        top: '0px',
        left: '-16px',
        right: '-16px',
      },
    },
    dialogContainer: {
      borderRadius: 0,
      maxWidth: '1080px',
    },
    imageContainer: {
      maxWidth: '252px',
      margin: '0px 16px',
    },
    videoHeader: {},
    classWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    form: {
      // maxWidth: '33.33%',
    },
    dialogContent: {
      padding: '24px 16px 72px',
    },
    mr6: {
      marginRight: '6px',
    },
    ml6: {
      marginLeft: '6px',
    },
    dialogActions: {
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      background: '#fff',
      border: '1px solid #D9D9D9',
      margin: '0px -16px',
      justifyContent: 'space-between',
      display: 'flex',
      zIndex: 140000,
    },
    videoHeaderContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
    },
    wrapGrid: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    wrapButton: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'auto',
      '& .MuiButton-textSizeLarge': {
        minWidth: '140px!important',
      },
    },
    publish: {
      marginRight: '5px',
    },
    cancelButton: {
      background: '#D0021B',
      color: '#FFFFFF',
      '&:hover': {
        background: '#D0021B',
        color: '#FFFFFF',
      },
    },
    startButton: {
      width: '503px',
      background: '#56FFFE',
      color: '#10171B',
      '&:hover': {
        background: '#56FFFE',
        color: '#10171B',
      },
      '&:focus': {
        background: '#56FFFE',
        color: '#10171B',
      },
    },
    submitButton: { width: '503px' },
    disabled: {
      fontFamily: 'ProximaNova-Bold',
      cursor: 'pointer',
      color: '#12161A',
      margin: '0px 4px',
      minHeight: '40px',
      lineHeight: '24px',
      borderRadius: '6px',
      letterSpacing: '1.15px',
      textTransform: 'capitalize',
      backgroundColor: 'rgba(0, 0, 0, 0.38)',
    },
  }),
);

const initialFormValues = {
  videoStatus: 'active',
  instructors: [],
  genres: [],
  bodyFocus: [],
  music: [],
  adminTags: [],
  likes: 0,
  title: '',
  interactive: {
    type: '2-way video',
    spots: number,
    spotsLeft: number,
    isCancelled: false,
    bannerImg: '',
    inReview: false,
    flagged: false,
    dropInPrice: '',
    isPrivate: false,
    restrictedToEmails: '',
  },
  description: '',
  classURL: `${NEOUS_APP_URL}/neou/neous/classes/classes/`,
  videoType: 'class',
  concept: '',
  classPlan: '',
  thumbnail: '',
  permaLink: '/classes/',
  fileName: '',
  liveStreamUrl: '',
  duration: '',
  difficulty: 'Beginner',
  equipments: {
    optional: [],
    required: [],
  },
  flag: '',
  isPublished: false,
  dataURL: '',
  explicitLang: false,
  isHidden: false,
  platform: 'zoom',
  isNewClassNotification: false,
  enterUrl: '',
  style: '',
  impact: '',
  contentId: '',
  startDate: date,
  airedDate: date,
};

const initialPossibleOptions = {
  concepts: [],
  interactiveInstructors: [],
  genres: [],
  bodyFocus: [],
  equipments: [],
  musics: [],
  flags: [{ label: 'None', value: 'None' }],
  impact: [{ label: 'low', value: 'low' }],
  videoType: [],
  style: [],
  difficulty: [],
  platform: [],
  duration: [],
  numberValues: [],
  interactiveVideoType: [
    { value: 'groupInteractive', label: 'Group Interactive' },
  ],
  airedDate: moment().format('YYYY-MM-DDTH, H:mm'),
};

const initialClassData = {
  isStarted: false,
  isEnded: false,
  end_time: '',
  time_zone: '',
  isPublished: false,
  isFlagged: false,
  isReviewed: false,
};

export default function ClassDetailForm(props) {
  const { videoDetail, clients } = props;
  const [isLoading, setLoader] = React.useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [classData, setClassData] = useState(initialClassData);
  const [isPrivateClassAlertOpen, showAlertPrivateClass] = React.useState(
    false,
  );
  const [possibleOptions, setPossibleOptions] = useState(
    initialPossibleOptions,
  );
  const [isConfirmPublishOpen, openConfirmPublishDialog] = React.useState(
    false,
  );
  const [isStartClass, setStartClass] = React.useState(false);
  const [isCancelAlertOpen, showAlertToCancel] = React.useState(false);
  const [savedFormData, setSavedFormData] = React.useState({});
  const [isPublish, setPublish] = React.useState(false);
  const [isDraft, setDraft] = React.useState(false);
  const [inReview, setInReview] = React.useState(false);
  const [classId, setClassId] = React.useState('');
  const [clientList, setClientList] = React.useState(false);

  const classes = dialogStyles();
  const dispatch = useDispatch();

  const _formInitialValues = (possibleValues, result) => {
    const {
      style = [],
      duration = [],
      difficulty = [],
      platform = [],
    } = possibleValues;
    const possibleFlags =
      possibleValues.flags && possibleValues.flags.length > 0
        ? possibleValues.flags.map(flag => ({
            label: flag.title,
            value: flag.title,
          }))
        : [];
    const flags = [{ label: 'None', value: 'None' }, ...possibleFlags];
    const possibleOptions = {
      concepts: possibleValues.concepts || [],
      interactiveInstructors: possibleValues.interactiveInstructors
        ? possibleValues.interactiveInstructors.map(i => ({
            id: i.id,
            title: `${i.firstName} ${i.lastName}`,
          }))
        : [],
      genres: possibleValues.genres || [],
      bodyFocus: possibleValues.bodyFocus || [],
      equipments: possibleValues.Equipments || [],
      musics: possibleValues.musics || [],
      flags,
      impact:
        possibleValues.impact && possibleValues.impact.length > 0
          ? possibleValues.impact.map(i => ({
              label: i.title,
              value: i.title,
            }))
          : [],

      videoType: [{ value: 'groupInteractive', label: 'Group Interactive' }],
      style:
        style && style.length > 0
          ? style.map(v => ({ label: v.title, value: v.title }))
          : [],
      difficulty:
        difficulty && difficulty.length > 0
          ? difficulty.map(v => ({ label: v.title, value: v.title }))
          : [],
      platform: [
        { label: 'Default (Zoom)', value: 'zoom' },
        //{ label: 'Signalwire', value: 'signalwire' },
      ],
      duration:
        duration && duration.length > 0
          ? duration.map(d => ({ label: d.title, value: d.title }))
          : [],
      numberValues: [],
    };
    for (let i = 0; i < 6; i++) {
      possibleOptions.numberValues.push({ label: i, value: i });
    }
    const { permaLink, startDate } = result;
    if (startDate) {
      result.airedDate = moment(+startDate).format('YYYY-MM-DDTHH:mm');
    }
    const videoInfo = {
      ...result,
      flag: !result.flag ? 'None' : result.flag,
      interactive: {
        dropInPrice: `$${
          result.interactive && result.interactive.dropInPrice
            ? `${result.interactive.dropInPrice
                .toString()
                .slice(
                  0,
                  -2,
                )}.${result.interactive.dropInPrice.toString().substr(-2)}`
            : `0.00`
        }`,
        spots: result.interactive.spots,
        bannerImg: result.interactive.bannerImg,
        spotsLeft: result.interactive.spotsLeft,
        isCancelled: result.interactive.isCancelled,
        isPrivate:
          result.interactive && result.interactive.isPrivate
            ? result.interactive.isPrivate
            : false,
        restrictedToEmails:
          result.interactive && result.interactive.restrictedToEmails
            ? result.interactive.restrictedToEmails
            : '',
      },
      platform:
        result.interactive && result.interactive.meetingType
          ? result.interactive.meetingType
          : 'zoom',
      enterUrl: result.interactive && result.interactive.hostURL,
      isPublished: result.isPublished,
      videoType: 'groupInteractive',
      classURL:
        permaLink && permaLink.includes('http')
          ? `${NEOUS_APP_URL}/neou/neous/classes/classes/`
          : `${NEOUS_APP_URL}/neou/neous/classes${permaLink}`,
      permaLink:
        permaLink && permaLink.includes('http') ? '/classes/' : permaLink,
      title: result.title && result.title.includes('http') ? '' : result.title,
      instructors: result.instructors
        ? result.instructors.map(i => ({
            id: i.id,
            title: `${i.firstName} ${i.lastName}`,
          }))
        : [],
      equipments: {
        required:
          result.equipments &&
          result.equipments.required &&
          result.equipments.required.length > 0
            ? result.equipments.required
            : [],
        optional:
          result.equipments &&
          result.equipments.optional &&
          result.equipments.optional.length > 0
            ? result.equipments.optional
            : [],
      },
    };
    return { possibleOptions, videoInfo };
  };

  const _formRequestBody = formData => {
    if (formData.title != '' && formData.permaLink == '/classes/') {
      formData.permaLink = `/classes/${formData.title}`;
      formData.classURL = `${NEOUS_APP_URL}/neou/neous/classes/classes/${formData.title}`;
    }
    if (formData.airedDate) {
      if (isPublish || inReview || formData.isPublished) {
        let currentTime = moment(Date.now());
        let startTime = moment(formData.airedDate);
        let isStarted = startTime.diff(currentTime) >= 30000;
        if (!isStarted) {
          dispatch(openSnackBar('Interactive class should have future time'));
          return;
        }
      }
      formData.startDate = moment(formData.airedDate)
        .utc()
        .valueOf()
        .toString();
    }

    const data = {
      ...formData,
      contentId: formData.id,
      duration: formData.duration,
      videoType: 'groupInteractive',
      interactive: {
        type: '2-way video',
        dropInPrice: parseInt(
          formData.interactive.dropInPrice.replace(/[^0-9 ]/g, ''),
        ),
        spots: parseInt(formData.interactive.spots),
        isCancelled: formData.interactive.isCancelled,
        bannerImg: formData.interactive.bannerImg,
        isPrivate: formData.interactive.isPrivate,
        restrictedToEmails: formData.interactive.restrictedToEmails,
        meetingType: formData.platform,
        hostURL: formData.enterUrl,
      },
      flag: formData.flag === 'None' ? '' : formData.flag,
      instructors: formData.instructors.map(i => i.id),
      genres: formData.genres.map(i => i.id),
      bodyFocus: formData.bodyFocus.map(i => i.id),
      music: formData.music.map(i => i.id),
      concept: (formData.concept && formData.concept.id) || '',
      equipments: {
        required:
          formData.equipments && formData.equipments.required
            ? formData.equipments.required.map(i => i.id)
            : [],
        optional:
          formData.equipments && formData.equipments.optional
            ? formData.equipments.optional.map(i => i.id)
            : [],
      },
    };
    delete formData.fileName;
    if (!formData.style) {
      delete formData.style;
    }
    return data;
  };

  const saveVideo = async formData => {
    try {
      const data = formData || savedFormData;
      delete data.interactive.hostURL;
      delete data.platform;
      delete data.enterUrl;
      delete data.classURL;
      await request({
        url: '/neouadmin/v1/videos',
        method: 'PUT',
        data: formData || savedFormData,
      });
      setLoader(false);
      dispatch(openSnackBar('The class has been saved successfully!'));
      dispatch(push('/neou/instructor-add-classes'));
    } catch ({ data: error }) {
      setLoader(false);
      if (error.error) {
        dispatch(openSnackBar(error.error));
      } else {
        dispatch('Error in updating the class..');
      }
    }
  };

  const _init = () => {
    try {
      if (Object.keys(videoDetail).length) {
        const { responseCode, result, ...possibleValues } = videoDetail;
        const { possibleOptions, videoInfo } = _formInitialValues(
          possibleValues,
          result,
        );
        const startDate = moment(parseInt(result.startDate))
          .utc()
          .valueOf();
        setClassId(result.id);
        //const classStartTime = moment(new Date(startDate));
        const currentTime = moment(Date.now());
        //const canReview = classStartTime.diff(currentTime, 'hours') < 48;

        classData.isPublished = result.isPublished;
        if (classData.isPublished) {
          const startTime = moment(new Date(+parseInt(result.startDate)));
          classData.isStarted = startTime.diff(currentTime) <= 10 * 1000 * 60;
          const endTime = moment(new Date(+parseInt(result.startDate))).add(
            result.duration,
            'minutes',
          );
          classData.isEnded = endTime.diff(currentTime) <= 1 * 1000 * 60;

          classData.time_zone = getTimeWithZone(new Date(+result.startDate));
          classData.end_time = endTime.format('HH:mm A');
        }
        classData.isFlagged = result.interactive && result.interactive.flagged;
        classData.isReviewed =
          result.interactive && result.interactive.inReview;

        setClassData(classData);

        // if (canReview) {
        //   setReview(true);
        // }
        setFormValues({ ...initialFormValues, ...videoInfo });
        setPossibleOptions({ ...initialPossibleOptions, ...possibleOptions });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(openSnackBar('Error in fetching the video details'));
    }
  };

  const getTimeWithZone = date => {
    const moment = require('moment-timezone');
    let dt: any = DateTime.local();
    dt = dt.zoneName;
    moment.tz.setDefault(dt);
    const tzTime = moment(new Date(date));

    return moment(tzTime, dt).format('z');
  };

  const _handleSubmit = values => {
    const body = _formRequestBody(values);
    body.interactive = body.interactive || {};
    body.interactive.inReview = Boolean(true);
    body.isHidden = Boolean(body.isHidden);
    body.interactive.isCancelled = Boolean(body.interactive.isCancelled);
    if (isDraft) {
      body.interactive.inReview = Boolean(false);
      body.interactive.flagged = Boolean(false);
    } else if (inReview) {
      body.interactive.inReview = Boolean(true);
      body.interactive.flagged = Boolean(false);
    } else if (isPublish) {
      body.isPublished = false;
      body.interactive.inReview = Boolean(true);
    }
    setSavedFormData({ ...body });
    if (isPublish) {
      openConfirmPublishDialog(true);
    } else {
      saveVideo(body);
    }
  };

  const cancelClass = async () => {
    setLoader(true);
    try {
      await request({
        url: `/neouadmin/v1/cancel-class?videoId=${classId}`,
        method: 'GET',
      });
      setLoader(false);

      dispatch(
        openSnackBar(
          'The Class has been cancelled and users refunded successfully!',
        ),
      );
    } catch ({ data: error }) {
      setLoader(false);
      if (error && error.error && error.error !== {}) {
        dispatch(
          openSnackBar(
            error.error.message
              ? error.error.message
              : 'Error in cancelling the class..',
          ),
        );
      } else {
        dispatch(openSnackBar('Error in cancelling the class..'));
      }
    } finally {
      dispatch(push('/neou/my-classes'));
    }
  };

  const renderInfoMessage = values => {
    if (!classData.isPublished) return;
    if (classData.isStarted) {
      if (classData.isEnded) {
        return (
          <div className={classes.message}>
            This class has ended and removed from views at{' '}
            {moment(classData.end_time, 'HH:mm:ss').format('h:mm a')}{' '}
            {classData.time_zone}
          </div>
        );
      }
      return (
        <div className={classes.startMessage}>
          Click "Admit participants" to enter preview mode
        </div>
      );
    }
    if (values.interactive.isCancelled) {
      return (
        <div className={classes.message}>
          Class has been cancelled by the instructor and booked users are
          refunded
        </div>
      );
    }
    if (
      values.interactive &&
      values.interactive.spots - values.interactive.spotsLeft === 0
    ) {
      return (
        <div className={classes.message}>
          This class has been published. You can make edits while you have 0
          bookings.{' '}
        </div>
      );
    }
    return (
      <div className={classes.message}>
        Class has been published and is no longer editable.
      </div>
    );
  };

  const handleJoinClass = values => {
    if (values.platform === 'zoom' && values.enterUrl) {
      window.open(values.enterUrl);
      values.platform === 'zoom' &&
        request({
          url: `${appApiURL}/neou/v1/update-class-started`,
          method: 'POST',
          data: {
            videoId: values.id,
          },
        })
          .then(res => {
            console.log('success');
          })
          .catch(err => {
            console.log('Player: Couldn’t start the class' + err);
          });
      setStartClass(false);
    } else {
      window.open(`${NEOUS_APP_URL}/preview/${classId}`);
      setStartClass(false);
    }
  };

  useEffect(() => {
    _init();
  }, [videoDetail]);

  useEffect(() => {
    if (clients !== [] && clients.length > 0) {
      setClientList(true);
    }
  }, [clients]);
  return (
    <>
      {isLoading && <Loader />}
      {props.videoDetail && props.videoDetail !== {} && (
        <Formik
          initialValues={formValues}
          enableReinitialize
          validationSchema={ClassSchema}
          onSubmit={_handleSubmit}
          validator={() => ({})}
        >
          {({
            values,
            setFieldValue,
            submitForm,
            errors,
            isValid,
            setErrors,
            validateForm,
            setTouched,
            handleSubmit,
          }) => (
            <>
              {renderInfoMessage(values)}

              <Form onSubmit={handleSubmit}>
                <Grid
                  container
                  alignItems="flex-start"
                  spacing={2}
                  style={{ padding: '16px 0px', marginBottom: '75px' }}
                >
                  <Grid item xs={3} container>
                    {!values.isPublished && (
                      <Grid item xs container className={classes.checkBox}>
                        <FormControlLabel
                          classes={{
                            root: classes.customStyle,
                          }}
                          control={
                            <GreenCheckbox
                              name="interactive.isPrivate"
                              checked={values.interactive.isPrivate}
                              onChange={() => {
                                showAlertPrivateClass(true);
                              }}
                            />
                          }
                          label={
                            <Typography className={classes.formControlLabel}>
                              Private Class
                            </Typography>
                          }
                          labelPlacement="end"
                        />
                        <div className={classes.privateMessage}>
                          Private classes are not visible on the NEO Us Schedule
                          and only accessible to people who have the link.
                        </div>
                      </Grid>
                    )}
                    <FormikField
                      name="interactive.dropInPrice"
                      label="Drop In Price"
                      required
                      placeholder="Enter drop In Price"
                      disabled={true}
                      onBlur={() => {
                        setFieldValue(
                          'interactive.dropInPrice',
                          '$' +
                            slugify(values.interactive.dropInPrice / 100) +
                            '.00',
                        );
                      }}
                    ></FormikField>

                    <StartDateTime
                      type="Edit"
                      date={values.airedDate}
                      selectedValue={setFieldValue}
                    />
                    <FormikSelect
                      items={possibleOptions.duration.sort(
                        (a, b) => a.value - b.value,
                      )}
                      name="duration"
                      label="Duration *"
                    />

                    <FormikSelect
                      items={possibleOptions.difficulty}
                      name="difficulty"
                      label="Difficulty"
                    />

                    <FormikField
                      name="interactive.spots"
                      label="Maximum Class Size"
                      required
                      disabled={values.isPublished}
                      placeholder="Enter Maximum Class size"
                    ></FormikField>

                    <FormikImageUploader
                      name="interactive.bannerImg"
                      aspectRatio="16x9"
                      key="16x9"
                      title="16:9 Banner Override"
                      defaultValue={
                        values.interactive && values.interactive.bannerImg
                      }
                    />

                    <Grid item>
                      <Typography variant="h6">HISTORY</Typography>
                      <HistoryText>
                        Added: {moment(values.createdAt).format('MM/DD/YYYY')}
                      </HistoryText>
                      {values.publishedDate && (
                        <HistoryText>
                          Published:
                          {moment(values.publishedDate).format('MM/DD/YYYY')}
                        </HistoryText>
                      )}
                      <HistoryText>
                        Updated: {moment(values.updatedAt).format('MM/DD/YYYY')}
                      </HistoryText>
                    </Grid>
                  </Grid>

                  <Grid item xs container>
                    <Grid item xs container>
                      <FormikField
                        name="title"
                        label="Title"
                        required
                        placeholder="Enter a title"
                        onBlur={() => {
                          setFieldValue(
                            'permaLink',
                            `/classes/${slugify(values.title)}` +
                              `/${values.id}`,
                          );
                          setFieldValue(
                            'classURL',
                            `${NEOUS_APP_URL}/neou/neous/classes/classes/` +
                              slugify(values.title) +
                              `/${values.id}`,
                          );
                        }}
                      ></FormikField>
                    </Grid>
                    <FormikField
                      name="permaLink"
                      required
                      label="Permalink"
                      placeholder="Interactive URL"
                    />
                    <div className={classes.classURLGrid}>
                      <div style={{ width: '90%' }}>
                        <FormikField
                          name="classURL"
                          required
                          label="Class URL"
                          placeholder="Interactive Class URL"
                        />
                      </div>
                      <ShareURL classURL={values.classURL} />
                    </div>

                    <div className={classes.wrapGrid}>
                      <Grid item xs container>
                        <FormikAutocomplete
                          name="instructors"
                          label="Interactive Instructors *"
                          placeholder="Add"
                          items={possibleOptions.interactiveInstructors}
                          multiple
                          getOptionLabel={option => `${option.title}`}
                        />
                      </Grid>
                      <Grid item xs container style={{ marginLeft: '15px' }}>
                        <FormikAutocomplete
                          items={possibleOptions.concepts}
                          name="concept"
                          multiple={false}
                          label="Interactive Concept *"
                        />
                      </Grid>
                    </div>

                    <FormikSelect
                      items={possibleOptions.platform}
                      name="platform"
                      label="Platform*"
                    />
                    {values.platform == 'zoom' && values.enterUrl && (
                      <FormikField
                        name="enterUrl"
                        label="Zoom url (host)"
                        placeholder="Zoom url"
                        disabled={true}
                        multiline
                      />
                    )}

                    {values.interactive && values.interactive.isPrivate && (
                      <FormikField
                        name="interactive.restrictedToEmails"
                        label="Restricted Emails or Domain"
                        placeholder="ex. @domain.com or john@gmail.com,bob@gmail.com"
                        multiline
                      />
                    )}
                    <FormikField
                      name="description"
                      label="Description"
                      placeholder="Enter a description"
                      multiline
                      rows="5"
                    />
                    <FormikField
                      name="classPlan"
                      label="Class Plan"
                      placeholder="Enter a class plan"
                      multiline
                      rows="5"
                    />
                    <FormikAutocomplete
                      name="genres"
                      label="Genres *"
                      placeholder="Add"
                      items={possibleOptions.genres}
                      multiple
                      getOptionLabel={option => `${option.title}`}
                    />

                    <FormikAutocomplete
                      name="bodyFocus"
                      label="Body Focus *"
                      placeholder="Add"
                      items={possibleOptions.bodyFocus}
                      multiple
                      getOptionLabel={option => `${option.title}`}
                    />

                    <div className={classes.wrapGrid}>
                      <Grid item xs container>
                        <FormikSelect
                          items={possibleOptions.impact}
                          name="impact"
                          label="Impact"
                        />
                      </Grid>
                      <Grid item xs container style={{ marginLeft: '15px' }}>
                        <FormikAutocomplete
                          items={possibleOptions.equipments}
                          name="equipments.optional"
                          label="Optional Equipment"
                          multiple
                        />
                      </Grid>
                    </div>
                    <div className={classes.wrapGrid}>
                      <Grid item xs container>
                        <FormikAutocomplete
                          items={possibleOptions.musics}
                          name="music"
                          label="Music *"
                          multiple
                        />
                      </Grid>
                      <Grid item xs container style={{ marginLeft: '15px' }}>
                        <FormikAutocomplete
                          items={possibleOptions.equipments}
                          name="equipments.required"
                          label="Required Equipment"
                          multiple
                        />
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.btnWrapper}>
                  <div className={classes.wrapButton}>
                    {classData.isPublished ? (
                      values.isPublished &&
                      !values.isHidden &&
                      values.interactive &&
                      !values.interactive.isCancelled && (
                        <>
                          <Button
                            className={classes.cancelButton}
                            size="large"
                            color="secondary"
                            onClick={() => showAlertToCancel(true)}
                          >
                            Cancel Class
                          </Button>
                          <ConfirmDialog
                            isOpen={isCancelAlertOpen}
                            headerText={
                              clientList
                                ? "Are you sure you'd like to cancel this class?"
                                : 'Are you sure?'
                            }
                            bodyText={
                              clientList
                                ? 'All participants will be notified and receive a full refund'
                                : 'You are about to cancel the classes'
                            }
                            onAgree={() => cancelClass()}
                            handleClose={() => showAlertToCancel(false)}
                            onDisagree={() => showAlertToCancel(false)}
                          ></ConfirmDialog>
                        </>
                      )
                    ) : (
                      <>
                        <Button
                          size="large"
                          color="secondary"
                          onClick={() => showAlertToCancel(true)}
                        >
                          Cancel
                        </Button>
                        <ConfirmDialog
                          isOpen={isCancelAlertOpen}
                          headerText="Are you sure?"
                          bodyText="You are about to close the classes"
                          onAgree={() =>
                            dispatch(push('/neou/instructor-add-classes'))
                          }
                          handleClose={() => showAlertToCancel(false)}
                          onDisagree={() => showAlertToCancel(false)}
                        ></ConfirmDialog>
                      </>
                    )}
                  </div>

                  <div className={classes.wrapButton}>
                    {classData.isStarted && classData.isPublished && (
                      <>
                        <Button
                          className={classes.startButton}
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={() => {
                            if (isValid) {
                              setStartClass(true);
                            }
                          }}
                        >
                          Admit participants
                        </Button>
                        <ConfirmDialog
                          isOpen={isStartClass}
                          headerText="Are you sure?"
                          bodyText={
                            values.platform === 'zoom'
                              ? 'You will be redirected into zoom meeting now'
                              : 'You are about to enter into preview screen now'
                          }
                          onAgree={e => handleJoinClass(values)}
                          handleClose={() => setStartClass(false)}
                          onDisagree={() => setStartClass(false)}
                        ></ConfirmDialog>
                      </>
                    )}
                    {classData.isPublished && !classData.isStarted && (
                      <>
                        {values.interactive &&
                          values.interactive.spots -
                            values.interactive.spotsLeft ===
                            0 &&
                          !values.interactive.isCancelled && (
                            <>
                              <Button
                                className={classes.submitButton}
                                variant="contained"
                                type="submit"
                                color="secondary"
                                size="large"
                                onClick={() => {
                                  if (isValid) {
                                    setPublish(true);
                                  }
                                }}
                              >
                                SAVE & RE-SUBMIT FOR REVIEW
                              </Button>
                              <ConfirmDialog
                                isOpen={isConfirmPublishOpen}
                                headerText="Are you sure?"
                                bodyText="Re-submitting will remove class from published schedule. Please allow upto 48hrs for review and publishing"
                                onAgree={e => {
                                  saveVideo();
                                }}
                                handleClose={() =>
                                  openConfirmPublishDialog(false)
                                }
                                onDisagree={() =>
                                  openConfirmPublishDialog(false)
                                }
                              ></ConfirmDialog>
                            </>
                          )}
                      </>
                    )}
                    <ConfirmDialog
                      isOpen={isPrivateClassAlertOpen}
                      headerText="Hide this class from your schedule?"
                      bodyText="Choose yes, if you do not want to appear on your public schedule. Hidden classes are only accessible to people who have link."
                      handleClose={() => showAlertPrivateClass(false)}
                      onAgree={() => {
                        setFieldValue('interactive.isPrivate', true);
                      }}
                      disagreeBtnText="NO"
                      onDisagree={() => {
                        setFieldValue('interactive.isPrivate', false);
                        showAlertPrivateClass(false);
                      }}
                    />
                    {!classData.isPublished && (
                      <div>
                        <Button
                          style={{ margin: '0px 10px' }}
                          variant="contained"
                          type="submit"
                          color="secondary"
                          size="large"
                          onClick={() => {
                            if (isValid) {
                              setDraft(true);
                            }
                          }}
                        >
                          {classData.isReviewed ? 'Unsubmit' : 'SAVE DRAFT'}
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          color="secondary"
                          size="large"
                          onClick={() => {
                            if (isValid) {
                              setInReview(true);
                            }
                          }}
                        >
                          {classData.isFlagged
                            ? 'RE-SUBMIT FOR REVIEW'
                            : 'SUBMIT FOR REVIEW'}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
}
