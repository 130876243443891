/*
 *
 * Legal actions
 *
 */

import { action } from 'typesafe-actions';
import { LegalList } from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

//Get Legal list
export const requestLegal = () => {
  return action(ActionTypes.REQUEST_LEGAL_LIST);
};

export const legalListSuccess = (legalList: LegalList) => {
  return action(ActionTypes.LEGAL_LIST_SUCCESS, legalList);
};
export const setTabChange = tab => {
  return action(ActionTypes.SET_ACTIVE_TAB, tab);
};
export const storeLegalPage = legalPage => {
  return action(ActionTypes.STORE_LEGAL_PAGE, legalPage);
};
export const legalListFailed = () => {
  return action(ActionTypes.LEGAL_LIST_FAILED);
};
