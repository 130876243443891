import React from 'react';
import Accordian from '../../../components/Accordian';
import DayCalender from '../../ProgramDetail/DayCalenderBtn';
import { FindVideo } from '../../../components/FindVideo';
import { Grid, Button, Box } from '@material-ui/core';
import ThreeDotMenu from '../../../components/ThreeDotMenu';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { getUniqItems } from '../../../utils/array';

interface Video {
  id: string;
  title: string;
}

type Weeks = {
  [key in string]: {
    [key in string]: Video[];
  };
};

interface CollectionWeek {
  weeks: Weeks;
  onChange: (Weeks) => void;
}

const CollectionWeek: React.FC<CollectionWeek> = ({ weeks = {}, onChange }) => {
  var defaultWeek = {};

  for (var i = 1; i <= 7; i++) {
    defaultWeek[`day_${i}`] = [];
  }

  const [weekGroup, setWeekGroup] = React.useState({});
  const [activeDays, setActiveDays] = React.useState({});
  const [deleteAlertInfo, setDeleteAlertInfo] = React.useState({
    isOpen: false,
    weekIdentifier: '',
  });
  const [weekExpandState, setWeekExpandState] = React.useState({});

  React.useEffect(() => {
    let activeDays = {};
    if (weekGroup && Object.keys(weekGroup).length === 0) {
      // weekGroup = {};
      Object.keys(weeks).map((weekKey, index) => {
        activeDays[weekKey] = 'day_1';
        weekGroup[weekKey] = { ...weeks[weekKey] };
        weekExpandState[weekKey] = index === 0;
      });

      setActiveDays({ ...activeDays });
      setWeekGroup(weekGroup);
    }
  }, [weeks]);

  React.useEffect(() => {
    // alert('changed...');
  }, [weekGroup]);

  const shiftWeeks = () => {
    let newWeekGroup = {};
    Object.keys(weekGroup).map((weekKey, index) => {
      newWeekGroup[`week_${index + 1}`] = {};
      activeDays[`week_${index + 1}`] = 'day_1';
      Object.keys(weekGroup[weekKey]).map(dayKey => {
        newWeekGroup[`week_${index + 1}`][dayKey] = weekGroup[weekKey][dayKey];
      });
    });

    return newWeekGroup;
  };
  const addWeek = () => {
    let weekCount = Object.keys(weekGroup).length;
    let newWeekGroup = shiftWeeks();
    newWeekGroup[`week_${weekCount + 1}`] = { ...defaultWeek };
    activeDays[`week_${weekCount + 1}`] = 'day_1';
    weekExpandState[`week_${weekCount + 1}`] = true;

    setWeekExpandState({ ...weekExpandState });
    setWeekGroup({ ...newWeekGroup });
    setActiveDays({ ...activeDays });

    onChange({ ...newWeekGroup });
  };

  const deleteWeek = weekGroupKey => {
    Object.keys(weekGroup).map((key, index) => {
      if (weekGroupKey === key) {
        delete weekGroup[key];
        delete activeDays[key];
        delete weekExpandState[key];
      }
    });
    let newWeekGroup = shiftWeeks();
    setDeleteAlertInfo({ isOpen: false, weekIdentifier: '' });

    setWeekGroup({ ...newWeekGroup });
    setActiveDays({ ...activeDays });
    setWeekExpandState({ ...weekExpandState });
    onChange({ ...newWeekGroup });
  };

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    weekExpandState[panel] = isExpanded;
    setWeekExpandState({ ...weekExpandState });
  };

  return (
    <>
      <ConfirmDialog
        headerText="Are you sure?"
        bodyText="You are about to delete a week."
        onAgree={() => {
          deleteWeek(deleteAlertInfo.weekIdentifier);
        }}
        onDisagree={() =>
          setDeleteAlertInfo({ isOpen: false, weekIdentifier: '' })
        }
        isOpen={deleteAlertInfo.isOpen}
      ></ConfirmDialog>
      {weekGroup &&
        Object.keys(weekGroup).map((weekGroupKey, weekGroupIndex) => {
          return (
            <Accordian
              key={weekGroupKey}
              title={`WEEK ${weekGroupIndex + 1}`}
              name={weekGroupKey}
              expanded={Boolean(weekExpandState[weekGroupKey])}
              handleChange={handleChange}
              menu={() => {
                return (
                  <ThreeDotMenu
                    options={['Delete All']}
                    onOptionClick={e => {
                      setDeleteAlertInfo({
                        isOpen: true,
                        weekIdentifier: weekGroupKey,
                      });
                    }}
                  />
                );
              }}
            >
              <Grid container>
                <Grid xs={12}>
                  {Object.keys(weekGroup[weekGroupKey]).map((dayKey, index) => {
                    // Object.keys(weekGroup[weekGroupKey][dayKey]).map(
                    //   (dayInfo, dayIndex) => {
                    return (
                      <DayCalender
                        key={index}
                        dayText={'day'}
                        index={weekGroupIndex * 7 + (index + 1)}
                        selected={activeDays[weekGroupKey] === dayKey}
                        theme="dark"
                        onClick={() => {
                          //   setVideoList(defaultWeek[dayKey]);
                          setActiveDays({
                            ...activeDays,
                            [weekGroupKey]: dayKey,
                          });
                        }}
                      />
                    );
                  })}
                </Grid>
                <FindVideo
                  videoList={weekGroup[weekGroupKey][activeDays[weekGroupKey]]}
                  onChange={list => {
                    weekGroup[weekGroupKey] = {
                      ...weekGroup[weekGroupKey],
                      [activeDays[weekGroupKey]]: getUniqItems(list, 'id'),
                    };
                    setWeekGroup(weekGroup);
                    onChange(weekGroup);
                  }}
                  subTitle={() => {}}
                />
              </Grid>
            </Accordian>
          );
        })}
      <Box mt={1}>
        <Button variant="outlined" fullWidth onClick={() => addWeek()}>
          Add a Week
        </Button>
      </Box>
    </>
  );
};

export default CollectionWeek;
