import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { useStyles } from './styles';

import { SvgClose } from '../../components/NeIcons';
import { SvgIconAlert, SvgIconEdit, SvgCheckCircle } from '../NeIcons';
import { CircularProgress, IconButton, ListItem } from '@material-ui/core';
import { StatusText } from '../Typography';

export enum FormType {
  ADD = 'add',
  EDIT = 'edit',
}

export default function ClassTable(props) {
  const classes = useStyles();
  const { list, isPastClass } = props;

  const getDateTime = list => {
    // if (list.isPublished) {
      return (
        <div>
          {`${moment(list.startDate).format(`ll`)}`} <br />
          {list.startDate ? `${moment(list.startDate).format(`LT`)}` : ''}
        </div>
      );
    // } else {
      // return `Saved ${moment(list.updatedAt).format(`L`)}`;
    // }
  };

  const getVideoType = list => {
    if (list.videoType === 'class') {
      return 'Class';
    } else if (list.videoType === 'live') {
      return `Live Class: ${moment(+list.startDate).format(`L`)}`;
    } else if (list.videoType === 'generic') {
      return 'Generic Video';
    }
  };

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead />
        <TableBody>
          {list &&
            list.map(list => {
              const dropInPrice =
                list.interactive && list.interactive.dropInPrice
                  ? list.interactive.dropInPrice
                  : 0;
              const spots = list.interactive && list.interactive.spots;
              const spotsLeft = list.interactive && list.interactive.spotsLeft;
              return (
                <TableRow key={list.id} hover role="checkbox" tabIndex={-1}>
                  <TableCell align="left">
                    <div className={classes.firstColumn}>
                      {/* <div className={classes.box}>
                          <SvgIconPlayArrow />
                        </div> */}
                      <div className={classes.firstCoulmContent}>
                        <div className={classes.conceptTitle}>
                          {list &&
                          list.title &&
                          list.instructors &&
                          list.instructors.length > 0 &&
                          list.instructors[0] &&
                          list.instructors[0].firstName
                            ? `${list.title} with ${list.instructors &&
                                list.instructors[0] &&
                                list.instructors[0]
                                  .firstName} ${list.instructors &&
                                list.instructors[0] &&
                                list.instructors[0].lastName}`
                            : list.title}
                        </div>

                        {list.interactive && list.interactive.isPrivate ? (
                          <StatusText>Private</StatusText>
                        ) : (
                          ''
                        )}

                        <div className={classes.text}>
                          {list.concept && list.concept.title}
                        </div>
                        <div className={classes.text}>{getVideoType(list)}</div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.text}>{getDateTime(list)}</div>
                    <div className={classes.text}>
                      {' '}
                      {list.duration && `${list.duration} minutes`}
                    </div>
                    {/* {!list.isPublished && <div className={classes.text}>
                      {' Start Time - '} {' '}
                      {list.startDate && moment(list.startDate).format('lll')}
                    </div>} */}
                  </TableCell>
                  <TableCell align="left">
                    {/* {renderCTA(list)} */}
                    <div className={classes.text}>
                      ${Number(dropInPrice / 100).toFixed(2)} {` Drop In Price`}
                    </div>
                    <div className={classes.text}>
                      {spots && spotsLeft && (
                        <>
                          {spots - spotsLeft >= 0 ? spots - spotsLeft : 0}{' '}
                          Booked{' '}
                        </>
                      )}
                    </div>
                    <div className={classes.text}>{spots} Max</div>
                    {isPastClass &&
                      list.interactive &&
                      list.interactive.isCancelled && (
                        <div className={classes.cancelled}>
                          {list.interactive.isInstrCancelled
                            ? 'Inst. Cancelled'
                            : 'Cancelled'}
                        </div>
                      )}
                  </TableCell>

                  {isPastClass ? (
                    <TableCell align="right">
                      {list.interactive && list.interactive.isCancelled ? (
                        <IconButton className={classes.closeButton}>
                          <SvgClose />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <SvgCheckCircle />
                        </IconButton>
                      )}
                    </TableCell>
                  ) : (
                    <TableCell align="right">
                      {list.isError && list.videoStatus !== 'processing' ? (
                        <div style={{ cursor: 'pointer' }}>
                          <SvgIconAlert onClick={() => props.reEncode(list)} />
                        </div>
                      ) : list.videoStatus == 'processing' ? (
                        <CircularProgress color="secondary" value={25} />
                      ) : (
                        <IconButton
                          onClick={() => {
                            // setActiveVideo(list.id);
                            // openEdiDialog(true);
                            props.setEditVideoInfo({
                              isOpen: true,
                              id: list.id,
                            });
                          }}
                          classes={{ root: classes.editIcon }}
                        >
                          <SvgIconEdit />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
