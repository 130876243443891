import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import request from '../../shared/lib/request';
import ActionTypes from './constants';
import { storeBrowseGenreList, requestBrowseGenreList } from './actions';
import { openSnackBar } from '../Root/actions';
import { setLoader } from '../LiveClass/actions';

// Individual exports for testing
export default function* curateGenreSaga() {
  yield takeLatest(ActionTypes.REQUEST_BROWSE_GENRE_LIST, fetchBrowseGenreList);
  yield takeLatest(ActionTypes.REORDER_TRAYS, reorderGenreList);
}

export function* fetchBrowseGenreList() {
  try {
    let { result } = yield call(request, {
      url: `neouadmin/v1/browse-genre`,
      method: 'GET',
    });
    yield put(storeBrowseGenreList(result));
  } catch (error) {
    yield put(storeBrowseGenreList([]));
    yield put(openSnackBar('Failed to fetch Genre List'));
  }
}

export function* reorderGenreList({ payload }: AnyAction) {
  let { genreList } = payload;
  let orderAry: any = {};
  genreList.map((item, index) => {
    orderAry[String(item.id)] = index + 1;
    return orderAry;
  });
  let requestBody = { orderAry };
  try {
    yield put(setLoader(true));
    let { result } = yield call(request, {
      url: `neouadmin/v1/reorder-browse-genre`,
      method: 'PUT',
      data: requestBody,
    });
    yield put(requestBrowseGenreList());
    yield put(setLoader(false));
  } catch (error) {
    yield put(setLoader(false));
    yield put(openSnackBar('Failed to re-order browse genres list'));
  }
}
