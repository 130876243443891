import * as React from 'react';
import { List, arrayMove, arrayRemove } from 'baseui/dnd-list';
import { Link, IconButton } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

export default function Drag({
  data,
  deleteImg,
  dialogFunc,
  latestImgList,
  videoThumb,
  handleImageVideo,
  videoThumbImg,
}) {
  const [imgList, setImgList] = React.useState([]);
  const [videoThumbState, setVideoThumbState] = React.useState();

  const status = true;
  const diloge = status => {
    dialogFunc(status);
  };

  React.useEffect(() => {
    setImgList(data);
    setVideoThumbState(videoThumbImg);
  }, [data]);

  const delImg = (imgList, oldIndex, newIndex) => {
    deleteImg(imgList, oldIndex);
  };

  const handleChangedImgList = newImgList => {
    setImgList(newImgList);
    latestImgList(newImgList);
  };

  const onDragListChange = ({ oldIndex, newIndex }) => {
    let newImageList = [];

    if (newIndex === -1) {
      // on delete
      delImg(imgList, oldIndex, newIndex);
      newImageList = arrayRemove(imgList, oldIndex);
    } else {
      // on order change
      newImageList = arrayMove(imgList, oldIndex, newIndex);
    }

    handleChangedImgList(newImageList);
  };

  return (
    <List
      items={imgList}
      removable
      onChange={onDragListChange}
      overrides={{
        List: {
          style: ({ $theme }) => {
            return {
              outline: `none`,
            };
          },
        },
        Label: {
          component: ({ $value, $index }) => (
            <GalleryListItem
              key={`gallery_list_item_${$index}`}
              $value={$value}
              $index={$index}
              videoThumbState={videoThumbState}
              handleImageVideo={handleImageVideo}
            />
          ),
        },
        Item: {
          style: ({ $theme, $isSelected, $isDragged }) => {
            return {
              outline: 'none',
              zIndex: '100000!important',
              borderBottomColor: '#E3E3E4!important',
              boxShadow:
                $isSelected || $isDragged
                  ? '0 2px 2px 0 rgba(0,0,0,0.5);'
                  : '0 2px 2px 0 rgba(0,0,0, 0);',
              borderTopColor: '#E3E3E4!important',
              borderLeftColor: '#E3E3E4!important',
              borderRightColor: '#E3E3E4!important',
              borderTopWidth: '1px !important',
              borderLeftWidth: '1px !important',
              borderRightWidth: '1px !important',
              borderBottomWidth: '1px !important',
              paddingLeft: '8px',
              paddingRight: '8px',
              paddingTop: '8px',
              paddingBottom: '8px',
              borderRadius: '6px',
              marginBottom: '6px',
              width: '100%',
            };
          },
        },
        CloseHandle: {
          component: props => (
            <IconButton {...props}>
              <DeleteOutlinedIcon />
            </IconButton>
          ),
        },
      }}
    />
  );
}

function GalleryListItem({
  $value,
  $index,
  videoThumbState,
  handleImageVideo,
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <img
        src={
          $value.type === 'video'
            ? $value.thumbnail || videoThumbState
            : $value.type === 'image'
            ? $value.url
            : ''
        }
        key={$value.name}
        alt={$value.name}
        style={{ borderRadius: '6px', width: '114px' }}
      />

      <div style={{ marginLeft: '20px' }}>
        <span>{$value.url.split('/').pop()}</span>
        {$value.type === 'video' ? (
          <div>
            <input
              type="file"
              id={$index}
              name="image_thumb"
              accept=".jpg, .jpeg, .png"
              style={{
                display: 'none',
              }}
              onChange={e => handleImageVideo(e)}
            />

            <Link
              style={{
                color: '#5AA700',
                fontFamily: 'Proxima-Nova-Regular',
                fontSize: '13px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              onClick={() => {
                document.getElementById($index).click();
              }}
            >
              Change thumbnail
            </Link>
          </div>
        ) : (
          ''
        )}
        <br></br>
      </div>
    </div>
  );
}
