import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import ActionTypes from './constants';
import request from '../../shared/lib/request';
import {
  achievementListSuccess,
  achievementListFailed,
  achievementDetailSuccess,
  setLoader,
  programChallengeSuccess,
  genresSuccess,
  deleteAchievementInfoSuccess,
  deleteAchievementInfoFailed,
  otherDetailSuccess,
  achievementLoader,
  // addAchievement,
  storeAchievementDetailInfo,
  achievementListCountSuccess,
  achievementListPaginationSuccess,
} from './actions';
import { selectAchievementDomain } from './selectors';
import { AnyAction } from 'redux';
import { openSnackBar } from '../Root/actions';
const SLICE_COUNT_OF_CLASSES = 50;

// Individual exports for testing
export default function* achievementSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(ActionTypes.REQUEST_ACHIEVEMENT_LIST, getAchievementList);
  yield takeLatest(
    ActionTypes.REQUEST_ACHIEVEMENT_LIST_PAGINATION,
    getAchivementListPagination,
  );
  yield takeLatest(
    ActionTypes.REQUEST_ACHIEVEMENT_DETAIL,
    getEditAchievementAPI,
  );
  yield takeLatest(ActionTypes.ACHIEVEMENT_UPDATED_INFO, updateAchievementAPI);
  yield takeLatest(ActionTypes.ACHIEVEMENT_DELETE_INFO, deleteAchievementAPI);
  yield takeLatest(ActionTypes.REQUEST_OTHER_DETAILS, getOtherDetails);
  yield takeLatest(ActionTypes.POST_ADD_ACHIEVEMENT, addAchievementApi);
}

function arrayDefaultValue(mainArray, selecteArray, filed) {
  let arr = [];
  for (let k = 0; k < selecteArray.length; k++) {
    for (let i = 0; i < mainArray.length; i++) {
      if (mainArray[i].id == selecteArray[k]) {
        arr.push(mainArray[i]);
        break;
      }
    }
  }
  return arr;
}

export function setDefaultOption(result, isId) {
  let obj = {
    isLoading: false,
    data: {
      categories: [],
      collectionType: '',
      createdAt: new Date(),
      description: '',
      flag: '',
      gallery: [],
      genres: [],
      graphics: {
        '16x9': '',
        '16x9_trans': '',
        '1x1': '',
        '32x9': '',
        '9x16': '',
      },
      instructors: [],
      isHidden: false,
      isPublished: false,
      kicker: '',
      permaLink: '',
      publishedDate: new Date(),
      seriesID: '',
      title: '',
      updatedAt: new Date(),
      videos: {
        videoIds: [],
      },
    },
  };
  if (isId) {
    let defaultInstructors = arrayDefaultValue(
      result.instructors,
      result.data.instructors,
      'instructors',
    );
    let defaultGenres = arrayDefaultValue(
      result.genres,
      result.data.genres,
      'genres',
    );
    let defaultCategories = arrayDefaultValue(
      result.categories,
      result.data.categories,
      'categories',
    );
    result = {
      ...result,
      defaultInstructors: defaultInstructors,
      defaultGenres: defaultGenres,
      defaultCategories: defaultCategories,
    };
  } else {
    result = { ...result, ...obj };
  }
  return result;
}

export function* getAchievementList() {
  try {
    let { sortBy, activeTab, searchKey } = yield select(
      selectAchievementDomain,
    );
    if (sortBy === '' || activeTab === '') {
      sortBy = 'updatedAt=desc';
      activeTab = 'special';
    }
    const splitResult = sortBy.split('=');
    let { result, programChallenge, genres, total } = yield call(() =>
      request({
        url: `/neouadmin/v1/badges?badgeType=${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&keyword=${searchKey}`,
        method: 'GET',
      }),
    );
    yield put(achievementListSuccess(result));
    yield put(programChallengeSuccess(programChallenge));
    yield put(genresSuccess(genres));
    yield put(achievementListCountSuccess(total));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(achievementListSuccess([]));
    }
    yield put(achievementListSuccess([]));

    yield put(achievementListFailed());
  }
}

export function* getAchivementListPagination(action) {
  yield put(setLoader(true));
  try {
    let { sortBy, activeTab } = yield select(selectAchievementDomain);
    if (sortBy === '' || activeTab === '') {
      sortBy = 'updatedAt=desc';
      activeTab = 'special';
    }
    const splitResult = sortBy.split('=');
    let { result, programChallenge, genres } = yield call(() =>
      request({
        url: `/neouadmin/v1/badges?badgeType=${activeTab}&sortBy=${splitResult[0]}&sortOrder=${splitResult[1]}&offset=${action.payload}&limit=${SLICE_COUNT_OF_CLASSES}`,
        method: 'GET',
      }),
    );
    yield put(achievementListPaginationSuccess(result));
    yield put(programChallengeSuccess(programChallenge));
    yield put(genresSuccess(genres));
    yield put(setLoader(false));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      var arr :any = []
      yield put(achievementListSuccess(arr));
      yield put(setLoader(false));
    }
    yield put(achievementListFailed());
    yield put(setLoader(false));
  }
}

export function* getEditAchievementAPI(action: AnyAction) {
  let id = action.payload;
  let url = {
    url: `/neouadmin/v1/badges`,
  };
  if (id) {
    url['params'] = {
      id,
    };
  }
  yield put(setLoader(true));
  try {
    let { result } = yield call(() => request(url));
    yield put(setLoader(false));
    if (!id) {
      let newResult = setDefaultOption(result, false);
      // yield put(addAchievement(newResult));
    } else {
      let newResult = setDefaultOption(result, true);
      yield put(storeAchievementDetailInfo(newResult));
    }
  } catch (error) {
    yield put(setLoader(false));
  }

  // yield put(setLoader(true));
  // try {
  //   let { result } = yield call(() =>
  //     request({
  //       url: `/neouadmin/v1/badges?id=${id}`,
  //       method: 'GET',
  //     }),
  //   );
  //   yield put(achievementDetailSuccess(result[0]));
  //   yield put(setLoader(false));
  // } catch (error) {
  //   if (error && error.data && error.data.responseCode === 404) {
  //     yield put(achievementListSuccess([]));
  //   }
  //   yield put(achievementListFailed());
  // }
}

export function* updateAchievementAPI(action: AnyAction) {
  let updateInfo = action.payload;
  let { achievementDetail } = yield select(selectAchievementDomain);
  let badgeId = updateInfo.badgeId;
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/badges`,
        method: 'PUT',
        data: updateInfo,
      }),
    );
  } catch (error) {
    yield put(openSnackBar('Failed to update badge'));
  }
}

export function* deleteAchievementAPI(data) {
  let { achievementDetail } = yield select(selectAchievementDomain);
  let badgeId = data.badgeId;
  yield put(setLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/delete-badge`,
        method: 'POST',
        data: data.payload,
      }),
    );

    console.log('result', result);
    yield put(setLoader(false));
    yield put(deleteAchievementInfoSuccess(result));
  } catch (error) {
    yield put(setLoader(false));
    yield put(deleteAchievementInfoFailed(error));
    yield put(openSnackBar('Failed to delete badge'));
  }
}

//get other detail values
export function* getOtherDetails(action: AnyAction) {
  let id = action.payload;
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/badges?isEmpty=${id}`,
        method: 'GET',
      }),
    );

    yield put(otherDetailSuccess(result));
  } catch (error) {
    if (error && error.data && error.data.responseCode === 404) {
      yield put(achievementListSuccess([]));
    }
    yield put(achievementListFailed());
  }
}

//add Achievement
export function* addAchievementApi(action: AnyAction) {
  yield put(achievementLoader(true));
  try {
    let { result } = yield call(() =>
      request({
        url: `/neouadmin/v1/badges`,
        method: 'POST',
        data: action.payload,
      }),
    );
    yield put(achievementLoader(false));
  } catch (error) {
    yield put(achievementLoader(false));
    yield put(openSnackBar('Failed to add badge'));
  }
}
