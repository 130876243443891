import React from 'react';
import {
  Checkbox,
  Divider,
  Button,
  MenuItem,
  Typography,
  Grid,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from 'react-redux';
import { NEInputLight as NEInput } from '../../../components/NEInput';
// actions
import { AddAdministrator } from '../actions';
import { green } from '@material-ui/core/colors';
import withStyles from '@material-ui/core/styles/withStyles';
import { useStyles } from './styles';
import Autocomplete from '../../../components/Autocomplete';

export default function AddAttributeDialog({ isOpen, searchSuggestion }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = React.useState({});
  const types = ['admin', 'superadmin', 'instructor'];

  const instr =
    searchSuggestion &&
    searchSuggestion.length > 0 &&
    searchSuggestion.map(data => ({
      id: data.id,
      title: `${data.firstName} ${data.lastName}`,
    }));

  const [state, setState] = React.useState({
    right: true,
    isLoading: true,
    manageLibrary: false,
    myClasses: false,
    addClasses: false,
    interactive: false,
    manageMembers: false,
    curateLabel: false,
    managePlans: false,
    liveParticipants: false,
    configure: false,
    name: '',
    email: '',
    type: '',
    instructor: [],
    errors: {},
    isNameError: false,
    isEmailError: false,
    nameError: '',
    instrId: '',
  });

  const change = (name, e) => {
    e.persist();
    handleChange(e);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange1 = event => {
    setState({ ...state, type: event.target.value });
  };

  const handleChange2 = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange3 = data => {
    setState({ ...state, instructor: data });
  };

  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

  const onAddAdministrator = event => {
    const { name, email, type } = state;
    let permissions = [];
    if (state.manageMembers) {
      permissions.push('members');
    }
    if (state.interactive) {
      permissions.push('interactive');
    }
    if (state.manageLibrary) {
      permissions.push('library');
    }
    if (state.myClasses) {
      permissions.push('my-classes');
    }
    if (state.addClasses) {
      permissions.push('add-classes');
    }
    if (state.curateLabel) {
      permissions.push('curate');
    }
    if (state.managePlans) {
      permissions.push('plans');
    }
    if (state.configure) {
      permissions.push('configure');
    }
    if (state.liveParticipants) {
      permissions.push('live-participants-page');
    }
    const formError = validateFields({
      name,
      email,
      type,
    });
    if (!name || !email || !type) {
      setError(formError);
      return null;
    } else {
      setError('');
      let useDate = {};
      if (state.type === 'instructor') {
        useDate = {
          instrId: (state.instructor && state.instructor.id) || '',
          name: state.name,
          email: state.email,
          role: state.type,
          permissions: permissions,
          isAdd: true,
        };
      } else {
        useDate = {
          name: state.name,
          email: state.email,
          role: state.type,
          permissions: permissions,
          isAdd: true,
        };
      }
      setState({ ...state, right: false });
      dispatch(AddAdministrator(useDate));
    }
  };

  return (
    <div>
      {['right'].map(anchor => (
        <React.Fragment key={anchor}>
          <Divider className={classes.divider} />
          <div className={classes.titleWrapper}>
            <NEInput
              id="administratorName"
              name="name"
              label="Name"
              value={state.name}
              defaultValue={state.name}
              onChange={change.bind(null, 'name')}
              fullWidth
              variant="outlined"
              error={!!error.name || ''}
              helperText={!!error.name || ''}
            />
            <NEInput
              id="administratorEmail"
              name="email"
              label="E-Mail Address"
              value={state.email}
              defaultValue={state.email}
              onChange={change.bind(null, 'email')}
              fullWidth
              variant="outlined"
              error={!!error.email || ''}
              helperText={!!error.email || ''}
            />
          </div>
          <div className={classes.secondRow}>
            <NEInput
              id="AdministratorType"
              name="type"
              className={classes.typeInput}
              select
              label="Type"
              defaultValue={state.type}
              onChange={e => handleChange1(e)}
              variant="outlined"
              error={!!error.type || ''}
              helperText={!!error.type || ''}
            >
              {types &&
                types.map(t => (
                  <MenuItem value={t} key={t}>
                    {t}
                  </MenuItem>
                ))}
            </NEInput>
          </div>
          {state && state.type === 'instructor' && (
            <div className={classes.secondRow}>
              <Autocomplete
                key="instructor"
                id="instructor"
                options={instr || []}
                label="interactive instructor *"
                defaultValue={state.instructor || []}
                name="instructor"
                onChange={(e, data) => handleChange3(data)}
              />
            </div>
          )}

          <div className={classes.secondRow}>
            <h4 className={classes.perLabel}>Permissions</h4>
            <div className={classes.checkboxBlock}>
              {state && state.type === 'instructor' && (
                <>
                  <FormControlLabel
                    value="top"
                    classes={{
                      root: classes.customStyle,
                    }}
                    control={
                      <GreenCheckbox
                        name="myClasses"
                        checked={state.myClasses}
                        onChange={e => handleChange2(e)}
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        My Classes
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="top"
                    classes={{
                      root: classes.customStyle,
                    }}
                    control={
                      <GreenCheckbox
                        name="addClasses"
                        checked={state.addClasses}
                        onChange={e => handleChange2(e)}
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        Add Classes
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </>
              )}
              {state && state.type !== 'instructor' && (
                <>
                  <FormControlLabel
                    value="top"
                    classes={{
                      root: classes.customStyle,
                    }}
                    control={
                      <GreenCheckbox
                        name="manageLibrary"
                        checked={state.manageLibrary}
                        onChange={e => handleChange2(e)}
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        Manage Library
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    classes={{
                      root: classes.customStyle,
                    }}
                    control={
                      <GreenCheckbox
                        name="liveParticipants"
                        checked={state.liveParticipants}
                        onChange={e => handleChange2(e)}
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        Live Class View Only
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    classes={{
                      root: classes.customStyle,
                    }}
                    control={
                      <GreenCheckbox
                        name="interactive"
                        checked={state.interactive}
                        onChange={e => handleChange2(e)}
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        Manage Interactive
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    classes={{
                      root: classes.customStyle,
                    }}
                    control={
                      <GreenCheckbox
                        name="manageMembers"
                        checked={state.manageMembers}
                        onChange={e => handleChange2(e)}
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        Manage Members
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    classes={{
                      root: classes.customStyle,
                    }}
                    control={
                      <GreenCheckbox
                        name="curateLabel"
                        checked={state.curateLabel}
                        onChange={e => handleChange2(e)}
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        Curate
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    classes={{
                      root: classes.customStyle,
                    }}
                    control={
                      <GreenCheckbox
                        name="managePlans"
                        checked={state.managePlans}
                        onChange={e => handleChange2(e)}
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        Manage Plans
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    classes={{
                      root: classes.customStyle,
                    }}
                    control={
                      <GreenCheckbox
                        name="configure"
                        checked={state.configure}
                        onChange={e => handleChange2(e)}
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        Configure
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                </>
              )}
            </div>
          </div>

          <Grid>
            <div className={classes.btnContainer}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={event => {
                  onAddAdministrator(event);
                }}
              >
                SAVE &amp; SEND INVITE
                {/* <div className={classes.saveLegalBtn1}>
                      SAVE &amp; SEND INVITE
                    </div> */}
              </Button>
            </div>
          </Grid>
        </React.Fragment>
      ))}
    </div>
  );
}

function validateFields({ name, email, type }) {
  let formError = {};
  if (!name) {
    formError = { ...formError, name: 'Please enter Name' };
  }
  if (!email) {
    formError = { ...formError, email: 'Please enter First Email' };
  }
  if (!type) {
    formError = { ...formError, type: 'Please Select Type' };
  }
  return formError;
}
