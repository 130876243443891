/*
 *
 * BrowseCurate constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/BrowseCurate/DEFAULT_ACTION',
  REQUEST_BROWSE_PAGE_INFO = 'app/BrowseCurate/REQUEST_BROWSE_PAGE_INFO',
  STORE_BROWSE_PAGE = 'app/BrowseCurate/STORE_BROWSE_PAGE',
  SET_LOADER = 'app/BrowseCurate/SET_LOADER',
  REORDER_LOWER_SECTION_TRAYS = 'app/BrowseCurate/REORDER_LOWER_SECTION_TRAYS',
  UPDATE_BROWSE_COMPONENT = 'app/BrowseCurate/UPDATE_BROWSE_COMPONENT',
}

export default ActionTypes;
