/*
 *
 * Concept
 *
 */

import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import makeSelectConcept from './selectors';
import reducer from './reducer';
import saga from './saga';
import debounce from 'lodash.debounce';
import { Button, Checkbox, InputAdornment } from '@material-ui/core';
// import StyledDialog from '../../components/StyledDialog';
import {
  requestConceptList,
  setTabChange,
  setSearchKey,
  requestConceptListPagination,
  setInteractiveClassesOnly,
  resetConcept,
} from './actions';
import ConceptListTable from './ConceptTable/ConceptTable';
import StyledTabs, { StyledTab } from '../../components/VideoList/StylesTabs';
import { useStyles } from './styles';
import {
  SvgIconSearch,
  SvgIconAdd,
  SvgLoaderGif,
} from '../../components/NeIcons';
import SortSelect from './Select';
import Loader from '../../components/Loader';
import NEInput from '../../components/NEInput';
import AddDialog from './ConceptTable/AddDialog';
import { getConceptDetail } from './actions';
import ConfirmDialog from '../../components/ConfirmDialog';
import ConceptFormDialog, { FormType } from './components/ConceptFormDialog';
import { Helmet } from 'react-helmet';

const stateSelector = createStructuredSelector({
  concept: makeSelectConcept(),
});

interface Props {}

function Concept(props: Props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'concept', reducer: reducer });
  useInjectSaga({ key: 'conept', saga: saga });

  const { concept } = useSelector(stateSelector);
  let {
    conceptList,
    isListFetchingError,
    isLoading,
    total,
    conceptDetail = {},
    isConceptLoading,
    isInteractiveFilterOn: isInteractiveFilterOnState,
  } = concept;

  const dispatch = useDispatch();
  const classes = useStyles();
  const [isAddConceptOpen, openAddConceptDialog] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [activeTabIndex, setActiveTab] = React.useState(0);
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  const [isInteractiveFilterOn, setIsInteractiveFilterOn] = React.useState(
    isInteractiveFilterOnState,
  );

  const [conceptDialogData, setConceptDialogData] = React.useState({
    isOpen: false,
    id: '',
  });

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    if (tabIndex === 0) {
      dispatch(setTabChange('isHidden=false'));
      dispatch(requestConceptList());
    } else if (tabIndex === 1) {
      dispatch(setTabChange('isPublished=false&isHidden=false'));
      dispatch(requestConceptList());
    } else if (tabIndex === 2) {
      dispatch(setTabChange('isPublished=true'));
      dispatch(requestConceptList());
    } else if (tabIndex === 3) {
      dispatch(setTabChange('isHidden=true'));
      dispatch(requestConceptList());
    }
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (activeTabIndex === 0) {
      dispatch(setTabChange('isHidden=false'));
    }
    dispatch(requestConceptList());
    dispatch(getConceptDetail(null));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetConcept());
    };
  }, []);

  const tabConfig = [
    {
      label: 'ALL',
      component: <ConceptListTable />,
    },
    {
      label: 'DRAFT',
      component: <ConceptListTable />,
    },
    {
      label: 'PUBLISHED',
      component: <ConceptListTable />,
    },
    {
      label: 'HIDDEN',
      component: <ConceptListTable />,
    },
  ];

  // highlight-starts
  const debouncedGet = useCallback(
    debounce(textValue => {
      onSearchClick(textValue);
    }, 1500),
    [], // will be created only once initially
  );

  const handleInput = e => {
    setSearchText(e.target.value);
    debouncedGet(e.target.value);
  };

  const onSearchClick = (text = '') => {
    dispatch(setSearchKey(text ? text : searchText));
    dispatch(requestConceptList());
  };

  const onAgree = () => {
    openAddConceptDialog(false);
  };

  const onDisAgree = () => {
    openAddConceptDialog(true);
  };

  function loadMore() {
    if (conceptList && conceptList.length < total) {
      dispatch(requestConceptListPagination(conceptList.length));
    }
  }

  function toggleInteractiveClassesOnly() {
    const isInteractiveFilterOnState = !isInteractiveFilterOn;
    setIsInteractiveFilterOn(isInteractiveFilterOnState);
    dispatch(setInteractiveClassesOnly(isInteractiveFilterOnState));
    dispatch(requestConceptList());
  }

  return (
    <>
      <Helmet>
        <title>Library Concepts</title>
        <meta name="description" content="Neou Fitness Concepts" />
      </Helmet>
      {isLoading && <Loader />}

      <div className={classes.root}>
        <div>
          {/* <AddDialog
            isOpen={isAddConceptOpen}
            conceptList={conceptList}
            title="ADD Concept"
            handleClose={() => {
              openConfirmDialog(true);
            }}
          ></AddDialog> */}
          {conceptDialogData.isOpen && (
            <ConceptFormDialog
              isOpen={conceptDialogData.isOpen}
              id={null}
              type={FormType.ADD}
              title="Add Concept"
              onClose={() => setConceptDialogData({ isOpen: false, id: '' })}
            />
          )}
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            handleClose={openConfirmDialog}
            onAgree={onAgree}
            onDisagree={onDisAgree}
            agreeButtonText="Yes"
            disagreeBtnText="Cancel"
            headerText="Are you sure?"
            bodyText="You’re about to close this Add Concept dialog box."
          ></ConfirmDialog>
          <div className={classes.headWrap}>
            <div className={classes.head}>Library: Concepts</div>
            <div className={classes.searchWrap}>
              <NEInput
                label="Search"
                variant="outlined"
                id="custom-css-outlined-input"
                fullWidth={true}
                onChange={e => handleInput(e)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={() => onSearchClick()} >
                        <SvgIconSearch />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.headWrap}>
            <Button
              onClick={() => {
                // openAddConceptDialog(true);
                setConceptDialogData({ isOpen: true });
              }}
              variant="contained"
              color="secondary"
              className={classes.addConceptBtn}
              disableRipple={true}
            >
              <SvgIconAdd />{' '}
              <div className={classes.newConcept}>New Concept</div>
            </Button>
            <div className={classes.headWrap}>
              <Checkbox
                checked={isInteractiveFilterOn}
                onChange={toggleInteractiveClassesOnly}
                name="checkedB"
                color="primary"
              />
              <div> Interactive Concepts Only </div>
            </div>
            <SortSelect />
          </div>

          <StyledTabs
            value={activeTabIndex}
            onChange={onTabChange}
            aria-label="My account tabs"
            centered
            className={classes.styledTab}
          >
            {tabConfig.map((t, index) => (
              <StyledTab key={index} label={t.label} />
            ))}
          </StyledTabs>
          {isListFetchingError && (conceptList && conceptList.length === 0) ? (
            <div className={classes.noData}>No Data Found</div>
          ) : (
            <ConceptListTable
              list={conceptList}
              isLoading={isLoading}
              isInteractiveFilterOn={isInteractiveFilterOn}
            />
          )}
          {isConceptLoading && (
            <div style={{ padding: '10px' }}>
              <SvgLoaderGif />
            </div>
          )}
          {!isConceptLoading &&
            conceptList &&
            conceptList.length > 0 &&
            total !== conceptList.length && (
              <div className={classes.loadMoreBtn}>
                <Button color="secondary" onClick={() => loadMore()}>
                  Load More Concepts
                </Button>
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default Concept;
