import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { NEInputLight as NEInput } from '../NEInput';
import reducer from '../../containers/BrandVoice/reducer';
import saga from '../../containers/BrandVoice/saga';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import { useDispatch } from 'react-redux';
import { updateBrandVoice } from '../../containers/BrandVoice/actions';
import CloseIcon from '@material-ui/icons/Close';
import {
  Divider,
  List,
  Button,
  Drawer,
  Typography,
  Fab,
} from '@material-ui/core';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
      background: '#12161A',
      width: '70px',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      padding: '0 !important',
      lineHeight: '20.4px',
      textAlign: 'center',
      minWidth: '85px !important',
      height: '24px !important',
      textTransform: 'none !important',
      letterSpacing: '1px !important',
      '&:hover': {
        background: '#12161A',
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    list: {
      width: '650px !important',
    },
    fullList: {
      width: 'auto',
    },
    btnText: {
      color: 'white',
      textTransform: 'capitalize',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      letterSpacing: '0',
      lineHeight: '20.4px',
      textAlign: 'center',
    },
    title: {
      padding: '16px 24px',
      color: '#12161A',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '16px',
      lineHeight: '20px',
      float: 'left',
    },
    title1: {
      padding: '16px 24px',
      color: '#12161A',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '16px',
      lineHeight: '20px',
    },
    activityForm: {
      padding: '0 20px',
    },
    brandVoiceBtn: {
      position: 'absolute',
      bottom: '50px',
      left: '20px',
    },
    brandVoiceSubtitle: {
      fontFamily: 'ProximaNova-Bold !important',
      paddingBottom: '10px',
    },
    brandInput: {
      marginBottom: '10px',
    },
    closeBtn: {
      float: 'right',
    },
    titleDiv: {
      width: '100%',
      float: 'left',
    },
    divider: {
      width: '100%',
      overflow: 'hidden',
    },
  }),
);

export default function PostWatchComponent({
  onClick,
  buttonText = 'Manage',
  title,
  achievementsTxt,
}) {
  const classes = useStyles();
  useInjectReducer({ key: 'brandVoice', reducer: reducer });
  useInjectSaga({ key: 'brandVoice', saga: saga });
  const dispatch = useDispatch();
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
    // left: false,
    achTxt: achievementsTxt,
    isError0: false,
    helperText0: '',
    isError1: false,
    helperText1: '',
    isError2: false,
    helperText2: '',
  });
  const handleInput2 = (ind, event) => {
    if (event.target.value.length < 1) {
      switch (ind) {
        case 0:
          setState({
            ...state,
            isError0: true,
            helperText0: 'Message cannot be empty',
          });
          break;
        case 1:
          setState({
            ...state,
            isError1: true,
            helperText1: 'Message cannot be empty',
          });
          break;
        case 2:
          setState({
            ...state,
            isError2: true,
            helperText2: 'Message cannot be empty',
          });
          break;
      }
    } else if (event.target.value.length > 36) {
      switch (ind) {
        case 0:
          setState({
            ...state,
            isError0: true,
            helperText0: 'Please enter text within 36 characters',
          });
          break;
        case 1:
          setState({
            ...state,
            isError1: true,
            helperText1: 'Please enter text within 36 characters',
          });
          break;
        case 2:
          setState({
            ...state,
            isError2: true,
            helperText2: 'Please enter text within 36 characters',
          });
          break;
      }
    } else {
      let tempAry = achievementsTxt;
      tempAry[ind] = event.target.value;
      switch (ind) {
        case 0:
          setState({
            ...state,
            achTxt: tempAry,
            isError0: false,
            helperText0: '',
          });
          break;
        case 1:
          setState({
            ...state,
            achTxt: tempAry,
            isError1: false,
            helperText1: '',
          });
          break;
        case 3:
          setState({
            ...state,
            achTxt: tempAry,
            isError2: false,
            helperText2: '',
          });
          break;
      }
    }
  };
  const toggleDrawer = (anchor, open) => event => {
    setState({ ...state, [anchor]: open });
  };
  const updateData1 = () => {
    if (!state.isError0 && !state.isError1 && !state.isError2) {
      dispatch(
        updateBrandVoice({
          achievementsTxt: state.achTxt,
        }),
      );
      toggleDrawer('right', false);
    }
  };
  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'right' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List>
        <div className={classes.titleDiv}>
          <div className={classes.title}>EDIT MESSAGING</div>
          <Button
            onClick={() => showAlertToClose(true)}
            className={classes.closeBtn}
          >
            <CloseIcon />
          </Button>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.title1}>{title}</div>
      </List>
      <form className={classes.activityForm}>
        <Typography className={classes.brandVoiceSubtitle} variant="subtitle1">
          Randomized Text:
        </Typography>
        {achievementsTxt &&
          achievementsTxt.map((item, index) => {
            return (
              <NEInput
                id={'achievements' + index}
                name="title"
                label="Message (upto 36 characters)"
                className={classes.brandInput}
                defaultValue={item}
                error={state['isError' + index]}
                helperText={state['helperText' + index]}
                onChange={e => handleInput2(index, e)}
                fullWidth
                variant="outlined"
              />
            );
          })}
        <Button
          className={classes.brandVoiceBtn}
          variant="contained"
          onClick={updateData1}
          color="secondary"
        >
          Save & Publish changes
        </Button>
      </form>
    </div>
  );

  return (
    <div>
      <div>
        <Fab
          variant="extended"
          size="medium"
          aria-label="add"
          className={classes.margin}
          //onClick={onClick}
        >
          <React.Fragment>
            <Button
              onClick={toggleDrawer('right', true)}
              className={classes.btnText}
            >
              {buttonText}
            </Button>

            <Drawer
              anchor={'right'}
              disableBackdropClick={true}
              open={state['right']}
              onClose={() => showAlertToClose(true)}
            >
              <ConfirmDialog
                isOpen={isCloseAlertOpen}
                headerText="Are you sure?"
                bodyText="You are about to close the post watch message."
                onAgree={toggleDrawer('right', false)}
                handleClose={() => showAlertToClose(false)}
                onDisagree={() => showAlertToClose(false)}
              ></ConfirmDialog>
              {list('right')}
            </Drawer>
          </React.Fragment>
        </Fab>
      </div>
    </div>
  );
}
