import * as React from 'react';
import styled from 'styled-components';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';

import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import saga from '.././saga';
import reducer from '.././reducer';
import makeSelectVideo from './../selectors';
import LabelInput from '../../../components/LabelInput';
import AddButton from '../../../components/AddButton';
import TextField from '@material-ui/core/TextField';
import CancelButton from '../../../components/CancelButton';

import { VideoEvent } from '../../../validators/Authentication';
import { formError, customMessage } from '../../../utils/validator';
import { addVideoEvent } from '.././actions';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },

  common: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  startTime: {
    width: '55%',
  },
  durations: {
    width: '40%',
    '& label.Mui-focused': {
      color: '#909599',
      '&.Mui-error': {
        color: '#FF1C58',
      },
    },
    '& .MuiOutlinedInput-root': {
      height: 48,
      borderRadius: '6px',
      '& fieldset': {
        borderColor: '#909599',
      },
      '&:hover fieldset': {
        borderColor: '#909599',
      },
      '&.Mui-focused fieldset': {
        border: `1px solid #909599`,
      },
    },
  },

  root: {},
}));

const stateSelector = createStructuredSelector({
  video: makeSelectVideo(),
});

export default function AddVideoEvent(props) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'video', reducer: reducer });
  useInjectSaga({ key: 'video', saga: saga });

  const { video } = useSelector(stateSelector);
  const { addVideoDialog } = video;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [error, setError] = React.useState({
    videoTitle: '',
  });
  const [videoTitle, setvideoTitle] = React.useState('');

  function handleTitleInput(e) {
    setvideoTitle(e.target.value);
  }

  const validateForm = () => {
    const { error, value } = VideoEvent.validate(
      { videoTitle },
      {
        abortEarly: false,
        messages: customMessage,
      },
    );
    return formError(error);
  };

  const onAddEvent = () => {
    const e = validateForm();
    setError(e);
    let extString = videoTitle.toString().substr(-4);
    if (
      Object.keys(e).length === 0 &&
      (extString === '.mov' || extString === '.mp4') &&
      videoTitle
    ) {
      if (extString === '.mov') {
        const data = {
          CORRELATION_ID: (Math.floor(Math.random() * 100000) + 1).toString(),
          url: videoTitle,
          endPoint: '/neou/v1/upload-mov',
          videoType: 'class',
        };
        dispatch(addVideoEvent(data));
      } else if (extString === '.mp4') {
        const data = {
          CORRELATION_ID: (Math.floor(Math.random() * 100000) + 1).toString(),
          url: videoTitle,
          endPoint: '/neou/v1/upload-mp4',
          videoType: 'class',
        };
        dispatch(addVideoEvent(data));
      }
      //   dispatch(addLiveEvent(data));
    } else if (
      (Object.keys(e).length === 0 && videoTitle && extString !== '.mov') ||
      extString !== '.mp4'
    ) {
      setError({ videoTitle: 'videoTitle" must be one of [.mp4, .mov]' });
    } else if (Object.keys(e).length === 0) {
      setError({ videoTitle: '' });
    }
  };

  function onClearEvent() {
    props.onClearEvent();
  }
  if (addVideoDialog) {
    props.onClearEvent();
  }

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <LabelInput
          label="Video URL"
          placeholder="Please Enter a Video URL"
          variant="outlined"
          id="custom-css-outlined-input"
          fullWidth={true}
          onChange={e => handleTitleInput(e)}
          error={!!error.videoTitle}
          helperText={error['videoTitle']}
        />
        <div className={classes.common}>
          <CancelButton
            key={1}
            title={'CANCEL'}
            clearAll={true}
            onClick={onClearEvent}
          />
          <AddButton title={'ADD VIDEO'} onClick={e => onAddEvent()} />
        </div>
      </Grid>
    </div>
  );
}
