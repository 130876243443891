import { drawerWidth, transition, container } from '../assets/jss/globalStyles';

// configurations

const appStyle = theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    overflowX: 'hidden',
  },
  main: {
    width: '100%',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      marginTop: '64px',
    },
  },
  noMatch: {
    padding: '0 20px',
  },
  mainPanel: {
    minHeight: '100vh',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: `84%`,
      maxWidth: `calc(100% - 266px)`,
      height: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '100%',
      maxWidth: 'calc(100% - 266px)',
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    overflowScrolling: 'touch',
    minHeight: '100%',
  },
  content: {
    marginTop: '70px',
    padding: '30px 15px',
    minHeight: 'calc(100vh - 123px)',
    ...transition,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    maxWidth: '100vw',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      marginLeft: 168,
      position: 'relative',
      left: 168,
    },
  },
  container,
  map: {
    marginTop: '70px',
  },
  // Main App bar
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#080B0D',
  },
  appBarShift: {
    width: `100vw`,
    left: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
});

export default appStyle;
