import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import logo from '../../images/neou@2x.png';
import { useStyles } from './style';
import PasswordField from '../../components/PasswordField';
import { createStructuredSelector } from 'reselect';
import makeSelectAuthentication from './selectors';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import saga from './saga';
import reducer from './reducer';
import { login, isLoginFetching } from './action';
import { encrypt } from '../../utils/crypto';
import { KEY_BASE64 } from '../../utils/config';
import { push } from 'connected-react-router';
import NEInput from '../../components/NEInput';
import { SignInSchema } from '../../validators/Authentication';
import { formError, customMessage } from '../../utils/validator';
import { ContainerState as AuthenticationState } from './types';

export interface IAuthenticationProps {}

const Image = styled.img`
  color: #ffffff;
`;

const stateSelector = createStructuredSelector({
  authentication: makeSelectAuthentication(),
});

export default function Authentication(props: IAuthenticationProps) {
  // Warning: Add your key to RootState in types/index.d.ts file
  useInjectReducer({ key: 'authentication', reducer: reducer });
  useInjectSaga({ key: 'authentication', saga: saga });
  const {
    authentication,
  }: { authentication: AuthenticationState } = useSelector(stateSelector);
  const { isAuth } = authentication;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [error, setError] = React.useState({ email: '', password: '' });
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleInput = e => {
    setEmail(e.target.value);
  };

  const handlePassword = e => {
    setPassword(e.target.value);
  };

  const validateForm = () => {
    const { error, value } = SignInSchema.validate(
      { email, password },
      {
        abortEarly: false,
        messages: customMessage,
      },
    );

    return formError(error);
  };

  const onSignIn = event => {
    dispatch(isLoginFetching(true));
    if (event) {
      event.preventDefault();
    }
    const err = validateForm();
    if (Object.keys(err).length !== 0) {
      dispatch(isLoginFetching(false));
    }
    setError(err);
    const data = {
      email: email,
      password: encrypt(password, KEY_BASE64),
    };
    if (Object.keys(err).length === 0) {
      dispatch(login(data));
    }
  };

  const onReset = () => {
    dispatch(push('/reset'));
  };

  return (
    <div className={classes.container}>
      <Grid container justify="center">
        <Grid item xs={4} className={classes.root}>
          <div className={classes.imgWrapper}>
            <Image src={logo} className={classes.img} />
            <div className={classes.admin}>Admin</div>
          </div>
          <form noValidate>
            <NEInput
              label="Email Address"
              variant="outlined"
              id="custom-css-outlined-input"
              fullWidth={true}
              onChange={e => handleInput(e)}
              error={!!error.email}
              helperText={error['email']}
              className={classes.emailField}
            />
            <PasswordField
              className={classes.passwordField}
              variant="outlined"
              password={password}
              setPassword={e => handlePassword(e)}
              error={!!error.password}
              helperText={error['password']}
            />
            <Button
              type="submit"
              color="primary"
              className={classes.signinBtn}
              disabled={isAuth}
              onClick={e => {
                onSignIn(e);
              }}
              startIcon={
                isAuth && (
                  <CircularProgress
                    className={classes.circularProgress}
                    size={20}
                  />
                )
              }
            >
              SIGN IN
            </Button>
            {/* <div
              className={classes.resetPass}
              onClick={() => {
                onReset();
              }}
            >
              Reset Password
            </div> */}
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
