import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import request from '../../../shared/lib/request';
// import other assets
import iconU from '../../../images/icon-U.svg';
import { Grid, ButtonBase } from '@material-ui/core';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import { genres } from './stub';
import Autocomplete from '../../../components/Autocomplete';
import Box from '@material-ui/core/Box';
import { useStyles } from '../styles';

import SelectMenu from './SelectMenu';
import Avatar from '@material-ui/core/Avatar';
import {
  requestPeopleDetail,
  updatePeopleInfo,
  requestPeopleList,
} from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import makeSelectPeople from '../selectors';
import moment from 'moment';
import GalleryDialog from '../../../components/Gallery/Gallery';
import { openSnackBar } from '../../Root/actions';
import { addPhoto, deletePhoto } from '../../../services/s3';
import {
  cloudfrontUrl,
  BUCKET_NAME,
  BUCKET_REGION,
} from '../../../utils/config';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { StyledSpinnerNext } from 'baseui/spinner';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { slugify } from '../../../utils/slugify';
import StyledTabs, {
  StyledTab,
} from '../../../components/VideoList/StylesTabs';
import {
  StatusLabel,
  StatusText,
  HistoryText,
  LabelText,
} from '../../../components/Typography';
import ImageUploader from '../../../components/ImageUploader';
import AddIcon from '@material-ui/icons/Add';
import NEAutocomplete from '../../../components/NEAutocomplete';
import ViewPackage from '../../../components/ViewPackage/ViewPackage';
import FormikField from '../../../components/Formik/FormikField';
import { Form, Formik } from 'formik';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: '#000',
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <img src={iconU} className={classes.iconU} />
      <Typography variant="h6">{children}</Typography>
      <div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dividers: {
    borderTop: '1px solid #D9D9D9',
    borderBottom: '1px solid #D9D9D9',
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(MuiDialogActions);

const useStylesLocal = makeStyles((theme: Theme) =>
  createStyles({
    packageWrapper: {
      width: '100%',
      padding: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      border: '1px solid rgba(212,216,220,0.5)',
      margin: '5px 0px',
      borderRadius: '5px',
    },
    bottomText: {
      paddingLeft: '10px',
      paddingBottom: '15px',
    },
    activeTime: {
      color: 'rgb(6, 11, 15)',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      lineHeight: '20px',
    },
    tag: {
      background: '#3C4042',
      color: '#fff',
      height: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    deleteIcon: {
      color: '#fff',
      width: '18px',
      opacity: 1,
      '&:hover': {
        color: '#fff',
        opacity: '0.5',
      },
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '20px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    dialogContainer: {
      borderRadius: 0,
      minWidth: '1032px',
    },
    spanHeader: {
      fontSize: '16px',
      fontFamily: 'ProximaNova-Bold',
      color: '#060B0F',
      //paddingLeft: '10px',
    },
    inputWrap: {
      '& .MuiInputBase-root': {
        '& .MuiInputBase-input': {
          color: '#060B0F !important',
          fontFamily: 'Proxima-Nova-Regular',
          fontSize: '16px',
          fontWeight: 500,
        },
      },
      '& .MuiOutlinedInput-inputMultiline': {
        padding: '0px',
      },
    },
    noData: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '20px',
      color: '#060B0F',
      width: '524px',
      padding: '20px',
      textAlign: 'center',
    },
    imageBox: {
      height: '252px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: '#12161A',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '17px',
    },
    galleryDrop: {
      height: '142px',
      width: 'auto',
      borderRadius: '6px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '180px',
      height: '180px',
      borderRadius: '50%',
      textAlign: 'center',
      color: 'white',
    },
    uploadBtn: {
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      textAlign: 'center',
      backgroundColor: '#3c4042',
      textTransform: 'capitalize',
      borderRadius: '20px',
      padding: '0px 10px',
      cursor: 'pointer',
    },
    flag: {
      marginBottom: '20px',
    },
    subTitle: {
      color: '#060B0F',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    subInTitle: {
      color: '#000000',
      fontSize: '14px',
      // fontFamily: 'ProximaNova-Bold',
      // fontWeight: 'bold',
      // textTransform: 'capitalize',
      lineHeight: '20px',
    },
    marginTitle: {
      marginTop: '20px',
      padding: '16px',
    },
    inputHidden: {
      display: 'none',
    },
    manageGallery: {
      textTransform: 'capitalize',
      color: '#FFFFFF',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: '#12161A',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 22px',
      position: 'absolute',
      height: '24px',
      top: 'calc(50% - 12px)',
    },
    galleryDiv: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      marginBottom: '16px',
    },
    hiddenStatus: {
      color: '#000000',
      fontFamily: 'ProximaNova-Bold',
      fontSize: '14px',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      lineHeight: '20px',
    },
    spanErrorSpecialty: {
      color: '#FF1C58',
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
      position: 'relative',
      bottom: '16px',
      left: '2px',
    },
    spanError: {
      color: '#FF1C58',
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    spacing: {
      padding: '5px',
    },
    styledTab: {
      width: '100%',
    },
    packageBtn: {
      float: 'right',
      minHeight: '32px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 20px',
    },
    package: {
      width: '100%',
      height: '28px',
    },
    viewPackages: {
      textTransform: 'capitalize',
      color: '#12161A',
      marginTop: '10px',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 10px',
      height: '24px',
    },
    createMeet: {
      textDecoration: 'underline',
      color: '#12161A',
      marginBottom: '15px',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '13px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      cursor: 'pointer',
      border: '1px solid rgba(151,151,151,0.2)',
      borderRadius: '10.2px',
      boxSizing: 'border-box',
      padding: '3px 10px',
      height: '30px',
      width: '100%',
    },
    instructorGrid: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
  }),
);

const stateSelector = createStructuredSelector({
  people: makeSelectPeople(),
});

const SLUG_PREFIX = '/person/';

export default function EditDialog({
  isOpen,
  handleClose,
  title,
  id,
  isInteractiveFilterOn,
}) {
  const classes = useStylesLocal();

  //local states
  const [isGalleryDialogOpen, openGalleryDialog] = React.useState(false);
  const [
    isInteractiveGalleryDialogOpen,
    openInteractiveGalleryDialog,
  ] = React.useState(false);
  const [isConfirmDialogOpen, openConfirmDialog] = React.useState(false);
  let [img, setImg] = React.useState('');
  const [imgObj, setImgObj] = useState({});
  const [interactiveImgObj, setInteractiveImgObj] = useState({});
  const [values, setValues] = useState({});
  const [delData, setDelData] = useState({ key: '', isTrue: Boolean });
  const [loader, setLoader] = useState(false);
  const [error, setError] = React.useState({});
  const [errImg, setErrImg] = useState(false);
  const [isCloseAlertOpen, showAlertToClose] = React.useState(false);
  const [copyIdBtnText, setCopyIdBtnText] = useState('Copy');
  const [errSpecialty, setErrSpecialty] = useState(true);
  const [isConfirmPublishOpen, openConfirmPublishDialog] = React.useState(
    false,
  );
  const [activeTab, setActiveTab] = React.useState(0);
  const [isInteractiveInstructor, setIsInteractiveInstructor] = React.useState(
    false,
  );

  const dispatch = useDispatch();

  const { people } = useSelector(stateSelector);
  let {
    specialtyValues,
    flagValues,
    instructorDetail,
    instructorPackage,
    isInstructorLoading,
  } = people;
  let {
    firstName = '',
    lastName = '',
    permaLink = '',
    charity = {
      name: '',
      url: '',
    },
    gallery = [],
    flag,
    adminTags,
    status,
    website = '',
    facebook = '',
    twitter = '',
    instagram = '',
    imageUrl,
    description,
    publishedDate,
    specialty,
    associatedConcepts,
    associatedCollections,
    createdAt,
    updatedAt,
    isHidden,
    isPublished,
    interactiveConcepts,
    interactive = {
      firstName: '',
      lastName: '',
      connectId: '',
      permaLink: '',
      gallery: [],
      graphics: {},
      bannerImg: '',
      flag: '',
      adminTags,
      status,
      website: '',
      facebook: '',
      twitter: '',
      instagram: '',
      imageUrl,
      description,
      publishedDate,
      specialty,
      associatedConcepts,
      associatedCollections,
      createdAt,
      updatedAt,
      isHidden,
      isPublished,
      promocodes: [],
    },
  } = values || {};
  let galleryUrl = gallery.filter(i => i.type === 'image').slice(0);
  let galleryInteractiveUrl =
    interactive &&
    interactive.gallery &&
    interactive.gallery.filter(i => i.type === 'image').slice(0);

  // img = imageUrl;

  useEffect(() => {
    setImg(img);
  }, [img]);

  useEffect(() => {
    if (isOpen) {
      dispatch(requestPeopleDetail(id));
      setErrImg(false);
      setErrSpecialty(false);
      setError('');

      if (specialty) {
        setErrSpecialty(true);
      } else if (interactive && interactive.specialty) {
        setErrSpecialty(true);
      } else {
        setErrSpecialty(false);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (values && values.specialty && values.specialty.length > 0) {
      setErrSpecialty(true);
    } else if (
      values &&
      values.interactive &&
      values.interactive.specialty &&
      values.interactive.specialty.length > 0
    ) {
      setErrSpecialty(true);
    } else {
      setErrSpecialty(false);
    }
  }, [values]);

  useEffect(() => {
    if (instructorDetail) {
      let {
        permaLink = '',
        firstName = '',
        lastName = '',
        specialty = [],
        interactive,
      } = instructorDetail;

      setValues({
        ...instructorDetail,
        permaLink: !permaLink.includes(SLUG_PREFIX)
          ? `${SLUG_PREFIX}${slugify(`${firstName} ${lastName}`)}`
          : permaLink,
        // specialty: specialty.map(e => e.id),
      });
      setIsInteractiveInstructor(interactive);
    }
  }, [instructorDetail]);

  const getBaseUrl = async e => {
    setLoader(true);
    const imgUrl = URL.createObjectURL(e.target.files[0]);

    let result = await addPhoto('people', e.target.files);

    let image = {
      imageUrl: result.Location.replace(
        'https://' + BUCKET_NAME + '.s3.' + BUCKET_REGION + '.amazonaws.com',
        cloudfrontUrl,
      ),
      instrId: id,
    };
    setErrImg(true);
    setImg(image.imageUrl);
    setValues({ ...values, imageUrl: image.imageUrl, instrId: id });
    setLoader(false);
  };

  const getInteractiveBaseUrl = async e => {
    setLoader(true);
    const imgUrl = URL.createObjectURL(e.target.files[0]);

    let result = await addPhoto('people', e.target.files);

    let image = {
      interactive: {
        imageUrl: result.Location.replace(
          'https://' + BUCKET_NAME + '.s3.' + BUCKET_REGION + '.amazonaws.com',
          cloudfrontUrl,
        ),
      },
      instrId: id,
    };
    setErrImg(true);
    setImg(image.imageUrl);
    setValues({
      ...values,
      interactive: {
        ...interactive,
        imageUrl: image.interactive && image.interactive.imageUrl,
      },
      instrId: id,
    });
    setLoader(false);
  };

  let instructorId = '';
  instructorId = id;

  const handleInput = e => {
    setValues({ ...values, [e.target.name]: e.target.value, instrId: id });
  };
  const handleInteractive = e => {
    let tempInteractive = values.interactive;
    tempInteractive[e.target.name] = e.target.value;
    setValues({
      ...values,
      interactive: tempInteractive,
      instrId: id,
    });
  };
  const galleryData = images => {
    let finalData: any;
    finalData = {
      gallery: images,
      interactive: {
        ...interactive,
      },
      instrId: id,
      isPublished: false,
      isHidden: true,
    };
    setImgObj(images);
  };

  const galleryInteractiveData = images => {
    let finalData: any;
    finalData = {
      interactive: {
        ...interactive,
        gallery: images,
        isPublished: false,
        isHidden: true,
      },
      instrId: id,
    };
    setInteractiveImgObj(images);
  };

  //for delete functionality -s3
  const isDelete = (delImageKey, fromDelete) => {
    if (fromDelete) {
      let delValues = {
        key: delImageKey,
        isTrue: fromDelete,
      };
      setDelData(delValues);
      let delResult = deletePhoto('people', delImageKey);
    }
  };
  const handleSaveBtn = () => {
    openGalleryDialog(false);
    // let finalData = {
    //   gallery: imgObj,
    //   interactive: {
    //     ...interactive,
    //   },
    //   instrId: id,
    //   isPublished: isPublished,
    //   isHidden: isHidden,
    // };
    setValues({
      ...values,
      interactive: { ...interactive },
      gallery: imgObj,
      instrId: id,
    });
    //dispatch(updatePeopleInfo(finalData));
    //setGalleryImg(imgObj);
    //dispatch(requestPeopleDetail(id));
  };

  const handleInteractiveSaveBtn = () => {
    openInteractiveGalleryDialog(false);
    // let finalData = {
    //   interactive: {
    //     ...interactive,
    //     gallery: imgObj,
    //     isPublished: interactive && interactive.isPublished,
    //     isHidden: interactive && interactive.isHidden,
    //   },
    //   instrId: id,
    // };
    setValues({
      ...values,
      interactive: {
        ...interactive,
        gallery: interactiveImgObj,
      },
      instrId: id,
    });
    //dispatch(updatePeopleInfo(finalData));
    //setGalleryImg(imgObj);
    //dispatch(requestPeopleDetail(id));
  };

  const saveInstructor = (btnVal = 'submit') => {
    if (activeTab === 0 && isInteractiveInstructor) {
      let firstNameErr = document.getElementById('firstName').value;
      let instagramErr = document.getElementById('instagram').value || '';
      let facebookErr = document.getElementById('facebook').value || '';
      let twitterErr = document.getElementById('twitter').value || '';
      let websiteErr = document.getElementById('website').value || '';
      let imageErr = errImg || imageUrl;
      let permaLinkErr = document.getElementById('permaLink').value;
      let descriptionErr = document.getElementById('description').value;
      let specialtyErr = values && values.specialty ? values.specialty : [];

      let v = {
        instrId: instructorDetail.id,
        firstName,
        imageUrl,
        permaLink,
        description,
        lastName,
        flag,
        adminTags,
        instagram,
        facebook,
        twitter,
        website,
        gallery,
        interactive: {
          ...instructorDetail.interactive,
          specialty:
            interactive &&
            interactive.specialty &&
            interactive.specialty.length > 0
              ? interactive.specialty
                  .map(s => s.id || s)
                  .filter(s => s !== null && s !== undefined)
              : [],
        },
        specialty:
          specialty && specialty.length > 0
            ? specialty
                .map(s => (s && s.id) || s)
                .filter(s => s !== null && s !== undefined)
            : [],
        isPublished: btnVal === 'submit' ? isPublished : true,
      };
      if (v.interactive) {
        if (v.interactive.interactiveConcepts) {
          delete v.interactive.interactiveConcepts;
        }
        if (v.interactive.associatedConcepts) {
          delete v.interactive.associatedConcepts;
        }
        if (v.interactive.associatedCollections) {
          delete v.interactive.associatedCollections;
        }
      }
      let finalData = {
        ...v,
        isPublished: btnVal === 'submit' ? isPublished : true,
        isHidden: btnVal === 'submit' ? isHidden : false,
        imageUrl: isPublished === true ? v.imageUrl : v.imageUrl || '',
      };

      const formError = validateFields({
        firstNameErr,
        permaLinkErr,
        descriptionErr,
        instagramErr,
        facebookErr,
        twitterErr,
        websiteErr,
      });

      if (
        !firstNameErr ||
        !permaLinkErr ||
        !descriptionErr ||
        (instagramErr !== '' && !instagramErr.includes('https')) ||
        (facebookErr !== '' && !facebookErr.includes('https')) ||
        (twitterErr !== '' && !twitterErr.includes('https')) ||
        (websiteErr !== '' && !websiteErr.includes('https'))
      ) {
        setError(formError);
        return null;
      } else {
        const data = {
          updateInfo: finalData,
          isPublish: btnVal === 'publish' ? true : false,
        };
        dispatch(updatePeopleInfo(data));
      }
    } else if (activeTab === 0 && !isInteractiveInstructor) {
      let firstNameErr = document.getElementById('firstName').value;
      let instagramErr = document.getElementById('instagram').value || '';
      let facebookErr = document.getElementById('facebook').value || '';
      let twitterErr = document.getElementById('twitter').value || '';
      let websiteErr = document.getElementById('website').value || '';
      let permaLinkErr = document.getElementById('permaLink').value;
      let descriptionErr = document.getElementById('description').value;

      let v = {
        instrId: instructorDetail.id,
        firstName,
        imageUrl,
        gallery,
        permaLink,
        description,
        lastName,
        flag,
        adminTags,
        instagram,
        facebook,
        twitter,
        website,
        specialty:
          specialty && specialty.length > 0
            ? specialty
                .map(s => (s && s.id) || s)
                .filter(s => s !== null && s !== undefined)
            : [],
        isPublished: btnVal === 'submit' ? isPublished : true,
      };
      let finalData = {
        ...v,
        isPublished: btnVal === 'submit' ? isPublished : true,
        isHidden: btnVal === 'submit' ? isHidden : false,
        imageUrl: isPublished === true ? v.imageUrl : v.imageUrl || '',
      };
      const formError = validateFields({
        firstNameErr,
        instagramErr,
        facebookErr,
        twitterErr,
        websiteErr,
        permaLinkErr,
        descriptionErr,
      });

      if (
        !firstNameErr ||
        !permaLinkErr ||
        !descriptionErr ||
        (instagramErr !== '' && !instagramErr.includes('https')) ||
        (facebookErr !== '' && !facebookErr.includes('https')) ||
        (twitterErr !== '' && !twitterErr.includes('https')) ||
        (websiteErr !== '' && !websiteErr.includes('https'))
      ) {
        setError(formError);
        return null;
      } else {
        const data = {
          updateInfo: finalData,
          isPublish: btnVal === 'publish' ? true : false,
        };
        dispatch(updatePeopleInfo(data));
      }
    } else {
      let firstNameErr = document.getElementById('interactiveFirstName').value;
      let emailErr = document.getElementById('interactiveEmail').value;
      // let connectIdErr =
      //   document.getElementById('interactiveConnectId').value || '';
      let instagramErr =
        document.getElementById('interactive-instagram').value || '';
      let facebookErr =
        document.getElementById('interactive-facebook').value || '';
      let twitterErr =
        document.getElementById('interactive-twitter').value || '';
      let websiteErr =
        document.getElementById('interactive-website').value || '';
      let permaLinkErr = document.getElementById('interactive-permaLink').value;
      let descriptionErr = document.getElementById('interactive-description')
        .value;
      let charityData = {
        name: document.getElementById('charity-name').value,
        url: document.getElementById('charity-url').value,
      };

      let v = {
        instrId: instructorDetail.id,
        interactiveConcepts,
        charity: { ...charity, ...charityData },
        interactive: {
          ...instructorDetail.interactive,
          ...interactive,
          specialty:
            interactive &&
            interactive.specialty &&
            interactive.specialty.length > 0
              ? interactive.specialty
                  .map(s => (s && s.id) || s)
                  .filter(s => s !== null && s !== undefined)
              : [],
          isPublished:
            btnVal === 'submit'
              ? instructorDetail &&
                instructorDetail.interactive &&
                instructorDetail.interactive.isPublished
              : true,
          isHidden:
            btnVal === 'submit'
              ? instructorDetail &&
                instructorDetail.interactive &&
                instructorDetail.interactive.isHidden
              : false,
        },
      };
      if (v.interactive) {
        if (v.interactive.interactiveConcepts) {
          delete v.interactive.interactiveConcepts;
        }
        if (v.interactive.associatedConcepts) {
          delete v.interactive.associatedConcepts;
        }
        if (v.interactive.associatedCollections) {
          delete v.interactive.associatedCollections;
        }
        if (activeTab === 1 && v && v.interactive) {
          v.interactive.updatedAt = moment().format('YYYY-MM-DDTHH:mm:ssZ');
          if (v.interactive && v.interactive.isPublished)
            v.interactive.publishedDate = moment().format(
              'YYYY-MM-DDTHH:mm:ssZ',
            );
        }
      }
      let finalData = {
        ...v,
      };

      const interactiveFormError = validateInteractiveFields({
        firstNameErr,
        emailErr,
        // connectIdErr,
        instagramErr,
        facebookErr,
        twitterErr,
        websiteErr,
        permaLinkErr,
        descriptionErr,
      });

      if (
        !firstNameErr ||
        (btnVal === 'publish' &&
          // !connectIdErr ||
          !emailErr) ||
        (v.interactive.isPublished &&
          //!connectIdErr ||
          !emailErr) ||
        !permaLinkErr ||
        !descriptionErr ||
        (instagramErr !== '' && !instagramErr.includes('https')) ||
        (facebookErr !== '' && !facebookErr.includes('https')) ||
        (twitterErr !== '' && !twitterErr.includes('https')) ||
        (websiteErr !== '' && !websiteErr.includes('https'))
      ) {
        setError(interactiveFormError);
        return null;
      } else {
        const data = {
          updateInfo: finalData,
          isPublish: btnVal === 'publish' ? true : false,
        };
        dispatch(updatePeopleInfo(data));
      }
    }
    setError('');

    dispatch(requestPeopleList());
    handleClose();
  };
  //save & publish handle
  const handleFormSubmit = btnVal => {
    if (activeTab === 1) {
      if (interactive && interactive.isPublished) {
        openConfirmPublishDialog(true);
      } else {
        saveInstructor(btnVal);
      }
    } else {
      if (isPublished) {
        openConfirmPublishDialog(true);
      } else {
        saveInstructor(btnVal);
      }
    }
  };

  //Preview screen
  const previewInstructor = tab => {
    // let firstNameErr = document.getElementById('firstName').value;
    // let permaLinkErr = document.getElementById('permaLink').value;
    // let descriptionErr = document.getElementById('description').value;
    // let specialtyErr = (values && values.specialty) || specialty || [];
    setError('');
    // dispatch(updatePeopleInfo(v));
    window.open(
      `${window.origin}/people-preview/${instructorDetail.id}/${tab}`,
    );
    dispatch(requestPeopleDetail(id));
    // dispatch(requestPeopleList());
    handleClose();
    // let v = {
    //   instrId: instructorDetail.id,
    //   firstName,
    //   permaLink,
    //   description,
    //   specialty: specialty.map(s => s.id),
    //   ...values,
    // };
    // const formError = validateFields({
    //   firstNameErr,
    //   permaLinkErr,
    //   descriptionErr,
    //   // specialtyErr,
    // });
    // if (
    //   !firstNameErr ||
    //   !permaLinkErr ||
    //   !descriptionErr
    //   // !(specialtyErr.length > 0)
    // ) {
    //   setError(formError);
    //   return null;
    // } else {
    //   setError('');
    //   // dispatch(updatePeopleInfo(v));
    //   dispatch(push(`/people-preview?id=${instructorDetail.id}`));
    //   // window.open(`${window.origin}/people-preview?id=${instructorDetail.id}`);
    //   dispatch(requestPeopleDetail(id));
    //   // dispatch(requestPeopleList());
    //   handleClose();
    // }
  };

  const handleOk = () => {
    if (activeTab === 0) {
      let updateInfo = {
        instrId: instructorDetail.id,
        ...values,
        specialty:
          specialty && specialty.length > 0
            ? specialty
                .map(s => s.id || s)
                .filter(s => s !== null && s !== undefined)
            : [],
        interactive: {
          ...interactive,
          specialty:
            interactive &&
            interactive.specialty &&
            interactive.specialty.length > 0
              ? interactive.specialty
                  .map(s => s.id || s)
                  .filter(s => s !== null && s !== undefined)
              : [],
        },
        isHidden: true,
        isPublished: false,
      };
      const data = {
        updateInfo: updateInfo,
        isPublish: false,
      };
      dispatch(updatePeopleInfo(data));
    } else {
      let updateInfo = {
        instrId: instructorDetail.id,
        charity: { ...charity },
        interactive: {
          ...interactive,
          specialty:
            interactive &&
            interactive.specialty &&
            interactive.specialty.length > 0
              ? interactive.specialty
                  .map(s => s.id || s)
                  .filter(s => s !== null && s !== undefined)
              : [],
          isHidden: true,
          isPublished: false,
          updatedAt: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        },
      };
      const data = {
        updateInfo: updateInfo,
        isPublish: false,
      };
      dispatch(updatePeopleInfo(data));
    }
    handleClose();
    dispatch(requestPeopleList());
    dispatch(requestPeopleDetail(instructorDetail.id));
    openConfirmDialog(false);
  };

  const generatePermalink = () => {
    let firstName = values.firstName || '';
    let lastName = values.lastName || '';

    setValues({
      ...values,
      permaLink: `${SLUG_PREFIX}${slugify(`${firstName} ${lastName}`)}`,
    });
  };

  const generatePermalinkInteractive = () => {
    let firstName = (values.interactive && values.interactive.firstName) || '';
    let lastName = (values.interactive && values.interactive.lastName) || '';

    setValues({
      ...values,
      interactive: {
        ...interactive,
        permaLink: `${SLUG_PREFIX}${slugify(`${firstName} ${lastName}`)}`,
      },
    });
  };

  const onTabChange = (event: React.ChangeEvent<{}>, tabIndex: number) => {
    setActiveTab(tabIndex);
  };
  const tabConfig = [
    {
      label: 'VOD',
      component: <div></div>,
    },
    {
      label: 'INTERACTIVE',
      component: <div></div>,
    },
  ];

  const enableForInteractive = async values => {
    const framedData = frameData(values, false);
    const payload = {
      instrId: id,
      charity: { ...charity },
      interactive: {
        ...framedData,
        associatedConcepts: [],
        graphics: {},
        bannerImg: '',
        isPublished: false,
        isHidden: false,
        createdAt: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        updatedAt: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        videos: {
          videoIds: [],
        },
      },
    };
    if (payload) {
      if (payload.interactive.interactiveConcepts) {
        delete payload.interactive.interactiveConcepts;
      }
      if (payload.interactive.associatedConcepts) {
        delete payload.interactive.associatedConcepts;
      }
      if (payload.interactive.associatedCollections) {
        delete payload.interactive.associatedCollections;
      }
    }
    saveInteractiveInstructor(payload);
  };

  //handling img - s3
  const handleImage = async (e, graphicsobj) => {
    try {
      let files = e.target.files;
      if (files && files.length > 0) {
        // setLoader(true);
        let result = await addPhoto('concept', files);
        let gResult = result.Location.replace(
          'https://' + BUCKET_NAME + '.s3.' + BUCKET_REGION + '.amazonaws.com',
          cloudfrontUrl,
        );
        setValues({
          ...values,
          interactive: {
            ...interactive,
            bannerImg: gResult,
          },
        });

        // setLoader(false);
      }
    } catch (error) {}
  };

  const saveInteractiveInstructor = async payload => {
    try {
      await request({
        url: '/neouadmin/v1/people',
        method: 'PUT',
        data: payload,
      });
      setLoader(false);
      dispatch(
        openSnackBar(
          'The Interactive Instructors has been updated successfully!',
        ),
      );
      handleClose();
    } catch ({ data: error }) {
      setLoader(false);
      if (error.error && typeof error.data === 'string') {
        dispatch(
          openSnackBar(`Update Interactive Instructor Failed - ${error.error}`),
        );
      } else {
        dispatch('Error in saving the instructor...');
      }
    }
  };

  const frameData = (values: any, isInteractiveObject: boolean = true) => {
    let framedData = {
      ...values,
      instructors: values.instructors ? values.instructors.map(i => i.id) : [],
      categories: values.categories ? values.categories.map(i => i.id) : [],
      specialty: values.specialty
        ? values.specialty
            .map(i => i.id)
            .filter(s => s !== null && s !== undefined)
        : [],
      genres: values.genres ? values.genres.map(i => i.id) : [],
      videos: {
        videoIds: [],
      },
      flag: values.flag === 'None' ? '' : values.flag,
    };

    if (values.videos && values.videos.videoIds && values.videos.videoIds) {
      framedData.videos.videoIds = values.videos.videoIds.map(
        (v, index) => v.id,
      );
    }

    if (isInteractiveInstructor && isInteractiveObject) {
      frameData.interactive = values.interactive ? values.interactive : {};

      frameData.interactive.instructors = values.interactive.instructors
        ? values.interactive.instructors.map(i => i.id)
        : [];
      frameData.interactive.specialty = values.interactive.specialty
        ? values.interactive.specialty
            .map(i => i.id)
            .filter(s => s !== null && s !== undefined)
        : [];
      frameData.interactive.categories = values.interactive.categories
        ? values.interactive.categories.map(i => i.id)
        : [];
      frameData.interactive.genres = values.interactive.genres
        ? values.interactive.genres.map(i => i.id)
        : [];
      frameData.interactive.videos = values.interactive.videos
        ? values.interactive.videos
        : { videoIds: [] };

      if (
        values.interactive.videos &&
        values.interactive.videos.videoIds &&
        values.interactive.videos.videoIds
      ) {
        framedData.interactive.videos.videoIds = values.interactive.videos.videoIds.map(
          (v, index) => v.id,
        );
      }
    }

    return framedData;
  };
  const getTextAreaContent = () => {
    let copyTextarea = instructorDetail.id;
    navigator.clipboard.writeText(copyTextarea).then(
      function() {
        setCopyIdBtnText('Copied');
      },
      function(err) {
        setCopyIdBtnText('Copy');
        dispatch(openSnackBar('Could not copy instructor Id!'));
      },
    );
  };

  if (isInstructorLoading) return null;
  return (
    <div>
      <ConfirmDialog
        isOpen={isConfirmPublishOpen}
        headerText="Are you sure?"
        bodyText="This content is already published. Changes will impact existing behavior"
        handleClose={() => openConfirmPublishDialog(false)}
        onAgree={() => {
          saveInstructor();
        }}
        onDisagree={() => openConfirmPublishDialog(false)}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        disableBackdropClick={true}
        classes={{
          paperScrollPaper: classes.dialogContainer,
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => showAlertToClose(true)}
        >
          {title}
          <div></div>
        </DialogTitle>
        <DialogContent dividers>
          <ConfirmDialog
            isOpen={isCloseAlertOpen}
            headerText="Are you sure?"
            bodyText="You are about to close the instructor profile."
            onAgree={() => handleClose()}
            handleClose={() => showAlertToClose(false)}
            onDisagree={() => showAlertToClose(false)}
          ></ConfirmDialog>
          <StyledTabs
            value={activeTab}
            onChange={onTabChange}
            aria-label="My account tabs"
            centered
          >
            {tabConfig.map((t, index) => (
              <StyledTab
                className={classes.styledTab}
                key={index}
                label={t.label}
              />
            ))}
          </StyledTabs>

          <Grid container spacing={2} className={classes.marginTitle}>
            {activeTab === 1 && !isInteractiveInstructor ? (
              <Grid
                container
                alignItems="flex-start"
                justify={'center'}
                style={{ minHeight: '500px' }}
                spacing={2}
              >
                <Grid
                  item
                  xs={8}
                  alignItems="flex-start"
                  justify={'center'}
                  container
                >
                  <div className={classes.noData}>
                    This instructor does not currently appear on NEOU
                    Interactive.
                  </div>
                  <Button
                    variant="contained"
                    onClick={() => enableForInteractive(values)}
                    color="secondary"
                    size="large"
                    disabled={!!id ? false : true}
                  >
                    ENABLE FOR NEOU INTERACTIVE
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {activeTab === 0 ? (
                  <>
                    <Grid item xs={9} className={classes.spacing}>
                      <Box component="span" className={classes.spanHeader}>
                        Profile*
                      </Box>
                    </Grid>
                    <Grid item xs={3} className={classes.spacing}>
                      <Box component="span" className={classes.spanHeader}>
                        Profile Image 1:1*
                      </Box>
                    </Grid>
                    <Grid container xs={9} className={classes.spacing}>
                      <Grid item xs={6} className={classes.spacing}>
                        <NEInput
                          label="First Name"
                          variant="outlined"
                          fullWidth={true}
                          required
                          name="firstName"
                          id="firstName"
                          defaultValue={firstName}
                          onChange={e => handleInput(e)}
                          error={!!error.firstName || ''}
                          helperText={error.firstName || ''}
                          onBlur={generatePermalink}
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.spacing}>
                        <NEInput
                          label="Last Name"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.inputWrap}
                          name="lastName"
                          defaultValue={lastName}
                          onChange={e => handleInput(e)}
                          onBlur={generatePermalink}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.spacing}>
                        <NEInput
                          label="Permalink"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.inputWrap}
                          name="permaLink"
                          id="permaLink"
                          value={values.permaLink}
                          onChange={e => handleInput(e)}
                          required
                          error={!!error.permaLink || ''}
                          helperText={error.permaLink || ''}
                          onBlur={() => {
                            if (!permaLink.includes(SLUG_PREFIX)) {
                              setValues({
                                ...values,
                                permaLink: `${SLUG_PREFIX}${slugify(
                                  `${firstName} ${lastName}`,
                                )}`,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.spacing}>
                        <NEAutocomplete
                          multiple
                          options={specialtyValues}
                          label="Specialty"
                          id="specialty"
                          defaultValue={specialty || []}
                          name="specialty"
                          onChange={(e, data) => {
                            setValues({
                              ...values,
                              specialty: data,
                              instrId: id,
                            });
                          }}
                        />
                        <NEInput
                          //id="flag"
                          name="flag"
                          label="Flag"
                          defaultValue={flag}
                          select
                          fullWidth
                          variant="outlined"
                          onChange={e => {
                            setValues({
                              ...values,
                              flag: e.target.value,
                              instrId: id,
                            });
                          }}
                        >
                          <MenuItem value="None">None</MenuItem>
                          {flagValues &&
                            flagValues.map(item => {
                              return (
                                <MenuItem value={item && item.title}>
                                  {item && item.title}
                                </MenuItem>
                              );
                            })}
                        </NEInput>
                        <Autocomplete
                          multiple
                          freeSolo
                          options={[]}
                          label="Admin Tags"
                          defaultValue={adminTags}
                          popupIcon={'+'}
                          required
                          name="adminTags"
                          renderTags={(value: string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                classes={{
                                  tag: classes.tag,
                                  label: classes.chipLabel,
                                  deleteIcon: classes.deleteIcon,
                                }}
                              />
                            ))
                          }
                          onChange={(e, data) => {
                            setValues({
                              ...values,
                              adminTags: data,
                              instrId: instructorId,
                            });
                          }}
                        />
                        {associatedConcepts && associatedConcepts.length > 0 ? (
                          <div className={classes.bottomText}>
                            <Box component="span" className={classes.subTitle}>
                              Associated Concepts
                            </Box>
                            {associatedConcepts &&
                              associatedConcepts.map(concept => {
                                return (
                                  <div>
                                    <Box
                                      component="span"
                                      className={classes.subInTitle}
                                    >
                                      {concept}
                                    </Box>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          ''
                        )}
                        {associatedCollections &&
                        associatedCollections.length > 0 ? (
                          <div className={classes.bottomText}>
                            <Box component="span" className={classes.subTitle}>
                              Associated Collections
                            </Box>
                            {associatedCollections &&
                              associatedCollections.map(collection => {
                                return (
                                  <div>
                                    <Box
                                      component="span"
                                      className={classes.subInTitle}
                                    >
                                      {collection}
                                    </Box>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          ''
                        )}
                        <Grid item className={classes.bottomText}>
                          <Typography variant="h6">HISTORY</Typography>
                          <HistoryText>
                            Added:{' '}
                            {moment(values.createdAt).format('MM/DD/YYYY')}
                          </HistoryText>
                          {values.publishedDate && (
                            <HistoryText>
                              Published:
                              {moment(values.publishedDate).format(
                                'MM/DD/YYYY',
                              )}
                            </HistoryText>
                          )}
                          <HistoryText>
                            Updated:{' '}
                            {moment(values.updatedAt).format('MM/DD/YYYY')}
                          </HistoryText>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className={classes.spacing}>
                        <NEInput
                          label="Description"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.inputWrap}
                          required
                          multiline
                          rows="4"
                          id="description"
                          name="description"
                          defaultValue={description}
                          onChange={e => handleInput(e)}
                          error={!!error.description || ''}
                          helperText={error.description || ''}
                        />

                        <Grid
                          item
                          xs={12}
                          className={classes.spacing}
                          style={{ paddingTop: '10px' }}
                        >
                          <NEInput
                            label="Instagram"
                            variant="outlined"
                            fullWidth={true}
                            className={classes.inputWrap}
                            name="instagram"
                            id="instagram"
                            defaultValue={instagram}
                            onChange={e => handleInput(e)}
                            error={(error && !!error.instagram) || ''}
                            helperText={(error && error.instagram) || ''}
                          />
                          <NEInput
                            label="Facebook"
                            variant="outlined"
                            fullWidth={true}
                            className={classes.inputWrap}
                            name="facebook"
                            id="facebook"
                            defaultValue={facebook}
                            onChange={e => handleInput(e)}
                            error={(error && !!error.facebook) || ''}
                            helperText={(error && error.facebook) || ''}
                          />
                          <NEInput
                            label="Twitter URL"
                            variant="outlined"
                            fullWidth={true}
                            className={classes.inputWrap}
                            name="twitter"
                            id="twitter"
                            defaultValue={twitter}
                            onChange={e => handleInput(e)}
                            error={(error && !!error.twitter) || ''}
                            helperText={(error && error.twitter) || ''}
                          />
                          <NEInput
                            label="Web URL"
                            variant="outlined"
                            fullWidth={true}
                            className={classes.inputWrap}
                            name="website"
                            id="website"
                            defaultValue={website}
                            onChange={e => handleInput(e)}
                            error={(error && !!error.website) || ''}
                            helperText={(error && error.website) || ''}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className={classes.spacing}></Grid>
                    </Grid>

                    <Grid container xs={3} className={classes.spacing}>
                      <Grid item xs={12} className={classes.spacing}>
                        <div className={classes.imageBox}>
                          <div className={classes.image}>
                            {loader ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'relative',
                                  top: '40%',
                                }}
                              >
                                <StyledSpinnerNext
                                  style={{ borderTopColor: 'black' }}
                                />
                              </div>
                            ) : (
                              <Avatar
                                alt="img"
                                src={img || imageUrl}
                                className={classes.image}
                              ></Avatar>
                            )}
                          </div>
                          {loader ? (
                            ''
                          ) : (
                            <label className={classes.uploadBtn}>
                              <input
                                accept="image/*"
                                className={classes.inputHidden}
                                id="icon-button-file"
                                type="file"
                                onChange={e => getBaseUrl(e)}
                              />
                              Update Image
                            </label>
                          )}
                        </div>
                        {error && error.imageUrl ? (
                          <div>
                            <Box component="span" className={classes.spanError}>
                              {error.imageUrl}
                            </Box>
                          </div>
                        ) : (
                          ''
                        )}
                        <Box component="span" className={classes.spanHeader}>
                          INSTRUCTOR PAGE
                          <LabelText> </LabelText>
                        </Box>
                        <div className={classes.galleryDiv}>
                          <div>
                            <Avatar
                              alt="img"
                              src={
                                (galleryUrl &&
                                  galleryUrl.length > 0 &&
                                  galleryUrl[0].url) ||
                                ''
                              }
                              className={classes.galleryDrop}
                            />
                          </div>
                          <div
                            className={classes.manageGallery}
                            onClick={() => {
                              setTimeout(() => {
                                openGalleryDialog(true);
                              });
                            }}
                          >
                            Manage Gallery
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        {isGalleryDialogOpen && (
                          <GalleryDialog
                            isOpen={isGalleryDialogOpen}
                            id={'1'}
                            key={'1'}
                            title="MANAGE INSTRUCTOR GALLERY"
                            galleryDetails={gallery}
                            handleSaveBtn={handleSaveBtn}
                            isDelete={isDelete}
                            type="people"
                            handleClose={() => {
                              openGalleryDialog(false);
                            }}
                            galleryData={galleryData}
                          ></GalleryDialog>
                        )}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={9} className={classes.spacing}>
                      <Box component="span" className={classes.spanHeader}>
                        Interactive Profile*
                      </Box>
                    </Grid>
                    <Grid item xs={3} className={classes.spacing}>
                      <Box component="span" className={classes.spanHeader}>
                        Interactive Profile Image 1:1*
                      </Box>
                    </Grid>
                    <Grid container xs={9} className={classes.spacing}>
                      <Grid item xs={6} className={classes.spacing}>
                        <NEInput
                          key={'interactive-firstName'}
                          label="First Name"
                          variant="outlined"
                          fullWidth={true}
                          required
                          name="firstName"
                          id="interactiveFirstName"
                          value={interactive.firstName || ''}
                          onChange={e => handleInteractive(e)}
                          error={
                            (error.interactive &&
                              !!error.interactive.firstName) ||
                            ''
                          }
                          helperText={
                            (error.interactive &&
                              error.interactive.firstName) ||
                            ''
                          }
                          onBlur={generatePermalinkInteractive}
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.spacing}>
                        <NEInput
                          key={'interactive-lastName'}
                          label="Last Name"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.inputWrap}
                          name="lastName"
                          value={interactive.lastName || ''}
                          onChange={e => handleInteractive(e)}
                          onBlur={generatePermalinkInteractive}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.spacing}>
                        <NEInput
                          key={'interactive-permaLink'}
                          label="Permalink"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.inputWrap}
                          name="permaLink"
                          id="interactive-permaLink"
                          value={
                            values &&
                            values.interactive &&
                            values.interactive.permaLink
                          }
                          onChange={e => handleInteractive(e)}
                          required
                          error={
                            (error.interactive &&
                              !!error.interactive.permaLink) ||
                            ''
                          }
                          helperText={
                            (error.interactive &&
                              error.interactive.permaLink) ||
                            ''
                          }
                          onBlur={() => {
                            if (!interactive.permaLink.includes(SLUG_PREFIX)) {
                              setValues({
                                ...values,
                                interactive: {
                                  permaLink: `${SLUG_PREFIX}${slugify(
                                    `${interactive.firstName} ${interactive.lastName}`,
                                  )}`,
                                },
                              });
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6} className={classes.spacing}>
                        <NEInput
                          key={'interactive-email'}
                          label="Email"
                          variant="outlined"
                          fullWidth={true}
                          required
                          name="email"
                          id="interactiveEmail"
                          value={interactive.email || ''}
                          onChange={e => handleInteractive(e)}
                          error={
                            (error.interactive && !!error.interactive.email) ||
                            ''
                          }
                          helperText={
                            (error.interactive && error.interactive.email) || ''
                          }
                        />
                        <NEAutocomplete
                          key={'interactive-speciality'}
                          id="interactive-specialty"
                          multiple
                          options={specialtyValues}
                          label="Specialty"
                          defaultValue={
                            (values.interactive &&
                              values.interactive.specialty) ||
                            []
                          }
                          name="interactive.specialty"
                          onChange={(e, data) => {
                            setValues({
                              ...values,
                              interactive: {
                                ...interactive,
                                specialty: data,
                              },
                              instrId: id,
                            });
                          }}
                        />
                        <NEInput
                          key={'charity-name'}
                          id="charity-name"
                          label="Charity Name"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.inputWrap}
                          name="charity.name"
                          defaultValue={charity && charity.name}
                          onChange={e => handleInput(e)}
                        />
                        <NEInput
                          key={'charity-url'}
                          id="charity-url"
                          label="Charity Url"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.inputWrap}
                          name="charity.url"
                          defaultValue={charity && charity.url}
                          onChange={e => handleInput(e)}
                        />
                        <NEInput
                          //id="flag"
                          key={'interactive-flag'}
                          name="interactive.flag"
                          label="Flag"
                          value={interactive.flag || ''}
                          select
                          fullWidth
                          variant="outlined"
                          onChange={e => {
                            setValues({
                              ...values,
                              interactive: {
                                ...interactive,
                                flag: e.target.value,
                              },
                              instrId: id,
                            });
                          }}
                        >
                          <MenuItem value="None">None</MenuItem>
                          {flagValues &&
                            flagValues.map(item => {
                              return (
                                <MenuItem value={item && item.title}>
                                  {item && item.title}
                                </MenuItem>
                              );
                            })}
                        </NEInput>
                        <Autocomplete
                          key={'interactive-adminTags'}
                          multiple
                          freeSolo
                          options={[]}
                          label="Admin Tags"
                          popupIcon={<AddIcon />}
                          forcePopupIcon={true}
                          disableClearable={true}
                          value={interactive.adminTags || ''}
                          required
                          name="adminTags"
                          renderTags={(value: string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                classes={{
                                  tag: classes.tag,
                                  label: classes.chipLabel,
                                  deleteIcon: classes.deleteIcon,
                                }}
                              />
                            ))
                          }
                          onChange={(e, data) => {
                            setValues({
                              ...values,
                              interactive: {
                                ...interactive,
                                adminTags: data,
                              },
                              instrId: instructorId,
                            });
                          }}
                        />
                        <Formik
                          initialValues={values}
                          enableReinitialize
                          validationSchema={() => {}}
                        >
                          {({
                            values,
                            setFieldValue,
                            submitForm,
                            errors,
                            isValid,
                            setErrors,
                            validateForm,
                            setTouched,
                            handleSubmit,
                          }) => {
                            return (
                              <Form onSubmit={() => {}}>
                                <div className={classes.instructorGrid}>
                                  <div style={{ width: '82%' }}>
                                    <FormikField
                                      name="id"
                                      label="Instructor Id"
                                      disabled={true}
                                    />
                                  </div>
                                  <ButtonBase
                                    className={classes.viewPackages}
                                    onClick={getTextAreaContent}
                                  >
                                    {copyIdBtnText}
                                  </ButtonBase>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                        <NEInput
                          id="interactiveConnectId"
                          key={'interactive-connectId'}
                          label="Connect Id"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.inputWrap}
                          name="connectId"
                          value={interactive.connectId || ''}
                          onChange={e => handleInteractive(e)}
                          error={
                            (error.interactive &&
                              !!error.interactive.connectId) ||
                            ''
                          }
                          helperText={
                            (error.interactive &&
                              error.interactive.connectId) ||
                            ''
                          }
                        />

                        {instructorPackage && instructorPackage.length > 0 && (
                          <>
                            <div className={classes.package}>
                              <Box
                                component="span"
                                className={classes.subTitle}
                              >
                                PACKAGES
                              </Box>
                            </div>
                            <div>
                              <Box
                                component="span"
                                className={classes.subInTitle}
                              >
                                <Grid container>
                                  {instructorPackage &&
                                    instructorPackage.map(data => {
                                      return (
                                        <div className={classes.packageWrapper}>
                                          {data.nickname}
                                          {data.currency === 'usd'
                                            ? ' $'
                                            : ' $'}
                                          {data.unit_amount / 100}.
                                          {data.unit_amount_decimal
                                            .toString()
                                            .substr(-2)}
                                          <ViewPackage
                                            data={data}
                                            nickname={data.nickname}
                                            description={
                                              data.metadata.description
                                            }
                                            classes={data.metadata.classes}
                                            price={`${
                                              data.currency === 'usd'
                                                ? ' $'
                                                : ' $'
                                            }${data.unit_amount /
                                              100}.${data.unit_amount_decimal
                                              .toString()
                                              .substr(-2)}`}
                                            id={data.id}
                                          />
                                        </div>
                                      );
                                    })}
                                </Grid>
                              </Box>
                            </div>
                          </>
                        )}
                        {interactiveConcepts &&
                        interactiveConcepts.length > 0 ? (
                          <div className={classes.bottomText}>
                            <Box component="span" className={classes.subTitle}>
                              Associated Concepts
                            </Box>
                            {interactiveConcepts &&
                              interactiveConcepts.map(concept => {
                                return (
                                  <div>
                                    <Box
                                      component="span"
                                      className={classes.subInTitle}
                                    >
                                      {concept}
                                    </Box>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          ''
                        )}
                        {interactive &&
                        interactive.promocodes &&
                        interactive.promocodes.length > 0 ? (
                          <div className={classes.bottomText}>
                            <Box component="span" className={classes.subTitle}>
                              Active Coupons
                            </Box>
                            {interactive.promocodes.map(coupon => {
                              return (
                                <div>
                                  <Box
                                    component="span"
                                    className={classes.subInTitle}
                                  >
                                    {coupon.uniqueCode}
                                  </Box>
                                  <div className={classes.activeTime}>
                                    {' '}
                                    {`Valid ${moment(coupon.startDate).format(
                                      'MM/DD/YYYY',
                                    )} - ${moment(coupon.endDate).format(
                                      'MM/DD/YYYY',
                                    )}`}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          ''
                        )}
                        <Grid item className={classes.bottomText}>
                          <Typography variant="h6">HISTORY</Typography>
                          <HistoryText>
                            Added:{' '}
                            {moment(
                              values.interactive &&
                                values.interactive.createdAt,
                            ).format('MM/DD/YYYY')}
                          </HistoryText>
                          {values.interactive &&
                            values.interactive.publishedDate && (
                              <HistoryText>
                                Published:
                                {moment(
                                  values.interactive &&
                                    values.interactive.publishedDate,
                                ).format('MM/DD/YYYY')}
                              </HistoryText>
                            )}
                          <HistoryText>
                            Updated:{' '}
                            {moment(
                              values.interactive &&
                                values.interactive.updatedAt,
                            ).format('MM/DD/YYYY')}
                          </HistoryText>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className={classes.spacing}>
                        <NEInput
                          key={'interactive-description'}
                          label="Description"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.inputWrap}
                          required
                          multiline
                          rows="4"
                          name="description"
                          id="interactive-description"
                          value={interactive.description || ''}
                          onChange={e => handleInteractive(e)}
                          error={
                            (error.interactive &&
                              !!error.interactive.description) ||
                            ''
                          }
                          helperText={
                            (error.interactive &&
                              error.interactive.description) ||
                            ''
                          }
                        />

                        <Grid
                          item
                          xs={12}
                          className={classes.spacing}
                          style={{ paddingTop: '10px' }}
                        >
                          <NEInput
                            key={'interactive-instagram'}
                            label="Instagram"
                            id="interactive-instagram"
                            variant="outlined"
                            fullWidth={true}
                            className={classes.inputWrap}
                            name="instagram"
                            value={interactive.instagram || ''}
                            onChange={e => handleInteractive(e)}
                            error={
                              (error.interactive &&
                                !!error.interactive.instagram) ||
                              ''
                            }
                            helperText={
                              (error.interactive &&
                                error.interactive.instagram) ||
                              ''
                            }
                          />
                          <NEInput
                            key={'interactive-facebook'}
                            label="Facebook"
                            variant="outlined"
                            fullWidth={true}
                            className={classes.inputWrap}
                            name="facebook"
                            id="interactive-facebook"
                            value={interactive.facebook || ''}
                            onChange={e => handleInteractive(e)}
                            error={
                              (error.interactive &&
                                !!error.interactive.facebook) ||
                              ''
                            }
                            helperText={
                              (error.interactive &&
                                error.interactive.facebook) ||
                              ''
                            }
                          />
                          <NEInput
                            key={'interactive-twitter'}
                            label="Twitter URL"
                            variant="outlined"
                            fullWidth={true}
                            className={classes.inputWrap}
                            name="twitter"
                            id="interactive-twitter"
                            value={interactive.twitter || ''}
                            onChange={e => handleInteractive(e)}
                            error={
                              (error.interactive &&
                                !!error.interactive.twitter) ||
                              ''
                            }
                            helperText={
                              (error.interactive &&
                                error.interactive.twitter) ||
                              ''
                            }
                          />
                          <NEInput
                            key={'interactive-website'}
                            label="Web URL"
                            variant="outlined"
                            fullWidth={true}
                            className={classes.inputWrap}
                            name="website"
                            id="interactive-website"
                            value={interactive.website || ''}
                            onChange={e => handleInteractive(e)}
                            error={
                              (error.interactive &&
                                !!error.interactive.website) ||
                              ''
                            }
                            helperText={
                              (error.interactive &&
                                error.interactive.website) ||
                              ''
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={6} className={classes.spacing}></Grid>
                    </Grid>

                    <Grid container xs={3} className={classes.spacing}>
                      <Grid item xs={12} className={classes.spacing}>
                        <div className={classes.imageBox}>
                          <div className={classes.image}>
                            {loader ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'relative',
                                  top: '40%',
                                }}
                              >
                                <StyledSpinnerNext
                                  style={{ borderTopColor: 'black' }}
                                />
                              </div>
                            ) : (
                              <Avatar
                                alt="img"
                                src={interactive.img || interactive.imageUrl}
                                className={classes.image}
                              ></Avatar>
                            )}
                          </div>
                          {loader ? (
                            ''
                          ) : (
                            <label className={classes.uploadBtn}>
                              <input
                                accept="image/*"
                                className={classes.inputHidden}
                                id="icon-button-file"
                                type="file"
                                onChange={e => getInteractiveBaseUrl(e)}
                              />
                              Update Image
                            </label>
                          )}
                        </div>
                        {/* {error && error.imageUrl ? (
                          <div>
                            <Box component="span" className={classes.spanError}>
                              {error.imageUrl}
                            </Box>
                          </div>
                        ) : (
                          ''
                        )} */}
                        <LabelText></LabelText>
                        <Box component="span" className={classes.spanHeader}>
                          MERCHANDISING ART
                          <LabelText>16:9 Interactive Banner </LabelText>
                        </Box>

                        <ImageUploader
                          title=""
                          aspectRatio="16x9"
                          value={
                            (interactive && interactive.bannerImg) ||
                            (interactive.graphics &&
                              interactive.graphics['16x9'])
                          }
                          handleChange={(e, g) => handleImage(e, g)}
                          id="ImageUploader1"
                          key="ImageUploader1"
                        />

                        <Box component="span" className={classes.spanHeader}>
                          INSTRUCTOR PAGE
                          <LabelText>
                            Gallery 16:9 (
                            {galleryInteractiveUrl &&
                              galleryInteractiveUrl.length}{' '}
                            items){' '}
                          </LabelText>
                        </Box>
                        <div className={classes.galleryDiv}>
                          <div>
                            <Avatar
                              alt="img"
                              src={
                                (galleryInteractiveUrl &&
                                  galleryInteractiveUrl.length > 0 &&
                                  galleryInteractiveUrl[0].url) ||
                                ''
                              }
                              className={classes.galleryDrop}
                            />
                          </div>
                          <div
                            className={classes.manageGallery}
                            onClick={() => {
                              setTimeout(() => {
                                openInteractiveGalleryDialog(true);
                              });
                            }}
                          >
                            Manage Gallery
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        {isInteractiveGalleryDialogOpen && (
                          <GalleryDialog
                            isOpen={isInteractiveGalleryDialogOpen}
                            id={'2'}
                            key={'2'}
                            title="MANAGE INTERACTIVE INSTRUCTOR GALLERY"
                            galleryDetails={interactive && interactive.gallery}
                            handleSaveBtn={handleInteractiveSaveBtn}
                            isDelete={isDelete}
                            type="people"
                            handleClose={() => {
                              openInteractiveGalleryDialog(false);
                            }}
                            galleryData={galleryInteractiveData}
                          ></GalleryDialog>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {activeTab === 0 ? (
          <DialogActions>
            {isHidden && !isPublished && (
              <Box
                display="flex"
                alignItems="center"
                mr={1}
                style={{ width: '100%' }}
              >
                <StatusLabel>STATUS</StatusLabel>
                <StatusText>HIDDEN</StatusText>
              </Box>
            )}{' '}
            {isPublished && (
              <div>
                <Button
                  onClick={() => {
                    openConfirmDialog(true);
                  }}
                  color="secondary"
                >
                  Hide
                </Button>
              </div>
            )}
            <div
              style={{
                textAlign: 'end',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {!isPublished && (
                <Button
                  onClick={() => {
                    let btnVal = 'publish';
                    handleFormSubmit(btnVal);
                  }}
                  color="secondary"
                >
                  Publish
                </Button>
              )}
              {isPublished && (
                <Box display="flex" alignItems="center" mr={1}>
                  <StatusLabel>STATUS</StatusLabel>
                  <StatusText>PUBLISHED</StatusText>
                </Box>
              )}
              <Button
                onClick={e => {
                  e.preventDefault();
                  previewInstructor('VOD');
                }}
                color="secondary"
              >
                Preview
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  let btnVal = 'submit';
                  handleFormSubmit(btnVal);
                }}
                color="secondary"
                size="large"
              >
                Save & Close
              </Button>
            </div>
          </DialogActions>
        ) : (
          <DialogActions>
            {interactive &&
              interactive.isHidden &&
              !interactive.isPublished && (
                <Box
                  display="flex"
                  alignItems="center"
                  mr={1}
                  style={{ width: '100%' }}
                >
                  <StatusLabel>STATUS</StatusLabel>
                  <StatusText>HIDDEN</StatusText>
                </Box>
              )}{' '}
            {interactive && interactive.isPublished && (
              <div>
                <Button
                  onClick={() => {
                    openConfirmDialog(true);
                  }}
                  color="secondary"
                >
                  Hide
                </Button>
              </div>
            )}{' '}
            <div
              style={{
                textAlign: 'end',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {interactive && !interactive.isPublished && (
                <Button
                  onClick={() => {
                    let btnVal = 'publish';
                    handleFormSubmit(btnVal);
                  }}
                  color="secondary"
                >
                  Publish
                </Button>
              )}
              {interactive && interactive.isPublished && (
                <Box display="flex" alignItems="center" mr={1}>
                  <StatusLabel>STATUS</StatusLabel>
                  <StatusText>PUBLISHED</StatusText>
                </Box>
              )}
              {interactive && (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    previewInstructor('Interactive');
                  }}
                  color="secondary"
                >
                  Preview
                </Button>
              )}
              <Button
                variant="contained"
                onClick={() => {
                  let btnVal = 'submit';
                  handleFormSubmit(btnVal);
                }}
                color="secondary"
                size="large"
              >
                Save & Close
              </Button>
            </div>
          </DialogActions>
        )}
        {/* <GalleryDialog
          isOpen={isGalleryDialogOpen}
          id={'1'}
          title="MANAGE INSTRUCTOR GALLERY"
          galleryDetails={gallery}
          handleSaveBtn={handleSaveBtn}
          isDelete={isDelete}
          type="people"
          handleClose={() => {
            openGalleryDialog(false);
          }}
          galleryData={galleryData}
        ></GalleryDialog>

        <GalleryDialog
          isOpen={isInteractiveGalleryDialogOpen}
          id={'2'}
          title="MANAGE INSTRUCTOR GALLERY"
          galleryDetails={interactive && interactive.gallery}
          handleSaveBtn={handleInteractiveSaveBtn}
          isDelete={isDelete}
          type="people"
          handleClose={() => {
            openInteractiveGalleryDialog(false);
          }}
          galleryData={galleryInteractiveData}
        ></GalleryDialog> */}
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          handleClose={openConfirmDialog}
          onAgree={handleOk}
          onDisagree={() => openConfirmDialog(false)}
          agreeButtonText="Yes"
          disagreeBtnText="Cancel"
          headerText="Are you sure?"
          bodyText="You’re about to hide this Instructor."
        ></ConfirmDialog>
      </Dialog>
    </div>
  );
}

function validateFields({
  firstNameErr,
  //imageErr,
  permaLinkErr,
  descriptionErr,
  // specialtyErr,
  instagramErr,
  facebookErr,
  twitterErr,
  websiteErr,
}) {
  let formError = {};
  if (!firstNameErr) {
    formError = { ...formError, firstName: 'Please enter first name' };
  }
  // if (!imageErr) {
  //   formError = { ...formError, imageUrl: 'Please upload a Profile Image' };
  // }
  if (!permaLinkErr) {
    formError = { ...formError, permaLink: 'Please enter Permalink' };
  }
  if (!descriptionErr) {
    formError = { ...formError, description: 'Please enter Description' };
  }
  // if (specialtyErr && specialtyErr.length === 0) {
  //   formError = { ...formError, isNoSpecialty: 'Please Choose Specialty' };
  // }
  if (instagramErr !== '' && !instagramErr.includes('https')) {
    formError = { ...formError, instagram: 'Please enter valid instagram url' };
  }
  if (facebookErr !== '' && !facebookErr.includes('https')) {
    formError = { ...formError, facebook: 'Please enter valid facebook url' };
  }
  if (twitterErr !== '' && !twitterErr.includes('https')) {
    formError = { ...formError, twitter: 'Please enter valid twitter url' };
  }
  if (websiteErr !== '' && !websiteErr.includes('https')) {
    formError = { ...formError, website: 'Please enter valid website url' };
  }

  return formError;
}

function validateInteractiveFields({
  firstNameErr,
  emailErr,
  //connectIdErr,
  instagramErr,
  facebookErr,
  twitterErr,
  websiteErr,
  permaLinkErr,
  descriptionErr,
}) {
  let formError = {};
  if (!firstNameErr) {
    formError = {
      ...formError,
      interactive: {
        firstName: 'Please enter first name',
      },
    };
  }
  if (!emailErr) {
    formError = {
      ...formError,
      interactive: {
        email: 'Please enter email Id',
      },
    };
  }
  // if (!connectIdErr) {
  //   formError = {
  //     ...formError,
  //     interactive: {
  //       connectId: 'Please enter connect Id',
  //     },
  //   };
  // }

  if (!permaLinkErr) {
    formError = {
      ...formError,
      interactive: { permaLink: 'Please enter Permalink' },
    };
  }
  if (!descriptionErr) {
    formError = {
      ...formError,
      interactive: { description: 'Please enter Description' },
    };
  }
  if (instagramErr !== '' && !instagramErr.includes('https')) {
    formError = {
      ...formError,
      interactive: { instagram: 'Please enter valid instagram url' },
    };
  }
  if (facebookErr !== '' && !facebookErr.includes('https')) {
    formError = {
      ...formError,
      interactive: { facebook: 'Please enter valid facebook url' },
    };
  }
  if (twitterErr !== '' && !twitterErr.includes('https')) {
    formError = {
      ...formError,
      interactive: {
        twitter: 'Please enter valid twitter url',
      },
    };
  }
  if (websiteErr !== '' && !websiteErr.includes('https')) {
    formError = {
      ...formError,
      interactive: {
        website: 'Please enter valid website url',
      },
    };
  }
  return formError;
}

function validateImage({ imageErr }) {
  let imagePublishError = {};
  if (!imageErr) {
    imagePublishError = {
      ...imagePublishError,
      imageUrl: 'Please upload a Profile Image',
    };
  }

  return imagePublishError;
}
