import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    dateTimeWrap: {
      '& .MuiFormControl-marginNormal': {
        marginBottom: '20px',
        marginTop: 0,
        width: '100%',
      },
    },
  }),
);

// Component for Seperate Start Date and Start Time

export default function StartDateTime(props) {
  const {
    type = 'Add',
    date,
    selectedValue = new Date(),
    label,
    value,
    defaultTime = false,
    disabled = false,
    onBlur = () => {},
  } = props;
  const classes = useStyles();
  const handleDateChange = date => {
    if (defaultTime) {
      if (label === 'Start') {
        date.setHours(0, 0, 0, 0);
      } else if (label === 'Expiry') {
        date.setHours(23, 59, 59, 0);
      }
      selectedValue(value, date);
    } else if (type === 'Edit') {
      selectedValue('airedDate', date);
    } else {
      selectedValue(date);
    }
    onBlur();
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.dateTimeWrap}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label={`${label ? label : 'Start'} Date`}
            value={date}
            disabled={disabled}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        {!defaultTime && (
          <div className={classes.dateTimeWrap}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label={`${label ? label : 'Start'} Time`}
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              variant="dialog"
              DialogProps={{
                maxWidth: '322px !important',
              }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        )}
      </MuiPickersUtilsProvider>
    </>
  );
}
