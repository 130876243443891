/*
 *
 * legal constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/Legal/DEFAULT_ACTION',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  REQUEST_LEGAL_LIST = 'REQUEST_LEGAL_LIST',
  LEGAL_LIST_SUCCESS = 'LEGAL_LIST_SUCCESS',
  LEGAL_LIST_FAILED = 'LEGAL_LIST_FAILED',
  STORE_LEGAL_PAGE = 'STORE_LEGAL_PAGE',
}

export default ActionTypes;
