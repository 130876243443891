/*
 *
 * CurateGenre actions
 *
 */

import { action } from 'typesafe-actions';
import ActionTypes from './constants';

export const requestBrowseGenreList = () =>
  action(ActionTypes.REQUEST_BROWSE_GENRE_LIST);
export const storeBrowseGenreList = result =>
  action(ActionTypes.STORE_BROWSE_GENRE_LIST, result);
export const setLoader = data => action(ActionTypes.SET_LOADER, data);
export const reorderTrays = genreList =>
  action(ActionTypes.REORDER_TRAYS, { genreList });
