import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  Typography,
  Theme,
  createStyles,
  withStyles,
  IconButton,
} from '@material-ui/core';
import { List, arrayMove, arrayRemove } from 'baseui/dnd-list';
import moment from 'moment';
import { getUniqItems } from '../utils/array';
import { getTimeAfterMinutes, UnivDate } from '../utils/calender';

const styles = (theme: Theme) =>
  createStyles({
    classWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    classWrap: {
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    videoTitle: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    videoItemCloseButton: {
      marginLeft: '16px',
      padding: '4px',
    },
    videoItemCloseIcon: {
      color: '#D6D9DC',
      width: '20px',
      height: '20px',
    },
    dragWrap: {
      marginBottom: '25px',
      width: '450px',
      background: 'none',
    },
    manageContent: {
      width: '100%',
      color: '#909599',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontStyle: 'italic',
      fontWeight: '500',
      letterSpacing: '0',
      lineHeight: '20px',
      textAlign: 'center',
      borderTop: '1px solid #E3E3E4',
      paddingTop: '15px',
    },
    divider: {
      width: '100%',
      position: 'fixed',
      bottom: '0',
      zIndex: '10',
      background: '#fff',
    },
    fullWidth: {
      width: '97%',
    },
    search: {
      width: '100%',
    },
  });

interface getSubTitleFunc {
  (): string;
}

interface DraggableListProps {
  height: string;
  minHeight: string;
  list: any[];
  onListChange: (value1: any, value2: any) => void;
  classes: any;
  subTitle: getSubTitleFunc | string;
  removable: boolean;
  isInteractive: boolean;
  listItem?: any;
  listIcon?: any;
}

export function DraggableList({
  height,
  minHeight,
  list,
  onListChange,
  classes,
  subTitle,
  removable = true,
  isInteractive,
  listItem: DraggableComponent,
  listIcon: DraggableIcon,
}: DraggableListProps) {
  function getLastUpdateTime() {
    const momentTZ = require('moment-timezone');
    const zone = momentTZ.tz.guess();
    const lastUpdateTime = momentTZ.tz(zone).format('z');
    return lastUpdateTime;
  }

  const defaultListItem = ({ $value, $index }) => (
    <div
      className={
        $value && $value.startDate ? classes.classWrap : classes.classWrapper
      }
      key={$index}
    >
      <Typography variant="subtitle1" classes={{ root: classes.videoTitle }}>
        {$value && ($value.title ? $value.title : $value.text)}
      </Typography>

      {isInteractive && $value && $value.startDate && (
        <Typography
          variant="subtitle1"
          style={{
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <>
            {moment(UnivDate($value.startDate))
              .utc()
              .format('ddd, MMM D')}{' '}
            {''}•{' '}
            {moment(UnivDate($value.startDate))
              .utc()
              .format('LT')}
            {$value.duration && (
              <> - {getTimeAfterMinutes($value.startDate, $value.duration)}</>
            )}{' '}
            {$value && $value.startDate && getLastUpdateTime()}
          </>
        </Typography>
      )}
      <Typography
        variant="body2"
        style={{ flexBasis: '54px', textAlign: 'right' }}
      >
        {typeof subTitle === 'function'
          ? subTitle($value, $index)
          : typeof subTitle === 'string' && subTitle}
      </Typography>
    </div>
  );

  const defaultListIcon = props => (
    <IconButton {...props} classes={{ root: classes.videoItemCloseButton }}>
      <CancelIcon classes={{ root: classes.videoItemCloseIcon }} />
    </IconButton>
  );

  return (
    <List
      items={list}
      removable={removable}
      onChange={({ oldIndex, newIndex }) => {
        const newList =
          newIndex === -1
            ? arrayRemove(list, oldIndex)
            : arrayMove(list, oldIndex, newIndex);
        const uniqueList = getUniqItems(newList, 'id');
        onListChange(uniqueList);
      }}
      overrides={{
        Label: {
          component: DraggableComponent || defaultListItem,
        },
        List: {
          style: ({ $theme }) => ({
            outline: `none`,
            marginTop: '0',
            overflowX: 'hidden',
            minHeight: minHeight ? minHeight : '100%',
            height: height ? height : '100%',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }),
        },
        Item: {
          style: ({ $theme, $isSelected, $isDragged }) => ({
            outline: 'none',
            borderBottomColor: '#E3E3E4!important',
            boxShadow:
              $isSelected || $isDragged
                ? '0 2px 2px 0 rgba(0,0,0,0.5);'
                : '0 2px 2px 0 rgba(0,0,0, 0);',
            borderTopColor: '#E3E3E4!important',
            borderLeftColor: '#E3E3E4!important',
            borderRightColor: '#E3E3E4!important',
            borderTopWidth: '1px !important',
            borderLeftWidth: '1px !important',
            borderRightWidth: '1px !important',
            borderBottomWidth: '1px !important',
            paddingLeft: '8px',
            paddingRight: '8px',
            paddingTop: '8px',
            paddingBottom: '8px',
            borderRadius: '6px',
            marginBottom: '6px',
            width: '100%',
          }),
        },
        CloseHandle: {
          component: DraggableIcon || defaultListIcon,
        },
        DragHandle: {
          style: ({ $theme, $isSelected, $isDragged }) => ({
            '& > svg': {
              color: 'rgba(0,0,0,0.54)!important',
            },
          }),
        },
      }}
    />
  );
}

export default withStyles(styles)(DraggableList);
