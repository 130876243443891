/**
 *
 * TopSection
 *
 */
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FloatingActionButton from '../FloatingActionButton';
import moment from 'moment';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      padding: '0 20px',
      borderBottom: '1px solid #E3E3E4',
    },
    topHead: {
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '28px',
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    subtitleWrap: {
      height: 'auto',
      borderRadius: '6px',
      backgroundColor: 'rgba(212,216,220,0.5)',
      alignItems: 'center',
      marginBottom: '12px',
    },
    topWrapper: {
      width: '100%',
      padding: '38px 20px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    subtitle: {
      width: 'auto',
      fontFamily: 'Proxima-Nova-Regular',
    },
    subtitle1: {
      color: '#12161A',
      fontSize: '17px',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '24px',
    },
    body2: {
      color: '#000000',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      fontFamily: 'ProximaNova-Medium',
    },
    buttonWrap: {
      display: 'flex',
    },
    typeWrap: {
      width: '255px',
      marginLeft: '10px',
      color: '#909599',
      fontFamily: 'Proxima-Nova-Regular',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '20px',
      float: 'left',
      textTransform: 'capitalize',
    },
  }),
);

export default function TopSection(props) {
  const classes = useStyles();
  let { topSection, handleComponentManage, carouselType = '' } = props;

  return (
    <Grid className={classes.top}>
      <div className={classes.topHead}>TOP SECTION</div>

      {topSection &&
        topSection.length > 0 &&
        topSection.map((item, index) => (
          <TraySection
            classes={classes}
            item={item}
            handleManage={() => handleComponentManage(item)}
            carouselType={carouselType}
          />
        ))}
    </Grid>
  );
}

export function TraySection({
  item,
  handleManage,
  handleAdd,
  carouselType,
  hideRightInfo = false,
}: {
  item: any;
  handleManage(item): void;
  handleAdd(item): void;
  carouselType: any;
  hideRightInfo: boolean;
}) {
  const classes = useStyles();

  return (
    <Grid container className={classes.subtitleWrap}>
      {item && (
        <div className={classes.topWrapper}>
          <div className={classes.subtitle}>
            <div className={classes.subtitle1}>{item.componentTitle}</div>
            <div className={classes.body2}>
              {item.userSegments ? 'Segments: ' + item.userSegments : ''}
            </div>
          </div>
          <div className={classes.buttonWrap}>
            {!hideRightInfo && (
              <div className={classes.typeWrap}>
                <div>
                  {item.componentType}:{' '}
                  {item.content && item.content.data
                    ? item.content.data.length
                    : 0}{' '}
                  {carouselType}
                </div>
                <div>
                  Last Updated:{' '}
                  {item.updatedAt
                    ? moment(item.updatedAt).format('MM/DD/YYYY')
                    : ''}
                </div>
              </div>
            )}
            {handleAdd && (
              <FloatingActionButton
                title={item.componentTitle}
                buttonText={'Add'}
                type={item.componentType}
                contentType={item.contentType}
                subTitle={item.userSegments}
                contentMode={item.content && item.content.mode}
                onClick={handleAdd}
              />
            )}
            <FloatingActionButton
              title={item.componentTitle}
              type={item.componentType}
              contentType={item.contentType}
              subTitle={item.userSegments}
              contentMode={item.content && item.content.mode}
              onClick={handleManage}
              editTitle={
                item.componentTitle == 'Promo Spot'
                  ? 'EDIT PROMO SPOT'
                  : 'EDIT COMPONENT'
              }
            />
          </div>
        </div>
      )}
    </Grid>
  );
}
