import React from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent } from '../../../components/Dialog';
import { isEmpty, map } from 'lodash';
import { Grid, Button, Box, FormControlLabel, Switch } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import request from '../../../shared/lib/request';
import { SectionHeader, LabelText } from '../../../components/Typography';
import { NEInputLight as NEInput } from '../../../components/NEInput';
import Loader from '../../../components/Loader';
// actions
import { passwordRestAction, switchEmailLogin } from '../actions';
import Accordian from '../../../components/Accordian';
import TableList from '../../../components/TableList';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { openSnackBar } from '../../Root/actions';
import Avatar from '@material-ui/core/Avatar';
import { Formik, Form } from 'formik';
import FormikField from '../../../components/Formik/FormikField';
import * as Yup from 'yup';
import { validateEmail } from '../../../utils/commonFunctions';

const { object, string } = Yup;

const ClassSchema = object({
  cashAmount: string()
    .required()
    .matches(/((\d+)+(\.\d{2,2}))$/, 'Enter the price of format $0.00'),
  notes: string().required('Notes is a required field'),
});

const EmailSchema = object({
  newEmail: string().required(),
});
const UserNameScheme = object({
  newUserName: string().required(),
});
const dialogStyles = () =>
  createStyles({
    dialogContainer: {
      borderRadius: 0,
      maxWidth: '1080px',
      minWidth: '1080px',
    },
    historyTab: {
      paddingBottom: '20px',
    },
    imageContainer: {
      maxWidth: '252px',
      margin: '0px 16px',
    },
    videoHeader: {
      borderBottom: '1px solid #999FA4',
    },
    classWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    form: {
      // maxWidth: '33.33%',
    },
    dialogContent: {
      padding: '24px 21px 72px',
    },
    rightPanel: {
      padding: '0 0px 0 40px',
    },
    leftPanel: {
      '& .MuiButton-fullWidth': {
        margin: '8px 0 10px 0',
      },
      '& section': {
        margin: '24px 0',
      },
    },
    sectionHeader: {
      margin: '0 0 16px 0 !important',
    },
    spanError: {
      color: '#FF1C58',
      fontSize: '12px',
      fontFamily: 'Proxima-Nova-Regular',
    },
    head: {
      padding: '7px 2px',
      backgroundColor: '#12161A',
      color: '#fff',
      fontSize: '12px',
      fontFamily: 'ProximaNova-Bold',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: '22px',
      textAlign: 'left',
    },
    detail: {
      fontFamily: 'ProximaNova-Bold',
      fontWeight: 500,
      color: '#000',
      fontSize: '12px',
      lineHeight: '22px',
      padding: '7px 15px',
      textAlign: 'left',
    },
    rowSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    table: {
      textAlign: 'center',
      borderCollapse: 'collapse',
      border: '1px solid #D4D8DC',
      width: '100%',
      maxHeight: '100px',
      overflow: 'auto',
    },
    tableHead: {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed',
    },
    tbody: {
      display: 'block',
      overflow: 'auto',
      tableLayout: 'fixed',
      maxHeight: '250px',
    },
    avatarProfile: {
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    textHeader: {
      minHeight: 0,
      marginBottom: '0px',
      marginTop: '12px',
    },
    avatar: {
      textTransform: 'uppercase',
    },
    large: {
      width: '47px',
      height: '47px',
    },
    updateUser:{
      marginBottom:'15px',
      marginTop:'10px',
    }
  });
declare module namespace {
  export interface VideoPreferences {
    wifiOnly: boolean;
  }

  export interface StoreUpgrade {
    product: string;
    durationInDays: string;
    startDate: string;
    endDate: string;
    planLabel: string;
  }

  export interface Upgrade {
    planId: string;
    planNickname: string;
    duration: string;
    startDate: string;
    firstBillingDate: string;
    endDate: string;
    cancelled: number;
  }

  export interface Stripe {
    currentPlanId: string;
    currentPlanNickname: string;
    duration: string;
    cancelled: number;
    upgrade: Upgrade;
  }

  export interface Subscription {
    expiryDate: string;
    subscriptionStatus: number;
    store: string;
    periodType: string;
    customerId: string;
    productId: string;
    storeUpgrade: StoreUpgrade;
    planLabel: string;
    cancelled: number;
    subscriptionHistory: number;
    stripe: Stripe;
  }

  export interface History {
    endurance: number;
    strength: number;
    recovery: number;
  }

  export interface Gallery {
    type: string;
    url: string;
  }

  export interface Specialty {
    title: string;
  }

  export interface Instructor {
    id: string;
    firstName: string;
    lastName: string;
    permaLink: string;
    gallery: Gallery[];
    imageUrl: string;
    specialty: Specialty[];
    concepts: string[];
  }

  export interface Genre {
    id: string;
    title: string;
  }

  export interface Category {
    id: string;
    title: string;
  }

  export interface Gallery2 {
    type: string;
    url: string;
  }

  export interface Graphics {
    '16x9': string;
    '9x16': string;
    '1x1': string;
    '32x9': string;
    '16x9_trans': string;
  }

  export interface Concept {
    id: string;
    title: string;
    collectionType: string;
    kicker: string;
    permaLink: string;
    flag: string;
    genres: Genre[];
    categories: Category[];
    gallery: Gallery2[];
    graphics: Graphics;
    dataURL: string;
    isFavorited: boolean;
    classCount: number;
  }

  export interface Favorites {
    instructors: Instructor[];
    concepts: Concept[];
  }

  export interface Graphics2 {
    '16x9': string;
    '32x9': string;
    '1x1': string;
    '9x16': string;
    '16x9_trans': string;
  }

  export interface NextClassTowatch {
    id: string;
    permaLink: string;
    videoType: string;
  }

  export interface StartedProgram {
    id: string;
    title: string;
    permaLink: string;
    graphics: Graphics2;
    kicker: string;
    collectionType: string;
    completedClassCount: number;
    badgeImageUrl: string;
    fitnessClassCount: number;
    nextClassTowatch: NextClassTowatch;
    btnText: string;
  }

  export interface Member {
    interactive: any;
    id: string;
    email: string;
    profiles?: string[];
    userStatus: string;
    videoPreferences?: VideoPreferences;
    subscription?: Subscription;
    firstName?: string;
    activityText?: string;
    goal: number;
    history?: History;
    favorites?: Favorites;
    startedPrograms?: StartedProgram[];
    isSocialLogin: boolean;
    isAppleLogin: boolean;
    imageUri: string;
  }
}
const intialMemberState: namespace.Member = {
  email: '',
  id: '',
  profiles: [],
  userStatus: 'inactive',
  goal: 1,
  isSocialLogin: false,
  isAppleLogin: false,
  firstName: '',
  imageUri: '',
};

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableCell);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: '10px',
      lineHeight: '20px',
      padding: '6px 8px',
      height: 34,
      fontFamily: 'Proxima-Nova-Regular',
    },
    body: {
      fontSize: '10px',
      lineHeight: '20px',
      padding: '6px 8px',
      fontFamily: 'Proxima-Nova-Regular',
    },
  }),
)(TableCell);

const initialValues: any = {
  cashAmount: '$0.00',
  notes: '',
};

const initialEmailForm: any = {
  newEmail: '',
};

const initialUserName: any = {
  newUserName: '',
};

function EditMembers({
  isOpen,
  title,
  classes,
  handleClose,
  id,
  name,
  listRefresh}) {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [memberInfo, setMemberInfo] = useState(intialMemberState);
  const [neoUsPurchaseHistory, setNeoUsPurchaseHistory] = useState(null);
  const [error, setError] = React.useState({});
  const [newEmailError, setNewEmailError] = React.useState(false);
  const [newUserNameError, setNewUserNameError] = React.useState(false);
  let [initialFormValues, setInitialFormValues] = React.useState(initialValues);
  let [initialEmailFormValues, setInitialEmailFormValues] = React.useState(
    initialEmailForm,
  );
  let [initialUserNameValues, setInitialUserNameValues] = React.useState(
    initialUserName,
  );
  const [isCloseAlertOpen, showAlertToClose] = useState(false);
  const [isDeleteAlertOpen, showAlertToDelete] = useState(false);
  const [isRevokeAlertOpen, showAlertToRevoke] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isRevoking, setRevoking] = useState(false);
  const [isCashApplying, setCashApplying] = useState(false);

  function neoUsCash(amount) {
    let walletPrice = `$0.00`;
    if (amount !== 0) {
      walletPrice = `$${amount
        .toString()
        .slice(0, -2)}.${amount.toString().substr(-2)}`;
    }
    return walletPrice;
  }

  function totalPassesAmount(passes) {
    let amount = 0;
    passes.map(pass => {
      if (pass.planId === 'drop-in' && pass.balance > 0) {
        amount = amount + pass.amount;
      }
    });
    let cash = neoUsCash(amount);
    return cash;
  }

  const _init = async () => {
    try {
      setLoader(true);
      let { result: memberDetail } = await request({
        url: `/neouadmin/v1/members`,
        method: 'GET',
        params: { id: `${id}` },
      });
      setMemberInfo(memberDetail);
      if(memberDetail && memberDetail.interactive && memberDetail.interactive.role) {
        setSuperAdminState({checked: true});
      }
      setLoader(false);
      getPurchaseHistory();
    } catch (error) {
      let message = "Couldn't fetch the member";
      if (error && error.data && error.data.error) {
        message =
          typeof error.data.error === 'string'
            ? error.data.error
            : error.data.message || "Couldn't fetch the member";
      }
      dispatch(openSnackBar(message));
      setLoader(false);
      setMemberInfo(intialMemberState);
      handleClose(true);
    }
  };

  const getPurchaseHistory = async () => {
    try {
      let { result } = await request({
        url: `/neouadmin/v1/members/get-purchase-history`,
        method: 'GET',
        params: { id: `${id}`, interactive: true },
      });
      setNeoUsPurchaseHistory(result);
    } catch (error) {
      let message = 'User has no purchase history';
      if (error && error.data && error.data.error) {
        message =
          typeof error.data.error === 'string'
            ? error.data.error
            : error.data.message || "Couldn't fetch the Purchase History";
      }
      dispatch(openSnackBar(message));
      setLoader(false);
    }
  };

  const newUserNameSubmit = (data) => {
    let { newUserName } = data;
    if (newUserName) {
      setNewUserNameError(false);
      try {
        request({
          url: `/neouadmin/v1/members/update-displayName?userId=${id}`,
          method: 'PUT',
          data: {
            displayName: newUserName,
          },
        });
        setTimeout(() => {
          _init();
        }, 1500);
        dispatch(openSnackBar('User Name updated successfully.'));
      } catch (error) {
        dispatch(openSnackBar('User Name update failed'));
      }
    } else {
      setNewUserNameError(true);
    }
  };

  const newEmailSubmit = data => {
    let { newEmail } = data;
    if (validateEmail(newEmail)) {
      setNewEmailError(false);
      try {
        request({
          url: `neouadmin/v1/members/update-email`,
          method: 'POST',
          data: {
            oldEmail: memberInfo.email,
            newEmail: newEmail.toLowerCase().trim(),
          },
        });
        setTimeout(() => {
          _init();
        }, 1500);
        dispatch(openSnackBar('Email updated successfully.'));
      } catch (error) {
        dispatch(openSnackBar('Email update failed'));
      }
    } else {
      setNewEmailError(true);
    }
  };

  const addCash = data => {
    let { cashAmount, notes } = data;
    let cash = cashAmount;
    if (cashAmount.charAt(0) !== '$') {
      setError(`Include '$' for Amount`);
    } else if (cashAmount.charAt(1) === '0') {
      setError(`Minimum $1.00 required to issue NEO Us Cash Amount`);
    } else if (!cashAmount) {
      setError('Amount is required to issue NEO Us Cash');
    } else {
      applyCash({
        numOfCredits: cash,
        notes: notes,
      });
      setError('');
    }
  };

  const applyCash = async ({ numOfCredits, notes }) => {
    try {
      const neoUsCashIssue = {
        userId: id,
        numOfCredits: parseInt(numOfCredits.replace(/[^0-9 ]/g, '')),
        notes: notes,
      };
      setCashApplying(true);
      await request({
        url: `/neouadmin/v1/members/grant-credits`,
        method: 'POST',
        data: neoUsCashIssue,
      });
      setTimeout(() => {
        _init();
      }, 1500);
      setError('');
      dispatch(openSnackBar('NEO Us Cash issued Successfully'));
    } catch ({ data: error }) {
      if (error.error) {
        dispatch(openSnackBar(error.error));
      } else {
        dispatch(openSnackBar('Issue NEO Us Cash Failed'));
      }
    } finally {
      setCashApplying(false);
    }
  };

  const _destroy = () => {};

  const _getId = id => {
    if (id) return `XX${String(id).substring(id.length - 4)}`;
  };

  const onResetPassword = () => {
    dispatch(passwordRestAction(memberInfo.email));
  };

  const switchEmailAction = () => {
    let body = {};
    if (memberInfo.isSocialLogin) {
      body = {
        id: memberInfo.id,
        isSocialLogin: false,
        email: memberInfo.email,
      };
    } else {
      body = {
        id: memberInfo.id,
        isAppleLogin: false,
        email: memberInfo.email,
      };
    }
    dispatch(switchEmailLogin(body));
  };

  const onRevokePassword = async () => {
    const getData = {
      userStatus: 'inactive',
      id: memberInfo.id,
    };
    try {
      setRevoking(true);
      let { result } = await request({
        url: `/neouadmin/v1/members/update`,
        data: getData,
        method: 'PUT',
      });
      listRefresh();
      _init();
    } catch (error) {
      if (error && error.data) {
        dispatch(openSnackBar(error.data.message || "Couldn't revoke access"));
      }
    } finally {
      setRevoking(false);
    }
  };

  const _changeFirstName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target as HTMLInputElement;
    let newMemberInfo = { ...memberInfo, [name]: value };
    setMemberInfo(newMemberInfo);

    try {
      let { result } = await request({
        url: `/neouadmin/v1/members/update`,
        method: 'PUT',
        data: newMemberInfo,
      });
      newMemberInfo = { ...memberInfo, [name]: value };
      // setMemberInfo(newMemberInfo);
    } catch (error) {
      // setMemberInfo(memberInfo || intialMemberState);
    }
  };
  const BlockSwitch = withStyles({
    switchBase: {
      color: '#fff',
      '&$checked': {
        color: '#86b660',
      },
      '&$checked + $track': {
        backgroundColor: '#86b660',
      },
    },
    checked: {},
    track: {},
  })(Switch);
  const [state, setState] = React.useState({
    checked: false,
  });
  const [superAdminstate, setSuperAdminState] = React.useState({
    checked: false,
  });

  async function removeProfile() {
    try {
      await request({
        url: `neouadmin/v1/members/remove-profile-pic`,
        method: 'POST',
        data: {
          id: memberInfo.id,
        },
      });
      _init();
      dispatch(
        openSnackBar(
          'Profile image for this user has been removed and disabled.',
        ),
      );
    } catch (error) {
      dispatch(openSnackBar(`Couldnt disable profile image for this user`));
    }
  }
  async function addProfile() {
    try {
      await request({
        url: `neouadmin/v1/members/enable-profile-pic`,
        method: 'POST',
        data: {
          id: memberInfo.id,
        },
      });
      dispatch(openSnackBar('Profile image for this user has been enabled.'));
    } catch (error) {
      dispatch(openSnackBar('Couldnt enable profile image for this user'));
    }
  }

  async function addSuperAdmin(data:boolean) {
    try {
      await request({
        url: `/neouadmin/v1/members/role/add-revoke`,
        method: 'POST',
        data: {
          id: memberInfo.id,
          isSuperAdmin: data
        },
      });
      if(data) {
        dispatch(openSnackBar("Super admin enabled"));
      }else {
        dispatch(openSnackBar("Super admin disabled"));
      }
    } catch (error) {
      dispatch(openSnackBar('Couldnt enable update the user'));
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let isChecked = event.target.checked;
    setState({ ...state, [event.target.name]: isChecked });
    if (isChecked) {
      removeProfile();
    } else {
      addProfile();
    }
  };
  const handleChangeSuperAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
    let isChecked = event.target.checked;
    setSuperAdminState({ ...state, [event.target.name]: isChecked });
    if (isChecked) {
      addSuperAdmin(true);
    } else {
      addSuperAdmin(false);
    }
  };

  async function deleteMember() {
    try {
      setDeleting(true);
      await request({
        url: `neouadmin/v1/members/delete-user`,
        method: 'POST',
        data: {
          email: memberInfo.email,
        },
      });
      listRefresh();
      dispatch(openSnackBar('User profile has been deleted successfully.'));
    } catch (error) {
      dispatch(openSnackBar(`Couldnt delete user profile`));
    } finally {
      setDeleting(false);
    }
  }

  useEffect(() => {
    _init();
    setError('');
    // _getSub();
    return _destroy();
  }, []);
  if (isLoading) {
    return <Loader />;
  } else {
    let favoritesList = [];
    let watchList = [];
    let getAccount = [];
    let subscriptionInfo = {};
    const getNewData = memberInfo.favorites;
    if (memberInfo.userStatus === 'active') {
      watchList = map(memberInfo.classList, el => el.videoDetails);
      subscriptionInfo = memberInfo.subscription || {};
      //check getAccount history [] missing 'billing_issues_detected_at'
      //getAccount = subscription && subscription.history;
      getAccount = (subscriptionInfo && subscriptionInfo.history) || [];
      map(getNewData.instructors, (el, i) => {
        favoritesList.push({ name: `${el}` });
      });
      map(getNewData.concepts, (el, i) => {
        favoritesList.push({ name: `${el}` });
      });
    }

    let message;
    if (
      !isEmpty(getAccount) &&
      getAccount[0].billing_issues_detected_at === null
    ) {
      message = 'Completed';
    } else {
      message =
        !isEmpty(getAccount) &&
        'Billing issues detected at' +
          `${getAccount[0].billing_issues_detected_at}`;
    }
    return (
      <>
        {isLoading && <Loader />}
        <ConfirmDialog
          isOpen={isDeleteAlertOpen}
          handleClose={() => showAlertToDelete(false)}
          onAgree={deleteMember}
          onDisagree={() => showAlertToDelete(false)}
          agreeButtonText="Yes"
          disagreeBtnText="Cancel"
          headerText="Are you sure?"
          bodyText="Do you want to delete the member?"
        ></ConfirmDialog>
        <ConfirmDialog
          isOpen={isRevokeAlertOpen}
          handleClose={() => showAlertToRevoke(false)}
          onAgree={onRevokePassword}
          onDisagree={() => showAlertToRevoke(false)}
          agreeButtonText="Yes"
          disagreeBtnText="Cancel"
          headerText="Are you sure?"
          bodyText="Do you want to revoke access to the member?"
        ></ConfirmDialog>
        {memberInfo.id && (
          <Dialog
            onClose={() => handleClose()}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            disableBackdropClick={true}
            classes={{
              paperScrollPaper: classes.dialogContainer,
            }}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => handleClose()}
            >
              {title}
            </DialogTitle>
            {memberInfo.id && (
              <DialogContent dividers classes={{ root: classes.dialogContent }}>
                <Grid container>
                  <Grid item xs={4} className={classes.leftPanel}>
                    <SectionHeader className={classes.sectionHeader}>
                      Member Credentials
                    </SectionHeader>
                    <NEInput
                      value={name}
                      variant="outlined"
                      label="Name"
                      placeholder="Enter Name"
                      name="firstName"
                      onChange={_changeFirstName}
                      fullWidth
                      disabled
                      className={classes.textHeader}
                    />
                    <Grid container className={classes.avatarProfile}>
                      <Grid item xs={2} className={classes.avatar}>
                        {memberInfo.imageUri ? (
                          <Avatar
                            alt={name}
                            src={memberInfo.imageUri}
                            className={classes.large}
                          ></Avatar>
                        ) : (
                          <Avatar
                            alt={name}
                            src="/static/images/avatar/1.jpg"
                            className={classes.large}
                          ></Avatar>
                        )}
                      </Grid>
                      <Grid item xs={10}>
                        <FormControlLabel
                          control={
                            <BlockSwitch
                              checked={Boolean(state.checked)}
                              onChange={handleChange}
                              name="checked"
                            />
                          }
                          label="Disable profile image"
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <FormControlLabel
                          control={
                            <BlockSwitch
                              checked={Boolean(superAdminstate.checked)}
                              onChange={handleChangeSuperAdmin}
                              name="checked"
                            />
                          }
                          label="Super Admin"
                        />
                      </Grid>
                    </Grid>
                    <LabelText>Email: {memberInfo.email}</LabelText>
                    <div className={classes.rowSpaceBetween}>
                      <LabelText>
                        Type: {memberInfo.isSocialLogin && 'Facebook Connect'}
                        {memberInfo.isAppleLogin && 'Apple Connect'}
                        {!memberInfo.isSocialLogin &&
                          !memberInfo.isAppleLogin &&
                          'Email Connect'}
                      </LabelText>
                      <LabelText>ID: {_getId(memberInfo.id)}</LabelText>
                    </div>
                    <Button
                      onClick={() => onResetPassword()}
                      color="secondary"
                      fullWidth
                    >
                      SEND PASSWORD RESET
                    </Button>
                    <>
                    <Formik
                      initialValues={initialUserNameValues}
                      enableReinitialize
                      validationSchema={UserNameScheme}
                      onSubmit={newUserNameSubmit}
                    >
                      {({
                          values,
                          setFieldValue,
                          submitForm,
                          errors,
                          isValid,
                          setErrors,
                          validateForm,
                          setTouched,
                        }) => {
                          return (
                          <Form noValidate>
                            <>
                              <div className={classes.updateUser}>
                                <SectionHeader>
                                  Update User Name
                                </SectionHeader>
                                <LabelText>
                                  Current User Name: {memberInfo.displayName || '-'}
                                </LabelText>
                              </div>
                              <FormikField
                                name="newUserName"
                                label="Enter new User Name"
                                required
                                placeholder="Enter new User Name"
                                onBlur={() => {
                                  setNewUserNameError(false);
                                }}
                              ></FormikField>
                              {newUserNameError ? (
                                  <div>
                                    <Box
                                      component="span"
                                      className={classes.spanError}
                                    >
                                      {'Enter valid User Name'}
                                    </Box>
                                  </div>
                                ) : (
                                  ''
                                )}
                              <Button
                                  color="secondary"
                                  type="submit"
                                  fullWidth
                                >
                                  UPDATE USER NAME
                              </Button>
                            </>
                          </Form>)
                        }}
                    </Formik>
                    </>
                    {!memberInfo.isSocialLogin && !memberInfo.isAppleLogin && (
                      <Formik
                        initialValues={initialEmailFormValues}
                        enableReinitialize
                        validationSchema={EmailSchema}
                        onSubmit={newEmailSubmit}
                      >
                        {({
                          values,
                          setFieldValue,
                          submitForm,
                          errors,
                          isValid,
                          setErrors,
                          validateForm,
                          setTouched,
                        }) => {
                          return (
                            <Form noValidate>
                              <>
                                <SectionHeader
                                  className={classes.sectionHeader}
                                >
                                  Update Email
                                </SectionHeader>
                                <FormikField
                                  name="newEmail"
                                  label="Enter new email"
                                  required
                                  placeholder="Enter new email"
                                  onBlur={() => {
                                    setNewEmailError(false);
                                  }}
                                ></FormikField>
                                {newEmailError ? (
                                  <div>
                                    <Box
                                      component="span"
                                      className={classes.spanError}
                                    >
                                      {'Enter valid email'}
                                    </Box>
                                  </div>
                                ) : (
                                  ''
                                )}

                                <Button
                                  color="secondary"
                                  type="submit"
                                  fullWidth
                                >
                                  UPDATE EMAIL
                                </Button>
                              </>
                            </Form>
                          );
                        }}
                      </Formik>
                    )}
                    {(memberInfo.isSocialLogin || memberInfo.isAppleLogin) && (
                      <Button
                        onClick={event => {
                          showAlertToClose(true);
                        }}
                        color="secondary"
                        fullWidth
                      >
                        SWITCH TO EMAIL LOGIN
                      </Button>
                    )}
                    <>
                      {isCloseAlertOpen && (
                        <ConfirmDialog
                          isOpen={isCloseAlertOpen}
                          headerText="Are you sure?"
                          bodyText="You are about switch social/apple login to email login."
                          onAgree={() => switchEmailAction()}
                          handleClose={() => showAlertToClose(false)}
                          onDisagree={() => showAlertToClose(false)}
                        ></ConfirmDialog>
                      )}
                    </>
                    <section>
                      <SectionHeader>Account & Subscription</SectionHeader>
                      <LabelText>
                        Account Created:{' '}
                        {!isEmpty(memberInfo)
                          ? moment(memberInfo.createdAt).format(
                              'MM/DD/YYYY [at] HH:mm:ss',
                            )
                          : '-'}{' '}
                      </LabelText>
                      <LabelText>
                        {' '}
                        Device:{' '}
                        {!isEmpty(memberInfo.registeredFrom)
                          ? memberInfo.registeredFrom
                          : ' -'}
                      </LabelText>
                      {/* <LabelText> Offer(s): 1 Week Free Trial</LabelText> */}
                    </section>
                    <section>
                      <LabelText>
                        Subscription Plan: {subscriptionInfo.productId || '-'}
                      </LabelText>
                      <LabelText>
                        Start Date:{' '}
                        {!isEmpty(memberInfo)
                          ? moment(memberInfo.createdAt, 'YYYY-MM-DD').format(
                              'LL',
                            )
                          : ' -'}
                      </LabelText>
                      <LabelText>
                        Subscription Status:{' '}
                        {subscriptionInfo.cancelled === 1
                          ? 'Suspended'
                          : Number(subscriptionInfo.subscriptionStatus) === 1
                          ? `Active, Expires ${moment(
                              subscriptionInfo.expiryDate,
                              'YYYY-MM-DD',
                            ).format('MMM, DD YYYY')}`
                          : 'Inactive'}
                      </LabelText>
                      <LabelText>
                        Store: {String(subscriptionInfo.store) || '-'}
                      </LabelText>
                    </section>

                    {memberInfo.userStatus === 'active' && (
                      <Button
                        onClick={() => {
                          showAlertToRevoke(true);
                        }}
                        disabled={isRevoking}
                        color="primary"
                        variant="contained"
                        fullWidth
                      >
                        REVOKE ACCESS
                      </Button>
                    )}
                    {/* <Button color="primary" variant="contained" fullWidth>
                Cancel Subscription
              </Button> */}
                    {memberInfo.id && (
                      <Button
                        onClick={deleteMember}
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={isDeleting}
                      >
                        DELETE MEMBER
                      </Button>
                    )}

                    {/* {memberInfo && memberInfo.interactive !== null ? (
                      <>
                        <section>
                          <SectionHeader>NEO Us Cash</SectionHeader>
                          <LabelText>{`Balance: ${
                            memberInfo.interactive &&
                            memberInfo.interactive.neoCash &&
                            memberInfo.interactive.neoCash.balance
                              ? neoUsCash(
                                  memberInfo.interactive.neoCash.balance,
                                )
                              : '$0.00'
                          }`}</LabelText>
                        </section>
                        <Formik
                          initialValues={initialFormValues}
                          enableReinitialize
                          validationSchema={ClassSchema}
                          onSubmit={() => {}}
                        >
                          {({
                            values,
                            setFieldValue,
                            submitForm,
                            errors,
                            isValid,
                            setErrors,
                            validateForm,
                            setTouched,
                          }) => {
                            return (
                              <Form noValidate>
                                <>
                                  <FormikField
                                    name="cashAmount"
                                    label="NEO Us Cash Amount"
                                    required
                                    placeholder="NEO Us Cash Amount"
                                    onBlur={() => {
                                      setError('');
                                    }}
                                  ></FormikField>
                                  {error ? (
                                    <div>
                                      <Box
                                        component="span"
                                        className={classes.spanError}
                                      >
                                        {error}
                                      </Box>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <FormikField
                                    name="notes"
                                    label="Notes"
                                    required
                                    placeholder="Notes"
                                  ></FormikField>
                                  <Button
                                    onClick={event => {
                                      if (isValid) {
                                        addCash(values);
                                      }
                                    }}
                                    color="secondary"
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    disabled={isCashApplying}
                                  >
                                    ISSUE NEO US CASH
                                  </Button>
                                </>
                              </Form>
                            );
                          }}
                        </Formik>
                      </>
                    ) : (
                      ''
                    )} */}
                  </Grid>
                  <Grid item xs={8} className={classes.rightPanel}>
                    {false && (
                      <Accordian title="Billing History & Offers">
                        <Grid container>
                          <Grid item xs className={classes.historyTab}>
                            <LabelText>
                              Current Billing Status: {message ? message : ' -'}
                            </LabelText>
                            <LabelText>
                              Next Billing:{' '}
                              {!isEmpty(subscriptionInfo.expiryDate)
                                ? moment(
                                    subscriptionInfo.expiryDate,
                                    'YYYY-MM-DD',
                                  ).format('MM/DD/YYYY')
                                : ' -'}
                            </LabelText>
                            {/* <LabelText>Method: CC 4842 EXP 12/2023</LabelText> */}
                          </Grid>
                          {/* <Grid item xs>
                    <LabelText>Apply New Offer:</LabelText>
                    <Box display="flex" alignItems="flex-start">
                      <NEInputLight
                        placeholder="Enter Offer Code"
                        variant="outlined"
                      />
                      <Button color="secondary">Apply</Button>
                    </Box>
                  </Grid> */}
                          {/* <Grid xs={12}>
                          <TableList
                            label="billing"
                            tHead={['Date', 'Plan', 'Period', 'Total']}
                            data={getAccount}
                            subList={subList && subList}
                          />
                        </Grid> */}
                        </Grid>
                      </Accordian>
                    )}
                    {/* <Accordian title="Credit Transaction">
                      <Grid container>
                        <Grid xs={12}>
                          <TableList
                            label="Credits"
                            tHead={[
                              'DATE',
                              'CREDITS',
                              'EXPIRY',
                              'AMT CHARGED',
                              'TYPE',
                            ]}
                            data={memberInfo && memberInfo.watchHistory}
                          />
                        </Grid>
                      </Grid>
                    </Accordian> */}
                    <Accordian title="NEO Us Billing History">
                      <TableList
                        label="neoUsBillingHistory"
                        tHead={[
                          'DATE',
                          'PACKAGE',
                          'STATUS',
                          'PAYMENT INTENT ID',
                          'DESC',
                          'TOTAL',
                        ]}
                        data={neoUsPurchaseHistory}
                      />
                    </Accordian>
                    <Accordian title="NEO Us Packages">
                      <TableList
                        label="neoUsPackages"
                        tHead={['TITLE', 'TOTAL PASSES', 'REMAINING PASSES']}
                        data={
                          memberInfo &&
                          memberInfo.interactive &&
                          memberInfo.interactive.passes
                            ? memberInfo.interactive.passes
                            : {}
                        }
                      />
                    </Accordian>
                    <Accordian title="Booking History">
                      <Grid container>
                        <Grid xs={12}>
                          <TableList
                            label="Booking"
                            tHead={[
                              'DATE',
                              'STATUS',
                              'TITLE',
                              'INSTRUCTOR',
                              'EVENT SCHEDULE',
                              'DROP IN PRICE',
                            ]}
                            data={memberInfo && memberInfo.bookedClasses}
                          />
                        </Grid>
                      </Grid>
                    </Accordian>
                    <Accordian title="Watch List">
                      <TableList
                        label="whatchlist"
                        tHead={['TITLE', 'BRAND/COLLECTION']}
                        data={memberInfo && memberInfo.watchList}
                      />
                    </Accordian>
                    <Accordian title="Favorites">
                      <TableList
                        label="Favorites"
                        tHead={['BRAND / INTSTRUCTOR']}
                        data={favoritesList}
                      />
                    </Accordian>
                    <Accordian title="History">
                      <TableList
                        label="History"
                        data={memberInfo && memberInfo.watchHistory}
                        tHead={['DATE', 'TITLE', 'BRAND/COLLECTION']}
                      />
                    </Accordian>
                  </Grid>
                </Grid>
              </DialogContent>
            )}
          </Dialog>
        )}
      </>
    );
  }
}
export default withStyles(dialogStyles)(EditMembers);
