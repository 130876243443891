/*
 *
 * Administrator reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  administratorList: [],
  isListFetchingError: false,
  isLoading: false,
  administratorDetailInfo: {},
  searchSuggestion: [],
  isAdminDrawerOpenAdd: false,
};
function administratorReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.STORE_INSTRUCTOR_NAME:
      return {
        ...state,
        searchSuggestion: action.payload,
      };
    case ActionTypes.REQUEST_ADMINISTRATOR_LIST:
      return { ...state, isLoading: true };
    case ActionTypes.OPEN_ADMIN_DRAWER_ADD:
      return { ...state, isAdminDrawerOpenAdd: action.payload };
    case ActionTypes.ADMINISTRATOR_LIST_SUCCESS:
      return {
        ...state,
        administratorList: [...action.payload],
        isLoading: false,
      };
    case ActionTypes.SET_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.SAVE_ADMINSTRATOR_DETAIL:
      return {
        ...state,
        administratorDetailInfo: action.payload[0],
      };
    case ActionTypes.ADMINISTRATOR_LIST_FAILED:
      return { ...state, isListFetchingError: true };
    default:
      return state;
  }
}

export default administratorReducer;
