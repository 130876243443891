export const formError = errorInfo => {
  let error = {};
  errorInfo &&
    errorInfo.details.map(d => {
      let key = d.path[0];
      error[key] = d.message;
      return d;
    });
  return error;
};

export const customMessage = {
  'string.base': `"username" should be a type of 'text'`,
  'string.empty': `Please enter an {#label}`,
  'string.min': `{#label} should be atleast 5`,
  'string.max': `{#label} should be atmost 15`,
  'any.required': `{#label} is a required field`,
};
