import React, { useState } from 'react';
import SearchVideo from './SearchVideo';
import request from '../shared/lib/request';
import DraggableListByType from './DraggableListByType';
import { Divider } from '@material-ui/core';

interface VideoID {
  [key: string]: string;
}

// export interface video {
//   id: string;
//   title: string;
//   videoID: VideoID;
// }

interface getSubTitleFunc {
  (): string;
}

interface SearchVideoComponentProps {
  onVideoListChange: (newValue: any, action: string) => void;
  contentType: string;
  type?: any;
  labelText?: any;
}

export const SearchTypeComponent: React.FC<SearchVideoComponentProps> = ({
  onVideoListChange,
  contentType,
  type,
  labelText,
}) => {
  const [isSearchResult, setSearchVideo] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [inputRef, setInputRef] = useState(null);
  let timeout;
  const searchHandleChange = value => {
    timeout = setTimeout(() => {
      setSearchText('');
      if (inputRef) inputRef.value = '';
    }, 1000);
    if (value) {
      setSearchText(value.title || value.firstName);
      onVideoListChange(value, 'new');
    }
  };

  function searchVideos(data) {
    if (contentType) {
      let url = '';
      if (
        contentType === 'collections' &&
        window.location.href.includes('browse-curate')
      ) {
        if (data !== '') {
          url = `/neouadmin/v1/search-collections/${data}?type=${
            type === 'collections' ? 'interest' : type
          }`;
        } else {
          url = `/neouadmin/v1/search-collections?type=${
            type === 'collections' ? 'interest' : type
          }`;
        }
      } else {
        url = `/neouadmin/v1/search-concept-collections` + `/${data}`;
      }
      switch (contentType) {
        case 'concept':
          return request({
            url: `/neouadmin/v1/search-concepts` + `/${data}`,
            method: 'GET',
          });
        case 'collections':
          return request({
            url: url,
            method: 'GET',
            params: {
              isPublished: true,
            },
          });
        case 'instructor':
          return request({
            url: `/neouadmin/v1/people?keyword=${data}`,
            method: 'GET',
          });
        case 'promo':
          return request({
            url: `/neouadmin/v1/search-concept-collections` + `/${data}`,
            method: 'GET',
          });
        default:
          return request({
            url: `/neouadmin/v1/search`,
            method: 'POST',
            data: {
              keyword: data,
              videoType: ['class', 'generic'],
            },
          });
      }
    } else {
      return request({
        url: `/neouadmin/v1/search`,
        method: 'POST',
        data: {
          keyword: data,
        },
      });
    }
  }

  const onSearchChange = async value => {
    try {
      let result = await searchVideos(value);
      if (contentType == 'videos') {
        setSearchVideo(result.result.classes.result);
      } else {
        setSearchVideo(result.result || []);
      }
    } catch (error) {
      setSearchVideo([]);
    }
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <SearchVideo
        options={isSearchResult || []}
        name="searchType"
        inputValue={searchText}
        onChange={(event, value) => searchHandleChange(value)}
        limitTags={2}
        onInputChange={(event, value) => onSearchChange(value)}
        getOptionLabel={option => option.title || option.firstName}
        onSearchTextChange={e => setSearchText(e)}
        ref={inputRef}
        setInputRef={ref => setInputRef(ref)}
        blurOnSelect
        clearOnBlur
        labelText={labelText}
      />
    </div>
  );
};
